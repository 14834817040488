import React, { useEffect, useState } from "react";
import {HiOutlineArrowRight, HiOutlineX, HiOutlineSearch, HiOutlineAtSymbol, HiOutlineCheck} from "react-icons/hi";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useParams} from "react-router";
import {showNotification} from "@mantine/notifications";
import {ScrollArea, TextInput, Loader, LoadingOverlay, Checkbox} from '@mantine/core';
import button from "@material-tailwind/react";
import ViewCandidate from "../../candidate/modal/ViewCandidate";
import CandidateList from "./inc/CandidateList";
import {format} from 'date-fns';


export default function AddJobCandidate({setShowModal, designation, setRecall}) {
    let startDate = format(new Date(designation['startDate'].date), 'y-MM-dd');
    let endDate = format(new Date(designation['endDate'].date), 'y-MM-dd');
    let workingDates = [startDate + '#' + designation['inTime'] + '-' + designation['outTime'], endDate + '#' + designation['inTime'] + '-' + designation['outTime']];

    const {t, i18n} = useTranslation();
    const {token, loggedUserId} = JSON.parse(localStorage.getItem('user'))
    const {jobId} = useParams();
    const [showCandidateProfile, setShowCandidateProfile] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [candidateId, setCandidateId] = useState(null);
    const [candidates, setCandidates] = useState([]);

    // const [candidateFilteredList, setCandidateFilteredList] = useState(candidates);
    const [candidateFilteredList, setCandidateFilteredList] = useState([]);

    const getAvailableCandidates = (reqDesignationId, workingDates) => {
        // console.log({reqDesignationId, workingDates, placeToUse: 'job-assign'})
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/available-candidate-designation-wise`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {reqDesignationId, workingDates, placeToUse: 'job-assign'}
        })
            .then(res => {
                setCandidates(res.data.data);
                setCandidateFilteredList(res.data.data)
                setShowLoader(false)

            })
            .catch(function (err) {
                console.log(err);
            });
    }

    useEffect(()=>{
        getAvailableCandidates(designation['id'], workingDates)
    }, [])


    const searchCandidate = (e) => {
   
        // setSearching(true);
        const query = e.target.value;
        // let updatedList = [...candidates];
        let filterUpdatedList = candidates.length > 0 && candidates.filter(candidate => {
            // return (item['name'].toLowerCase().indexOf(query.toLowerCase()) !== -1) || (item['name'].toLowerCase().indexOf(query.toLowerCase()) !== -1) || (item['phoneNumber'].indexOf(query) !== -1);
            return (candidate['name'].toLowerCase().includes(query.toLowerCase())) || (candidate['email'].toLowerCase().includes(query.toLowerCase())) || (candidate['phone'].includes(query));
        });

        setCandidateFilteredList(filterUpdatedList);

        // setSearching(false);
    }


    /*const removeCandidate = (data) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-assign-candidate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: data
        })
            .then(res => {
                if (res.data.status === 404){
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Exist') + ' !'),
                        message: (t('NotFound')),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });
                }else if (res.data.status === 200){
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Success') + ' !'),
                        message: (t('Removed')),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'green',
                    });
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }*/
   
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.8]">                
                <div className="relative w-3/4">
                    {/*content*/}
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="pt-4 modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('Available') + ' ' + (designation.name ? designation.name : '')}</h5>
                                <button
                                    onClick={() => {
                                        setRecall(prevState => !prevState)
                                        setShowModal(false)
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>




                            <div className="modal-body relative">
                                        {/*<div className="flex justify-between">
                                            <div className="text-center w-full">
                                                <h1 className="text-2xl">{t('Available') + ' ' + (designation.name ? designation.name : '')}</h1>
                                            </div>
                                            <button onClick={() => {
                                                setRecall(prevState => !prevState)
                                                setShowModal(false)
                                            }}
                                                    className="bg-grey-light hover:bg-grey rounded inline-flex items-cente focus:outline-none focus:ring-1 mt-1 focus:ring-offset-1  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-4 py-2 text-sm">
                                                <HiOutlineX
                                                    className="mr-2 h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>*/}
                                <div>
                                    <div className="relative flex w-full">
                                        <TextInput
                                            onChange={(e) => e && searchCandidate(e)}
                                            // rightSection={searching && <Loader size="xs" />}
                                            placeholder=" Name, email or phone"
                                            className="w-full px-2"
                                            icon={<HiOutlineSearch />}
                                            iconWidth={15}
                                        />
                                        {/* <span className="absolute left-0 top-0 bottom-0 right-8 pr-10 inline-flex items-center px-3 text-gray-500 sm:text-sm">
                                            <HiOutlineSearch />
                                        </span> */}
                                    </div>

                                    <ScrollArea scrollbarSize={4} style={{height: 500}}>
                                        <LoadingOverlay visible={showLoader} overlayBlur={2} className="h-full" loaderProps={{ color: 'indigo', size: 'xs' }} overlayColor="bg-indigo-100" h={100}/>

                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead className="bg-gray-50">
                                            <tr>
                                                <th scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t('Name')}
                                                </th>
                                                <th scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{t('Phone')}
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                   
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white relative">

                                            {
                                                candidateFilteredList?.length > 0 && candidateFilteredList.map((candidate, index) => (

                                                    <CandidateList key={candidate['userId']} candidate={candidate} designation={designation} setCandidateId={setCandidateId} setShowCandidateProfile={setShowCandidateProfile}/>
                                                ))
                                            }
                                            {
                                                candidates?.length === 0 && (
                                                    <tr>
                                                        <td colSpan={3} className="text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm">
                                                            {t('NoAvailableCandidates')}
                                                        </td>
                                                    </tr>
                                                )
                                            }

                                            </tbody>
                                        </table>
                                    </ScrollArea>
                                </div>
                            </div>

                            {/*<div
                                className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                <button onClick={() => {
                                    setRecall(preState => !prevState)
                                    setShowModal(false)
                                }}
                                        className="bg-grey-light hover:bg-grey rounded inline-flex items-cente focus:outline-none focus:ring-1 mt-1 focus:ring-offset-1  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-4 py-2 text-sm">
                                    <HiOutlineX
                                        className="mr-2 h-5 w-5"
                                        aria-hidden="true"
                                    /><span>{t('Close')}</span>
                                </button>

                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>

            { showCandidateProfile && <ViewCandidate setShowModal={setShowCandidateProfile} candidateid={candidateId}/> }
        </>
    );
}
