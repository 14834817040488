import { StyleSheet } from '@react-pdf/renderer'

export default StyleSheet.create({
  page: {
    // display: 'flex',
    // flexDirection: 'row',
    paddingTop: 10,
    paddingLeft: 30,
    paddingRight: 15,
    paddingBottom: 10,
  },
  section_right: {
    padding: 5,
    // paddingTop: 20,
    width: '50%',
    display: 'block',
    clear: "both"
  },
  section_left: {
    width: '50%',
    // height: '100%',
    padding: 5,
    // paddingTop: 20,
    display: 'block',
    clear: "both"
  },
  image:{
    width: '100px',
    height: '40px',
  },
  table_100:{
    width: '100%'
  },
  table: {
    display: "table",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 10,
  },
  tableRow: {
    // margin: "auto",
    flexDirection: "row"
  },
  tableCol: {
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    fontSize: '9px',
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '1px',
    paddingRight: '1px',
  },
  tableNoBorder: {
    display: "table",
    width: "auto",
    border: "none",
    marginBottom: 10,
  },
  tableRowNoBorder: {
    margin: "auto",
    flexDirection: "row",
    width: "100%",
  },
  tableColNoBorder: {
    border: "none",
  },
  tableColBottomNoBorder: {
    borderBottom: "none",
  },
  tableColRightNoBorder: {
    borderRight: "none",
  },
  tableColTopBottomNoBorder: {
    borderTop: "none",
    borderBottom: "none",
  },
  colPositionRelative:{
    position: 'relative',
  },
  colPositionAbsolute:{
    position: 'absolute',
  },

  colPositionTop35:{
    top: '35%'
  },

  colPositionTop20:{
    top: '20%'
  },

  colPositionTop25:{
    top: '25%'
  },

  colPositionTop30:{
    top: '30%'
  },

  colPositionTop45:{
    top: '45%'
  },

  textAlignLeft:{
    textAlign: 'left'
  },
  textAlignCenter:{
    textAlign: 'center'
  },
  textAlignRight:{
    textAlign: 'right'
  },
  width_100:{
    width: "100%",
    clear:"both"
  },
  width_95:{
    width: "95%",
  },
  width_92:{
    width: "92%",
  },
  width_90:{
    width: "90%",
  },
  width_89:{
    width: "89%",
  },
  width_88:{
    width: "88%",
  },
  width_85:{
    width: "85%",
  },
  width_80:{
    width: "80%",
  },
  width_78:{
    width: "78%",
  },
  width_75:{
    width: "75%"
  },
  width_70:{
    width: "70%"
  },
  width_65:{
    width: "65%"
  },
  width_64:{
    width: "64%"
  },
  width_64_5:{
    width: "64.45%"
  },
  width_60:{
    width: "60%"
  },
  width_61:{
    width: "61%"
  },
  width_62:{
    width: "62%"
  },
  width_59:{
    width: "59%"
  },
  width_55:{
    width: "55%"
  },
  width_50:{
    width: "50%"
  },
  width_40:{
    width: "40%"
  },
  width_35:{
    width: "35%"
  },
  width_30:{
    width: "30%"
  },
  width_28:{
    width: "28%"
  },
  width_25:{
    width: "25%"
  },
  width_24:{
    width: "24%"
  },
  width_22:{
    width: "22%"
  },
  width_20:{
    width: "20%"
  },
  width_16:{
    width: "16%"
  },
  width_15:{
    width: "15%"
  },
  width_14:{
    width: "14%"
  },
  width_13:{
    width: "13%"
  },
  width_12:{
    width: "12%"
  },
  width_11:{
    width: "11%"
  },
  width_10:{
    width: "10%"
  },
  width_8:{
    width: "8%"
  },
  width_7:{
    width: "7%"
  },
  width_6:{
    width: "6%"
  },
  width_6_5:{
    width: "6.4%"
  },
  width_5:{
    width: "5%"
  },
  min_height_100:{
    minHeight: '100px'
  },
  min_height_75:{
    minHeight: '75px'
  },
  min_height_60:{
    minHeight: '60px'
  },
  min_height_50:{
    minHeight: '50px'
  },
  min_height_40:{
    minHeight: '40px'
  },
  min_height_30:{
    minHeight: '30px'
  },
  min_height_25:{
    minHeight: '25px'
  },
  min_height_20:{
    minHeight: '20px'
  },
  height_100:{
    height: '100vh'
  },
  profile_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20',
    marginBottom: '20px',
    height: '150',
    fontFamily: 'Helvetica-Bold',
  },
  name_text: {
    paddingTop: '10px',
    paddingBottom: '5px',
    fontSize: '14px',
    fontWeight: '900',
    color: 'white',
  },
  profession_text: {
    color: '#d1d5db',
    fontSize: '11px',
  },
  profile_img: {
    width: '60px',
    height: '60px',
    borderRadius: '90',
  },
  profile_line: {
    marginTop: '10px',
    width: '10%',
    height: '1px',
    backgroundColor: '#FFF',
    textAlign: 'center',
  },
  text_16: {
    fontSize: '16px',
  },
  text_15: {
    fontSize: '15px',
  },
  text_14: {
    fontSize: '14px',
  },
  text_13: {
    fontSize: '13px',
  },
  text_12: {
    fontSize: '12px',
  },
  text_11: {
    fontSize: '11px',
  },
  text_10: {
    fontSize: '10px',
  },
  text_sm: {
    fontSize: '11px',
    lineHeight: '1.4px'
  },
  pr_2: {
    paddingRight: '2px'
  },
})
