import React, {useEffect, useRef, useState} from 'react';

import {useTranslation} from "react-i18next";
import axios from "axios";
import {
    HiEye,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiPencil, HiTrash, HiOutlineFilter
} from "react-icons/hi";
import {Link, useNavigate} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Box, Group, Text, Drawer, ScrollArea } from "@mantine/core";
import {useForm} from "react-hook-form";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {openConfirmModal} from "@mantine/modals";

const PER_PAGE = 20;

function Index() {
    const [opened, setOpened] = useState(false);
    const navigate = useNavigate();
    const {roles, token} = JSON.parse(localStorage.getItem('user'));
    const drawerHeight = localStorage.getItem('drawerHeight');

    const {t, i18n} = useTranslation();
    // const [page, setPage] = useState(localStorage.getItem('item_page') ? localStorage.getItem('item_page') : 1);
    const [page, setPage] = useState(1);
    const [activeTab, setActiveTab] = useState('all');
    const [records, setRecords] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [clientStatusList, setClientStatusList] = useState([]);
    const [fetching, setFetching] = useState(true);
    const [clientID, setClientID] = useState('');

    const [emailData, setEmailData] = useState(null);
    const EmailDataHandel = (e) => {
        setEmailData(e.target.value);
    }

    const [nameData, setNameData] = useState(null);
    const NameDataHandel = (e) => {
        setNameData(e.target.value);
    }

    const [phoneNumberData, setPhoneNumberData] = useState(null);
    const PhoneNumberDataHandel = (e) => {
        setPhoneNumberData(e.target.value);
    }

    const [spinner, setSpinner] = useState(false);
    const [refresh, setRefresh] = useState(false);

    //Form validation
    const validationSchema = Yup.object().shape({
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    function formSubmit(data) {
        setSpinner(true);
        setFetching(true);
        if (page === 1){
            setRefresh(true);
        }else {
            setPage(1);
        }
        setOpened(false)
    }

    const fullTableHeight = localStorage.getItem('fullTableHeight');


    const getModerators = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/administrator`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "per_page": PER_PAGE,
                "page": page,
            }
        })
            .then(res => {
                setTotalRecords(res.data.total)
                setRecords(res.data.data)
                setFetching(false);

            })
            .catch(function (error) {
                console.log(error)
            })
    }

    useEffect(()=>{
        getModerators();
    }, []);


    const ClientDelete = (e, id) => {
        e.preventDefault();
        openConfirmModal({
            title: (t('DeleteThisData')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureYouWantToDelete')}
                </Text>
            ),
            labels: {confirm: (t('Delete')), cancel: (t('NoDontDeleteIt'))},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () =>
                (
                    axios({
                        method: 'delete',
                        url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/delete`,
                        headers: {
                            "Accept": `application/json`,
                            "Content-Type": `application/json`,
                            "Access-Control-Allow-Origin": '*',
                            "Authorization": `Bearer ${token}`
                        },
                        data:{
                            user_id : id
                        }
                    })
                        .then(res => {
                            if (res.status === 202) {
                                setRefresh(true)
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                )
            ,
        });
    }


    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-between text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Moderator')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('ModeratorRelatedInformationCanBeFoundHere')}</sub></span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                {/* <button
                                    onClick={() => setOpened(true)}
                                    className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0">
                                    <HiOutlineFilter size={12} className={'mr-1'}/>{t('Filter')}
                                </button> */}

                                <Link to ='/moderator/register'
                                      className="inline-flex items-center ml-3 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiOutlinePlus size={12} className={'mr-1'}/>{t('AddModerator')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full px-4">
                    {/* <Drawer
                        opened={opened}
                        onClose={() => setOpened(false)}
                        title="Filter Client"
                        padding="xl"
                        size="xl"
                        overlayColor={"black"}
                        overlayOpacity={0.55}
                        overlayBlur={3}
                        position={"right"}
                        transitionDuration={250}
                        transitionTimingFunction="ease"
                    >
                        <form onSubmit={handleSubmit(formSubmit)}>
                            <ScrollArea style={{ height: drawerHeight }}>
                                <div className="mb-2">
                                    <TextInput
                                        {...register("name")}
                                        onChange={NameDataHandel}
                                        placeholder={t("EnterCompanyName")}
                                        value={nameData?nameData:''}
                                        withAsterisk={false}
                                        label={t("CompanyName")}
                                    />
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="Email" className="form-input-sm-label">{t('Email')}</label>
                                    <TextInput
                                        {...register("email")}
                                        onChange={EmailDataHandel}
                                        placeholder={t("EnterEmail")}
                                        value={emailData?emailData:''}
                                        withAsterisk
                                    />
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="Phone Number" className="form-input-sm-label">{t('Phone Number')}</label>
                                    <TextInput
                                        {...register("phoneNumber")}
                                        onChange={PhoneNumberDataHandel}
                                        placeholder={t("EnterPhoneNumber")}
                                        value={phoneNumberData?phoneNumberData:''}
                                        withAsterisk
                                    />
                                </div>

                            </ScrollArea>



                            <div className="mb-2 mt-5 text-right">

                                <Grid>
                                    <Grid.Col span={6}>


                                    </Grid.Col>
                                    <Grid.Col span={3}>

                                    </Grid.Col>
                                    <Grid.Col span={3}>
                                        <button type='submit' className="px-6 py-2 bg-indigo-800 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                            {spinner ? (
                                                <HiOutlineFilter
                                                    className={'mr-2 animate-spin'}
                                                    size={16}/>
                                            ) : (<HiOutlineFilter size={16} className={'mr-2'}/>)}
                                            <span>{t('Filter')}</span>
                                        </button>
                                    </Grid.Col>
                                </Grid>


                            </div>
                        </form>

                    </Drawer> */}
                    <Box sx={{height: fullTableHeight}}>
                        <DataTable
                            withBorder
                            records={records}
                            striped
                            styles={(theme) => ({
                                root: {
                                    border: '1px solid #e0e7ff',
                                    borderRadius: 2,
                                },
                                header: {
                                    background: '#e0e7ff',
                                    backgroundColor: "#e0e7ff",
                                    color: theme.colors.indigo[6],
                                },
                                pagination: {
                                    color: theme.colors.gray[3],
                                    paddingTop : "5px",
                                    paddingBottom : "5px"
                                },
                                item: {
                                    '&[data-active]': {
                                        backgroundImage: theme.fn.gradient({ from: 'red', to: 'yellow' }),
                                    },
                                },
                            })}
                            columns={[
                                {
                                    accessor: '',
                                    title: (t('SN.')),
                                    width: 45,
                                    render: (record) => (records.indexOf(record) + 1) + PER_PAGE * (page - 1)
                                },
                                {
                                    accessor: 'name',
                                    title: (t('Name')),
                                },
                                {
                                    accessor: 'email',
                                    title: (t('Email')),
                                    width: 300,
                                },
                                {
                                    accessor: 'phoneNumber',
                                    title: (t('PhoneNumber')),
                                },
                                {
                                    accessor: 'status',
                                    title: (t('Status')),
                                    width: 100,
                                    render: ({enable}) => enable ? 'Enable' : 'Disable'
                                },
                                {
                                    accessor: 'actions',
                                    title: <Text mr="xs">{t('Action')}</Text>,
                                    textAlignment: 'right',
                                    // render: (record) => (
                                    //     <Group spacing={4} position="right" noWrap>
                                    //         <ActionIcon color="green" >
                                    //             <HiPencil size={16} onClick = {(e) => console.log(record.id)}/>
                                    //         </ActionIcon>
                                    //         <ActionIcon color="blue">
                                    //             <HiEye size={16} onClick = {(e) => console.log(record)} />
                                    //         </ActionIcon>
                                    //         <ActionIcon color="red">
                                    //             <HiTrash size={16} onClick= {(e) => console.log(record.id)} />
                                    //         </ActionIcon>
                                    //     </Group>
                                    // ),
                                },

                            ]}
                            totalRecords={totalRecords}
                            recordsPerPage={PER_PAGE}
                            page={page}
                            onPageChange={(p) => {
                                setPage(p)
                                setFetching(true)
                            }}
                            fetching={fetching}
                            loaderSize="sm"
                            loaderColor="indigo"
                            loaderBackgroundBlur={1}
                        />
                    </Box>

                </div>

            </main>

            {showModal ? <ViewModerator setShowModal={setShowModal} clientID={clientID}/> : ''}

        </>
    )
}

export default Index