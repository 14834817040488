import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {HiEye, HiOutlineOfficeBuilding, HiOutlinePlus} from "react-icons/hi";
import {ActionIcon, Box, Group, LoadingOverlay, Text} from "@mantine/core";
import {DataTable} from "mantine-datatable";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import {useForm} from "react-hook-form";
import axios from "axios";
import {showNotification} from "@mantine/notifications";
import {FiX} from "react-icons/fi";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useNavigate} from "react-router";



function PayrollSummery() {
    const {domainId, roles, token, loggedUserId, name} = JSON.parse(localStorage.getItem('user'));
    const {t, i18n} = useTranslation();
    const location = useLocation();
    let fullFormHeight = localStorage.getItem('fullFormHeight');
    fullFormHeight = fullFormHeight.slice(0, -2);
    fullFormHeight = fullFormHeight - 77;
    fullFormHeight = fullFormHeight + "px";
    const [assignCandidateId, setAssignCandidateId] = useState([]);
    const [payrollInfo, setPayrollInfo] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-assign-candidate-list-by-date-range`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: location.state
        }).then(res => {

            if(res.data.status===200){

                setPayrollInfo(res.data.data);
            }
            if(res.data.status===404){
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t(res.data.message)),
                    // message: (t(res.data.message)),
                    autoClose: 3000,
                    disallowClose: true,
                    color: 'red',
                });
            }
        });
    }, []);

    const {register, handleSubmit, reset, formState} = useForm();
    const [isError, setIsError] = useState(false);
    const handleAssignCandidateId = (event, id) => {
        let permissions_array = [...assignCandidateId];
        if (event.target.checked) {

            permissions_array = [...assignCandidateId, id];
        } else {
            permissions_array.splice(assignCandidateId.indexOf(id), 1);
        }

        if(permissions_array.length===0){
            setIsError(true);
        }else {
            setIsError(false);
        }
        setAssignCandidateId(permissions_array);

    };

    console.log(assignCandidateId)

    let errors = [];
    function formSubmit(data) {

        const formData = {
            // employeeId: candidateData,
            fromDate: data.fromDate,
            toDate: data.toDate,
            jobCandidateTimeSheetId: assignCandidateId,
            loggedUserId: loggedUserId,
            domainId: domainId,
            created_by_name: name,
        }

        if (formData.jobCandidateTimeSheetId.length === 0){
            if(!(errors.indexOf("CheckedCandidates") > -1)){
                errors.push('CheckedCandidates');
            }
        }
        console.log(errors)
        if (errors.length===0) {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-assign-candidate-for-payroll-generate`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                params: formData
            }).then(res => {
                console.log(res.data)
                if (res.data.status === 201) {
                    setAssignCandidateId([]);
                    navigate('/payroll');
                }
                if (res.data.status === 404) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t(res.data.message)),
                        // message: (t(res.data.message)),
                        autoClose: 3000,
                        disallowClose: true,
                        color: 'red',
                    });
                }

            });
            setIsError(false);
        }else {
            setIsError(true);
        }
    }
    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div
                            className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Payroll')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('PayrollInformation')}</sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <Link to={'/payroll'}>
                                    <button
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    >
                                        <HiOutlinePlus size={12} className={'mr-1'}/>{t('Payroll')}
                                    </button>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full px-4">
                    { !payrollInfo && !payrollInfo.payrollBatch && <LoadingOverlay visible={true} overlayBlur={2} loaderProps={{ size: 'xs', color: 'indigo' }} overlayColor="bg-indigo-100"/> }
                    {payrollInfo && payrollInfo.payrollBatch?
                    <form onSubmit={handleSubmit(formSubmit)}>

                    <h3 className="text-center">{t('Date')} : {payrollInfo && payrollInfo.payrollBatch?payrollInfo.payrollBatch.from_date:''} to {payrollInfo && payrollInfo.payrollBatch?payrollInfo.payrollBatch.to_date:''}</h3>
                        <input {...register('fromDate')} type="hidden" name={'fromDate'} defaultValue={payrollInfo && payrollInfo.payrollBatch?payrollInfo.payrollBatch.from_date:''}/>
                        <input {...register('toDate')} type="hidden" name={'toDate'} defaultValue={payrollInfo && payrollInfo.payrollBatch?payrollInfo.payrollBatch.to_date:''}/>
                        <div style={{height: fullFormHeight}}
                             className="px-8 py-3 relative flex flex-col min-w-0 break-words w-full border-0 inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                            <table className="min-w-full">
                        <thead className="bg-white">
                        <tr className="bg-indigo-50">
                            <th className="relative whitespace-nowrap p-2 text-sm font-medium w-12">
                                <input type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                            </th>
                            <th>{t('CandidateName')}</th>
                            <th>{t('JobTitle')}</th>
                            <th>{t('CheckInTime')}</th>
                            <th>{t('CheckOutTime')}</th>
                            <th>{t('Hour')}</th>
                            <th>{t('Rate')}</th>
                            {/*<th>{t('Amount')}</th>*/}
                        </tr>
                        </thead>
                        <tbody>

                        {payrollInfo && payrollInfo.details?Object.keys(payrollInfo.details).map((employeeId)=>{
                                {var candidateWiseCount = 0}
                            return (
                                <>
                                    {payrollInfo && payrollInfo.details && payrollInfo.details[employeeId] && Object.keys(payrollInfo.details[employeeId]).map((checkInTime)=>{
                                        return (
                                            <>
                                                {payrollInfo && payrollInfo.details && payrollInfo.details[employeeId] && payrollInfo.details[employeeId][checkInTime] && Object.values(payrollInfo.details[employeeId][checkInTime]).map((detail, index)=>{
                                                    { candidateWiseCount = candidateWiseCount+1}
                                                    return (
                                                        <>
                                                            <tr className={detail.payrollBatchId && 'bg-yellow-400'} key={detail.job_candidate_time_sheet_id}>
                                                                <th className="border border-slate-300 relative whitespace-nowrap p-2 text-sm font-medium w-12">
                                                                    {detail.payrollBatchId===null &&
                                                                    <input
                                                                        {...register('assignCandidateId')}
                                                                        value={detail.job_candidate_time_sheet_id}
                                                                        type="checkbox"
                                                                        className={`w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 ${isError && "border-pink-500 ring-pink-500"}`}
                                                                        onClick={(e)=>handleAssignCandidateId(e, detail.job_candidate_time_sheet_id )}
                                                                    />
                                                                    }
                                                                </th>
                                                                {candidateWiseCount===1 &&
                                                                <td rowSpan={payrollInfo && payrollInfo.candidateDataLength[employeeId].length} className="border border-slate-300">{detail.employee_name}</td>
                                                                }

                                                                {/*<td className="border border-slate-300">{detail.employee_name}</td>*/}
                                                                <td className="border border-slate-300">{detail.job_title}</td>
                                                                <td className="border border-slate-300">
                                                                    {detail.check_in_time ? format(new Date(detail.check_in_time.date), 'dd, MMMM Y h:MM aa') : ''}
                                                                </td>
                                                                <td className="border border-slate-300">
                                                                    {detail.check_out_time ? format(new Date(detail.check_out_time.date), 'dd, MMMM Y h:MM aa') : ''}
                                                                </td>
                                                                <td className="border border-slate-300">
                                                                    {detail.hour ? detail.hour : ''}
                                                                </td>
                                                                <td className="border border-slate-300">
                                                                    {detail.rate ? detail.rate : ''}
                                                                </td>
                                                                {/*<td className="border border-slate-300">
                                                                    {detail.total_amount ? detail.total_amount : ''}
                                                                </td>*/}
                                                            </tr>


                                                        </>
                                                    )
                                                })}

                                            </>
                                        )
                                    })}
                                </>
                            )
                            }):
                            (
                                <tr>
                                    <td colSpan={8} align={"center"}>
                                        {t('NoRecordFound')}
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                        </div>
                        <footer className="relative mt-1">
                            <div className="container mx-auto justify-items-center">
                                <div className="text-right  pt-0.5 mb-0.5">
                                    <button
                                        type='reset'
                                        className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                    ><FiX size={16} className={'mr-2'}/>
                                        <span>{t('Cancel')}</span>
                                    </button>
                                    <button type='submit'
                                            className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-sm"
                                    >

                                        <span>{t('Save')}</span>
                                    </button>

                                </div>
                            </div>
                        </footer>

                    </form>
                        :''
                }
                </div>
            </main>
        </>
            )
}

export default PayrollSummery;