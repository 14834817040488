import React, { useState } from "react";
import { Switch } from "@headlessui/react";

const InputSwitch = ({ registerForm }) => {
    const [enabled, setEnabled] = useState(false);
    return (
        <div>
            <Switch
                name="status"
                {...registerForm}
                defaultChecked={enabled}
                onClick={() => setEnabled(!enabled)}
                className={`${enabled ? "bg-indigo-900" : "bg-indigo-300"}
relative inline-flex h-[24px] w-[64px] shrink-0 cursor-pointer  border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
                <span className="sr-only">Use setting</span>
                <span
                    aria-hidden="true"
                    className={`${enabled ? "translate-x-9" : "translate-x-0"}
pointer-events-none inline-block h-[20px] w-[24px] transform  bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
            </Switch>
        </div>
    );
};

export default InputSwitch;
