import React from "react";
import { HiOutlineExclamation } from "react-icons/hi";
const InlineTextarea = ({
    labelText,
    labelFor,
    errors,
    name,
    placeholder,
    registerForm,
}) => {
    return (
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
            <label
                htmlFor={labelFor}
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
                {labelText}
            </label>
            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                {errors.address?.type === "required" && (
                    <div className="flex bg-red-50 text-red-900 text-xs border border-1 border-red-300  py-1 px-2 right-0 absolute z-30 w-full -top-6">
                        <HiOutlineExclamation
                            size={16}
                            className={"mr-2"}
                        ></HiOutlineExclamation>{" "}
                        {errors.address?.message}
                    </div>
                )}
                <textarea
                    name={name}
                    {...registerForm}
                    type="text"
                    className={`form-control block w-full px-2 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none focus:ring-0 ${
                        errors.address
                            ? "border-red-300 focus:border-red-600"
                            : "border-gray-300 focus:border-blue-600"
                    } `}
                    id="address"
                    placeholder={placeholder}
                ></textarea>
            </div>
        </div>
    );
};

export default InlineTextarea;
