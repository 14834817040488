import React, {useState} from "react";
import {HiEye, HiOutlineOfficeBuilding, HiOutlinePlus, HiPencil, HiTrash} from "react-icons/hi";
import {ActionIcon, Box, Grid, Group, Text} from "@mantine/core";
import {AiOutlineFileSearch, AiOutlineLoading3Quarters, AiOutlineMinus} from "react-icons/ai";
import {DataTable} from "mantine-datatable";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {FiArrowDownLeft, FiArrowDownRight, FiSave, FiX} from "react-icons/fi";
const Step2 = (props) => {

    const { data, handleChange, next, back } = props;

    console.log(props)
    const notify = () => toast.success('Job created',{
        "position": 'top-center',
        "duration": 2000,
    });
    const {domainId, loggedUserId ,roles, token} = JSON.parse(localStorage.getItem('user'));
    const fullFormHeight = localStorage.getItem('fullFormHeight');
    const {t, i18n} = useTranslation();
    const [page, setPage] = useState(localStorage.getItem('item_page') ? localStorage.getItem('item_page') : 1);

    const [items, setItem] = useState([]);
    const [activeTab, setActiveTab] = useState('published');
    const [totalItem, setTotalItem] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [jobDetails, setJobDetails] = useState({});
    const fullTableHeight = localStorage.getItem('fullTableHeight');
    const [spinner, setSpinner] = useState(false);
    const navigate = useNavigate();
    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm();
    const { errors } = formState;

    return (
        <main className="w-full">
            <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                <div className={"flex-1"}>
                    <div
                        className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                        <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('JobPost')}
                            <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('JobPostRelatedInformation')}</sub>
                            </span>
                    </div>
                </div>
                <div className={"right flex mr-8"}>
                    <div className="flex items-center justify-center">
                        <div className="inline-flex border-1 border-red-300" role="group">
                            <Link to ='/job-post'
                                // className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs"
                                  className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                            >
                                <AiOutlineFileSearch size={12} className={'mr-1'}/>

                                {/*<HiOutlinePlus size={12} className={'mr-1'}/>*/}
                                {t('Jobs')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="min-h-full w-full">
                <div className="flex w-full md:w-auto">
                    <div className="flex-1">
                        <div className="min-w-0 flex-1 bg-white xl:flex">
                            <div className="bg-white lg:min-w-0 lg:flex-1">
                                <div className="h-full px-4 rounded sm:px-6 lg:px-4">
                                    <div className="relative">

                                        <div className={'flex flex-1 overflow-hidden w-full'}>
                                            <div className="w-full mr-0 mx-auto border">
                                                <form id="horizontal-form" >
                                                    <div className="px-8 py-3 relative flex flex-col min-w-0 break-words w-full border-0 inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full" style={{height:fullFormHeight}}>
                                                        {/*                  EDUCATION INFORMATION START                  */}
                                                        <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                            <div className="md:col-span-1">
                                                                <div className="px-4 sm:px-0">
                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("EducationInformation")}</h3>
                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                        {t('EducationInformationDetails')}
                                                                    </p>
                                                                </div>
                                                            </div>


                                                            <div className="mt-5 md:col-span-4 md:mt-0">
                                                                <div className="shadow sm:overflow-hidden sm:rounded">
                                                                    <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                                                        <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                            <div className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="school_name" className="form-input-sm-label">{t('SchoolName')}</label>
                                                                                <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        {...register("school_name")}
                                                                                        className={`form-input-sm-control`}
                                                                                        name="school_name"
                                                                                        placeholder={t("EnterSchoolName")}
                                                                                    />
                                                                                </div>
                                                                            </div>


                                                                            <div className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="school_address" className="form-input-sm-label">{t('SchoolAddress')}</label>
                                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        {...register("school_address")}
                                                                                        className={`form-input-sm-control`}
                                                                                        name="school_address"
                                                                                        placeholder={t("EnterSchoolAddress")}
                                                                                    />
                                                                                </div>
                                                                            </div>


                                                                            <div className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="college_name" className="form-input-sm-label">{t('CollegeName')}</label>
                                                                                <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        {...register("college_name")}
                                                                                        className={`form-input-sm-control`}
                                                                                        name="college_name"
                                                                                        placeholder={t("EnterCollegeName")}
                                                                                    />
                                                                                </div>
                                                                            </div>


                                                                            <div className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="college_address" className="form-input-sm-label">{t('CollegeAddress')}</label>
                                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        {...register("college_address")}
                                                                                        className={`form-input-sm-control`}
                                                                                        name="college_address"
                                                                                        placeholder={t("EnterCollegeAddress")}
                                                                                    />
                                                                                </div>
                                                                            </div>


                                                                            <div className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="additional_education" className="form-input-sm-label">{t('AdditionalEducation')}</label>
                                                                                <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        {...register("additional_education")}
                                                                                        className={`form-input-sm-control`}
                                                                                        name="additional_education"
                                                                                        placeholder={t("EnterAdditionalEducation")}
                                                                                    />
                                                                                </div>
                                                                            </div>


                                                                            <div className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="additional_education_address" className="form-input-sm-label">{t('AdditionalEducationAddress')}</label>
                                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        {...register("additional_education_address")}
                                                                                        className={`form-input-sm-control`}
                                                                                        name="additional_education_address"
                                                                                        placeholder={t("EnterAdditionalEducationAddress")}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="mb-3 w-full items-center">
                                                                                <label htmlFor="DidYouGraduate" className="form-input-sm-label">{t('DidYouGraduate')}</label>
                                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white rounded
 border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                                                                        <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                                                                            <div className="flex items-center pl-3">
                                                                                                <input
                                                                                                    {...register("did_you_graduate")}
                                                                                                    id="did_you_graduate_yes" type="radio" value="Yes" name="did_you_graduate" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                                                                                                <label htmlFor="did_you_graduate_yes" className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">{t('Yes')}</label>
                                                                                            </div>
                                                                                        </li>
                                                                                        <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                                                                                            <div className="flex items-center pl-3">
                                                                                                <input
                                                                                                    {...register("did_you_graduate")}

                                                                                                    id="did_you_graduate_no" type="radio" value="No" name="did_you_graduate" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                                                                                                <label htmlFor="did_you_graduate_no" className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">{t('No')}</label>
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*                  EDUCATION INFORMATION END                     */}


                                                        {/*                  REFERENCE INFORMATION START                  */}
                                                        <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                            <div className="md:col-span-1">
                                                                <div className="px-4 sm:px-0">
                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("ReferencesInformation")}</h3>
                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                        {t('ReferencesInformationDetails')}
                                                                    </p>
                                                                </div>
                                                            </div>


                                                            <div className="mt-5 md:col-span-4 md:mt-0">
                                                                <div className="shadow sm:overflow-hidden sm:rounded">
                                                                    <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                                                        <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                            <div className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="reference_name" className="form-input-sm-label">{t('Name')}</label>
                                                                                <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        {...register("reference_name")}
                                                                                        className={`form-input-sm-control`}
                                                                                        name="reference_name"
                                                                                        placeholder={t("EnterReferenceName")}
                                                                                    />
                                                                                </div>
                                                                            </div>


                                                                            <div className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="relation" className="form-input-sm-label">{t('Relation')}</label>
                                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        {...register("relation")}
                                                                                        className={`form-input-sm-control`}
                                                                                        name="relation"
                                                                                        placeholder={t("EnterRelation")}
                                                                                    />
                                                                                </div>
                                                                            </div>


                                                                            <div className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="reference_email" className="form-input-sm-label">{t('Email')}</label>
                                                                                <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        {...register("reference_email")}
                                                                                        className={`form-input-sm-control`}
                                                                                        name="reference_email"
                                                                                        placeholder={t("EnterEmail")}
                                                                                    />
                                                                                </div>
                                                                            </div>


                                                                            <div className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="reference_mobile" className="form-input-sm-label">{t('Mobile')}</label>
                                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        {...register("reference_mobile")}
                                                                                        className={`form-input-sm-control`}
                                                                                        name="reference_mobile"
                                                                                        placeholder={t("EnterMobileNumber")}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*                  REFERENCE INFORMATION END                     */}
                                                    </div>
                                                    <footer className="relative mt-1">
                                                        <div className="container mx-auto justify-items-center">
                                                            <div
                                                                className="text-right pt-0.5 mb-0.5">
                                                                <button onClick={back} type='reset'
                                                                        className={'px-6 py-2 inline-flex text-white  duration-150 ease-in-ou ml-3 bg-gray-400 hover:bg-gray-500 items-center  text-sm'}
                                                                ><FiArrowDownLeft size={16} className={'mr-2'}/>
                                                                    <span>{t('Back')}</span>
                                                                </button>
                                                                <button onClick={next}
                                                                        className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                    {spinner ? (
                                                                        <AiOutlineLoading3Quarters className={'mr-2 animate-spin'} size={16}/>
                                                                    ) : (<FiArrowDownRight size={16} className={'mr-2'}/>)}
                                                                    <span>{t('Next')}</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </footer>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};
export default Step2;
