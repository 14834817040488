import React, { useState, useEffect } from "react";
import {Link, useNavigate} from "react-router-dom";

import {
    HiOutlineExclamation,
    HiArrowCircleRight,
    HiOutlinePlus,
    HiOutlineUserAdd,
    HiOutlineUsers
} from "react-icons/hi";
import {AiOutlineLoading3Quarters, AiOutlineMinus} from "react-icons/ai";

import {useTranslation} from "react-i18next";
import { DataTable } from "mantine-datatable";
import { ActionIcon, Box, Group, Text, ScrollArea, LoadingOverlay, Select, Input, NumberInput } from "@mantine/core";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import {showNotification} from "@mantine/notifications";




function RegisterClient(){
    const {innerHeight} = window;

    const {domainId, loggedUserId, roles, token} = JSON.parse(localStorage.getItem('user'));
    const {t, i18n} = useTranslation();

    const navigate = useNavigate();

    const [countries, setCountries] = useState([]);
    const [cityData, setCityData] = useState('');
    const [cityDropdown, setCityDropdown] = useState([]);
    const [countryData, setCountryData] = useState('');
    const [citySearchValue, setCitySearchValue] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [designations, setDesignations] = useState([]);
    const [assignDesignations, setAssignDesignations] = useState([]);




    const getDesignations = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/master-data/dropdown`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "slug": "designation",
                "type": "normal"
            }
        })
            .then(res => {
                setDesignations(res.data.data);
            });
    }

    useEffect(() => {
        getDesignations();
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setCountries(res.data.data);
            });
    }, []);

    let countryDropdown =
        countries.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })
    

    const CountryDataHandel = (e) => {
        setCountryData(e);

        setCityDropdown([])
        setValue('city',null)
        setCityData('')

        setValue('country',e)

        e && setError("country", null)

        // if (e == '' || e == null){
        //     setError("country", Yup.string().required(t("ChooseCountry")).nullable() );
        //     // setError("country", Yup.string().required(t("ChooseCountry")).nullable() );
        //     // setError("country", { type: 'required', message: t("ChooseCountry") });
        // }else {
        //     // setError("country", null);
        // }
    }

    const CityDataHandelForSearch = (e) => {
        setCitySearchValue(e);
        if (citySearchValue.length >1){
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/city/dropdown-with-keyword`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                params: { country_id: countryData, keyword: citySearchValue}
            })
                .then(res => {
                    if (res.status === 200) {
                        let data =
                            res.data.data.map((head, index) => {
                                return ({
                                    'label': head.name, 'value': head.id
                                })
                            })
                        setCityDropdown(data);
                    }
                })
        }
    }

    const CityDataHandel = (e) => {
        setCityData(e);
        setValue('city',e)

        e && setError("city", null)

        // if (e == '' || e == null){
        //     // setError("city", Yup.string().required(t("ChooseCity")).nullable() );
        //     setError("city", { type: 'required', message: t("ChooseCity") });
        // }else {
        //     // setError("city", null);
        // }
    }
    const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    const onlyNumber = /^[0-9]+$/;
    const phoneNumberRegex = /^[\+]?[1]?[-\s]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;


    const validationSchemaFirstStep = Yup.object().shape({
        companyName: Yup.string().required(t("EnterCompanyName")),
        email: Yup.string().email(t('EnterValidEmail')).required(t("EnterEmail")),
        phone: Yup.string().required(t("EnterMobileNumber")).matches(phoneNumberRegex, t("PhoneNumberNotValid")),
        address: Yup.string().required(t("EnterAddress")),
        country: Yup.string().required(t("ChooseCountry")).nullable(),
        city: Yup.string().required(t("ChooseCity")).nullable(),
        zipCode: Yup.string().required(t("EnterZipCode")).matches(onlyNumber, t("InvalidZipCode")).nullable(),
    });
    let formOptions = {resolver: yupResolver(validationSchemaFirstStep)};
    const {register, handleSubmit, reset, formState, setValue, setError, clearErrors, getValues} = useForm(formOptions);
    const {errors} = formState;

    function phoneNumberValidation(event){
        if(! phoneNumberRegex.test(event.target.value)){
            setError("phone", {type: 'validation', message: t('InvaliedPhoneNumber')});
            // console.log(errors);
        }else{
            clearErrors('phone');
        }
    }
    

    const [position, setPosition] = useState('');
    const [rate, setRate] = useState(1);
    const addPosition = () => {
        if(! position){
            setError('position', {type: "required", message: "Select Position"});
            return;
        };
        if(!rate || rate <= 0){
            setError('rate', {type: "required", message: "Enter Rate"});
            return;
        }
        
        const findElementIndex = assignDesignations.findIndex(item => item.name == position);
        if(findElementIndex >= 0){
            assignDesignations.splice(findElementIndex, 1);
            // setAssignDesignations([...assignDesignations]);
        }
        assignDesignations.push( {name: position, rate } );
        setAssignDesignations([...assignDesignations]);
        clearErrors("position");
        clearErrors("rate");
        setPosition('');
        setRate(1);

    }

    function formSubmit(data) {

        delete data['position'];
        delete data['rate'];

        data['entry_from'] = 'CRM';
        data['positions'] = assignDesignations;
        data['clientInfo'] = [
            {first_name: null, middle_name: null, last_name: null, phone: null, email: null, position: null, flag: 'contact-person'},
            {first_name: null, middle_name: null, last_name: null, phone: null, email: null, position: null, flag: 'person-in-charge'},
            {first_name: null, middle_name: null, last_name: null, phone: null, email: null,position: null, flag: 'person-in-charge-billing'},
            {bank_name: null, branch_name: null, phone: null, email: null, address: null, website: null, currency: null, flag: 'billing'}
        ]

        setShowLoader(true);

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/register-client`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: data
        })
            .then(res => {
                if (res.status === 201) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Success')),
                        message: (t('RegistrationSuccessful')),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'green',
                    });
                    navigate('/crm');
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('error')),
                        message: res.response.data.message,
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'red',
                    });
                }
                setShowLoader(false);
            })
            .catch(function (error) {})
    }

    return(
        <main className="w-full">
            <div className="mx-auto px-6 bg-indigo-50 pb-4" >
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mb-2 pt-6">
                    <div className="overflow-hidden rounded-md bg-indigo-800 text-white relative">
                        <Link to={"/crm/register-client"} className="cursor-pointer">
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="">
                                        <HiOutlineUserAdd
                                            className="h-5 w-5 text-white hover:text-indigo-400"
                                            aria-hidden="true"/>
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="truncate text-sm font-medium">{t('NewClient')}</dt>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="overflow-hidden rounded-md bg-white hover:bg-indigo-100 relative">
                        <Link to={"/crm/register-candidate"} className="cursor-pointer">
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="">
                                        <HiOutlineUserAdd
                                            className="h-5 w-5 text-indigo-500 hover:text-indigo-400"
                                            aria-hidden="true"/>
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="truncate text-sm font-medium text-gray-500">{t('NewCandidate')}</dt>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="overflow-hidden rounded-md bg-white hover:bg-indigo-100 relative">
                        <Link to={"/crm/client"} className="cursor-pointer">
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="">
                                        <HiOutlineUsers className="h-5 w-5 text-indigo-500 hover:text-indigo-400" aria-hidden="true"/>
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="truncate text-sm font-medium text-gray-500">{t('Clients')}</dt>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="overflow-hidden rounded-md bg-white hover:bg-indigo-100 relative">
                        <Link to={"/crm/candidate"} className="cursor-pointer">
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="">
                                        <HiOutlineUsers className="h-5 w-5 text-indigo-500 hover:text-indigo-400" aria-hidden="true"/>
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="truncate text-sm font-medium text-gray-500">{t('Candidates')}</dt>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                                
                </div>
                <div className="w-full relative">
                    { <LoadingOverlay visible={showLoader} overlayBlur={2} loaderProps={{ size: 'sm', color: 'indigo' }} overlayColor="bg-indigo-100"/> }

                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                        <ScrollArea h={innerHeight - 200} type="always" scrollbarSize={4}>
                            <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full bg-gray-200">
                                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                    <div className="md:col-span-1">
                                        <div className="px-4 sm:px-0 ml-2">
                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("CompanyInformation")}</h3>
                                            <p className="mt-1 text-sm text-gray-600">
                                                {t('CompanyDetails')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-5 md:col-span-4 md:mt-0">
                                        <div className="shadow     sm:rounded-md">
                                            <div className="bg-gray-50 px-4 py-5 sm:p-6">
                                                <div className="mb-3 w-full items-center">
                                                    <label htmlFor="CompanyName" className="form-input-sm-label-required">{t('CompanyName')}</label>
                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                        {
                                                        errors.companyName?.type === 'required' &&
                                                            <div className="form-input-sm-error">
                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.companyName?.message}
                                                            </div>
                                                        }
                                                        <Input
                                                            type="text"
                                                            {...register("companyName")}
                                                            name="companyName"
                                                            id="companyName"
                                                            placeholder={t("EnterCompanyName")}
                                                            autoComplete="off"

                                                        />

                                                    </div>
                                                </div>
                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                    <div className="mb-3 w-1/2 items-center">
                                                        <label htmlFor="phone" className="form-input-sm-label-required">{t('Phone')}</label>
                                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                            {
                                                                errors.phone &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.phone?.message}
                                                                    </div>
                                                            }

                                                            <Input
                                                                {...register("phone")}
                                                                type="text"
                                                                name="phone"
                                                                id="phone"
                                                                placeholder={t("EnterPhoneNumber")}
                                                                onChange={event => phoneNumberValidation(event)}
                                                                autoComplete="off"

                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mb-3 w-1/2 items-center">
                                                        <label htmlFor="email" className="form-input-sm-label-required">{t('Email')}</label>
                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                            {
                                                                errors.email &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.email?.message}
                                                                    </div>
                                                            }

                                                            <Input
                                                                {...register("email")}
                                                                type="text"
                                                                name="email"
                                                                id="email"
                                                                placeholder={t("EnterEmail")}
                                                                autoComplete="off"

                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                    <div className="md:col-span-1">
                                        <div className="px-4 sm:px-0 ml-3">
                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("Position")}</h3>
                                            <p className="mt-1 text-sm text-gray-600"> {t('PositionAndRate')} </p>
                                        </div>
                                    </div>

                                    <div className="mt-5 md:col-span-4 md:mt-0">
                                        <div className="shadow sm:rounded">
                                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                    <div className=" w-4/6 items-center">
                                                        <label htmlFor="position" className="form-input-sm-label-required">{t('Position')}</label>
                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0 mr-3">
                                                            {
                                                                errors.position &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.position.message}
                                                                    </div>
                                                            }

                                                            <Select
                                                                {...register("position")}
                                                                placeholder={t('ChoosePosition')}
                                                                className={`${errors.country ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                searchable clearable
                                                                allowDeselect
                                                                nothingFound="No options"
                                                                data={designations.map(designation => ({label: designation['name'], value: designation['slug']}))}
                                                                transition="pop-top-left"
                                                                transitionDuration={80}
                                                                transitionTimingFunction="ease"
                                                                onChange={e => setPosition(e)}
                                                                value={position}
                                                                
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className=" w-1/6 items-center">
                                                        <label htmlFor="rate" className="form-input-sm-label-required">{t('Rate')}</label>
                                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                            {
                                                                errors.rate &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.rate.message}
                                                                    </div>
                                                            }
                                                            <NumberInput
                                                                {...register("rate")}
                                                                id="rate"
                                                                placeholder={t("Rate")}
                                                                autoComplete="off"
                                                                onChange={e => setRate(e)}
                                                                min={1}
                                                                value={rate}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="w-1/6">
                                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                            <div className="mt-8 flex items-center justify-right">
                                                                <button
                                                                type="button"
                                                                className="disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-700 disabled:hover:bg-gray-300 px-2 py-1.5 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-sm"
                                                                onClick={addPosition}
                                                            >
                                                                <HiOutlinePlus className={'mr-1'} /> <span>{t('Add')}</span>
                                                                    
                                                            </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ScrollArea h={150} scrollbarSize={4} className="w-full">
                                                    <table className="min-w-full divide-y divide-gray-300 border border-gray-200">
                                                        <thead className="bg-gray-50">
                                                            <tr>
                                                                <th scope="col" className="py-1 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t('Designation')} </th>
                                                                <th scope="col" className="py-1 text-left text-sm font-semibold text-gray-900">{t('Rate')} </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200 bg-white relative">
                                                            {
                                                                assignDesignations.map((assignDesignation, index) => (
                                                                <tr key={index}>
                                                                    <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm sm:pl-6">{assignDesignation['name'].charAt(0).toUpperCase() + assignDesignation['name'].slice(1)}</td>
                                                                    <td className="whitespace-nowrap py-1 pr-3 text-sm">{assignDesignation['rate']}</td>
                                                                </tr>
                                                            ))
                                                            }
                                                            
                                                        </tbody>
                                                    </table>
                                                </ScrollArea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                    <div className="md:col-span-1">
                                        <div className="px-4 sm:px-0 ml-2">
                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("CompanyLocation")}</h3>
                                            <p className="mt-1 text-sm text-gray-600">{t('CompanyLocationDetails')}</p>
                                        </div>
                                    </div>
                                    <div className="mt-5 md:col-span-4 md:mt-0">
                                        <div className="shadow     sm:rounded-md">
                                            <div className="bg-gray-50 px-4 py-5 sm:p-6">
                                                <div className="mb-3 w-full items-center">
                                                    <label htmlFor="address" className="form-input-sm-label-required">{t('CompanyAddress')}</label>
                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                        {
                                                            errors.address &&
                                                                <div className="form-input-sm-error">
                                                                    <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.address?.message}
                                                                </div>
                                                        }
                                                        <textarea
                                                            {...register("address")}
                                                            className={`form-input-sm-control ${errors.address ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'}`}
                                                            name="address"
                                                            placeholder={t("EnterAddress")}
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                    <div className="mb-3 w-1/3 items-center">
                                                        <label htmlFor="country" className="form-input-sm-label-required">{t('Country')}</label>
                                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                            {
                                                                errors.country?.type === "required" &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.country?.message}
                                                                    </div>
                                                            }

                                                            <Select
                                                                {...register("country")}
                                                                placeholder={t('ChooseCountry')}
                                                                className={`${errors.country ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                searchable
                                                                clearable
                                                                allowDeselect
                                                                nothingFound="No options"
                                                                data={countryDropdown}
                                                                transition="pop-top-left"
                                                                transitionDuration={80}
                                                                transitionTimingFunction="ease"
                                                                onChange={CountryDataHandel}
                                                                value={countryData}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 w-1/3 items-center">
                                                        <label htmlFor="city" className="form-input-sm-label-required">{t('City')}</label>
                                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                            {
                                                                errors.city?.type === "required" &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.city?.message}
                                                                    </div>
                                                            }

                                                            <Select
                                                                {...register("city")}
                                                                placeholder={t("ChooseCity")}
                                                                className={`${errors.city ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                searchable clearable
                                                                allowDeselect
                                                                nothingFound={t('PleaseTypeCityName')}
                                                                withAsterisk
                                                                data={cityDropdown}
                                                                transition="pop-top-left"
                                                                transitionDuration={80}
                                                                transitionTimingFunction="ease"
                                                                onSearchChange={CityDataHandelForSearch}
                                                                onChange={e =>  CityDataHandel(e)}
                                                                value={cityData}
                                                                searchValue={citySearchValue}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 w-1/3 items-center">
                                                        <label htmlFor="zipCode" className="form-input-sm-label-required">{t('ZipCode')}</label>
                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                            {
                                                                errors.zipCode &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.zipCode?.message}
                                                                    </div>
                                                            }
                                                            <Input
                                                                {...register("zipCode")}
                                                                type="text"
                                                                name="zipCode"
                                                                id="zipCode"
                                                                placeholder={t("EnterZipCode")}
                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ScrollArea>

                        <footer className="relative mt-1">
                            <div className="mr-3">
                                <div className="text-right pt-0.5 mb-0.5">

                                    <button type='submit' className="inline-flex items-center rounded bg-green-700 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-0">
                                        {showLoader ? (
                                            <AiOutlineLoading3Quarters
                                                className={'mr-2 animate-spin'}
                                                size={16}/>
                                        ) : (<HiOutlinePlus size={16} className={'mr-2'}/>)}
                                        <span>{t('Register')}</span>
                                    </button>
                                </div>
                            </div>
                        </footer>
                    </form>
                </div>
            </div>
        </main>
    )
}

export default RegisterClient