import {Document, Page, PDFViewer, Text, View, Image} from "@react-pdf/renderer";
import styles from "../../../pdf-styles";
import {Grid, ScrollArea} from "@mantine/core";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {HiChevronLeft, HiEye, HiOutlineOfficeBuilding} from "react-icons/hi";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import axios from "axios";
import {useLayoutEffect} from "@radix-ui/react-use-layout-effect";
import {useLocation} from "react-router";


function InvoicePdf() {

    const {domainId, loggedUserId ,roles, token} = JSON.parse(localStorage.getItem('user'));
    // const { state } = props.viewMode;
    let location = useLocation();
    const paramsState = location.state;

    const viewMode = paramsState ? paramsState.viewMode : '';


    const {invoiceId} = useParams();
    const {t, i18n} = useTranslation();
    const fullTableHeight = localStorage.getItem('fullTableHeight');
    const [invoiceDetails, setInvoiceDetails] = useState({});

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/invoice/${invoiceId}/show`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                if (res.status === 200){
                    setInvoiceDetails(res.data.data)
                }
            });
    }, [])
    var lineTotalAmount = invoiceDetails.subTotalAmount;
    var lineTotalAmountDecimalHour = invoiceDetails.subTotalDecimalHourAmount>0?invoiceDetails.subTotalDecimalHourAmount:0;
    var taxRate = invoiceDetails.taxRate?invoiceDetails.taxRate:0;
    var taxAmount = ((lineTotalAmount*taxRate)/100).toFixed(2);
    var taxAmountDecimalHour = ((lineTotalAmountDecimalHour*taxRate)/100).toFixed(2);
    return (
        <Fragment>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Invoice')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>
                                    {t('InvoiceInformation')}
                                </sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <Link to={'/invoice/'+invoiceDetails.id+'/view'}>
                                    <button
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    >
                                        <HiEye size={16} className={'mr-1'}/>{t('ViewInvoice')}
                                    </button>
                                </Link>
                                <Link to='/invoice'
                                      className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={12} className={'mr-1'}/>{t('Back')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <PDFViewer
                    showToolbar={true}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Document>
                        <Page orientation='p' size='A4' style={styles.page}>

                            <View style={[styles.width_100, {width:'100%', display: 'flex', clear: 'both', flexDirection: "row"}]}>
                                <View style={styles.section_left}>
                                    <View>
                                        <View>
                                            <Text>
                                                <Image style={styles.image} src={"/assets/images/simple-staffing.png"} alt="Simple Staffing" />
                                                {/*<img src={"/assets/images/simple-staffing.png"} alt="Simple Staffing" />*/}
                                            </Text>
                                        </View>
                                        <View>
                                            <Text style={[styles.text_12, {marginBottom:'5px'}]}>SIMPLE STAFFING USA LLC</Text>
                                        </View>
                                        <View>
                                            <Text style={styles.text_10}>
                                                3235 Satellite Blvd. Building 400
                                            </Text>
                                            <Text style={styles.text_10}>Suite 300 Duluth, GA</Text>
                                            <Text style={styles.text_10}>{t('Phone')}: 1.888.902.7759</Text>
                                            <Text style={styles.text_10}>{t('Email')}: billing@simplestaffingllc.com</Text>
                                        </View>
                                    </View>

                                    <View>
                                        <View style={[{ marginTop: '15px' }]}>
                                            <Text style={[styles.text_12, {marginBottom:'5px'}]}>BILL TO:</Text>
                                            <Text style={[styles.text_12, {marginBottom:'5px'}]}>{invoiceDetails.clientName}</Text>
                                        </View>
                                        <View>
                                            <Text style={styles.text_10}>
                                                {invoiceDetails.clientAddress}
                                            </Text>
                                            <Text style={styles.text_10}>
                                                Phone: {invoiceDetails.clientPhone}
                                            </Text>
                                            <Text style={styles.text_10}>
                                                Email: {invoiceDetails.clientEmail}
                                            </Text>
                                        </View>
                                    </View>

                                </View>
                                <View style={styles.section_right}>
                                    <View>
                                        <View style={styles.text_16}>
                                            <Text style={[styles.textAlignCenter, styles.text_16, {marginBottom: '10px', color: 'red'}]}>
                                                {t('INVOICE')}
                                            </Text>
                                        </View>
                                        <View style={[styles.tableNoBorder, styles.table_100]}>
                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableColNoBorder, styles.width_40]}>
                                                    <Text style={[styles.tableCell, styles.textAlignRight]}>{t('INVOICE NO')} : </Text>
                                                </View>
                                                <View style={[styles.tableColNoBorder, styles.width_60]}>
                                                    <Text style={[styles.tableCell, styles.textAlignLeft]}>{invoiceDetails.invoiceId}</Text>
                                                </View>
                                            </View>

                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableColNoBorder, styles.width_40]}>
                                                    <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                        {t('Date')} :
                                                    </Text>
                                                </View>
                                                <View style={[styles.tableColNoBorder, styles.width_60]}>
                                                    <Text style={[styles.tableCell, styles.textAlignLeft]}>
                                                        {invoiceDetails.invoiceDate ? format(new Date(invoiceDetails.invoiceDate.date), 'MMMM dd, Y') : ''}
                                                    </Text>
                                                </View>
                                            </View>

                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableColNoBorder, styles.width_40]}>
                                                    <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                        {t('InvoicePeriod')} :
                                                    </Text>
                                                </View>
                                                <View style={[styles.tableColNoBorder, styles.width_60]}>
                                                    <Text style={[styles.tableCell, styles.textAlignLeft]}>
                                                        {invoiceDetails.startTime ? format(new Date(invoiceDetails.startTime.date), 'MMM dd, Y') : ''} To  {invoiceDetails.endTime ? format(new Date(invoiceDetails.endTime.date), 'MMM dd, Y') : ''}
                                                    </Text>
                                                </View>
                                            </View>

                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableColNoBorder, styles.width_40]}>
                                                    <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                        {t('InvoiceDueDate')} :
                                                    </Text>
                                                </View>
                                                <View style={[styles.tableColNoBorder, styles.width_60]}>
                                                    <Text style={[styles.tableCell, styles.textAlignLeft]}>
                                                        {invoiceDetails.dueDate ? format(new Date(invoiceDetails.dueDate.date), 'MMMM dd, Y') : ''}
                                                    </Text>
                                                </View>
                                            </View>

                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={[styles.width_100, {display: 'block', clear: 'both', marginTop: '20px'}]}>
                                { viewMode === 'decimal' &&
                                    <View style={[styles.table, styles.width_100, styles.textAlignLeft]}>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_16, styles.colPositionRelative]}>

                                                <Text style={[styles.tableCell, styles.textAlignCenter, styles.colPositionAbsolute, styles.colPositionTop45]}>
                                                    {t('CandidateName')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_90]}>

                                                <View style={[styles.tableRow, styles.width_100]}>

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('JobTitle')}</Text>*/}
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_11, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('JobTitle')}</Text>*/}
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Designation')}</Text>*/}
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('RegularRate')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('OvertimeRate')}</Text>
                                                    </View>


                                                    <View style={[styles.tableCol, styles.width_6, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Total Hours')}</Text>*/}
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Regular')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Overtime')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder, styles.tableColRightNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('TotalAmount')}</Text>*/}
                                                    </View>
                                                </View>

                                                <View style={[styles.tableRow, styles.width_100]}>


                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Date')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_11, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('JobTitle')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Designation')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                   {/* <View style={[styles.tableCol, styles.width_6, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>*/}
                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    {/*<View style={[styles.tableCol, styles.width_6, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>*/}

                                                    <View style={[styles.tableCol, styles.width_6, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Total Hours')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    {/*<View style={[styles.tableCol, styles.width_6, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>*/}
                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    {/*<View style={[styles.tableCol, styles.width_6, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>*/}

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder, styles.tableColRightNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('TotalAmount')}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>



                                        {invoiceDetails && invoiceDetails.details && Object.keys(invoiceDetails.details).map((detailsKey, index) => {


                                            return (
                                                <Fragment key={index}>
                                                    {detailsKey==='invoiceDetails' && Object.keys(invoiceDetails.details[detailsKey]).map((employeeId)=> {

                                                        {var candidateWiseCount = 0}
                                                        {var lineTotalHour = 0}
                                                        {var lineTotalMin = 0}
                                                        {var countOvertimeHour = 0}
                                                        {var dataLength = invoiceDetails && invoiceDetails.details && invoiceDetails.details['candidateDataLength'] && Object.values(invoiceDetails.details['candidateDataLength'][employeeId]) ? Object.values(invoiceDetails.details['candidateDataLength'][employeeId]).length:''}
                                                        {var candidateNameLength = invoiceDetails && invoiceDetails.details['candidateInfo'] && invoiceDetails.details['candidateInfo'][employeeId]?invoiceDetails.details['candidateInfo'][employeeId]['candidateName'].length:''}
                                                        return (
                                                            <Fragment key={employeeId}>
                                                                <View style={[styles.tableRow]}>
                                                                    <View style={[styles.tableCol, styles.width_16, styles.colPositionRelative]}>

                                                                        <Text style={[styles.tableCell, styles.textAlignCenter, dataLength>1? styles.colPositionAbsolute:styles.colPositionRelative, dataLength>1?styles.colPositionTop35:'']}>

                                                                            {invoiceDetails && invoiceDetails.details['candidateInfo'] && invoiceDetails.details['candidateInfo'][employeeId]?invoiceDetails.details['candidateInfo'][employeeId]['candidateName']:''}
                                                                        </Text>
                                                                    </View>
                                                                    <View style={[styles.tableCol, styles.width_90]}>

                                                                        {invoiceDetails.details[detailsKey][employeeId] && Object.keys(invoiceDetails.details[detailsKey][employeeId]).map((checkInTime, index)=>{

                                                                            return (
                                                                                <Fragment key={index}>
                                                                                    {invoiceDetails.details[detailsKey][employeeId][checkInTime] && Object.values(invoiceDetails.details[detailsKey][employeeId][checkInTime]).map((element, index)=>{
                                                                                        { candidateWiseCount = candidateWiseCount+1}


                                                                                        {var candidateDataLength = invoiceDetails && invoiceDetails.details && invoiceDetails.details['candidateDataLength'] && Object.values(invoiceDetails.details['candidateDataLength'][employeeId]) ? Object.values(invoiceDetails.details['candidateDataLength'][employeeId]).length:''}

                                                                                        return(
                                                                                            <Fragment key={index}>
                                                                                                <View style={[styles.tableRow, styles.width_100]}>
                                                                                                    <View style={[
                                                                                                        styles.tableCol,
                                                                                                        styles.width_12,
                                                                                                        candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'',
                                                                                                        dataLength===1 && candidateNameLength > 15 && candidateNameLength < 30 ? styles.min_height_30:'',
                                                                                                        dataLength===1 && candidateNameLength > 30 && candidateNameLength < 45 ? styles.min_height_50:'',
                                                                                                        dataLength===1 && candidateNameLength > 45 && candidateNameLength < 60 ? styles.min_height_75:''
                                                                                                    ]}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {checkInTime}
                                                                                                        </Text>
                                                                                                    </View>

                                                                                                    <View style={[styles.tableCol, styles.width_11, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.jobTitle}
                                                                                                        </Text>
                                                                                                    </View>
                                                                                                    <View style={[styles.tableCol, styles.width_12, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.designationName}
                                                                                                        </Text>
                                                                                                    </View>

                                                                                                    <View style={[styles.tableCol, styles.width_12, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.regularHourRate}
                                                                                                        </Text>
                                                                                                    </View>
                                                                                                    {/*<View style={[styles.tableCol, styles.width_6, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.regularMinRate}
                                                                                                        </Text>
                                                                                                    </View>*/}
                                                                                                    <View style={[styles.tableCol, styles.width_12, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.overtimeHourRate}
                                                                                                        </Text>
                                                                                                    </View>
                                                                                                    {/*<View style={[styles.tableCol, styles.width_6, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.overtimeMinRate}
                                                                                                        </Text>
                                                                                                    </View>*/}

                                                                                                    <View style={[styles.tableCol, styles.width_6, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.minuteFormatToHourFormat}
                                                                                                        </Text>
                                                                                                    </View>

                                                                                                    <View style={[styles.tableCol, styles.width_12, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.regularHourMinToDecimal}
                                                                                                        </Text>
                                                                                                    </View>
                                                                                                    {/*<View style={[styles.tableCol, styles.width_6, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.regularMin}
                                                                                                        </Text>
                                                                                                    </View>*/}
                                                                                                    <View style={[styles.tableCol, styles.width_12, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.overtimeHourMinToDecimal}
                                                                                                        </Text>
                                                                                                    </View>
                                                                                                    {/*<View style={[styles.tableCol, styles.width_6, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.overtimeMin}
                                                                                                        </Text>
                                                                                                    </View>*/}

                                                                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColRightNoBorder, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                                                                            {element.amountDecimalHour>0?element.amountDecimalHour:((element.regularHourMinToDecimal*element.regularHourRate)+(element.overtimeHourMinToDecimal*element.overtimeHourRate)).toFixed(2)}
                                                                                                        </Text>
                                                                                                    </View>
                                                                                                </View>
                                                                                            </Fragment>
                                                                                        )


                                                                                    })
                                                                                    }
                                                                                </Fragment>
                                                                            )
                                                                        })}
                                                                    </View>

                                                                </View>


                                                            </Fragment>
                                                        )

                                                    })}
                                                </Fragment>
                                            )

                                        })}


                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_70, {borderWidth: '1px'}]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('TotalInvoiceHours')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_6]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {invoiceDetails.totalHoursMinuteFormatToHourFormat}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_22, {borderWidth: '1px'}]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('TotalInvoiceAmount')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_11]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {lineTotalAmountDecimalHour && lineTotalAmountDecimalHour>0?lineTotalAmountDecimalHour.toFixed(2):''}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_90]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('Tax')} ({invoiceDetails.taxRate?invoiceDetails.taxRate:0}%)
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_10]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    $ {taxAmountDecimalHour}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_90]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('Total')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_10]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    $ {(lineTotalAmountDecimalHour+Number(taxAmountDecimalHour)).toFixed(2)}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_90]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('PaymentMade')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_10]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight, {color:'red'}]}>
                                                    (-) $ {invoiceDetails.totalPaymentAmount}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_90]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('BalanceDue')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_10]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    $ {((lineTotalAmountDecimalHour+Number(taxAmountDecimalHour))-invoiceDetails.totalPaymentAmount).toFixed(2)}
                                                </Text>
                                            </View>
                                        </View>

                                    </View>
                                }

                                { viewMode === 'hour' &&
                                    <View style={[styles.table, styles.width_100, styles.textAlignLeft]}>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_16, styles.colPositionRelative]}>

                                                <Text style={[styles.tableCell, styles.textAlignCenter, styles.colPositionAbsolute, styles.colPositionTop45]}>
                                                    {t('CandidateName')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_90]}>

                                                <View style={[styles.tableRow, styles.width_100]}>

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('JobTitle')}</Text>*/}
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_11, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('JobTitle')}</Text>*/}
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Designation')}</Text>*/}
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('RegularRate')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('OvertimeRate')}</Text>
                                                    </View>


                                                    <View style={[styles.tableCol, styles.width_6, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Total Hours')}</Text>*/}
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Regular')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Overtime')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder, styles.tableColRightNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('TotalAmount')}</Text>*/}
                                                    </View>
                                                </View>

                                                <View style={[styles.tableRow, styles.width_100]}>


                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Date')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_11, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('JobTitle')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Designation')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_6, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_6, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_6, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_6, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_6, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Total Hours')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_6, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_6, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_6, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_6, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder, styles.tableColRightNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('TotalAmount')}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>



                                        {invoiceDetails && invoiceDetails.details && Object.keys(invoiceDetails.details).map((detailsKey, index) => {


                                            return (
                                                <Fragment key={index}>
                                                    {detailsKey==='invoiceDetails' && Object.keys(invoiceDetails.details[detailsKey]).map((employeeId)=> {

                                                        {var candidateWiseCount = 0}
                                                        {var lineTotalHour = 0}
                                                        {var lineTotalMin = 0}
                                                        {var countOvertimeHour = 0}
                                                        {var dataLength = invoiceDetails && invoiceDetails.details && invoiceDetails.details['candidateDataLength'] && Object.values(invoiceDetails.details['candidateDataLength'][employeeId]) ? Object.values(invoiceDetails.details['candidateDataLength'][employeeId]).length:''}
                                                        {var candidateNameLength = invoiceDetails && invoiceDetails.details['candidateInfo'] && invoiceDetails.details['candidateInfo'][employeeId]?invoiceDetails.details['candidateInfo'][employeeId]['candidateName'].length:''}
                                                        return (
                                                            <Fragment key={employeeId}>
                                                                <View style={[styles.tableRow]}>
                                                                    <View style={[styles.tableCol, styles.width_16, styles.colPositionRelative]}>

                                                                        <Text style={[styles.tableCell, styles.textAlignCenter, dataLength>1? styles.colPositionAbsolute:styles.colPositionRelative, dataLength>1?styles.colPositionTop35:'']}>

                                                                            {invoiceDetails && invoiceDetails.details['candidateInfo'] && invoiceDetails.details['candidateInfo'][employeeId]?invoiceDetails.details['candidateInfo'][employeeId]['candidateName']:''}
                                                                        </Text>
                                                                    </View>
                                                                    <View style={[styles.tableCol, styles.width_90]}>

                                                                        {invoiceDetails.details[detailsKey][employeeId] && Object.keys(invoiceDetails.details[detailsKey][employeeId]).map((checkInTime, index)=>{

                                                                            return (
                                                                                <Fragment key={index}>
                                                                                    {invoiceDetails.details[detailsKey][employeeId][checkInTime] && Object.values(invoiceDetails.details[detailsKey][employeeId][checkInTime]).map((element, index)=>{
                                                                                        { candidateWiseCount = candidateWiseCount+1}


                                                                                        {var candidateDataLength = invoiceDetails && invoiceDetails.details && invoiceDetails.details['candidateDataLength'] && Object.values(invoiceDetails.details['candidateDataLength'][employeeId]) ? Object.values(invoiceDetails.details['candidateDataLength'][employeeId]).length:''}

                                                                                        return(
                                                                                            <Fragment key={index}>
                                                                                                <View style={[styles.tableRow, styles.width_100]}>
                                                                                                    <View style={[
                                                                                                        styles.tableCol,
                                                                                                        styles.width_12,
                                                                                                        candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'',
                                                                                                        dataLength===1 && candidateNameLength > 15 && candidateNameLength < 30 ? styles.min_height_30:'',
                                                                                                        dataLength===1 && candidateNameLength > 30 && candidateNameLength < 45 ? styles.min_height_50:'',
                                                                                                        dataLength===1 && candidateNameLength > 45 && candidateNameLength < 60 ? styles.min_height_75:''
                                                                                                    ]}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {checkInTime}
                                                                                                        </Text>
                                                                                                    </View>

                                                                                                    <View style={[styles.tableCol, styles.width_11, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.jobTitle}
                                                                                                        </Text>
                                                                                                    </View>
                                                                                                    <View style={[styles.tableCol, styles.width_12, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.designationName}
                                                                                                        </Text>
                                                                                                    </View>

                                                                                                    <View style={[styles.tableCol, styles.width_6, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.regularHourRate}
                                                                                                        </Text>
                                                                                                    </View>
                                                                                                    <View style={[styles.tableCol, styles.width_6, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.regularMinRate}
                                                                                                        </Text>
                                                                                                    </View>
                                                                                                    <View style={[styles.tableCol, styles.width_6, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.overtimeHourRate}
                                                                                                        </Text>
                                                                                                    </View>
                                                                                                    <View style={[styles.tableCol, styles.width_6, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.overtimeMinRate}
                                                                                                        </Text>
                                                                                                    </View>

                                                                                                    <View style={[styles.tableCol, styles.width_6, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.hourForDisplay}
                                                                                                        </Text>
                                                                                                    </View>

                                                                                                    <View style={[styles.tableCol, styles.width_6, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.regularHour}
                                                                                                        </Text>
                                                                                                    </View>
                                                                                                    <View style={[styles.tableCol, styles.width_6, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.regularMin}
                                                                                                        </Text>
                                                                                                    </View>
                                                                                                    <View style={[styles.tableCol, styles.width_6, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.overtimeHour}
                                                                                                        </Text>
                                                                                                    </View>
                                                                                                    <View style={[styles.tableCol, styles.width_6, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                            {element.overtimeMin}
                                                                                                        </Text>
                                                                                                    </View>

                                                                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColRightNoBorder, candidateDataLength===candidateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                                        <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                                                                            {element.amount}
                                                                                                        </Text>
                                                                                                    </View>
                                                                                                </View>
                                                                                            </Fragment>
                                                                                        )


                                                                                    })
                                                                                    }
                                                                                </Fragment>
                                                                            )
                                                                        })}
                                                                    </View>

                                                                </View>


                                                            </Fragment>
                                                        )

                                                    })}
                                                </Fragment>
                                            )

                                        })}


                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_70, {borderWidth: '1px'}]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('TotalInvoiceHours')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_6]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {invoiceDetails.totalHoursForDisplay}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_22, {borderWidth: '1px'}]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('TotalInvoiceAmount')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_11]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {lineTotalAmount}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_90]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('Tax')} ({invoiceDetails.taxRate?invoiceDetails.taxRate:0}%)
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_10]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    $ {taxAmount}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_90]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('Total')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_10]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    $ {(lineTotalAmount+Number(taxAmount)).toFixed(2)}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_90]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('PaymentMade')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_10]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight, {color:'red'}]}>
                                                    (-) $ {invoiceDetails.totalPaymentAmount}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_90]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('BalanceDue')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_10]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    $ {((lineTotalAmount+Number(taxAmount))-invoiceDetails.totalPaymentAmount).toFixed(2)}
                                                </Text>
                                            </View>
                                        </View>

                                    </View>
                                }
                            </View>

                            <View style={[styles.width_100, {display: 'block', clear: 'both', marginTop: '20px'}]}>

                                <Text style={[styles.text_13]}>
                                    {t('AmountInWords')}: {viewMode ==='decimal'? invoiceDetails.amountInWordDecimalHour : invoiceDetails.amountInWord}
                                </Text>
                            </View>

                            <View style={[styles.width_100, {display: 'block', clear: 'both', marginTop: '10px'}]}>
                                <Text style={[styles.text_11]}>
                                    {t('TermsAndConditions')}: {invoiceDetails.termsAndConditions}
                                </Text>
                            </View>

                            {/*<View style={[styles.width_100, {display: 'block', clear: 'both', marginTop: '10px'}]}>
                                <Text style={[styles.text_11]}>
                                    {t('Comments')}: {invoiceDetails.remarks}
                                </Text>
                            </View>*/}

                            <View style={[styles.width_100, {display: 'block', clear: 'both', marginTop: '20px'}]}>

                                <Text style={[styles.text_12]}>
                                    {t('NoticeOfAssignment')}:
                                </Text>
                                <Text style={[styles.text_10]}>
                                    {invoiceDetails.remarks}
                                </Text>
                            </View>

                        </Page>
                    </Document>
                </PDFViewer>

            </main>

        </Fragment>
    )
}

export default InvoicePdf