import React  from 'react';

import {
    HiOutlineKey,
    HiOutlineMail,
    HiOutlineExclamation,
    HiOutlineOfficeBuilding,
} from "react-icons/hi";

import { AiOutlineLoading3Quarters } from "react-icons/ai";
import {FiAirplay,FiSave,FiX,FiEye,FiEyeOff} from "react-icons/fi";
import {Switch} from '@headlessui/react';
import {useState} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {useTranslation} from "react-i18next";



function UserForm() {
    const { t, i18n } = useTranslation();


    const axios = require('axios').default;
    const [animal, setAnimal] = useState({
            value: null, label: null
    });
    const [enabled, setEnabled] = useState(false);
    const [gender, setGender] = useState('');
    const handleSelect = (value) => {
        setAnimal(value);
    };

    const [passwordShown, setPasswordShown] = useState(false);
    // Password toggle handler
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };



    const options = [
        {value: "fox", label: "🦊 Fox"},
        {value: "Butterfly", label: "🦋 Butterfly"},
        {value: "Honeybee", label: "🐝 Honeybee"},
    ];

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        email: Yup.string()
            .required('Name is required')
            .email('Email is invalid'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters'),
        confirmPassword: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password')], 'Passwords must match'),
        address: Yup.string()
            .required('Name is required'),
        dob: Yup.string()
            .required('Date of Birth is required')
            .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date of Birth must be a valid date in the format YYYY-MM-DD'),
        country: Yup.string()
            .required('Name is required'),
        acceptTerms: Yup.bool()
            .oneOf([true], 'Accept Terms and Condition is required'),
        gender: Yup.string()
            .oneOf([true], 'Select Gender')

    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const { isSubmitting } = formState;

    function onSubmit(data) {
        console.log(data)
        // display form data on success
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, 2000);
        });
        alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
        return false;
    }

    return (
        <>
            <main className="flex flex-1 flex-1 bg-indigo-700 overflow-y-auto  px-1 scrollbar-thin scrollbar-thumb-custom scrollbar-track-custom-light overflow-y-scroll">
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">

                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full py-6 px-4 sm:px-6 lg:px-4">
                                        <div className="relative bg-white">
                                            <div className="w-full flex justify-start text-gray-600 mb-1">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                       <HiOutlineOfficeBuilding size={48}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-3 font-lg font-bold clear-both relative'}>
                                                            {/*Single row layout*/}
                                                            {t('name')+' '+t('manish')}
                                                            <sub className={'font-normal absolute left-0 top-6'}>sub text</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex"}>

                                                    <div className="flex items-center justify-center">
                                                        <div
                                                            className="inline-flex border-1 border-red-300"
                                                            role="group">
                                                            {/*<Link
                                                                  className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                <HiOutlinePlus  size={12} className={'mr-1'} ></HiOutlinePlus>Add New
                                                            </Link>
                                                            <Link
                                                                className="inline-flex items-center px-2 py-1 bg-gray-100 text-indigo-600 hover:bg-gray-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineChartSquareBar  size={16} className={'mr-1'} ></HiOutlineChartSquareBar>Lists
                                                            </Link>
                                                            <Link
                                                                className="inline-flex justify-center flex-1 items-center h-full  rounded-full px-2 py-1  text-red-600 text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>

                                                            </Link>
*/}

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <form onSubmit={handleSubmit(onSubmit)} id="horizontal-form">
                                                <div className={'pt-3 sm:border-t sm:border-gray-200 '}>
                                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                                                        <label htmlFor="first-name" className="inline-flex  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Name<span className="text-red-500 flex mt-1 ml-1">*</span></label>
                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                            {errors.name?.type === 'required' && <div
                                                                className="flex bg-red-50 text-red-900 text-xs border border-1 border-red-300  py-1 px-2 right-0 absolute z-30 w-full -top-6">
                                                                <HiOutlineExclamation  size={16} className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                            </div>}
                                                            <div className="absolute right-0 text-gray-400 flex items-center pr-3 h-full cursor-pointer">
                                                                <FiAirplay size={16}></FiAirplay>
                                                            </div>
                                                            <input
                                                                {...register("name")}
                                                                type="text"
                                                                className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                name="name"
                                                                placeholder="Enter item name"
                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">DOB</label>
                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                            {errors.dob?.type === 'required' && <div
                                                                className="flex bg-red-50 text-red-900 text-xs border border-1 border-red-300  py-1 px-2 right-0 absolute z-30 w-full -top-6">
                                                                <HiOutlineExclamation  size={16} className={'mr-2'}></HiOutlineExclamation> {errors.dob?.message}
                                                            </div>}
                                                            <input
                                                                {...register("dob")}
                                                                type="date"
                                                                className={`form-control block w-full px-2 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none focus:ring-0 ${errors.dob ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                name="dob"
                                                                placeholder="Enter DOB"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Email Address</label>
                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                            {errors.email?.type === 'required' && <div
                                                                className="flex bg-red-50 text-red-900 text-xs border border-1 border-red-300  py-1 px-2 right-0 absolute z-30 w-full -top-6">
                                                                <HiOutlineExclamation  size={16} className={'mr-2'}></HiOutlineExclamation> {errors.email?.message}
                                                            </div>}
                                                            <div className="absolute right-0 text-gray-400 flex items-center pr-3 h-full cursor-pointer">
                                                                <HiOutlineMail size={16}></HiOutlineMail>
                                                            </div>
                                                            <input
                                                                {...register("email")}
                                                                name="email"
                                                                type="text"
                                                                className={`form-control block w-full px-2 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none focus:ring-0 ${errors.email ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                id="mail"
                                                                placeholder="Enter valid email address"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Password</label>
                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                            {errors.password?.type === 'required' && <div
                                                                className="flex bg-red-50 text-red-900 text-xs border border-1 border-red-300  py-1 px-2 right-0 absolute z-30 w-full -top-6">
                                                                <HiOutlineExclamation  size={16} className={'mr-2'}></HiOutlineExclamation> {errors.password?.message}
                                                            </div>}
                                                            <div className="absolute right-0 text-gray-400 flex items-center pr-3 h-full cursor-pointer">
                                                                {passwordShown ? (<FiEyeOff className='text-gray-400' onClick={togglePassword} size={16}></FiEyeOff>
                                                            ) : (<FiEye className='text-gray-700' onClick={togglePassword} size={16}></FiEye>)}
                                                            </div>
                                                            <input
                                                                {...register("password")}
                                                                type={passwordShown ? "text" : "password"}
                                                                className={`form-control block w-full px-2 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none focus:ring-0 ${errors.password ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                id="password"
                                                                placeholder="Enter password"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Re-Password</label>
                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                            {errors.confirmPassword?.type === 'required' && <div
                                                                className="flex bg-red-50 text-red-900 text-xs border border-1 border-red-300  py-1 px-2 right-0 absolute z-30 w-full -top-6">
                                                                <HiOutlineExclamation  size={16} className={'mr-2'}></HiOutlineExclamation> {errors.confirmPassword?.message}
                                                            </div>}
                                                            <div className="absolute right-0 text-gray-400 flex items-center pr-3 h-full cursor-pointer">
                                                                <HiOutlineKey size={16}></HiOutlineKey>
                                                            </div>
                                                            <input
                                                                name="confirmPassword"
                                                                type="password"
                                                                {...register("confirmPassword")}
                                                                className={`form-control block w-full px-2 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none focus:ring-0 ${errors.confirmPassword ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                id="confirm-password"
                                                                placeholder="Enter re-password"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Address</label>
                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                            {errors.address?.type === 'required' && <div
                                                                className="flex bg-red-50 text-red-900 text-xs border border-1 border-red-300  py-1 px-2 right-0 absolute z-30 w-full -top-6">
                                                                <HiOutlineExclamation  size={16} className={'mr-2'}></HiOutlineExclamation> {errors.address?.message}
                                                            </div>}
                                                            <textarea
                                                                name="address"
                                                                {...register("address")}
                                                                type="text"
                                                                className={`form-control block w-full px-2 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none focus:ring-0 ${errors.address ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                id="address"
                                                                placeholder="Enter address"></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Gander</label>
                                                        <div className="mt-3 mb-3 space-y-3">
                                                            <div className="flex items-center">
                                                                <input
                                                                    {...register("gander", { required: true })}
                                                                    name="gander"
                                                                    value="Male"
                                                                    type="radio"
                                                                    checked={'checked'}
                                                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                />
                                                                <label htmlFor="push-everything" className="ml-3">
                                                            <span
                                                                className="block text-sm font-medium text-gray-700">Male</span>
                                                                </label>
                                                            </div>
                                                            <div className="flex items-center">
                                                                <input
                                                                    {...register("gander", { required: true })}
                                                                    name="gander"
                                                                    value="Female"
                                                                    type="radio"
                                                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                />
                                                                <label htmlFor="push-email" className="ml-3">
                                                                    <span className="block text-sm font-medium text-gray-700">Female</span>
                                                                </label>
                                                            </div>
                                                            <div className="flex items-center">
                                                                <input
                                                                    {...register("gander", { required: true })}
                                                                    name="gander"
                                                                    value="Other"
                                                                    type="radio"
                                                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                />
                                                                <label htmlFor="push-nothing" className="ml-3">
                                                                    <span className="block text-sm font-medium text-gray-700">Other</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Country</label>
                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                            {errors.country?.type === 'required' && <div
                                                                className="flex bg-red-50 text-red-900 text-xs border border-1 border-red-300  py-1 px-2 right-0 absolute z-30 w-full -top-6">
                                                                <HiOutlineExclamation  size={16} className={'mr-2'}></HiOutlineExclamation> {errors.country?.message}
                                                            </div>}
                                                            <select
                                                                {...register("country")}
                                                                name="country"
                                                                autoComplete="country-name"
                                                                className={`form-control block w-full px-2 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none focus:ring-0 ${errors.country ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}>
                                                                <option value="">Select Country</option>
                                                                <option value="United States">United States</option>
                                                                <option value="Canada">Canada</option>
                                                                <option value="Mexico">Mexico</option>
                                                            </select>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                                                        <label htmlFor="first-name"
                                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Status</label>
                                                        <Switch
                                                            name="status"
                                                            {...register("status")}
                                                            defaultChecked={enabled}
                                                            onChange={setEnabled}
                                                            className={`${enabled ? 'bg-indigo-300' : 'bg-indigo-900'}
          relative inline-flex h-[24px] w-[60px] shrink-0 cursor-pointer rounded-full border-1 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                                                        >
                                                            <span className="sr-only">Use setting</span>
                                                            <span
                                                                aria-hidden="true"
                                                                className={`${enabled ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                                            />
                                                        </Switch>
                                                    </div>
                                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                                                        <label htmlFor="first-name"
                                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Notification</label>
                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                            <div
                                                                className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                                                <div className="flex items-center">
                                                                    <input id="email" name="notification-method"
                                                                           type="radio" defaultChecked={true}
                                                                           className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"/>
                                                                        <label htmlFor="email" className="ml-3 block text-sm font-medium text-gray-700">Email</label>
                                                                </div>

                                                                <div className="flex items-center">
                                                                    <input id="sms" name="notification-method"
                                                                           type="radio"
                                                                           className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"/>
                                                                        <label htmlFor="sms"
                                                                               className="ml-3 block text-sm font-medium text-gray-700">Phone
                                                                            (SMS)</label>
                                                                </div>

                                                                <div className="flex items-center">
                                                                    <input id="push" name="notification-method"
                                                                           type="radio"
                                                                           className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"/>
                                                                        <label htmlFor="push"
                                                                               className="ml-3 block text-sm font-medium text-gray-700">Push
                                                                            notification</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Accept Terms & Conditions</label>
                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                            {errors.acceptTerms?.type === 'required' && <div
                                                                className="flex bg-red-50 text-red-900 text-xs border border-1 border-red-300  py-1 px-2 right-0 absolute z-30 w-full -top-6">
                                                                <HiOutlineExclamation  size={16} className={'mr-2'}></HiOutlineExclamation> {errors.acceptTerms?.message}
                                                            </div>}
                                                            <input name="acceptTerms" type="checkbox" {...register('acceptTerms')} id="acceptTerms" className={`form-check-input ${errors.acceptTerms ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'}`} />
                                                        </div>
                                                    </div>
                                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                                                        <label htmlFor="acceptTerms" className="form-check-label">&nbsp;</label>
                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                            <button type='submit' className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                            {isSubmitting ? (  <AiOutlineLoading3Quarters className={'mr-2 animate-spin'} size={16}></AiOutlineLoading3Quarters>
                                                            ) : (<FiSave  size={16} className={'mr-2'}></FiSave>)}
                                                                <span>Save</span>
                                                            </button>
                                                            <button type='reset'
                                                                className={'px-4 py-2 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                onClick={() => reset()} > <FiX  size={16} className={'mr-2'}></FiX> <span>Cancel</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </main>
        </>
    )
}

export default UserForm