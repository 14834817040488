import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {
    HiOutlineX,
    HiHome, HiOutlineCalendar, HiEye
} from "react-icons/hi";
import {Fragment} from 'react'
import {format} from 'date-fns';
import button from "@material-tailwind/react";
import {ScrollArea, Table} from "@mantine/core";
import DesignationHistoryModel from "./DesignationHistoryModel";



export default function ViewCandidate({setShowModal, candidateid}) {
    const {t, i18n} = useTranslation();
    const {roles, token} = JSON.parse(localStorage.getItem('user'));

    const modalHeight = localStorage.getItem('modalHeight');
    const [designationsWiseRate, setDesignationsWiseRate] = useState([]);

    const [candidateDetails, setCandidateDetails] = useState({});


    const [designationHistoryModel,setDesignationHistoryModel] = useState(false)
    const [designationHistoryID,setDesignationHistoryID] = useState(null)
    const [designationHistoryExtendId,setDesignationHistoryExtendId] = useState(null)

    //get list of designation wise rate
    useEffect(() => {

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/${candidateid}/candidate/show`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                if (res.status === 200) {
                    setCandidateDetails(res.data.data)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        // get designations wise rate
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/designation-wise-rate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "userId": candidateid,
                "type":'candidate'
            }
        })
            .then(res => {
                setDesignationsWiseRate(res.data.data);
            });


    }, []);

    const DesignationRate = designationsWiseRate? designationsWiseRate.map((element,index) => (
        <tr key={element.id}>
            <td>{++index}</td>
            <td>{element.designationName}</td>
            {/*<td>$ {element.demandRate}</td>*/}
            <td>$ {element.rate}</td>
            <td>{element.createdAt ? format(new Date(element.createdAt.date), 'MM/dd/Y') : ''}</td>
            <td>
                <button
                    type="button"
                    className=" ml-2 px-1.5 py-1.5 bg-blue-500 hover:bg-blue-600 text-white text-xs"
                    onClick={ e => {
                        setDesignationHistoryExtendId(element.userExtendedInfoId)
                        setDesignationHistoryID(element.designationId)
                        setDesignationHistoryModel(true)
                    } }
                >
                    <HiEye size={12}/>
                </button>
            </td>
        </tr>
    )):'';

    const EducationData = candidateDetails.candidateEducation ? candidateDetails.candidateEducation.map((element,index) => (
        <tr key={element.id}>
            <td>{++index}</td>
            <td>{element.instituteTypeName}</td>
            <td> {element.title}</td>
            <td> {element.address}</td>
            {/*<td>{element.createdAt ? format(new Date(element.createdAt.date), 'dd/MM/Y') : ''}</td>*/}
        </tr>
    )):'';


    const EmploymentHistory = candidateDetails.employmentHistory?candidateDetails.employmentHistory.map((element,index) => (
        <>
                {/*<h2>{element.companyName}</h2>*/}
                <ul role="list" className=" space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0 border candidate-view-margin-padding">
                    <li className="flex items-start lg:col-span-1">
                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('CompanyName')} : </span> {element.companyName}</p>
                    </li>
                    <li className="flex items-start lg:col-span-1">
                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('Phone')} : </span> {element.phone}</p>
                    </li>
                    <li className="flex items-start lg:col-span-1">
                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('email')} : </span> {element.email}</p>
                    </li>
                    <li className="flex items-start lg:col-span-1">
                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('Supervisor')} : </span> {element.supervisor}</p>
                    </li>
                    <li className="flex items-start lg:col-span-1">
                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('JobTitle')} : </span> {element.jobTitle}</p>
                    </li>
                    <li className="flex items-start lg:col-span-1">
                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('StartingSalary')} : </span> {element.startingSalary}</p>
                    </li>
                    <li className="flex items-start lg:col-span-1">
                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('EndingSalary')} : </span> {element.endingSalary}</p>
                    </li>
                    <li className="flex items-start lg:col-span-1">
                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('FromDate')} : </span>{element.fromDate ? format(new Date(element.fromDate.date), 'MM/dd/Y') : ''}</p>
                    </li>
                    <li className="flex items-start lg:col-span-1">
                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('ToDate')} : </span>{element.toDate ? format(new Date(element.toDate.date), 'MM/dd/Y') : ''}</p>
                    </li>
                    <li className="flex items-start lg:col-span-1">
                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('ReasonForLeaving')} : </span>{element.reasonForLeaving}</p>
                    </li>

                </ul>
                </>

    )):'';

    const AttachmentData = candidateDetails.candidateAttachment?candidateDetails.candidateAttachment.map((element,index) => (
        <tr key={element.id}>
            <td>{++index}</td>
            <td>{element.label}</td>
            <td>
                <a href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/user-attachment/${element.attachment}`} target="new">
                    {element.attachment}
                </a>
            </td>
        </tr>
    )):'';

    const referenceInformation = candidateDetails.candidateReference?candidateDetails.candidateReference.map((ref,index) => (
        <ul role="list" className=" space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0 border candidate-view-margin-padding">
            <li className="flex items-start lg:col-span-1">
                <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('Name')} : </span> {ref.referenceName}</p>
            </li>
            <li className="flex items-start lg:col-span-1">
                <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('Relation')} : </span> {ref.referenceRelation}</p>
            </li>
            <li className="flex items-start lg:col-span-1">
                <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('Email')} : </span> {ref.referenceEmail}</p>
            </li>
            <li className="flex items-start lg:col-span-1">
                <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('Mobile')} : </span> {ref.referencePhone}</p>
            </li>
        </ul>
        )):'';

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{candidateDetails.firstName} {candidateDetails.middleName?candidateDetails.middleName:""} {candidateDetails.lastName}</h5>
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <aside className="w-full sm:w-1/3 md:w-1/4 px-2">
                                            <div className="sticky top-0 w-full">
                                                <div className="mt-2">
                                                    <div className="mx-auto  max-w-lg lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4 lg:mx-0 lg:mt-10 lg:max-w-none">
                                                        <div className="relative z-10 rounded-lg shadow-xl">
                                                            <div className="pointer-events-none absolute inset-0 rounded-lg border-2 border-indigo-600" aria-hidden="true"/>
                                                            <div className="absolute inset-x-0 top-0 translate-y-px transform">
                                                            </div>
                                                            <div className="rounded-t-lg bg-white px-6 pt-6 pb-4">
                                                                <div>
                                                                    <ul role="list" className="mt-2">
                                                                        {candidateDetails.profileImage ?
                                                                            <img className="mx-auto h-2/3 w-2/3 mb-8" src={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${candidateDetails.profileImage}`} alt="Image"/> :                                                                         <img className="mx-auto h-full w-full mb-8" src={"/assets/images/No_Image.jpg"} alt="Simple Staffing"/>
                                                                        }


                                                                        <li className="flex items-start lg:col-span-1 mb-2">
                                                                            <div className="flex-shrink-0">
                                                                                <HiHome size={20} className={`bg-indigo-500 text-white`}/>
                                                                            </div>
                                                                            <p className="ml-3 text-sm text-gray-700">  {candidateDetails.cityName?candidateDetails.cityName:''}{candidateDetails.stateName?','+candidateDetails.stateName:''}{candidateDetails.countryName?','+candidateDetails.countryName:''}</p>
                                                                        </li>
                                                                        <li className="flex items-start lg:col-span-1">
                                                                            <div className="flex-shrink-0">
                                                                                <HiOutlineCalendar size={20} className={`bg-indigo-500 text-white`}/>
                                                                            </div>
                                                                            <p className="ml-3 text-sm text-gray-700">{t('MemberSince')}: {candidateDetails.createdAt ? format(new Date(candidateDetails.createdAt.date), 'MM/dd/Y') : ''} </p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="rounded-b-lg border-t-2 border-gray-100 bg-gray-50">
                                                                <p className="block w-full rounded-lg border border-transparent bg-indigo-600 px-6 py-4 text-center text-xl font-medium leading-6 text-white hover:bg-indigo-700"> {candidateDetails.statusName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </aside>
                                        <main role="main" className="w-full sm:w-2/3 md:w-3/4 pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <Fragment>
                                                    <div className="bg-gray-100">
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">
                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('PersonalInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                <ul role="list" className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('candidateID')} : </span> {candidateDetails.employeeId }</p>
                                                                                    </li>
                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('Username')} : </span>{candidateDetails.email}</p>
                                                                                    </li>

                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('SecondaryEmail')} : </span>{candidateDetails.secondaryEmail}</p>
                                                                                    </li>

                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('Mobile')} : </span>{candidateDetails.phoneNumber}</p>
                                                                                    </li>

                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('EmployeeStatus')} : </span> {candidateDetails.employeeStatusName}</p>
                                                                                    </li>

                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                                <Fragment>
                                                    <div className="bg-gray-100">
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">
                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('OtherInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                <ul role="list" className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('gender')} : </span> {candidateDetails.genderName }</p>
                                                                                    </li>

                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('date_of_birth')} : </span> {candidateDetails.dateOfBirth ? format(new Date(candidateDetails.dateOfBirth.date), 'MM/dd/Y') : ''}</p>
                                                                                    </li>

                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('language')} : </span> {candidateDetails.languageName ? candidateDetails.languageName : ''}</p>
                                                                                    </li>

                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('ImmigrationStatus')} : </span> {candidateDetails.immigrationStatusName }</p>
                                                                                    </li>
                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('Linkedin')} : </span>
                                                                                            <a href={candidateDetails.linkedin} target="new"> {candidateDetails.linkedin}</a>
                                                                                        </p>
                                                                                    </li>

                                                                                    {/*<li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700">{t('CertLicense')}: {candidateDetails.certificateLicense }</p>
                                                                                    </li>*/}

                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>

                                                <Fragment>
                                                    <div className="bg-gray-100">
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">
                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('LocationInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>

                                                                                <ul role="list" className="mt-4 space-y-5 lg:grid lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('StreetAddress')} : </span> {candidateDetails.streetAddress}</p>
                                                                                    </li>
                                                                                </ul>

                                                                                <ul role="list" className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('Zipcode')} : </span> {candidateDetails.zipCode}</p>
                                                                                    </li>
                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('City')} : </span> {candidateDetails.cityName}</p>
                                                                                    </li>
                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('State')} : </span> {candidateDetails.stateName}</p>
                                                                                    </li>
                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('Country')} : </span> {candidateDetails.countryName}</p>
                                                                                    </li>

                                                                                </ul>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>

                                                <Fragment>
                                                    <div className="bg-gray-100">
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">
                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('DesignationInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                <Table>
                                                                                    <thead>
                                                                                    <tr>
                                                                                        <th>{t('SL')}</th>
                                                                                        <th>{t('Designation')}</th>
                                                                                        <th>{t('HourlyRate')}</th>
                                                                                        <th>{t('Date')}</th>
                                                                                        <th width={50}>{t('History')}</th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>{DesignationRate}</tbody>
                                                                                </Table>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>




                                                <Fragment>
                                                    <div className="bg-gray-100">
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">
                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('CandidateJobInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>

                                                                                <ul role="list" className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">

                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('Source')} : </span> {candidateDetails.sourceName}</p>
                                                                                    </li>
                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('Availability')} : </span> {candidateDetails.availability}</p>
                                                                                    </li>

                                                                                </ul>
                                                                                <ul role="list" className="mt-4 space-y-5 lg:grid lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('Remarks')} : </span> {candidateDetails.remark}</p>
                                                                                    </li>
                                                                                </ul>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>

                                                <Fragment>
                                                    <div className="bg-gray-100">
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">
                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('WorkShiftsInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                <ul role="list" className="mt-4 space-y-5 lg:grid lg:gap-x-8 lg:gap-y-5 lg:space-y-0">

                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('ShiftAbleToWork')} : </span> {
                                                                                            candidateDetails.work_shift && Object.entries(candidateDetails.work_shift).map(([key, value])=>(
                                                                                                value?' '+value+' ,':''
                                                                                            ))

                                                                                        }</p>
                                                                                    </li>
                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('AreCitizenOfTheUnitedStates')} </span> {candidateDetails.united_citizen && candidateDetails.united_citizen === true ? 'Yes' : 'No'}</p>
                                                                                    </li>
                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('HavePreviousWorkThisCompany')} </span> {candidateDetails.previous_work_this_company && candidateDetails.previous_work_this_company === true ? 'Yes' : 'No'}</p>
                                                                                    </li>
                                                                                    <li className="flex items-start lg:col-span-1">
                                                                                        <p className="ml-3 text-sm text-gray-700"><span className={'font-bold'}>{t('HaveConvictedFelony')} </span> {candidateDetails.have_convicted_felony && candidateDetails.have_convicted_felony === true ? 'Yes' : 'No'}</p>
                                                                                    </li>
                                                                                </ul>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>

                                                <Fragment>
                                                    <div className="bg-gray-100">
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">
                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('EducationInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                <Table>
                                                                                    <thead>
                                                                                    <tr>
                                                                                        <th>{t('SL')}</th>
                                                                                        <th>{t('EducationType')}</th>
                                                                                        <th>{t('InstitutionName')}</th>
                                                                                        <th>{t('DegreeAttained')}</th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>{EducationData}</tbody>
                                                                                </Table>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>

                                                <Fragment>
                                                    <div className="bg-gray-100">
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">
                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ReferencesInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                {referenceInformation}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>

                                                <Fragment>
                                                    <div className="bg-gray-100">
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">
                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('EmploymentHistory')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>

                                                                                {EmploymentHistory}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>

                                                <Fragment>
                                                    <div className="bg-gray-100">
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">
                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('AttachmentInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>
                                                                                <Table>
                                                                                    <thead>
                                                                                    <tr>
                                                                                        <th>{t('SL')}</th>
                                                                                        <th>{t('AttachmentLebel')}</th>
                                                                                        <th>{t('FileName')}</th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>{AttachmentData}</tbody>
                                                                                </Table>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            </ScrollArea>
                                        </main>
                                        {designationHistoryModel ? <DesignationHistoryModel
                                            setDesignationHistoryModel={setDesignationHistoryModel}
                                            designationHistoryExtendId={designationHistoryExtendId}
                                            designationHistoryID={designationHistoryID}
                                        /> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
        </>
    );

}
