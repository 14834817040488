import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiLocationMarker,
    HiCalendar,
    HiOutlineX,
    HiDocumentDownload,
    HiOutlineOfficeBuilding,
    HiOutlineCurrencyDollar,
    HiEye,
    HiPencil,
    HiOutlinePlus,
    HiChevronLeft,
} from "react-icons/hi";
import {Fragment} from 'react';
import {format} from 'date-fns';
import {Link, useParams} from "react-router-dom";
import button from "@material-tailwind/react";
import {ActionIcon, Grid, ScrollArea} from "@mantine/core";
import axios from "axios";



export default function PayrollShow() {
    const {domainId, loggedUserId ,roles, token} = JSON.parse(localStorage.getItem('user'));
    const hasRoleModerator = roles.find(role => {
        return role === "ROLE_MODERATOR";
    })
    const {payrollId} = useParams();

    const {t, i18n} = useTranslation();

    const [payrollDetails, setPayrollDetails] = useState({});
    const [viewMode, setViewMode] = useState('decimal');

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }


    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/payroll/${payrollId}/show`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                if (res.status === 200){
                    setPayrollDetails(res.data.data)
                }
            });
    }, [])

    const modalHeight = localStorage.getItem('modalHeight');
    var lineTotalAmount=payrollDetails.amount;
    var lineTotalAmountDecimalHour=payrollDetails.amountDecimalHour;
    var lineTotalHours=0;


    const convertHourMinToDecimal = (time, separator) => {
        if(time === undefined || time === null || time === '') return 0;
        const totalMinutes = time.split(separator).reduce((acc,time) => (60 * acc) + +time)
        const hours = totalMinutes/60;

        return hours.toFixed(2)
    }

    const convertDecimalToHour = (time, separator) => {
        if(time === undefined || time === null || time === '') return 0;
        const timeSplit = time.split(separator);
        const hours = timeSplit[0];
        const minutes = timeSplit[1];

        const hourLength = hours.length;

        const minLength = minutes.length;

        const hour = hourLength<2?'0'+hours:hours;
        const minute = minLength<2?'0'+minutes:minutes;

        return `${hour}:${minute}`
    }

    return (
        <main className="w-full">
            <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                <div className={"flex-1"}>
                    <div
                        className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                        <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Payroll')}
                            <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('PayrollDetails')}</sub>
                            </span>
                    </div>
                </div>
                <div className={"right flex mr-8"}>
                    <div className="flex items-center justify-center">
                        <div className="inline-flex border-1 border-red-300" role="group">
                            <Link to={`/payroll/`+payrollDetails.id+`/pdf`}
                                  state = { viewMode==='hour' ? {viewMode: 'hour'} : {viewMode: 'decimal'}}
                                  className="inline-flex items-center text-right font-normal text-red-600 ">
                                <HiDocumentDownload className="h-5 w-5" aria-hidden="true"/> {t('GeneratePDF')}
                            </Link>
                            { hasRoleModerator && payrollDetails.paymentStatus && payrollDetails.paymentStatus!=="PAID" &&

                                <Link to={`/payroll/`+payrollDetails.id+`/edit`}
                            className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                            >
                            <HiPencil size={16} className={'mr-1'}/>{t('EditPayroll')}
                        </Link>
                            }


                            <Link to={'/payroll'}>
                                <button
                                    className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={16} className={'mr-1'}/>{t('Back')}
                                </button>
                            </Link>

                        </div>
                    </div>
                </div>
            </div>

            <div className="min-h-full w-full">
                <div className="bg-white px-2 lg:px-4 lg:py-2">
                    <button onClick={()=>setViewMode('decimal')}
                            className={`inline-flex items-center ml-6 rounded border-none py-2 px-4 text-sm font-normal shadow-sm bg-indigo-100 ${viewMode==='decimal' && "bg-indigo-600 text-white"}`}
                    >
                        {t('Decimal')}
                    </button>
                    {hasRoleModerator &&
                        <button onClick={()=>setViewMode('hour')}
                                className={`inline-flex items-center ml-6 rounded border-none py-2 px-4 text-sm font-normal shadow-sm bg-indigo-100 ${viewMode==='hour' && "bg-indigo-600 text-white"}`}
                        >
                            {t('Hour')}
                        </button>
                    }

                </div>
                <div className="flex w-full md:w-auto">
                    <div className="bg-red-100 flex-1">
                        <div className="min-w-0 flex-1 bg-white xl:flex">
                            <div className="bg-white lg:min-w-0 lg:flex-1">
                                <div className="h-full px-4 sm:px-6 lg:px-4">
                                    <div className="">

                                        <div className={'flex flex-1 w-full'}>
                                            <div className="w-full mr-0 mx-auto border">

                                                <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                    <Fragment>
                                                        <div className="bg-gray-100">
                                                            <div className="mt-2 bg-white">
                                                                <div className="relative">
                                                                    <div className="relative mx-auto px-2 lg:px-2">

                                                                        <div className="w-full">

                                                                            <div className="bg-white px-2 py-2 lg:p-2">

                                                                                <div className="w-full">
                                                                                    <Grid columns={24}>
                                                                                        <Grid.Col span={14}>
                                                                                            <div className="w-full">
                                                                                                <img src={"/assets/images/simple-staffing.png"} alt="Simple Staffing" />
                                                                                                <h2 className="text-lg">SIMPLE STAFFING USA LLC</h2>
                                                                                                <p className="text-sm">
                                                                                                    3235 Satellite Blvd. Building 400
                                                                                                </p>
                                                                                                <p className="text-sm">
                                                                                                    Suite 300 Duluth, GA
                                                                                                </p>
                                                                                                <p className="text-sm">
                                                                                                    {t('Phone')}: 1.888.902.7759
                                                                                                </p>
                                                                                                <p className="text-sm">
                                                                                                    {t('Email')}: billing@simplestaffingllc.com
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="w-full mt-10">
                                                                                                <h2 className="text-lg"><strong>BILL TO:</strong></h2>
                                                                                                <h2 className="text-lg">{payrollDetails.employeeName}</h2>
                                                                                                <p className="text-sm">{payrollDetails.employeeAddress}</p>
                                                                                                <p className="text-sm">Phone: {payrollDetails.employeePhone}</p>
                                                                                                <p className="text-sm">Email: {payrollDetails.employeeEmail}</p>
                                                                                            </div>
                                                                                        </Grid.Col>
                                                                                        <Grid.Col span={10}>
                                                                                            <div className="text-center">
                                                                                                <h1>Payroll</h1>
                                                                                                <table>
                                                                                                    <tbody>
                                                                                                    <tr>
                                                                                                        <td className="text-right">{t("PayrollNO")} : </td>
                                                                                                        <td className="text-left">{payrollDetails.payrollId}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className="text-right">{t('DATE')} : </td>
                                                                                                        <td className="text-left">
                                                                                                            {payrollDetails.createdAt ? format(new Date(payrollDetails.createdAt.date), 'MMMM dd, Y') : ''}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className="text-right">{t('PayrollPeriod')} : </td>
                                                                                                        <td className="text-left">{payrollDetails.startTime ? format(new Date(payrollDetails.startTime.date), 'MMM dd, Y') : ''} To  {payrollDetails.endTime ? format(new Date(payrollDetails.endTime.date), 'MMM dd, Y') : ''}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className="text-right">{t('PayrollDueDate')} : </td>
                                                                                                        <td className="text-left">{payrollDetails.dueDate ? format(new Date(payrollDetails.dueDate.date), 'MMM dd, Y') : ''}</td>
                                                                                                    </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        </Grid.Col>
                                                                                    </Grid>
                                                                                </div>

                                                                                { viewMode==='decimal' &&
                                                                                    <div className="w-full mt-2">
                                                                                        <table className="border-collapse border border-slate-400 min-w-full" >
                                                                                            <thead className="bg-white">
                                                                                            <tr>
                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                    {t('JobTitle')}
                                                                                                </th>
                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('Date')}
                                                                                                </th>
                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                    {t('Designation')}
                                                                                                </th>
                                                                                                <th colSpan={1} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('RegularRate')}
                                                                                                </th>
                                                                                                <th colSpan={1} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('OverTimeRate')}
                                                                                                </th>

                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                    {t('TotalHours')}
                                                                                                </th>
                                                                                                <th colSpan={1} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('Regular')}
                                                                                                </th>
                                                                                                <th colSpan={1} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('OverTime')}
                                                                                                </th>
                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                    {t('TotalAmount')}

                                                                                                </th>

                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                                {/*<th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Min")}</th>*/}

                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                                {/*<th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Min")}</th>*/}

                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                                {/*<th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Min")}</th>*/}

                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                                {/*<th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Min")}</th>*/}
                                                                                            </tr>
                                                                                            </thead>
                                                                                            <tbody className="bg-white">

                                                                                            {payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && Object.keys(payrollDetails.details['payrollDetails']).map((jobId, index) => {

                                                                                                {var jobWiseCount = 0}
                                                                                                {var jobWiseTotalHours = 0}
                                                                                                {var jobWiseTotalAmount = 0}

                                                                                                return (

                                                                                                    <Fragment key={index}>
                                                                                                        {payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && payrollDetails.details['payrollDetails'][jobId] && Object.values(payrollDetails.details['payrollDetails'][[jobId]]).map((element, index) => {
                                                                                                            { jobWiseCount = jobWiseCount+1}

                                                                                                            { jobWiseTotalHours = jobWiseTotalHours + Number(element.hour)}
                                                                                                            {var amountDecimalHour = element.amountDecimalHour>0?(element.amountDecimalHour):((element.regularHourMinToDecimal*element.regularHourRate)+(element.overtimeHourMinToDecimal*element.overtimeHourRate))}
                                                                                                            { jobWiseTotalAmount = jobWiseTotalAmount + Number(amountDecimalHour)}



                                                                                                            { var dateWiseDataLength = (payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && payrollDetails.details['payrollDetails'][jobId]) ? Object.values(payrollDetails.details['payrollDetails'][[jobId]]).length:0}

                                                                                                            return (
                                                                                                                <tr key={index} className="border-t border-gray-200">
                                                                                                                    {jobWiseCount===1 &&
                                                                                                                        <td rowSpan={dateWiseDataLength} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                            {element.jobTitle}
                                                                                                                        </td>
                                                                                                                    }
                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {/*{element.jobTitle}*/}
                                                                                                                        {element.inTime ? format(new Date(element.inTime.date), 'MM/dd/Y') : ''}
                                                                                                                    </td>
                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.designationName}
                                                                                                                    </td>
                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.regularHourRate}
                                                                                                                    </td>
                                                                                                                    {/*<td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.regularMinRate}
                                                                                                                    </td>*/}

                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.overtimeHourRate}
                                                                                                                    </td>
                                                                                                                    {/*<td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.overtimeMinRate}
                                                                                                                    </td>*/}

                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.minuteFormatToHourFormat}
                                                                                                                    </td>

                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.regularHourMinToDecimal}
                                                                                                                    </td>
                                                                                                                    {/*<td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.regularMin}
                                                                                                                    </td>*/}

                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.overtimeHourMinToDecimal}
                                                                                                                    </td>
                                                                                                                    {/*<td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.overtimeMin}
                                                                                                                    </td>*/}

                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900 text-right">
                                                                                                                        {amountDecimalHour.toFixed(2)}
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            )

                                                                                                        })}
                                                                                                        <tr>
                                                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right" colSpan={5}>{t('Hours')}</th>
                                                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-left">
                                                                                                                {convertHourMinToDecimal(jobWiseTotalHours.toFixed(2), '.')}
                                                                                                            </th>
                                                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right" colSpan={2}>
                                                                                                                {t('Amount')}
                                                                                                            </th>
                                                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right">
                                                                                                                {Number(jobWiseTotalAmount).toFixed(2)}
                                                                                                            </th>
                                                                                                        </tr>
                                                                                                    </Fragment>


                                                                                                )

                                                                                            })}


                                                                                            </tbody>
                                                                                            <tfoot>
                                                                                            <tr>
                                                                                                <th className="border border-slate-300 text-right px-4" colSpan={5}>
                                                                                                    {t('TotalHours')}
                                                                                                </th>
                                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-left">
                                                                                                    {/*{payrollDetails.totalHoursForDisplay}*/}
                                                                                                    {convertHourMinToDecimal(payrollDetails.totalHoursForDisplay, ':')}

                                                                                                </th>
                                                                                                <th className="border border-slate-300 text-right px-4" colSpan={2}>
                                                                                                    {t('GrossIncome')}
                                                                                                </th>
                                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right">
                                                                                                    {lineTotalAmountDecimalHour?Number(lineTotalAmountDecimalHour).toFixed(2):''}
                                                                                                </th>
                                                                                            </tr>
                                                                                            </tfoot>
                                                                                        </table>
                                                                                    </div>
                                                                                }


                                                                                { hasRoleModerator && viewMode==='hour' &&
                                                                                    <div className="w-full mt-2">
                                                                                        <table className="border-collapse border border-slate-400 min-w-full" >
                                                                                            <thead className="bg-white">
                                                                                            <tr>
                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                    {t('JobTitle')}
                                                                                                </th>
                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('Date')}
                                                                                                </th>
                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                    {t('Designation')}
                                                                                                </th>
                                                                                                <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('RegularRate')}
                                                                                                </th>
                                                                                                <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('OverTimeRate')}
                                                                                                </th>

                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                    {t('TotalHours')}
                                                                                                </th>
                                                                                                <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('Regular')}
                                                                                                </th>
                                                                                                <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('OverTime')}
                                                                                                </th>
                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                    {t('TotalAmount')}

                                                                                                </th>

                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Min")}</th>
                                                                                            </tr>
                                                                                            </thead>
                                                                                            <tbody className="bg-white">

                                                                                            {payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && Object.keys(payrollDetails.details['payrollDetails']).map((jobId, index) => {

                                                                                                {var jobWiseCount = 0}
                                                                                                {var jobWiseTotalHours = 0}
                                                                                                {var jobWiseTotalAmount = 0}

                                                                                                return (

                                                                                                    <Fragment key={index}>
                                                                                                        {payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && payrollDetails.details['payrollDetails'][jobId] && Object.values(payrollDetails.details['payrollDetails'][[jobId]]).map((element, index) => {
                                                                                                            { jobWiseCount = jobWiseCount+1}

                                                                                                            { jobWiseTotalHours = jobWiseTotalHours + Number(element.hour)}
                                                                                                            { jobWiseTotalAmount = jobWiseTotalAmount + Number(element.amount)}



                                                                                                            { var dateWiseDataLength = (payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && payrollDetails.details['payrollDetails'][jobId]) ? Object.values(payrollDetails.details['payrollDetails'][[jobId]]).length:0}

                                                                                                            return (
                                                                                                                <tr key={index} className="border-t border-gray-200">
                                                                                                                    {jobWiseCount===1 &&
                                                                                                                        <td rowSpan={dateWiseDataLength} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                            {element.jobTitle}
                                                                                                                        </td>
                                                                                                                    }
                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {/*{element.jobTitle}*/}
                                                                                                                        {element.inTime ? format(new Date(element.inTime.date), 'MM/dd/Y') : ''}
                                                                                                                    </td>
                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.designationName}
                                                                                                                    </td>
                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.regularHourRate}
                                                                                                                    </td>
                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.regularMinRate}
                                                                                                                    </td>

                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.overtimeHourRate}
                                                                                                                    </td>
                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.overtimeMinRate}
                                                                                                                    </td>

                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.hourForDisplay}
                                                                                                                    </td>

                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.regularHour}
                                                                                                                    </td>
                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.regularMin}
                                                                                                                    </td>

                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.overtimeHour}
                                                                                                                    </td>
                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {element.overtimeMin}
                                                                                                                    </td>

                                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900 text-right">
                                                                                                                        {(element.amount).toFixed(2)}
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            )

                                                                                                        })}
                                                                                                        <tr>
                                                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right" colSpan={7}>{t('Hours')}</th>
                                                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-left">
                                                                                                                { convertDecimalToHour(Number(jobWiseTotalHours).toFixed(2), '.')}
                                                                                                            </th>
                                                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right" colSpan={4}>
                                                                                                                {t('Amount')}
                                                                                                            </th>
                                                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right">
                                                                                                                {Number(jobWiseTotalAmount).toFixed(2)}
                                                                                                            </th>
                                                                                                        </tr>
                                                                                                    </Fragment>


                                                                                                )

                                                                                            })}


                                                                                            </tbody>
                                                                                            <tfoot>
                                                                                            <tr>
                                                                                                <th className="border border-slate-300 text-right px-4" colSpan={7}>
                                                                                                    {t('TotalHours')}
                                                                                                </th>
                                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-left">{payrollDetails.totalHoursForDisplay}</th>
                                                                                                <th className="border border-slate-300 text-right px-4" colSpan={4}>
                                                                                                    {t('GrossIncome')}
                                                                                                </th>
                                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right">
                                                                                                    {Number(lineTotalAmount).toFixed(2)}
                                                                                                </th>
                                                                                            </tr>
                                                                                            </tfoot>
                                                                                        </table>
                                                                                    </div>
                                                                                }


                                                                                <div className="mt-1 py-5 text-left">
                                                                                    <div className="flex flex-wrap">
                                                                                        <div className="w-full mt-2">
                                                                                            <h3><strong>{t('AmountInWords')}</strong>: {viewMode ==='decimal'? payrollDetails.amountInWordDecimalAmount:payrollDetails.amountInWord}</h3>
                                                                                        </div>
                                                                                        <div className="w-full mt-1">
                                                                                            <p className="text-slate-800"><strong>{t('Comments')}</strong>: {payrollDetails.remarks ? payrollDetails.remarks : ''}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>

                                                </ScrollArea>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </main>
    );
}
