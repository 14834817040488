import React from "react";

const InputCheckbox = ({ labelText, name }) => {
    return (
        <label className="inline-flex items-center ">
            <input className={"ring-2 h-4 w-4"} type="checkbox" name={name} />
            <span className="ml-2">{labelText}</span>
        </label>
    );
};

export default InputCheckbox;
