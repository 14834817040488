import React from "react";
import {useTranslation} from "react-i18next";
import {
    HiLocationMarker,
    HiCalendar,
    HiOutlineX, HiDocumentDownload,
} from "react-icons/hi";
import {Fragment} from 'react';
import {format} from 'date-fns';
import {Link} from "react-router-dom";
import button from "@material-tailwind/react";
import {Grid, ScrollArea} from "@mantine/core";



export default function ViewPayroll({setShowModal, payrollDetails}) {
    // console.log(payrollDetails, requiredDesignation, assignedCandidate)
    const {t, i18n} = useTranslation();
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const modalHeight = localStorage.getItem('modalHeight');
    var lineTotalAmount=payrollDetails.amount;
    var lineTotalHours=0;
    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('PayrollDetails')}</h5>
                                {/*<Link to={`/invoice/`+invoiceDetails.id+`/pdf`}
                                      className="inline-flex items-center text-right font-normal text-red-600 ">
                                    <HiDocumentDownload className="h-5 w-5" aria-hidden="true"/> Generate PDF
                                </Link>*/}
                                <Link to={`/payroll/`+payrollDetails.id+`/pdf`}
                                      className="inline-flex items-center text-right font-normal text-red-600 ">
                                    <HiDocumentDownload className="h-5 w-5" aria-hidden="true"/> Generate PDF
                                </Link>
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <Fragment>
                                                    <div className="bg-gray-100">
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">
                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2">

                                                                    <div className="w-full">

                                                                        <div className="bg-white px-2 py-2 lg:p-2">

                                                                            <div className="w-full">
                                                                                <Grid columns={24}>
                                                                                    <Grid.Col span={14}>
                                                                                        <div className="w-full">
                                                                                            <img src={"/assets/images/simple-staffing.png"} alt="Simple Staffing" />
                                                                                            <h2 className="text-lg">SIMPLE STAFFING USA LLC</h2>
                                                                                            <p className="text-sm">
                                                                                                3235 Satellite Blvd. Building 400
                                                                                            </p>
                                                                                            <p className="text-sm">
                                                                                                Suite 300 Duluth, GA
                                                                                            </p>
                                                                                            <p className="text-sm">
                                                                                                {t('Phone')}: 1.888.902.7759
                                                                                            </p>
                                                                                            <p className="text-sm">
                                                                                                {t('Email')}: billing@simplestaffingllc.com
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="w-full mt-10">
                                                                                            <h2 className="text-lg"><strong>BILL TO:</strong></h2>
                                                                                            <h2 className="text-lg">{payrollDetails.employeeName}</h2>
                                                                                            <p className="text-sm">{payrollDetails.employeeAddress}</p>
                                                                                            <p className="text-sm">Phone: {payrollDetails.employeePhone}</p>
                                                                                            <p className="text-sm">Email: {payrollDetails.employeeEmail}</p>
                                                                                        </div>
                                                                                    </Grid.Col>
                                                                                    <Grid.Col span={10}>
                                                                                        <div className="text-center">
                                                                                            <h1>Payroll</h1>
                                                                                            <table>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td className="text-right">{t("PayrollNO")} : </td>
                                                                                                        <td className="text-left">{payrollDetails.payrollId}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className="text-right">{t('DATE')} : </td>
                                                                                                        <td className="text-left">
                                                                                                            {payrollDetails.createdAt ? format(new Date(payrollDetails.createdAt.date), 'MMMM dd, Y') : ''}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className="text-right">{t('PayrollPeriod')} : </td>
                                                                                                        <td className="text-left">{payrollDetails.startTime ? format(new Date(payrollDetails.startTime.date), 'MMM dd, Y') : ''} To  {payrollDetails.endTime ? format(new Date(payrollDetails.endTime.date), 'MMM dd, Y') : ''}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className="text-right">{t('PayrollDueDate')} : </td>
                                                                                                        <td className="text-left">{payrollDetails.dueDate ? format(new Date(payrollDetails.dueDate.date), 'MMM dd, Y') : ''}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </Grid.Col>
                                                                                </Grid>
                                                                            </div>

                                                                            <div className="w-full mt-2">
                                                                                <table className="border-collapse border border-slate-400 min-w-full" >
                                                                                    <thead className="bg-white">
                                                                                    <tr>
                                                                                        <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                            {t('JobTitle')}
                                                                                        </th>
                                                                                        <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                            {t('Date')}
                                                                                        </th>
                                                                                        <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                            {t('Designation')}
                                                                                        </th>
                                                                                        <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                            {t('RegularRate')}
                                                                                        </th>
                                                                                        <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                            {t('OverTimeRate')}
                                                                                        </th>

                                                                                        <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                            {t('TotalHours')}
                                                                                        </th>
                                                                                        <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                            {t('Regular')}
                                                                                        </th>
                                                                                        <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                            {t('OverTime')}
                                                                                        </th>
                                                                                        <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                            {t('TotalAmount')}

                                                                                        </th>

                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                        <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                                        <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                        <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                                        <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                        <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                                        <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                        <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Min")}</th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody className="bg-white">

                                                                                    {payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && Object.keys(payrollDetails.details['payrollDetails']).map((jobId, index) => {

                                                                                        {var jobWiseCount = 0}
                                                                                        {var jobWiseTotalHours = 0}
                                                                                        {var jobWiseTotalAmount = 0}

                                                                                        return (

                                                                                            <Fragment key={index}>
                                                                                                {payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && payrollDetails.details['payrollDetails'][jobId] && Object.values(payrollDetails.details['payrollDetails'][[jobId]]).map((element, index) => {
                                                                                                    { jobWiseCount = jobWiseCount+1}

                                                                                                    { jobWiseTotalHours = jobWiseTotalHours + Number(element.hour)}
                                                                                                    { jobWiseTotalAmount = jobWiseTotalAmount + Number(element.amount)}



                                                                                                    { var dateWiseDataLength = (payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && payrollDetails.details['payrollDetails'][jobId]) ? Object.values(payrollDetails.details['payrollDetails'][[jobId]]).length:0}

                                                                                                    return (
                                                                                                        <tr key={index} className="border-t border-gray-200">
                                                                                                            {jobWiseCount===1 &&
                                                                                                            <td rowSpan={dateWiseDataLength} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                {element.jobTitle}
                                                                                                            </td>
                                                                                                            }
                                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                {/*{element.jobTitle}*/}
                                                                                                                {element.inTime ? format(new Date(element.inTime.date), 'MM/dd/Y') : ''}
                                                                                                            </td>
                                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                {element.designationName}
                                                                                                            </td>
                                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                {element.regularHourRate}
                                                                                                            </td>
                                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                {element.regularMinRate}
                                                                                                            </td>

                                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                {element.overtimeHourRate}
                                                                                                            </td>
                                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                {element.overtimeMinRate}
                                                                                                            </td>

                                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                {element.hourForDisplay}
                                                                                                            </td>

                                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                {element.regularHour}
                                                                                                            </td>
                                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                {element.regularMin}
                                                                                                            </td>

                                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                {element.overtimeHour}
                                                                                                            </td>
                                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                {element.overtimeMin}
                                                                                                            </td>

                                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900 text-right">
                                                                                                                {(element.amount).toFixed(2)}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )

                                                                                                })}
                                                                                                <tr>
                                                                                                    <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right" colSpan={7}>Sub Total</th>
                                                                                                    <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-left">
                                                                                                        {Number(jobWiseTotalHours).toFixed(2)}
                                                                                                    </th>
                                                                                                    <th colSpan={4}></th>
                                                                                                    <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right">
                                                                                                        {Number(jobWiseTotalAmount).toFixed(2)}
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </Fragment>


                                                                                        )

                                                                                    })}


                                                                                    </tbody>
                                                                                    <tfoot>
                                                                                    <tr>
                                                                                        <th className="border border-slate-300 text-right px-4" colSpan={7}>
                                                                                            {t('Grand Total')}
                                                                                        </th>
                                                                                        <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-left">{payrollDetails.totalHoursForDisplay}</th>
                                                                                        <th className="border border-slate-300 text-right px-4" colSpan={4}>

                                                                                        </th>
                                                                                        <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right">
                                                                                            {Number(lineTotalAmount).toFixed(2)}
                                                                                        </th>
                                                                                    </tr>
                                                                                    </tfoot>
                                                                                </table>
                                                                            </div>

                                                                            <div className="mt-1 py-5 text-left">
                                                                                <div className="flex flex-wrap">
                                                                                    <div className="w-full mt-2">
                                                                                        <h3><strong>{t('AmountInWords')}</strong>: {payrollDetails.amountInWord}</h3>
                                                                                    </div>
                                                                                    <div className="w-full mt-1">
                                                                                        <p className="text-slate-800"><strong>{t('Comments')}</strong>: {payrollDetails.remarks ? payrollDetails.remarks : ''}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>

                                            </ScrollArea>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
        </>
    );
}
