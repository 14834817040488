import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineArrowRight, HiOutlineX, HiOutlineExclamation, HiOutlineRefresh, HiOutlineCheck } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import { ScrollArea, LoadingOverlay, TextInput} from '@mantine/core';
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {AiOutlineLoading3Quarters} from "react-icons/ai";




export default function PasswordUpdateModal({ setShowModal }) {
    const { token, loggedUserId } = JSON.parse(localStorage.getItem('user'))
    const { t, i18n } = useTranslation();
    const [spinner, setSpinner] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const navigate = useNavigate();


    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required(t('EnterOldPassword')),
        newPassword: Yup.string().required(t("EnterNewPassword")),
        confirmPassword: Yup.string().required(t("EnterNewPasswordAgain")),

    });
    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState, setValue} = useForm(formOptions);
    const {errors} = formState;

    function formSubmit(payload) {
        setSpinner(true);

        // const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        const format = /[\'^£$%&*()}{@#~?><>,|=_+¬-]/;

        payload['loggedUserId'] = loggedUserId;

        const oldPassword = payload['oldPassword'];
        const newPassword = payload['newPassword'];
        const confirmPassword = payload['confirmPassword'];


        if(confirmPassword != newPassword){
            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('Warning')),
                message: (t('ConfirmPasswordDoesNotMatch')),
                autoClose: 2000,
                disallowClose: true,
                color: 'red',
            });
            setSpinner(false);
            return;
        }

        if(! format.test(newPassword)){
            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('Warning')),
                message: (t('PasswordMustContainSpecialCharacters')),
                autoClose: 2000,
                disallowClose: true,
                color: 'red',
            });
            setSpinner(false);

            return;
        }

        if(oldPassword === newPassword){
            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('Warning')),
                message: (t('PasswordCanNotBeSame')),
                autoClose: 2000,
                disallowClose: true,
                color: 'red',
            });
            setSpinner(false);
            return;
        }

        if(newPassword.length < 8){
            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('Warning')),
                message: (t('PasswordLengthCanNotBeLessThen8')),
                autoClose: 2000,
                disallowClose: true,
                color: 'red',
            });
            setSpinner(false);
            return;
        }

        const passwordStrength = format.test(newPassword);
        console.log(payload, passwordStrength);

        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/update-password`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: payload
        })
            .then(res => {
                console.log(res)
                if(res.data.status != 202){
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Warning')),
                        message: res.data.message,
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });
                }else{
                    localStorage.clear();
                    navigate("/login")
                }
                setSpinner(false);

            })
            .catch(function (error) {
                console.log(error)
            });
    }

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.8]">
                <div className="relative w-1/3">
                    {/*content*/}
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="pt-4 modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('ChangePassword')}</h5>
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>

                            <div className="modal-body relative">
                                { <LoadingOverlay visible={spinner} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'sm' }} overlayColor="bg-indigo-100"/> }
                                <form id="horizontal-form" onSubmit={handleSubmit(formSubmit)}>
                                <div className="px-4 py-5 sm:p-6 text-left">
                                    <div className="w-full">
                                        <label htmlFor="oldPassword" className="form-input-sm-label-required">{t('OldPassword')}</label>
                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                            {
                                                errors.oldPassword?.type === 'required' &&
                                                <div className="form-input-sm-error">
                                                    <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.oldPassword?.message}
                                                </div>
                                            }
                                            <TextInput
                                                {...register("oldPassword")}
                                                type="text"
                                                name="oldPassword"
                                                id="oldPassword"
                                                placeholder={t("EnterOldPassword")}
                                                autoComplete="off"

                                            />
                                        </div>
                                    </div>
                                    <div className="w-full items-center">
                                        <label htmlFor="newPassword" className="form-input-sm-label-required">{t('NewPassword')}</label>
                                        <div
                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                            {
                                                errors.newPassword?.type === 'required' &&
                                                <div className="form-input-sm-error">
                                                    <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.newPassword?.message}
                                                </div>
                                            }
                                            <TextInput
                                                {...register("newPassword")}
                                                type="text"
                                                name="newPassword"
                                                id="newPassword"
                                                placeholder={t("EnterNewPassword")}
                                                description={'* ' + t('PasswordMustBe8CharactersLongAndContainSpecialCharacters')}
                                                inputWrapperOrder={['label', 'error', 'input', 'description']}
                                                autoComplete="off"

                                            />
                                        </div>
                                    </div>
                                    <div className="w-full items-center">
                                        <label htmlFor="confirmPassword" className="form-input-sm-label-required">{t('ConfirmPassword')}</label>
                                        <div
                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                            {
                                                errors.confirmPassword?.type === 'required' &&
                                                <div className="form-input-sm-error">
                                                    <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.confirmPassword?.message}
                                                </div>
                                            }
                                            <TextInput
                                                {...register("confirmPassword")}
                                                type="text"
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                placeholder={t("EnterNewPasswordAgain")}
                                                autoComplete="off"
                                                // description={'* ' + t('PasswordMustBe8CharactersLongAndContainSpecialCharacters')}
                                                // inputWrapperOrder={['label', 'error', 'input', 'description']}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <footer className="relative mt-1 border border-t">
                                    <div className="mr-3">
                                        <div className="text-right pt-0.5 mb-0.5">
                                        <button type='reset' className="px-6 py-2 text-gray-400 inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-gray-100 bg-transparent items-center rounded text-sm" >
                                            <HiOutlineRefresh size={16} className={'mr-2'}/>
                                            <span>{t('Reset')}</span>
                                        </button>
                                        <button
                                            disabled={spinner}
                                            type='submit'
                                            className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-sm">
                                            {
                                                spinner ? (<AiOutlineLoading3Quarters className={'mr-2 animate-spin'} size={16} />) : (<HiOutlineCheck size={16} className={'mr-2'} />)
                                            }
                                            <span>{t('Change')}</span>
                                        </button>
                                            
                                        </div>
                                    </div>
                                </footer>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red" />
        </>
    );
}
