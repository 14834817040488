import React, {useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiEye,
    HiOutlineChartSquareBar, HiOutlineExclamation,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiPencil, HiTrash
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Group, Text, Box, ScrollArea} from "@mantine/core";
import {FiAirplay, FiSave, FiX} from "react-icons/fi";
import {TbCurrencyTaka} from "react-icons/tb";
import {MdOutlineMonitorWeight} from "react-icons/md";
import {Switch} from "@headlessui/react";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import container from "postcss/lib/container";
import TablePagination from "../../datatable/TablePagination";
import ModalForm from "./Modal/ModalForm";


const PAGE_SIZE = 1;

function PageForm() {

    const {t, i18n} = useTranslation();
    const innerContainer = useRef(100);
    const height = innerContainer.current.clientHeight;
    const tableHeight = (height - 63);
    const fromHeight = height - 153 + "px";


    const [items, setItemTypes] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isAddForm, setAddForm] = useState(true);

    //Add Model Init
    let [isOpen, setIsOpen] = useState(false);

    //Submit Spinner Init
    const [spinner, SetSpinner] = useState(false);

    //Add Model close function
    function closeModal() {
        setIsOpen(false)
    }

    //Add Model Open function
    function openModal() {
        setIsOpen(true)
    }

    const [page, setPage] = useState(1);
    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setItemTypes(items.slice(from, to));
    }, [page]);

    //Form validation
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t("NameIsRequired"))
    });

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState} = useForm(formOptions);
    const {errors} = formState;


    //Form Data Submit
    function formSubmit(data) {
        // SetSpinner(true);
        const formData = {
            item_types: data.name,
            status: 1
        };
    }

    return (
        <>

            <main className="flex flex-1" ref={innerContainer}>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div
                                                className="w-full flex -ml-4 pl-4 h-12 bg-indigo-50 justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <div
                                                        className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24}
                                                                                 className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span
                                                            className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                                            {t('pageTitle')}
                                                            <sub
                                                                className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('pageSubTitle')}</sub></span>
                                                    </div>
                                                </div>
                                                <div className={"right flex mr-8"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300"
                                                             role="group">

                                                            <button onClick={event => setAddForm(prevCheck => !prevCheck)} className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>Add New </button>

                                                            <Link to='/master-data/item'
                                                                  className="inline-flex items-center px-4 py-2 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineChartSquareBar size={16}
                                                                                         className={'mr-1'}></HiOutlineChartSquareBar>Lists
                                                            </Link>
                                                            <Link to='/master-data/item'
                                                                  className="inline-flex justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-red-600 text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle
                                                                    size={16}></HiOutlineExclamationCircle>

                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex flex-1 overflow-hidden '}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex">
                                                            <div className="w-full">
                                                                <Box sx={{height: tableHeight}}>
                                                                    <TablePagination></TablePagination>
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                {isAddForm ?
                                                    <aside
                                                        className="sm:block  h-full  transition-all transition-shadow transition-transform	">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">
                                                                        <header className="relative mb-1 bg-indigo-600">
                                                                            <div
                                                                                className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-center flex justify-between">
                                                                                    <div
                                                                                        className="text-white   text-sm font-bold items-center leading-7">  {t('formTitle')}</div>
                                                                                    <button
                                                                                        onClick={event => setAddForm(prevCheck => !prevCheck)}
                                                                                        className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                        type="button">
                                                                                        <FiX></FiX>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>
                                                                        <form onSubmit={handleSubmit(formSubmit)}
                                                                              id="horizontal-form">
                                                                            <div
                                                                                className="relative flex flex-col min-w-0 break-words inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
                                                                                style={{height: fromHeight}}>
                                                                                <div
                                                                                    className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>

                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="first-name"
                                                                                               className="form-input-sm-label-required">{t('Name')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.name?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay
                                                                                                    size={16}></FiAirplay>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("name")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="name"
                                                                                                placeholder={t("EnterItemName")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="first-name"
                                                                                               className="form-input-sm-label-required">{t('Name')}
                                                                                        </label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            <div
                                                                                                className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                                                {errors.country?.type === 'required' &&
                                                                                                <div
                                                                                                    className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation
                                                                                                        size={16}
                                                                                                        className={'mr-2'}></HiOutlineExclamation> {errors.country?.message}
                                                                                                </div>}
                                                                                                <select
                                                                                                    {...register("country")}
                                                                                                    name="country"
                                                                                                    autoComplete="country-name"
                                                                                                    className={`form-input-sm-control ${errors.country ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}>
                                                                                                    <option
                                                                                                        value="">Select
                                                                                                        Country
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="United States">United
                                                                                                        States
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="Canada">Canada
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="Mexico">Mexico
                                                                                                    </option>
                                                                                                </select>
                                                                                                <ModalForm></ModalForm>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="first-name"
                                                                                               className="form-input-sm-label">{t('Name')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay
                                                                                                    size={16}></FiAirplay>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("test")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control`}
                                                                                                name="name"
                                                                                                placeholder={t("EnterItemName")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="first-name"
                                                                                               className="form-input-sm-label-required">{t('Name')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.name?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay
                                                                                                    size={16}></FiAirplay>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("name")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="name"
                                                                                                placeholder={t("EnterItemName")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="first-name"
                                                                                               className="form-input-sm-label-required">{t('Name')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.name?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay
                                                                                                    size={16}></FiAirplay>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("name")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="name"
                                                                                                placeholder={t("EnterItemName")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="first-name"
                                                                                               className="form-input-sm-label-required">{t('Name')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.name?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay
                                                                                                    size={16}></FiAirplay>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("name")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="name"
                                                                                                placeholder={t("EnterItemName")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="first-name"
                                                                                               className="form-input-sm-label-required">{t('Name')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.name?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay
                                                                                                    size={16}></FiAirplay>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("name")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="name"
                                                                                                placeholder={t("EnterItemName")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div
                                                                                        className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button
                                                                                            onClick={event => setAddForm(prevCheck => !prevCheck)}
                                                                                            type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX size={16}
                                                                                              className={'mr-2'}></FiX>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}></AiOutlineLoading3Quarters>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}></FiSave>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </footer>

                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default PageForm