import React, {Fragment, useEffect, useRef, useState} from 'react'
import {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ClockIcon,
    EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid'
import { Menu, Transition } from '@headlessui/react'
import {Link} from "react-router-dom";
//import MonthViewModal from './Views/MonthViewModal';

const days = [
    { date: '2021-12-27', events: [] },
    { date: '2021-12-28', events: [] },
    { date: '2021-12-29', events: [] },
    { date: '2021-12-30', events: [] },
    { date: '2021-12-31', events: [] },
    { date: '2022-01-01', isCurrentMonth: true, events: [] },
    { date: '2022-01-02', isCurrentMonth: true, events: [] },
    {
        date: '2022-01-03',
        isCurrentMonth: true,
        events: [
            { id: 1, type: 'button', name: 'Design review', time: '10AM', datetime: '2022-01-03T10:00', href: '/domain/month-view-modal' },
            { id: 2, name: 'Sales meeting', time: '2PM', datetime: '2022-01-03T14:00', href: '#' },
        ],
    },
    { date: '2022-01-04', isCurrentMonth: true, events: [] },
    { date: '2022-01-05', isCurrentMonth: true, events: [] },
    { date: '2022-01-06', isCurrentMonth: true, events: [] },
    {
        date: '2022-01-07',
        isCurrentMonth: true,
        events: [{ id: 3, name: 'Soccer Game', time: '6PM', datetime: '2022-01-08T18:00', href: '#' }],
    },
    { date: '2022-01-08', isCurrentMonth: true, events: [] },
    { date: '2022-01-09', isCurrentMonth: true, events: [] },
    { date: '2022-01-10', isCurrentMonth: true, events: [] },
    { date: '2022-01-11', isCurrentMonth: true, events: [] },
    {
        date: '2022-01-12',
        isCurrentMonth: true,
        isToday: true,
        
        events: [{ id: 6, name: "Sam's birthday party", time: '2PM', datetime: '2022-01-25T14:00', href: ''}],
       
    }, 
    { date: '2022-01-13', isCurrentMonth: true, events: [] },
    { date: '2022-01-14', isCurrentMonth: true, events: [] },
    { date: '2022-01-15', isCurrentMonth: true, events: [] },
    { date: '2022-01-16', isCurrentMonth: true, events: [] },
    { date: '2022-01-17', isCurrentMonth: true, events: [] },
    { date: '2022-01-18', isCurrentMonth: true, events: [] },
    { date: '2022-01-19', isCurrentMonth: true, events: [] },
    { date: '2022-01-20', isCurrentMonth: true, events: [] },
    { date: '2022-01-21', isCurrentMonth: true, events: [] },
    {
        date: '2022-01-22',
        isCurrentMonth: true,
        isSelected: true,
        events: [
            { id: 4, name: 'Maple syrup museum', time: '3PM', datetime: '2022-01-22T15:00', href: '#' },
            { id: 5, name: 'Hockey game', time: '7PM', datetime: '2022-01-22T19:00', href: '#' },
        ],
    },
    { date: '2022-01-23', isCurrentMonth: true, events: [] },
    { date: '2022-01-24', isCurrentMonth: true, events: [] },
    { date: '2022-01-25', isCurrentMonth: true, events: [] },
    { date: '2022-01-26', isCurrentMonth: true, events: [] },
    { date: '2022-01-27', isCurrentMonth: true, events: [] },
    { date: '2022-01-28', isCurrentMonth: true, events: [] },
    { date: '2022-01-29', isCurrentMonth: true, events: [] },
    { date: '2022-01-30', isCurrentMonth: true, events: [] },
    { date: '2022-01-31', isCurrentMonth: true, events: [] },
    { date: '2022-02-01', events: [] },
    { date: '2022-02-02', events: [] },
    {
        date: '2022-02-03',
        events: [{ id: 7, name: 'Cinema with friends', time: '9PM', datetime: '2022-02-04T21:00', href: '#' }],
    },
    { date: '2022-02-04', events: [] },
    { date: '2022-02-05', events: [] },
    { date: '2022-02-06', events: [] },
]
const selectedDay = days.find((day) => day.isSelected)

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
import {useTranslation} from "react-i18next";
import AgencyCalendar from "../agency/AgencyCalendar";
import MonthViewModal from './Views/MonthViewModal';

function Dashboard(){
    const {t, i18n} = useTranslation();
    const [height, setHeight] = useState(100)
    const innerContainer = useRef(null)
    useEffect(() => {
        setHeight(innerContainer.current.clientHeight)
    },[innerContainer])


    /* const innerContainer = useRef(100);
     const height = innerContainer.current.clientHeight;*/
    // console.log(height);
    // console.log(innerContainer);
    const calendarHeight = (height-120) + "px";
    return(
        <>
            <main className="flex flex-1">
                <div className="min-h-full w-full" ref={innerContainer}>
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full h-12 px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className={'flex flex-1 overflow-hidden -ml-4'}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full px-2 py-1" >
                                                                <div className="h-full flex-1" id={'calendr-area'} >
                                                                    <header className="flex items-center justify-between  px-6 mb-3 mt-1 lg:flex-none">
                                                                        <h1 className="text-lg font-semibold text-gray-900">
                                                                            <time dateTime="2022-01">January 2022</time>
                                                                        </h1>
                                                                        <div className="flex items-center">

                                                                            {/* Day View Section */}
                                                                            <div className="flex items-center rounded-md shadow-sm md:items-stretch">
                                                                                <button
                                                                                    type="button"
                                                                                    className="flex items-center justify-center rounded-l-md border border-r-0 border-blue-200 bg-blue-200 py-2 pl-3 pr-4 text-gray-400 hover:text-blue-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                                                                                >
                                                                                    <span className="sr-only">Yesterday</span>
                                                                                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </button>
                                                                                <Link to="/domain/day-view"
                                                                                    type="button"
                                                                                    className="hidden border-t border-b border-blue-200 bg-blue-200 px-5 py-2 text-sm font-medium text-gray-700 hover:bg-blue-500 hover:text-gray-900 focus:relative md:block"
                                                                                    
                                                                                >
                                                                                 Day View   
                                                                                </Link>

                                                                                
                                                                                <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
                                                                                <button
                                                                                    type="button"
                                                                                    className="flex items-center justify-center rounded-r-md border border-l-0 border-blue-200 bg-blue-200 py-2 pl-4 pr-3 text-gray-400 hover:text-blue-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                                                                                >
                                                                                    <span className="sr-only">Tomorrow</span>
                                                                                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </button>
                                                                            </div>

                                                                            {/* Week View */}
                                                                            <div className="flex items-center rounded-md shadow-sm md:items-stretch">
                                                                                <button
                                                                                    type="button"
                                                                                    className="flex items-center justify-center rounded-l-md border border-r-0 border-violet-400 bg-violet-400 py-2 pl-3 pr-4 text-white hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                                                                                >
                                                                                    <span className="sr-only">Last Week</span>
                                                                                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </button>
                                                                                <Link to="/domain/week-view"
                                                                                    type="button"
                                                                                    className="hidden border-t border-b border-violet-400 bg-violet-400 px-5 py-2 text-sm font-medium text-white hover:bg-gray-50 hover:text-gray-500 focus:relative md:block"
                                                                                    
                                                                                >
                                                                                 Week View   
                                                                                </Link>

                                                                                
                                                                                <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
                                                                                <button
                                                                                    type="button"
                                                                                    className="flex items-center justify-center rounded-r-md border border-l-0 border-violet-400 bg-violet-400 py-2 pl-4 pr-3 text-white hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                                                                                >
                                                                                    <span className="sr-only">Next Week</span>
                                                                                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </button>
                                                                            </div>

                                                                            {/* Month View */}
                                                                            <div className="flex items-center rounded-md shadow-sm md:items-stretch">
                                                                                <button
                                                                                    type="button"
                                                                                    className="flex items-center justify-center rounded-l-md border border-r-0 border-blue-500 bg-blue-500 py-2 pl-3 pr-4 text-gray-700 hover:text-blue-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                                                                                >
                                                                                    <span className="sr-only">Yesterday</span>
                                                                                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </button>
                                                                                <Link to="/domain"
                                                                                    type="button"
                                                                                    className="hidden border-t border-b border-blue-500 bg-blue-500 text-justify-center px-5 py-2 text-sm font-medium text-gray-700 hover:bg-blue-500 hover:text-gray-900 focus:relative md:block"
                                                                                    
                                                                                >
                                                                                    
                                                                                 Month View   
                                                                                </Link>

                                                                                
                                                                                <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
                                                                                <button
                                                                                    type="button"
                                                                                    className="flex items-center justify-center rounded-r-md border border-l-0 border-blue-500 bg-blue-500 py-2 pl-4 pr-3 text-gray-700 hover:text-blue-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                                                                                >
                                                                                    <span className="sr-only">Tomorrow</span>
                                                                                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </button>
                                                                            </div>

                                                                            
                                                                            {/* Year View */}
                                                                            <div className="flex items-center rounded-md shadow-sm md:items-stretch">
                                                                                <button
                                                                                    type="button"
                                                                                    className="flex items-center justify-center rounded-l-md border border-r-0 border-violet-400 bg-violet-400 py-2 pl-3 pr-4 text-white hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                                                                                >
                                                                                    <span className="sr-only">Last Year</span>
                                                                                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </button>
                                                                                <Link to="/domain/year-view"
                                                                                    type="button"
                                                                                    className="hidden border-t border-b border-violet-400 bg-violet-400 px-5 py-2 text-sm font-medium text-white hover:bg-gray-50 hover:text-gray-500 focus:relative md:block"
                                                                                    
                                                                                >
                                                                                    Year View   
                                                                                     </Link>               

                                                                                
                                                                                <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
                                                                                <button
                                                                                    type="button"
                                                                                    className="flex items-center justify-center rounded-r-md border border-l-0 border-violet-400 bg-violet-400 py-2 pl-4 pr-3 text-white hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                                                                                >
                                                                                    <span className="sr-only">Next Year</span>
                                                                                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </button>
                                                                            </div>
                                                                                
                                                                                {/* Add Appointment */}
                                                                                <div className="ml-6 h-6 w-px bg-gray-300" />
                                                                                <button
                                                                                    type="button"
                                                                                    className="ml-6 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                                >
                                                                                    Add Appointment
                                                                                </button>
                                                                            
                                                                            <Menu as="div" className="relative ml-6 md:hidden">
                                                                                <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
                                                                                    <span className="sr-only">Open menu</span>
                                                                                    <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </Menu.Button>

                                                                                <Transition
                                                                                    as={Fragment}
                                                                                    enter="transition ease-out duration-100"
                                                                                    enterFrom="transform opacity-0 scale-95"
                                                                                    enterTo="transform opacity-100 scale-100"
                                                                                    leave="transition ease-in duration-75"
                                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                                    leaveTo="transform opacity-0 scale-95"
                                                                                >
                                                                                    <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                        <div className="py-1">
                                                                                            <Menu.Item>
                                                                                                {({ active }) => (
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        className={classNames(
                                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                            'block px-4 py-2 text-sm'
                                                                                                        )}
                                                                                                    >
                                                                                                        Create Appintment
                                                                                                    </a>
                                                                                                )}
                                                                                            </Menu.Item>
                                                                                        </div>
                                                                                        <div className="py-1">
                                                                                            <Menu.Item>
                                                                                                {({ active }) => (
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        className={classNames(
                                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                            'block px-4 py-2 text-sm'
                                                                                                        )}
                                                                                                    >
                                                                                                        Go to today
                                                                                                    </a>
                                                                                                )}
                                                                                            </Menu.Item>
                                                                                        </div>
                                                                                        <div className="py-1">
                                                                                            <Menu.Item>
                                                                                                {({ active }) => (
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        className={classNames(
                                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                            'block px-4 py-2 text-sm'
                                                                                                        )}
                                                                                                    >
                                                                                                        Day view
                                                                                                    </a>
                                                                                                )}
                                                                                            </Menu.Item>
                                                                                            <Menu.Item>
                                                                                                {({ active }) => (
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        className={classNames(
                                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                            'block px-4 py-2 text-sm'
                                                                                                        )}
                                                                                                    >
                                                                                                        Week view
                                                                                                    </a>
                                                                                                )}
                                                                                            </Menu.Item>
                                                                                            <Menu.Item>
                                                                                                {({ active }) => (
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        className={classNames(
                                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                            'block px-4 py-2 text-sm'
                                                                                                        )}
                                                                                                    >
                                                                                                        Month view
                                                                                                    </a>
                                                                                                )}
                                                                                            </Menu.Item>
                                                                                            <Menu.Item>
                                                                                                {({ active }) => (
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        className={classNames(
                                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                            'block px-4 py-2 text-sm'
                                                                                                        )}
                                                                                                    >
                                                                                                        Year view
                                                                                                    </a>
                                                                                                )}
                                                                                            </Menu.Item>
                                                                                        </div>
                                                                                    </Menu.Items>
                                                                                </Transition>
                                                                            </Menu>
                                                                        </div>
                                                                    </header>
                                                                    <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col ml-3">
                                                                        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                                                                            <div className="bg-white py-2">
                                                                                M<span className="sr-only sm:not-sr-only">on</span>
                                                                            </div>
                                                                            <div className="bg-white py-2">
                                                                                T<span className="sr-only sm:not-sr-only">ue</span>
                                                                            </div>
                                                                            <div className="bg-white py-2">
                                                                                W<span className="sr-only sm:not-sr-only">ed</span>
                                                                            </div>
                                                                            <div className="bg-white py-2">
                                                                                T<span className="sr-only sm:not-sr-only">hu</span>
                                                                            </div>
                                                                            <div className="bg-white py-2">
                                                                                F<span className="sr-only sm:not-sr-only">ri</span>
                                                                            </div>
                                                                            <div className="bg-white py-2">
                                                                                S<span className="sr-only sm:not-sr-only">at</span>
                                                                            </div>
                                                                            <div className="bg-white py-2">
                                                                                S<span className="sr-only sm:not-sr-only">un</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                                                                            <div
                                                                                className="relative flex flex-col min-w-0 break-words inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
                                                                                style={{height:calendarHeight}}>
                                                                                <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
                                                                                    {days.map((day) => (
                                                                                        <div
                                                                                            key={day.date}
                                                                                            className={classNames(
                                                                                                day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                                                                                                'relative py-2 px-3'
                                                                                            )}
                                                                                        >
                                                                                            <time
                                                                                                dateTime={day.date}
                                                                                                className={
                                                                                                    day.isToday
                                                                                                        ? 'flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white'
                                                                                                        : undefined
                                                                                                }
                                                                                            >
                                                                                                {day.date.split('-').pop().replace(/^0/, '')}
                                                                                            </time>
                                                                                            {day.events.length > 0 && (
                                                                                                <ol className="mt-2">
                                                                                                    {day.events.slice(0, 2).map((event) => (
                                                                                                        <li key={event.id}>
                                                                                                            <a href={event.href} className="group flex">
                                                                                                                <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                                                                                                                    {event.name}
                                                                                                                </p>
                                                                                                                <time
                                                                                                                    dateTime={event.datetime}
                                                                                                                    className="ml-3 hidden flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
                                                                                                                >
                                                                                                                    {event.time}
                                                                                                                </time>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    ))}
                                                                                                    {day.events.length > 2 && <li className="text-gray-500">+ {day.events.length - 2} more</li>}
                                                                                                </ol>
                                                                                            )}
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                                <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                                                                                    {days.map((day) => (
                                                                                        <button
                                                                                            key={day.date}
                                                                                            type="button"
                                                                                            className={classNames(
                                                                                                day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                                                                                                (day.isSelected || day.isToday) && 'font-semibold',
                                                                                                day.isSelected && 'text-white',
                                                                                                !day.isSelected && day.isToday && 'text-indigo-600',
                                                                                                !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                                                                                                !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                                                                                                'flex h-14 flex-col py-2 px-3 hover:bg-gray-100 focus:z-10'
                                                                                            )}
                                                                                        >
                                                                                            <time
                                                                                                dateTime={day.date}
                                                                                                className={classNames(
                                                                                                    day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                                                                                                    day.isSelected && day.isToday && 'bg-indigo-600',
                                                                                                    day.isSelected && !day.isToday && 'bg-gray-900',
                                                                                                    'ml-auto'
                                                                                                )}
                                                                                            >
                                                                                                {day.date.split('-').pop().replace(/^0/, '')}
                                                                                            </time>
                                                                                            <span className="sr-only">{day.events.length} events</span>
                                                                                            {day.events.length > 0 && (
                                                                                                <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                    {day.events.map((event) => (
                        <span key={event.id} className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
                    ))}
                  </span>
                                                                                            )}
                                                                                        </button>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {selectedDay?.events.length > 0 && (
                                                                        <div className="py-10 px-4 sm:px-6 lg:hidden">
                                                                            <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
                                                                                {selectedDay.events.map((event) => (
                                                                                    <li key={event.id} className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
                                                                                        <div className="flex-auto">
                                                                                            <p className="font-semibold text-gray-900">{event.name}</p>
                                                                                            <time dateTime={event.datetime} className="mt-2 flex items-center text-gray-700">
                                                                                                <ClockIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                                                {event.time}
                                                                                            </time>
                                                                                        </div>
                                                                                        <a
                                                                                            href={event.href}
                                                                                            className="ml-6 flex-none self-center rounded-md border border-gray-300 bg-white py-2 px-3 font-semibold text-gray-700 opacity-0 shadow-sm hover:bg-gray-50 focus:opacity-100 group-hover:opacity-100"
                                                                                        >
                                                                                            Edit<span className="sr-only">, {event.name}</span>
                                                                                        </a>
                                                                                    </li>
                                                                                ))}
                                                                            </ol>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </main>
        </>
    )
}
export default Dashboard