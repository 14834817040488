import React from "react";
import { HiOutlineExclamation } from "react-icons/hi";
const InlineInput = ({
    labelText,
    labelFor,
    errors,
    icon,
    name,
    placeholder,
    registerForm,
    type,
}) => {
    return (
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
            <label
                htmlFor={labelFor}
                className="inline-flex  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
                {labelText}
                <span className="text-red-500 flex mt-1 ml-1">*</span>
            </label>
            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                {errors.name?.type === "required" && (
                    <div className="flex bg-red-50 text-red-900 text-xs border border-1 border-red-300  py-1 px-2 right-0 absolute z-30 w-full -top-6">
                        <HiOutlineExclamation
                            size={16}
                            className={"mr-2"}
                        ></HiOutlineExclamation>{" "}
                        {errors.name?.message}
                    </div>
                )}
                <div className="absolute right-0 text-gray-400 flex items-center pr-3 h-full cursor-pointer">
                    {icon}
                </div>
                <input
                    {...registerForm}
                    type={type}
                    className={`form-input-sm-control ${
                        errors.name
                            ? "border-red-300 focus:border-red-600"
                            : "border-gray-300 focus:border-blue-600"
                    } `}
                    name={name}
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
};

export default InlineInput;
