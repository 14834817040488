import React, { Fragment, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import button from "@material-tailwind/react";
import { HiHome, HiOutlineCalendar, HiOutlineExclamation, HiOutlineX, HiCheckCircle, HiArrowCircleRight } from "react-icons/hi";
import { ScrollArea, Table, LoadingOverlay, Select, Grid, Group, Text, Stepper, Button, TextInput, PasswordInput, Code, Accordion, List, } from "@mantine/core";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiArrowRight } from "react-icons/fi";
import * as Yup from "yup";
import { format } from 'date-fns';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import ViewCandidateProfile from "../../candidate/modal/ViewCandidate";
// import { useForm } from '@mantine/form';
import {DatePicker} from '@mantine/dates';
import { Link, useNavigate } from "react-router-dom";



export default function AddEventModal({ setShowModal, activeTab }) {
    const { t, i18n } = useTranslation();
    const { token, loggedUserId, roles, domainId } = JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate();

    const modalHeight = localStorage.getItem("modalHeight");
    const [spinner, setSpinner] = useState(false);
    const [clients, setClients] = useState([]);
    const [callClientPosition, setCallClientPosition] = useState(true);
    const [positions, setPositions] = useState([]);
    const [countryId, setCountryId] = useState(null);
    const [country, setCountry] = useState(null);
    const [showLoader, setShowLoader] = useState(true);
    const [inCompletePreviousEvent, setInCompletePreviousEvent] = useState({});



    const validationSchema = Yup.object().shape({
        title: Yup.string().required(t('TitleIsRequired')),
        date: Yup.string().required(t('DateIsRequired')),
        location: Yup.string().required(t('LocationIsRequired')),
        clientId: Yup.string().required(t('ClientIsRequired')),
        // parameters: Yup.array().min(1).of(Yup.string().required()).required(),

    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState, setValue } = useForm(formOptions);
    const { errors } = formState;

    const [domainDetails, setDomainDetails] = useState({});
    const getDomainDetails = () => {
        axios({
            method: 'get',
            // url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/active-clients`,
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/domains/${domainId}`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            }
        })
            .then(res => {
                setDomainDetails(res.data.data);
                if(res.data.data.country){
                    const countryId = Number(res.data.data.country.split('-')[0]);
                    // console.log(res.data.data.country)

                    setCountryId(Number(res.data.data.country.split('-')[0]));
                    setCountry(res.data.data.country);

                }

            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const getInCompletePreviousEvent = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/jobs/`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                status: ['in-progress'],
                jobType: 'previous',
                domainId,
                loggedUserId,
                roles,
            }
        })
            .then(res => {
                setInCompletePreviousEvent(res.data);
                setShowLoader(false);
              
            })
            .catch(function (error) {
                console.log(error)
            });
    }


    const getClients = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/client`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                status: "client"
            }
        })
            .then(res => {
                setClients(res.data.data)

            })
            .catch(function (error) {
                console.log(error)
            })
    }

    useEffect(()=>{
        getClients();
        getDomainDetails();
        getInCompletePreviousEvent();
    }, []);

    function formSubmit(payload) {
        setSpinner(true);
        payload['domainId'] = domainId;
        payload['createdBy'] = loggedUserId;
        payload['device'] = 'web';
        payload['country'] = country;
       axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/previous-job-from-time-sheet`,
        headers: {
            "Accept": `application/json`,
            "Content-Type": `application/json`,
            "Access-Control-Allow-Origin": '*',
            "Authorization": `Bearer ${token}`
        },
        data: payload
    })
        .then(res => {
            setSpinner(false);
            setShowModal(false);

            navigate('/time-sheet/' + res.data.data.jobId + '/previous-event');
        })
        .catch(function (error) {
            console.log(error)
        });

    }

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-2/3">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-gray-800 relative">
                            {spinner && <LoadingOverlay visible={true} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'sm' }} overlayColor="bg-indigo-100" />}

                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('PreviousEvent')}</h5>
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <Accordion transitionDuration={500}>
                                    <Accordion.Item value="in-complete-previous-jobs">
                                        <Accordion.Control py="xs" className={`${inCompletePreviousEvent['total'] > 0 ? 'bg-red-200 hover:bg-red-300' : 'bg-green-200 hover:bg-green-200'}`}>
                                        <span className="relative mr-1">
                                            <LoadingOverlay visible={showLoader} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'xs' }} overlayColor="bg-indigo-100" />
                                            {inCompletePreviousEvent['total']}
                                        </span> 
                                             {t('InCompletePreviousEvent')}
                                        </Accordion.Control> 
                                        <Accordion.Panel className="bg-indigo-100">
                                            <ScrollArea h={inCompletePreviousEvent['total'] > 0 ? 100 : 0} scrollbarSize={4}>
                                            <List
                                                spacing="xs"
                                                size="sm"
                                                center
                                                icon={ <HiArrowCircleRight className="text-indigo-800"/> }
                                            >
                                                {
                                                    inCompletePreviousEvent['total'] > 0 && inCompletePreviousEvent['data'].map(job => (
                                                        <List.Item key={job.id}>
                                                            <Link
                                                                to={`/time-sheet/${job['id']}/previous-event`} 
                                                                className="hover:underline hover:text-indigo-800"
                                                            >{job['title']}</Link>
                                                        </List.Item>
                                                    ))
                                                }
                                                    
                                                    </List>
                                                <ul>
                                                    
                                                    
                                                </ul>
                                            </ScrollArea>
                                            
                                        </Accordion.Panel>
                                        
                                    </Accordion.Item>
                                </Accordion>
                                <form onSubmit={handleSubmit(formSubmit)}>
                                    <div className="container mx-auto">
                                        <div className="flex flex-row flex-wrap">
                                            <div role="main" className="w-full pt-5 px-2">
                                                <div className="w-full items-center">
                                                    <label htmlFor="client" className="form-input-sm-label-required">{t('Client')}</label>
                                                    <div
                                                        className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                        {
                                                            errors.clientId?.type === 'required' &&
                                                            <div className="form-input-sm-error">
                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.clientId?.message}
                                                            </div>
                                                        }
                                                        <Select
                                                            searchable
                                                            placeholder={t('SelectClient')}
                                                            data={clients.map(client => ({ value: client['id'], label: client['name'] }))}
                                                            {...register("clientId")}
                                                            id="client"
                                                            onChange={e => setValue('clientId', e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full items-center">
                                                    <label htmlFor="jobTitle" className="form-input-sm-label-required">{t('JobTitle')}</label>
                                                    <div
                                                        className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                        {
                                                            errors.title?.type === 'required' &&
                                                            <div className="form-input-sm-error">
                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.title?.message}
                                                            </div>
                                                        }
                                                        <TextInput
                                                            autoComplete="off"
                                                            placeholder={t('JobTitle')} 
                                                            {...register("title")}
                                                            id="jobTitle"

                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full items-center">
                                                    <label htmlFor="jobHash" className="form-input-sm-label">{t('Job#')}</label>
                                                    <div
                                                        className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                        {
                                                            errors.jobHash?.type === 'required' &&
                                                            <div className="form-input-sm-error">
                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.jobHash?.message}
                                                            </div>
                                                        }
                                                        <TextInput
                                                            autoComplete="off"
                                                            placeholder={t('job#')} 
                                                            {...register("jobHash")}
                                                            id="jobHash"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full items-center">
                                                    <label htmlFor="date" className="form-input-sm-label-required">{t('Date')}</label>
                                                    <div
                                                        className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                        {
                                                            errors.date?.type === 'required' &&
                                                            <div className="form-input-sm-error">
                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.date?.message}
                                                            </div>
                                                        }
                                                        <DatePicker
                                                            radius={0}
                                                            placeholder="Date"
                                                            {...register("date")}
                                                            maxDate={new Date()}
                                                            id="date"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full items-center">
                                                    <label htmlFor="location" className="form-input-sm-label-required">{t('Location')}</label>
                                                    <div
                                                        className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                        {
                                                            errors.location?.type === 'required' &&
                                                            <div className="form-input-sm-error">
                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.location?.message}
                                                            </div>
                                                        }
                                                        <TextInput
                                                            autoComplete="off"
                                                            placeholder={t('Location')} 
                                                            {...register("location")}
                                                            id="location"

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <footer className="relative mt-1">
                                        <div className="text-right pt-0.5 mb-0.5">
                                            <button
                                                type="submit"
                                                className="px-2 py-1 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-sm"
                                            >
                                                <span>{t('Next')}</span>
                                                {
                                                    spinner ? (<AiOutlineLoading3Quarters className={'ml-1 animate-spin'} size={16} />) : (<FiArrowRight size={16} className={'ml-1'} />)
                                                }
                                            </button>
                                        </div>
                                    </footer>
                                    
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red" />
        </>
    );
}