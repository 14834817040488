import {Document, Page, PDFViewer, Text, View, Image} from "@react-pdf/renderer";
import styles from "../../../pdf-styles";
import {Grid, ScrollArea, Select, TextInput, Textarea} from "@mantine/core";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {
    HiChevronLeft,
    HiDocumentDownload,
    HiOutlineCurrencyDollar,
    HiOutlineExclamation,
    HiOutlineOfficeBuilding, HiOutlinePlus, HiPencil, HiEye
} from "react-icons/hi";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import axios from "axios";
import {FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {useForm} from "react-hook-form";
import {showNotification} from "@mantine/notifications";
import {DatePicker} from "@mantine/dates";
import dayjs from "dayjs";
import {useNavigate} from "react-router";
import ViewPayroll from "./modal/ViewPayroll";
import * as Yup from "yup";
import PayrollDetailTr from "./inc/payrollDetailTr";


function PayrollUpdate() {

    const {domainId, loggedUserId ,roles, token} = JSON.parse(localStorage.getItem('user'));
    const hasRoleModerator = roles.find(role => {
        return role === "ROLE_MODERATOR";
    })
    const {payrollId} = useParams();
    const {t, i18n} = useTranslation();
    const [payrollDetails, setPayrollDetails] = useState({});
    const fullFormHeight = localStorage.getItem('fullFormHeight');
    const navigate = useNavigate();
    const [taxData, setTaxData] = useState(0.00);
    const [totalAmountData, setTotalAmountData] = useState(0.00);
    const [showModal, setShowModal] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [recall, setRecall] = useState(false);
    const [accessType, setAccessType] = useState(false);

    let paymentStatusDropdown = [];

    const paymentStatusArray = [
            {'label':'Created','value':'CREATED'},
            {'label':'Paid','value':'PAID'},
            {'label':'Disputed','value':'DISPUTED'}
        ];

    if (paymentStatusArray) {
        paymentStatusDropdown =
            paymentStatusArray.map((type, index) => {
                return ({
                    'label': type.label, 'value': type.value
                })
            })
    }

    // on change company status data get
    const PaymentStatusDataHandel = (e) => {
        setPaymentStatus(e);
        setValue('paymentStatus',e)
    }




    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/payroll/${payrollId}/show`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                if (res.status === 200){
                    setPayrollDetails(res.data.data)
                    setPaymentStatus(res.data.data.paymentStatus)
                    setValue('payrollDate',res.data.data.payrollDate ? format(new Date(res.data.data.payrollDate.date), 'yyyy-MM-dd') : '')
                    setValue('dueDate',res.data.data.dueDate ? format(new Date(res.data.data.dueDate.date), 'yyyy-MM-dd') : '')
                    // setValue('termsAndConditions',res.data.data.termsAndConditions ? res.data.data.termsAndConditions : '')
                    setValue('remarks',res.data.data.remarks ? res.data.data.remarks : '')
                    // setValue('tax',res.data.data.taxRate ? res.data.data.taxRate : '')
                    // setTaxData(res.data.data.taxRate?res.data.data.taxRate:0.00)
                    setValue('paymentStatus',res.data.data.paymentStatus)
                    setValue('payrollId', res.data.data.payrollId ? res.data.data.payrollId:'')

                    setAccessType(res.data.data.accessType==='public'?true:false);
                }
            });
    }, [recall])


    const payrollView = (payrollId) => {

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/payroll/${payrollId}/show`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                if (res.status === 200){
                    setPayrollDetails(res.data.data)
                    setShowModal(!!setPayrollDetails)
                }
            });
    }



    const TaxDataHandel = (e) => {
        setTaxData(e.target.value);
    }

    const {register, handleSubmit, reset, formState, setValue} = useForm();
    const {errors} = formState;


    //Form Data Submit
    function formSubmit(data) {

        data['paymentStatus']= paymentStatus;
        data['accessType']=accessType;

       if(data){
           axios({
               method: 'put',
               url: `${process.env.REACT_APP_API_GATEWAY_URL}/payroll/${payrollId}/update`,
               headers: {
                   "Accept": `application/json`,
                   "Content-Type": `application/json`,
                   "Access-Control-Allow-Origin": '*',
                   "Authorization": `Bearer ${token}`
               },
               data: data
           })
               .then(res => {

                   if (res.data.status === 202) {
                       showNotification({
                           id: 'load-data',
                           loading: true,
                           title: (t('UpdatedSuccessfully')),
                           message: (t('DataLoad')),
                           autoClose: 500,
                           disallowClose: true,
                           color: 'green',
                       });
                       navigate('/payroll');
                   }else {
                       showNotification({
                           id: 'load-data',
                           loading: true,
                           title: (t('Error')),
                           message: (t('PayrollUpdateNotPermission')),
                           autoClose: 500,
                           disallowClose: true,
                           color: 'red',
                       });
                   }
               })
               .catch(function (error) {
                   console.log(error)
               })
       }

    }

    var lineTotalAmount = payrollDetails && payrollDetails.amount>0?(payrollDetails.amount).toFixed(2):0;

    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Payroll')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>
                                    {t('PayrollInformation')}
                                </sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <Link to={`/payroll/`+payrollDetails.id+`/pdf`}
                                      className="inline-flex items-center text-right font-normal text-red-600 ">
                                    <HiDocumentDownload className="h-5 w-5" aria-hidden="true"/> {t('GeneratePDF')}
                                </Link>

                                {/*<button onClick={(e) => payrollView(payrollDetails.id)}
                                    className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiEye size={16} className={'mr-1'}/>{t('ViewPayroll')}
                                </button>*/}

                                <Link to={`/payroll/`+payrollDetails.id+`/view`}
                                      className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiEye size={16} className={'mr-1'}/>{t('ViewPayroll')}
                                </Link>

                                { hasRoleModerator &&
                                <Link to={'/payroll/create'}>
                                    <button
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    >
                                        <HiOutlinePlus size={16} className={'mr-1'}/>{t('Generate Payroll')}
                                    </button>
                                </Link>
                                }
                                <Link to='/payroll'
                                      className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={16} className={'mr-1'}/>{t('Back')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border">
                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                        <ScrollArea style={{ height: fullFormHeight }} scrollbarSize={4}>
                                                            <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full bg-gray-200">
                                                                <div className="mt-5 md:mt-0">
                                                                    <div
                                                                        className="shadow sm:overflow-hidden sm:rounded-md">
                                                                        <div className="bg-gray-50 px-4 py-5 sm:p-6">
                                                                            <div className="mb-3 w-full items-center">
                                                                                <label htmlFor="CandidateName"
                                                                                       className="form-input-sm-label">{t('CandidateName')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                    <p className="form-input-sm-control border-gray-300">
                                                                                        {payrollDetails.employeeName}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                <div className="mb-3 w-1/2 items-center">
                                                                                    <label htmlFor="payrollId"
                                                                                           className="form-input-sm-label">{t('PayrollNO')}</label>
                                                                                    <div
                                                                                        className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                        {/*<p className="form-input-sm-control border-gray-300">
                                                                                            {payrollDetails.payrollId}
                                                                                        </p>*/}
                                                                                        <input
                                                                                            {...register("payrollId")}
                                                                                            type="text"
                                                                                            className={`form-input-sm-control ${errors.toDate ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                            name="payrollId"
                                                                                            id="payrollId"
                                                                                            placeholder={t("Payroll number")}
                                                                                            defaultValue={payrollDetails && payrollDetails.payrollId ? payrollDetails.payrollId : ''}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="mb-3 w-1/2 items-center">
                                                                                    <label htmlFor="payrollId"
                                                                                           className="form-input-sm-label">{t('PaymentStatus')}</label>
                                                                                    <div
                                                                                        className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                        <Select
                                                                                            {...register("paymentStatus")}
                                                                                            placeholder={t('ChoosePaymentStatus')}
                                                                                            searchable clearable
                                                                                            allowDeselect
                                                                                            nothingFound="No options"
                                                                                            data={paymentStatusDropdown}
                                                                                            transition="pop-top-left"
                                                                                            transitionDuration={80}
                                                                                            transitionTimingFunction="ease"
                                                                                            onChange={PaymentStatusDataHandel}
                                                                                            value={paymentStatus}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                <div className="mb-3 w-1/2 items-center">
                                                                                    <label htmlFor="payrollDate"
                                                                                           className="form-input-sm-label">{t('PayrollDate')}</label>

                                                                                    <div
                                                                                        className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                        <input
                                                                                            {...register("payrollDate")}
                                                                                            type="date"
                                                                                            className={`form-input-sm-control ${errors.toDate ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                            name="payrollDate"
                                                                                            id="payrollDate"
                                                                                            placeholder={t("PayrollDate")}
                                                                                            defaultValue={payrollDetails && payrollDetails.payrollDate ? format(new Date(payrollDetails.payrollDate.date), 'yyyy-MM-dd') : ''}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="mb-3 w-1/2 items-center">
                                                                                    <label htmlFor="dueDate"
                                                                                           className="form-input-sm-label">{t('DueDate')}</label>
                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                        <input
                                                                                            {...register("dueDate")}
                                                                                            type="date"
                                                                                            className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                                                            name="dueDate"
                                                                                            id="dueDate"
                                                                                            placeholder={t("dueDate")}
                                                                                            defaultValue={payrollDetails && payrollDetails.dueDate ? format(new Date(payrollDetails.dueDate.date), 'yyyy-MM-dd') : ''}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                            </div>


                                                                            <Grid columns={24}>
                                                                                <Grid.Col span={24}>
                                                                                    <div className="w-full inner-div-scroll pb-10">
                                                                                        <table className="border-collapse border border-slate-400 min-w-full" >
                                                                                            <thead className="bg-white">
                                                                                            <tr>
                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('JobTitle')}
                                                                                                </th>
                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('Date')}
                                                                                                </th>
                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                    {t('Designation')}
                                                                                                </th>
                                                                                                <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('RegularRate')}
                                                                                                </th>
                                                                                                <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('OverTimeRate')}
                                                                                                </th>

                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                    {t('TotalHours')}
                                                                                                </th>
                                                                                                <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('Regular')}
                                                                                                </th>
                                                                                                <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('OverTime')}
                                                                                                </th>
                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                    {t('TotalAmount')}

                                                                                                </th>
                                                                                                {payrollDetails.dispute===2 &&
                                                                                                <th rowSpan={2}  className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                    {t('')}

                                                                                                </th>
                                                                                                }

                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                                <th className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">{t("Min")}</th>
                                                                                            </tr>
                                                                                            </thead>
                                                                                            <tbody className="bg-white">

                                                                                            {Object.values(payrollDetails).length > 0 &&
                                                                                            Object.values(payrollDetails.details).length > 0 &&
                                                                                            Object.keys(payrollDetails.details['payrollDetails']).map((checkInTime, indexP) => {

                                                                                                {var dateWiseCount = 0}
                                                                                                {var jobWiseTotalHours = 0}
                                                                                                {var jobWiseTotalAmount = 0}

                                                                                                return (
                                                                                                    <Fragment key={indexP}>
                                                                                                        {
                                                                                                            payrollDetails.details['payrollDetails'][checkInTime] && Object.values(payrollDetails.details['payrollDetails'][[checkInTime]]).map((element, index) => {
                                                                                                                { dateWiseCount = dateWiseCount+1}
                                                                                                                { jobWiseTotalHours = jobWiseTotalHours + Number(element.hour)}
                                                                                                                { jobWiseTotalAmount = jobWiseTotalAmount + Number(element.amount)}


                                                                                                                { var dateWiseDataLength = (payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && payrollDetails.details['payrollDetails'][checkInTime]) ? Object.values(payrollDetails.details['payrollDetails'][[checkInTime]]).length:0}

                                                                                                                return (
                                                                                                                    <PayrollDetailTr
                                                                                                                        key={index}
                                                                                                                        element={element}
                                                                                                                        checkInTime={checkInTime}
                                                                                                                        payrollDetails={payrollDetails}
                                                                                                                        dateWiseCount={dateWiseCount}
                                                                                                                        dateWiseDataLength={dateWiseDataLength}
                                                                                                                        setRecall={setRecall}
                                                                                                                    />
                                                                                                                )

                                                                                                            })
                                                                                                        }
                                                                                                        <tr>
                                                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right" colSpan={7}>{t("Hours")}</th>
                                                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-left">
                                                                                                                {Number(jobWiseTotalHours).toFixed(2)}
                                                                                                            </th>
                                                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right" colSpan={4}>
                                                                                                                {t('Amount')}
                                                                                                            </th>
                                                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right">
                                                                                                                {Number(jobWiseTotalAmount).toFixed(2)}
                                                                                                            </th>
                                                                                                        </tr>
                                                                                                    </Fragment>
                                                                                                )

                                                                                            })}


                                                                                            </tbody>
                                                                                            <tfoot>
                                                                                            <tr>
                                                                                                <th className="border border-slate-300 text-right px-4" colSpan={7}>{t('TotalHours')}</th>
                                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-left">{payrollDetails.totalHoursForDisplay}</th>
                                                                                                <th className="border border-slate-300 text-right px-4" colSpan={4}>
                                                                                                    {t('GrossIncome')}
                                                                                                </th>
                                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right">{lineTotalAmount}</th>
                                                                                                {payrollDetails.dispute===2 &&
                                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                    {t('')}

                                                                                                </th>
                                                                                                }
                                                                                            </tr>
                                                                                            </tfoot>
                                                                                        </table>
                                                                                    </div>
                                                                                </Grid.Col>
                                                                                <Grid.Col span={24}>
                                                                                    <div className="w-full">

                                                                                        <div
                                                                                            className="mb-10 w-full items-center">
                                                                                            <input
                                                                                                {...register("accessType")}
                                                                                                onChange={(event) => setAccessType(event.currentTarget.checked)}
                                                                                                id={payrollDetails.accessType}
                                                                                                type="checkbox"
                                                                                                value={payrollDetails.accessType}
                                                                                                checked={accessType}
                                                                                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                                                                                            <label
                                                                                                htmlFor={payrollDetails.accessType}
                                                                                                className="py-3 ml-2 w-full text-lg font-medium text-gray-900 dark:text-gray-300">{t('Is Public')}</label>
                                                                                        </div>

                                                                                        <div className="mb-3 w-full items-center">
                                                                                            <Textarea
                                                                                                {...register("remarks")}
                                                                                                placeholder={t("Comments")}
                                                                                                label={t("Comments")}
                                                                                                autosize
                                                                                                minRows={2}
                                                                                            />
                                                                                        </div>

                                                                                    </div>
                                                                                </Grid.Col>
                                                                            </Grid>


                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </ScrollArea>

                                                        <footer className="relative mt-1">
                                                            <div className="mr-3">
                                                                <div className="text-right pt-0.5 mb-0.5">
                                                                    <button type='reset'
                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                    ><FiX size={16} className={'mr-2'}/>
                                                                        <span>{t('Cancel')}</span>
                                                                    </button>
                                                                    <button type='submit'
                                                                            className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                        <span>{t('Save')}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </footer>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </main>
            {showModal ? <ViewPayroll setShowModal={setShowModal} payrollDetails={payrollDetails} /> : ''}
        </>
    )
}

export default PayrollUpdate