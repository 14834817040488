import React, {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {HiOutlineExclamation, HiOutlineOfficeBuilding, HiChevronLeft, HiArrowCircleRight} from "react-icons/hi";

import {FiSave, FiX} from "react-icons/fi";

import {AiOutlineLoading3Quarters, AiOutlineMinus} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import  {Toaster} from "react-hot-toast";
import { ScrollArea, Select, Stepper, Text} from "@mantine/core";
import {showNotification} from "@mantine/notifications";


function Register() {

    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [stateData, setStateData] = useState('');
    const [cityData, setCityData] = useState('');
    const [cities, setCities] = useState([]);
    const [cityDropdown, setCityDropdown] = useState([]);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setCountries(res.data.data);
            });
    }, []);

    let countryDropdown =
        countries.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })
    const [countryData, setCountryData] = useState('');

    const CountryDataHandel = (e) => {
        setCountryData(e);
        setStates([])
        setValue('state',null)

        setCityDropdown([])
        setValue('city',null)
        setCityData('')
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/state/dropdown?country_id=${e}`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    let stateDropdown =
                        res.data.data.map((type, index) => {
                            return ({
                                'label': type.name, 'value': type.id
                            })
                        })
                    setStates(stateDropdown)
                }
            });

        setValue('country',e)
        if (e == '' || e == null){
            setError("country", Yup.string().required(t("ChooseCountry")).nullable() );
        }else {
            setError("country", null);
        }
    }

    const StateDataHandel = (e) => {
        setStateData(e);
        setValue('state',e)

        setCityDropdown([])
        setValue('city',null)
        setCityData('')
    }

    const [citySearchValue, setCitySearchValue] = useState('');

    const CityDataHandelForSearch = (e) => {
        setCitySearchValue(e);
        if (citySearchValue.length >1){
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/city/dropdown-with-keyword?country_id=${countryData}&keyword=${citySearchValue}&state_id=${stateData}`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
            })
                .then(res => {
                    if (res.status === 200) {
                        let data =
                            res.data.data.map((head, index) => {
                                return ({
                                    'label': head.name, 'value': head.id
                                })
                            })
                        setCityDropdown(data);
                    }
                })
        }
    }

    const CityDataHandel = (e) => {
        setCityData(e);
        setValue('city',e)
        if (e == '' || e == null){
            setError("city", Yup.string().required(t("ChooseCity")).nullable() );
        }else {
            setError("city", null);
        }
    }


    const navigate = useNavigate();
    const {roles, token} = JSON.parse(localStorage.getItem('user'));
    const {t, i18n} = useTranslation();
    const fullFormHeight = localStorage.getItem('fullFormHeight');


    //Submit Spinner Init
    const [spinner, setSpinner] = useState(false);

    // start get company status dropdown
    const [companyStatusData, setCompanyStatusData] = useState('');
    const [companyStatus, setCompanyStatus] = useState([]);
    let companyStatusDropdown = [];
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=company-status&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setCompanyStatus(res.data.data);
                }

            });
    }, []);

    if (companyStatus) {
        companyStatusDropdown =
            companyStatus.map((type, index) => {
                return ({
                    'label': type.name === 'Client'?'Active':type.name, 'value': type.id
                })
            })
    }


    // on change company status data get
    const CompanyStatusDataHandel = (e) => {
        setCompanyStatusData(e);
        setValue('companyStatus',e)
        if (e == '' || e == null){
            setError("companyStatus", Yup.string().required(t("ChooseCompanyStatus")).nullable());
        }else {
            setError("companyStatus", null);
        }
    }
    // End get company status dropdown


    // start get position dropdown
    const [positionData, setPositionData] = useState(null);
    const [personInChargePositionData, setPersonInChargePositionData] = useState(null);
    const [personInChargeBillingPositionData, setPersonInChargeBillingPositionData] = useState(null);
    const [positions, setPositions] = useState([]);
    let positionDropdown = [];
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=position&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setPositions(res.data.data);
                }

            });
    }, []);

    if (positions) {
        positionDropdown =
            positions.map((type, index) => {
                return ({
                    'label': type.name, 'value': type.id
                })
            })
    }


    // on change position data get
    const PositionDataHandel = (e) => {
        setPositionData(e);
        setValue('position',e)
        if (e == '' || e == null){
            setError("position", Yup.string().required(t("ChoosePosition")).nullable());
        }else {
            setError("position", null);
        }
    }
    // End get position dropdown

    // on change person in charge position data get
    const PersonInChargePositionDataHandel = (e) => {
        setPersonInChargePositionData(e);
    }
    // End get person in charge position dropdown

    // on change person in charge billing position data get
    const PersonInChargeBillingPositionDataHandel = (e) => {
        setPersonInChargeBillingPositionData(e);
    }
    // End get person in charge billing position dropdown


    // start get Currency dropdown
    const [currencyData, setCurrencyData] = useState('');
    const [currencies, setCurrency] = useState([]);
    let currencyDropdown = [];
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=currency&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setCurrency(res.data.data);
                }

            });
    }, []);

    if (currencies) {
        currencyDropdown =
            currencies.map((type, index) => {
                return ({
                    'label': type.name, 'value': type.id
                })
            })
    }


    // on change department data get
    const CurrencyDataHandel = (e) => {
        setCurrencyData(e);
    }
    // End get department dropdown

    //Form validation
    const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
    const onlyNumber = /^[0-9]+$/


    const validationSchemaFirstStep = Yup.object().shape({
        companyName: Yup.string().required(t("EnterCompanyName")),
        email: Yup.string().email(t('EnterValidEmail')).required(t("EnterEmail")),
        secondary_email: Yup.string().email(t('EnterValidEmail')).required(t("EnterEmail")),
        phone: Yup.string().required(t("EnterMobileNumber")).matches(phoneRegExp, t("PhoneNumberNotValid")),
        companyStatus: Yup.string().required(t("ChooseCompanyStatus")).nullable(),
        address: Yup.string().required(t("EnterAddress")),
        country: Yup.string().required(t("ChooseCountry")).nullable(),
        city: Yup.string().required(t("ChooseCity")).nullable(),
        zipCode: Yup.string().required(t("EnterZipCode")).matches(onlyNumber, t("InvalidZipCode")).nullable(),
    });

    const validationSchemaThirdStep = Yup.object().shape({
        contactPersonFirstName: Yup.string().required(t("EnterFirstName")).nullable(),
        contactPersonLastName: Yup.string().required(t("EnterLastName")).nullable(),
        contactPersonPhone: Yup.string().required(t("EnterPhone")).matches(phoneRegExp, t("PhoneNumberNotValid")).nullable(),
        contactPersonEmail: Yup.string().email(t('EnterValidEmail')).required(t("EnterEmail")),
        position: Yup.string().required(t("ChoosePosition")).nullable(),
        PersonInChargeEmail: Yup.string().email(t('HtmlValidEmail')),
    });

    const validationSchemaFourthStep = Yup.object().shape({
        PersonInChargeBillingEmail: Yup.string().email(t('HtmlValidEmail')),

        BankName: Yup.string().required(t("EnterBankName")).nullable(),
        BranchName: Yup.string().required(t("EnterBranchName")).nullable(),
        BillingPhone: Yup.string().required(t("EnterPhone")).matches(phoneRegExp, t("PhoneNumberNotValid")).nullable(),
        BillingEmail: Yup.string().email(t('EnterValidEmail')).required(t("EnterEmail")),
        BillingAddress: Yup.string().required(t("EnterAddress")).nullable(),
    });

    let formOptions = {resolver: yupResolver(validationSchemaFirstStep)};

    if (active === 2) {
        console.log(active)
        formOptions = {resolver: yupResolver(validationSchemaThirdStep)};
    }
    if (active===3){
        formOptions = {resolver: yupResolver(validationSchemaFourthStep)};
    }
    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState,setValue ,setError} = useForm(formOptions);
    const {errors} = formState;

    const [recall, setRecall] = useState(false);

    //Form Data Submit
    function formSubmit(data) {

        if (active === 0) {
            setSpinner(true);
            const formData = {
                companyName: data.companyName,
                email: data.email,
                secondary_email: data.secondary_email,
                phone: data.phone,
                website: data.website,
                companyStatus: companyStatusData,
                companyBillingAddress: data.CompanyBillingAddress?data.CompanyBillingAddress:null,
                remark: data.remark,

                address: data.address,
                streetAddress: data.streetAddress?data.streetAddress:'',
                zipCode: data.zipCode,
                country: countryData,
                state: stateData,
                city: cityData,

                clientInfo: [
                    {
                        first_name: data.contactPersonFirstName?data.contactPersonFirstName:null,
                        middle_name: data.contactPersonMiddleName?data.contactPersonMiddleName:null,
                        last_name: data.contactPersonLastName?data.contactPersonLastName:null,
                        phone: data.contactPersonPhone?data.contactPersonPhone:null,
                        email: data.contactPersonEmail?data.contactPersonEmail:null,
                        position: positionData,
                        flag: 'contact-person'
                    },
                    {
                        first_name: data.PersonInChargeFirstName?data.PersonInChargeFirstName:null,
                        middle_name: data.PersonInChargeMiddleName?data.PersonInChargeMiddleName:null,
                        last_name: data.PersonInChargeLastName?data.PersonInChargeLastName:null,
                        phone: data.PersonInChargePhone?data.PersonInChargePhone:null,
                        email: data.PersonInChargeEmail?data.PersonInChargeEmail:null,
                        position: personInChargePositionData?personInChargePositionData:null,
                        flag: 'person-in-charge'
                    },
                    {
                        first_name: data.PersonInChargeBillingFirstName?data.PersonInChargeBillingFirstName:null,
                        middle_name: data.PersonInChargeBillingMiddleName?data.PersonInChargeBillingMiddleName:null,
                        last_name: data.PersonInChargeBillingLastName?data.PersonInChargeBillingLastName:null,
                        phone: data.PersonInChargeBillingPhone?data.PersonInChargeBillingPhone:null,
                        email: data.PersonInChargeBillingEmail?data.PersonInChargeBillingEmail:null,
                        position: personInChargeBillingPositionData,
                        flag: 'person-in-charge-billing'
                    },
                    {
                        bank_name: data.BankName?data.BankName:null,
                        branch_name: data.BranchName?data.BranchName:null,
                        phone: data.BillingPhone?data.BillingPhone:null,
                        email: data.BillingEmail?data.BillingEmail:null,
                        address: data.BillingAddress?data.BillingAddress:null,
                        website: data.BillingWebsite?data.BillingWebsite:null,
                        currency: currencyData,
                        flag: 'billing'
                    }
                ]
            };

            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/register-client`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                data: formData
            })
                .then(res => {
                    console.log(res)
                    if (res.status === 201) {
                        console.log(res.data)
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('SuccessfullyDataAdded')),
                            message: (t('DataLoad')),
                            autoClose: 1000,
                            disallowClose: true,
                            color: 'green',
                        });
                        setSpinner(false);
                        axios({
                            method: 'post',
                            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/designation-wise-rate`,
                            headers: {
                                "Accept": `application/json`,
                                "Content-Type": `application/json`,
                                "Access-Control-Allow-Origin": '*',
                                "Authorization": `Bearer ${token}`
                            },
                            data: {
                                userId: res.data.userId
                            }
                        })
                            .then(response => {
                                console.log(response)
                                if (response.status === 200) {
                                    // console.log(res.data)
                                    setRecall(!recall)
                                }
                            })
                            .catch(function (error) {
                                console.log(error)
                            })
                        navigate('/client/'+res.data.userId+'/edit',{
                            state: {
                                type:"create"
                            }
                        })
                    } else {
                        console.log(res)
                    }
                })
                .catch(function (error) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('error')),
                        message: error.response.data.message,
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'red',
                    });
                    setSpinner(false)
                })
        }

    }

    return (
        <>
            <Toaster toastOptions={{className: 'w-1/2'}} containerStyle={{top: 63}}/>

            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Client')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>
                                    {t('ManageClientInformation')}
                                </sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <Link to='/client'
                                      className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={12} className={'mr-1'}/>{t('Back')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border">

                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form" autoComplete="off">
                                                        <ScrollArea style={{ height: fullFormHeight }} scrollbarSize={4}>
                                                        <div
                                                            className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full bg-gray-200">
                                                            <Stepper active={active}  breakpoint="sm" color="green" size="sm">
                                                                <Stepper.Step label= {t('CompanyInformation')} description={t('CompanyDetails')}>

                                                            {/*                COMPANY INFORMATION START                   */}
                                                            <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                                <div className="md:col-span-1">
                                                                    <div className="px-4 sm:px-0 ml-2">
                                                                        <h3 className="text-lg font-medium leading-6 text-gray-900">{t("CompanyInformation")}</h3>
                                                                        <p className="mt-1 text-sm text-gray-600">
                                                                            {t('CompanyDetails')}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-5 md:col-span-4 md:mt-0">
                                                                    <div
                                                                        className="shadow     sm:rounded-md">
                                                                        <div
                                                                            className="bg-gray-50 px-4 py-5 sm:p-6">
                                                                            <div className="mb-3 w-full items-center">
                                                                                <label htmlFor="CompanyName"
                                                                                       className="form-input-sm-label-required">{t('CompanyName')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    {errors.companyName?.type === 'required' &&
                                                                                    <div className="form-input-sm-error">
                                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.companyName?.message}
                                                                                    </div>}
                                                                                    <input
                                                                                        type="text"
                                                                                        {...register("companyName")}
                                                                                        className={`form-input-sm-control ${errors.companyName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                        name="companyName"
                                                                                        id="companyName"
                                                                                        placeholder={t("EnterCompanyName")}
                                                                                    />
                                                                                </div>
                                                                            </div>


                                                                            <div
                                                                                className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                <div
                                                                                    className="mb-3 w-1/2 items-center">

                                                                                    <label htmlFor="email"
                                                                                           className="form-input-sm-label-required">{t('Username')}</label>
                                                                                    <div
                                                                                        className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                        {errors.email?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}/> {errors.email?.message}
                                                                                            </div>}

                                                                                        {errors.email?.type === 'email' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}/> {errors.email?.message}
                                                                                            </div>}

                                                                                        {errors.email && errors.email.message != null &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}/> {errors.email?.message}
                                                                                            </div>}

                                                                                        <input
                                                                                            {...register("email")}
                                                                                            type="text"
                                                                                            className={`form-input-sm-control ${errors.email ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                            name="email"
                                                                                            onKeyUp={(e) => {
                                                                                                var emailCheck = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
                                                                                                if (emailCheck.test(e.target.value)) {
                                                                                                    setValue('email', e.target.value)
                                                                                                    setError('email', null)
                                                                                                } else {
                                                                                                    setError('email', {
                                                                                                        type: 'custom',
                                                                                                        message: t("EnterValidEmail")
                                                                                                    });
                                                                                                }
                                                                                            }}
                                                                                            id="email"
                                                                                            placeholder={t("EnterEmailAsUsername")}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="mb-3 w-1/2 items-center">

                                                                                    <label htmlFor="secondary_email"
                                                                                           className="form-input-sm-label-required">{t('SecondaryEmail')}</label>
                                                                                    <div
                                                                                        className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                        {errors.secondary_email?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}/> {errors.secondary_email?.message}
                                                                                            </div>}

                                                                                        {errors.secondary_email?.type === 'email' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}/> {errors.secondary_email?.message}
                                                                                            </div>}

                                                                                        {errors.secondary_email && errors.secondary_email.message != null &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}/> {errors.secondary_email?.message}
                                                                                            </div>}

                                                                                        <input
                                                                                            {...register("secondary_email")}
                                                                                            type="text"
                                                                                            className={`form-input-sm-control ${errors.secondary_email ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                            name="secondary_email"
                                                                                            onKeyUp={(e) => {
                                                                                                var emailCheck = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
                                                                                                if (emailCheck.test(e.target.value)) {
                                                                                                    setValue('secondary_email', e.target.value)
                                                                                                    setError('secondary_email', null)
                                                                                                } else {
                                                                                                    setError('secondary_email', {
                                                                                                        type: 'custom',
                                                                                                        message: t("EnterValidEmail")
                                                                                                    });
                                                                                                }
                                                                                            }}
                                                                                            id="email"
                                                                                            placeholder={t("EnterEmail")}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div
                                                                                    className="mb-3 w-1/3 items-center">
                                                                                    <label htmlFor="phone"
                                                                                           className="form-input-sm-label-required">{t('Phone')}</label>
                                                                                    <div
                                                                                        className="relative mt-1 mr-2 sm:col-span-2 sm:mt-0">
                                                                                        {errors.phone && errors.phone.message != null &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}/> {errors.phone?.message}
                                                                                            </div>}

                                                                                        <input
                                                                                            {...register("phone")}
                                                                                            type="text"
                                                                                            className={`form-input-sm-control ${errors.phone ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                            name="phone"
                                                                                            onKeyUp={(e) => {
                                                                                                if (e.target.value.match(phoneRegExp)) {
                                                                                                    setValue('phone', e.target.value)
                                                                                                    setError('phone', null)
                                                                                                } else {
                                                                                                    setError('phone', {
                                                                                                        type: 'custom',
                                                                                                        message: t("PhoneNumberNotValid")
                                                                                                    });
                                                                                                }
                                                                                            }}
                                                                                            id="phone"
                                                                                            placeholder={t("EnterPhoneNumber")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="mb-3 w-1/3 items-center">
                                                                                    <label htmlFor="Website"
                                                                                           className="form-input-sm-label">{t('Website')}</label>
                                                                                    <div
                                                                                        className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                        <input
                                                                                            {...register("website")}
                                                                                            type="text"
                                                                                            className={`form-input-sm-control border-gray-300 focus:border-blue-600 `}
                                                                                            name="website"
                                                                                            id="website"
                                                                                            placeholder={t("EnterWebsiteLink")}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div
                                                                                    className="mb-3 w-1/3 items-center">
                                                                                    <label htmlFor="CompanyStatus"
                                                                                           className="form-input-sm-label-required">{t('CompanyStatus')}</label>
                                                                                    <div
                                                                                        className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                        {errors.companyStatus?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}/> {errors.companyStatus?.message}
                                                                                            </div>}
                                                                                        <Select
                                                                                            {...register("companyStatus")}
                                                                                            placeholder={t('ChooseCompanyStatus')}
                                                                                            className={`${errors.companyStatus ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                            searchable clearable
                                                                                            allowDeselect
                                                                                            nothingFound="No options"
                                                                                            data={companyStatusDropdown}
                                                                                            transition="pop-top-left"
                                                                                            transitionDuration={80}
                                                                                            transitionTimingFunction="ease"
                                                                                            onChange={CompanyStatusDataHandel}
                                                                                            value={companyStatusData}
                                                                                        />
                                                                                    </div>
                                                                                </div>


                                                                            </div>


                                                                            <div className="mb-3 w-full items-center">
                                                                                <label htmlFor="Remark"
                                                                                       className="form-input-sm-label">{t('Remark')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                    <textarea
                                                                                        {...register("remark")}
                                                                                        className={`form-input-sm-control candidate-remark`}
                                                                                        type="textarea"
                                                                                        placeholder={t("EnterRemark")}
                                                                                        rows={5}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                                    {/*                COMPANY INFORMATION END                   */}


                                                                    {/*                COMPANY LOCATION INFORMATION START                   */}
                                                                    <div
                                                                        className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                                        <div className="md:col-span-1">
                                                                            <div className="px-4 sm:px-0 ml-2">
                                                                                <h3 className="text-lg font-medium leading-6 text-gray-900">{t("CompanyLocation")}</h3>
                                                                                <p className="mt-1 text-sm text-gray-600">
                                                                                    {t('CompanyLocationDetails')}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mt-5 md:col-span-4 md:mt-0">
                                                                            <div
                                                                                className="shadow     sm:rounded-md">
                                                                                <div
                                                                                    className="bg-gray-50 px-4 py-5 sm:p-6">
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="address"
                                                                                               className="form-input-sm-label-required">{t('CompanyAddress')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.address?.type === 'required' &&
                                                                                                <div
                                                                                                    className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation
                                                                                                        size={16}
                                                                                                        className={'mr-2'}/> {errors.address?.message}
                                                                                                </div>}
                                                                                            <textarea
                                                                                                {...register("address")}
                                                                                                className={`form-input-sm-control ${errors.address ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'}`}
                                                                                                name="address"
                                                                                                placeholder={t("EnterAddress")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    {/*<div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="CompanyBillingAddress"
                                                                                               className="form-input-sm-label">{t('BillingAddress')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            <input
                                                                                                {...register("CompanyBillingAddress")}
                                                                                                className={`form-input-sm-control`}
                                                                                                name="CompanyBillingAddress"
                                                                                                placeholder={t("EnterBillingAddress")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                                        <div
                                                                                            className="mb-3 w-full items-center">
                                                                                            <label htmlFor="streetAddress"
                                                                                                   className="form-input-sm-label">{t('StreetAddress')}</label>
                                                                                            <div
                                                                                                className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    {...register("streetAddress")}
                                                                                                    className={`form-input-sm-control`}
                                                                                                    name="streetAddress"
                                                                                                    id="streetAddress"
                                                                                                    placeholder={t("EnterStreetAddress")}
                                                                                                />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>*/}
                                                                                    <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                        <div
                                                                                            className="mb-3 w-1/3 items-center">
                                                                                            <label htmlFor="country"
                                                                                                   className="form-input-sm-label-required">{t('Country')}</label>
                                                                                            <div
                                                                                                className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                {errors.country?.type === 'required' &&
                                                                                                <div className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.country?.message}
                                                                                                </div>}

                                                                                                <Select
                                                                                                    {...register("country")}
                                                                                                    placeholder={t('ChooseCountry')}
                                                                                                    className={`${errors.country ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                    searchable clearable
                                                                                                    allowDeselect
                                                                                                    nothingFound="No options"
                                                                                                    data={countryDropdown}
                                                                                                    transition="pop-top-left"
                                                                                                    transitionDuration={80}
                                                                                                    transitionTimingFunction="ease"
                                                                                                    onChange={CountryDataHandel}
                                                                                                    value={countryData}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className="mb-3 w-1/3 items-center">
                                                                                            <label htmlFor="state"
                                                                                                   className="form-input-sm-label">{t('State')}</label>
                                                                                            <div
                                                                                                className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                                <Select
                                                                                                    placeholder={t('ChooseState')}
                                                                                                    searchable clearable
                                                                                                    allowDeselect
                                                                                                    nothingFound="No options"
                                                                                                    data={states}
                                                                                                    transition="pop-top-left"
                                                                                                    transitionDuration={80}
                                                                                                    transitionTimingFunction="ease"
                                                                                                    onChange={StateDataHandel}
                                                                                                    value={stateData}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className="mb-3 w-1/3 items-center">
                                                                                            <label htmlFor="city"
                                                                                                   className="form-input-sm-label-required">{t('City')}</label>
                                                                                            <div
                                                                                                className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                {errors.city?.type === 'required' &&
                                                                                                <div className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.city?.message}
                                                                                                </div>}

                                                                                                <Select
                                                                                                    {...register("city")}
                                                                                                    placeholder={t("ChooseCity")}
                                                                                                    className={`${errors.city ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                    searchable clearable
                                                                                                    allowDeselect
                                                                                                    nothingFound={t('PleaseTypeCityName')}
                                                                                                    withAsterisk
                                                                                                    data={cityDropdown}
                                                                                                    transition="pop-top-left"
                                                                                                    transitionDuration={80}
                                                                                                    transitionTimingFunction="ease"
                                                                                                    onSearchChange={CityDataHandelForSearch}
                                                                                                    onChange={e => {
                                                                                                        CityDataHandel(e)
                                                                                                    }}
                                                                                                    value={cityData}
                                                                                                    searchValue={citySearchValue}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                        <div className="mb-3 w-1/3 items-center">
                                                                                            <label htmlFor="zipCode" className="form-input-sm-label-required">{t('ZipCode')}</label>
                                                                                            <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                {/*{errors.zipCode?.type === 'required' &&*/}
                                                                                                {errors.zipCode &&
                                                                                                <div className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.zipCode?.message}
                                                                                                </div>}
                                                                                                <input
                                                                                                    {...register("zipCode")}
                                                                                                    type="text"
                                                                                                    className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                                                                    name="zipCode"
                                                                                                    id="zipCode"
                                                                                                    placeholder={t("EnterZipCode")}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/*                COMPANY LOCATION INFORMATION END                   */}


                                                                </Stepper.Step>
                                                                <Stepper.Step label={t('DesignationInformation')} description={t('ClientDesignationDetails')}>

                                                                </Stepper.Step>
                                                                <Stepper.Step label={t('OfficeContact')} description={t('OfficeContactDetails')}>

                                                                </Stepper.Step>

                                                                <Stepper.Step label={t('OfficeBillingInfo')} description={t('OfficeBillingDetails')}>

                                                                </Stepper.Step>

                                                                <Stepper.Step label={t("ImageAndAttachmentInformation")} description={t('ImageAndAttachmentDetails')}>
                                                                </Stepper.Step>
                                                            </Stepper>

                                                        </div>
                                                        </ScrollArea>

                                                        <footer className="relative mt-1">
                                                            <div className="mr-3">
                                                                <div
                                                                    className="text-right pt-0.5 mb-0.5">

                                                                    <button type='submit'
                                                                            className="inline-flex items-center rounded bg-green-700 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-0">
                                                                        {spinner ? (
                                                                            <AiOutlineLoading3Quarters
                                                                                className={'mr-2 animate-spin'}
                                                                                size={16}/>
                                                                        ) : (<HiArrowCircleRight size={16} className={'mr-2'}/>)}
                                                                        <span>{t('Next')}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </footer>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default Register