import React, {useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiEye, HiOutlineExclamation,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiPencil, HiTrash
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Group, Text, Box} from "@mantine/core";
import {FiAirplay, FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
// import View from "./UserPermissionView";

import {openConfirmModal} from '@mantine/modals';
import {showNotification} from '@mantine/notifications';


function UserPermission() {

    const {t, i18n} = useTranslation();
    const token = localStorage.getItem('user_token');
    const [isAddForm, setAddForm] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const [listRefresh, setListRefresh] = useState(false);

    const addFormControl = (e) => {
        setEditForm(false);
        setAddForm(true);
    }


// FOR DATA TABLE DATA SHOW START
    const PAGE_SIZE = 50;
    const [page, setPage] = useState(localStorage.getItem('cost_header_page') ? localStorage.getItem('cost_header_page') : 1);
    const innerContainer = useRef(100);
    const height = innerContainer.current.clientHeight;
    const tableHeight = (height - 63);
    const fromHeight = height - 153 + "px";

    const [userPermission, setUserPermission] = useState([]);
    const [totalItem, setTotalItem] = useState(0);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/users/permission?offset=${PAGE_SIZE}&page=${page}`, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setUserPermission(res.data.data);
                setTotalItem(res.data.total);
                localStorage.setItem('cost_header_page', page);
            });
    }, [spinner, page, listRefresh]);
// FOR DATA TABLE DATA SHOW END


//Form validation
    const validationSchema = Yup.object().shape({
        label: Yup.string().required(t("LabelIsRequired")),
        group: Yup.string().required(t("GroupIsRequired")),
        value: Yup.string().required(t("ValueIsRequired")),
    });

    const formOptions = {resolver: yupResolver(validationSchema)};

// get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState} = useForm(formOptions);
    const {errors} = formState;


//Form Data Submit
    function formSubmit(data) {
        console.log(data)
        setSpinner(true);
        const formData = {
            permission_label: data.label,
            permission_value: data.value,
            permission_group: data.group,
            status: true
        };

        if (formData) {
            axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/users/permission/`, formData, {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                    if (res.data.status === 201) {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('UserPermissionAddedSuccessfully')),
                            message: (t('DataLoad')),
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });
                        setSpinner(false);
                        reset();
                    } else if (res.data.status === 200) {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('ThisPermissionAlreadyEXists')),
                            message: (t('DataLoad')),
                            autoClose: 500,
                            disallowClose: true,
                            color: 'red',
                        });
                    } else {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('Error')),
                            message: res.data.message,
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });
                    }
                });
        }
    }

    const [isEditForm, setEditForm] = useState(false);
    const [editInfo, setEditInfo] = useState({
        permission_label: '',
        permission_value: '',
        permission_group: '',
    });
    const UserPermissionEdit = (e, id) => {
        e.preventDefault();
        setAddForm(false);
        setEditForm(true);
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/users/permission/${id}`, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setEditInfo(res.data.data[0]);
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: res.data.message,
                        autoClose: 3000,
                        disallowClose: true,
                        color: 'green',
                    });
                }
            });
    }

    const handelInputUpdate = (e) => {
        e.persist();
        setEditInfo({...editInfo, [e.target.name]: e.target.value})
    }

    function formSubmitUpdate(data) {
        setSpinner(true);
        const updateFormData = {
            permission_label: editInfo.label ? editInfo.label : editInfo.permission_label,
            permission_value: editInfo.value ? editInfo.value : editInfo.permission_value,
            permission_group: editInfo.group ? editInfo.group : editInfo.permission_group,
            status: true
        };

        if (updateFormData) {
            axios.put(`${process.env.REACT_APP_API_GATEWAY_URL}/users/permission/${editInfo.id}`,
                updateFormData,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                    if (res.data.status === 202) {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('UserPermissionUpdatedSuccessfully')),
                            message: (t('DataLoad')),
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });
                        setSpinner(false);
                        reset();
                    } else if (res.data.status === 200) {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('ThisPermissionAlreadyEXists')),
                            message: (t('DataLoad')),
                            autoClose: 500,
                            disallowClose: true,
                            color: 'red',
                        });
                    } else {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('Error')),
                            message: res.data.message,
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });
                    }
                });
        }
    }


    const UserPermissionDelete = (e, id) => {
        e.preventDefault();
        openConfirmModal({
            title: (t('DeleteThisData')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureYouWantToDelete')}
                </Text>
            ),
            labels: {confirm: (t('DeleteUserPermission')), cancel: (t('NoDontDeleteIt'))},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () =>
                (
                    axios.delete(`${process.env.REACT_APP_API_GATEWAY_URL}/users/permission/${id}`, {headers: {"Authorization": `Bearer ${token}`}})
                        .then(res => {
                            if (res.status === 200) {
                                showNotification({
                                    id: 'load-data',
                                    loading: true,
                                    title: (t('DataIsDeleting')),
                                    message: (t('DataLoad')),
                                    autoClose: 3000,
                                    disallowClose: true,
                                    color: 'red',
                                });
                                setListRefresh(true);
                            }
                        })
                )
            ,
        });
        setListRefresh(false);
    }

    const [viewModel, setViewModel] = useState(false);
    const [viewData, setViewData] = useState(null);
    const UserPermissionView = (e, id) => {
        e.preventDefault();
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/users/permission/${id}`, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setViewData(res.data.data[0]);
                    setViewModel(true);
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: res.data.message,
                        autoClose: 3000,
                        disallowClose: true,
                        color: 'green',
                    });
                }
            });
    }

    return (
        <>
            {viewModel ? <View data={viewData} setViewModel={setViewModel}/> : ''}

            <main className="flex flex-1 " ref={innerContainer}>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div
                                                className="w-full flex -ml-4 pl-4 h-12 bg-indigo-50 justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <div
                                                        className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24}
                                                                                 className={'mt-3 mr-2 text-indigo-300'}/>
                                                        <span
                                                            className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
{t('UserPermission')}
                                                            <sub
                                                                className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('UserPermissionInformation')}</sub></span>
                                                    </div>
                                                </div>
                                                <div className={"right flex mr-8"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300"
                                                             role="group">

                                                            <button
                                                                onClick={event => addFormControl(event)}

                                                                className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                <HiOutlinePlus size={12}
                                                                               className={'mr-1'}/>{t('AddUserPermission')}
                                                            </button>


                                                            <Link to='/master-data/cost-header'
                                                                  className="inline-flex justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-red-600 text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle size={16}/>
                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex flex-1 overflow-hidden '}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex">
                                                            <div className="w-full">
                                                                <Box sx={{height: tableHeight}}>
                                                                    <DataTable
                                                                        withBorder
                                                                        records={userPermission}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: '#',
                                                                                textAlignment: 'right',
                                                                                render: (item) => (userPermission.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                            },
                                                                            {
                                                                                accessor: 'permission_group',
                                                                                title: <Text
                                                                                    mr="xs">{t('PermissionGroup')}</Text>
                                                                            },
                                                                            {
                                                                                accessor: 'permission_label',
                                                                                title: <Text
                                                                                    mr="xs">{t('PermissionLabel')}</Text>
                                                                            }, {
                                                                                accessor: 'permission_value',
                                                                                title: <Text
                                                                                    mr="xs">{t('PermissionValue')}</Text>
                                                                            },
                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text
                                                                                    mr="xs">{t('Action')}</Text>,
                                                                                textAlignment: 'right',
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="right"
                                                                                           noWrap>
                                                                                        <ActionIcon color="green"
                                                                                                    onClick={(e) => UserPermissionEdit(e, item.id)}>
                                                                                            <HiPencil size={16}/>
                                                                                        </ActionIcon>
                                                                                        <ActionIcon color="blue">
                                                                                            <HiEye size={16}
                                                                                                   onClick={(e) => UserPermissionView(e, item.id)}/>
                                                                                        </ActionIcon>
                                                                                        <ActionIcon color="red">
                                                                                            <HiTrash size={16}
                                                                                                     onClick={(e) => UserPermissionDelete(e, item.id)}/>
                                                                                        </ActionIcon>
                                                                                    </Group>
                                                                                ),
                                                                            },

                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => setPage(p)}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                {isAddForm ?
                                                    <aside
                                                        className="sm:block  h-full  transition-all">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">
                                                                        <header className="relative mb-1 bg-indigo-600">
                                                                            <div
                                                                                className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-center flex justify-between">
                                                                                    <div
                                                                                        className="text-white   text-sm font-bold items-center leading-7">  {t('UserPermission')}</div>
                                                                                    <button
                                                                                        onClick={() => setAddForm(false)}
                                                                                        className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                        type="button">
                                                                                        <FiX/>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>
                                                                        <form onSubmit={handleSubmit(formSubmit)}
                                                                              id="horizontal-form">
                                                                            <div
                                                                                className="relative flex flex-col min-w-0 break-words inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
                                                                                style={{height: fromHeight}}>
                                                                                <div
                                                                                    className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>

                                                                                    {/* Title Section*/}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="label"
                                                                                               className="form-input-sm-label-required">{t('Group')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.group?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}/> {errors.group?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay size={16}/>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("group")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.group ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="group"
                                                                                                id="group"
                                                                                                placeholder={t("EnterGroup")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    {/* Title Section*/}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="label"
                                                                                               className="form-input-sm-label-required">{t('Label')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.label?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}/> {errors.label?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay size={16}/>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("label")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.label ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="label"
                                                                                                id="label"
                                                                                                placeholder={t("EnterTitle")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    {/* Title Section*/}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="label"
                                                                                               className="form-input-sm-label-required">{t('Value')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.value?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}/> {errors.value?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay size={16}/>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("value")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.value ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="value"
                                                                                                id="value"
                                                                                                placeholder={t("EnterValue")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div
                                                                                        className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button
                                                                                            onClick={() => setAddForm(false)}
                                                                                            type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX size={16}
                                                                                              className={'mr-2'}/>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}/>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}/>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </footer>

                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}


                                                {isEditForm ?
                                                    <aside
                                                        className="sm:block  h-full  transition-all">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">
                                                                        <header className="relative mb-1 bg-indigo-600">
                                                                            <div
                                                                                className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-center flex justify-between">
                                                                                    <div
                                                                                        className="text-white   text-sm font-bold items-center leading-7">  {t('UpdateUserPermission')}</div>
                                                                                    <button
                                                                                        onClick={() => setEditForm(false)}
                                                                                        className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                        type="button">
                                                                                        <FiX/>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>
                                                                        <form onSubmit={handleSubmit(formSubmitUpdate)}
                                                                              id="horizontal-form">
                                                                            <div
                                                                                className="relative flex flex-col min-w-0 break-words inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
                                                                                style={{height: fromHeight}}>
                                                                                <div
                                                                                    className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>

                                                                                    {/* Title Section*/}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="label"
                                                                                               className="form-input-sm-label-required">{t('Group')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.group?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}/> {errors.group?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay size={16}/>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("group")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.group ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="group"
                                                                                                id="group"
                                                                                                value={editInfo.group ? editInfo.group : editInfo.permission_group}
                                                                                                onChange={handelInputUpdate}
                                                                                                placeholder={t("EnterGroup")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Title Section*/}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="label"
                                                                                               className="form-input-sm-label-required">{t('Label')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.label?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}/> {errors.label?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay size={16}/>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("label")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.label ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="label"
                                                                                                id="label"
                                                                                                value={editInfo.label ? editInfo.label : editInfo.permission_label}
                                                                                                onChange={handelInputUpdate}
                                                                                                placeholder={t("EnterTitle")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    {/* Title Section*/}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="label"
                                                                                               className="form-input-sm-label-required">{t('Value')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.value?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}/> {errors.value?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay size={16}/>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("value")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.value ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="value"
                                                                                                id="value"
                                                                                                value={editInfo.value ? editInfo.value : editInfo.permission_value}
                                                                                                onChange={handelInputUpdate}
                                                                                                placeholder={t("EnterValue")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div
                                                                                        className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button
                                                                                            onClick={() => setEditForm(false)}
                                                                                            type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX size={16}
                                                                                              className={'mr-2'}/>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}/>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}/>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </footer>
                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default UserPermission