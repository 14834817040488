import {Document, Page, PDFViewer, Text, View, Image} from "@react-pdf/renderer";
import styles from "../../../pdf-styles";
import {Grid, ScrollArea, Select, TextInput, Textarea, Box, Group, ActionIcon} from "@mantine/core";
import React, {Fragment, useEffect, useState} from "react";
import {
    HiChevronLeft,
    HiEye,
    HiViewList,
    HiOutlineExclamation,
    HiOutlineOfficeBuilding,
    HiOutlinePlus,
    HiPencil,
    HiOutlineCurrencyDollar, HiDocumentDownload
} from "react-icons/hi";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import axios from "axios";
import {FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {useForm} from "react-hook-form";
import {showNotification} from "@mantine/notifications";
import {DatePicker} from "@mantine/dates";
import dayjs from "dayjs";
import {useNavigate} from "react-router";
import {DataTable} from "mantine-datatable";
import {onHidden} from "web-vitals/dist/modules/lib/onHidden";


function InvoiceSummery() {

    const {domainId, loggedUserId ,roles, token} = JSON.parse(localStorage.getItem('user'));
    const hasRoleModerator = roles.find(role => {
        return role === "ROLE_MODERATOR";
    })
    const {invoiceId} = useParams();
    const {t, i18n} = useTranslation();
    const [invoice, setInvoice] = useState({});
    const [invoiceDetail, setInvoiceDetail] = useState({});
    const fullFormHeight = localStorage.getItem('fullFormHeight');
    const navigate = useNavigate();
    const [taxData, setTaxData] = useState(0.00);
    const [viewMode, setViewMode] = useState('decimal');

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/invoice/${invoiceId}/summery`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                if (res.status === 200){
                    setInvoice(res.data.data.invoiceInfo)
                    setInvoiceDetail(res.data.data)
                }
            });
    }, [])

    const convertSecondsToHourMin = (seconds) => {

        const totalMinutes = Math.floor(seconds / 60);

        const hours = Math.floor(totalMinutes / 60);
        const hourLength = hours.toString().length;

        const minutes = totalMinutes % 60;
        const minLength = minutes.toString().length;

        const hour = hourLength<2?'0'+hours:hours;
        const minute = minLength<2?'0'+minutes:minutes;

        return `${hour}:${minute}`
    }
    const convertSecondsToDecimal = (seconds) => {

        const totalMinutes = seconds / 60;

        const hours = totalMinutes/60;


        return hours.toFixed(2)
    }

    var lineTotalAmount = invoice.subTotalAmount?invoice.subTotalAmount:0;
    var lineTotalAmountDecimalHour = invoice.subTotalDecimalHourAmount && invoice.subTotalDecimalHourAmount>0?invoice.subTotalDecimalHourAmount:0;

    var taxRate = invoice.taxRate?invoice.taxRate:0;

    var taxAmount = ((lineTotalAmount*taxRate)/100).toFixed(2);
    var taxAmountDecimalHour = ((lineTotalAmountDecimalHour*taxRate)/100).toFixed(2);

    var grandTotalSecond = 0;

    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div
                            className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Invoice')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('InvoiceInformation')}</sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <Link to={`/invoice/`+invoice.id+`/summery/pdf`}
                                      state = { viewMode==='hour' ? {viewMode: 'hour'} : {viewMode: 'decimal'}}
                                      className="inline-flex items-center text-right font-normal text-red-600 ">
                                    <HiDocumentDownload className="h-5 w-5" aria-hidden="true"/> Generate PDF
                                </Link>
                                {hasRoleModerator && invoice.paymentStatus && invoice.paymentStatus!=="PAID" &&
                                <Link to={'/invoice/'+invoice.id+'/payment/create'}>
                                    <button
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    >
                                        <HiOutlineCurrencyDollar size={16} className={'mr-1'}/>{t('RecordPayment')}
                                    </button>
                                </Link>

                                }
                                {hasRoleModerator &&
                                <Link to={'/invoice/'+invoice.id+'/view'}>
                                    <button
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    >
                                        <HiEye size={16} className={'mr-1'}/>{t('DetailInvoice')}
                                    </button>
                                </Link>
                                }
                                {hasRoleModerator && invoice.paymentStatus && (invoice.paymentStatus==="DISPUTED" || invoice.paymentStatus==="CREATED") &&
                                <Link to={'/invoice/'+invoice.id+'/edit'}>
                                    <button
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    >
                                        <HiPencil size={16} className={'mr-1'}/>{t('EditInvoice')}
                                    </button>
                                </Link>
                                }
                                { hasRoleModerator &&
                                <Link to={'/invoice/create'}>
                                    <button
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    >
                                        <HiOutlinePlus size={16} className={'mr-1'}/>{t('NewInvoice')}
                                    </button>
                                </Link>
                                }


                                <Link to={'/invoice'}>
                                    <button
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    >
                                        <HiChevronLeft size={16} className={'mr-1'}/>{t('Back')}
                                    </button>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-full w-full">
                    <div className="bg-white px-2 lg:px-4 lg:py-2">
                        <button onClick={()=>setViewMode('decimal')}
                                className={`inline-flex items-center ml-6 rounded border-none py-2 px-4 text-sm font-normal shadow-sm bg-indigo-100 ${viewMode==='decimal' && "bg-indigo-600 text-white"}`}
                        >
                            {t('Decimal')}
                        </button>
                        { hasRoleModerator &&
                            <button onClick={()=>setViewMode('hour')}
                                    className={`inline-flex items-center ml-6 rounded border-none py-2 px-4 text-sm font-normal shadow-sm bg-indigo-100 ${viewMode==='hour' && "bg-indigo-600 text-white"}`}
                            >
                                {t('Hour')}
                            </button>
                        }
                    </div>
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 sm:px-6 lg:px-4">
                                        <div className="">

                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border">
                                                    <ScrollArea style={{ height: fullFormHeight }} scrollbarSize={4}>
                                                        { viewMode==='decimal' &&
                                                            <div className="bg-white px-2 py-2 lg:p-4">
                                                                <Grid columns={24}>
                                                                    <Grid.Col span={14}>
                                                                        <div className="w-full">
                                                                            <img src={"/assets/images/simple-staffing.png"} alt="Simple Staffing" />
                                                                            <h2 className="text-lg">SIMPLE STAFFING USA LLC</h2>
                                                                            <p className="text-sm">
                                                                                3235 Satellite Blvd. Building 400
                                                                            </p>
                                                                            <p className="text-sm">
                                                                                Suite 300 Duluth, GA
                                                                            </p>
                                                                            <p className="text-sm">
                                                                                {t('Phone')}: 1.888.902.7759
                                                                            </p>
                                                                            <p className="text-sm">
                                                                                {t('Email')}: billing@simplestaffingllc.com
                                                                            </p>
                                                                        </div>
                                                                        <div className="w-full mt-10">
                                                                            <h2 className="text-lg"><strong>BILL TO:</strong></h2>
                                                                            <h2 className="text-lg">{invoice.clientName}</h2>
                                                                            <p className="text-sm">{invoice.clientAddress}</p>
                                                                            <p className="text-sm">Phone: {invoice.clientPhone}</p>
                                                                            <p className="text-sm">Email: {invoice.clientEmail}</p>
                                                                        </div>
                                                                    </Grid.Col>
                                                                    <Grid.Col span={10}>
                                                                        <div className="text-center">
                                                                            <h1>Invoice</h1>
                                                                            <table>
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td className="text-right">INVOICE NO : </td>
                                                                                    <td className="text-left">{invoice.invoiceId}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="text-right">{t('Date')} : </td>
                                                                                    <td className="text-left">
                                                                                        {invoice.invoiceDate ? format(new Date(invoice.invoiceDate.date), 'MMMM dd, Y') : ''}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="text-right">{t('InvoicePeriod')} : </td>
                                                                                    <td className="text-left">{invoice.startTime ? format(new Date(invoice.startTime.date), 'MMM dd, Y') : ''} To  {invoice.endTime ? format(new Date(invoice.endTime.date), 'MMM dd, Y') : ''}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="text-right">{t('InvoiceDueDate')} : </td>
                                                                                    <td className="text-left">
                                                                                        {invoice.dueDate ? format(new Date(invoice.dueDate.date), 'MMMM dd, Y') : ''}
                                                                                    </td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </Grid.Col>
                                                                </Grid>
                                                                <div className="w-full mt-2" style={{overflow:scroll}}>
                                                                    <table className="border-collapse border border-slate-400 min-w-full" >
                                                                        <thead className="bg-white">
                                                                        <tr>
                                                                            <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('Date')}
                                                                            </th>
                                                                            <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('JobDescription')}
                                                                            </th>
                                                                            <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('NumberOfJob')}
                                                                            </th>

                                                                            <th colSpan={1} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('RegularRate')}
                                                                            </th>
                                                                            <th colSpan={1} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('OverTimeRate')}
                                                                            </th>

                                                                            <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('TotalHours')}
                                                                            </th>
                                                                            <th colSpan={1} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('Regular')}
                                                                            </th>
                                                                            <th colSpan={1} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('OverTime')}
                                                                            </th>
                                                                            <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('TotalAmount')}

                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                            {/*<th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>*/}

                                                                            <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                            {/*<th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>*/}

                                                                            <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                            {/*<th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>*/}

                                                                            <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                            {/*<th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>*/}
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody className="bg-white">


                                                                        {invoiceDetail && invoiceDetail.details && Object.keys(invoiceDetail.details).map((jobId) => {

                                                                            {var jobWiseTotalSeconds = 0}
                                                                            {var jobWiseTotalAmount = 0}

                                                                            return (
                                                                                <Fragment key={jobId}>
                                                                                    <tr className="border-t border-gray-200">
                                                                                        <th align="left" className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">{t('EventName')}</th>
                                                                                        <th align="left" colSpan={2} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">{invoiceDetail.jobDetails && invoiceDetail.jobDetails[jobId]? invoiceDetail.jobDetails[jobId].jobTitle:''}</th>
                                                                                        <th align="left" colSpan={1} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">{t('Location')}</th>
                                                                                        <th align="left" colSpan={1} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">{invoiceDetail.jobDetails && invoiceDetail.jobDetails[jobId]? invoiceDetail.jobDetails[jobId].jobLocation:''}</th>
                                                                                        <th align="left" colSpan={1} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">{t('Job')} #</th>
                                                                                        <td align="left" colSpan={3} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                            {/*{jobId}*/}
                                                                                        </td>

                                                                                    </tr>
                                                                                    {invoiceDetail.details[jobId] && Object.keys(invoiceDetail.details[jobId]).map((checkInTime, index)=> {
                                                                                        {var dateWiseCount = 0}
                                                                                        {var dateDataLength = invoiceDetail && invoiceDetail.details && invoiceDetail.details[jobId] && Object.values(invoiceDetail.details[jobId][checkInTime]) ? Object.values(invoiceDetail.details[jobId][checkInTime]).length:1}

                                                                                        return (
                                                                                            <Fragment key={index}>

                                                                                                {invoiceDetail.details[jobId][checkInTime] && Object.values(invoiceDetail.details[jobId][checkInTime]).map((element, designationId)=>{

                                                                                                    { dateWiseCount = dateWiseCount+1}

                                                                                                    { jobWiseTotalSeconds = jobWiseTotalSeconds + parseFloat(element.totalSecond)}
                                                                                                    { jobWiseTotalAmount = jobWiseTotalAmount + parseFloat(element.totalAmountDecimalHour)}
                                                                                                    { grandTotalSecond = grandTotalSecond + parseFloat(element.totalSecond)}
                                                                                                    return(
                                                                                                        <Fragment key={designationId}>
                                                                                                            <tr key={designationId} className="border-t border-gray-200">
                                                                                                                {dateWiseCount===1 &&
                                                                                                                    <td rowSpan={dateDataLength} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {checkInTime}
                                                                                                                    </td>
                                                                                                                }
                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.designationName}
                                                                                                                </td>
                                                                                                                <td align="center" className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.noOfItems}
                                                                                                                </td>
                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.regularHourRate}
                                                                                                                </td>
                                                                                                                {/*<td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.regularMinRate}
                                                                                                                </td>*/}

                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.overtimeHourRate}
                                                                                                                </td>


                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.minuteFormatToHourFormat}
                                                                                                                </td>

                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.regularHourMinToDecimal}
                                                                                                                </td>
                                                                                                                {/*<td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.totalRegularMin}
                                                                                                                </td>*/}

                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.overtimeHourMinToDecimal}
                                                                                                                </td>
                                                                                                                {/*<td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.totalOvertimeMin}
                                                                                                                </td>*/}

                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900 text-right">
                                                                                                                    {element.totalAmountDecimalHour?parseFloat(element.totalAmountDecimalHour).toFixed(2):'0.00'}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </Fragment>
                                                                                                    )
                                                                                                })}
                                                                                            </Fragment>
                                                                                        )

                                                                                    })}

                                                                                    <tr className="border-t border-gray-200">
                                                                                        <th align="right" colSpan={5} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900">{t('Hours')}</th>
                                                                                        <th colSpan={1} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-left">{convertSecondsToDecimal(jobWiseTotalSeconds)}</th>
                                                                                        <th align="right" colSpan={2} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900">{t('Amount')}</th>
                                                                                        <th align="right" colSpan={1} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right">{jobWiseTotalAmount.toFixed(2)}</th>

                                                                                    </tr>
                                                                                </Fragment>
                                                                            )

                                                                        })}

                                                                        </tbody>
                                                                        <tfoot>
                                                                        <tr>
                                                                            <th className="border border-slate-300 text-right px-4" colSpan={5}>{t('TotalInvoiceHours')}</th>
                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-left">{convertSecondsToDecimal(grandTotalSecond)}</th>
                                                                            <th className="border border-slate-300 text-right px-4" colSpan={2}>{t('TotalInvoiceAmount')}</th>
                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right">{lineTotalAmountDecimalHour.toFixed(2)}</th>
                                                                        </tr>
                                                                        <tr>

                                                                            <th colSpan={8} className="border border-slate-300 text-right px-4">{t('Tax')} ({invoice.taxRate?invoice.taxRate:0}%)</th>
                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6">
                                                                                {/*$ {(invoice.taxAmount?invoice.taxAmount:0).toFixed(2)}*/}
                                                                                $ {taxAmountDecimalHour}
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th colSpan={8} className="border border-slate-300 text-right px-4">{t('Total')}</th>
                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6">
                                                                                $ {(lineTotalAmountDecimalHour + Number(taxAmountDecimalHour)).toFixed(2)}
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th colSpan={8} className="border border-slate-300 text-right px-4">{t('PaymentMade')}</th>
                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6 text-red-700">
                                                                                (-) $ {invoice.totalPaymentAmount}
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th colSpan={8} className="border border-slate-300 text-right px-4">{t('BalanceDue')}</th>
                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6">
                                                                                $ {((lineTotalAmountDecimalHour+Number(taxAmountDecimalHour))-invoice.totalPaymentAmount).toFixed(2)}
                                                                            </th>
                                                                        </tr>
                                                                        </tfoot>
                                                                    </table>
                                                                </div>
                                                                <Grid columns={24}>
                                                                    <Grid.Col span={24}>
                                                                        <div className="w-full mt-10">
                                                                            <h3><strong>{t('AmountInWords')}:</strong> {invoice.amountInWordDecimalHour}</h3>
                                                                        </div>
                                                                        <div className="w-full mt-3">
                                                                            <p><strong>{t('TermsAndConditions')}:</strong> {invoice.termsAndConditions}</p>
                                                                        </div>
                                                                        {/*<div className="w-full mt-3">
                                                                            <p><strong>{t('Comments')}: </strong> {invoice.remarks}</p>
                                                                        </div>*/}
                                                                        <div className="w-full mt-10">
                                                                            <h3><strong>{t('NoticeOfAssignment')}:</strong></h3>
                                                                            <p className="text-sm">
                                                                                {invoice.remarks}
                                                                            </p>
                                                                        </div>
                                                                    </Grid.Col>
                                                                </Grid>
                                                            </div>
                                                        }
                                                        { hasRoleModerator && viewMode==='hour' &&
                                                            <div className="bg-white px-2 py-2 lg:p-4">
                                                                <Grid columns={24}>
                                                                    <Grid.Col span={14}>
                                                                        <div className="w-full">
                                                                            <img src={"/assets/images/simple-staffing.png"} alt="Simple Staffing" />
                                                                            <h2 className="text-lg">SIMPLE STAFFING USA LLC</h2>
                                                                            <p className="text-sm">
                                                                                3235 Satellite Blvd. Building 400
                                                                            </p>
                                                                            <p className="text-sm">
                                                                                Suite 300 Duluth, GA
                                                                            </p>
                                                                            <p className="text-sm">
                                                                                {t('Phone')}: 1.888.902.7759
                                                                            </p>
                                                                            <p className="text-sm">
                                                                                {t('Email')}: billing@simplestaffingllc.com
                                                                            </p>
                                                                        </div>
                                                                        <div className="w-full mt-10">
                                                                            <h2 className="text-lg"><strong>BILL TO:</strong></h2>
                                                                            <h2 className="text-lg">{invoice.clientName}</h2>
                                                                            <p className="text-sm">{invoice.clientAddress}</p>
                                                                            <p className="text-sm">Phone: {invoice.clientPhone}</p>
                                                                            <p className="text-sm">Email: {invoice.clientEmail}</p>
                                                                        </div>
                                                                    </Grid.Col>
                                                                    <Grid.Col span={10}>
                                                                        <div className="text-center">
                                                                            <h1>Invoice</h1>
                                                                            <table>
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td className="text-right">INVOICE NO : </td>
                                                                                    <td className="text-left">{invoice.invoiceId}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="text-right">{t('Date')} : </td>
                                                                                    <td className="text-left">
                                                                                        {invoice.invoiceDate ? format(new Date(invoice.invoiceDate.date), 'MMMM dd, Y') : ''}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="text-right">{t('InvoicePeriod')} : </td>
                                                                                    <td className="text-left">{invoice.startTime ? format(new Date(invoice.startTime.date), 'MMM dd, Y') : ''} To  {invoice.endTime ? format(new Date(invoice.endTime.date), 'MMM dd, Y') : ''}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="text-right">{t('InvoiceDueDate')} : </td>
                                                                                    <td className="text-left">
                                                                                        {invoice.dueDate ? format(new Date(invoice.dueDate.date), 'MMMM dd, Y') : ''}
                                                                                    </td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </Grid.Col>
                                                                </Grid>
                                                                <div className="w-full mt-2" style={{overflow:scroll}}>
                                                                    <table className="border-collapse border border-slate-400 min-w-full" >
                                                                        <thead className="bg-white">
                                                                        <tr>
                                                                            <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('Date')}
                                                                            </th>
                                                                            <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('JobDescription')}
                                                                            </th>
                                                                            <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('NumberOfJob')}
                                                                            </th>

                                                                            <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('RegularRate')}
                                                                            </th>
                                                                            <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('OverTimeRate')}
                                                                            </th>

                                                                            <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('TotalHours')}
                                                                            </th>
                                                                            <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('Regular')}
                                                                            </th>
                                                                            <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('OverTime')}
                                                                            </th>
                                                                            <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                {t('TotalAmount')}

                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                            <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                            <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                            <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                            <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                            <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                            <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                            <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody className="bg-white">


                                                                        {invoiceDetail && invoiceDetail.details && Object.keys(invoiceDetail.details).map((jobId) => {

                                                                            {var jobWiseTotalSeconds = 0}
                                                                            {var jobWiseTotalAmount = 0}

                                                                            return (
                                                                                <Fragment key={jobId}>
                                                                                    <tr className="border-t border-gray-200">
                                                                                        <th align="left" className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">{t('EventName')}</th>
                                                                                        <th align="left" colSpan={2} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">{invoiceDetail.jobDetails && invoiceDetail.jobDetails[jobId]? invoiceDetail.jobDetails[jobId].jobTitle:''}</th>
                                                                                        <th align="left" colSpan={2} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">{t('Location')}</th>
                                                                                        <th align="left" colSpan={4} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">{invoiceDetail.jobDetails && invoiceDetail.jobDetails[jobId]? invoiceDetail.jobDetails[jobId].jobLocation:''}</th>
                                                                                        <th align="left" colSpan={2} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">{t('Job')} #</th>
                                                                                        <td align="left" colSpan={4} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">

                                                                                        </td>

                                                                                    </tr>
                                                                                    {invoiceDetail.details[jobId] && Object.keys(invoiceDetail.details[jobId]).map((checkInTime, index)=> {
                                                                                        {var dateWiseCount = 0}
                                                                                        {var dateDataLength = invoiceDetail && invoiceDetail.details && invoiceDetail.details[jobId] && Object.values(invoiceDetail.details[jobId][checkInTime]) ? Object.values(invoiceDetail.details[jobId][checkInTime]).length:1}

                                                                                        return (
                                                                                            <Fragment key={index}>

                                                                                                {invoiceDetail.details[jobId][checkInTime] && Object.values(invoiceDetail.details[jobId][checkInTime]).map((element, designationId)=>{

                                                                                                    { dateWiseCount = dateWiseCount+1}

                                                                                                    { jobWiseTotalSeconds = jobWiseTotalSeconds + parseFloat(element.totalSecond)}
                                                                                                    { jobWiseTotalAmount = jobWiseTotalAmount + parseFloat(element.totalAmount)}
                                                                                                    { grandTotalSecond = grandTotalSecond + parseFloat(element.totalSecond)}
                                                                                                    return(
                                                                                                        <Fragment key={designationId}>
                                                                                                            <tr key={designationId} className="border-t border-gray-200">
                                                                                                                {dateWiseCount===1 &&
                                                                                                                    <td rowSpan={dateDataLength} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                        {checkInTime}
                                                                                                                    </td>
                                                                                                                }
                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.designationName}
                                                                                                                </td>
                                                                                                                <td align="center" className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.noOfItems}
                                                                                                                </td>
                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.regularHourRate}
                                                                                                                </td>
                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.regularMinRate}
                                                                                                                </td>

                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.overtimeHourRate}
                                                                                                                </td>
                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.overtimeMinRate}
                                                                                                                </td>

                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {/*{element.hourForDisplay}*/}
                                                                                                                    {convertSecondsToHourMin(parseFloat(element.totalSecond))}
                                                                                                                </td>

                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.totalRegularHour}
                                                                                                                </td>
                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.totalRegularMin}
                                                                                                                </td>

                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.totalOvertimeHour}
                                                                                                                </td>
                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                    {element.totalOvertimeMin}
                                                                                                                </td>

                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900 text-right">
                                                                                                                    {element.totalAmount?parseFloat(element.totalAmount).toFixed(2):''}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </Fragment>
                                                                                                    )
                                                                                                })}
                                                                                            </Fragment>
                                                                                        )

                                                                                    })}

                                                                                    <tr className="border-t border-gray-200">
                                                                                        <th align="right" colSpan={7} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right">{t('Hours')}</th>
                                                                                        <th colSpan={1} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-left">{convertSecondsToHourMin(jobWiseTotalSeconds)}</th>
                                                                                        <th align="right" colSpan={4} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right">{t('Amount')}</th>
                                                                                        <th align="right" colSpan={1} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900">{jobWiseTotalAmount.toFixed(2)}</th>

                                                                                    </tr>
                                                                                </Fragment>
                                                                            )

                                                                        })}

                                                                        </tbody>
                                                                        <tfoot>
                                                                        <tr>
                                                                            <th className="border border-slate-300 text-right px-4" colSpan={7}>{t('TotalInvoiceHours')}</th>
                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-left">{convertSecondsToHourMin(grandTotalSecond)}</th>
                                                                            <th className="border border-slate-300 text-right px-4" colSpan={4}>{t('TotalInvoiceAmount')}</th>
                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right">{lineTotalAmount.toFixed(2)}</th>
                                                                        </tr>
                                                                        <tr>

                                                                            <th colSpan={12} className="border border-slate-300 text-right px-4">{t('Tax')} ({invoice.taxRate?invoice.taxRate:0}%)</th>
                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6">
                                                                                {/*$ {(invoice.taxAmount?invoice.taxAmount:0).toFixed(2)}*/}
                                                                                $ {taxAmount}
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th colSpan={12} className="border border-slate-300 text-right px-4">{t('Total')}</th>
                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6">
                                                                                $ {(lineTotalAmount+Number(taxAmount)).toFixed(2)}
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th colSpan={12} className="border border-slate-300 text-right px-4">{t('PaymentMade')}</th>
                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6 text-red-700">
                                                                                (-) $ {invoice.totalPaymentAmount}
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th colSpan={12} className="border border-slate-300 text-right px-4">{t('BalanceDue')}</th>
                                                                            <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6">
                                                                                $ {((lineTotalAmount+Number(taxAmount))-invoice.totalPaymentAmount).toFixed(2)}
                                                                            </th>
                                                                        </tr>
                                                                        </tfoot>
                                                                    </table>
                                                                </div>
                                                                <Grid columns={24}>
                                                                    <Grid.Col span={24}>
                                                                        <div className="w-full mt-10">
                                                                            <h3><strong>{t('AmountInWords')}:</strong> {invoice.amountInWord}</h3>
                                                                        </div>
                                                                        <div className="w-full mt-3">
                                                                            <p><strong>{t('TermsAndConditions')}:</strong> {invoice.termsAndConditions}</p>
                                                                        </div>
                                                                        {/*<div className="w-full mt-3">
                                                                            <p><strong>{t('Comments')}: </strong> {invoice.remarks}</p>
                                                                        </div>*/}
                                                                        <div className="w-full mt-10">
                                                                            <h3><strong>{t('NoticeOfAssignment')}:</strong></h3>
                                                                            <p className="text-sm">
                                                                                {invoice.remarks}
                                                                            </p>
                                                                        </div>
                                                                    </Grid.Col>
                                                                </Grid>
                                                            </div>
                                                        }

                                                    </ScrollArea>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default InvoiceSummery