import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    HiOutlineUserCircle,
    HiOutlineCheck,
    HiOutlineClock,
    HiCheckCircle
} from "react-icons/hi";
import {format} from 'date-fns';
import { LoadingOverlay, Text } from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import {openConfirmModal} from '@mantine/modals';
import jwtDecode from "jwt-decode";

export default function DesignationList({designation, setRecall, jobView, jobDetails, setDeclineInfo, setShowDeclineModal}){
    const { t, i18n } = useTranslation();
    const {token, loggedUserId, roles} = JSON.parse(localStorage.getItem('user'));

    const loggedUser = jwtDecode(token);

    const [showLoader, setShowLoader] = useState(false);
    const [timeMatrix, setTimeMatrix] = useState({});


    const jobApply = (data) => {
        openConfirmModal({
            title: (t('')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSure')}?
                </Text>
            ),
            labels: {confirm: (t('Yes')), cancel: (t('No'))},
            confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
            // confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                setShowLoader(true);

                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-apply`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    data: data
                })
                    .then(res => {
                        if (res.data.status === 200){
                            setRecall(prevState => !prevState)
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Success') + ' !'),
                                message: (t(res.data.message)),
                                autoClose: 2000,
                                disallowClose: true,
                                color: 'green',
                            });
                        }else {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Error') + ' !'),
                                message: (t(res.data.message)),
                                autoClose: 2000,
                                disallowClose: true,
                                color: 'red',
                            });
                        }
                        jobView(jobDetails['id']);
                        setShowLoader(false);
        
                    })
                    .catch(function (err) {
                        console.log(err);
        
                    });
            }
            ,
        }); 
        
    }

    useEffect(()=>{
        // required-designation-time-matrix-for-job-view
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/required-designation-time-matrix-for-job-view`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {id: designation['id']}
        })
            .then(res => {
                setTimeMatrix(res.data.data);
            })
            .catch(function (err) {
                console.log(err);

            });
    }, []);




    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })
    const hasRoleCandidate = roles.find(role => {
        return role === 'ROLE_CANDIDATE';
    })

    return (
        <div className="bg-gray-100">
            <div className="mt-2 bg-white">
                <div className="relative">
                    <div className="relative mx-auto max-w-7xl px-2 lg:px-2">
                        <div
                            className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                            <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                <h3 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">{designation.designationName}</h3>
                                <p className="mt-3 text-base text-gray-500">{designation.description}</p>
                                <div className="mt-4">
                                    <div className="flex items-center">
                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ScheduleInformation')}</h4>
                                        <div className="flex-1 border-t-2 border-gray-200"/>
                                    </div>
                                    <ul role="list"
                                        className="my-4 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                        <li className="flex items-start lg:col-span-1">
                                            <div className="flex-shrink-0">
                                                <HiOutlineUserCircle size={20} className={`bg-indigo-500 text-white rounded-full`}/>
                                            </div>
                                            <p className="ml-3 text-sm text-gray-700">{t('NoOfPerson')}: {designation.noOfPerson}</p>
                                        </li>

                                        {/* <li className="flex items-start lg:col-span-1">
                                            <div className="flex-shrink-0">
                                                <HiOutlineClock size={20} className={`bg-indigo-500 text-white rounded-full`}/>
                                            </div>
                                            <p className="ml-3 text-sm text-gray-700">{t('Start')}: {designation.startTime ? format(new Date(designation.startTime.date), 'dd/MM/Y hh:mm a') : ''}</p>
                                        </li>

                                        <li className="flex items-start lg:col-span-1">
                                          

                                        </li>

                                        <li className="flex items-start lg:col-span-1">
                                            <div className="flex-shrink-0">
                                                <HiOutlineClock size={20} className={`bg-indigo-500 text-white rounded-full`}/>
                                            </div>
                                            <p className="ml-3 text-sm text-gray-700">{t('End')}: {designation.endTime ? format(new Date(designation.endTime.date), 'dd/MM/Y hh:mm a') : ''}</p>
                                        </li> */}
                                    </ul>

                                    <div className="relative">
                                        <LoadingOverlay visible={!timeMatrix} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'xs' }} overlayColor="bg-indigo-100" />

                                        {
                                            Object.keys(timeMatrix).map((time, pIndex)=>(
                                                <div key={time} className='bg-indigo-100 w-full px-3 py-2 mb-1 rounded'>
                                                    <h2>{t('InTimeOutTime')}: {time}</h2>
                                                    {/* <ul role="list" className="space-y-1 text-xs text-gray-600 grid grid-cols-2 gap-x-1"> */}
                                                    <ul role="list" className="flex flex-wrap w-full text-xs text-gray-600">
                                                        
                                                        {
                                                            timeMatrix[time].map((item, cIndex)=>(
                                                            //    <li key={cIndex} className="flex items-start gap-x-1 col-span-1">
                                                               <li key={cIndex} className="flex items-center w-1/4">
                                                                    <HiCheckCircle size={18} className="h-4 w-4 flex-none text-indigo-800 mr-1"/>{format(new Date(item['date'].date), 'MM/dd/yyyy')}
                                                                </li>
                                                            ))
                                                        }
                                                        
                                                    </ul>


                                                    <ul>
                                                        
                                                    </ul>
                                                </div>
                                            ))
                                        }
                                    </div>


                                </div>
                            </div>
                            <div className="bg-gray-50 py-8 px-6 text-center lg:flex lg:flex-shrink-0 lg:flex-col lg:justify-center lg:p-12 basis-1/3">
                                {/*<p className="text-lg font-medium leading-6 text-gray-900">Pay once, own it forever</p>*/}

                                {
                                    !hasRoleCandidate && (
                                        <div
                                            className="mt-4 flex items-center justify-center text-5xl font-bold tracking-tight text-gray-900">
                                            <span>{designation.rate ? '$'+designation.rate : ''}</span>
                                            { designation.rate && <span className="ml-3 text-xl font-medium tracking-normal text-gray-500">USD</span>}

                                        </div>
                                    )
                                }
                                
                                <div className="mt-6">
                                    {
                                        hasRoleCandidate && (
                                            <span className="relative isolate inline-flex rounded text-center">

                                                {
                                                    null === designation['applyStatus'] && (
                                                        <button
                                                            className={`disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-100 bg-indigo-800 hover:bg-indigo-700 text-white inline-flex items-center rounded border-none  py-2 px-4 text-sm font-normal shadow-sm focus:outline-none focus:ring-0`}
                                                            disabled={'published' !== jobDetails['status'] || loggedUser.status !== 'active'}
                                                            onClick={()=> jobApply(
                                                                {
                                                                    designationId: designation.id,
                                                                    designationSlug: designation.designationSlug,
                                                                    designationName: designation.designationName,
                                                                    userId: loggedUserId,
                                                                    jobId: jobDetails.id,
                                                                    flag: "apply",
                                                                }
                                                            )}
                                                        >
                                                            <span>{t('Apply')}</span>
                                                        </button>
                                                    )
                                                }

                                                {
                                                    'preferred' === designation['applyStatus'] && (
                                                        <>
                                                            <button
                                                                onClick={()=> jobApply(
                                                                    {
                                                                        designationId: designation.id,
                                                                        designationSlug: designation.designationSlug,
                                                                        userId: loggedUserId,
                                                                        jobId: jobDetails.id,
                                                                        flag: "accept",

                                                                    }
                                                                )}
                                                                type="button"
                                                                className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-100 relative inline-flex items-center rounded-l bg-indigo-800 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                                                disabled={'published' !== jobDetails['status'] || loggedUser.status !== 'active'}

                                                            >
                                                                {t('Accept')}
                                                            </button>
                                                            <button
                                                                onClick={()=> {
                                                                    setShowDeclineModal(true)
                                                                    setDeclineInfo({});
                                                                    setDeclineInfo({
                                                                        designationId: designation.id,
                                                                        designationSlug: designation.designationSlug,
                                                                        date: designation.startTime,
                                                                        userId: loggedUserId,
                                                                        jobId: jobDetails.id,
                                                                        flag: "decline"

                                                                    })

                                                                    /*jobApply(
                                                                        {
                                                                            designationId: designation.id,
                                                                            designationSlug: designation.designationSlug,
                                                                            userId: loggedUserId,
                                                                            jobId: jobDetails.id,
                                                                            flag: "decline",
                                                                            remark: ""

                                                                        }
                                                                    )*/
                                                                }}
                                                                type="button"
                                                                className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-100 relative -ml-px inline-flex items-center rounded-r bg-red-700 px-4 py-2 text-sm font-medium text-white hover:bg-red-600 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                                                disabled={'published' !== jobDetails['status'] || loggedUser.status !== 'active'}

                                                            >
                                                                {t('Decline')}
                                                            </button>
                                                        </>
                                                    )
                                                }
                                                {
                                                    'applied' === designation['applyStatus'] && (
                                                        <span className="inline-flex items-center ml-6 rounded border-none bg-gray-300 py-2 px-4 text-sm font-normal text-white shadow-sm focus:outline-none focus:ring-0">{t('Applied')}</span>
                                                    )
                                                }
                                                {
                                                    'accepted' === designation['applyStatus'] && (
                                                        <span className="inline-flex items-center ml-6 rounded border-none bg-gray-300 py-2 px-4 text-sm font-normal text-white shadow-sm focus:outline-none focus:ring-0">{t('Accepted')}</span>
                                                    )
                                                }
                                                {
                                                    'declined' === designation['applyStatus'] && (
                                                        <span className="inline-flex items-center ml-6 rounded border-none bg-gray-300 py-2 px-4 text-sm font-normal text-white shadow-sm focus:outline-none focus:ring-0">{t('Declined')}</span>
                                                    )
                                                }
                                                {
                                                    'continue' === designation['applyStatus'] && (
                                                        <span className="inline-flex items-center ml-6 rounded border-none bg-gray-300 py-2 px-4 text-sm font-normal text-white shadow-sm focus:outline-none focus:ring-0">{t('Continue')}</span>
                                                    )
                                                }
                                                {
                                                    'confirmed' === designation['applyStatus'] && (
                                                        <span className="inline-flex items-center ml-6 rounded border-none bg-gray-300 py-2 px-4 text-sm font-normal text-white shadow-sm focus:outline-none focus:ring-0">{t('Confirmed')}</span>
                                                    )
                                                }
                                                {
                                                    'completed' === designation['applyStatus'] && (
                                                        <span className="inline-flex items-center ml-1 rounded border-none bg-green-700 py-2 px-4 text-sm font-normal text-white shadow-sm focus:outline-none focus:ring-0">
                                                        <HiOutlineCheck size={16} className={'mr-2'}/>
                                                            {t('Complete')}
                                                    </span>
                                                    )
                                                }
                                                
                                                <LoadingOverlay visible={showLoader} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'xs' }} overlayColor="bg-indigo-100" />

                                        </span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}