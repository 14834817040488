import React, {useEffect, useState, Fragment} from "react";
import {useTranslation} from "react-i18next";
import {
    HiOutlineOfficeBuilding,
    HiChevronLeft,
    HiOutlineBookOpen,
    HiOutlineCheckCircle,
    HiOutlineIdentification,
    HiOutlineBan
} from "react-icons/hi";
import {ScrollArea, Grid, Text, LoadingOverlay, Loader} from "@mantine/core";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import DesignationList from "./inc/DesignationList";
import PreviewPublishDesignationList from "./inc/PreviewPublishDesignationList";
import format from "date-fns/format";
import {openConfirmModal} from '@mantine/modals';
import { showNotification } from "@mantine/notifications";
import ViewCandidate from "../candidate/modal/ViewCandidate";
import { HiOutlineCheck } from "react-icons/hi2";



function ViewJob() {
    const {token, loggedUserId, roles} = JSON.parse(localStorage.getItem('user'));
    const formTableHeight = localStorage.getItem('formTableHeight');
    const fullFormHeight = localStorage.getItem('fullFormHeight');
    const {jobId} = useParams();

    const navigate = useNavigate();

    const {t, i18n} = useTranslation();
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const [recall, setRecall] = useState(false);
    // const [candidateList, setCandidateList] = useState([]);
    const [jobDetails, setJobDetails] = useState({});
    const [candidateId, setCandidateId] = useState(null);
    const [showCandidateProfile, setShowCandidateProfile] = useState(false);
    const [showPageLoader, setShowPageLoader] = useState(true);
    const [publishSpinner, setPublishSpinner] = useState(false);

    const getJobDetails = (jobId) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/show-job`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                setJobDetails(res.data.data);
                setShowPageLoader(false);
                

            });
    }


    const rejectAssignedCandidate = (candidate) => {
        openConfirmModal({
            title: (t('')),
            centered: true,
            children: <Text size="sm">{t('AreYouSure')}?</Text>,
            labels: {confirm: (t('Yes')), cancel: (t('No'))},
            // confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                axios({
                    method: 'delete',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-assign-candidate`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    data: candidate
                })
                    .then(res => {
                        if (res.data.status === 404) {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Warning') + ' !'),
                                message: (t('NotFound')),
                                autoClose: 2000,
                                disallowClose: true,
                                color: 'red',
                            });
                        } else if (res.data.status === 200) {
                            setRecall(prevState => !prevState)

                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Success') + ' !'),
                                message: (t('Removed')),
                                autoClose: 2000,
                                disallowClose: true,
                                color: 'green',
                            });
                        }
                    })
                    .catch(function (error) {
                        setRecall(prevState => !prevState)

                        console.log(error)
                    })
            }
            ,
        }); 
    }

    const assignedCandidateAcceptance = (status, loggedUserId, candidate, acceptance) => {
        openConfirmModal({
            title: (t('')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSure')}?
                </Text>
            ),
            labels: {confirm: (t('Yes')), cancel: (t('No'))},
            confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
            // confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                axios({
                    method: 'put',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-assigned-candidate-acceptance`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                        status,
                        loggedUserId,
                        candidate,
                        acceptance,
                        jobInfo: { id: jobDetails['id'], title: jobDetails['title'], description: jobDetails['description'] }
                    }
                })
                    .then(res => {
                        setRecall(!recall)
                        showNotification({
                            id: 'load-data',
                            loading: false,
                            title: res.data.status === 202 ? (t('Success')) : (t('Error')),
                            message: (t(res.data.message)),
                            autoClose: 2000,
                            disallowClose: true,
                            color: res.data.status === 202 ? 'green' : 'red.7',
                            icon: res.data.status === 202 ? <HiOutlineCheck /> : <HiOutlineBan />
                        });
                       
                    })
                    .catch(function (error) {
                        console.log(error)
                    }); 
            }
        });
    }

    const updateJobStatus = (property, value) => {
        openConfirmModal({
            title: (t('')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSure')}?
                </Text>
            ),
            labels: {confirm: (t('Yes')), cancel: (t('No'))},
            // confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {

                setShowPageLoader(true);
                axios({
                    method: 'put',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/update-job`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    data: {property, value, currentStatus: jobDetails['status']}
                })
                    .then(res => {
                        if(property === 'status' && value === 'cancel'){
                            navigate('/job-post');
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Success')),
                                message: (t(res.data.message)),
                                autoClose: 2000,
                                disallowClose: true,
                                color: 'green',
                            });
                        }
                        getJobDetails(jobId)
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: res.data.status === 202 ? (t('Success')) : (t('Error')),
                            message: (t(res.data.message)),
                            autoClose: 2000,
                            disallowClose: true,
                            color: res.data.status === 202 ? 'green' : 'red',
                        });
                      
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
        });
    }


    function candidateInvitation(jobInfo){

        const designations = jobDetails['designations'];

        let designationsWithPreferredCandidates = {};
        designations.map((designation, index) => {
            designationsWithPreferredCandidates[designation['designationName']] = {
                id: designation['designationAutoId'],
                name: designation['designationName'],
                slug: designation['designationSlug'],
                startTime: designation['startTime'],
                endTime: designation['endTime'],
                preferred: designation['candidates']['preferred'] && designation['candidates']['preferred']
            }
        });

            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-candidate-invitation`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                // data: { designationsWithPreferredCandidates, jobInfo, senderId: loggedUserId, sendAll: data['sendAll']}
                data: { designationsWithPreferredCandidates, jobInfo, senderId: loggedUserId}
            })
                .then(res => {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: ( t('Success')),
                        message: (t(res.data.message)),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'green',
                    });
                })
                .catch(function (error) {
                    console.log(error)
                })
    }


    function jobPublish(){
  
        openConfirmModal({
            title: (t('')),
            centered: true,
            children: <Text size="sm">{t('AreYouSure')}?</Text>,
            labels: {confirm: (t('Yes')), cancel: (t('No'))},
            confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
            onCancel: () => console.log('Cancel'),
            onConfirm: () => {
                setPublishSpinner(true);

                if((new Date(jobDetails['currentDateTime'].date)).setHours(0,0,0,0) > (new Date(jobDetails['startTime'].date)).setHours(0,0,0,0)){
                    setPublishSpinner(false);
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Warning')),
                        message: (t('StartTimeExpired.JobCanNotBePublished')),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });

                    return;
                }

                axios({
                    method: 'put',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/update-job`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    data: { property: 'status', value: 'published', currentStatus: jobDetails['status']}
                })
                    .then(res => {

                        setPublishSpinner(false);

                        if (202 === res.data.status | 200 === res.data.status) {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Success')),
                                message: (t(res.data.message)),
                                autoClose: 2000,
                                disallowClose: true,
                                color: 'green',
                            });

                            if(202 == res.data.status | 200 == res.data.status){
                                candidateInvitation(res.data.data)
                            }

                            getJobDetails(jobId);


                        } else {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Error')),
                                message: (t(res.data.message)),
                                autoClose: 2000,
                                disallowClose: true,
                                color: 'red',
                            });
                        }
        
                    })
                    .catch(function (error) {
                        console.log(error)
                    })

            }
            ,
        });
    }


    useEffect(()=>{
        getJobDetails(jobId)
    },[recall])

    const modalHeight = localStorage.getItem('modalHeight');

    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })

    return (
        <main className="w-full">
            <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start items-center text-gray-600 mb-3">
                <div className={"flex-1"}>
                    <div
                        className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                        <HiOutlineOfficeBuilding size={24} className={'mt-2 mr-2 text-indigo-300'}/>
                        <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                            {jobDetails.title}
                        </span>
                    </div>
                </div>
                <div className={"right flex mr-8"}>
                    <div className="flex items-center justify-center">
                        <div className="inline-flex border-1 border-red-300" role="group">
                            {
                                Object.values(jobDetails).length > 0 && jobDetails['status'] === 'running' && (
                                    <button 
                                        className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-100 inline-flex items-center ml-1 rounded border-none bg-amber-600 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-amber-500 focus:outline-none focus:ring-0"
                                        disabled={Object.values(jobDetails).length > 0 && !jobDetails['enableCancel'] || showPageLoader}
                                        onClick={() => updateJobStatus('status', 'cancel')}
                                    >
                                        <HiOutlineBan size={12} className={'mr-1'}/>{t('CancelJob')}
                                    </button>
                                )
                            }

                            {
                                (() => {
                                    if(Object.values(jobDetails).length > 0){
                                        if(hasRoleModerator){
                                            // if('published' === jobDetails['status'] && (format(new Date(jobDetails['startTime'].date), 'MM/dd/Y') === format(new Date(jobDetails['currentDateTime'].date), 'MM/dd/Y') || new Date(jobDetails['startTime'].date) < new Date(jobDetails['currentDateTime'].date) )){
                                                if('published' === jobDetails['status'] && format(new Date(jobDetails['startTime'].date), 'MM/dd/Y') === format(new Date(jobDetails['currentDateTime'].date), 'MM/dd/Y')){
                                                return (
                                                    <button 
                                                        className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-100 inline-flex items-center ml-1 rounded border-none bg-green-600 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-0"
                                                        onClick={() => updateJobStatus('status', 'running')}
                                                    >
                                                        <HiOutlineIdentification size={12} className={'mr-1'}/>{t('MakeItRunning')}
                                                    </button>
                                                )
                                            }else if('running' === jobDetails['status'] && new Date(format(new Date(jobDetails['currentDateTime'].date), 'MM/dd/Y')) > new Date(format(new Date(jobDetails['endTime'].date), 'MM/dd/Y'))){
                                                return (
                                                    <button 
                                                        className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-100 inline-flex items-center ml-1 rounded border-none bg-green-600 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-0"
                                                        onClick={() => updateJobStatus('status', 'complete')}
                                                    >
                                                        <HiOutlineIdentification size={12} className={'mr-1'}/>{t('MakeItComplete')}
                                                    </button>
                                                )
                                            }else{
                                                return (
                                                    <>
                                                    <span className="bg-gray-100 text-gray-500 inline-flex items-center ml-1 rounded border-none py-2 px-4 text-sm font-normal shadow-sm focus:outline-none focus:ring-0">{ jobDetails['status'].charAt(0).toUpperCase() + jobDetails['status'].slice(1) }</span>
                                                    {
                                                        'in-progress' === jobDetails['status'] &&
                                                        (
                                                            <button
                                                                type='button'
                                                                disabled={publishSpinner}
                                                                className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 bg-green-600 text-white inline-flex items-center ml-1 rounded border-none py-2 px-4 text-sm font-normal shadow-sm focus:outline-none focus:ring-0"
                                                                onClick={() => jobPublish()}

                                                            >
                                                                {publishSpinner ? <Loader  size={16} className={'mr-2'} /> : <HiOutlineBookOpen  size={16} className={'mr-2'} />} {t('MakeItPublish')}

                                                            </button>
                                                        )
                                                    }
                                                    
                                                    </>
                                                )
                                            }
                                        }else{
                                            return (
                                                <span className="bg-gray-100 text-gray-500 inline-flex items-center ml-1 rounded border-none py-2 px-4 text-sm font-normal shadow-sm focus:outline-none focus:ring-0">{ jobDetails['status'].charAt(0).toUpperCase() + jobDetails['status'].slice(1) }</span>
                                            )
    
                                        }
                                    }else{
                                        <span className="relative bg-gray-100 text-gray-500 inline-flex items-center ml-1 rounded border-none py-2 px-4 text-sm font-normal shadow-sm focus:outline-none focus:ring-0">
                                            <LoadingOverlay visible={true} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'xs' }} overlayColor="bg-indigo-100" />
                                        </span>

                                    }

                                })()
                            }
                            <Link to="/job-post" className="inline-flex items-center rounded border-none bg-indigo-800 py-2 px-4 ml-1 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0" >
                                <HiChevronLeft size={12} className={'mr-1'}/>{t('Back')}
                            </Link>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="w-full px-4 flex flex-row flex-wrap relative">
                <LoadingOverlay visible={showPageLoader} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'sm' }} overlayColor="bg-indigo-100" />
                {/* <ScrollArea scrollbarSize={4} h={fullFormHeight} className="w-full"> */}
                    <div className="w-full">
                        <div className="w-full bg-indigo-100 p-2 text-gray-900 rounded">
                            <Grid className="w-full">
                                <Grid.Col span='auto'>
                                    <Text size="sm" weight={400} fw={700}>{t('JobBasicInformation')}</Text>
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <Text size="md" weight={400} fw={700}>{jobDetails && jobDetails['title']}</Text>
                                </Grid.Col>
                                
                                <Grid.Col span='auto'>
                                    <Text size="sm" weight={400} fw={700}>{t('JobDetails')} :</Text>   
                                </Grid.Col>
                            </Grid>
                            <Grid className="w-full">
                                <Grid.Col span='auto'>
                                    <div className='flex flex-row'>
                                        <Text size="sm" weight={400} fw={700} className="text-gray-700">{t('Client')} : </Text>
                                        <Text size="sm" weight={400} className="text-gray-700 ml-1"> {jobDetails && jobDetails['clientName']}</Text>
                                    </div>
                                </Grid.Col>
                                <Grid.Col span='auto'>
                                    <div className='flex flex-start w-full'>
                                        <Text size="sm" weight={400} fw={700} className='w-1/2'>{t('Place')}</Text>
                                        <Text size="sm" weight={400} className='w-1/2'>: {jobDetails && jobDetails['streetAddress']}</Text>
                                    </div>
                                    <div className='flex flex-start w-full'>
                                        <Text size="sm" weight={400} fw={700} className='w-1/2'>{t('TotalNoOfPerson')}</Text>
                                        <Text size="sm" weight={400} className='w-1/2'>: {jobDetails && jobDetails['vacancy']}</Text>
                                    </div>

                                </Grid.Col>
                                <Grid.Col span='auto'>
                                    <div className='flex flex-start w-full'>
                                        <Text size="sm" weight={400} fw={700} className='w-1/2'>{t('StartDate')}</Text>
                                        <Text size="sm" weight={400} className='w-1/2'>: {jobDetails && jobDetails['startTime'] && format(new Date(jobDetails['startTime'].date), 'MM/dd/Y')}</Text>
                                    </div>
                                    <div className='flex flex-start w-full'>
                                        <Text size="sm" weight={400} fw={700} className='w-1/2'>{t('EndDate')}</Text>
                                        <Text size="sm" weight={400} className='w-1/2'>: {jobDetails && jobDetails['endTime'] && format(new Date(jobDetails['endTime'].date), 'MM/dd/Y')}</Text>
                                    </div>
                                    {/* <Text size="sm" weight={400}>{t('StartDate')} : {jobDetails && jobDetails['startTime'] && format(new Date(jobDetails['startTime'].date), 'MM/dd/Y') }</Text> */}
                                    {/* <Text size="sm" weight={400}>{t('EndDate')} : {jobDetails && jobDetails['endTime'] && format(new Date(jobDetails['endTime'].date), 'MM/dd/Y') }</Text> */}
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <ScrollArea scrollbarSize={4} style={{ height: 96}} type='auto' className="border border-1 border-gray-400 p-1 rounded">
                                        <Text size="sm" weight={400}>{jobDetails && jobDetails['description']}</Text>
                                    </ScrollArea>
                                </Grid.Col>
                            </Grid>
                        </div>


                        <ScrollArea scrollbarSize={4} h={window.innerHeight - (58 + 48 + 148 + 40)}>
                        {
                            Object.values(jobDetails).length > 0 && jobDetails['designations'].length > 0 && jobDetails['designations'].map((designation, index)=>(
                                designation['designationSlug'] && (
                                    <PreviewPublishDesignationList
                                        key={designation['id']}
                                        jobDetails={jobDetails}
                                        designation={designation}
                                        rejectAssignedCandidate={rejectAssignedCandidate}
                                        setCandidateId={setCandidateId}
                                        setShowCandidateProfile={setShowCandidateProfile}
                                        assignedCandidateAcceptance={assignedCandidateAcceptance}
                                    />
                                )
                            ))
                        }
                        </ScrollArea>
                    </div>
                {/* </ScrollArea> */}
                
                
            </div>
            { showCandidateProfile && <ViewCandidate setShowModal={setShowCandidateProfile} candidateid={candidateId}/> }
        </main>

    );
}

export default ViewJob;
