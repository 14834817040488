import React, {Fragment, useEffect, useState} from 'react';
import {HiOutlineExclamation, HiOutlineOfficeBuilding} from "react-icons/hi";
import {Link} from "react-router-dom";
import {AiOutlineFileSearch, AiOutlineLoading3Quarters} from "react-icons/ai";
import {Tab} from "@headlessui/react";
import {Select} from "@mantine/core";
import {FiArrowDownRight, FiSave, FiX} from "react-icons/fi";
import {useTranslation} from 'react-i18next';
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";

function Register() {
    const {t, i18n} = useTranslation();
    const {token} = JSON.parse(localStorage.getItem('user'));
    const fullFormHeight = localStorage.getItem('fullFormHeight');
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [stateData, setStateData] = useState('');
    const [cityData, setCityData] = useState('');
    const [cities, setCities] = useState([]);
    const [spinner, setSpinner] = useState(false);








    const [companyStatus, setCompanyStatus] = useState([]);
    let companyStatusDropdown = [];
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=company-status&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setCompanyStatus(res.data.data);
                }

            });
    }, []);

    if (companyStatus) {
        companyStatusDropdown =
            companyStatus.map((type, index) => {
                return ({
                    'label': type.name, 'value': type.id
                })
            })
    }
    const [companyStatusData, setCompanyStatusData] = useState('');

    // on change company status data get
    const CompanyStatusDataHandel = (e) => {
        setCompanyStatusData(e);
    }


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setCountries(res.data.data);
            });
    }, []);

    let countryDropdown =
        countries.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })
    const [countryData, setCountryData] = useState('');
    const CountryDataHandel = (e) => {
        setCountryData(e);
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/state/dropdown?country_id=${e}`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setStates(res.data.data)
                }
            });

        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/city/dropdown?country_id=${e}`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setCities(res.data.data)
                }
            });
    }

    let stateDropdown =
        states.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })
    // on change state data get
    const StateDataHandel = (e) => {
        setStateData(e);
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/state/wise/city/dropdown?state_id=${e}`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setCities(res.data.data)
                }
            });
    }

    let cityDropdown =
        cities.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })
    //city data handel
    const CityDataHandel = (e) => {
        setCityData(e);
    }


    const [positionData, setPositionData] = useState('');
    const [personInChargePositionData, setPersonInChargePositionData] = useState('');
    const [personInChargeBillingPositionData, setPersonInChargeBillingPositionData] = useState('');
    const [positions, setPositions] = useState([]);
    let positionDropdown = [];
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=position&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setPositions(res.data.data);
                }

            });
    }, []);

    if (positions) {
        positionDropdown =
            positions.map((type, index) => {
                return ({
                    'label': type.name, 'value': type.id
                })
            })
    }


    // on change position data get
    const PositionDataHandel = (e) => {
        setPositionData(e);
    }
    // End get position dropdown

    // on change person in charge position data get
    const PersonInChargePositionDataHandel = (e) => {
        setPersonInChargePositionData(e);
    }
    // End get person in charge position dropdown

    // on change person in charge billing position data get
    const PersonInChargeBillingPositionDataHandel = (e) => {
        setPersonInChargeBillingPositionData(e);
    }
    // End get person in charge billing position dropdown


    // start get Currency dropdown
    const [currencyData, setCurrencyData] = useState('');
    const [currencies, setCurrency] = useState([]);
    let currencyDropdown = [];
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=currency&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setCurrency(res.data.data);
                }

            });
    }, []);

    if (currencies) {
        currencyDropdown =
            currencies.map((type, index) => {
                return ({
                    'label': type.name, 'value': type.id
                })
            })
    }


    // on change department data get
    const CurrencyDataHandel = (e) => {
        setCurrencyData(e);
    }


    //Form validation
    const validationSchema = Yup.object().shape({
        // name: Yup.string().required(t("NameIsRequired")),
    });

    // get functions to build form with useForm() hook
    const formOptions = {resolver: yupResolver(validationSchema)};
    const {register, handleSubmit, reset, formState, setValue} = useForm(formOptions);
    const {errors} = formState;

    function formSubmit(data) {

        console.log(data);

    }

    return (
        <main className="w-full" >
            <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                <div className={"flex-1"}>
                    <div
                        className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                        <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('NewEmployee')}
                            <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('EmployeeRegistration')}</sub>
                            </span>
                    </div>
                </div>
                <div className={"right flex mr-8"}>
                    <div className="flex items-center justify-center">
                        <div className="inline-flex border-1 border-red-300" role="group">
                            <Link to="/job-post" className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                            >
                                <AiOutlineFileSearch size={12} className={'mr-1'}/>{t('Employees')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="min-h-full w-full">
                <div className="flex w-full md:w-auto">
                    <div className="bg-red-100 flex-1">
                        <div className="min-w-0 flex-1 bg-white xl:flex">
                            <div className="bg-white lg:min-w-0 lg:flex-1">
                                <div className="h-full px-4 sm:px-6 lg:px-4">
                                    <div className="relative">
                                        <div className={'flex flex-1 w-full'}>
                                            <div className="w-full mr-0 mx-auto border">
                                                <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                    <div
                                                        className="px-8 py-3 relative flex flex-col min-w-0 break-words w-full border-0 inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
                                                        style={{height: fullFormHeight}}>


                                                        {/*                COMPANY INFORMATION START                   */}
                                                        <div className="md:grid md:grid-cols-5 md:gap-6">
                                                            <div className="md:col-span-1">
                                                                <div className="px-4 sm:px-0">
                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("CompanyInformation")}</h3>
                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                        {t('CompanyDetails')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-5 md:col-span-4 md:mt-0">
                                                                <div
                                                                    className="shadow sm:overflow-hidden sm:rounded-md">
                                                                    <div
                                                                        className="space-y-6 bg-gray-50 px-4 py-5 sm:p-6">
                                                                        <div className="mb-3 w-full items-center">
                                                                            <label htmlFor="CompanyName"
                                                                                   className="form-input-sm-label-required">{t('CompanyName')}</label>
                                                                            <div
                                                                                className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                {errors.companyName ?
                                                                                    <div
                                                                                        className="form-input-sm-error">
                                                                                        <HiOutlineExclamation
                                                                                            size={16}
                                                                                            className={'mr-2'}/> {errors.companyName}
                                                                                    </div> : ''}
                                                                                <input
                                                                                    type="text"
                                                                                    {...register("companyName")}
                                                                                    className={`form-input-sm-control`}
                                                                                    name="companyName"
                                                                                    id="companyName"
                                                                                    placeholder={t("EnterCompanyName")}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                            <div
                                                                                className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="email"
                                                                                       className="form-input-sm-label-required">{t('Email')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    {errors.email ?
                                                                                        <div
                                                                                            className="form-input-sm-error">
                                                                                            <HiOutlineExclamation
                                                                                                size={16}
                                                                                                className={'mr-2'}/> {errors.email}
                                                                                        </div> : ''}

                                                                                    <input
                                                                                        {...register("email")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control ${errors.email ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                        name="email"
                                                                                        id="email"
                                                                                        placeholder={t("EnterEmail")}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="phone"
                                                                                       className="form-input-sm-label-required">{t('Phone')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    {errors.phone ?
                                                                                        <div
                                                                                            className="form-input-sm-error">
                                                                                            <HiOutlineExclamation
                                                                                                size={16}
                                                                                                className={'mr-2'}/> {errors.phone}
                                                                                        </div> : ''}

                                                                                    <input
                                                                                        {...register("phone")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control ${errors.phone ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                        name="phone"
                                                                                        id="phone"
                                                                                        placeholder={t("EnterPhoneNumber")}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div
                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                            <div
                                                                                className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="Website"
                                                                                       className="form-input-sm-label">{t('Website')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        {...register("website")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control border-gray-300 focus:border-blue-600 `}
                                                                                        name="website"
                                                                                        id="website"
                                                                                        placeholder={t("EnterWebsiteLink")}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="CompanyStatus"
                                                                                       className="form-input-sm-label-required">{t('CompanyStatus')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    {errors.CompanyStatus ?
                                                                                        <div
                                                                                            className="form-input-sm-error">
                                                                                            <HiOutlineExclamation
                                                                                                size={16}
                                                                                                className={'mr-2'}/> {errors.CompanyStatus}
                                                                                        </div> : ''}
                                                                                    <Select
                                                                                        placeholder={t('ChooseCompanyStatus')}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        data={companyStatusDropdown}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={CompanyStatusDataHandel}
                                                                                        value={companyStatusData}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="mb-3 w-full items-center">
                                                                            <label htmlFor="CompanyBillingAddress"
                                                                                   className="form-input-sm-label">{t('BillingAddress')}</label>
                                                                            <div
                                                                                className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                <input
                                                                                    {...register("CompanyBillingAddress")}
                                                                                    className={`form-input-sm-control`}
                                                                                    name="CompanyBillingAddress"
                                                                                    placeholder={t("EnterBillingAddress")}
                                                                                />
                                                                            </div>
                                                                        </div>


                                                                        <div className="mb-3 w-full items-center">
                                                                            <label htmlFor="Remark"
                                                                                   className="form-input-sm-label">{t('Remark')}</label>
                                                                            <div
                                                                                className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                <input
                                                                                    {...register("remark")}
                                                                                    className={`form-input-sm-control`}
                                                                                    name="remark"
                                                                                    placeholder={t("EnterRemark")}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="py-5 hidden sm:block">
                                                            <div className="border-t border-gray-200"/>
                                                        </div>
                                                        {/*                COMPANY INFORMATION END                   */}


                                                        {/*                COMPANY LOCATION INFORMATION START                   */}
                                                        <div className="md:grid md:grid-cols-5 md:gap-6">
                                                            <div className="md:col-span-1">
                                                                <div className="px-4 sm:px-0">
                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("CompanyLocation")}</h3>
                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                        {t('CompanyLocationDetails')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-5 md:col-span-4 md:mt-0">
                                                                <div
                                                                    className="shadow sm:overflow-hidden sm:rounded-md">
                                                                    <div
                                                                        className="space-y-6 bg-gray-50 px-4 py-5 sm:p-6">
                                                                        <div className="mb-3 w-full items-center">
                                                                            <label htmlFor="address"
                                                                                   className="form-input-sm-label-required">{t('Address')}</label>
                                                                            <div
                                                                                className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                {errors.address ?
                                                                                    <div
                                                                                        className="form-input-sm-error">
                                                                                        <HiOutlineExclamation
                                                                                            size={16}
                                                                                            className={'mr-2'}/> {errors.address}
                                                                                    </div> : ''}
                                                                                <textarea
                                                                                    {...register("address")}
                                                                                    className={`form-input-sm-control`}
                                                                                    name="address"
                                                                                    placeholder={t("EnterAddress")}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                            <div
                                                                                className="mb-3 w-2/3 items-center">
                                                                                <label htmlFor="streetAddress"
                                                                                       className="form-input-sm-label">{t('StreetAddress')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        type="text"
                                                                                        {...register("streetAddress")}
                                                                                        className={`form-input-sm-control`}
                                                                                        name="streetAddress"
                                                                                        id="streetAddress"
                                                                                        placeholder={t("EnterStreetAddress")}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label htmlFor="zipCode"
                                                                                       className="form-input-sm-label">{t('ZipCode')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                    <input
                                                                                        {...register("zipCode")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                                                        name="zipCode"
                                                                                        id="zipCode"
                                                                                        placeholder={t("EnterZipCode")}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label htmlFor="country"
                                                                                       className="form-input-sm-label-required">{t('Country')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    {errors.country ?
                                                                                        <div
                                                                                            className="form-input-sm-error">
                                                                                            <HiOutlineExclamation
                                                                                                size={16}
                                                                                                className={'mr-2'}/> {errors.country}
                                                                                        </div> : ''}

                                                                                    <Select
                                                                                        {...register("country")}
                                                                                        placeholder={t('ChooseCountry')}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        data={countryDropdown}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={CountryDataHandel}
                                                                                        value={countryData}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label htmlFor="state"
                                                                                       className="form-input-sm-label">{t('State')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                    <Select
                                                                                        placeholder={t('ChooseState')}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        data={stateDropdown}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={StateDataHandel}
                                                                                        value={stateData}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label htmlFor="city"
                                                                                       className="form-input-sm-label-required">{t('City')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    {errors.city ?
                                                                                        <div
                                                                                            className="form-input-sm-error">
                                                                                            <HiOutlineExclamation
                                                                                                size={16}
                                                                                                className={'mr-2'}/> {errors.city}
                                                                                        </div> : ''}
                                                                                    <Select
                                                                                        placeholder={t('ChooseCity')}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        data={cityDropdown}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={CityDataHandel}
                                                                                        value={cityData}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="py-5 hidden sm:block">
                                                            <div className="border-t border-gray-200"/>
                                                        </div>
                                                        {/*                COMPANY LOCATION INFORMATION END                   */}


                                                        {/*                CONTACT PERSON INFORMATION START                   */}
                                                        <div className="md:grid md:grid-cols-5 md:gap-6">
                                                            <div className="md:col-span-1">
                                                                <div className="px-4 sm:px-0">
                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("ContactPersonInformation")}</h3>
                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                        {t('ContactPersonDetails')}
                                                                    </p>
                                                                </div>
                                                            </div>


                                                            <div className="mt-5 md:col-span-4 md:mt-0">
                                                                <div
                                                                    className="shadow sm:overflow-hidden sm:rounded-md">
                                                                    <div
                                                                        className="space-y-6 bg-gray-50 px-4 py-5 sm:p-6">


                                                                        <div
                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label
                                                                                    htmlFor="contactPersonFirstName"
                                                                                    className="form-input-sm-label-required">{t('FirstName')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    {errors.contactPersonFirstName ?
                                                                                        <div
                                                                                            className="form-input-sm-error">
                                                                                            <HiOutlineExclamation
                                                                                                size={16}
                                                                                                className={'mr-2'}/> {errors.contactPersonFirstName}
                                                                                        </div> : ''}

                                                                                    <input
                                                                                        {...register("contactPersonFirstName")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control ${errors.contactPersonFirstName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                        name="contactPersonFirstName"
                                                                                        id="contactPersonFirstName"
                                                                                        placeholder={t("EnterFirstName")}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label
                                                                                    htmlFor="contactPersonMiddleName"
                                                                                    className="form-input-sm-label">{t('MiddleName')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        {...register("contactPersonMiddleName")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control`}
                                                                                        name="contactPersonMiddleName"
                                                                                        id="contactPersonMiddleName"
                                                                                        placeholder={t("EnterMiddleName")}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label
                                                                                    htmlFor="contactPersonLastName"
                                                                                    className="form-input-sm-label-required">{t('LastName')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    {errors.contactPersonLastName ?
                                                                                        <div
                                                                                            className="form-input-sm-error">
                                                                                            <HiOutlineExclamation
                                                                                                size={16}
                                                                                                className={'mr-2'}/> {errors.contactPersonLastName}
                                                                                        </div> : ''}

                                                                                    <input
                                                                                        {...register("contactPersonLastName")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control ${errors.contactPersonLastName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                        name="contactPersonLastName"
                                                                                        id="contactPersonLastName"
                                                                                        placeholder={t("EnterLastName")}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div
                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label htmlFor="contactPersonPhone"
                                                                                       className="form-input-sm-label-required">{t('Phone')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    {errors.contactPersonPhone ?
                                                                                        <div
                                                                                            className="form-input-sm-error">
                                                                                            <HiOutlineExclamation
                                                                                                size={16}
                                                                                                className={'mr-2'}/> {errors.contactPersonPhone}
                                                                                        </div> : ''}
                                                                                    <input
                                                                                        type="text"
                                                                                        {...register("contactPersonPhone")}
                                                                                        className={`form-input-sm-control ${errors.contactPersonPhone ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'}`}
                                                                                        name="contactPersonPhone"
                                                                                        id="contactPersonPhone"
                                                                                        placeholder={t("EnterPhone")}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label htmlFor="contactPersonEmail"
                                                                                       className="form-input-sm-label-required">{t('Email')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    {errors.contactPersonEmail ?
                                                                                        <div
                                                                                            className="form-input-sm-error">
                                                                                            <HiOutlineExclamation
                                                                                                size={16}
                                                                                                className={'mr-2'}/> {errors.contactPersonEmail}
                                                                                        </div> : ''}
                                                                                    <input
                                                                                        {...register("contactPersonEmail")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control ${errors.contactPersonEmail ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'}`}
                                                                                        name="contactPersonEmail"
                                                                                        id="contactPersonEmail"
                                                                                        placeholder={t("EnterEmail")}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label htmlFor="Position"
                                                                                       className="form-input-sm-label-required">{t('Position')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    {errors.Position ?
                                                                                        <div
                                                                                            className="form-input-sm-error">
                                                                                            <HiOutlineExclamation
                                                                                                size={16}
                                                                                                className={'mr-2'}/> {errors.Position}
                                                                                        </div> : ''}
                                                                                    <Select
                                                                                        placeholder={t('ChoosePosition')}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        data={positionDropdown}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={PositionDataHandel}
                                                                                        value={positionData}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="py-5 hidden sm:block">
                                                            <div className="border-t border-gray-200"/>
                                                        </div>
                                                        {/*                CONTACT PERSON INFORMATION END                   */}


                                                        {/*                Person In Charge Information START                   */}
                                                        <div className="md:grid md:grid-cols-5 md:gap-6">
                                                            <div className="md:col-span-1">
                                                                <div className="px-4 sm:px-0">
                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("PersonInChargeInformation")}</h3>
                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                        {t('PersonInChargeDetails')}
                                                                    </p>
                                                                </div>
                                                            </div>


                                                            <div className="mt-5 md:col-span-4 md:mt-0">
                                                                <div
                                                                    className="shadow sm:overflow-hidden sm:rounded-md">
                                                                    <div
                                                                        className="space-y-6 bg-gray-50 px-4 py-5 sm:p-6">


                                                                        <div
                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label
                                                                                    htmlFor="PersonInChargeFirstName"
                                                                                    className="form-input-sm-label">{t('FirstName')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                    <input
                                                                                        {...register("PersonInChargeFirstName")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control `}
                                                                                        name="PersonInChargeFirstName"
                                                                                        id="PersonInChargeFirstName"
                                                                                        placeholder={t("EnterFirstName")}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label
                                                                                    htmlFor="PersonInChargeMiddleName"
                                                                                    className="form-input-sm-label">{t('MiddleName')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        {...register("PersonInChargeMiddleName")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control`}
                                                                                        name="PersonInChargeMiddleName"
                                                                                        id="PersonInChargeMiddleName"
                                                                                        placeholder={t("EnterMiddleName")}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label
                                                                                    htmlFor="PersonInChargeLastName"
                                                                                    className="form-input-sm-label">{t('LastName')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                    <input
                                                                                        {...register("PersonInChargeLastName")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control `}
                                                                                        name="PersonInChargeLastName"
                                                                                        id="PersonInChargeLastName"
                                                                                        placeholder={t("EnterLastName")}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div
                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label htmlFor="PersonInChargePhone"
                                                                                       className="form-input-sm-label">{t('Phone')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        type="text"
                                                                                        {...register("PersonInChargePhone")}
                                                                                        className={`form-input-sm-control `}
                                                                                        name="PersonInChargePhone"
                                                                                        id="PersonInChargePhone"
                                                                                        placeholder={t("EnterPhone")}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label htmlFor="PersonInChargeEmail"
                                                                                       className="form-input-sm-label">{t('Email')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                    <input
                                                                                        {...register("PersonInChargeEmail")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control `}
                                                                                        name="PersonInChargeEmail"
                                                                                        id="PersonInChargeEmail"
                                                                                        placeholder={t("EnterEmail")}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label
                                                                                    htmlFor="PersonInChargePosition"
                                                                                    className="form-input-sm-label">{t('Position')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    <Select
                                                                                        placeholder={t('ChoosePosition')}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        data={positionDropdown}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={PersonInChargePositionDataHandel}
                                                                                        value={personInChargePositionData}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="py-5 hidden sm:block">
                                                            <div className="border-t border-gray-200"/>
                                                        </div>
                                                        {/*                Person In Charge Information END                   */}


                                                        {/*                Person In Charge BILLING Information START                   */}
                                                        <div className="md:grid md:grid-cols-5 md:gap-6">
                                                            <div className="md:col-span-1">
                                                                <div className="px-4 sm:px-0">
                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("PersonInChargeBillingInformation")}</h3>
                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                        {t('PersonInChargeBillingDetails')}
                                                                    </p>
                                                                </div>
                                                            </div>


                                                            <div className="mt-5 md:col-span-4 md:mt-0">
                                                                <div
                                                                    className="shadow sm:overflow-hidden sm:rounded-md">
                                                                    <div
                                                                        className="space-y-6 bg-gray-50 px-4 py-5 sm:p-6">


                                                                        <div
                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label
                                                                                    htmlFor="PersonInChargeBillingFirstName"
                                                                                    className="form-input-sm-label">{t('FirstName')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                    <input
                                                                                        {...register("PersonInChargeBillingFirstName")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control `}
                                                                                        name="PersonInChargeBillingFirstName"
                                                                                        id="PersonInChargeBillingFirstName"
                                                                                        placeholder={t("EnterFirstName")}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label
                                                                                    htmlFor="PersonInChargeBillingMiddleName"
                                                                                    className="form-input-sm-label">{t('MiddleName')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        {...register("PersonInChargeBillingMiddleName")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control`}
                                                                                        name="PersonInChargeBillingMiddleName"
                                                                                        id="PersonInChargeBillingMiddleName"
                                                                                        placeholder={t("EnterMiddleName")}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label
                                                                                    htmlFor="PersonInChargeBillingLastName"
                                                                                    className="form-input-sm-label">{t('LastName')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                    <input
                                                                                        {...register("PersonInChargeBillingLastName")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control `}
                                                                                        name="PersonInChargeBillingLastName"
                                                                                        id="PersonInChargeBillingLastName"
                                                                                        placeholder={t("EnterLastName")}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div
                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label
                                                                                    htmlFor="PersonInChargeBillingPhone"
                                                                                    className="form-input-sm-label">{t('Phone')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        type="text"
                                                                                        {...register("PersonInChargeBillingPhone")}
                                                                                        className={`form-input-sm-control`}
                                                                                        name="PersonInChargeBillingPhone"
                                                                                        id="PersonInChargeBillingPhone"
                                                                                        placeholder={t("EnterPhone")}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label
                                                                                    htmlFor="PersonInChargeBillingEmail"
                                                                                    className="form-input-sm-label">{t('Email')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                    <input
                                                                                        {...register("PersonInChargeBillingEmail")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                                                        name="PersonInChargeBillingEmail"
                                                                                        id="PersonInChargeBillingEmail"
                                                                                        placeholder={t("EnterEmail")}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="mb-3 w-1/3 items-center">
                                                                                <label
                                                                                    htmlFor="PersonInChargePosition"
                                                                                    className="form-input-sm-label">{t('Position')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    <Select
                                                                                        placeholder={t('ChoosePosition')}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        data={positionDropdown}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={PersonInChargeBillingPositionDataHandel}
                                                                                        value={personInChargeBillingPositionData}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="py-5 hidden sm:block">
                                                            <div className="border-t border-gray-200"/>
                                                        </div>
                                                        {/*                Person In Charge BILLING Information END                   */}


                                                        {/*                BILLING Information START                   */}
                                                        <div className="md:grid md:grid-cols-5 md:gap-6">
                                                            <div className="md:col-span-1">
                                                                <div className="px-4 sm:px-0">
                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("BillingInformation")}</h3>
                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                        {t('BillingDetails')}
                                                                    </p>
                                                                </div>
                                                            </div>


                                                            <div className="mt-5 md:col-span-4 md:mt-0">
                                                                <div
                                                                    className="shadow sm:overflow-hidden sm:rounded-md">
                                                                    <div
                                                                        className="space-y-6 bg-gray-50 px-4 py-5 sm:p-6">


                                                                        <div
                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                            <div
                                                                                className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="BankName"
                                                                                       className="form-input-sm-label-required">{t('BankName')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    {errors.BankName ?
                                                                                        <div
                                                                                            className="form-input-sm-error">
                                                                                            <HiOutlineExclamation
                                                                                                size={16}
                                                                                                className={'mr-2'}/> {errors.BankName}
                                                                                        </div> : ''}

                                                                                    <input
                                                                                        {...register("BankName")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control ${errors.BankName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                        name="BankName"
                                                                                        id="BankName"
                                                                                        placeholder={t("EnterBankName")}
                                                                                    />
                                                                                </div>
                                                                            </div>


                                                                            <div
                                                                                className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="BranchName"
                                                                                       className="form-input-sm-label-required">{t('BranchName')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    {errors.BranchName ?
                                                                                        <div
                                                                                            className="form-input-sm-error">
                                                                                            <HiOutlineExclamation
                                                                                                size={16}
                                                                                                className={'mr-2'}/> {errors.BranchName}
                                                                                        </div> : ''}

                                                                                    <input
                                                                                        {...register("BranchName")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control ${errors.BranchName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                        name="BranchName"
                                                                                        id="BranchName"
                                                                                        placeholder={t("EnterBranchName")}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div
                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                            <div
                                                                                className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="BillingPhone"
                                                                                       className="form-input-sm-label-required">{t('Phone')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                    {errors.BillingPhone ?
                                                                                        <div
                                                                                            className="form-input-sm-error">
                                                                                            <HiOutlineExclamation
                                                                                                size={16}
                                                                                                className={'mr-2'}/> {errors.BillingPhone}
                                                                                        </div> : ''}
                                                                                    <input
                                                                                        type="text"
                                                                                        {...register("BillingPhone")}
                                                                                        className={`form-input-sm-control ${errors.BillingPhone ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                        name="BillingPhone"
                                                                                        id="BillingPhone"
                                                                                        placeholder={t("EnterPhone")}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="BillingEmail"
                                                                                       className="form-input-sm-label-required">{t('Email')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    {errors.BillingEmail ?
                                                                                        <div
                                                                                            className="form-input-sm-error">
                                                                                            <HiOutlineExclamation
                                                                                                size={16}
                                                                                                className={'mr-2'}/> {errors.BillingEmail}
                                                                                        </div> : ''}
                                                                                    <input
                                                                                        {...register("BillingEmail")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control ${errors.BillingEmail ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                        name="BillingEmail"
                                                                                        id="BillingEmail"
                                                                                        placeholder={t("EnterEmail")}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div
                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                            <div
                                                                                className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="BillingWebsite"
                                                                                       className="form-input-sm-label">{t('Website')}</label>
                                                                                <div
                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                    <input
                                                                                        {...register("BillingWebsite")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                                                        name="BillingWebsite"
                                                                                        id="BillingWebsite"
                                                                                        placeholder={t("EnterWebsiteLink")}
                                                                                    />
                                                                                </div>
                                                                            </div>


                                                                            <div
                                                                                className="mb-3 w-1/2 items-center">
                                                                                <label htmlFor="Currency"
                                                                                       className="form-input-sm-label">{t('Currency')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                    <Select
                                                                                        placeholder={t('ChooseCurrency')}
                                                                                        searchable clearable
                                                                                        allowDeselect
                                                                                        nothingFound="No options"
                                                                                        data={currencyDropdown}
                                                                                        transition="pop-top-left"
                                                                                        transitionDuration={80}
                                                                                        transitionTimingFunction="ease"
                                                                                        onChange={CurrencyDataHandel}
                                                                                        value={currencyData}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="mb-3 w-full items-center">
                                                                            <label htmlFor="BillingAddress"
                                                                                   className="form-input-sm-label-required">{t('Address')}</label>
                                                                            <div
                                                                                className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                {errors.BillingAddress ?
                                                                                    <div
                                                                                        className="form-input-sm-error">
                                                                                        <HiOutlineExclamation
                                                                                            size={16}
                                                                                            className={'mr-2'}/> {errors.BillingAddress}
                                                                                    </div> : ''}
                                                                                <input
                                                                                    {...register("BillingAddress")}
                                                                                    className={`form-input-sm-control ${errors.BillingAddress ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                    name="BillingAddress"
                                                                                    placeholder={t("EnterAddress")}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="py-5 hidden sm:block">
                                                            <div className="border-t border-gray-200"/>
                                                        </div>
                                                        {/*                BILLING Information END                   */}

                                                    </div>

                                                    <footer className="relative mt-1">
                                                        <div className="container mx-auto justify-items-center">
                                                            <div
                                                                className="text-right pt-0.5 mb-0.5">
                                                                <button type='reset'
                                                                        className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                ><FiX size={16} className={'mr-2'}/>
                                                                    <span>{t('Cancel')}</span>
                                                                </button>
                                                                <button type='submit'
                                                                        className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                    {spinner ? (
                                                                        <AiOutlineLoading3Quarters
                                                                            className={'mr-2 animate-spin'}
                                                                            size={16}/>
                                                                    ) : (<FiSave size={16} className={'mr-2'}/>)}
                                                                    <span>{t('Save')}</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </footer>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    );
}

export default Register;