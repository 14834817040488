import React, {Fragment, useState} from "react";

import {useTranslation} from "react-i18next";
import button from "@material-tailwind/react";
import {HiHome, HiOutlineCalendar, HiOutlineExclamation, HiOutlineX} from "react-icons/hi";
import {ScrollArea, Table, LoadingOverlay} from "@mantine/core";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {FiArrowRight} from "react-icons/fi";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import {showNotification} from "@mantine/notifications";

export default function NoShowModal({setShowModal, detailsData, setRefreshList}) {
    const {t, i18n} = useTranslation();
    const {token, loggedUserId} = JSON.parse(localStorage.getItem("user"));
    const modalHeight = localStorage.getItem("modalHeight");
    const [spinner, setSpinner] = useState(false);

    const validationSchema = Yup.object().shape({
        remark: Yup.string().required(t('RemarkIsRequired')),

    });

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState, setValue} = useForm(formOptions);
    const {errors} = formState;


    function formSubmit(payload) {
        setSpinner(true);
        payload["rowId"] = detailsData['id'];
        payload["createdById"] = loggedUserId;
        payload["jobId"] = detailsData['jobId'];
        payload["reqDesignationId"] = detailsData['reqDesignationId'];
        payload["candidateId"] = detailsData['userId'];
        payload["clientId"] = detailsData['clientId'];

        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-candidate-no-show`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: payload
        })
            .then(res => {
                // console.log(parameters)
                if (200 === res.data.status){
                    setRefreshList(true);
                    setShowModal(false);
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Success')),
                        message: (t(res.data.message)),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'green',
                    });
                }else {
                    setShowModal(false);
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: (t(res.data.message)),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });
                }
            })
            .catch(function (err) {
                console.log(err);
            });

    }


    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-gray-800 relative">
                        {spinner && <LoadingOverlay visible={true} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'sm' }} overlayColor="bg-indigo-100"/>}

                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('NoShow')}</h5>
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <div role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <form onSubmit={handleSubmit(formSubmit)}>

                                                    <div className="w-full">
                                                        <label htmlFor="remark" className="form-input-sm-label-required">{t('Remark')}</label>
                                                        <div
                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                            {
                                                                errors.remark?.type === 'required' &&
                                                                <div className="form-input-sm-error">
                                                                    <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.remark?.message}
                                                                </div>
                                                            }
                                                            <textarea
                                                                {...register("remark")}
                                                                className={`form-input-sm-control-textarea ${errors.details ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                name="remark"
                                                                id="remark"
                                                                placeholder={t("Remark")}
                                                                rows={20}
                                                            />
                                                        </div>
                                                    </div>

                                                    <footer className="relative mt-1">
                                                        {/*<div className="mr-3">*/}
                                                            <div className="text-right pt-0.5 mb-0.5">
                                                                <button
                                                                    type="submit"
                                                                    className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-sm"
                                                                >
                                                                    <span>{t('Submit')}</span>
                                                                    {
                                                                        spinner ? ( <AiOutlineLoading3Quarters className={'ml-1 animate-spin'} size={16}/> ) : (<FiArrowRight size={16} className={'ml-1'}/>)
                                                                    }
                                                                </button>
                                                            </div>
                                                        {/*</div>*/}
                                                    </footer>
                                                </form>
                                            </ScrollArea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
        </>
    );
}