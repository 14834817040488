import React, {useEffect, useState, Fragment} from "react";
import {useTranslation} from "react-i18next";
import {
    HiOutlineOfficeBuilding,
    HiChevronLeft,
    HiOutlineExclamation,
    HiOutlinePlus,
    HiOutlineChevronDown,
    HiCheckCircle,
    HiStar
} from "react-icons/hi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiArrowRight, FiX } from "react-icons/fi";
import {
    ScrollArea,
    Grid,
    Text,
    LoadingOverlay,
    Select,
    Box,
    Group,
    useMantineTheme
} from "@mantine/core";
import { DataTable } from "mantine-datatable";

import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import {useParams} from "react-router";
// import DesignationList from "./inc/DesignationList";
// import PreviewPublishDesignationList from "./inc/PreviewPublishDesignationList";
import format from "date-fns/format";
import {openConfirmModal} from '@mantine/modals';
import ViewCandidate from "../candidate/modal/ViewCandidate";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import PreviousEventAddCandidateModal from "../time-sheet/modals/PreviousEventAddCandidateModal";
import PreviousEventCandidates from "./inc/PreviousEventCandidates";
import { showNotification } from "@mantine/notifications";


function PreviousEnvent() {
    const theme = useMantineTheme();
    const {token, loggedUserId, roles} = JSON.parse(localStorage.getItem('user'));
    const fullFormHeight = localStorage.getItem('fullFormHeight');
    const {jobId} = useParams();
    const {t, i18n} = useTranslation();

    const navigate = useNavigate();

    const [recall, setRecall] = useState(false);
    // const [candidateList, setCandidateList] = useState([]);
    const [jobDetails, setJobDetails] = useState({});
    const [spinner, setSpinner] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [recallDesignation, setRecallDesignation] = useState(false);
    const [showCandidateListModal, setShowCandidateListModal] = useState(false);
    const [reqDesignation, setReqDesignation] = useState({});
    const [recallCandidates, setRecallCandidates] = useState(false);
    const [clientDesignations, setClientDesignations] = useState([]);



    const validationSchema = Yup.object().shape({
        designationSlug: Yup.string().required(t('PositionIsRequired')),
        rate: Yup.string().required(t('RateIsRequired')),
        inTime: Yup.string().required(t('InTimeIsRequired')),
        outTime: Yup.string().required(t('OutTimeIsRequired')),

    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState, setValue, getValues } = useForm(formOptions);
    const { errors } = formState;

    const getJobDetails = (jobId) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/show-job`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params:{loggedUserId}
        })
            .then(res => {
                setJobDetails(res.data.data);
                getClientDesignations(res.data.data['clientId']);

            });
    }


    const getClientDesignations = (userId) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/client-designation-rate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params:{userId}
        })
            .then(res => {
                setClientDesignations(res.data.data)
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const [requiredDesignation, setRequiredDesignation] = useState([]);
    const getJobRequiredDesignation = (jobId) => {
        setFetching(true);
        
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/job-required-designation`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                setRequiredDesignation(res.data.data);
                setFetching(false);
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const removeDesignation = (reqDesignationId) =>{
        // console.log(reqDesignationId)
        openConfirmModal({
            // title: (<HiOutlineExclamation size={30} className="text-amber-500"/>),
            title: <Text size="sm" fw={700}> {t('AreYouSure')}?</Text>,
            centered: true,
            children: <div className="flex"><HiOutlineExclamation size={16} className="text-amber-500 mr-2"/><Text size="xs" fw={700}> {t('AllCandidatesWillBeRemoved')}</Text></div>,
            labels: {confirm: (t('Yes')), cancel: (t('No'))},
            // confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                axios({
                    method: 'delete',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/previous-job-required-designation`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    params: {id: reqDesignationId}
                })
                    .then(res => {
                        if(res.data.status === 202){
                            setRecallDesignation(prevState => !prevState);
                        }
                        // console.log(res)
                    })
                    .catch(function (error) {
                        console.log(error)
                    });
            }
        }); 
        
    }

    const readyForInvoicePayroll = () => {
        openConfirmModal({
            // title: (<HiOutlineExclamation size={30} className="text-amber-500"/>),
            title: <Text size="sm" fw={700}> {t('AreYouSure')}?</Text>,
            centered: true,
            children: <div className="flex"><HiOutlineExclamation size={16} className="text-amber-500 mr-2"/><Text size="xs" fw={700}> {t('ThisJobTimeSlotsWillBeAvailabeForInvoiceAndPayroll')}</Text></div>,
            labels: {confirm: (t('Yes')), cancel: (t('No'))},
            // confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/ready-invoice-payroll-previous-job`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    data: {jobId}
                })
                    .then(res => {
                        if(res.data['status'] === 200){
                            navigate('/time-sheet');
                        }else{
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Error')),
                                message: (t(res.data.message)),
                                autoClose: 2000,
                                disallowClose: true,
                                color: 'red',
                            });
                        }
                        
                    })
                    .catch(function (error) {
                        console.log(error)
                    });
            }
        }); 
    }


    function formSubmit(payload){
        // previous-job-required-designation
        payload['jobId'] = jobId;
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/previous-job-required-designation`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: payload
        })
            .then(res => {
                setRecallDesignation(prevState => !prevState);
                setValue('designationSlug', null);
                setRate('')
                reset();
                
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    useEffect(()=>{
        getJobDetails(jobId)
    },[])
    useEffect(()=>{
        getJobRequiredDesignation(jobId);
    }, [recallDesignation])


    const [rate, setRate] = useState('');
    const [rateFieldLoader, setRateFieldLoader] = useState(false);
    const findDesignation = (slug) => {
        setRateFieldLoader(true);
        const find = clientDesignations.find(designation => designation['slug'] === slug);
        setRate(Object.values(find).length > 0 && find['rate']);
        setValue('rate', Object.values(find).length > 0 && find['rate']);
        setRateFieldLoader(false);

    }

    return (
        <main className="w-full">
            <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start items-center text-gray-600 mb-3">
                <div className={"flex-1"}>
                    <div
                        className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                        <HiOutlineOfficeBuilding size={24} className={'mt-2 mr-2 text-indigo-300'}/>
                        <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                            {jobDetails.title}
                        </span>
                    </div>
                </div>
            </div>
            <div className="w-full px-4 flex flex-row flex-wrap relative">
                <LoadingOverlay visible={Object.values(jobDetails).length === 0} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'sm' }} overlayColor="bg-indigo-100" />
                <ScrollArea scrollbarSize={4} h={fullFormHeight} className="w-full">
                    <div className="w-full">
                        <div className="w-full bg-indigo-100 p-2 text-gray-900 rounded">
                            <Grid className="w-full">
                                <Grid.Col span='auto'>
                                    <div className='flex flex-start w-full'>
                                        <Text size="sm" weight={400} fw={700} className='w-2/5'>{t('Client')}</Text>
                                        <Text size="sm" weight={400}>: {Object.values(jobDetails).length > 0 && jobDetails['clientName']}</Text>
                                    </div>
                                    <div className='flex flex-start w-full'>
                                        <Text size="sm" weight={400} fw={700} className='w-2/5'>{t('Place')}</Text>
                                        <Text size="sm" weight={400}>: {Object.values(jobDetails).length > 0 && jobDetails['streetAddress']}</Text>
                                    </div>
                                </Grid.Col>
                                
                                <Grid.Col span='auto' offset={7}>
                                    <div className='flex flex-start w-full'>
                                        <Text size="sm" weight={400} fw={700} className='w-1/4'>{t('Date')}</Text>
                                        <Text size="sm" weight={400}>: {Object.values(jobDetails).length > 0 && jobDetails['startTime'] && format(new Date(jobDetails['startTime'].date), 'MM/dd/Y')}</Text>
                                    </div>
                                    <div className='flex flex-start w-full'>
                                        <Text size="sm" weight={400} fw={700} className='w-1/4'>{t('Job#')}</Text>
                                        <Text size="sm" weight={400}>: {Object.values(jobDetails).length > 0 && jobDetails['jobHash']}</Text>
                                    </div>
                                </Grid.Col>
                            </Grid>
                        </div>
                        <form onSubmit={handleSubmit(formSubmit)} className="w-full">
                            <Grid className="w-full mx-auto" justify="center" align="flex-end">
                                <Grid.Col span="auto" pl={0}>
                                    <div className="w-full items-center">
                                        <label htmlFor="designation" className="form-input-sm-label-required">{t('Designation')}</label>
                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                            {
                                                errors.designationSlug?.type === 'required' &&
                                                <div className="form-input-sm-error">
                                                    <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.designationSlug?.message}
                                                </div>
                                            }
                                            <Select
                                                placeholder={t('Designation')}
                                                searchable
                                                nothingFound="No options"
                                                maxDropdownHeight={280}
                                                data={clientDesignations.map((item, index) => ({ value: item.slug, label: item.name }))}
                                                {...register("designationSlug")}
                                                id="designation"
                                                onChange={ e => {setValue('designationSlug', e); findDesignation(e)}}
                                                size="xs"
                                                value={getValues('designationSlug')}
                                            />
                                            
                                        </div>
                                    </div>
                                </Grid.Col>
                                <Grid.Col span="auto">
                                    <div className="w-full items-center">
                                        <label htmlFor="rate" className="form-input-sm-label-required">{t('Rate')}</label>
                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                            {
                                                errors.rate?.type === 'required' &&
                                                <div className="form-input-sm-error">
                                                    <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.rate?.message}
                                                </div>
                                            }
                                            <div className="relative">
                                                <input
                                                    type="number"
                                                    className={`form-input-sm-control disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500`}
                                                    autoComplete="off"
                                                    placeholder={t('Rate')} 
                                                    {...register("rate")}
                                                    id="rate"
                                                    defaultValue={rate}

                                                />
                                                <LoadingOverlay visible={rateFieldLoader} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'xs' }} overlayColor="bg-indigo-100" />
                                                
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </Grid.Col>
                                <Grid.Col span="auto">
                                    <div className="w-full items-center">
                                        <label htmlFor="inTime" className="form-input-sm-label-required">{t('InTime')}</label>
                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                            {
                                                errors.inTime?.type === 'required' &&
                                                <div className="form-input-sm-error">
                                                    <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.inTime?.message}
                                                </div>
                                            }
                                            <input
                                                type="time"
                                                className={`form-input-sm-control disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500`}
                                                {...register("inTime")}
                                                id="inTime"
                                            />
                                            
                                        </div>
                                    </div>
                                </Grid.Col>
                                <Grid.Col span="auto">
                                    <div className="w-full items-center">
                                        <label htmlFor="outTime" className="form-input-sm-label-required">{t('OutTime')}</label>
                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                            {
                                                errors.outTime?.type === 'required' &&
                                                <div className="form-input-sm-error">
                                                    <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.outTime?.message}
                                                </div>
                                            }
                                            <input
                                                type="time"
                                                className={`form-input-sm-control disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500`}
                                                {...register("outTime")}
                                                id="outTime"
                                            />
                                            
                                        </div>
                                    </div>
                                </Grid.Col>
                                <Grid.Col span={1} align="right" px={0}>
                                    <button
                                        type="submit"
                                        className="px-2 py-1.5 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-sm"
                                    >
                                        { spinner ? (<AiOutlineLoading3Quarters className={'mr-1 animate-spin'} size={12} />) : (<HiOutlinePlus size={12} className={'mr-1'} />) }
                                        <span>{t('Add')}</span>
                                    </button>
                                </Grid.Col>
                            </Grid>
                        </form>
                    </div>
                    <Box>
                        <DataTable
                            records={requiredDesignation}
                            striped
                            noRecordsText=""
                            noRecordsIcon={"..."}
                            rowStyle={() => ({ backgroundColor: 'rgb(224 231 255)' })}
                            // rowClassName={() => ({backgroundColor: "#FA5639",})}
                            columns={[
                                {
                                    accessor: 'designationName',
                                    title: (t('Name')),
                                    width: 200,
                                    render: ({designationName}) => (
                                        <div className="flex items-center">
                                            <HiOutlineChevronDown size={16} className="mr-1 text-indigo-700"/>{designationName}
                                        </div>
                                    )
                                },
                                {
                                    accessor: 'rate',
                                    title: (t('Rate')),
                                    render: ({rate}) => '$' + rate

                                },
                                {
                                    accessor: 'inTime',
                                    title: (t('InTime')),

                                },
                                {
                                    accessor: 'outTime',
                                    title: (t('OutTime')),

                                },
                                {
                                    accessor: 'actions',
                                    title: (t('Action')),
                                    textAlignment: 'right',
                                    render: (item) => (
                                        <Group spacing={2} position="right" noWrap>
                                            <button
                                                type="button"
                                                className="px-2 py-1 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-xs"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setReqDesignation(item);
                                                    setShowCandidateListModal(true)
                                                }}
                                            >
                                                <HiOutlinePlus size={12} className={'mr-1'} /> <span>{t('Candidate')}</span>
                                            </button>
                                            <button
                                                type="button"
                                                className="px-1.5 py-1.5 text-white inline-flex focus:ring-red-700 transition duration-150 ease-in-out hover:bg-red-600 bg-red-700 items-center border rounded text-xs"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    removeDesignation(item['id'])
                                                }}
                                            >
                                                <FiX size={12}/>
                                                {/* <span>{t('Remove')}</span> */}
                                            </button>
                                        </Group>
                                    ),
                                },

                            ]}
                            rowExpansion={{ content: ({record}) => <PreviousEventCandidates key={record['id']} reqDesignationId={record['id']} recallCandidates={recallCandidates} setRecallCandidates={setRecallCandidates}/> }}
                            totalRecords={0}
                            fetching={fetching}
                            loaderSize="sm"
                            loaderColor="indigo"
                            loaderBackgroundBlur={2}
                        />
                    </Box>
                </ScrollArea>
                <footer className="relative mt-1 w-full flex flext-row justify-between bg-indigo-100 px-2 items-center">
                    <Grid className="w-full" align="center">
                        <Grid.Col span={6}>
                            <Text fz="xs" fw={700} color="blue" className="text-red-200"><span className="flex flex-row items-center"><HiStar color="red" />{t('ToReadyInvoiceAndPayrollFinishTheProcess')}</span></Text>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <button
                                type="button"
                                className=" px-8 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-md"
                                onClick={readyForInvoicePayroll}
                            >
                                <HiCheckCircle className={'mr-1'} /> <span>{t('Finish')}</span>
                            </button>
                        </Grid.Col>
                    </Grid>
                    
                </footer>
                
                
            </div>
            {/* { showCandidateProfile && <ViewCandidate setShowModal={setShowCandidateProfile} candidateid={candidateId}/> } */}
            { Object.values(reqDesignation).length > 0 && showCandidateListModal && <PreviousEventAddCandidateModal setShowModal={setShowCandidateListModal} reqDesignation={reqDesignation} setRecallCandidates={setRecallCandidates}/> }

        </main>

    );
}

export default PreviousEnvent;
