import {Document, Page, PDFViewer, Text, View, Image} from "@react-pdf/renderer";
import styles from "../../../pdf-styles";
import {Grid, ScrollArea, Select, TextInput, Textarea} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {HiChevronLeft, HiOutlineExclamation, HiOutlineOfficeBuilding} from "react-icons/hi";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import axios from "axios";
import {FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {useForm} from "react-hook-form";
import {showNotification} from "@mantine/notifications";
import {DatePicker} from "@mantine/dates";
import dayjs from "dayjs";
import {useNavigate} from "react-router";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";


function InvoicePaymentCreate() {

    const {domainId, loggedUserId ,roles, token, name} = JSON.parse(localStorage.getItem('user'));

    const {invoiceId} = useParams();
    const {t, i18n} = useTranslation();
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const fullFormHeight = localStorage.getItem('fullFormHeight');
    const navigate = useNavigate();
    const [taxData, setTaxData] = useState(0.00);
    const [paymentTypeData, setPaymentTypeData] = useState('');

    let paymentTypeDropdownArray =
        [
            {'label': 'Cash', 'value': 'CASH'},
            {'label': 'Cheque', 'value': 'CHEQUE'},
            {'label': 'Transfer', 'value': 'TRANSFER'},
        ];
    let paymentTypeDropdown = [];
    if (paymentTypeDropdownArray) {
        paymentTypeDropdown = paymentTypeDropdownArray.map((type, index) => {
                return ({
                    'label': type.label, 'value': type.value
                })
            })
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/invoice/${invoiceId}/show`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                if (res.status === 200){
                    setInvoiceDetails(res.data.data)
                    setValue('amount', res.data.data.dueAmount?res.data.data.dueAmount:0.00)
                    setValue('paymentDate',format(new Date(), 'yyyy-MM-dd'))
                    /*setValue('invoiceDate',res.data.data.invoiceDate ? format(new Date(res.data.data.invoiceDate.date), 'yyyy-MM-dd') : '')
                    setValue('dueDate',res.data.data.dueDate ? format(new Date(res.data.data.dueDate.date), 'yyyy-MM-dd') : '')
                    setValue('termsAndConditions',res.data.data.termsAndConditions ? res.data.data.termsAndConditions : '')
                    setValue('remarks',res.data.data.remarks ? res.data.data.remarks : '')
                    setValue('amount',res.data.data.taxRate ? res.data.data.taxRate : '')
                    setTaxData(res.data.data.taxRate?res.data.data.taxRate:0.00)*/
                }
            });
    }, [])

    const PaymentTypeDataHandel = (e) => {
        setPaymentTypeData(e);
        setValue('paymentType',e)
        if (e == '' || e == null){
            setError("paymentType", Yup.string().required(t("ChoosePaymentType")).nullable());
        }else {
            setError("paymentType", null);
        }
    }

    const TaxDataHandel = (e) => {
        setTaxData(e.target.value);
    }

    //Form validation
    const validationSchema = Yup.object().shape({
        // amount: Yup.string().required(t('EnterAmount')),
        // amount: Yup.number().moreThan(0).positive(),
        amount: Yup.number().required("EnterAmount").positive().min(1, "Min is 0").max(invoiceDetails.dueAmount, "MaxAmountIs "+invoiceDetails.dueAmount),
        paymentDate: Yup.string().required(t("EnterPaymentDate")),
        paymentType: Yup.string().required(t("ChoosePaymentMode")),
    });

    let formOptions = {resolver: yupResolver(validationSchema)};
    const {register, handleSubmit, reset, formState, setValue ,setError} = useForm(formOptions);
    const {errors} = formState;


    //Form Data Submit
    function formSubmit(data) {
        data['paymentType']=paymentTypeData;
        data['created_by']=loggedUserId;
        data['created_by_name']=name;
       if(data){
           axios({
               method: 'post',
               url: `${process.env.REACT_APP_API_GATEWAY_URL}/invoice/${invoiceId}/payment/create`,
               headers: {
                   "Accept": `application/json`,
                   "Content-Type": `application/json`,
                   "Access-Control-Allow-Origin": '*',
                   "Authorization": `Bearer ${token}`
               },
               data: data
           })
               .then(res => {
                   console.log(res.data.status)
                   if (res.data.status === 202) {
                       showNotification({
                           id: 'load-data',
                           loading: true,
                           title: (t('UpdatedSuccessfully')),
                           message: (t('DataLoad')),
                           autoClose: 500,
                           disallowClose: true,
                           color: 'green',
                       });
                       navigate('/invoice');
                   }
                   if (res.data.status === 406) {
                       showNotification({
                           id: 'load-data',
                           loading: true,
                           title: (t('Error')),
                           message: (t('AmountIsNotSufficient')),
                           autoClose: 500,
                           disallowClose: true,
                           color: 'red',
                       });
                       // navigate('/invoice');
                   }
               })
               .catch(function (error) {
                   console.log(error)
               })
       }

    }

    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Invoice')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>
                                    {t('InvoiceInformation')}
                                </sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <Link to='/invoice'
                                      className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={12} className={'mr-1'}/>{t('Back')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border">
                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                        <ScrollArea style={{ height: fullFormHeight }} scrollbarSize={4}>
                                                            <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full bg-gray-200">
                                                                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                                    <div className="md:col-span-1">
                                                                        <div className="px-4 sm:px-0 ml-2">
                                                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("PaymentInformation")}</h3>
                                                                            <p className="mt-1 text-sm text-gray-600">
                                                                                {t('PaymentDetails')}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-5 md:col-span-4 md:mt-0">
                                                                        <div
                                                                            className="shadow sm:overflow-hidden sm:rounded-md">
                                                                            <div className="bg-gray-50 px-4 py-5 sm:p-6">
                                                                                <div className="mb-3 w-full items-center">
                                                                                    <label htmlFor="CompanyName"
                                                                                           className="form-input-sm-label">{t('ClientName')}</label>
                                                                                    <div
                                                                                        className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                        <p className="form-input-sm-control border-gray-300">
                                                                                            {invoiceDetails.clientName}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex">
                                                                                    <div className="mb-3 w-1/2 items-center">
                                                                                        <label htmlFor="CompanyName"
                                                                                               className="form-input-sm-label">{t('Invoice No')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                            <p className="form-input-sm-control border-gray-300">
                                                                                                {invoiceDetails.invoiceId}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 w-1/2 items-center">
                                                                                        <label htmlFor="CompanyName"
                                                                                               className="form-input-sm-label">{t('Invoice Amount')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                            <p className="form-input-sm-control border-gray-300">
                                                                                                {invoiceDetails.invoiceTotalAmount}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                    <div className="mb-3 w-1/2 items-center">
                                                                                        <label htmlFor="paymentDate"
                                                                                               className="form-input-sm-label">{t('AmountReceived')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                            {errors.amount?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.amount?.message}
                                                                                            </div>}
                                                                                            {errors.amount?.type === 'typeError' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {t('EnterValidAmount')}
                                                                                            </div>}
                                                                                            {errors.amount?.type === 'min' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {t('EnterValidAmount')}
                                                                                            </div>}
                                                                                            {errors.amount?.type === 'max' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.amount?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="relative items-stretch focus-within:z-10">
                                                                                            <input
                                                                                                {...register("amount")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                                                                id="amount"
                                                                                                placeholder={t("0.00")}
                                                                                                defaultValue={invoiceDetails.dueAmount}
                                                                                                onKeyPress={(event) => {
                                                                                                    if (/[^0-9\.]/g.test(event.key)) {
                                                                                                        event.preventDefault();
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="mb-3 w-1/2 items-center">
                                                                                        <label htmlFor="dueDate"
                                                                                               className="form-input-sm-label">{t('BankCharge')} (if any)</label>
                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            <input
                                                                                                {...register("bankCharge")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                                                                id="bankCharge"
                                                                                                placeholder={t("0.00")}
                                                                                                defaultValue={0.00}
                                                                                                onKeyPress={(event) => {
                                                                                                    if (/[^0-9\.]/g.test(event.key)) {
                                                                                                        event.preventDefault();
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>

                                                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                    <div className="mb-3 w-1/2 items-center">
                                                                                        <label htmlFor="paymentDate" className="form-input-sm-label">{t('PaymentDate')}</label>

                                                                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                            {errors.paymentDate?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.paymentDate?.message}
                                                                                            </div>}
                                                                                            <div className="relative items-stretch focus-within:z-10">
                                                                                            <input
                                                                                                {...register("paymentDate")}
                                                                                                type="date"
                                                                                                className={`form-input-sm-control ${errors.toDate ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="paymentDate"
                                                                                                id="paymentDate"
                                                                                                placeholder={t("paymentDate")}
                                                                                                defaultValue={format(new Date(), 'yyyy-MM-dd')}
                                                                                            />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="mb-3 w-1/2 items-center">
                                                                                        <label htmlFor="dueDate" className="form-input-sm-label">{t('PaymentMode')}</label>
                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.paymentType?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.paymentType?.message}
                                                                                            </div>}
                                                                                            <div className="relative items-stretch focus-within:z-10">
                                                                                            <Select
                                                                                                {...register("paymentType")}
                                                                                                placeholder={t('ChoosePaymentType')}
                                                                                                searchable clearable
                                                                                                allowDeselect
                                                                                                nothingFound="No options"
                                                                                                data={paymentTypeDropdown}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onChange={PaymentTypeDataHandel}
                                                                                                value={paymentTypeData}
                                                                                            />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>


                                                                                <Grid columns={24}>

                                                                                    <Grid.Col span={24}>
                                                                                        <div className="w-full">
                                                                                            <div className="mb-3 w-full items-center">
                                                                                                <TextInput
                                                                                                    {...register("reference")}
                                                                                                    placeholder={t("Reference")}
                                                                                                    label={t("Reference")}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3 w-full items-center">
                                                                                                <Textarea
                                                                                                    {...register("remarks")}
                                                                                                    placeholder={t("Comments")}
                                                                                                    label={t("Comments")}
                                                                                                    autosize
                                                                                                    minRows={2}
                                                                                                />
                                                                                            </div>

                                                                                        </div>
                                                                                    </Grid.Col>
                                                                                </Grid>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </ScrollArea>

                                                        <footer className="relative mt-1">
                                                            <div className="mr-3">
                                                                <div className="text-right pt-0.5 mb-0.5">
                                                                    <button type='reset'
                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                    ><FiX size={16} className={'mr-2'}/>
                                                                        <span>{t('Cancel')}</span>
                                                                    </button>
                                                                    <button type='submit'
                                                                            className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                        <span>{t('Save')}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </footer>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </main>

        </>
    )
}

export default InvoicePaymentCreate