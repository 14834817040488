import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format, lastDayOfMonth } from 'date-fns';
import { DataTable } from "mantine-datatable";
import { Box, Group, Text, Select, ScrollArea, TextInput, Grid, Drawer, useMantineTheme, NumberInput } from "@mantine/core";
import axios from "axios";
import {
    HiOutlineClipboardList,
    HiOutlineCalendar
} from "react-icons/hi";
import { Calendar, DatePicker, RangeCalendar, DatePickerInput, DateRangePicker } from '@mantine/dates';
import dayjs from 'dayjs';

import CompleteListView from './CompleteListView';
import CompleteWeekView from './CompleteWeekView';


const PER_PAGE = 20;
function CompleteView() {
    const { t, i18n } = useTranslation();
    const { token, roles, loggedUserId, domainId } = JSON.parse(localStorage.getItem('user'));

    const theme = useMantineTheme();


    const fullTableHeight = localStorage.getItem('fullTableHeight');
    const [page, setPage] = useState(1);
    const [opened, setOpened] = useState(false);
    const drawerHeight = localStorage.getItem('drawerHeight');

    const [data, setData] = useState([]);
    const [jobId, setJobId] = useState(null);
    const [clientId, setClientId] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [sortStatus, setSortStatus] = useState({ columnAccessor: 'jobTitle', direction: 'asc' });
    const [layoutView, setLayoutView] = useState('');
    const [weekNumber, setWeekNumber] = useState(null);
    const [weeklyTimeSheetData, setWeeklyTimeSheetData] = useState(null);
    const [showWeekCalendar, setShowWeekCalendar] = useState(false);
    const [view, setView] = useState('list');


    return (
        <Fragment>
            { view === 'list' && <CompleteListView setView={setView} /> }
                
            { view === 'week' && <CompleteWeekView setView={setView} /> }
        </Fragment>
    );

}
export default CompleteView