import React, { Fragment, useEffect, useState } from "react";
import {
    HiOutlineFilter,
    HiOutlineOfficeBuilding,
    HiOutlinePlus,
    HiOutlineSearch,
    HiPencil,
    HiSearch,
    HiOutlineExclamation
} from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { format, lastDayOfMonth } from 'date-fns';
import { DataTable } from "mantine-datatable";
import { Box, Group, Text, Select, ScrollArea, TextInput, Grid, Drawer, useMantineTheme, NumberInput, Tooltip } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import AddTimeSheetModal from "../../../modals/AddTimeSheetModal";
import ApproveModal from "../../../modals/ApproveModal";
import NoShowModal from "../../../modals/NoShowModal";
import DisputeModal from "../../../../crm/modals/DisputeModal";





const PER_PAGE = 20;
function RunningView() {
    const { t, i18n } = useTranslation();
    const { token, roles, loggedUserId, domainId } = JSON.parse(localStorage.getItem('user'));
    const [showDisputeModal, setShowDisputeModal] = useState(false);
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [showNoShowModal, setShowNoShowModal] = useState(false);
    const [forVerifyData, setForVerifyData] = useState({});

    const theme = useMantineTheme();

    const [timeSheet, setTimeSheet] = useState({});

    const fullTableHeight = localStorage.getItem('fullTableHeight');
    const [page, setPage] = useState(1);
    const [opened, setOpened] = useState(false);
    const drawerHeight = localStorage.getItem('drawerHeight');

    const [data, setData] = useState([]);
    const [jobId, setJobId] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [sortStatus, setSortStatus] = useState({ columnAccessor: 'jobTitle', direction: 'asc' });
    const [refreshList, setRefreshList] = useState(false);


    const [spinner, setSpinner] = useState(false);
    //Form validation
    const validationSchema = Yup.object().shape({
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);


    const [jobList, setJobList] = useState([]);
    const getJobList = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-list-for-time-sheet`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                loggedUserId,
                roles,
                flag: 'running',
            }
        })
            .then(res => {
                setJobList(res.data.data);
            })
            .catch(function (err) {
                console.log(err);

            });
    }

    const timeSheetparams = {
        roles,
        loggedUserId,
        jobId,
        per_page: PER_PAGE,
        page: page,
        flag: 'running'
    }

    function jobFilterSubmit(id) {
        setJobId(id)
        timeSheetparams['jobId'] = id;


        // console.log(timeSheetparams);
        getTimeSheet(timeSheetparams);
    }

    const getTimeSheet = (params) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-time-sheet`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: params
        })
            .then(res => {
                setData(res.data);
                setFetching(false);
            })
            .catch(function (err) {
                console.log(err);

            });
    }


    const clientCheckInCheckOut = (payload) => {
        // job-client-check-in-check-out
        payload['loggedUserId'] = loggedUserId;
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-client-check-in-check-out`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: payload
        })
            .then(res => {
                // console.log(parameters)
                if (202 === res.data.status) {
                    setFetching(true);
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Success')),
                        message: (t(res.data.message)),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'green',
                    });
                    // getTimeSheet(timeSheetparams);
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: (t(res.data.message)),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });
                }
            })
            .catch(function (err) {
                console.log(err);
            });
    }

    const verifyCheckInCheckOut = ({ timeSheet, verifiedBy }) => {

        openConfirmModal({
            title: (<HiOutlineExclamation size={30} className="text-amber-500"/>),
            centered: true,
            children: (
                <>
                    <Text size="xs">
                        {t('FinalApprovalWillProcessInvoice&PayrollForThisTimeSlotThisCannotBeRevert')}
                    </Text>
                    <Text size="sm" fw={700}>
                        {t('AreYouSure')}?
                    </Text>
                </>
            ),
            labels: {confirm: (t('Yes')), cancel: (t('No'))},
            // confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                setFetching(true);

                const payload = {
                    loggedUserId,
                    rowId: timeSheet['id'],
                    verifiedBy,
                    candidate: { name: timeSheet['name'], email: timeSheet['email'], phoneNumber: timeSheet['phoneNumber'], userId: timeSheet['userId'] },
                };

                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-time-sheet-verify-check-in-check-out`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    data: payload
                })
                    .then(res => {
                        if (202 === res.data.status) {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Success')),
                                message: (t(res.data.message)),
                                autoClose: 2000,
                                disallowClose: true,
                                color: 'green',
                            });
                            getTimeSheet(timeSheetparams);
                        } else {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Error')),
                                message: (t(res.data.message)),
                                autoClose: 2000,
                                disallowClose: true,
                                color: 'red',
                            });
                        }
                        setFetching(false);
                    })
                    .catch(function (err) {
                        console.log(err);

                    });
            }
        }); 
        
    }

    const disputeModal = (timeSheet) => {
        setTimeSheet(timeSheet);
        setShowDisputeModal(prevState => !prevState);
    }

    const noShowModal = (timeSheet) => {
        setTimeSheet(timeSheet);
        setShowNoShowModal(prevState => !prevState);
    }

    useEffect(() => {
        getJobList();
        getTimeSheet(timeSheetparams);
    }, []);


    useEffect(() => {
        getTimeSheet(timeSheetparams);
    }, [fetching, refreshList]);

    useEffect(() => {
        setFetching(true);
    }, [sortStatus]);

    return (
        <Fragment>
            <div className="my-2 p-1 bg-gray-400 rounded">
                <Grid align="center">
                    <Grid.Col span="auto">
                        <Select
                            placeholder={t('SelectJob')}
                            data={jobList.map((job, index) => ({ value: job['id'], label: job['title'] }))}
                            onChange={(e) => jobFilterSubmit(e)}
                            value={jobId}
                            nothingFound="No Jobs"
                            maxDropdownHeight={280}
                            searchable
                        />
                    </Grid.Col>
                </Grid>
            </div>

            <Box sx={{ height: fullTableHeight }}>
                <DataTable
                    noRecordsText={t('NoRecords') + ' !'}
                    withBorder
                    records={data.data}
                    striped
                    styles={(theme) => ({
                        root: {
                            border: '1px solid #e0e7ff',
                            borderRadius: 2,
                        },
                        header: {
                            background: '#e0e7ff',
                            backgroundColor: "#e0e7ff",
                            color: theme.colors.indigo[6],
                        },
                        pagination: {
                            color: theme.colors.gray[3],
                            paddingTop: "5px",
                            paddingBottom: "5px"
                        },
                        item: {
                            '&[data-active]': {
                                backgroundImage: theme.fn.gradient({ from: 'red', to: 'yellow' }),
                            },
                        },
                    })}
                    columns={[
                        {
                            accessor: '',
                            title: (t('No.')),
                            width: 45,
                            render: (item) => (data.data.indexOf(item) + 1) + PER_PAGE * (page - 1)
                        },
                        {
                            accessor: 'jobTitle',
                            title: (t('JobTitle')),
                            // render: ({jobTitle}) => <Link to={`/job-post`} className={`text-red`}>{jobTitle}</Link>,

                        },
                        {
                            accessor: 'name',
                            title: (t('Name')),
                            // sortable: true
                            // render: ({jobTitle}) => <Link to={`/job-post`} className={`text-red`}>{jobTitle}</Link>,

                        },
                        {
                            accessor: 'designationName',
                            title: (t('Designation')),
                            // sortable: true
                        },
                        {
                            accessor: 'workingDate',
                            title: (t('Date')),
                            // render: ({ workingDate }) => format(new Date(workingDate.date), 'MM/dd/yyyy'),
                        },
                        {
                            accessor: 'inTime',
                            title: (t('CheckInTime')),
                        },
                        {
                            accessor: 'checkInTime',
                            title: (t('InTime')),
                            // width: 150,
                            render: (timeSheet) => {
                                return (
                                    timeSheet['checkInTime'] ? (
                                        timeSheet['checkInTime']
                                    ) : (

                                        <input
                                            disabled={ (new Date() < new Date(timeSheet['workingDateOutTime'].date)) || timeSheet['noShow'] }
                                            onChange={(e) => clientCheckInCheckOut({ time: e.target.value, rowId: timeSheet['id'], flag: "check-in", addedBy: "client" })}
                                            type="time"
                                            className={`form-input-sm-control disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500`}
                                        // value={candidate['checkInTime'] ? format(new Date(candidate['checkInTime'].date), "yyyy-MM-dd HH:mm") : ''}
                                        />
                                    )

                                )
                            },
                        },
                        {
                            accessor: 'checkOutTime',
                            title: (t('OutTime')),
                            // width: 150,
                            render: (timeSheet) => {
                                return (
                                    timeSheet['checkOutTime'] ? (
                                        timeSheet['checkOutTime']
                                    ) : (
                                        <input
                                            disabled={ (new Date() < new Date(timeSheet['workingDateOutTime'].date)) || timeSheet['noShow'] }
                                            onChange={(e) => clientCheckInCheckOut({ time: e.target.value, rowId: timeSheet['id'], flag: "check-out", addedBy: "client" })}
                                            type="time"
                                            className={`form-input-sm-control disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500`}
                                        // value={candidate['checkOutTime'] ? format(new Date(candidate['checkOutTime'].date), "yyyy-MM-dd HH:mm") : ''}
                                        />
                                    )
                                )
                            },

                        },
                        {
                            accessor: 'breakDuration',
                            title: (t('BreakDuration')),
                            // width: 120,
                            render: (timeSheet) => {
                                return (
                                    timeSheet['finalApprove'] ? (
                                        timeSheet['breakDuration'] >= 0 && timeSheet['breakDuration'] + ' min'
                                    ) : (
                                        <NumberInput
                                            disabled={(new Date() < new Date(timeSheet['workingDateOutTime'].date)) || timeSheet['noShow']}
                                            onBlur={(e) => e.target.value && clientCheckInCheckOut({ time: Number(e.target.value), rowId: timeSheet['id'], flag: "break-duration", addedBy: "client" })}
                                            type="number"
                                            placeholder="Minute"
                                            defaultValue={timeSheet['breakDuration'] > 0 && timeSheet['breakDuration']}
                                            min={0}
                                        />
                                    )

                                )
                            },
                        },
                        // {
                        //     accessor: 'totalHour',
                        //     title: (t('Hour')),
                        //     // width: 100,
                        //     render: ({totalHour}) => totalHour ? totalHour : 0
                        // },
                        {
                            accessor: 'disputeNo',
                            title: (t('disputeNo')),
                            render: (timeSheet) => timeSheet['disputeNo'] && (
                                <Tooltip
                                    multiline
                                    width={500}
                                    withArrow
                                    position="left"
                                    color="indigo"
                                    className="text-left"
                                    transitionProps={{ duration: 200 }}
                                    disabled={!!!timeSheet['disputeDetails']}
                                    label={ timeSheet['disputeDetails'] && timeSheet['disputeDetails'] }
                                    >
                                        <span className="w-20 justify-center inline-flex items-center ml-1 rounded border-none bg-yellow-400 py-1 px-2 text-xs font-normal text-white shadow-sm focus:outline-none focus:ring-0">{timeSheet['disputeNo']}</span>
                                </Tooltip>
                            )
                        },

                        /*{
                            accessor: 'rate',
                            title: (t('Rate')),
                            render: ({rate}) => rate ? ("$" + rate) : 0,

                        },
                        {
                            accessor: 'totalAmount',
                            title: (t('Amount')),
                            render: ({totalAmount}) => totalAmount ? ("$" + totalAmount) : 0,

                        },*/
                        {
                            accessor: 'actions',
                            title: <Text mr="xs">{t('Action')}</Text>,
                            // width: 160,
                            render: (timeSheet) => (
                                <Group spacing={1}>
                                    {
                                        ('candidate' === timeSheet['checkInProcessMode']) && ('candidate' === timeSheet['checkOutProcessMode']) && !timeSheet['finalApprove'] && !timeSheet['dispute'] && timeSheet['checkInTime'] && timeSheet['checkOutTime'] && (new Date() > new Date(timeSheet['workingDateOutTime'].date)) && (
                                            <Tooltip
                                                multiline
                                                width={500}
                                                withArrow
                                                position="top"
                                                color="indigo"
                                                className="text-left"
                                                transitionProps={{ duration: 200 }}
                                                disabled={!!!timeSheet['disputeRemark']}
                                                label={ timeSheet['disputeRemark'] && timeSheet['disputeRemark'] }
                                                >
                                                    <span>
                                                        <button
                                                            type="button"
                                                            disabled={ !timeSheet['checkInTime'] || !timeSheet['checkOutTime'] }
                                                            onClick={() => verifyCheckInCheckOut({ timeSheet, verifiedBy: "client" })}
                                                            className="disabled:cursor-not-allowed disabled:bg-gray-400 disabled:border-gray-400 disabled:hover:bg-gray-400 w-20 justify-center inline-flex items-center ml-1 rounded border-none bg-indigo-900 py-1 px-2 text-xs font-normal text-white shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-0"
                                                        >
                                                            {t('Approve')}
                                                        </button>
                                                    </span>
                                            </Tooltip>
                                        )

                                    }

                                    {
                                        timeSheet['finalApprove'] && (new Date() > new Date(timeSheet['workingDateOutTime'].date)) && !timeSheet['dispute'] && (
                                            
                                            <Tooltip
                                                multiline
                                                width={220}
                                                withArrow
                                                position="left"
                                                color="indigo"
                                                className="text-left"
                                                transitionProps={{ duration: 200 }}
                                                disabled={!!!timeSheet['disputeRemark']}
                                                label={ timeSheet['disputeRemark'] && timeSheet['disputeRemark'] }
                                                >
                                                    <span className="w-20 justify-center inline-flex items-center ml-1 rounded border-none bg-green-700 py-1 px-2 text-xs font-normal text-white shadow-sm focus:outline-none focus:ring-0">
                                                        {t('Approved')}
                                                    </span>
                                            </Tooltip>
                                            
                                            
                                        )

                                    }

                                    {
                                        !timeSheet['finalApprove'] && !timeSheet['dispute'] && !timeSheet['checkInTime'] && !timeSheet['checkOutTime'] && !timeSheet['noShow'] && (new Date() < new Date(timeSheet['workingDateOutTime'].date)) && (
                                            <button
                                                onClick={() => noShowModal(timeSheet)}
                                                className="w-20 justify-center inline-flex items-center ml-1 rounded border-none bg-indigo-600 py-1 px-2 text-xs font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                            >
                                                {t('NoShow')}
                                            </button>
                                        )
                                    }
                                    {
                                        !timeSheet['finalApprove'] && !timeSheet['dispute'] && !timeSheet['checkInTime'] && !timeSheet['checkOutTime'] && !timeSheet['noShow'] && (new Date() > new Date(timeSheet['workingDateOutTime'].date)) && (
                                            <button
                                                onClick={() => noShowModal(timeSheet)}
                                                className="w-20 justify-center inline-flex items-center ml-1 rounded border-none bg-indigo-600 py-1 px-2 text-xs font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                            >
                                                {t('NoShow')}
                                            </button>
                                        )
                                    }

                                    {
                                        !timeSheet['finalApprove'] && timeSheet['noShow'] && (
                                            <Tooltip
                                                multiline
                                                width={220}
                                                withArrow
                                                position="left"
                                                transitionProps={{ duration: 200 }}
                                                label={timeSheet['noShowRemark']}
                                                >
                                                    <span
                                                        className="w-20 justify-center text-gray-600 bg-gray-300 inline-flex items-center ml-1 rounded border-none py-1 px-2 text-xs font-normal text-white shadow-sm focus:outline-none focus:ring-0"
                                                    >
                                                        {t('NoShow')}
                                                    </span>
                                            </Tooltip>
                                            
                                        )
                                    }

                                    {/* {
                                        !timeSheet['finalApprove'] && !timeSheet['dispute'] && timeSheet['checkInTime'] && (new Date() > new Date(timeSheet['workingDateOutTime'].date)) && (
                                            <button
                                                onClick={() => disputeModal(timeSheet)}
                                                className="inline-flex items-center ml-1 rounded border-none bg-amber-600 py-1 px-2 text-xs font-normal text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-0"
                                            >
                                                {t('Dispute')}
                                            </button>
                                        )
                                    } */}
                                    {
                                        !timeSheet['disputeNo'] && !timeSheet['finalApprove'] && !timeSheet['dispute'] && (timeSheet['checkInTime'] || timeSheet['checkOutTime']) && (new Date() > new Date(timeSheet['workingDateOutTime'].date)) && (
                                            <button
                                                onClick={() => disputeModal(timeSheet)}
                                                className="w-20 justify-center inline-flex items-center ml-1 rounded border-none bg-amber-600 py-1 px-2 text-xs font-normal text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-0"
                                            >
                                                {t('Dispute')}
                                            </button>
                                        )
                                    }

                                    {
                                        !timeSheet['finalApprove'] && timeSheet['dispute'] && (new Date() > new Date(timeSheet['workingDateOutTime'].date)) && (
                                            <span
                                                className="w-20 justify-center inline-flex items-center ml-1 rounded border-none bg-amber-900 py-1 px-2 text-xs font-normal text-white shadow-sm focus:outline-none focus:ring-0"
                                            >
                                                {t('Disputed')}
                                            </span>
                                        )
                                    }



                                </Group>
                            ),
                        },

                    ]}
                    totalRecords={data.totalRecords}
                    recordsPerPage={PER_PAGE}
                    page={page}
                    onPageChange={(p) => {
                        setPage(p)
                        setFetching(true)
                    }}
                    fetching={fetching}
                    loaderSize="sm"
                    loaderColor="indigo"
                    loaderBackgroundBlur={2}
                // sortStatus={sortStatus}
                // onSortStatusChange={setSortStatus}
                />
            </Box>
            {showNoShowModal && <NoShowModal setShowModal={setShowNoShowModal} detailsData={timeSheet} />}
            {showDisputeModal && <DisputeModal setShowModal={setShowDisputeModal} disputeDetailsData={timeSheet} flag={`time-sheet`} setRefreshList={setRefreshList}/>}
            {/* {showApproveModal && <ApproveModal setShowModal={setShowApproveModal} verifyCheckInCheckOut={verifyCheckInCheckOut} forVerifyData={forVerifyData} />} */}
        </Fragment>
    );

}
export default RunningView