import {Document, Page, PDFViewer, Text, View, Image} from "@react-pdf/renderer";
import styles from "../../../pdf-styles";
import {Grid, ScrollArea, Select, TextInput, Textarea, Checkbox, ActionIcon} from "@mantine/core";
import React, {useEffect, useRef, useState, Fragment} from "react";
import {
    HiChevronLeft,
    HiDocumentDownload,
    HiOutlineCurrencyDollar,
    HiOutlineExclamation,
    HiOutlineOfficeBuilding, HiOutlinePlus, HiPencil, HiEye
} from "react-icons/hi";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import axios from "axios";
import {FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {useForm} from "react-hook-form";
import {showNotification} from "@mantine/notifications";
import {DatePicker} from "@mantine/dates";
import dayjs from "dayjs";
import {useNavigate} from "react-router";
import InvoiceDetailTr from "./inc/InvoiceDetailTr";


function InvoiceUpdate() {

    const {domainId, roles, token, loggedUserId, name} = JSON.parse(localStorage.getItem('user'));
    const hasRoleModerator = roles.find(role => {
        return role === "ROLE_MODERATOR";
    })
    const {invoiceId} = useParams();
    const {t, i18n} = useTranslation();
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const fullFormHeight = localStorage.getItem('fullFormHeight');
    const navigate = useNavigate();
    const [taxData, setTaxData] = useState(0.00);
    const [totalAmountData, setTotalAmountData] = useState(0.00);
    const [recall, setRecall] = useState(false);
    const inputRef = useRef();
    const [accessType, setAccessType] = useState(false);
    const [termAndConditionId, setTermAndConditionId] = useState(null);


    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/invoice/${invoiceId}/show`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                if (res.status === 200){
                    setInvoiceDetails(res.data.data)
                    setValue('invoiceDate',res.data.data.invoiceDate ? format(new Date(res.data.data.invoiceDate.date), 'yyyy-MM-dd') : '')
                    setValue('dueDate',res.data.data.dueDate ? format(new Date(res.data.data.dueDate.date), 'yyyy-MM-dd') : '')
                    setValue('termsAndConditions',res.data.data.termsAndConditions ? res.data.data.termsAndConditions : '')
                    setValue('remarks',res.data.data.remarks ? res.data.data.remarks : '')
                    setValue('tax',res.data.data.taxRate ? res.data.data.taxRate : '')
                    setValue('invoiceId',res.data.data.invoiceId ? res.data.data.invoiceId : '')
                    setTaxData(res.data.data.taxRate?res.data.data.taxRate:0.00)
                    setTermAndConditionId(res.data.data.termsAndConditionId?res.data.data.termsAndConditionId:'')

                    setAccessType(res.data.data.accessType==='public'?true:false);
                }
            });
    }, [recall])


    const [termsAndCondition, setTermsAndCondition] = useState([]);

    const getTermsAndConditions = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/invoice/terms-and-condition`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                type: "invoice"
            }
        })
            .then(res => {
                setTermsAndCondition(res.data && res.data.data?res.data.data.map((termsAndCondition) => ({ value: termsAndCondition['id'], label: termsAndCondition['description'] })):[])
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    useEffect(() => {
        getTermsAndConditions()
    }, [])

    const TaxDataHandel = (e) => {
        setTaxData(e.target.value);
    }

    const termAndConditionDataHandle = (e) => {
        setTermAndConditionId(e);
    }

    const {register, handleSubmit, reset, formState, setValue} = useForm();
    const {errors} = formState;


    //Form Data Submit
    function formSubmit(data) {
        // data['totalInvoiceAmount'] = inputRef.current.value;
        data['accessType']=accessType
        data['termsAndConditionId']=termAndConditionId

       if(data){
           axios({
               method: 'put',
               url: `${process.env.REACT_APP_API_GATEWAY_URL}/invoice/${invoiceId}/update`,
               headers: {
                   "Accept": `application/json`,
                   "Content-Type": `application/json`,
                   "Access-Control-Allow-Origin": '*',
                   "Authorization": `Bearer ${token}`
               },
               data: data
           })
               .then(res => {

                   if (res.data.status === 202) {
                       showNotification({
                           id: 'load-data',
                           loading: true,
                           title: (t('UpdatedSuccessfully')),
                           message: (t('DataLoad')),
                           autoClose: 500,
                           disallowClose: true,
                           color: 'green',
                       });
                       navigate('/invoice');
                   }else {
                       showNotification({
                           id: 'load-data',
                           loading: true,
                           title: (t('Error')),
                           message: (t('InvoiceUpdateNotPermission')),
                           autoClose: 500,
                           disallowClose: true,
                           color: 'red',
                       });
                   }
               })
               .catch(function (error) {
                   console.log(error)
               })
       }

    }

    var lineTotalAmount = invoiceDetails && invoiceDetails.subTotalAmount?invoiceDetails.subTotalAmount:0;
    var taxAmount = ((lineTotalAmount*Number(taxData))/100).toFixed(2);

    var grandTotalAmount = (lineTotalAmount+Number(taxAmount)).toFixed(2);

    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Invoice')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>
                                    {t('InvoiceInformation')}
                                </sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <Link to={`/invoice/`+invoiceDetails.id+`/pdf`}
                                      className="inline-flex items-center text-right font-normal text-red-600 ">
                                    <HiDocumentDownload className="h-5 w-5" aria-hidden="true"/> Generate PDF
                                </Link>
                                { hasRoleModerator && invoiceDetails.paymentStatus && invoiceDetails.paymentStatus!=="PAID" &&
                                    <Link to={'/invoice/'+invoiceDetails.id+'/payment/create'}>
                                        <button
                                            className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                        >
                                            <HiOutlineCurrencyDollar size={16} className={'mr-1'}/>{t('RecordPayment')}
                                        </button>
                                    </Link>
                                }

                                <Link to={'/invoice/'+invoiceDetails.id+'/summery'}>
                                    <button
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    >
                                        <HiEye size={16} className={'mr-1'}/>{t('ViewInvoice')}
                                    </button>
                                </Link>
                                { hasRoleModerator &&
                                <Link to={'/invoice/create'}>
                                    <button
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    >
                                        <HiOutlinePlus size={16} className={'mr-1'}/>{t('NewInvoice')}
                                    </button>
                                </Link>
                                }
                                <Link to='/invoice'
                                      className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={16} className={'mr-1'}/>{t('Back')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border">
                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                        <ScrollArea style={{ height: fullFormHeight }} scrollbarSize={4}>
                                                            <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full bg-gray-200">
                                                                <div className="mt-5 md:mt-0">
                                                                    <div
                                                                        className="shadow sm:overflow-hidden sm:rounded-md">
                                                                        <div className="bg-gray-50 px-4 py-5 sm:p-6">


                                                                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                <div className="mb-3 w-1/2 items-center">
                                                                                    <label htmlFor="CompanyName"
                                                                                           className="form-input-sm-label">{t('ClientName')}</label>
                                                                                    <div
                                                                                        className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                        <p className="form-input-sm-control border-gray-300">
                                                                                            {invoiceDetails.clientName}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="mb-3 w-1/2 items-center">
                                                                                    <label htmlFor="CompanyName"
                                                                                           className="form-input-sm-label">{t('Invoice No')}</label>
                                                                                    <div
                                                                                        className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                        {/*<p className="form-input-sm-control border-gray-300">
                                                                                            {invoiceDetails.invoiceId}
                                                                                        </p>*/}
                                                                                        <input
                                                                                            {...register("invoiceId")}
                                                                                            type="text"
                                                                                            className={`form-input-sm-control ${errors.toDate ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                            name="invoiceId"
                                                                                            id="invoiceId"
                                                                                            placeholder={t("Invoice number")}
                                                                                            defaultValue={invoiceDetails && invoiceDetails.invoiceId ? invoiceDetails.invoiceId : ''}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                <div
                                                                                    className="mb-3 w-1/2 items-center">
                                                                                    <label htmlFor="invoiceDate"
                                                                                           className="form-input-sm-label">{t('InvoiceDate')}</label>

                                                                                    <div
                                                                                        className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                    <input
                                                                                            {...register("invoiceDate")}
                                                                                            type="date"
                                                                                            className={`form-input-sm-control ${errors.toDate ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                            name="invoiceDate"
                                                                                            id="invoiceDate"
                                                                                            placeholder={t("InvoiceDate")}
                                                                                            defaultValue={invoiceDetails && invoiceDetails.invoiceDate ? format(new Date(invoiceDetails.invoiceDate.date), 'yyyy-MM-dd') : ''}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="mb-3 w-1/2 items-center">
                                                                                    <label htmlFor="dueDate"
                                                                                           className="form-input-sm-label">{t('DueDate')}</label>
                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                        <input
                                                                                            {...register("dueDate")}
                                                                                            type="date"
                                                                                            className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                                                            name="dueDate"
                                                                                            id="dueDate"
                                                                                            placeholder={t("dueDate")}
                                                                                            defaultValue={invoiceDetails && invoiceDetails.dueDate ? format(new Date(invoiceDetails.dueDate.date), 'yyyy-MM-dd') : ''}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                            </div>


                                                                            <Grid columns={24}>
                                                                                <Grid.Col span={24}>
                                                                                    <div className="w-full inner-div-scroll pb-10">
                                                                                        <table className="border-collapse border border-slate-400 min-w-full">
                                                                                            <thead className="bg-white">
                                                                                            <tr>

                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                    {t('CandidateName')}
                                                                                                </th>
                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                    {t('Date')}
                                                                                                </th>
                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                    {t('Designation')}
                                                                                                </th>

                                                                                                <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('RegularRate')}
                                                                                                </th>
                                                                                                <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('OverTimeRate')}
                                                                                                </th>

                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('TotalHours')}
                                                                                                </th>
                                                                                                <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('Regular')}
                                                                                                </th>
                                                                                                <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('OverTime')}
                                                                                                </th>
                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-center text-sm font-semibold text-gray-900">
                                                                                                    {t('TotalAmount')}

                                                                                                </th>
                                                                                                {invoiceDetails.dispute===2 &&
                                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                    {t('Action')}

                                                                                                </th>
                                                                                                }

                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>
                                                                                            </tr>
                                                                                            </thead>
                                                                                            <tbody className="bg-white">


                                                                                            {
                                                                                                Object.values(invoiceDetails).length > 0 &&
                                                                                                Object.values(invoiceDetails.details).length > 0 &&
                                                                                                Object.keys(invoiceDetails.details['invoiceDetails']).map((employeeId) => {
                                                                                                    {var candidateWiseCount = 0}
                                                                                                    return (
                                                                                                        invoiceDetails.details['invoiceDetails'][employeeId] && Object.keys(invoiceDetails.details['invoiceDetails'][employeeId]).map((checkInTime,indexP)=>{
                                                                                                            return (
                                                                                                                invoiceDetails.details['invoiceDetails'][employeeId][checkInTime] && Object.values(invoiceDetails.details['invoiceDetails'][employeeId][checkInTime]).map((element, index)=>{
                                                                                                                    { candidateWiseCount = candidateWiseCount+1}

                                                                                                                    {var candidateDataLength = invoiceDetails && invoiceDetails.details && invoiceDetails.details['candidateDataLength'] && Object.values(invoiceDetails.details['candidateDataLength'][employeeId]) ? Object.values(invoiceDetails.details['candidateDataLength'][employeeId]).length:''}

                                                                                                                    return (
                                                                                                                        <InvoiceDetailTr
                                                                                                                            key={index}
                                                                                                                            element={element}
                                                                                                                            checkInTime={checkInTime}
                                                                                                                            invoiceDetails={invoiceDetails}
                                                                                                                            candidateWiseCount={candidateWiseCount}
                                                                                                                            candidateDataLength={candidateDataLength}
                                                                                                                            setRecall={setRecall}
                                                                                                                        />
                                                                                                                    )
                                                                                                                })
                                                                                                            )
                                                                                                        })
                                                                                                    )

                                                                                                })
                                                                                            }


                                                                                            </tbody>

                                                                                            <tfoot>
                                                                                            <>
                                                                                                <tr>

                                                                                                    <th className="border border-slate-300 text-right px-4" colSpan={7}>{t('TotalInvoiceHours')}</th>
                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">{invoiceDetails.totalHoursForDisplay}</td>
                                                                                                    <th className="border border-slate-300 text-right px-4" colSpan={4}>{t('TotalInvoiceAmount')}</th>
                                                                                                    <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900 text-right">{lineTotalAmount}</td>


                                                                                                    {/*<input key={`totalInvoiceAmount`} {...register('totalInvoiceAmount')} ref={inputRef} type="hidden" value={(lineTotalAmount)}/>*/}
                                                                                                    {invoiceDetails.dispute===2 &&
                                                                                                    <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                        {t('')}

                                                                                                    </th>
                                                                                                    }
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th colSpan={12} className="border border-slate-300 text-right px-4">{t('Tax')} (%)</th>
                                                                                                    <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6">
                                                                                                        <input key={`tax`}
                                                                                                               {...register("tax")}
                                                                                                               onChange={TaxDataHandel}
                                                                                                               type="text"
                                                                                                               className={`form-input-sm-control border-gray-300 focus:border-blue-600 text-right`}
                                                                                                               id="tax"
                                                                                                               placeholder={t("0.00")}
                                                                                                               defaultValue={invoiceDetails.taxRate?invoiceDetails.taxRate:0.00}
                                                                                                               onKeyPress={(event) => {
                                                                                                                   if (/[^0-9\.]/g.test(event.key)) {
                                                                                                                       event.preventDefault();
                                                                                                                   }
                                                                                                               }}
                                                                                                        />

                                                                                                    </th>
                                                                                                    {invoiceDetails.dispute===2 &&
                                                                                                    <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                        {t('')}

                                                                                                    </th>
                                                                                                    }
                                                                                                </tr>
                                                                                                <tr>

                                                                                                    <th colSpan={12} className="border border-slate-300 text-right px-4">{t('Total')}</th>
                                                                                                    <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6">
                                                                                                        $ {grandTotalAmount}
                                                                                                    </th>
                                                                                                    {invoiceDetails.dispute===2 &&
                                                                                                    <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                                        {t('')}

                                                                                                    </th>
                                                                                                    }
                                                                                                </tr>
                                                                                            </>

                                                                                            </tfoot>

                                                                                        </table>
                                                                                    </div>
                                                                                </Grid.Col>
                                                                                <Grid.Col span={24}>
                                                                                    <div className="w-full">

                                                                                        <div
                                                                                            className="mb-10 w-full items-center">
                                                                                            <input
                                                                                                {...register("accessType")}
                                                                                                onChange={(event) => setAccessType(event.currentTarget.checked)}
                                                                                                id={invoiceDetails.accessType}
                                                                                                type="checkbox"
                                                                                                value={invoiceDetails.accessType}
                                                                                                checked={accessType}
                                                                                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                                                                                            <label
                                                                                                htmlFor={invoiceDetails.accessType}
                                                                                                className="py-3 ml-2 w-full text-lg font-medium text-gray-900 dark:text-gray-300">{t('Is Public')}</label>
                                                                                        </div>

                                                                                        <div className="mb-3 w-full items-center">
                                                                                            <Textarea
                                                                                                {...register("termsAndConditions")}
                                                                                                placeholder={t("TermsAndConditions")}
                                                                                                label={t("TermsAndConditions")}
                                                                                                autosize
                                                                                                minRows={2}
                                                                                            />

                                                                                        </div>
                                                                                        <div className="mb-3 w-full items-center">
                                                                                            <Select
                                                                                            placeholder={t('SelectNoticeOfAssignment')}
                                                                                            // style={{border:`${clientError===true ?'red 1px solid':''}`}}
                                                                                            data={termsAndCondition.length>0 ? termsAndCondition:[]}
                                                                                            nothingFound={t('NoNoticeOfAssignment')}
                                                                                            maxDropdownHeight={280}
                                                                                            onChange={e => { termAndConditionDataHandle(e)}}
                                                                                            defaultValue={termAndConditionId}
                                                                                            value={termAndConditionId}
                                                                                            searchable
                                                                                            label={t("NoticeOfAssignment")}
                                                                                        />
                                                                                        </div>

                                                                                    </div>
                                                                                </Grid.Col>
                                                                            </Grid>


                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </ScrollArea>

                                                        <footer className="relative mt-1">
                                                            <div className="mr-3">
                                                                <div className="text-right pt-0.5 mb-0.5">
                                                                    <button type='reset'
                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                    ><FiX size={16} className={'mr-2'}/>
                                                                        <span>{t('Cancel')}</span>
                                                                    </button>
                                                                    <button type='submit'
                                                                            className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                        <span>{t('Save')}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </footer>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </main>

        </>
    )
}

export default InvoiceUpdate