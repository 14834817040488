import React, {Fragment, useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import button from "@material-tailwind/react";
import {
    HiOutlineDocumentAdd,
    HiOutlineExclamation,
    HiOutlineUpload,
    HiOutlineX,
    HiOutlineDocumentDownload,
    HiOutlineDownload,
    HiArrowCircleRight,
    HiBan,
    HiCloud,
    HiOutlineDocumentText,
} from "react-icons/hi";
import {ScrollArea, Table} from "@mantine/core";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {FiArrowRight} from "react-icons/fi";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import {showNotification} from "@mantine/notifications";
import { Group, Text, useMantineTheme } from '@mantine/core';
import {Dropzone, DropzoneProps, IMAGE_MIME_TYPE, MIME_TYPES} from '@mantine/dropzone';

export default function AddTimeSheetModal({setShowModal, jobId}) {
    const theme = useMantineTheme();
    const {t, i18n} = useTranslation();
    const {token, loggedUserId, roles} = JSON.parse(localStorage.getItem("user"));
    const modalHeight = localStorage.getItem("modalHeight");
    const [spinner, setSpinner] = useState(false);
    const [file, setFile] = useState(null);

    const validationSchema = Yup.object().shape({
        file: Yup.mixed().required(t('PleaseUploadFile')),

    });

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState, setValue} = useForm(formOptions);
    const {errors} = formState;

    const [csvData, setCsvData] = useState('');
    const getMissingTime = (jobId) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/candidate-empty-time-sheet-by-job`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {jobId}
        })
            .then(res => {
                setCsvData(res.data.data);
            })
            .catch(function (err) {
                console.log(err);
            });
    }
    useEffect(()=>{
        getMissingTime(jobId)
    }, [])

    const [recall, setRecall] = useState(false);
    function formSubmit(data) {
        setSpinner(true);

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-time-sheet/upload`,
            headers: {
                "Accept": `multipart/form-data`,
                "Content-Type": `multipart/form-data`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                jobId,
                file: data['file'],
                loggedUserId,
                roles
            }
        })
            .then(res => {
                setFile(null);
                setSpinner(false);
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: res.data.status === 200 ? (t('Success')) : (t('Error')),
                    message: (t(res.data.message)),
                    autoClose: 2000,
                    disallowClose: true,
                    color: res.data.status === 200 ? 'green' : 'red',
                });
                // getUploadedFiles();
            })
            .catch(function (error) {
                console.log(error)
            })


    }


    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('UploadTimeSheet')}</h5>
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <div role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: modalHeight}} scrollbarSize={4}>
                                                <form onSubmit={handleSubmit(formSubmit)}>
                                                    <div className="flex w-full">
                                                        <div className="w-1/2 pr-2">
                                                            <div>
                                                                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                                                                    <li className="flex gap-x-3">
                                                                        <HiArrowCircleRight size={18} className="h-6 w-5 flex-none text-indigo-800"/>
                                                                        {t('YouNeedToDownloadTheFollowingCsvFileToFillTheInTime&OutTimeOfCandidates')}.
                                                                    </li>
                                                                    <li className="flex gap-x-3">
                                                                        <HiArrowCircleRight size={18} className="h-6 w-5 flex-none text-indigo-800"/>
                                                                        {t('YouOnlyFindThoseCandidatesWhoDon’tHaveInTime&OutTime')}.
                                                                    </li>
                                                                    <li className="flex gap-x-3">
                                                                        <HiArrowCircleRight size={18} className="h-6 w-5 flex-none text-indigo-800"/>
                                                                        {t('AfterFillingAllTheInformationUploadTheCsvFileHere')}.
                                                                    </li>
                                                                    <li className="flex gap-x-3 text-red-500">
                                                                        <HiOutlineExclamation size={18} className="h-6 w-5 flex-none text-red-500"/>
                                                                        {t('AddTimeSheetWarningInstruction')}.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="flex justify-center mt-2">
                                                                {
                                                                    csvData ? (
                                                                        <a
                                                                            className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center ml-3 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                                                            href={`data:text/csv;charset=utf-8,${escape(csvData)}`}
                                                                            download={`job_Id-${jobId}_missing_time.csv`}
                                                                        ><HiOutlineDownload size={16} className={'mr-1'} />{t('Download')}
                                                                        </a>
                                                                    ) : (
                                                                        <button
                                                                            disabled={true}
                                                                            className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center ml-3 rounded border-none py-2 px-4 text-sm font-normal shadow-sm focus:outline-none focus:ring-0"
                                                                        ><HiBan size={16} className={'mr-1'} />{t('Download')}
                                                                        </button>
                                                                    )
                                                                }
                                                            </div>


                                                        </div>
                                                        <div className="w-1/2 relative">
                                                            {/*<label htmlFor="upload" className="form-input-sm-label-required">{t('Upload')}</label>*/}
                                                            {
                                                                errors.file?.type === 'required' &&
                                                                <div className="mt-6 form-input-sm-error">
                                                                    <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.file?.message}
                                                                </div>
                                                            }
                                                            <Dropzone
                                                                loading={spinner}
                                                                // onDrop={(file) => console.log('accepted files', file)}
                                                                onDrop={(file) => {
                                                                    setValue("file", file[0])
                                                                    setFile(file[0])
                                                                }}
                                                                // onReject={(files) => console.log('rejected files', files)}
                                                                // maxSize={3 * 1024 ** 2}
                                                                // maxSize={1}
                                                                accept={[MIME_TYPES.csv]}
                                                                multiple={false}
                                                                disabled={!csvData}
                                                            >
                                                                <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
                                                                    <Dropzone.Accept>
                                                                        <HiOutlineUpload
                                                                            size={40}
                                                                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                        />
                                                                        
                                                                    </Dropzone.Accept>
                                                                    <Dropzone.Reject>
                                                                        <HiOutlineX
                                                                            size={40}
                                                                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                                        />
                                                                    </Dropzone.Reject>
                                                                    <Dropzone.Idle>
                                                                        
                                                                        {file ? <HiOutlineDocumentText size={40} className="text-indigo-800"/> : <HiOutlineDocumentAdd size={40} className="text-indigo-800"/>}
                                                                    </Dropzone.Idle>

                                                                    <div className="text-center">
                                                                        { 
                                                                            file ? <Text size="xl" inline className="text-indigo-800">{t('FileSelected')}</Text> : (
                                                                                <Fragment>
                                                                                    <Text size="xl" inline className="text-indigo-800">{t('DragFileHereOrClickToSelectFile')}</Text>
                                                                                    <Text size="sm" inline mt={7} className="text-indigo-300">
                                                                                        {t('Only')} <span className="font-bold font-italic">CSV</span> {t('formatAcceptable')}
                                                                                    </Text>
                                                                                </Fragment>
                                                                            )
                                                                        }
                                                                        
                                                                    </div>
                                                                </Group>
                                                            </Dropzone>
                                                        </div>
                                                    </div>

                                                    <footer className="relative mt-1">
                                                        {/*<div className="mr-3">*/}
                                                            <div className="text-right pt-0.5 mb-0.5">
                                                                <button
                                                                    disabled={spinner || !csvData}
                                                                    type="submit"
                                                                    className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-700 bg-indigo-800 items-center border rounded text-sm"
                                                                >
                                                                    <span>{t('Submit')}</span>
                                                                    {
                                                                        spinner ? ( <AiOutlineLoading3Quarters className={'ml-1 animate-spin'} size={16}/> ) : (<FiArrowRight size={16} className={'ml-1'}/>)
                                                                    }
                                                                </button>
                                                            </div>
                                                        {/*</div>*/}
                                                    </footer>
                                                </form>





                                                {/*<Fragment>
                                                    <div className="bg-gray-100">
                                                        <div className="mt-2 bg-white">
                                                            <div className="relative">
                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                            <div className="mt-4">
                                                                                <div className="flex items-center">
                                                                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('DesignationInformation')}</h4>
                                                                                    <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>*/}
                                            </ScrollArea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
        </>
    );
}