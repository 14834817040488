import React, {useEffect, useState} from "react";
import {HiOutlineOfficeBuilding, HiOutlinePlus, HiPencil, HiSearch, HiOutlineClipboardList} from "react-icons/hi";
import {useTranslation} from "react-i18next";
import {format,lastDayOfMonth} from 'date-fns';
import {DataTable} from "mantine-datatable";
import {ActionIcon, Box, Group, Text, Select} from "@mantine/core";
import { DateRangePicker } from "@mantine/dates"
import {showNotification} from "@mantine/notifications";
import axios from "axios";
import {Link} from "react-router-dom";
import {HiChevronLeft} from "react-icons/hi";



const PER_PAGE = 20;
function Add() {
    const {t, i18n} = useTranslation();
    const {token, roles, loggedUserId, domainId} = JSON.parse(localStorage.getItem('user'));
    const fullTableHeight = localStorage.getItem('fullTableHeight');
    const [page, setPage] = useState(1);
    // const [dateRange, setDateRange] = useState([
    //     new Date(format(new Date(), 'MM-01-y')),
    //     new Date(format(lastDayOfMonth(new Date()), 'MM-dd-y'))
    // ]);
    const [dateRange, setDateRange] = useState([]);
    const [data, setData] = useState([]);
    const [jobId, setJobId] = useState(null);

    let parameters = {
        startDate: format(new Date(), '01-MM-y'),
        endDate: format(lastDayOfMonth(new Date()), 'dd-MM-y'),
        roles,
        loggedUserId,
        jobId

    };
    const [jobs, setJobs] = useState([]);
    const getRunningAndCompleteJobs = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/jobs/`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "status": ["running", "complete"],
                "domainId": domainId,
                "loggedUserId": loggedUserId,
                "roles": roles,
            }
        })
            .then(res => {
                setJobs(res.data.data);

            });
    }

    const jobsArray = jobs.map((job, index)=> ({value: job.id, label:job.title}));

    function filterFormSubmit(e) {
        e.preventDefault();
        /*if (!dateRange[0] || !dateRange[1]){
            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('Error') + ' !'),
                message: (t("PleaseSelectDateRange")),
                autoClose: 2000,
                disallowClose: false,
                color: 'red',
            });
        }else {
            const parameters = {
                startDate: format(new Date(dateRange[0]), 'dd-MM-y'),
                endDate: format(new Date(dateRange[1]), 'dd-MM-y'),
                roles,
                loggedUserId

            };
            getTimeSheet(parameters);

        }*/
        const parameters = {
            startDate: dateRange[0] ? format(new Date(dateRange[0]), 'dd-MM-y') : null,
            endDate: dateRange[1] ? format(new Date(dateRange[1]), 'dd-MM-y') : null,
            roles,
            loggedUserId,
            jobId

        };
        getTimeSheet(parameters);
    }
    const getTimeSheet = (parameters) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-time-sheet`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: parameters
        })
            .then(res => {
               setData(res.data)
            })
            .catch(function (err) {
                console.log(err);

            });
    }
    const clientCheckInCheckOut = (payload) => {
        // job-client-check-in-check-out
        payload['loggedUserId'] = loggedUserId;

        console.log(payload)
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-client-check-in-check-out`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: payload
        })
            .then(res => {
                // console.log(parameters)
                if (202 === res.data.status){
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Success')),
                        message: (t(res.data.message)),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'green',
                    });
                    getTimeSheet(parameters);
                }else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: (t(res.data.message)),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });
                }
            })
            .catch(function (err) {
                console.log(err);
            });
    }


    useEffect(() => {
        getRunningAndCompleteJobs()
    }, []);

    // useEffect(()=>{
    //     getTimeSheet(parameters);
    // }, []);
    const hasRoleClient = roles.find(role => {
        return role === 'ROLE_CLIENT';
    })
    const hasRoleCandidate = roles.find(role => {
        return role === 'ROLE_CANDIDATE';
    })
    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })

    return (
        <main className="w-full">
            <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-between text-gray-600 mb-3">
                <div className={"flex-1"}>
                    <div className="flex text-gray-800 h-full tracking-normal leading-tight ">
                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                        <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                            {t('AddTimeSheet')}
                            <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('AddTimeHere')}</sub></span>
                    </div>
                </div>
                <div className={"right flex mr-8"}>
                    <div className="flex items-center justify-center">
                        <div className="inline-flex border-1 border-red-300" role="group">
                            <Link
                                to ='/time-sheet'
                                className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                            >
                                <HiChevronLeft size={12} className={'mr-1'}/>{t('Back')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full px-4">
                <form onSubmit={(e)=> filterFormSubmit(e)} className={`flex`}>
                    <Select
                        placeholder={t('SelectJob')}
                        data={jobsArray}
                        onChange={setJobId}
                        value={jobId}
                    />
                    <button
                        type={`submit`}
                        className="inline-flex items-center rounded-tr rounded-br border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                    >
                        <HiSearch size={16} />
                    </button>
                </form>

                <Box sx={{height: fullTableHeight}}>
                    <DataTable
                        withBorder
                        records={data.data}
                        striped
                        styles={(theme) => ({
                            root: {
                                border: '1px solid #e0e7ff',
                                borderRadius: 2,
                            },
                            header: {
                                background: '#e0e7ff',
                                backgroundColor: "#e0e7ff",
                                color: theme.colors.indigo[6],
                            },
                            pagination: {
                                color: theme.colors.gray[3],
                                paddingTop : "5px",
                                paddingBottom : "5px"
                            },
                            item: {
                                '&[data-active]': {
                                    backgroundImage: theme.fn.gradient({ from: 'red', to: 'yellow' }),
                                },
                            },
                        })}
                        columns={[
                            {
                                accessor: '',
                                title: (t('No.')),
                                width: 45,
                                render: (item) => (data.data.indexOf(item) + 1) + PER_PAGE * (page - 1)
                            },
                            {
                                accessor: 'jobTitle',
                                title: (t('Job'))
                                // render: ({jobTitle}) => <Link to={`/job-post`} className={`text-red`}>{jobTitle}</Link>,

                            },
                            {
                                accessor: 'designationName',
                                title: (t('Designation')),
                            },
                            {
                                accessor: 'name',
                                title: (t('Name')),
                            },
                            /*{
                                accessor: ' ',
                                title: (t('Date')),
                                render: (item) => {
                                    if (item.checkInTime && item.checkOutTime){
                                        // return format(new Date(item.checkInTime.date), 'MM/dd/y')
                                        return (format(new Date(item.checkInTime.date), 'MM/dd/y') !== format(new Date(item.checkOutTime.date), 'MM/dd/y')) ? (format(new Date(item.checkInTime.date), 'MM/dd/y') + ' - ' + format(new Date(item.checkOutTime.date), 'MM/dd/y')) : format(new Date(item.checkInTime.date), 'MM/dd/y')
                                    }

                                },
                            },*/

                            {
                                accessor: 'checkInTime',
                                title: (t('InTime')),
                                render: (item) => {
                                    if (hasRoleModerator){
                                        return (
                                            <input
                                                onChange={(e) => clientCheckInCheckOut({time: e.target.value, rowId: item.id, flag: "check-in", addedBy: "moderator"})}
                                                type="datetime-local"
                                                className={`form-input-sm-control`}
                                                value={item.checkInTime ? format(new Date(item.checkInTime.date), "yyyy-MM-dd HH:mm") : ''}
                                            />
                                        )
                                    }else if (hasRoleClient){
                                        return item.checkInTime ? format(new Date(item.checkInTime.date), 'MM/dd/yyyy hh:mm:ii a') : <input onChange={(e) => clientCheckInCheckOut({time: e.target.value, rowId: item.id, flag: "check-in", addedBy: "client"})} type="datetime-local" className={`form-input-sm-control`} />
                                    }

                                }
                                ,
                            },
                            {
                                accessor: 'checkOutTime',
                                title: (t('OutTime')),
                                render: (item) => {
                                    if (hasRoleModerator){
                                        return (
                                            <input
                                                onChange={(e) => clientCheckInCheckOut({time: e.target.value, rowId: item.id, flag: "check-out", addedBy: "moderator"})}
                                                type="datetime-local"
                                                className={`form-input-sm-control`}
                                                value={item.checkOutTime ? format(new Date(item.checkOutTime.date), "yyyy-MM-dd HH:mm") : ''}
                                            />
                                        )
                                    }else if (hasRoleClient){
                                        return item.checkOutTime ? format(new Date(item.checkOutTime.date), 'MM/dd/yyyy hh:mm:ii a') : <input onChange={(e) => clientCheckInCheckOut({time: e.target.value, rowId: item.id, flag: "check-in", addedBy: "client"})} type="datetime-local" className={`form-input-sm-control`} />
                                    }
                                }

                            },
                            {
                                accessor: 'totalHour',
                                title: (t('Hour')),
                            },
                            {
                                accessor: 'rate',
                                title: (t('Rate')),
                                render: ({rate}) => rate ? ("$" + rate) : 0,

                            },
                            {
                                accessor: 'totalAmount',
                                title: (t('Amount')),
                                render: ({totalAmount}) => totalAmount ? ("$" + totalAmount) : 0,

                            },
                            {
                                accessor: 'actions',
                                title: <Text mr="xs">{t('Action')}</Text>,
                                textAlignment: 'right',
                                width: 200,
                                render: (client) => (
                                    <Group spacing={4} position="right">
                                        <ActionIcon color="green" >
                                            {/*<HiPencil size={16} />*/}

                                        </ActionIcon>
                                        {/*<ActionIcon color="blue">
                                                <HiEye size={16} onClick = {(e) => getClientDetails(client.id)} />
                                            </ActionIcon>*/}
                                        {/*<ActionIcon color="red">
                                                <HiTrash size={16} />
                                            </ActionIcon>*/}
                                    </Group>
                                ),
                            },

                        ]}
                        totalRecords={data.totalRecords}
                        recordsPerPage={PER_PAGE}
                        page={page}
                        onPageChange={(p) => setPage(p)}
                    />
                </Box>


            </div>
        </main>
    );

}
export default Add