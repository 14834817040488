import React, {useEffect} from 'react';
import { useNavigate} from 'react-router-dom';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiOutlineExclamation,
    HiOutlineUser,
    HiChevronLeft,HiArrowCircleRight
} from "react-icons/hi";

import { FiX} from "react-icons/fi";

import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import  {Toaster} from "react-hot-toast";
import {ScrollArea, Select, MultiSelect, Stepper} from '@mantine/core';
import {showNotification} from "@mantine/notifications";

function Register() {
    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const [candidateId,setCandidateId] = useState(null);

    const navigate = useNavigate();
    const {roles, token} = JSON.parse(localStorage.getItem('user'));
    const {t, i18n} = useTranslation();
    const fullFormHeight = localStorage.getItem('fullFormHeight');

    //Submit Spinner Init
    const [spinner, setSpinner] = useState(false);
    const [cityDropdown, setCityDropdown] = useState([]);


    // start get gender dropdown
    const [genderData, setGenderData] = useState('');
    const [genders, setGenders] = useState([]);
    let genderDropdown = [];
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=gender&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setGenders(res.data.data);
                }

            });
    }, []);

    if (genders) {
        genderDropdown =
            genders.map((type, index) => {
                return ({
                    'label': type.name, 'value': type.id
                })
            })
    }

    // on change gender data get
    const GenderDataHandel = (e) => {
        setGenderData(e);
        setValue('gender',e)
        if (e == '' || e == null){
            setError("gender", Yup.string().required(t("ChooseGender")).nullable() );
        }else {
            setError("gender", null);
        }
    }
    // End get gender dropdown


    // start get country dropdown
    const [countryData, setCountryData] = useState('');
    const [countries, setCountries] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setCountries(res.data.data);
            });
    }, []);

    let countryDropdown =
        countries.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })

    // on change country data get
    const [stateData, setStateData] = useState('');
    const [states, setStates] = useState([]);
    const [cityData, setCityData] = useState('');
    const CountryDataHandel = (e) => {
        setCountryData(e);
        setStates([])
        setValue('state',null)

        setCityDropdown([])
        setValue('city',null)
        setCityData('')
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/state/dropdown?country_id=${e}`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    let stateDropdown =
                        res.data.data.map((type, index) => {
                            return ({
                                'label': type.name, 'value': type.id
                            })
                        })
                    setStates(stateDropdown)
                }
            });

        setValue('country',e)
        if (e == '' || e == null){
            setError("country", Yup.string().required(t("ChooseCountry")).nullable() );
        }else {
            setError("country", null);
        }
    }


    // on change state data get
    const StateDataHandel = (e) => {
        setStateData(e);
        setValue('state',e)

        setCityDropdown([])
        setValue('city',null)
        setCityData('')
    }

    //city data handel
    const CityDataHandel = (e) => {
        setCityData(e);
        setValue('city',e)
        if (e == '' || e == null){
            setError("city", Yup.string().required(t("ChooseCity")).nullable() );
        }else {
            setError("city", null);
        }
    }
    const [citySearchValue, setCitySearchValue] = useState('');

    const CityDataHandelForSearch = (e) => {
        setCitySearchValue(e);
        if (citySearchValue.length >1){
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/city/dropdown-with-keyword?country_id=${countryData}&keyword=${citySearchValue}&state_id=${stateData}`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
            })
                .then(res => {
                    if (res.status === 200) {
                        let data =
                            res.data.data.map((head, index) => {
                                return ({
                                    'label': head.name, 'value': head.id
                                })
                            })
                        setCityDropdown(data);
                    }
                })
        }
    }
    // End get country dropdown


    // start get highest education dropdown
    const [highestEducationData, setHighestEducationData] = useState('');
    const [highestEducation, setHighestEducation] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=highest-education&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setHighestEducation(res.data.data);
                }
            });
    }, []);

    let highestEducationDropdown =
        highestEducation.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })

    // on change gender data get
    const HighestEducationDataHandel = (e) => {
        setHighestEducationData(e);
        setValue('highest_education',e)
        if (e == '' || e == null){
            setError("highest_education", Yup.string().required(t("ChooseHighestEducation")).nullable() );
        }else {
            setError("highest_education", null);
        }
    }
    // End get highest education dropdown


    // start get highest education dropdown
    const [immigrationStatusData, setImmigrationStatusData] = useState('');
    const [immigrationStatus, setImmigrationStatus] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=immigration-status&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setImmigrationStatus(res.data.data);
                }
            });
    }, []);

    let immigrationStatusDropdown =
        immigrationStatus.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })

    // on change gender data get
    const ImmigrationStatusDataHandel = (e) => {
        setImmigrationStatusData(e);
        setValue('immigration_status',e)
        if (e == '' || e == null){
            setError("immigration_status", Yup.string().required(t("ChooseImmigrationStatus")).nullable() );
        }else {
            setError("immigration_status", null);
        }
    }
    // End get highest education dropdown


    // start get employee status dropdown
    const [employeeStatusData, setEmployeeStatusData] = useState('');
    const [employeeStatus, setEmployeeStatus] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=candidate-status&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setEmployeeStatus(res.data.data);
                }
            });
    }, []);

    let employeeStatusDropdown =
        employeeStatus.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })

    // on change gender data get
    const EmployeeStatusDataHandel = (e) => {
        setEmployeeStatusData(e);
        setValue('employee_status',e)
        if (e == '' || e == null){
            setError("employee_status", Yup.string().required(t("ChooseEmployeeStatus")).nullable());
        }else {
            setError("employee_status", null);
        }
    }
    // End get employee status dropdown


    // start get source dropdown
    const [sourceData, setSourceData] = useState('');
    const [sources, setSource] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=source&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setSource(res.data.data);
                }
            });
    }, []);

    let sourcesDropdown =
        sources.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })

    // on change gender data get
    const SourceDataHandel = (e) => {
        setSourceData(e);
        setValue('source',e)
    }
    // End get source dropdown

    // start get source dropdown
    const [languageData, setLanguageData] = useState([]);
    const [languages, setLanguage] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=language`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setLanguage(res.data.data);
                }
            });
    }, []);

    let languageDropdown =
        languages.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })
    // language data control

    const LanguageDataHandel = (e) => {
        setLanguageData(e);
        setValue('language',e)
    }

    // End get source dropdown

    // start get work shift dropdown
    const [workShiftData, setWorkShiftData] = useState([]);
    const [workShift, setWorkShift] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=work-shifts&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setWorkShift(res.data.data);
                }
            });
    }, []);
    const WorkShiftDataHandel = (e, label) => {
        if (e.target.checked === true) {
            setWorkShiftData({...workShiftData,[e.target.value] : label}) ;
            setError("work_shift", null);
        } else {
            delete workShiftData[e.target.value];
            // console.log(Object.values(workShiftData).length)
            if (Object.values(workShiftData).length === 0 ){
                setValue('work_shift',[])
                setError("work_shift", Yup.array().required(t("ShiftAbleToWorkValidation")).nullable());
            }
        }
    }
    // End get work shift dropdown
    const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
    const onlyNumber = /^[0-9]+$/
    //Form validation
    const validationSchemaStepOne = Yup.object().shape({
        first_name: Yup.string().required(t("EnterFirstName")),
        last_name: Yup.string().required(t("EnterLastName")),
        email: Yup.string().email(t('EnterValidEmail')).required(t("EnterEmail")),
        secondary_email: Yup.string().email(t('EnterValidSecondaryEmail')).required(t("EnterSecondaryEmail")),
        phone: Yup.string().required(t("EnterMobileNumber")).matches(phoneRegExp, t("PhoneNumberNotValid")),
        date_of_birth: Yup.string().required(t("ChooseDate")),
        gender: Yup.string().required(t("ChooseGender")).nullable(),
        // emergency_contact: Yup.string().required(t("EnterEmergencyContact")),
        // highest_education: Yup.string().required(t("ChooseHighestEducation")).nullable(),
        immigration_status: Yup.string().required(t("ChooseImmigrationStatus")).nullable(),
        // cert_license: Yup.string().required(t("EnterCertLicense")),
        employee_status: Yup.string().required(t("ChooseEmployeeStatus")).nullable(),
        country: Yup.string().required(t("ChooseCountry")).nullable(),
        city: Yup.string().required(t("ChooseCity")).nullable(),
        street_address: Yup.string().required(t("EnterStreetAddress")).nullable(),
        zip: Yup.string().required(t("EnterZipCode")).matches(onlyNumber, t("InvalidZipCode")).nullable(),
    });

    let formOptions = {resolver: yupResolver(validationSchemaStepOne)};


    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState,setValue ,setError} = useForm(formOptions);
    const {errors} = formState;

    //Form Data Submit
    function formSubmit(data) {
        data['united_citizen'] = data.united_citizen === "Yes";
        data['did_you_graduate'] = data.did_you_graduate === "Yes";
        data['have_convicted_felony'] = data.have_convicted_felony === "Yes";
        data['previous_work_this_company'] = data.previous_work_this_company === "Yes";
        data['work_shift'] = workShiftData;
        data['cert_license'] = null;

        setSpinner(true);
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/register-candidate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: data
        })
            .then(res => {
                if (res.status === 201) {
                    setCandidateId(res.data.candidate_id)
                    navigate('/candidate/'+res.data.candidate_id+'/edit',{
                        state: {
                            type:"create"
                        }
                    });
                    nextStep()
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: res.data.message,
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'red',
                    });
                }
            })
            .catch(function (error) {
                console.log(error)
                setSpinner(false)
            })
    }

    return (
        <>
            <Toaster toastOptions={{className: 'w-1/2'}} containerStyle={{top: 63}}/>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineUser size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Candidate')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>
                                    {t('AllCandidateRelatedInformationCanBeFoundHere')}
                                </sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <button onClick={() => navigate(-1)}
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={12} className={'mr-1'}/>{t('Back')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 rounded sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className={'flex flex-1 overflow-hidden w-full'}>
                                                <div className="w-full mr-0 mx-auto border">
                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form" autoComplete="off">
                                                        <ScrollArea style={{ height: fullFormHeight }} scrollbarSize={4}>
                                                        <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full border-0 bg-gray-200 ">
                                                        <Stepper active={active} breakpoint="sm" color="green" size="sm">
                                                            <Stepper.Step label={t("BasicInformation")} description={t('BasicDetails')}>
                                                                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2 mr-6">
                                                                    <div className="md:col-span-1">
                                                                        <div className="px-4 sm:px-0 ml-3">
                                                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("BasicInformation")}</h3>
                                                                            <p className="mt-1 text-sm text-gray-600">
                                                                                {t('BasicDetails')}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="mt-5 md:col-span-4 md:mt-0">
                                                                        <div className="shadow      sm:rounded">
                                                                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                    <div className=" w-1/3 items-center">
                                                                                        <label htmlFor="last_name" className="form-input-sm-label-required">{t('LastName')}</label>
                                                                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                            {errors.last_name?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.last_name?.message}
                                                                                            </div>}

                                                                                            <input
                                                                                                {...register("last_name")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.last_name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                id="lastName"
                                                                                                placeholder={t("EnterLastName")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    <div className=" w-1/3 items-center">
                                                                                        <label htmlFor="middle_name" className="form-input-sm-label">{t('MiddleName')}</label>
                                                                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                            <input
                                                                                                {...register("middle_name")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control`}
                                                                                                id="middleName"
                                                                                                placeholder={t("EnterMiddleName")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className=" w-1/3 items-center">
                                                                                        <label htmlFor="first_name" className="form-input-sm-label-required">{t('FirstName')}</label>
                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                            {errors.first_name?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.first_name?.message}
                                                                                            </div>}

                                                                                            <input
                                                                                                {...register("first_name")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.first_name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                id="firstName"
                                                                                                placeholder={t("EnterFirstName")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div
                                                                                    className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                                    <div
                                                                                        className=" w-1/3 items-center">
                                                                                        <label htmlFor="phone"
                                                                                               className="form-input-sm-label-required">{t('Phone')}</label>
                                                                                        <div
                                                                                            className="relative mr-3 mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.phone &&
                                                                                                <div
                                                                                                    className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation
                                                                                                        size={16}
                                                                                                        className={'mr-2'}></HiOutlineExclamation> {errors.phone?.message}
                                                                                                </div>}

                                                                                            <input
                                                                                                {...register("phone")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.phone ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="phone"
                                                                                                id="phone"
                                                                                                placeholder={t("EnterPhoneNumber")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className=" w-1/3 items-center">
                                                                                        <label htmlFor="email"
                                                                                               className="form-input-sm-label-required">{t('Username')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                            {errors.email?.type === 'required' &&
                                                                                                <div
                                                                                                    className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation
                                                                                                        size={16}
                                                                                                        className={'mr-2'}></HiOutlineExclamation> {errors.email?.message}
                                                                                                </div>}

                                                                                            {errors.email?.type === 'email' &&
                                                                                                <div
                                                                                                    className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation
                                                                                                        size={16}
                                                                                                        className={'mr-2'}></HiOutlineExclamation> {errors.email?.message}
                                                                                                </div>}

                                                                                            <input
                                                                                                {...register("email")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.email ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="email"
                                                                                                id="email"
                                                                                                placeholder={t("EnterEmail")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className=" w-1/3 items-center">
                                                                                        <label htmlFor="SecondaryEmail"
                                                                                               className="form-input-sm-label-required">{t('SecondaryEmail')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                            {errors.secondary_email?.type === 'required' &&
                                                                                                <div
                                                                                                    className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation
                                                                                                        size={16}
                                                                                                        className={'mr-2'}></HiOutlineExclamation> {errors.secondary_email?.message}
                                                                                                </div>}

                                                                                            {errors.secondary_email?.type === 'email' &&
                                                                                                <div
                                                                                                    className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation
                                                                                                        size={16}
                                                                                                        className={'mr-2'}></HiOutlineExclamation> {errors.secondary_email?.message}
                                                                                                </div>}

                                                                                            <input
                                                                                                {...register("secondary_email")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.secondary_email ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="secondary_email"
                                                                                                id="secondary_email"
                                                                                                placeholder={t("EnterSecondaryEmail")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    <div
                                                                                        className=" w-1/3 items-center">
                                                                                        <label htmlFor="employee_status"
                                                                                               className="form-input-sm-label-required">{t('CandidateStatus')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.employee_status?.type === 'required' &&
                                                                                                <div
                                                                                                    className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation
                                                                                                        size={16}
                                                                                                        className={'mr-2'}></HiOutlineExclamation> {errors.employee_status?.message}
                                                                                                </div>}

                                                                                            <Select
                                                                                                {...register("employee_status")}
                                                                                                placeholder={t('ChooseEmployeeStatus')}
                                                                                                className={`${errors.employee_status ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                searchable clearable
                                                                                                allowDeselect
                                                                                                nothingFound="No options"
                                                                                                data={employeeStatusDropdown}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onChange={EmployeeStatusDataHandel}
                                                                                                value={employeeStatusData}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="md:grid md:grid-cols-5 md:gap-6 mt-2 mr-6">
                                                                    <div className="md:col-span-1">
                                                                        <div className="px-4 sm:px-0 ml-3">
                                                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("OtherInformation")}</h3>
                                                                            <p className="mt-1 text-sm text-gray-600">
                                                                                {t('OtherDetails')}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="mt-5 md:col-span-4 md:mt-0">
                                                                        <div className="shadow      sm:rounded">
                                                                            <div
                                                                                className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">

                                                                                <div
                                                                                    className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                    <div
                                                                                        className=" w-1/3 items-center">
                                                                                        <label htmlFor="gender"
                                                                                               className="form-input-sm-label-required">{t('gender')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                            {errors.gender?.type === 'required' &&
                                                                                                <div
                                                                                                    className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation
                                                                                                        size={16}
                                                                                                        className={'mr-2'}></HiOutlineExclamation> {errors.gender?.message}
                                                                                            </div>}

                                                                                            <Select
                                                                                                {...register("gender")}
                                                                                                className={`${errors.gender ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                placeholder={t('ChooseGender')}
                                                                                                searchable clearable
                                                                                                allowDeselect
                                                                                                nothingFound="No options"
                                                                                                data={genderDropdown}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onChange={GenderDataHandel}
                                                                                                value={genderData}
                                                                                            />

                                                                                        </div>
                                                                                    </div>
                                                                                    <div className=" w-1/3 items-center">
                                                                                        <label htmlFor="date_of_birth" className="form-input-sm-label-required">{t('date_of_birth')}</label>
                                                                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                            {errors.date_of_birth?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.date_of_birth?.message}
                                                                                            </div>}
                                                                                            <input
                                                                                                {...register("date_of_birth")}
                                                                                                type="date"
                                                                                                className={`form-input-sm-control ${errors.date_of_birth ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="date_of_birth"
                                                                                                id="date_of_birth"
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    {/*<div className=" w-1/3 items-center">
                                                                                        <label htmlFor="emergency_contact" className="form-input-sm-label-required">{t('EmergencyContact')}</label>
                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.emergency_contact?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.emergency_contact?.message}
                                                                                            </div>}

                                                                                            <input
                                                                                                {...register("emergency_contact")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.emergency_contact ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="emergency_contact"
                                                                                                id="emergency_contact"
                                                                                                placeholder={t("EnterEmergencyContact")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>*/}


                                                                                    <div className=" w-full items-center">
                                                                                        <label htmlFor="language" className="form-input-sm-label">{t('Language')}</label>
                                                                                        <MultiSelect
                                                                                            {...register("language")}
                                                                                            placeholder={t('ChooseLanguage')}
                                                                                            searchable clearable
                                                                                            nothingFound="No options"
                                                                                            data={languageDropdown}
                                                                                            transition="pop-top-left"
                                                                                            transitionDuration={80}
                                                                                            transitionTimingFunction="ease"
                                                                                            onChange={LanguageDataHandel}
                                                                                            value={languageData}
                                                                                        />
                                                                                    </div>




                                                                                </div>

                                                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                                    {/*<div className=" w-1/3 items-center">
                                                                                        <label htmlFor="highest_education" className="form-input-sm-label-required">{t('HighestEducation')}</label>
                                                                                        <div className="relative mr-3 mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.highest_education?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.highest_education?.message}
                                                                                            </div>}

                                                                                            <Select
                                                                                                {...register("highest_education")}
                                                                                                className={`${errors.highest_education ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}

                                                                                                placeholder={t('ChooseHighestEducation')}
                                                                                                searchable clearable
                                                                                                allowDeselect
                                                                                                nothingFound="No options"
                                                                                                data={highestEducationDropdown}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onChange={HighestEducationDataHandel}
                                                                                                value={highestEducationData}
                                                                                            />
                                                                                        </div>
                                                                                    </div>*/}

                                                                                    <div className=" w-1/3 items-center">
                                                                                        <label htmlFor="immigration_status" className="form-input-sm-label-required">{t('ImmigrationStatus')}</label>
                                                                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                            {errors.immigration_status?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.immigration_status?.message}
                                                                                            </div>}

                                                                                            <Select
                                                                                                {...register("immigration_status")}
                                                                                                className={`${errors.immigration_status ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                placeholder={t('ChooseImmigrationStatus')}
                                                                                                searchable clearable
                                                                                                allowDeselect
                                                                                                nothingFound="No options"
                                                                                                data={immigrationStatusDropdown}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onChange={ImmigrationStatusDataHandel}
                                                                                                value={immigrationStatusData}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    {/*<div className=" w-1/3 items-center">
                                                                                        <label htmlFor="cert_license" className="form-input-sm-label-required">{t('CertLicense')}</label>
                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                            {errors.cert_license?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.cert_license?.message}
                                                                                            </div>}

                                                                                            <input
                                                                                                {...register("cert_license")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.cert_license ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="cert_license"
                                                                                                id="cert_license"
                                                                                                placeholder={t("EnterCertLicense")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>*/}

                                                                                    <div className=" w-2/3 items-center">
                                                                                        <label htmlFor="linkedin" className="form-input-sm-label">{t('Linkedin')}</label>
                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            <input
                                                                                                {...register("linkedin")}
                                                                                                className={`form-input-sm-control`}
                                                                                                name="linkedin"
                                                                                                placeholder={t("EnterLinkedin")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>




                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2 mr-6">
                                                                    <div className="md:col-span-1">
                                                                        <div className="px-4 sm:px-0 ml-3">
                                                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("LocationInformation")}</h3>
                                                                            <p className="mt-1 text-sm text-gray-600">
                                                                                {t('LocationDetails')}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="mt-5 md:col-span-4 md:mt-0">
                                                                        <div className="shadow      sm:rounded">
                                                                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">

                                                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                    <div className=" w-full items-center">
                                                                                        <label htmlFor="street_address" className="form-input-sm-label-required">{t('StreetAddress')}</label>
                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.street_address?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.street_address?.message}
                                                                                            </div>}
                                                                                        <textarea
                                                                                            {...register("street_address")}
                                                                                            className={`form-input-sm-control`}
                                                                                            name="street_address"
                                                                                            placeholder={t("EnterStreetAddress")}
                                                                                        />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className=" w-1/3 items-center">
                                                                                        <label htmlFor="country" className="form-input-sm-label-required">{t('Country')}</label>
                                                                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                            {errors.country?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.country?.message}
                                                                                            </div>}

                                                                                            <Select
                                                                                                {...register("country")}
                                                                                                placeholder={t('ChooseCountry')}
                                                                                                className={`${errors.country ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                searchable clearable
                                                                                                allowDeselect
                                                                                                nothingFound="No options"
                                                                                                data={countryDropdown}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onChange={CountryDataHandel}
                                                                                                value={countryData}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className=" w-1/3 items-center">
                                                                                        <label htmlFor="state" className="form-input-sm-label">{t('State')}</label>
                                                                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                            <Select
                                                                                                {...register("state")}
                                                                                                placeholder={t('ChooseState')}
                                                                                                searchable clearable
                                                                                                allowDeselect
                                                                                                nothingFound="No options"
                                                                                                data={states}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onChange={StateDataHandel}
                                                                                                value={stateData}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className=" w-1/3 items-center">
                                                                                        <label htmlFor="city" className="form-input-sm-label-required">{t('City')}</label>
                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.city?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.city?.message}
                                                                                            </div>}

                                                                                            <Select
                                                                                                {...register("city")}
                                                                                                placeholder={t("ChooseCity")}
                                                                                                className={`${errors.city ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                searchable clearable
                                                                                                allowDeselect
                                                                                                nothingFound={t('PleaseTypeCityName')}
                                                                                                withAsterisk
                                                                                                data={cityDropdown}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onSearchChange={CityDataHandelForSearch}
                                                                                                onChange={e => {
                                                                                                    CityDataHandel(e)
                                                                                                }}
                                                                                                value={cityData}
                                                                                                searchValue={citySearchValue}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    <div className=" w-1/3 items-center">
                                                                                        <label htmlFor="zip" className="form-input-sm-label-required">{t('Zip')}</label>
                                                                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                            {errors.zip &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.zip?.message}
                                                                                            </div>}
                                                                                            <input
                                                                                                {...register("zip")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control `}
                                                                                                name="zip"
                                                                                                id="zip"
                                                                                                placeholder={t("EnterZip")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Stepper.Step>
                                                            <Stepper.Step label={t("PositionInformation")} description={t('PositionDetails')}></Stepper.Step>
                                                            <Stepper.Step label={t("EducationInformation")} description={t('EducationInformationDetails')}></Stepper.Step>
                                                            <Stepper.Step label={t("EmploymentHistory")} description= {t('EmploymentHistoryDetails')}></Stepper.Step>
                                                            <Stepper.Step label={t("ReferencesInformation")} description={t('ReferencesInformationDetails')}></Stepper.Step>
                                                            <Stepper.Step label={t("ImageAndAttachmentInformation")} description={t('ImageAndAttachmentDetails')}></Stepper.Step>


                                                        </Stepper>


                                                        </div>
                                                        </ScrollArea>
                                                        <footer className="relative mt-1 border-t">
                                                            <div className="mr-3">
                                                                <div className="text-right pt-0.5 mb-0.5 ">

                                                                                                                                        <button type='submit' className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                        {spinner ? (
                                                                            <AiOutlineLoading3Quarters className={'mr-2 animate-spin'} size={16}/>
                                                                        ) : (<HiArrowCircleRight size={16} className={'mr-2'}/>)}
                                                                        <span>{t('Next')}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </footer>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default Register