import React, {useEffect, useState, Fragment} from "react";
import {useTranslation} from "react-i18next";
import {
    HiOutlineOfficeBuilding,
    HiChevronLeft,
    HiOutlineExclamation,
    HiOutlinePlus,
    HiOutlineChevronDown,
    HiCheckCircle,
    HiStar,
    HiOutlineX
} from "react-icons/hi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiArrowRight, FiX } from "react-icons/fi";
import {
    ScrollArea,
    Grid,
    Text,
    LoadingOverlay,
    Select,
    Box,
    Group,
    useMantineTheme
} from "@mantine/core";

import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import format from "date-fns/format";
import {openConfirmModal} from '@mantine/modals';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { showNotification } from "@mantine/notifications";


function AddPositionModal({jobId, setShowModal, getRequiredDesignations}) {
    const {token, loggedUserId, roles} = JSON.parse(localStorage.getItem('user'));
    const {t, i18n} = useTranslation();

    const navigate = useNavigate();

    const [recall, setRecall] = useState(false);
    // const [candidateList, setCandidateList] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [jobDetails, setJobDetails] = useState({});
    const [recallDesignation, setRecallDesignation] = useState(false);
    const [clientDesignations, setClientDesignations] = useState([]);

    const validationSchema = Yup.object().shape({
        designationSlug: Yup.string().required(t('PositionIsRequired')),
        rate: Yup.string().required(t('RateIsRequired')),
        startDate: Yup.string().required(t('StartDateIsRequired')),
        endDate: Yup.string().required(t('EndDateIsRequired')),
        inTime: Yup.string().required(t('InTimeIsRequired')),
        outTime: Yup.string().required(t('OutTimeIsRequired')),

    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState, setValue, getValues } = useForm(formOptions);
    const { errors } = formState;

    const getJobDetails = (jobId) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/show-job`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params:{loggedUserId}
        })
            .then(res => {
                // console.log(res)
                setValue('startDate', format(new Date(res.data.data.startTime.date), 'yyyy-MM-dd'))
                setValue('endDate', format(new Date(res.data.data.endTime.date), 'yyyy-MM-dd'))
                setJobDetails(res.data.data);
                getClientDesignations(res.data.data['clientId']);

            });
    }


    const getClientDesignations = (userId) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/client-designation-rate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params:{userId}
        })
            .then(res => {

                setClientDesignations(res.data.data)
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    function formSubmit(payload){
        payload['jobId'] = jobId;
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-required-designation-from-time-sheet`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: payload
        })
            .then(res => {
                if(res.data.status === 201){
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Success')),
                        message: res.data.message,
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'green',
                    });
                }else{
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Warning')),
                        message: res.data.message,
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });
                }
                setRecallDesignation(prevState => !prevState);
                    setValue('designationSlug', null);
                    setRate('');
                    reset();
                

                
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    useEffect(()=>{
        getJobDetails(jobId)
    },[])

    const [rate, setRate] = useState('');
    const [rateFieldLoader, setRateFieldLoader] = useState(false);
    const findDesignation = (slug) => {
        setRateFieldLoader(true);
        const find = clientDesignations.find(designation => designation['slug'] === slug);
        setRate(Object.values(find).length > 0 && find['rate']);
        setValue('rate', Object.values(find).length > 0 && find['rate']);
        setRateFieldLoader(false);

    }

    return (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
            <div className="relative w-2/3">
                <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                    <div className="modal-content border-none shadow-lg flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-gray-800 relative">
                        {spinner && <LoadingOverlay visible={true} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'sm' }} overlayColor="bg-indigo-100" />}

                        <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                            <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('AddPosition')}</h5>
                            <button
                                onClick={() => {
                                    setShowModal(false)
                                    getRequiredDesignations(jobId)
                                }}
                                className="inline-flex items-center text-right font-normal text-gray-600 ">
                                <HiOutlineX className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="modal-body p-4">
                            {/* <ScrollArea style={{ height: modalHeight }} scrollbarSize={4}> */}
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <div role="main" className="w-full pt-1 px-1">
                                            <form onSubmit={handleSubmit(formSubmit)} className="h-full">
                                                <Grid className="w-full mx-auto" align="flex-end">
                                                    <Grid.Col span="auto">
                                                        <div className="w-full items-center">
                                                            <label htmlFor="designation" className="form-input-sm-label-required">{t('Designation')}</label>
                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                {
                                                                    errors.designationSlug?.type === 'required' &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.designationSlug?.message}
                                                                    </div>
                                                                }
                                                                <Select
                                                                    placeholder={t('Designation')}
                                                                    searchable
                                                                    nothingFound="No options"
                                                                    maxDropdownHeight={280}
                                                                    data={clientDesignations.map((item, index) => ({ value: item.slug, label: item.name }))}
                                                                    {...register("designationSlug")}
                                                                    id="designation"
                                                                    onChange={ e => {setValue('designationSlug', e); findDesignation(e)}}
                                                                    size="xs"
                                                                    value={getValues('designationSlug')}
                                                                />
                                                                
                                                            </div>
                                                        </div>
                                                    </Grid.Col>
                                                    <Grid.Col span="auto">
                                                        <div className="w-full items-center">
                                                            <label htmlFor="rate" className="form-input-sm-label-required">{t('Rate')}</label>
                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                {
                                                                    errors.rate?.type === 'required' &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.rate?.message}
                                                                    </div>
                                                                }
                                                                <div className="relative">
                                                                    <input
                                                                        type="number"
                                                                        className={`form-input-sm-control disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500`}
                                                                        autoComplete="off"
                                                                        placeholder={t('Rate')} 
                                                                        {...register("rate")}
                                                                        id="rate"
                                                                        defaultValue={rate}

                                                                    />
                                                                    <LoadingOverlay visible={rateFieldLoader} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'xs' }} overlayColor="bg-indigo-100" />
                                                                    
                                                                </div>
                                                                
                                                                
                                                            </div>
                                                        </div>
                                                    </Grid.Col>
                                                </Grid>
                                                <Grid className="w-full mx-auto" align="flex-end">
                                                    <Grid.Col span="auto">
                                                        <div className="w-full items-center">
                                                            <label htmlFor="startDate" className="form-input-sm-label-required">{t('StartDate')}</label>
                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                {
                                                                    errors.startDate?.type === 'required' &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.startDate?.message}
                                                                    </div>
                                                                }
                                                                <input
                                                                    {...register("startDate")}
                                                                    type="date"
                                                                    className={`form-input-sm-control`}
                                                                    name="startDate"
                                                                    id="startDate"
                                                                    placeholder={t("")}
                                                                    defaultValue={Object.values(jobDetails).length > 0 && jobDetails.startTime ? format(new Date(jobDetails.startTime.date), 'yyyy-MM-dd') : ''}
                                                                    min={Object.values(jobDetails).length > 0 && jobDetails.startTime ? format(new Date(jobDetails.startTime.date), 'yyyy-MM-dd') : ''}
                                                                    max={Object.values(jobDetails).length > 0 && jobDetails.endTime ? format(new Date(jobDetails.endTime.date), 'yyyy-MM-dd') : ''}
                                                                />
                                                                
                                                            </div>
                                                        </div>
                                                    </Grid.Col>
                                                    <Grid.Col span="auto">
                                                        <div className="w-full items-center">
                                                            <label htmlFor="endDate" className="form-input-sm-label-required">{t('EndDate')}</label>
                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                {
                                                                    errors.endDate?.type === 'required' &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.endDate?.message}
                                                                    </div>
                                                                }
                                                                <input
                                                                    {...register("endDate")}
                                                                    type="date"
                                                                    className={`form-input-sm-control`}
                                                                    name="endDate"
                                                                    id="endDate"
                                                                    placeholder={t("")}
                                                                    defaultValue={Object.values(jobDetails).length > 0 && jobDetails.endTime ? format(new Date(jobDetails.endTime.date), 'yyyy-MM-dd') : ''}
                                                                    min={ Object.values(jobDetails).length > 0 && jobDetails.startTime ? format(new Date(jobDetails.startTime.date), 'yyyy-MM-dd') : ''}
                                                                    max={ Object.values(jobDetails).length > 0 && jobDetails.endTime ? format(new Date(jobDetails.endTime.date), 'yyyy-MM-dd') : ''}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Grid.Col>
                                                </Grid>
                                                <Grid className="w-full mx-auto" align="flex-end">
                                                    <Grid.Col span="auto">
                                                        <div className="w-full items-center">
                                                            <label htmlFor="inTime" className="form-input-sm-label-required">{t('InTime')}</label>
                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                {
                                                                    errors.inTime?.type === 'required' &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.inTime?.message}
                                                                    </div>
                                                                }
                                                                <input
                                                                    type="time"
                                                                    className={`form-input-sm-control disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500`}
                                                                    {...register("inTime")}
                                                                    id="inTime"
                                                                />
                                                                
                                                            </div>
                                                        </div>
                                                    </Grid.Col>
                                                    <Grid.Col span="auto">
                                                        <div className="w-full items-center">
                                                            <label htmlFor="outTime" className="form-input-sm-label-required">{t('OutTime')}</label>
                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                {
                                                                    errors.outTime?.type === 'required' &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.outTime?.message}
                                                                    </div>
                                                                }
                                                                <input
                                                                    type="time"
                                                                    className={`form-input-sm-control disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500`}
                                                                    {...register("outTime")}
                                                                    id="outTime"
                                                                />
                                                                
                                                            </div>
                                                        </div>
                                                        
                                                    </Grid.Col>
                                                </Grid>
                                                <footer className="relative mt-1 w-full mx-auto text-right px-2">
                                                    <button
                                                        type="submit"
                                                        className="px-2 py-1.5 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-sm"
                                                    >
                                                        { spinner ? (<AiOutlineLoading3Quarters className={'mr-1 animate-spin'} size={12} />) : (<HiOutlinePlus size={12} className={'mr-1'} />) }
                                                        <span>{t('Add')}</span>
                                                    </button>
                                                </footer>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            {/* </ScrollArea> */}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddPositionModal;
