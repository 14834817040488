import React, { useEffect, useRef } from 'react';

import { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
    HiEye,
    HiOutlineChartSquareBar,
    HiOutlineExclamationCircle,
    HiOutlineExclamation,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiPencil,
    HiTrash,
    HiSearch,
    HiOutlineFilter, HiOutlineSearch
} from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { DataTable } from "mantine-datatable";
import { ActionIcon, Box, Group, Text, Checkbox, Grid, TextInput, ScrollArea, Select, Drawer, LoadingOverlay } from "@mantine/core";
import toast, { Toaster } from 'react-hot-toast';
import dayjs from "dayjs";
import ViewJobAdmin from "./modal/admin/ViewJobAdmin";
import { showNotification } from "@mantine/notifications";
import JobFilterModal from "./modal/JobFilterModal";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { DatePicker } from "@mantine/dates";
import { format } from "date-fns";
import JobStatusDropdown from './inc/JobStatusDropdown';
import ViewClient from '../client/modal/ViewClient';
import {openConfirmModal} from '@mantine/modals';



const PER_PAGE = 20;

function Index() {

    const { domainId, loggedUserId, roles, token } = JSON.parse(localStorage.getItem('user'));

    const { t, i18n } = useTranslation();
    // const [page, setPage] = useState(localStorage.getItem('item_page') ? localStorage.getItem('item_page') : 1);
    const [page, setPage] = useState(1);

    const [items, setItem] = useState([]);
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState('all');
    const [totalItem, setTotalItem] = useState(0);
    const [showModal, setShowModal] = useState(false);
    // const [showFilterModal, setShowFilterModal] = useState(false);
    const [jobDetails, setJobDetails] = useState({});

    const fullTableHeight = localStorage.getItem('fullTableHeight');
    const navigate = useNavigate();

    const [opened, setOpened] = useState(false);
    const drawerHeight = localStorage.getItem('drawerHeight');
    //get active clients
    const [clientData, setClientData] = useState('');
    const [clients, setClients] = useState([]);
    // const [sortStatus, setSortStatus] = useState({ columnAccessor: 'clientName', direction: 'asc' });
    const [sortStatus, setSortStatus] = useState({});
    const [fetching, setFetching] = useState(true);
    const [clientId, setClientId] = useState(null);
    const [showClientProfile, setShowClientProfile] = useState(false);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/client`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                status: "client"
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setClients(res.data.data)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }, [])

    const [jobStatusList, setJobStatusList] = useState([]);
    const getJobStatusList = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/master-data`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "type_slug": 'job-status'
            }
        })
            .then(res => {
                setJobStatusList(res.data.data);
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    // const updateJobDetails = (jobId, property, value) => {

    //     axios({
    //         method: 'put',
    //         url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/update-job`,
    //         headers: {
    //             "Accept": `application/json`,
    //             "Content-Type": `application/json`,
    //             "Access-Control-Allow-Origin": '*',
    //             "Authorization": `Bearer ${token}`
    //         },
    //         data: {
    //             property,
    //             value
    //         }
    //     })
    //         .then(res => {
    //             if (406 === res.data.status) {
    //                 showNotification({
    //                     id: 'load-data',
    //                     loading: true,
    //                     title: (t('Warning') + ' !'),
    //                     message: (t(res.data.message)),
    //                     autoClose: 2000,
    //                     disallowClose: true,
    //                     color: 'red',
    //                 });
    //             } else {
    //                 showNotification({
    //                     id: 'load-data',
    //                     loading: true,
    //                     title: (t('Success') + ' !'),
    //                     message: res.data.message,
    //                     autoClose: 2000,
    //                     disallowClose: true,
    //                     color: 'green',
    //                 });
    //             }
    //             setJobStatusChangeLoader(false)


    //         })
    //         .catch(function (error) {
    //             console.log(error)
    //         })
    // }

    let clientDropdown =
        clients.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })

    useEffect(() => {
        getJobStatusList()
    }, [])

    const clientDataHandle = (e) => {
        setClientData(e);
    }

    // start order by dropdown
    let orderByDropdown =
        [
            { 'label': 'ASC', 'value': 'ASC' },
            { 'label': 'DESC', 'value': 'DESC' }
        ];

    const [orderByData, setOrderByData] = useState('DESC');
    const OrderByDataHandel = (e) => {
        setOrderByData(e);
    }
    // end order by dropdown


    // start order type dropdown
    let orderTypeDropdown =
        [
            { 'label': 'Client Name', 'value': 'clientName' },
            { 'label': 'JobTitle', 'value': 'title' },
            { 'label': 'Created Date', 'value': 'createdAt' },
        ];

    const [orderTypeData, setOrderTypeData] = useState('createdAt');
    const OrderTypeDataHandel = (e) => {
        setOrderTypeData(e);
    }
    // end order type dropdown

    const [jobTitleData, setJobTitleData] = useState(null);
    const JobTitleDataHandel = (e) => {
        setJobTitleData(e.target.value);
    }

    // REQUIRED DATE HANDEL
    const [startDateData, setStartDate] = useState(null);
    const StartDateHandel = (e) => {
        setStartDate(e);
    }

    const [endDateData, setEndDate] = useState(null);
    const EndDateHandel = (e) => {
        setEndDate(e);
    }
    // REQUIRED DATE HANDEL

    const [spinner, setSpinner] = useState(false);
    //Form validation
    const validationSchema = Yup.object().shape({
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    function formSubmit(data) {
        setSpinner(true);
        setFetching(true);
        if (page === 1) {
            setFetching(true);
        } else {
            setPage(1);
        }
        setOpened(false)
    }
    useEffect(() => {
        setFetching(true);
    }, [sortStatus]);
    // get all jobs
    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/jobs/`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                per_page: PER_PAGE,
                page,
                status: [activeTab],
                domainId,
                loggedUserId,
                roles,
                client: clientData ? clientData : '',
                jobTitle: jobTitleData ? jobTitleData : '',
                start_date: startDateData ? format(startDateData, 'yyyy-MM-dd') : '',
                end_date: endDateData ? format(endDateData, 'yyyy-MM-dd') : '',
                /*"order_by": orderByData?orderByData:'',
                "order_type": orderTypeData?orderTypeData:'',*/
                order_type: sortStatus.columnAccessor ? sortStatus.columnAccessor : '',
                order_by: sortStatus.direction ? sortStatus.direction : '',
                jobType: 'regular'
            }
        })
            .then(res => {
                setItem(res.data.data);
                setTotalItem(res.data.totalJobs);
                setTabs(res.data.statusList);
                // console.log(res.data.process)
                // localStorage.setItem('item_page', page);
                setSpinner(false);
                setFetching(false);
            });
    }, [page, activeTab, sortStatus, fetching]);

    useEffect(() => {
        setPage(1);
    }, [activeTab])

    const ItemDelete = (e, id) => {
        e.preventDefault();
    }

    const [query, setQuery] = useState('');

    const jobView = (jobId) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/show-job`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                if (res.status === 200) {
                    setJobDetails(res.data.data)
                    setShowModal(!!setJobDetails)
                }
            });
    }

    const deleteJob = (id) => {
        openConfirmModal({
            title: (<HiOutlineExclamation size={30} className="text-amber-500"/>),
            centered: true,
            children: <Text size="sm" fw={700}> {t('AreYouSure')}? </Text>,
            labels: {confirm: (t('Yes')), cancel: (t('No'))},
            // confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                setFetching(true);
                axios({
                    method: 'delete',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/delete-job`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    data: {id, loggedUserId}
                })
                    .then(res => {
                        if (200 === res.data.status) {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Success')),
                                message: "Deleted",
                                autoClose: 2000,
                                disallowClose: true,
                                color: 'green',
                            });
                        } else {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Error')),
                                message: (t(res.data.message)),
                                autoClose: 2000,
                                disallowClose: true,
                                color: 'red',
                            });
                        }
                        setFetching(false);
                    })
                    .catch(function (err) {
                        console.log(err);

                    });
                
            }
        })
    }
    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })
    const hasRoleClient = roles.find(role => {
        return role === 'ROLE_CLIENT';
    })

    return (
        <>
            {/*<Toaster toastOptions={{ className: 'w-1/2' }} containerStyle={{ top : 63 }} />*/}

            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div
                            className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'} />
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('JobPost')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('JobPostRelatedInformation')}</sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <button
                                    onClick={() => setOpened(true)}
                                    className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0">
                                    <HiOutlineFilter size={12} className={'mr-1'} />{t('Filter')}
                                </button>

                                <Link to='/job-post/create'
                                    className="inline-flex items-center ml-3 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiOutlinePlus size={12} className={'mr-1'} />{t('NewJob')}
                                </Link>
                                {/*<button
                                    onClick={(e) => createJobPost(e)}
                                    className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiOutlinePlus size={12} className={'mr-1'}/>{t('NewJob')}
                                </button>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full px-4">
                    <Grid align="center">
                        <Grid.Col xs={4} sm={4} md={8} lg={8}>
                            <nav className="isolate flex divide-x divide-gray-200 rounded-t-lg shadow" aria-label="Tabs">
                                {tabs && tabs.map((tab, index) => (
                                    <button
                                        key={index}
                                        className="text-gray-900 rounded group relative min-w-0 flex-1 overflow-hidden bg-gray-100 py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                        aria-current="page"
                                        onClick={() => {
                                            setActiveTab(tab.slug)
                                            setFetching(true)
                                        }}
                                    >
                                        <span>{tab.name}</span>
                                        {activeTab === tab.slug && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5" />}

                                    </button>
                                ))}


                                {/*<button*/}
                                {/*    className="text-gray-500 hover:text-gray-700 rounded group relative min-w-0 flex-1 overflow-hidden bg-gray-100 py-2 px-4 text-sm font-medium text-center hover:bg-gray-100 focus:z-10"*/}
                                {/*    onClick={() => setActiveTab('draft')}*/}
                                {/*>*/}
                                {/*    <span>{t('Draft')}</span>*/}
                                {/*    {activeTab === 'draft'  && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5"/> }*/}

                                {/*    /!*<span aria-hidden="true" className="bg-transparent absolute inset-x-0 bottom-0 h-0.5"/>*!/*/}
                                {/*</button>*/}

                            </nav>
                        </Grid.Col>
                        <Grid.Col xs={4} sm={4}>

                        </Grid.Col>
                        {/*<Grid.Col xs={2} sm={2} align="right">
                            <button
                                onClick={() => setShowFilterModal(!showFilterModal)}
                                className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                            >
                                <HiOutlineFilter size={16} className="mr-2"/> {t('Filter')}
                            </button>
                        </Grid.Col>*/}
                    </Grid>

                    <Drawer
                        opened={opened}
                        onClose={() => setOpened(false)}
                        title={t('FilterJobs')}
                        padding="xl"
                        size="xl"
                        // overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
                        overlayColor={"black"}
                        overlayOpacity={0.55}
                        overlayBlur={3}
                        position={"right"}
                        // transition="rotate-left"
                        transitionDuration={250}
                        transitionTimingFunction="ease"
                    >
                        <form onSubmit={handleSubmit(formSubmit)}>
                            <ScrollArea style={{ height: drawerHeight }}>

                                {
                                    !hasRoleClient &&
                                    <div className="mb-5">
                                        <Select
                                            {...register("client")}
                                            placeholder={t('ChooseClient')}
                                            searchable clearable
                                            allowDeselect
                                            nothingFound="No options"
                                            data={clientDropdown}
                                            transition="pop-top-left"
                                            transitionDuration={80}
                                            transitionTimingFunction="ease"
                                            onChange={clientDataHandle}
                                            value={clientData}
                                            label={t("CompanyName")}
                                        />
                                    </div>
                                }



                                <div className="mb-5">
                                    <TextInput
                                        {...register("job_title")}
                                        onChange={JobTitleDataHandel}
                                        placeholder={t("EnterJobTitle")}
                                        value={jobTitleData ? jobTitleData : ''}
                                        withAsterisk={false}
                                        label={t("JobTitle")}
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="startDate" className="form-input-sm-label">{t('StartDate')}</label>

                                    <DatePicker
                                        {...register("start_date")}
                                        dropdownType="modal"
                                        placeholder="StartDate"
                                        onChange={StartDateHandel}
                                        value={startDateData}
                                    />
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="endDate" className="form-input-sm-label">{t('EndDate')}</label>
                                    <DatePicker
                                        {...register("end_date")}
                                        dropdownType="modal"
                                        placeholder="EndDate"
                                        onChange={EndDateHandel}
                                        value={endDateData}
                                    />
                                </div>


                            </ScrollArea>



                            <div className="mb-2 mt-5 text-right">

                                <Grid>
                                    <Grid.Col span={6}>
                                        <Select
                                            {...register("order_type")}
                                            searchable clearable
                                            allowDeselect
                                            nothingFound="No options"
                                            withAsterisk
                                            data={orderTypeDropdown}
                                            transition="pop-top-left"
                                            transitionDuration={80}
                                            transitionTimingFunction="ease"
                                            onChange={OrderTypeDataHandel}
                                            value={orderTypeData}
                                        />

                                    </Grid.Col>
                                    <Grid.Col span={3}>
                                        <Select

                                            {...register("order_by")}
                                            searchable clearable
                                            allowDeselect
                                            nothingFound="No options"
                                            withAsterisk
                                            data={orderByDropdown}
                                            transition="pop-top-left"
                                            transitionDuration={80}
                                            transitionTimingFunction="ease"
                                            onChange={OrderByDataHandel}
                                            value={orderByData}


                                        />
                                    </Grid.Col>
                                    <Grid.Col span={3}>
                                        <button type='submit' className="px-6 py-2 bg-indigo-800 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                            {spinner ? (
                                                <HiOutlineFilter
                                                    className={'mr-2 animate-spin'}
                                                    size={16} />
                                            ) : (<HiOutlineFilter size={16} className={'mr-2'} />)}
                                            <span>{t('Filter')}</span>
                                        </button>
                                    </Grid.Col>
                                </Grid>


                            </div>
                        </form>

                    </Drawer>

                    <Box sx={{ height: fullTableHeight }}>
                        <DataTable
                            withBorder
                            records={items}
                            striped
                            styles={(theme) => ({
                                root: {
                                    border: '1px solid #e0e7ff',
                                    borderRadius: 2,
                                },
                                header: {
                                    background: '#e0e7ff',
                                    backgroundColor: "#e0e7ff",
                                    color: theme.colors.indigo[6],
                                },
                                pagination: {
                                    color: theme.colors.gray[3],
                                    paddingTop: "5px",
                                    paddingBttom: "5px"
                                },
                                item: {
                                    '&[data-active]': {
                                        backgroundImage: theme.fn.gradient({ from: 'red', to: 'yellow' }),
                                    },
                                },
                            })}
                            columns={[
                                {
                                    accessor: '',
                                    title: (t('No.')),
                                    width: 45,
                                    render: (item) => (items.indexOf(item) + 1) + PER_PAGE * (page - 1)
                                },
                                {
                                    accessor: 'clientName',
                                    title: (t('ClientName')),
                                    width: 200,
                                    sortable: true,
                                    hidden: !!hasRoleClient,
                                    render: (item) => <Text className="cursor-pointer" onClick={() => {setClientId(item.clientId); setShowClientProfile(true)}}>{item.clientName}<span className="ml-2 inline-flex items-center rounded bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800">{t('Details')}</span></Text>,

                                },
                                {
                                    accessor: 'title',
                                    title: (t('JobTitle')),
                                    width: 200,
                                    sortable: true
                                },
                                {
                                    accessor: 'vacancy',
                                    title: (t('Vacancy')),
                                },
                                {
                                    accessor: 'startDate',
                                    title: (t('StartDate')),
                                    render: ({ startDate }) => startDate && format(new Date(startDate.date), 'MM/dd/yyyy'),
                                },
                                {
                                    accessor: 'endDate',
                                    title: (t('EndDate')),
                                    render: ({ endDate }) => endDate && format(new Date(endDate.date), 'MM/dd/yyyy'),
                                },
                                {
                                    accessor: '',
                                    title: (t('Status')),
                                    width: 200,
                                    render: (job) => (


                                        job['status'][0].toUpperCase() + job['status'].slice(1)
                                        // hasRoleModerator ? (
                                        //     ['running', 'complete', 'archived', 'published'].some(status => status === job['status']) ? 
                                        //     job['status'][0].toUpperCase() + job['status'].slice(1) :
                                        //     <JobStatusDropdown key={job['id']} jobId={job.id} statusList={jobStatusList} jobStatusSlug={job['status']} setRefresh={setFetching} />

                                        // ) : job['status'][0].toUpperCase() + job['status'].slice(1)
                                    ),
                                },
                                {
                                    accessor: 'actions',
                                    title: <Text mr="xs">{t('Action')}</Text>,
                                    textAlignment: 'right',
                                    render: (job) => (
                                        <Group spacing={4} position="right" noWrap>
                                            {
                                                ['running', 'complete', 'archived'].some(status => status === job['status']) ? '' : 
                                                    <ActionIcon 
                                                        color={job.status === 'published' ? 'gray' : 'green'}
                                                        onClick={(e) => navigate(job.id + '/edit')}
                                                        disabled={ hasRoleClient && ['published', 'running', 'complete', 'archived'].find(status => status === job.status) }
                                                    >
                                                        <HiPencil size={16}/>
                                                    </ActionIcon>
                                            }
                                            
                                            <ActionIcon color="blue">
                                                {/*<HiEye size={16} onClick={(e) => jobView(job.id)}/>*/}
                                                <HiEye size={16} onClick={(e) => navigate(job.id + '/view')} />
                                            </ActionIcon>

                                            {
                                                ['running', 'complete', 'archived', 'published'].some(status => status === job['status']) ? '' : 
                                                    <ActionIcon
                                                        color={job.status === 'published' ? 'gray' : 'red'}
                                                        disabled={ hasRoleClient && ['published', 'running', 'complete', 'archived'].find(status => status === job.status) }
                                                        onClick={(e) => {
                                                            if (job.status === 'published') {
                                                                showNotification({
                                                                    id: 'load-data',
                                                                    loading: true,
                                                                    title: (t('Error') + ' !'),
                                                                    message: (t('Published job can not be deleted')),
                                                                    autoClose: 1000,
                                                                    disallowClose: true,
                                                                    color: 'red',
                                                                });
                                                            }
                                                            deleteJob(job.id)
                                                        }}
                                                    >
                                                        <HiTrash size={16} />
                                                    </ActionIcon>
                                            }

                                            
                                        </Group>
                                    ),
                                },

                            ]}
                            // idAccessor={Math.random()}
                            totalRecords={totalItem}
                            recordsPerPage={PER_PAGE}
                            page={page}
                            onPageChange={(p) => {
                                setPage(p)
                                setFetching(true)
                            }}
                            fetching={fetching}
                            loaderSize="sm"
                            loaderColor="indigo"
                            loaderBackgroundBlur={2}
                            sortStatus={sortStatus}
                            onSortStatusChange={setSortStatus}
                        />
                    </Box>
                </div>
            </main>


            {showModal ? <ViewJobAdmin setShowModal={setShowModal} jobDetails={jobDetails} roles={roles} /> : ''}
            {/*{showFilterModal ? <JobFilterModal setShowFilterModal={setShowFilterModal} showFilterModal={showFilterModal}/> : ''}*/}
            { showClientProfile && <ViewClient setShowModal={setShowClientProfile} clientID={clientId} /> }


        </>
    )
}

export default Index