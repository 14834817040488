import React, {Fragment, useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiOutlineExclamation,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiChevronLeft
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {FiArrowDownRight, FiArrowRight, FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters, AiOutlineFileSearch, AiOutlineMinus} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import {useNavigate} from "react-router";
import {Select, Tooltip, ScrollArea, TextInput} from "@mantine/core";
import {Tab} from "@headlessui/react";
import {format} from 'date-fns';
import { input } from '@material-tailwind/react';



function Create(){
    const {roles, token, loggedUserId, domainId} = JSON.parse(localStorage.getItem('user'));
    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })
    const hasRoleClient = roles.find(role => {
        return role === 'ROLE_CLIENT';
    })

    const navigate = useNavigate();

    const {t, i18n} = useTranslation();
    const [spinner, setSpinner] = useState(false);

    const [clients, setClients] = useState([]);

    const [startTimeMax, setStartTimeMax] = useState('');
    const [endTimeMin, setEndTimeMin] = useState('');

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [stateData, setStateData] = useState('');
    const [cityData, setCityData] = useState('');
    const [cities, setCities] = useState([]);

    const [validate,setValidate] = useState(false);

    const [countryId, setCountryId] = useState(null);
    const [stateId, setStateId] = useState(null);
    const [country, setCountry] = useState(null);

    // console.log(countryId)


    const [domainDetails, setDomainDetails] = useState({});
    const getDomainDetails = () => {
        axios({
            method: 'get',
            // url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/active-clients`,
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/domains/${domainId}`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            }
        })
            .then(res => {
                setDomainDetails(res.data.data);
                if(res.data.data.country){
                    const countryId = Number(res.data.data.country.split('-')[0]);
                    // console.log(res.data.data.country)

                    setCountryId(Number(res.data.data.country.split('-')[0]));
                    setCountry(res.data.data.country);
                    getStates(countryId);
                    // getCities(countryId, null);

                }

            })
            .catch(function (error) {
                console.log(error)
            })
    }


    const getStates = (countryId) => {
        // console.log(countryId)
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/state/dropdown`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {country_id: countryId}
        })
            .then(res => {
                // console.log(res)
                setStates(res.data.data)

            })
            .catch(function (error) {
                console.log(error)
            })
    }



    const citySearch = (searchValue) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/city/dropdown-with-keyword`,
            params: {country_id: Object.values(domainDetails).length > 0 && domainDetails['country'].split('-')[0], keyword: searchValue, state_id: stateId && stateId.split('-')[0]},
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                setCities(res.data.data)
            })
    }


    const getCities = (countryId, stateId) => {
        if(stateId){
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/countries/state/wise/city/dropdown`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                params: {country_id: countryId, state_id: stateId}
            })
                .then(res => {
                    setCities(res.data.data)
    
                })
                .catch(function (error) {
                    console.log(error)
                })
        }else{
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/city/dropdown`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                params: {country_id: countryId}
            })
                .then(res => {
                    // console.log(res)
                    setCities(res.data.data)
    
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
        
    }

    useEffect(() => {
        getDomainDetails();
    }, []);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setCountries(res.data.data);
            });
    }, []);

    let countryDropdown =
        countries.map((type, index) => {
            return ({
                // 'label': type.name, 'value': type.id
                'label': type.name, 'value': type.id + '-' + type.name
            })
        })
    const [countryData, setCountryData] = useState('');
    const CountryDataHandel = (e) => {
        if (e){
            // const countryWithId = e.split('-');
            const countryWithId = domainDetails['country'] && domainDetails['country'].split('-');
            setCountryData(e);
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/state/dropdown?country_id=${Number(countryWithId[0])}`,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                    console.log(res.data.data)
                    if (res.data.status === 200) {
                        setStates(res.data.data)
                    }
                });

            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/city/dropdown?country_id=${Number(countryWithId[0])}`,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                    if (res.data.status === 200) {
                        setCities(res.data.data)
                    }
                });
        }

    }

    // let stateDropdown =
    // states.map((type, index) => {
    //         return ({
    //             'label': type.name, 'value': type.id + '-' + type.name
    //         })
    //     })
    // on change state data get
    const StateDataHandel = (stateId) => {
        setStateData(stateId);
        // getCities(null, stateId);
    }

    // let cityDropdown =
    //     cities.map((type, index) => {
    //         return ({
    //             'label': type.name, 'value': type.id + '-' + type.name
    //         })
    //     })

    //get active clients
    useEffect(() => {
        axios({
            method: 'get',
            // url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/active-clients`,
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/client`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                status: "client"
            }
        })
            .then(res => {
                setClients(res.data.data)

            })
            .catch(function (error) {
                console.log(error)
            })
    },[]);


    let clientDropdown =
    clients.map((client, index) => {
            return ({
                'label': client.name, 'value': client.id
            })
        })

    let fullFormHeight = localStorage.getItem('fullFormHeight');
    fullFormHeight = fullFormHeight.slice(0, -2);
    fullFormHeight = fullFormHeight - 77;
    fullFormHeight = fullFormHeight + "px";

    //Form validation

    const validationSchema = Yup.object().shape({
        dummyKey: Yup.string(),
        clientId: Yup.string().when('dummyKey', {
            is: (value) => hasRoleModerator === 'ROLE_MODERATOR',
            then: Yup.string().required(t('SelectClient')),
            otherwise: Yup.string().notRequired(),
        }),
        title: Yup.string().required(t("JobTitleIsRequired")),
        description: Yup.string().required(t("JobDetailsIsRequired")),
        // address: Yup.string().required(t('AddressIsRequired')),
        streetAddress: Yup.string().required(t('StreetAddressIsRequired')),
        // zipCode: Yup.string().required(t('ZipCodeIsRequired')),
        // startTime: Yup.string().required(t("StartTimeIsRequired")),
        // endTime: Yup.string().required(t("EndTimeIsRequired")),

    });
    // if (hasRoleModerator){
    //     const validationSchema = Yup.object().shape({
    //         clientId: Yup.string().required(t('SelectClient')),
    //         title: Yup.string().required(t("JobTitleIsRequired")),
    //         description: Yup.string().required(t("JobDetailsIsRequired")),
    //         streetAddress: Yup.string().required(t('StreetAddressIsRequired')),
    //         startTime: Yup.string().required(t("StartTimeIsRequired")),
    //         endTime: Yup.string().required(t("EndTimeIsRequired")),

    //     });
    // }
    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState, setValue, setError} = useForm(formOptions);
    const {errors} = formState;



    //Form Data Submit
    function formSubmit(payload) {
        setSpinner(true);

        payload['clientId'] = hasRoleClient ? loggedUserId : Number(payload['clientId']);
        payload['domainId'] = domainId;
        payload['createdBy'] = loggedUserId;
        payload['status'] = 'draft';
        payload['device'] = 'web';
        payload['country'] = country;

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/new-job`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: payload
        })
            .then(res => {
                navigate('/job-post/' + res.data.data.jobId + '/edit');
            })
            .catch(function (error) {
                console.log(error)
            });
    }




    return (
        <main className="w-full" >
            <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                <div className={"flex-1"}>
                    <div
                        className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                        <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('JobPost')}
                            <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('JobPostRelatedInformation')}</sub>
                            </span>
                    </div>
                </div>
                <div className={"right flex mr-8"}>
                    <div className="flex items-center justify-center">
                        <div className="inline-flex border-1 border-red-300" role="group">
                            <Link to="/job-post" className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0">
                                <HiChevronLeft size={12} className={'mr-1'} />{t('Back')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="min-h-full w-full">

                <div className="flex w-full md:w-auto">
                    <div className="bg-red-100 flex-1">
                        <div className="min-w-0 flex-1 bg-white xl:flex">
                            <div className="bg-white lg:min-w-0 lg:flex-1">
                                <div className="h-full px-4 sm:px-6 lg:px-4">
                                    <div className="relative">
                                        <div className={'flex flex-1 overflow-hidden w-full'}>
                                            <div className="w-full mr-0 mx-auto border">
                                                <div>
                                                    <div className="hidden sm:block">
                                                        <div className="border-b border-gray-200">
                                                            <Tab.Group>
                                                                <Tab.List
                                                                    className={'flex bg-white h-12 mb-2'}>
                                                                    <Tab as={Fragment}>
                                                                        {({selected}) => (
                                                                            <button
                                                                                className={selected ? ' h-12 border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-500 text-indigo-700' : 'border-b-2  py-1.5 p-3.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'}>{t('Basic')}</button>
                                                                        )}
                                                                    </Tab>
                                                                    <Tab as={Fragment} disabled>
                                                                        {({selected}) => (
                                                                            <button className={selected ? 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-500 text-indigo-700' : 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'}>{t('RequiredDesignation')}</button>

                                                                        )}

                                                                    </Tab>
                                                                    {/*<Tab as={Fragment} disabled>
                                                                        {({selected}) => (
                                                                            <button
                                                                                className={selected ? 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-500 text-indigo-700' : 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'}>Preferred
                                                                                Candidate
                                                                            </button>
                                                                        )}
                                                                    </Tab>*/}
                                                                </Tab.List>
                                                                <Tab.Panels>
                                                                    <Tab.Panel>
                                                                        <form id="horizontal-form" onSubmit={handleSubmit(formSubmit)}>
                                                                        <ScrollArea style={{ height: fullFormHeight }} scrollbarSize={4}>

                                                                                <div
                                                                                    className="py-3 relative flex flex-col min-w-0 break-words w-full border-0">
                                                                                    <div>
                                                                                        <div className="md:grid md:grid-cols-5 md:gap-6">
                                                                                            <div className="md:col-span-1">
                                                                                                <div className="ml-4 px-4 sm:px-0">
                                                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("AppointmentInformation")}</h3>
                                                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                                                        {t('EnterAppointmentRelatedDetails')}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="mt-5 md:col-span-4 md:mt-0">
                                                                                                <div className="shadow sm:overflow-hidden sm:rounded">
                                                                                                    <div className="bg-gray-50 px-4 py-5 sm:p-6">

                                                                                                        {
                                                                                                            hasRoleModerator && (
                                                                                                                <div className="w-full items-center">
                                                                                                                    <label htmlFor="client" className="form-input-sm-label-required">{t('Client')}</label>
                                                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                                                        {
                                                                                                                            errors.clientId?.type === 'required' &&
                                                                                                                            <div className="form-input-sm-error">
                                                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.clientId?.message}
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        <div
                                                                                                                            className="relative items-stretch focus-within:z-10">
                                                                                                                            <Select
                                                                                                                                {...register("clientId")}
                                                                                                                                placeholder={t('ChooseClient')}
                                                                                                                                searchable clearable
                                                                                                                                allowDeselect
                                                                                                                                nothingFound="No options"
                                                                                                                                data={clientDropdown}
                                                                                                                                transition="pop-top-left"
                                                                                                                                transitionDuration={80}
                                                                                                                                transitionTimingFunction="ease"
                                                                                                                                onChange={e => {
                                                                                                                                    setValue("clientId", e);
                                                                                                                                    setError("clientId", null);
                                                                                                                                }}
                                                                                                                                // value={clientData}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        }

                                                                                                        <div className="w-full items-center">
                                                                                                            <label htmlFor="jobTitle" className="form-input-sm-label-required">{t('JobTitle')}</label>
                                                                                                            <div
                                                                                                                className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                {
                                                                                                                    errors.title?.type === 'required' &&
                                                                                                                    <div className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.title?.message}
                                                                                                                    </div>
                                                                                                                }
                                                                                                                <input
                                                                                                                    {...register("title")}
                                                                                                                    type="text"
                                                                                                                    className={`form-input-sm-control ${errors.title ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                                    name="title"
                                                                                                                    id="title"
                                                                                                                    placeholder={t("EnterJobTitle")}

                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {/*<div className='flex flex-wrap col-span-2'>
                                                                                                            <div className="w-1/3 items-center">
                                                                                                                <label htmlFor="startDate" className="form-input-sm-label-required">{t('StartDate')}</label>
                                                                                                                <div className="relative mr-3 mt-1 sm:col-span-2 sm:mt-0">

                                                                                                                    {
                                                                                                                        errors.startTime?.type === 'required' &&
                                                                                                                        <div className="form-input-sm-error">
                                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.startTime?.message}
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                    <div className="relative  items-stretch focus-within:z-10">
                                                                                                                        <input
                                                                                                                            {...register("startTime")}
                                                                                                                            type="date"
                                                                                                                            className={`form-input-sm-control`}
                                                                                                                            name="startTime"
                                                                                                                            id="startTime"
                                                                                                                            placeholder={t("")}
                                                                                                                            min={format(new Date(), 'yyyy-MM-dd')}
                                                                                                                            max={startTimeMax ? startTimeMax : ''}
                                                                                                                            onChange={e => {
                                                                                                                                setEndTimeMin(e.target.value);
                                                                                                                                setError("startTime");
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="w-1/3 items-center">
                                                                                                                <label htmlFor="endDate" className="form-input-sm-label-required">{t('EndDate')}</label>
                                                                                                                <div className="relative mr-3 mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                    {
                                                                                                                        errors.endTime?.type === 'required' &&
                                                                                                                        <div className="form-input-sm-error">
                                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.endTime?.message}
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                    <div className="relative items-stretch focus-within:z-10">
                                                                                                                        <input
                                                                                                                            {...register("endTime")}
                                                                                                                            type="date"
                                                                                                                            className={`form-input-sm-control`}
                                                                                                                            name="endTime"
                                                                                                                            id="endTime"
                                                                                                                            placeholder={t("")}
                                                                                                                            min={endTimeMin ? endTimeMin : format(new Date(), 'yyyy-MM-dd')}
                                                                                                                            onChange={e => {
                                                                                                                                setStartTimeMax(e.target.value);
                                                                                                                                setError("endTime");
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>*/}

                                                                                                        <div className="w-full items-center">
                                                                                                            <label htmlFor="jobDetails" className="form-input-sm-label-required">{t('JobDetails')}</label>
                                                                                                            <div
                                                                                                                className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                {
                                                                                                                    errors.description?.type === 'required' &&
                                                                                                                    <div className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.description?.message}
                                                                                                                    </div>
                                                                                                                }
                                                                                                                <textarea
                                                                                                                    {...register("description")}
                                                                                                                    className={`form-input-sm-control-textarea ${errors.description ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                                    name="description"
                                                                                                                    id="description"
                                                                                                                    placeholder={t("EnterJobDetails")}
                                                                                                                    rows={20}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="mb-3 w-full items-center">
                                                                                                            <label htmlFor="specialRequest" className="form-input-sm-label">{t('DoYouHaveAnySpecialRequest')}?</label>
                                                                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                            <textarea
                                                                                                                {...register("specialRequest")}
                                                                                                                className={`form-input-sm-control-textarea border-gray-300 focus:border-blue-600`}
                                                                                                                name="specialRequest"
                                                                                                                id="specialRequest"
                                                                                                                placeholder={t("DoYouHaveAnySpecialRequest") + "?"}
                                                                                                                rows={20}
                                                                                                            />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="hidden sm:block" aria-hidden="true">
                                                                                        <div className="py-5">
                                                                                            <div className="border-t border-gray-200"/>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Job Location Section */}
                                                                                    <div className="mt-10 sm:mt-0">
                                                                                        <div className="md:grid md:grid-cols-5 md:gap-6">
                                                                                            <div className="md:col-span-1">
                                                                                                <div className="ml-4 px-4 sm:px-0">
                                                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t('JobLocation')}</h3>
                                                                                                    <p className="mt-1 text-sm text-gray-600">{t('EnterJobsLocationRelatedDetails')}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="mt-5 md:col-span-4 md:mt-0">
                                                                                                <div className="shadow sm:rounded">
                                                                                                    <div className="bg-gray-50 px-4 py-5 sm:p-6">
                                                                                                        {/* <div className="w-full items-center">
                                                                                                            <label htmlFor="streetAddress" className="form-input-sm-label-required">{t('StreetAddress')}</label>
                                                                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                {
                                                                                                                    errors.streetAddress?.type === 'required' &&
                                                                                                                    <div className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.streetAddress?.message}
                                                                                                                    </div>
                                                                                                                }
                                                                                                                <TextInput 
                                                                                                                    {...register("streetAddress")}
                                                                                                                    name="streetAddress"
                                                                                                                    id="streetAddress"
                                                                                                                    placeholder={t("EnterStreetAddress")}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div> */}




                                                                                                        <div className='flex flex-wrap -mx-0.5 col-span-2'>
                                                                                                            <div className="w-2/3 items-center">
                                                                                                                <label htmlFor="streetAddress" className="form-input-sm-label-required">{t('StreetAddress')}</label>
                                                                                                                <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                                    {
                                                                                                                        errors.streetAddress?.type === 'required' &&
                                                                                                                        <div className="form-input-sm-error">
                                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.streetAddress?.message}
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                    <TextInput 
                                                                                                                        {...register("streetAddress")}
                                                                                                                        name="streetAddress"
                                                                                                                        id="streetAddress"
                                                                                                                        placeholder={t("EnterStreetAddress")}
                                                                                                                    />
                                                                                                                    {/* <textarea
                                                                                                                        {...register("streetAddress")}
                                                                                                                        className={`form-input-sm-control-textarea `}
                                                                                                                        name="streetAddress"
                                                                                                                        id="streetAddress"
                                                                                                                        placeholder={t("EnterStreetAddress")}
                                                                                                                    /> */}
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="w-1/3 items-center">
                                                                                                                <label htmlFor="zipCode" className="form-input-sm-label">{t('ZipCode')}</label>

                                                                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                    {/*{
                                                                                                                        errors.zipCode?.type === 'required' &&
                                                                                                                        <div className="form-input-sm-error">
                                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.zipCode?.message}
                                                                                                                        </div>
                                                                                                                    }*/}

                                                                                                                    <TextInput
                                                                                                                        {...register("zipCode")}
                                                                                                                        type="text"
                                                                                                                        name="zipCode"
                                                                                                                        id="zipCode"
                                                                                                                        placeholder={t("EnterZipCode")}
                                                                                                                    />

                                                                                                                    {/* <input
                                                                                                                        {...register("zipCode")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control h-[31px]`}
                                                                                                                        name="zipCode"
                                                                                                                        id="zipCode"
                                                                                                                        placeholder={t("EnterZipCode")}
                                                                                                                    /> */}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        





                                                                                                        {/*<div className="w-full items-center">
                                                                                                            <label htmlFor="address" className="form-input-sm-label-required">{t('Address')}</label>
                                                                                                            <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                                {
                                                                                                                    errors.address?.type === 'required' &&
                                                                                                                    <div className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.address?.message}
                                                                                                                    </div>
                                                                                                                }
                                                                                                                <textarea
                                                                                                                    {...register("address")}
                                                                                                                    className={`form-input-sm-control-textarea ${errors.address ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                                    name="address"
                                                                                                                    id="address"
                                                                                                                    placeholder={t("EnterAddress")}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>*/}
                                                                                                        {/* <div className='flex flex-wrap -mx-0.5 col-span-2'>
                                                                                                            <div className="w-1/3 items-center">
                                                                                                                <label htmlFor="zipCode" className="form-input-sm-label">{t('ZipCode')}</label>

                                                                                                                <div className="relative mr-3 mt-1 sm:col-span-2 sm:mt-0">

                                                                                                                    <input
                                                                                                                        {...register("zipCode")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control`}
                                                                                                                        name="zipCode"
                                                                                                                        id="zipCode"
                                                                                                                        placeholder={t("EnterZipCode")}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div> */}
                                                                                                        <div className='flex flex-wrap -mx-0.5 col-span-2'>
                                                                                                            <div className="w-1/3 items-center">
                                                                                                                <label htmlFor="country" className="form-input-sm-label">{t('Country')}</label>

                                                                                                                <div className="relative mr-3 mt-1 sm:col-span-2 sm:mt-0">

                                                                                                                    <TextInput
                                                                                                                        disabled
                                                                                                                        className={`font-bold`}
                                                                                                                        value={domainDetails['country'] && domainDetails['country'].split('-')[1]}
                                                                                                                    />
                                                                                                                    {/* <input
                                                                                                                        {...register("zipCode")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control h-[31px]`}
                                                                                                                        name="zipCode"
                                                                                                                        id="zipCode"
                                                                                                                        placeholder={t("EnterZipCode")}
                                                                                                                    /> */}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="w-1/3 items-center">
                                                                                                                <label htmlFor="state" className="form-input-sm-label">{t('State')}</label>
                                                                                                                <div  className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                                    <Select
                                                                                                                        {...register("state")}
                                                                                                                        placeholder={t('ChooseState')}
                                                                                                                        searchable clearable
                                                                                                                        allowDeselect
                                                                                                                        nothingFound="No options"
                                                                                                                        data={states.length > 0 ? states.map((state, index) => ({label: state['name'], value: state['id'] + '-' + state['name']})) : []}
                                                                                                                        transition="pop-top-left"
                                                                                                                        transitionDuration={80}
                                                                                                                        transitionTimingFunction="ease"
                                                                                                                        maxDropdownHeight={200}
                                                                                                                        onChange={e => {
                                                                                                                            setValue("state", e)
                                                                                                                            setValue("city", null)
                                                                                                                            setStateId(e)
                                                                                                                            // StateDataHandel(e)
                                                                                                                        }}
                                                                                                                        // value={stateData}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="w-1/3 items-center">
                                                                                                                <label htmlFor="cityId" className="form-input-sm-label">{t('City')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                    {
                                                                                                                        errors.city ?.type === 'required' &&
                                                                                                                        <div className="form-input-sm-error">
                                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.city?.message}
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                    <Select
                                                                                                                        {...register("city")}
                                                                                                                        placeholder={t('PleaseTypeCityName')}
                                                                                                                        searchable clearable
                                                                                                                        allowDeselect
                                                                                                                        nothingFound={t('PleaseTypeCityName')}
                                                                                                                        data={cities.length > 0 ? cities.map((city, index) => ({label: city['name'], value: city['id'] + '-' + city['name']})) : []}
                                                                                                                        transition="pop-top-left"
                                                                                                                        dropdownPosition="top"
                                                                                                                        transitionDuration={80}
                                                                                                                        transitionTimingFunction="ease"
                                                                                                                        onChange={e => setValue("city", e)}
                                                                                                                        onSearchChange={e => e && citySearch(e)}
                                                                                                                        maxDropdownHeight={200}
                                                                                                                        

                                                                                                                        // value={cityData}
                                                                                                                    />

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        {/* <div className="mb-3 w-full items-center">
                                                                                                            <label htmlFor="locationMap" className="form-input-sm-label">{t('LocationMapLink')}</label>

                                                                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                                                <textarea
                                                                                                                    {...register("locationMap")}
                                                                                                                    className={`form-input-sm-control-textarea ${errors.locationMap ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                                    name="locationMap"
                                                                                                                    id="locationMap"
                                                                                                                    placeholder={t("EnterLocationMapLink")}
                                                                                                                    rows={20}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                        </ScrollArea>
                                                                        <footer className="relative mt-1 border border-t">
                                                                            <div className="mr-3">
                                                                                <div className="text-right pt-0.5 mb-0.5">
                                                                                    {/*<button onClick={next} className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">*/}
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm"
                                                                                    >
                                                                                        <span>{t('Next')}</span>
                                                                                        {
                                                                                            spinner ? ( <AiOutlineLoading3Quarters className={'ml-1 animate-spin'} size={16}/> ) : (<FiArrowRight size={16} className={'ml-1'}/>)
                                                                                        }
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </footer>
                                                                    </form>

                                                                </Tab.Panel>
                                                                    <Tab.Panel>
                                                                    </Tab.Panel>
                                                                    <Tab.Panel>
                                                                    </Tab.Panel>
                                                                </Tab.Panels>
                                                            </Tab.Group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};
export default Create;
