import React, {useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiOutlineExclamation,
    HiOutlineOfficeBuilding,
    HiChevronLeft, HiArrowCircleLeft, HiEye, HiArrowCircleRight,HiOutlinePlus
} from "react-icons/hi";
import {useParams, useNavigate, Link} from "react-router-dom";
import {FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters, AiOutlineMinus} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import {Button, Group, ScrollArea, Select, Stepper, Switch, Text, useMantineTheme} from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import {useLocation} from "react-router";
import ViewClient from "./modal/ViewClient";
import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {IconPhoto, IconUpload, IconX} from "@tabler/icons";
import DesignationHistoryModel from "../client/modal/DesignationHistoryModel";


function Update() {

    const {state} = useLocation();
    const theme = useMantineTheme();


    const [active, setActive] = useState(state && state.type && state.type === 'list' ? 0 : 1);
    const nextStep = () => setActive((current) => (current < 5 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    const {clientId} = useParams();
    const navigate = useNavigate();
    const {roles, token} = JSON.parse(localStorage.getItem('user'));
    const {t, i18n} = useTranslation();

    const fullFormHeight = localStorage.getItem('fullFormHeight');

    const [clickDiseabled,setClickDiseabled] = useState(false)
    const [client, setClient] = useState({});
    const [clientDetails, setClientDetails] = useState({});
    const [designationsWiseRate, setDesignationsWiseRate] = useState([]);
    const [designations, setDesignation] = useState([]);
    const [recall, setRecall] = useState(false);


    //Submit Spinner Init
    const [spinner, setSpinner] = useState(false);
    const [imageUpload, setImageUpload] = useState(false);


    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [stateData, setStateData] = useState('');
    const [cityData, setCityData] = useState('');
    const [companyStatusData, setCompanyStatusData] = useState('');
    const [positionData, setPositionData] = useState('');
    const [personInChargePositionData, setPersonInChargePositionData] = useState('');
    const [personInChargeBillingPositionData, setPersonInChargeBillingPositionData] = useState('');


    const [validationDesignation, setValidationDesignation] = useState([]);
    const [validationRate, setValidationRate] = useState([]);
    const [designationHistoryModel,setDesignationHistoryModel] = useState(false)
    const [designationHistoryID,setDesignationHistoryID] = useState(null)
    const [designationHistoryExtendId,setDesignationHistoryExtendId] = useState(null)

    const viewport = useRef(null);
    const scrollToTop = () => viewport.current.scrollTo({ top: 0, behavior: 'smooth' });

    useEffect(() => {
        //get client details
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/${clientId}/client/details`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            }
        })
            .then(res => {
                setImageUpload(false)
                setClient(res.data.data);
                setClientDetails(res.data.data.clientDetails);
                setCountryData(res.data.data.country)
                setStateData(res.data.data.state)
                setCityData(res.data.data.city)
                setCompanyStatusData(res.data.data.companyStatus)
                setPositionData(res.data.data.clientDetails[0]?.position)
                setPersonInChargePositionData(res.data.data.clientDetails[1]?.position)
                setPersonInChargeBillingPositionData(res.data.data.clientDetails[2]?.position)
                setValue('companyName', res.data.data.name)
                setValue('phone', res.data.data.phoneNumber)
                setValue('email', res.data.data.email)
                setValue('secondary_email', res.data.data.secondaryEmail)
                setValue('companyStatus', res.data.data.companyStatus)
                setValue('address', res.data.data.address)
                setValue('country', res.data.data.country)
                setValue('state', res.data.data.state)
                setValue('city', res.data.data.city)
                setValue('zipCode', res.data.data.zipCode)
                setValue('position', res.data.data.clientDetails[0]?.position)
                setValue('personInChargePosition', res.data.data.clientDetails[1]?.position)
                setValue('personInChargeBillingPosition', res.data.data.clientDetails[2]?.position)


                // get state data
                axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/state/dropdown?country_id=${res.data.data.country}`,
                    {headers: {"Authorization": `Bearer ${token}`}})
                    .then(res => {
                        if (res.data.status === 200) {
                            let stateDropdown =
                                res.data.data.map((type, index) => {
                                    return ({
                                        'label': type.name, 'value': type.id
                                    })
                                })
                            setStates(stateDropdown)

                        }
                    });

                let dataDrop = res.data.data.city && res.data.data.cityName ? [{
                    id: Number(res.data.data.city),
                    name: res.data.data.cityName
                }].map((type, index) => {
                    return ({
                        'label': type.name, 'value': type.id
                    })
                }) : []
                setCityDropdown(dataDrop)
            });


        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setCountries(res.data.data);
            });

        // get designations
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/master-data`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "type_slug": "designation"
            }
        })
            .then(res => {
                setDesignation(res.data.data);
            });
    }, [imageUpload]);


    //get list of designation wise rate
    useEffect(() => {
        // get designations wise rate
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/designation-wise-rate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "userId": clientId,
                "type": 'client'
            }
        })
            .then(res => {
                if (res.data.data.length>0) {
                    if (res.data.data[0].designationId && res.data.data[0].designationId != null) {
                        setValue('validatePosition', 'ok')
                        setError("validatePosition", null);
                    }
                }else{
                    setValue('validatePosition', null)
                }
                setDesignationsWiseRate(res.data.data);
            });


    }, [recall]);


    const updateDesignationRate = (id, property, value) => {
        if (property=='designationId'){
            if (Object.keys(validationRate).length>0){
                setValue('validatePosition','ok')
                setError("validatePosition", null);
            }
            setValidationDesignation({...validationDesignation,[value] : value}) ;
        }
        if (property=='rate'){
            if (Object.keys(validationDesignation).length>0){
                setValue('validatePosition','ok')
                setError("validatePosition", null);
            }
            setValidationRate({...validationRate,[value] : value}) ;
        }
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/designation-wise-rate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                id,
                property,
                value
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setRecall(!recall)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const newDesignationWiseRate = () => {

        var table = document.getElementById("designationTable");
        var lastRowIndex = table.rows.length - 2;
        let callApi = false;

        if (lastRowIndex === 0) {
            callApi = true;
        } else {
            // var designation = document.getElementById("designation_"+lastRowIndex);
            var designation = document.getElementById("designation_0");
            var designationValue = designation.value;
            var designationText = designation.options[designation.selectedIndex].text;
            if (!designationValue || designationValue === '') {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('Choose_designation_in_row_01')),
                    message: ('Error'),
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
            } else {
                /* var hourRate = document.getElementById("hourRate_0").value;
                 if (!hourRate || hourRate == '') {
                     showNotification({
                         id: 'load-data',
                         loading: true,
                         title: (t('Enter_hour_rate_in_row_01')),
                         message: ('Error'),
                         autoClose: 1000,
                         disallowClose: true,
                         color: 'red',
                     });
                 } else {*/
                var approveRate = document.getElementById("approveRate_0").value;
                if (!approveRate || approveRate == '') {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Enter_approve_rate_in_row_01')),
                        message: ('Error'),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'red',
                    });
                } else {
                    callApi = true;
                }
                // }
            }
        }
        if (callApi) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/designation-wise-rate`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    userId: clientId
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        setRecall(!recall)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    }

    const removeDesignationWiseRate = (id) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/designation-wise-rate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                id
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setRecall(!recall)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    let countryDropdown =
        countries.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })
    const [countryData, setCountryData] = useState('');
    const [citySearchValue, setCitySearchValue] = useState('');
    const [cityDropdown, setCityDropdown] = useState([]);

    const CityDataHandelForSearch = (e) => {
        setCitySearchValue(e);
        if (citySearchValue.length > 1) {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/city/dropdown-with-keyword?country_id=${countryData}&keyword=${citySearchValue}&state_id=${stateData}`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
            })
                .then(res => {
                    if (res.status === 200) {
                        let data =
                            res.data.data.map((head, index) => {
                                return ({
                                    'label': head.name, 'value': head.id
                                })
                            })
                        setCityDropdown(data);
                    }
                })
        }
    }
    const CountryDataHandel = (e) => {
        setCountryData(e);
        setStates([])
        setValue('state', null)
        setStateData('')


        setCityDropdown([])
        setValue('city', null)
        setCityData('')

        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/state/dropdown?country_id=${e}`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    let stateDropdown =
                        res.data.data.map((type, index) => {
                            return ({
                                'label': type.name, 'value': type.id
                            })
                        })
                    setStates(stateDropdown)
                }
            });

        setValue('country', e)
        if (e == '' || e == null) {
            setError("country", Yup.string().required(t("ChooseCountry")).nullable());
        } else {
            setError("country", null);
        }
    }


    const StateDataHandel = (e) => {
        setCityDropdown([])
        setValue('city', null)
        setCityData('')
        setStateData(e);
        setValue('state', e)
    }

    const CityDataHandel = (e) => {
        setCityData(e);
        setValue('city', e)
        if (e == '' || e == null) {
            setError("city", Yup.string().required(t("ChooseCity")).nullable());
        } else {
            setError("city", null);
        }
    }

    const [companyStatus, setCompanyStatus] = useState([]);
    let companyStatusDropdown = [];
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=company-status&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setCompanyStatus(res.data.data);
                }

            });
    }, []);

    if (companyStatus) {
        companyStatusDropdown =
            companyStatus.map((type, index) => {
                return ({
                    'label': type.name === 'Client'?'Active':type.name, 'value': type.id
                })
            })
    }


    // on change company status data get
    const CompanyStatusDataHandel = (e) => {
        setCompanyStatusData(e);
        setValue('companyStatus', e)
        if (e == '' || e == null) {
            setError("companyStatus", Yup.string().required(t("ChooseCompanyStatus")).nullable());
        } else {
            setError("companyStatus", null);
        }
    }
    // End get company status dropdown


    // start get position dropdown
    const [positions, setPositions] = useState([]);
    let positionDropdown = [];
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=position&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setPositions(res.data.data);
                }

            });
    }, []);

    if (positions) {
        positionDropdown =
            positions.map((type, index) => {
                return ({
                    'label': type.name, 'value': type.id
                })
            })
    }


    // on change position data get
    const PositionDataHandel = (e) => {
        setPositionData(e);

        setValue('position', e)
        if (e == '' || e == null) {
            setError("position", Yup.string().required(t("ChoosePosition")).nullable());
        } else {
            setError("position", null);
        }
    }
    // End get position dropdown

    // on change person in charge position data get
    const PersonInChargePositionDataHandel = (e) => {
        setPersonInChargePositionData(e);
        setValue('personInChargePosition', e)
    }
    // End get person in charge position dropdown

    // on change person in charge billing position data get
    const PersonInChargeBillingPositionDataHandel = (e) => {
        setPersonInChargeBillingPositionData(e);
        setValue('personInChargeBillingPosition', e)
    }
    // End get person in charge billing position dropdown


    // start get Currency dropdown
    const [currencyData, setCurrencyData] = useState('');
    const [currencies, setCurrency] = useState([]);
    let currencyDropdown = [];
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=currency&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setCurrency(res.data.data);
                }

            });
    }, []);

    if (currencies) {
        currencyDropdown =
            currencies.map((type, index) => {
                return ({
                    'label': type.name, 'value': type.id
                })
            })
    }


    // on change department data get
    const CurrencyDataHandel = (e) => {
        setCurrencyData(e);
    }
    // End get department dropdown

    const [showModal, setShowModal] = useState(false);

    //Form validation
    const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
    const onlyNumber = /^[0-9]+$/

    const validationSchemaFirstStep = Yup.object().shape({
        companyName: Yup.string().required(t("EnterCompanyName")),
        email: Yup.string().email(t('EnterValidEmail')).required(t("EnterEmail")),
        secondary_email: Yup.string().email(t('EnterValidEmail')).required(t("EnterEmail")),

        phone: Yup.string().required(t("EnterMobileNumber")).matches(phoneRegExp, t("PhoneNumberNotValid")),
        companyStatus: Yup.string().required(t("ChooseCompanyStatus")),
        address: Yup.string().required(t("EnterAddress")),
        country: Yup.string().required(t("ChooseCountry")).nullable(),
        city: Yup.string().required(t("ChooseCity")).nullable(),
        zipCode: Yup.string().required(t("EnterZipCode")).matches(onlyNumber, t("InvalidZipCode")).nullable(),
    });

    const validationSchemaThirdStep = Yup.object().shape({
        contactPersonFirstName: Yup.string().required(t("EnterFirstName")).nullable(),
        contactPersonLastName: Yup.string().required(t("EnterLastName")).nullable(),
        contactPersonPhone: Yup.string().required(t("EnterPhone")).matches(phoneRegExp, t("PhoneNumberNotValid")).nullable(),
        contactPersonEmail: Yup.string().email(t('EnterValidEmail')).required(t("EnterEmail")),
        position: Yup.string().required(t("ChoosePosition")),

        PersonInChargeEmail: Yup.string().email(t('HtmlValidEmail')),

        PersonInChargePhone: Yup.string().when("PersonInChargePhone", (value) => {
            if (value) {
                return Yup.string().matches(phoneRegExp, t("PhoneNumberNotValid"));
            }
        }),
    }, [
        ["PersonInChargePhone", "PersonInChargePhone"],
    ]);

    const validationSchemaFourthStep = Yup.object().shape({
        PersonInChargeBillingEmail: Yup.string().email(t('HtmlValidEmail')),
        BillingEmail: Yup.string().email(t('EnterValidEmail')),
        PersonInChargeBillingPhone: Yup.string().when("PersonInChargeBillingPhone", (value) => {
            if (value) {
                return Yup.string().matches(phoneRegExp, t("PhoneNumberNotValid"));
            }
        }),
        BillingPhone: Yup.string().when("BillingPhone", (value) => {
            if (value) {
                return Yup.string().matches(phoneRegExp, t("PhoneNumberNotValid"));
            }
        }),
    }, [
        ["PersonInChargeBillingPhone", "PersonInChargeBillingPhone"],
        ["BillingPhone", "BillingPhone"],
    ]);

    const validationSchemaTwoStep = Yup.object().shape({
        validatePosition: Yup.string().required(t("ChooseAtLeastOnePosition")).nullable(),
    });

    let formOptions = {resolver: yupResolver(validationSchemaFirstStep)};
    if (active === 1) {
        formOptions = {resolver: yupResolver(validationSchemaTwoStep)};
    }
    if (active === 2) {
        formOptions = {resolver: yupResolver(validationSchemaThirdStep)};
    }
    if (active === 3) {
        formOptions = {resolver: yupResolver(validationSchemaFourthStep)};
    }
    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState, setValue, setError} = useForm(formOptions);
    const {errors} = formState;


    //Form Data Submit
    function formSubmit(data) {
        setClickDiseabled(true)
        if (data) {
            const formData = {
                companyName: data.companyName ? data.companyName : client.name,
                email: client.email,
                secondary_email: data.secondary_email ? data.secondary_email : client.secondary_email,
                phone: data.phone ? data.phone : client.phoneNumber,
                website: data.website ? data.website : client.website,
                companyStatus: companyStatusData ? companyStatusData : client.companyStatus,
                companyBillingAddress: data.CompanyBillingAddress ? data.CompanyBillingAddress : client.billingAddress,
                remark: data.remark ? data.remark : client.remark,

                address: data.address ? data.address : client.address,
                streetAddress: data.streetAddress ? data.streetAddress : client.streetAddress,
                zipCode: data.zipCode ? data.zipCode : client.zipCode,
                country: countryData ? countryData : client.country,
                state: stateData ? stateData : client.state,
                city: cityData ? cityData : client.city,

                clientInfo: [
                    {
                        first_name: data.contactPersonFirstName ? data.contactPersonFirstName : (clientDetails[0] && clientDetails[0].firstName?clientDetails[0].firstName:null),
                        middle_name: data.contactPersonMiddleName ? data.contactPersonMiddleName :(clientDetails[0] && clientDetails[0].middleName?clientDetails[0].middleName:null),
                        last_name: data.contactPersonLastName ? data.contactPersonLastName : (clientDetails[0] && clientDetails[0].lastName?clientDetails[0].lastName:null),
                        phone: data.contactPersonPhone ? data.contactPersonPhone : (clientDetails[0] && clientDetails[0].phone?clientDetails[0].phone:null),
                        email: data.contactPersonEmail ? data.contactPersonEmail : (clientDetails[0] && clientDetails[0].email?clientDetails[0].email:null),
                        position: positionData ? positionData : (clientDetails[0] && clientDetails[0].position?clientDetails[0].position:null),
                        flag: 'contact-person'
                    },
                    {
                        first_name: data.PersonInChargeFirstName ? data.PersonInChargeFirstName : (clientDetails[1] && clientDetails[1].firstName?clientDetails[1].firstName:null),
                        middle_name: data.PersonInChargeMiddleName ? data.PersonInChargeMiddleName : (clientDetails[1] && clientDetails[1].middleName?clientDetails[1].middleName:null),
                        last_name: data.PersonInChargeLastName ? data.PersonInChargeLastName : (clientDetails[1] && clientDetails[1].lastName?clientDetails[1].lastName:null),
                        phone: data.PersonInChargePhone ? data.PersonInChargePhone : (clientDetails[1] && clientDetails[1].phone?clientDetails[1].phone:null),
                        email: data.PersonInChargeEmail ? data.PersonInChargeEmail : (clientDetails[1] && clientDetails[1].email?clientDetails[1].email:null),
                        position: personInChargePositionData ? personInChargePositionData : '',
                        flag: 'person-in-charge'
                    },
                    {
                        first_name: data.PersonInChargeBillingFirstName ? data.PersonInChargeBillingFirstName : (clientDetails[2] && clientDetails[2].firstName?clientDetails[2].firstName:null),
                        middle_name: data.PersonInChargeBillingMiddleName ? data.PersonInChargeBillingMiddleName : (clientDetails[2] && clientDetails[2].middleName?clientDetails[2].middleName:null),
                        last_name: data.PersonInChargeBillingLastName ? data.PersonInChargeBillingLastName : (clientDetails[2] && clientDetails[2].lastName?clientDetails[2].lastName:null),
                        phone: data.PersonInChargeBillingPhone ? data.PersonInChargeBillingPhone : (clientDetails[2] && clientDetails[2].phone?clientDetails[2].phone:null),
                        email: data.PersonInChargeBillingEmail ? data.PersonInChargeBillingEmail : (clientDetails[2] && clientDetails[2].email?clientDetails[2].email:null),
                        position: personInChargeBillingPositionData ? personInChargeBillingPositionData : '',
                        flag: 'person-in-charge-billing'
                    },
                    {
                        bank_name: data.BankName ? data.BankName : (clientDetails[3] && clientDetails[3].bankName?clientDetails[3].bankName:null),
                        branch_name: data.BranchName ? data.BranchName : (clientDetails[3] && clientDetails[3].branchName?clientDetails[3].branchName:null),
                        phone: data.BillingPhone ? data.BillingPhone : (clientDetails[3] && clientDetails[3].phone?clientDetails[3].phone:null),
                        email: data.BillingEmail ? data.BillingEmail : (clientDetails[3] && clientDetails[3].email?clientDetails[3].email:null),
                        address: data.BillingAddress ? data.BillingAddress : (clientDetails[3] && clientDetails[3].address?clientDetails[3].address:null),
                        website: data.BillingWebsite ? data.BillingWebsite : (clientDetails[3] && clientDetails[3].website?clientDetails[3].website:null),
                        currency: currencyData ? currencyData : (clientDetails[3] && clientDetails[3].currency?clientDetails[3].currency:null),
                        flag: 'billing'
                    }
                ]
            };
            setSpinner(true);
            axios({
                method: 'put',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/${clientId}/update-client`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                data: formData
            })
                .then(res => {
                    if (res.status === 200) {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('UpdatedSuccessfully')),
                            message: (t('DataLoad')),
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });
                        setSpinner(false)

                        setTimeout(function () {
                            if (active == 4) {
                                navigate('/client')
                            } else {
                                setClickDiseabled(false)
                                scrollToTop()
                                nextStep()
                            }
                            setSpinner(false)
                        }, 1000)
                    } else {
                        setClickDiseabled(false)
                        console.log(res)
                    }
                })
                .catch(function (error) {
                    setClickDiseabled(false)
                    setSpinner(false)
                })

        }
    }

    const clientProfileImageUpload = (file) => {
        const data = {
            file: file,
            client_id: clientId
        };
        if (data) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/client/profile-image/upload`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `multipart/form-data`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                data: data
            })
                .then(res => {
                    if (res.status === 200) {
                        setImageUpload(true)
                    } else {
                        console.log(res)
                    }

                })
                .catch(function (error) {
                    setSpinner(false)
                })
        }
    }

    const newAttachment = () => {
        var table = document.getElementById("attachmentTable");
        var lastRowIndex = table.rows.length - 2;
        let callApi = false;

        if (lastRowIndex == 0) {
            callApi = true;
        } else {
            var lebel = document.getElementById("label_0");
            var lebelValue = lebel.value;

            if (!lebelValue || lebelValue == '') {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('EnterLabelInRow01')),
                    message: ('Error'),
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
            } else {
                var attachment = document.getElementById("attachment_0").value;
                if (!attachment || attachment == '') {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('SelectAttachmentInRow01')),
                        message: ('Error'),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'red',
                    });
                } else {
                    callApi = true;
                }
            }
        }
        if (callApi) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/new/attachment`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    user_id: clientId
                }
            })
                .then(res => {
                    if (res.status === 201) {
                        setImageUpload(!imageUpload)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    }

    const candidateAttachmentUpload = (attachmentID, property, file) => {
        const data = {
            id: attachmentID,
            property: property,
            file: file
        };
        if (data) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/attachment/upload`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `multipart/form-data`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                data: data
            })
                .then(res => {
                    if (res.status === 200) {
                        setImageUpload(!imageUpload)
                    } else {
                        console.log(res)
                    }

                })
                .catch(function (error) {
                    setSpinner(false)
                })
        }
    }
    const removeCandidateAttachment = (id) => {
        // console.log(id)
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/attachment-delete`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                id
            }
        })
            .then(res => {
                if (res.status === 202) {
                    setImageUpload(!imageUpload)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const isClient = roles.find(role => {
        return role === "ROLE_CLIENT";
    })

    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Client')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>
                                    {t('ManageClientInformation')}
                                </sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <Link to='/client'
                                      className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={12} className={'mr-1'}/>{t('Back')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border ">

                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form" autoComplete="off">
                                                        <ScrollArea style={{height: fullFormHeight}} scrollbarSize={4} viewportRef={viewport} type="always">
                                                            <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full bg-gray-200">
                                                                <Stepper active={active} breakpoint="sm" color="green" size="sm">
                                                                    <Stepper.Step label={t('CompanyInformation')} description={t('CompanyDetails')}>
                                                                        <div className="md:grid md:grid-cols-5 md:gap-6 ">
                                                                            <div className="md:col-span-1">
                                                                                <div className="px-4 sm:px-0 ml-2">
                                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("CompanyInformation")}</h3>
                                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                                        {t('CompanyDetails')}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mt-5 md:col-span-4 md:mt-0">
                                                                                <div
                                                                                    className="shadow   sm:rounded-md">
                                                                                    <div
                                                                                        className="bg-gray-50 px-4 py-5 sm:p-6">
                                                                                        <div
                                                                                            className="mb-3 w-full items-center">
                                                                                            <label htmlFor="CompanyName"
                                                                                                   className="form-input-sm-label-required">{t('CompanyName')}</label>
                                                                                            <div
                                                                                                className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                {errors.companyName?.type === 'required' &&
                                                                                                <div
                                                                                                    className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation
                                                                                                        size={16}
                                                                                                        className={'mr-2'}/> {errors.companyName?.message}
                                                                                                </div>}
                                                                                                <input
                                                                                                    type="text"
                                                                                                    {...register("companyName")}
                                                                                                    className={`form-input-sm-control`}
                                                                                                    name="companyName"
                                                                                                    id="companyName"
                                                                                                    placeholder={t("EnterCompanyName")}
                                                                                                    // defaultValue={client.name ? client.name : ''}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                            <div
                                                                                                className="mb-3 w-1/2 items-center">
                                                                                                <label htmlFor="email"
                                                                                                       className="form-input-sm-label-required">{t('Email')}</label>
                                                                                                <div
                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                    {errors.email?.type === 'required' &&
                                                                                                        <div
                                                                                                            className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation
                                                                                                                size={16}
                                                                                                                className={'mr-2'}/> {errors.email?.message}
                                                                                                        </div>}

                                                                                                    {errors.email?.type === 'email' &&
                                                                                                        <div
                                                                                                            className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation
                                                                                                                size={16}
                                                                                                                className={'mr-2'}/> {errors.email?.message}
                                                                                                        </div>}

                                                                                                    <input
                                                                                                        {...register("email")}
                                                                                                        type="text"
                                                                                                        className={`form-input-sm-control ${errors.email ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                        name="email"
                                                                                                        id="email"
                                                                                                        placeholder={t("EnterEmail")}
                                                                                                        // defaultValue={client.email ? client.email : ''}
                                                                                                        readOnly={true}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            <div
                                                                                                className="mb-3 w-1/2 items-center">

                                                                                                <label
                                                                                                    htmlFor="secondary_email"
                                                                                                    className="form-input-sm-label-required">{t('SecondaryEmail')}</label>
                                                                                                <div
                                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                    {errors.secondary_email?.type === 'required' &&
                                                                                                        <div
                                                                                                            className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation
                                                                                                                size={16}
                                                                                                                className={'mr-2'}/> {errors.secondary_email?.message}
                                                                                                        </div>}

                                                                                                    {errors.secondary_email?.type === 'email' &&
                                                                                                        <div
                                                                                                            className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation
                                                                                                                size={16}
                                                                                                                className={'mr-2'}/> {errors.secondary_email?.message}
                                                                                                        </div>}

                                                                                                    {errors.secondary_email && errors.secondary_email.message != null &&
                                                                                                        <div
                                                                                                            className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation
                                                                                                                size={16}
                                                                                                                className={'mr-2'}/> {errors.secondary_email?.message}
                                                                                                        </div>}

                                                                                                    <input
                                                                                                        {...register("secondary_email")}
                                                                                                        type="text"
                                                                                                        className={`form-input-sm-control ${errors.secondary_email ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                        name="secondary_email"
                                                                                                        onKeyUp={(e) => {
                                                                                                            var emailCheck = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
                                                                                                            if (emailCheck.test(e.target.value)) {
                                                                                                                setValue('secondary_email', e.target.value)
                                                                                                                setError('secondary_email', null)
                                                                                                            } else {
                                                                                                                setError('secondary_email', {
                                                                                                                    type: 'custom',
                                                                                                                    message: t("EnterValidEmail")
                                                                                                                });
                                                                                                            }
                                                                                                        }}
                                                                                                        id="email"
                                                                                                        placeholder={t("EnterEmail")}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            <div
                                                                                                className="mb-3 w-1/2 items-center">
                                                                                                <label htmlFor="phone"
                                                                                                       className="form-input-sm-label-required">{t('Phone')}</label>
                                                                                                <div
                                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                    {/*{errors.phone?.type === 'required' &&*/}
                                                                                                    {errors.phone &&
                                                                                                        <div
                                                                                                            className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation
                                                                                                                size={16}
                                                                                                                className={'mr-2'}/> {errors.phone?.message}
                                                                                                        </div>}

                                                                                                    <input
                                                                                                        {...register("phone")}
                                                                                                        type="text"
                                                                                                        className={`form-input-sm-control ${errors.phone ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                        name="phone"
                                                                                                        id="phone"
                                                                                                        placeholder={t("EnterPhoneNumber")}
                                                                                                        onChange={(e) => {
                                                                                                            console.log('validation')
                                                                                                        }}
                                                                                                        // defaultValue={client.phoneNumber ? client.phoneNumber : ''}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>


                                                                                        <div
                                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                            <div
                                                                                                className="mb-3 w-1/2 items-center">
                                                                                                <label htmlFor="Website"
                                                                                                       className="form-input-sm-label">{t('Website')}</label>
                                                                                                <div
                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                    <input
                                                                                                        {...register("website")}
                                                                                                        type="text"
                                                                                                        className={`form-input-sm-control border-gray-300 focus:border-blue-600 `}
                                                                                                        name="website"
                                                                                                        id="website"
                                                                                                        placeholder={t("EnterWebsiteLink")}
                                                                                                        // defaultValue={client.website ? client.website : ''}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            <div
                                                                                                className="mb-3 w-1/2 items-center">
                                                                                                <label
                                                                                                    htmlFor="CompanyStatus"
                                                                                                    className="form-input-sm-label-required">{t('CompanyStatus')}</label>
                                                                                                <div
                                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                    {errors.companyStatus?.type === 'required' &&
                                                                                                        <div
                                                                                                            className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation
                                                                                                                size={16}
                                                                                                                className={'mr-2'}/> {errors.companyStatus?.message}
                                                                                                        </div>}
                                                                                                    {errors.companyStatus?.type === 'typeError' &&
                                                                                                        <div
                                                                                                            className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation
                                                                                                                size={16}
                                                                                                                className={'mr-2'}/> {t("ChooseCompanyStatus")}
                                                                                                        </div>}
                                                                                                    <Select
                                                                                                        {...register("companyStatus")}
                                                                                                        placeholder={t('ChooseCompanyStatus')}
                                                                                                        searchable
                                                                                                        clearable
                                                                                                        allowDeselect
                                                                                                        nothingFound="No options"
                                                                                                        data={companyStatusDropdown}
                                                                                                        transition="pop-top-left"
                                                                                                        transitionDuration={80}
                                                                                                        transitionTimingFunction="ease"
                                                                                                        onChange={CompanyStatusDataHandel}
                                                                                                        value={companyStatusData}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div
                                                                                            className="mb-3 w-full items-center">
                                                                                            <label htmlFor="Remark"
                                                                                                   className="form-input-sm-label">{t('Remark')}</label>
                                                                                            <div
                                                                                                className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                <textarea
                                                                                                    {...register("remark")}
                                                                                                    className={`form-input-sm-control candidate-remark`}
                                                                                                    type="textarea"
                                                                                                    placeholder={t("EnterRemark")}
                                                                                                    rows={5}
                                                                                                    defaultValue={client.remark ? client.remark : ''}
                                                                                                />
                                                                                            </div>
                                                                                        </div>


                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                                            <div className="md:col-span-1">
                                                                                <div className="px-4 sm:px-0 ml-2">
                                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("CompanyLocation")}</h3>
                                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                                        {t('CompanyLocationDetails')}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mt-5 md:col-span-4 md:mt-0">
                                                                                <div
                                                                                    className="shadow   sm:rounded-md">
                                                                                    <div
                                                                                        className="bg-gray-50 px-4 py-5 sm:p-6">
                                                                                        <div
                                                                                            className="mb-3 w-full items-center">
                                                                                            <label htmlFor="address"
                                                                                                   className="form-input-sm-label-required">{t('CompanyAddress')}</label>
                                                                                            <div
                                                                                                className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                {errors.address?.type === 'required' &&
                                                                                                <div
                                                                                                    className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation
                                                                                                        size={16}
                                                                                                        className={'mr-2'}/> {errors.address?.message}
                                                                                                </div>}
                                                                                                <textarea
                                                                                                    {...register("address")}
                                                                                                    className={`form-input-sm-control ${errors.address ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'}`}
                                                                                                    name="address"
                                                                                                    placeholder={t("EnterAddress")}
                                                                                                    defaultValue={client.address ? client.address : ''}
                                                                                                    rows={14}
                                                                                                />
                                                                                            </div>
                                                                                        </div>

                                                                                        <div
                                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                            <div
                                                                                                className=" w-1/3 items-center">
                                                                                                <label htmlFor="country"
                                                                                                       className="form-input-sm-label-required">{t('Country')}</label>
                                                                                                <div
                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                                    {errors.country?.type === 'required' &&
                                                                                                    <div
                                                                                                        className="form-input-sm-error">
                                                                                                        <HiOutlineExclamation
                                                                                                            size={16}
                                                                                                            className={'mr-2'}></HiOutlineExclamation> {errors.country?.message}
                                                                                                    </div>}

                                                                                                    <Select
                                                                                                        {...register("country")}
                                                                                                        placeholder={t('ChooseCountry')}
                                                                                                        className={`${errors.country ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                        searchable
                                                                                                        clearable
                                                                                                        allowDeselect
                                                                                                        nothingFound="No options"
                                                                                                        data={countryDropdown}
                                                                                                        transition="pop-top-left"
                                                                                                        transitionDuration={80}
                                                                                                        transitionTimingFunction="ease"
                                                                                                        onChange={CountryDataHandel}
                                                                                                        value={countryData}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            <div
                                                                                                className=" w-1/3 items-center">
                                                                                                <label htmlFor="state"
                                                                                                       className="form-input-sm-label">{t('State')}</label>
                                                                                                <div
                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                    <Select
                                                                                                        {...register("state")}
                                                                                                        placeholder={t('ChooseState')}
                                                                                                        searchable
                                                                                                        clearable
                                                                                                        allowDeselect
                                                                                                        nothingFound="No options"
                                                                                                        data={states}
                                                                                                        transition="pop-top-left"
                                                                                                        transitionDuration={80}
                                                                                                        transitionTimingFunction="ease"
                                                                                                        onChange={StateDataHandel}
                                                                                                        value={stateData}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            <div
                                                                                                className=" w-1/3 items-center">
                                                                                                <label htmlFor="city"
                                                                                                       className="form-input-sm-label-required">{t('City')}</label>
                                                                                                <div
                                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                    {errors.city?.type === 'required' &&
                                                                                                    <div
                                                                                                        className="form-input-sm-error">
                                                                                                        <HiOutlineExclamation
                                                                                                            size={16}
                                                                                                            className={'mr-2'}></HiOutlineExclamation> {errors.city?.message}
                                                                                                    </div>}

                                                                                                    <Select
                                                                                                        {...register("city")}
                                                                                                        placeholder={t("ChooseCity")}
                                                                                                        className={`${errors.city ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                        searchable
                                                                                                        clearable
                                                                                                        allowDeselect
                                                                                                        nothingFound={t('PleaseTypeCityName')}
                                                                                                        withAsterisk
                                                                                                        data={cityDropdown}
                                                                                                        transition="pop-top-left"
                                                                                                        transitionDuration={80}
                                                                                                        transitionTimingFunction="ease"
                                                                                                        onSearchChange={CityDataHandelForSearch}
                                                                                                        onChange={e => {
                                                                                                            CityDataHandel(e)
                                                                                                        }}
                                                                                                        value={cityData}
                                                                                                        searchValue={citySearchValue}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                                            <div
                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                <label htmlFor="zipCode"
                                                                                                       className="form-input-sm-label-required">{t('ZipCode')}</label>
                                                                                                <div
                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                    {errors.zipCode &&
                                                                                                    <div
                                                                                                        className="form-input-sm-error">
                                                                                                        <HiOutlineExclamation
                                                                                                            size={16}
                                                                                                            className={'mr-2'}/> {errors.zipCode?.message}
                                                                                                    </div>}
                                                                                                    <input
                                                                                                        {...register("zipCode")}
                                                                                                        type="text"
                                                                                                        className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                                                                        name="zipCode"
                                                                                                        id="zipCode"
                                                                                                        placeholder={t("EnterZipCode")}
                                                                                                        defaultValue={client.zipCode ? client.zipCode : ''}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Stepper.Step>

                                                                    <Stepper.Step label={t('DesignationInformation')} description={t('ClientDesignationDetails')}>
                                                                        {/*                  CANDIDATE DESIGNATION Start                     */}

                                                                        <div
                                                                            className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                                            <div className="md:col-span-1">
                                                                                <div className="px-4 sm:px-0 ml-2 ml-3">
                                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("DesignationInformation")}</h3>
                                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                                        {t('ClientDesignationDetails')}
                                                                                    </p>
                                                                                </div>
                                                                            </div>

                                                                            <div className="mt-5 md:col-span-4 md:mt-0">
                                                                                <div className="shadow   sm:rounded">
                                                                                    <div className="space-y-1 bg-gray-50 sm:p-6">
                                                                                        <div className="mx-auto justify-items-right w-full">
                                                                                            <section className="-mx-0.5">
                                                                                                <div className="flex flex-col justify-center h-full">

                                                                                                    <div className=" w-full items-center">
                                                                                                        <div className="relative mt-1  sm:col-span-2 sm:mt-0">
                                                                                                            {errors.validatePosition?.type === 'required' &&
                                                                                                            <div className="form-input-sm-error">
                                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.validatePosition?.message}
                                                                                                            </div>}
                                                                                                            <input
                                                                                                                {...register("validatePosition")}
                                                                                                                type="hidden"
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <table className="table-full w-full" id="designationTable">
                                                                                                        <thead
                                                                                                            className="text-xs font-semibold text-black bg-gray-200">
                                                                                                        <tr>
                                                                                                            <th className="p-2">
                                                                                                                <div
                                                                                                                    className="font-semibold text-left">{t('Designation')}</div>
                                                                                                            </th>

                                                                                                            <th className="p-2">
                                                                                                                <div className="font-semibold text-left">{t('HourlyRate')}</div>
                                                                                                            </th>
                                                                                                            <th align={`right`}
                                                                                                                className="p-2">

                                                                                                            </th>
                                                                                                        </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                        {designationsWiseRate && designationsWiseRate.length > 0 && designationsWiseRate.map((designation, index) => {
        return (
            <tr className={"bg-gray-100"}
                key={designation.id}>
                <td>
                    {
                        designation.udTotal > 0 ?
                            <span
                                className={`designation-margin-left`}>{designation.designationName}</span>

                            :
                            <select
                                name="designation"
                                className={`form-input-sm-control`}
                                onChange={(e) => updateDesignationRate(designation.id, "designationId", Number(e.target.value))}
                                defaultValue={designation.designationId}
                                id={`designation_${index}`}
                            >
                                <option
                                    value="">{t('SelectDesignation')}</option>

                                {designations && designations.map((item, index) => {
                                    return <option
                                        value={item.id}
                                        key={item.id}>{item.name}</option>;

                                })}
                            </select>
                    }

                </td>


                <td width={100}>
                    {
                        isClient ? (designation.rate ? designation.rate : '') : (
                            <input
                                onBlur={(e) => updateDesignationRate(designation.id, "rate", Number(e.target.value))}
                                type="text"
                                className={`form-input-sm-control`}
                                name="hourRate"
                                id={`approveRate_${index}`}
                                defaultValue={designation.rate ? designation.rate : ''}
                                onKeyPress={(event) => {
                                    if (/[^0-9\.]/g.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}

                            />
                        )
                    }
                </td>
                <td align={"right"}
                    className="pr-2"
                    width={150}>


                    {
                        designation.udTotal > 0 ?
                            <>
                            <button
                                type="button"
                                className=" ml-2 px-1.5 py-1.5 bg-blue-500 hover:bg-blue-600 text-white text-xs"
                                onClick={ e => {
                                    setDesignationHistoryExtendId(designation.userExtendedInfoId)
                                    setDesignationHistoryID(designation.designationId)
                                    setDesignationHistoryModel(true)
                                } }
                            >
                                <HiEye size={12}/>
                            </button>
                            <Switch
                                className="mb-2 ml-2 inline-block"
                                size="md"
                                radius="xs"
                                color="indigo"
                                onLabel="Active"
                                offLabel="Inactive"
                                checked={designation.status ? true : false}
                                onChange={(event) => {
                                    updateDesignationRate(designation.id, 'status', event.currentTarget.checked)
                                }}
                            />
                            </>
                            :
                            <>
                            <button
                                type="button"
                                className=" ml-2 px-1.5 py-1.5 bg-blue-500 hover:bg-blue-600 text-white text-xs"
                                onClick={ e => {
                                    setDesignationHistoryExtendId(designation.userExtendedInfoId)
                                    setDesignationHistoryID(designation.designationId)
                                    setDesignationHistoryModel(true)
                                } }
                            >
                                <HiEye size={12}/>
                            </button>
                            <button
                                type="button"
                                className="ml-2 px-1.5 py-1.5 bg-red-500 hover:bg-red-600 text-white text-xs"
                                onClick={e => removeDesignationWiseRate(designation.id)}
                            >
                                <AiOutlineMinus
                                    size={12}
                                />
                            </button>
                            </>
                    }
                </td>
            </tr>
        );
                                                                                                        })}

                                                                                                        <tr>
                                                                                                            <td colSpan={"4"}>
                                                                                                                <a className=" mt-3 flex font-bold text-indigo-500 cursor-pointer hover:text-indigo-800 hover:underline"
                                                                                                                   onClick={e => newDesignationWiseRate()}>
                                                                                                                    <HiOutlinePlus size={16} className={'mr-1 inline-block add-more-margin-top'}/> {t('AddMore')}
                                                                                                                </a>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                                <br/>
                                                                                            </section>

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        {/*                  CANDIDATE DESIGNATION END                     */}
                                                                    </Stepper.Step>
                                                                    <Stepper.Step label={t('OfficeContact')} description={t('OfficeContactDetails')}>
                                                                        {clientDetails && clientDetails.length > 0 && clientDetails.map((details, index) => {
                                                                            if (details.flag === 'contact-person') {
                                                                                return (
                                                                                    <div key={details.flag}>
                                                                                        <div
                                                                                            className="md:grid md:grid-cols-5 md:gap-6 mt-2"
                                                                                            key={index}>
                                                                                            <div
                                                                                                className="md:col-span-1">
                                                                                                <div
                                                                                                    className="px-4 sm:px-0 ml-2">
                                                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("ContactPersonInformation")}</h3>
                                                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                                                        {t('ContactPersonDetails')}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>


                                                                                            <div
                                                                                                className="mt-5 md:col-span-4 md:mt-0">
                                                                                                <div
                                                                                                    className="shadow   sm:rounded-md">
                                                                                                    <div
                                                                                                        className="bg-gray-50 px-4 py-5 sm:p-6">


                                                                                                        <div
                                                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="contactPersonLastName"
                                                                                                                    className="form-input-sm-label-required">{t('LastName')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                                    {errors.contactPersonLastName?.type === 'required' &&
                                                                                                                    <div
                                                                                                                        className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation
                                                                                                                            size={16}
                                                                                                                            className={'mr-2'}/> {errors.contactPersonLastName?.message}
                                                                                                                    </div>}

                                                                                                                    <input
                                                                                                                        {...register("contactPersonLastName")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control ${errors.contactPersonLastName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                                        name="contactPersonLastName"
                                                                                                                        id="contactPersonLastName"
                                                                                                                        placeholder={t("EnterLastName")}
                                                                                                                        defaultValue={details.lastName ? details.lastName : ''}

                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="contactPersonMiddleName"
                                                                                                                    className="form-input-sm-label">{t('MiddleName')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                                    <input
                                                                                                                        {...register("contactPersonMiddleName")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control`}
                                                                                                                        name="contactPersonMiddleName"
                                                                                                                        id="contactPersonMiddleName"
                                                                                                                        placeholder={t("EnterMiddleName")}
                                                                                                                        defaultValue={details.middleName ? details.middleName : ''}

                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="contactPersonFirstName"
                                                                                                                    className="form-input-sm-label-required">{t('FirstName')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                    {errors.contactPersonFirstName?.type === 'required' &&
                                                                                                                    <div
                                                                                                                        className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation
                                                                                                                            size={16}
                                                                                                                            className={'mr-2'}/> {errors.contactPersonFirstName?.message}
                                                                                                                    </div>}

                                                                                                                    <input
                                                                                                                        {...register("contactPersonFirstName")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control ${errors.contactPersonFirstName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                                        name="contactPersonFirstName"
                                                                                                                        id="contactPersonFirstName"
                                                                                                                        placeholder={t("EnterFirstName")}
                                                                                                                        defaultValue={details.firstName ? details.firstName : ''}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div
                                                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="contactPersonPhone"
                                                                                                                    className="form-input-sm-label-required">{t('Phone')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                                    {/*{errors.contactPersonPhone?.type === 'required' &&*/}
                                                                                                                    {errors.contactPersonPhone &&
                                                                                                                    <div
                                                                                                                        className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation
                                                                                                                            size={16}
                                                                                                                            className={'mr-2'}/> {errors.contactPersonPhone?.message}
                                                                                                                    </div>}
                                                                                                                    <input
                                                                                                                        type="text"
                                                                                                                        {...register("contactPersonPhone")}
                                                                                                                        className={`form-input-sm-control`}
                                                                                                                        name="contactPersonPhone"
                                                                                                                        id="contactPersonPhone"
                                                                                                                        placeholder={t("EnterPhone")}
                                                                                                                        defaultValue={details.phone ? details.phone : ''}

                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="contactPersonEmail"
                                                                                                                    className="form-input-sm-label-required">{t('Email')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                                    {errors.contactPersonEmail?.type === 'required' &&
                                                                                                                    <div
                                                                                                                        className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation
                                                                                                                            size={16}
                                                                                                                            className={'mr-2'}/> {errors.contactPersonEmail?.message}
                                                                                                                    </div>}
                                                                                                                    {errors.contactPersonEmail?.type === 'email' &&
                                                                                                                    <div
                                                                                                                        className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation
                                                                                                                            size={16}
                                                                                                                            className={'mr-2'}/> {errors.contactPersonEmail?.message}
                                                                                                                    </div>}

                                                                                                                    <input
                                                                                                                        {...register("contactPersonEmail")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                                                                                        name="contactPersonEmail"
                                                                                                                        id="contactPersonEmail"
                                                                                                                        placeholder={t("EnterEmail")}
                                                                                                                        defaultValue={details.email ? details.email : ''}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="Position"
                                                                                                                    className="form-input-sm-label-required">{t('Position')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                    {errors.position?.type === 'required' &&
                                                                                                                    <div
                                                                                                                        className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation
                                                                                                                            size={16}
                                                                                                                            className={'mr-2'}/> {errors.position?.message}
                                                                                                                    </div>}
                                                                                                                    {errors.position?.type === 'typeError' &&
                                                                                                                    <div
                                                                                                                        className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation
                                                                                                                            size={16}
                                                                                                                            className={'mr-2'}/> {t("ChoosePosition")}
                                                                                                                    </div>}
                                                                                                                    <Select
                                                                                                                        {...register("position")}
                                                                                                                        placeholder={t('ChoosePosition')}
                                                                                                                        searchable
                                                                                                                        clearable
                                                                                                                        allowDeselect
                                                                                                                        nothingFound="No options"
                                                                                                                        data={positionDropdown}
                                                                                                                        transition="pop-top-left"
                                                                                                                        transitionDuration={80}
                                                                                                                        transitionTimingFunction="ease"
                                                                                                                        onChange={PositionDataHandel}
                                                                                                                        value={positionData}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            if (details.flag === 'person-in-charge') {
                                                                                return (
                                                                                    <div key={details.flag}>
                                                                                        <div
                                                                                            className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                                                            <div
                                                                                                className="md:col-span-1">
                                                                                                <div
                                                                                                    className="px-4 sm:px-0 ml-2">
                                                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("PersonInChargeInformation")}</h3>
                                                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                                                        {t('PersonInChargeDetails')}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>


                                                                                            <div
                                                                                                className="mt-5 md:col-span-4 md:mt-0">
                                                                                                <div
                                                                                                    className="shadow   sm:rounded-md">
                                                                                                    <div
                                                                                                        className="bg-gray-50 px-4 py-5 sm:p-6">


                                                                                                        <div
                                                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="PersonInChargeLastName"
                                                                                                                    className="form-input-sm-label">{t('LastName')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                                                    <input
                                                                                                                        {...register("PersonInChargeLastName")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control `}
                                                                                                                        name="PersonInChargeLastName"
                                                                                                                        id="PersonInChargeLastName"
                                                                                                                        placeholder={t("EnterLastName")}
                                                                                                                        defaultValue={details.lastName ? details.lastName : ''}

                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="PersonInChargeMiddleName"
                                                                                                                    className="form-input-sm-label">{t('MiddleName')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                                    <input
                                                                                                                        {...register("PersonInChargeMiddleName")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control`}
                                                                                                                        name="PersonInChargeMiddleName"
                                                                                                                        id="PersonInChargeMiddleName"
                                                                                                                        placeholder={t("EnterMiddleName")}
                                                                                                                        defaultValue={details.middleName ? details.middleName : ''}

                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="PersonInChargeFirstName"
                                                                                                                    className="form-input-sm-label">{t('FirstName')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                                                    <input
                                                                                                                        {...register("PersonInChargeFirstName")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control`}
                                                                                                                        name="PersonInChargeFirstName"
                                                                                                                        id="PersonInChargeFirstName"
                                                                                                                        placeholder={t("EnterFirstName")}
                                                                                                                        defaultValue={details.firstName ? details.firstName : ''}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>


                                                                                                        <div
                                                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="PersonInChargePhone"
                                                                                                                    className="form-input-sm-label">{t('Phone')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                                    {errors.PersonInChargePhone &&
                                                                                                                    <div
                                                                                                                        className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation
                                                                                                                            size={16}
                                                                                                                            className={'mr-2'}/> {errors.PersonInChargePhone?.message}
                                                                                                                    </div>}
                                                                                                                    <input
                                                                                                                        type="text"
                                                                                                                        {...register("PersonInChargePhone")}
                                                                                                                        className={`form-input-sm-control`}
                                                                                                                        name="PersonInChargePhone"
                                                                                                                        id="PersonInChargePhone"
                                                                                                                        placeholder={t("EnterPhone")}
                                                                                                                        defaultValue={details.phone ? details.phone : ''}

                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="PersonInChargeEmail"
                                                                                                                    className="form-input-sm-label">{t('Email')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                                    {errors.PersonInChargeEmail?.type === 'email' &&
                                                                                                                    <div
                                                                                                                        className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation
                                                                                                                            size={16}
                                                                                                                            className={'mr-2'}/> {errors.PersonInChargeEmail?.message}
                                                                                                                    </div>}

                                                                                                                    <input
                                                                                                                        {...register("PersonInChargeEmail")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                                                                                        name="PersonInChargeEmail"
                                                                                                                        id="PersonInChargeEmail"
                                                                                                                        placeholder={t("EnterEmail")}
                                                                                                                        defaultValue={details.email ? details.email : ''}

                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="PersonInChargePosition"
                                                                                                                    className="form-input-sm-label">{t('Position')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                    <Select
                                                                                                                        {...register("personInChargePosition")}
                                                                                                                        placeholder={t('ChoosePosition')}
                                                                                                                        searchable
                                                                                                                        clearable
                                                                                                                        allowDeselect
                                                                                                                        nothingFound="No options"
                                                                                                                        data={positionDropdown}
                                                                                                                        transition="pop-top-left"
                                                                                                                        transitionDuration={80}
                                                                                                                        transitionTimingFunction="ease"
                                                                                                                        onChange={PersonInChargePositionDataHandel}
                                                                                                                        value={personInChargePositionData}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }

                                                                        })}
                                                                    </Stepper.Step>

                                                                    <Stepper.Step label={t('OfficeBillingInfo')} description={t('OfficeBillingDetails')}>
                                                                        {clientDetails && clientDetails.length > 0 && clientDetails.map((details, index) => {

                                                                            if (details.flag === 'person-in-charge-billing') {
                                                                                return (
                                                                                    <div key={details.flag}>
                                                                                        <div
                                                                                            className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                                                            <div
                                                                                                className="md:col-span-1">
                                                                                                <div
                                                                                                    className="px-4 sm:px-0 ml-2">
                                                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("PersonInChargeBillingInformation")}</h3>
                                                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                                                        {t('PersonInChargeBillingDetails')}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>


                                                                                            <div
                                                                                                className="mt-5 md:col-span-4 md:mt-0">
                                                                                                <div
                                                                                                    className="shadow   sm:rounded-md">
                                                                                                    <div
                                                                                                        className="bg-gray-50 px-4 py-5 sm:p-6">


                                                                                                        <div
                                                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="PersonInChargeBillingLastName"
                                                                                                                    className="form-input-sm-label">{t('LastName')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                                                    <input
                                                                                                                        {...register("PersonInChargeBillingLastName")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control`}
                                                                                                                        name="PersonInChargeBillingLastName"
                                                                                                                        id="PersonInChargeBillingLastName"
                                                                                                                        placeholder={t("EnterLastName")}
                                                                                                                        defaultValue={details.lastName ? details.lastName : ''}

                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="PersonInChargeBillingMiddleName"
                                                                                                                    className="form-input-sm-label">{t('MiddleName')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                                    <input
                                                                                                                        {...register("PersonInChargeBillingMiddleName")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control`}
                                                                                                                        name="PersonInChargeBillingMiddleName"
                                                                                                                        id="PersonInChargeBillingMiddleName"
                                                                                                                        placeholder={t("EnterMiddleName")}
                                                                                                                        defaultValue={details.middleName ? details.middleName : ''}

                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>


                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="PersonInChargeBillingFirstName"
                                                                                                                    className="form-input-sm-label">{t('FirstName')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                                                    <input
                                                                                                                        {...register("PersonInChargeBillingFirstName")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control`}
                                                                                                                        name="PersonInChargeBillingFirstName"
                                                                                                                        id="PersonInChargeBillingFirstName"
                                                                                                                        placeholder={t("EnterFirstName")}
                                                                                                                        defaultValue={details.firstName ? details.firstName : ''}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>


                                                                                                        <div
                                                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="PersonInChargeBillingPhone"
                                                                                                                    className="form-input-sm-label">{t('Phone')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                                    {errors.PersonInChargeBillingPhone &&
                                                                                                                    <div
                                                                                                                        className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation
                                                                                                                            size={16}
                                                                                                                            className={'mr-2'}/> {errors.PersonInChargeBillingPhone?.message}
                                                                                                                    </div>}
                                                                                                                    <input
                                                                                                                        type="text"
                                                                                                                        {...register("PersonInChargeBillingPhone")}
                                                                                                                        className={`form-input-sm-control`}
                                                                                                                        name="PersonInChargeBillingPhone"
                                                                                                                        id="PersonInChargeBillingPhone"
                                                                                                                        placeholder={t("EnterPhone")}
                                                                                                                        defaultValue={details.phone ? details.phone : ''}

                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="PersonInChargeBillingEmail"
                                                                                                                    className="form-input-sm-label">{t('Email')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                                                    {errors.PersonInChargeBillingEmail &&
                                                                                                                    <div
                                                                                                                        className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation
                                                                                                                            size={16}
                                                                                                                            className={'mr-2'}/> {errors.PersonInChargeBillingEmail?.message}
                                                                                                                    </div>}
                                                                                                                    <input
                                                                                                                        {...register("PersonInChargeBillingEmail")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                                                                                        placeholder={t("EnterEmail")}
                                                                                                                        defaultValue={details.email ? details.email : ''}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div
                                                                                                                className="mb-3 w-1/3 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="PersonInChargePosition"
                                                                                                                    className="form-input-sm-label">{t('Position')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                    <Select
                                                                                                                        {...register("personInChargeBillingPosition")}
                                                                                                                        placeholder={t('ChoosePosition')}
                                                                                                                        searchable
                                                                                                                        clearable
                                                                                                                        allowDeselect
                                                                                                                        nothingFound="No options"
                                                                                                                        data={positionDropdown}
                                                                                                                        transition="pop-top-left"
                                                                                                                        transitionDuration={80}
                                                                                                                        transitionTimingFunction="ease"
                                                                                                                        onChange={PersonInChargeBillingPositionDataHandel}
                                                                                                                        value={personInChargeBillingPositionData}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }

                                                                            if (details.flag === 'billing') {
                                                                                return (
                                                                                    <div key={details.flag}>
                                                                                        <div
                                                                                            className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                                                            <div
                                                                                                className="md:col-span-1">
                                                                                                <div
                                                                                                    className="px-4 sm:px-0 ml-2">
                                                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("BillingInformation")}</h3>
                                                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                                                        {t('BillingDetails')}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>


                                                                                            <div
                                                                                                className="mt-5 md:col-span-4 md:mt-0">
                                                                                                <div
                                                                                                    className="shadow   sm:rounded-md">
                                                                                                    <div
                                                                                                        className="bg-gray-50 px-4 py-5 sm:p-6">


                                                                                                        <div
                                                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                                            <div
                                                                                                                className="mb-3 w-1/2 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="BankName"
                                                                                                                    className="form-input-sm-label">{t('BankName')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                                    {errors.BankName?.type === 'required' &&
                                                                                                                    <div
                                                                                                                        className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation
                                                                                                                            size={16}
                                                                                                                            className={'mr-2'}/> {errors.BankName?.message}
                                                                                                                    </div>}

                                                                                                                    <input
                                                                                                                        {...register("BankName")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control ${errors.BankName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                                        name="BankName"
                                                                                                                        id="BankName"
                                                                                                                        placeholder={t("EnterBankName")}
                                                                                                                        defaultValue={details.bankName ? details.bankName : ''}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>


                                                                                                            <div
                                                                                                                className="mb-3 w-1/2 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="BranchName"
                                                                                                                    className="form-input-sm-label">{t('BranchName')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                    {errors.BranchName?.type === 'required' &&
                                                                                                                    <div
                                                                                                                        className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation
                                                                                                                            size={16}
                                                                                                                            className={'mr-2'}/> {errors.BranchName?.message}
                                                                                                                    </div>}

                                                                                                                    <input
                                                                                                                        {...register("BranchName")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control ${errors.BranchName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                                        name="BranchName"
                                                                                                                        id="BranchName"
                                                                                                                        placeholder={t("EnterBranchName")}
                                                                                                                        defaultValue={details.branchName ? details.branchName : ''}

                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>


                                                                                                        <div
                                                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                                                            <div
                                                                                                                className="mb-3 w-1/2 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="BillingPhone"
                                                                                                                    className="form-input-sm-label">{t('Phone')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                                    {errors.BillingPhone &&
                                                                                                                    <div
                                                                                                                        className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation
                                                                                                                            size={16}
                                                                                                                            className={'mr-2'}/> {errors.BillingPhone?.message}
                                                                                                                    </div>}
                                                                                                                    <input
                                                                                                                        type="text"
                                                                                                                        {...register("BillingPhone")}
                                                                                                                        className={`form-input-sm-control ${errors.BillingPhone ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                                        name="BillingPhone"
                                                                                                                        id="BillingPhone"
                                                                                                                        placeholder={t("EnterPhone")}
                                                                                                                        defaultValue={details.phone ? details.phone : ''}

                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="mb-3 w-1/2 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="BillingEmail"
                                                                                                                    className="form-input-sm-label">{t('Email')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                    {errors.BillingEmail &&
                                                                                                                    <div
                                                                                                                        className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation
                                                                                                                            size={16}
                                                                                                                            className={'mr-2'}/> {errors.BillingEmail?.message}
                                                                                                                    </div>}

                                                                                                                    <input
                                                                                                                        {...register("BillingEmail")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control ${errors.BillingEmail ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                                        name="BillingEmail"
                                                                                                                        id="BillingEmail"
                                                                                                                        placeholder={t("EnterEmail")}
                                                                                                                        defaultValue={details.email ? details.email : ''}

                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div
                                                                                                            className="mb-3 w-full items-center">
                                                                                                            <label
                                                                                                                htmlFor="BillingAddress"
                                                                                                                className="form-input-sm-label">{t('Address')}</label>
                                                                                                            <div
                                                                                                                className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                {errors.BillingAddress?.type === 'required' &&
                                                                                                                <div
                                                                                                                    className="form-input-sm-error">
                                                                                                                    <HiOutlineExclamation
                                                                                                                        size={16}
                                                                                                                        className={'mr-2'}/> {errors.BillingAddress?.message}
                                                                                                                </div>}
                                                                                                                <input
                                                                                                                    {...register("BillingAddress")}
                                                                                                                    className={`form-input-sm-control ${errors.BillingAddress ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                                    name="BillingAddress"
                                                                                                                    placeholder={t("EnterAddress")}
                                                                                                                    defaultValue={details.address ? details.address : ''}

                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div
                                                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                                                                            <div
                                                                                                                className="mb-3 w-1/2 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="Currency"
                                                                                                                    className="form-input-sm-label">{t('Currency')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                                                    <Select
                                                                                                                        placeholder={t('ChooseCurrency')}
                                                                                                                        searchable
                                                                                                                        clearable
                                                                                                                        allowDeselect
                                                                                                                        nothingFound="No options"
                                                                                                                        data={currencyDropdown}
                                                                                                                        transition="pop-top-left"
                                                                                                                        transitionDuration={80}
                                                                                                                        transitionTimingFunction="ease"
                                                                                                                        onChange={CurrencyDataHandel}
                                                                                                                        value={currencyData ? currencyData : details.currency}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {/* <div
                                                                                                                className="mb-3 w-1/2 items-center">
                                                                                                                <label
                                                                                                                    htmlFor="BillingWebsite"
                                                                                                                    className="form-input-sm-label">{t('Website')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                                                    <input
                                                                                                                        {...register("BillingWebsite")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control border-gray-300 focus:border-blue-600`}
                                                                                                                        name="BillingWebsite"
                                                                                                                        id="BillingWebsite"
                                                                                                                        placeholder={t("EnterWebsiteLink")}
                                                                                                                        defaultValue={details.website ? details.website : ''}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div> */}
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        })}
                                                                    </Stepper.Step>

                                                                    <Stepper.Step label={t("ImageAndAttachmentInformation")} description={t('ImageAndAttachmentDetails')}>
                                                                        <div className="md:grid md:grid-cols-5 md:gap-6 mt-2 ">
                                                                            <div className="md:col-span-1">
                                                                                <div className="px-4 sm:px-0 ml-3">
                                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("ProfileInformation")}</h3>
                                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                                        {t('ProfileDetails')}
                                                                                    </p>
                                                                                </div>
                                                                            </div>

                                                                            <div className="mt-5 md:col-span-4 md:mt-0">
                                                                                <div className="shadow   sm:rounded">
                                                                                    <div
                                                                                        className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">

                                                                                        <div
                                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                            <div className=" w-1/2 items-center">
                                                                                                <Dropzone
                                                                                                    onDrop={(file) => clientProfileImageUpload(file[0])}
                                                                                                    onReject={(files) => console.log('rejected files', files)}
                                                                                                    // maxSize={3 * 1024 ** 2}
                                                                                                    accept={{
                                                                                                        'image/*': []
                                                                                                    }}
                                                                                                    multiple={false}
                                                                                                >
                                                                                                    <Group
                                                                                                        position="center"
                                                                                                        spacing="xl"
                                                                                                        style={{
                                                                                                            minHeight: 220,
                                                                                                            pointerEvents: 'none'
                                                                                                        }}>
                                                                                                        <Dropzone.Accept>
                                                                                                            <IconUpload
                                                                                                                size={50}
                                                                                                                stroke={1.5}
                                                                                                                color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                            />
                                                                                                        </Dropzone.Accept>
                                                                                                        <Dropzone.Reject>
                                                                                                            <IconX
                                                                                                                size={50}
                                                                                                                stroke={1.5}
                                                                                                                color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                            />
                                                                                                        </Dropzone.Reject>
                                                                                                        <Dropzone.Idle>
                                                                                                            <IconPhoto
                                                                                                                size={50}
                                                                                                                stroke={1.5}/>
                                                                                                        </Dropzone.Idle>

                                                                                                        <div>
                                                                                                            <Text
                                                                                                                size="xl"
                                                                                                                inline>
                                                                                                                {t('DragProfileImage')}
                                                                                                            </Text>
                                                                                                            <Text
                                                                                                                size="sm"
                                                                                                                color="dimmed"
                                                                                                                inline
                                                                                                                mt={7}>
                                                                                                                Attach
                                                                                                                as many
                                                                                                                files as
                                                                                                                you
                                                                                                                like,
                                                                                                                each
                                                                                                                file
                                                                                                                should
                                                                                                                not
                                                                                                                exceed
                                                                                                                5mb
                                                                                                            </Text>
                                                                                                        </div>
                                                                                                    </Group>
                                                                                                </Dropzone>
                                                                                            </div>

                                                                                            <div
                                                                                                className=" w-1/2 items-center">
                                                                                                <div
                                                                                                    className="rounded-t-lg bg-white px-6 pt-6 pb-4">
                                                                                                    <div>
                                                                                                        <ul role="list"
                                                                                                            className="mt-2">
                                                                                                            {client.profileImage ?
                                                                                                                <img
                                                                                                                    className="mx-auto h-1/2 w-1/2 mb-8"
                                                                                                                    src={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/client/profile/${client.profileImage}`}
                                                                                                                    alt="Simple Staffing"/> :
                                                                                                                <img
                                                                                                                    className="mx-auto h-1/2 w-1/2 mb-8"
                                                                                                                    src={"/assets/images/No_Image.jpg"}
                                                                                                                    alt="Simple Staffing"/>
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>


                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                                            <div className="md:col-span-1">
                                                                                <div className="px-4 sm:px-0 ml-3">
                                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t("AttachmentInformation")}</h3>
                                                                                    <p className="mt-1 text-sm text-gray-600">
                                                                                        {t('AttachmentDetails')}
                                                                                    </p>
                                                                                </div>
                                                                            </div>

                                                                            <div className="mt-5 md:col-span-4 md:mt-0">
                                                                                <div className="shadow   sm:rounded">
                                                                                    <div
                                                                                        className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                                                                        <div
                                                                                            className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                            <div
                                                                                                className="mx-auto justify-items-right w-full">
                                                                                                <section
                                                                                                    className="-mx-0.5">
                                                                                                    <div
                                                                                                        className="flex flex-col justify-center h-full">
                                                                                                        <table
                                                                                                            className="table-full w-full"
                                                                                                            id="attachmentTable">
                                                                                                            <thead
                                                                                                                className="text-xs font-semibold  text-black bg-gray-200">
                                                                                                            <tr>

                                                                                                                <th className="p-2">
                                                                                                                    <div
                                                                                                                        className="font-semibold text-left">{t('AttachmentLebel')}</div>
                                                                                                                </th>
                                                                                                                <th className="p-2">
                                                                                                                    <div
                                                                                                                        className="font-semibold text-left">{t('FileName')}</div>
                                                                                                                </th>
                                                                                                                <th className="p-2">
                                                                                                                    <div
                                                                                                                        className="font-semibold text-left">{t('Attachment')}</div>
                                                                                                                </th>
                                                                                                                <th align={`right`}
                                                                                                                    className="p-2">

                                                                                                                </th>
                                                                                                            </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                            {client.clientAttachment && client.clientAttachment.length > 0 && client.clientAttachment.map((attachment, index) => {
                                                                                                                return (
                                                                                                                    <tr className={"bg-gray-100"}
                                                                                                                        key={attachment.id}>

                                                                                                                        <td>
                                                                                                                            <input
                                                                                                                                onBlur={(e) => candidateAttachmentUpload(attachment.id, "label", e.target.value)}
                                                                                                                                type="text"
                                                                                                                                className={`form-input-sm-control`}
                                                                                                                                name="name"
                                                                                                                                id={`label_${index}`}
                                                                                                                                defaultValue={attachment.label ? attachment.label : ''}

                                                                                                                            />
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <input
                                                                                                                                type="hidden"
                                                                                                                                id={`attachment_${index}`}
                                                                                                                                value={attachment && attachment.attachment ? attachment.attachment : ''}/>
                                                                                                                            <span
                                                                                                                                className="ml-6">
                                                                <a href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/user-attachment/${attachment.attachment}`}
                                                                   target="new">
                    {attachment && attachment.attachment ? attachment.attachment : ''}
                </a>

                                                            </span>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <Dropzone
                                                                                                                                onDrop={(file) => candidateAttachmentUpload(attachment.id, 'file', file[0])}
                                                                                                                                onReject={(files) => console.log('rejected files', files)}
                                                                                                                                // accept={[MIME_TYPES.csv, MIME_TYPES.pdf, MIME_TYPES.doc, MIME_TYPES.docx, MIME_TYPES.xls, MIME_TYPES.xlsx, MIME_TYPES.ppt, MIME_TYPES.pptx, MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.mp4]}
                                                                                                                                accept={[MIME_TYPES.pdf, MIME_TYPES.doc, MIME_TYPES.docx, MIME_TYPES.png, MIME_TYPES.jpeg,MIME_TYPES.jpeg ]}
                                                                                                                                multiple={false}
                                                                                                                            >
                                                                                                                                <Group
                                                                                                                                    position="center"
                                                                                                                                    spacing="sm"
                                                                                                                                    style={{pointerEvents: 'none'}}>
                                                                                                                                    <Dropzone.Accept>
                                                                                                                                        <IconUpload
                                                                                                                                            stroke={1.5}
                                                                                                                                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                                                        />
                                                                                                                                    </Dropzone.Accept>
                                                                                                                                    <Dropzone.Reject>
                                                                                                                                        <IconX
                                                                                                                                            stroke={1.5}
                                                                                                                                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                                                        />
                                                                                                                                    </Dropzone.Reject>
                                                                                                                                    <Dropzone.Idle>
                                                                                                                                        <IconPhoto
                                                                                                                                            stroke={1.5}/>
                                                                                                                                    </Dropzone.Idle>

                                                                                                                                    <div>
                                                                                                                                        <Text
                                                                                                                                            size="sm"
                                                                                                                                            inline>
                                                                                                                                            {t('DragAttachment')}
                                                                                                                                        </Text>
                                                                                                                                    </div>
                                                                                                                                </Group>
                                                                                                                            </Dropzone>
                                                                                                                        </td>


                                                                                                                        <td align={"right"}
                                                                                                                            className="pr-2"
                                                                                                                            width={20}>
                                                                                                                            <button
                                                                                                                                type="button"
                                                                                                                                className="px-1.5 py-1.5 bg-red-500 hover:bg-red-600 text-white text-xs"
                                                                                                                                onClick={e => removeCandidateAttachment(attachment.id)}
                                                                                                                            >
                                                                                                                                <AiOutlineMinus
                                                                                                                                    size={12}
                                                                                                                                    className={'mr-1'}/>
                                                                                                                            </button>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                );
                                                                                                            })}
                                                                                                            <tr>
                                                                                                                <td colSpan={"4"}>
                                                                                                                    <a className=" mt-3 flex font-bold text-indigo-500 cursor-pointer hover:text-indigo-800 hover:underline"
                                                                                                                       onClick={e => newAttachment()}>
                                                                                                                        <HiOutlinePlus size={16} className={'mr-1 inline-block add-more-margin-top'}/> {t('AddMore')}
                                                                                                                    </a>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                    <br/>
                                                                                                </section>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </Stepper.Step>
                                                                </Stepper>
                                                            </div>
                                                        </ScrollArea>

                                                        <footer className="relative mt-1">
                                                            <div className="mr-3 flow-root">
                                                                <div className="float-left pt-0.5 mb-0.5">

    {
        active != 0 ? <Button onClick={(e) => {
            setShowModal(true);
        }} className="px-6 py-2 ml-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-blue-600 bg-blue-700 items-center border  rounded text-sm mr-2"><HiEye
            size={16}
            className={'mr-2'}/><span> {t('Preview')}</span></Button>: ''
    }

                                                                </div>
                                                                <div className="float-right  pt-0.5 mb-0.5">
                                                                    <Button onClick={(e) => {
                                                                        setRecall(true)
                                                                        prevStep()
                                                                    }} className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-red-600 bg-red-700 items-center border  rounded text-sm mr-2">
                                                                        <HiArrowCircleLeft size={16} className={'mr-2'}/><span> {t('Previous')}</span></Button>

                                                                    <button type='submit'
                                                                            disabled={clickDiseabled}
                                                                            className="inline-flex items-center rounded bg-green-700 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-0">
                                                                        {spinner ? (
                                                                            <AiOutlineLoading3Quarters
                                                                                className={'mr-2 animate-spin'}
                                                                                size={16}/>
                                                                        ) : (<HiArrowCircleRight size={16}
                                                                                                 className={'mr-2'}/>)}
                                                                        <span>{active == 4 ? t('Submit') : t('Next')}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </footer>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {showModal ? <ViewClient setShowModal={setShowModal} clientID={client.id}/> : ''}
            {designationHistoryModel ? <DesignationHistoryModel
                setDesignationHistoryModel={setDesignationHistoryModel}
                designationHistoryExtendId={designationHistoryExtendId}
                designationHistoryID={designationHistoryID}
            /> : ''}
        </>
    )
}

export default Update