import React, {useEffect, useRef, useState} from 'react';

import {useTranslation} from "react-i18next";
import axios from "axios";
import {
    HiOutlineOfficeBuilding, HiOutlinePlus, HiChevronLeft
} from "react-icons/hi";
import {Link, useNavigate} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import { Box, Text } from "@mantine/core";
import { format } from 'date-fns';


const PER_PAGE = 20;

function CandidateIndex() {
    const {roles, token} = JSON.parse(localStorage.getItem('user'));

    const {t, i18n} = useTranslation();
    const [page, setPage] = useState(1);
    const [candidateList, setCandidateList] = useState([]);
    const [sortStatus, setSortStatus] = useState({ columnAccessor: 'createdAt', direction: 'desc' });
    const [totalCandidates, setTotalCandidates] = useState(0);
    const [fetching, setFetching] = useState(true);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/candidate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "per_page": PER_PAGE,
                "page": page,
                "entry_from": "CRM"
            }
        })
            .then(res => {
                setCandidateList(res.data.data)
                setTotalCandidates(res.data.total)
                setFetching(false);

            })
            .catch(function (error) {
                console.log(error)
            })
    }, [page, fetching, sortStatus]);

    const fullTableHeight = localStorage.getItem('fullTableHeight');

    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-between text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-3 font-lg font-bold clear-both relative text-indigo-500 w-full'}> {t('Candidates')}</span>
                        </div>
                    </div>
                    <div className={"right flex mr-2"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <Link to ='/crm'
                                      className="inline-flex items-center ml-3 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={12} className={'mr-1'}/>{t('Back')}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <Link to ='/crm/register-candidate'
                                      className="inline-flex items-center ml-3 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiOutlinePlus size={12} className={'mr-1'}/>{t('Add')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full px-4">
                    <Box sx={{height: fullTableHeight}}>
                    <DataTable
                        withBorder
                        striped
                        styles={(theme) => ({
                            root: {
                                border: '1px solid #e0e7ff',
                                borderRadius: 2,
                            },
                            header: {
                                background: '#e0e7ff',
                                backgroundColor: "#e0e7ff",
                                color: theme.colors.indigo[6],
                            },
                            pagination: {
                                color: theme.colors.gray[3],
                                paddingTop : "5px",
                                paddingBottom : "5px"
                            },
                            item: {
                                '&[data-active]': {
                                    backgroundImage: theme.fn.gradient({ from: 'red', to: 'yellow' }),
                                },
                            },
                        })}
                        records={candidateList}
                        // records={records}
                        columns={[
                            {
                                accessor: '',
                                title: (t('SN.')),
                                width: 45,
                                render: (candidate) => (candidateList.indexOf(candidate) + 1) + PER_PAGE * (page - 1)
                            },
                            {
                                accessor: 'name',
                                title: (t('Name')),
                                sortable: true
                            },
                            {
                                accessor: 'email',
                                sortable: true
                            },
                            {
                                accessor: 'phoneNumber',
                                title: (t('PhoneNumber')),
                                sortable: true
                            },
                            {
                                accessor: 'employeeStatus',
                                title : (t('Status')),
                                width: 100,
                                // render: ({enable}) => enable ? 'Enable' : 'Disable'
                            },{
                                accessor: 'cityName',
                                title : (t('City')),
                            },{
                                accessor: 'createdAt',
                                title: (t('JoinDate')),
                                render: ({ createdAt }) => createdAt ? format(new Date(createdAt.date), 'MM/dd/Y') : '',
                            },
                        ]}
                        totalRecords={totalCandidates}
                        recordsPerPage={PER_PAGE}
                        page={page}
                        onPageChange={(p) => setPage(p)}
                        fetching={fetching}
                        loaderSize="sm"
                        loaderColor="indigo"
                        loaderBackgroundBlur={2}
                        sortStatus={sortStatus}
                        onSortStatusChange={setSortStatus}
                    />
                    </Box>

                </div>

            </main>

        </>
    )
}

export default CandidateIndex