import {Dialog, Switch, Transition} from '@headlessui/react'
import React, {Fragment, useEffect, useState} from 'react'
import {
    HiOutlineExclamation,
    HiOutlinePlus,
} from "react-icons/hi";
import {FiAirplay, FiSave, FiX} from "react-icons/fi";
import {MdOutlineMonitorWeight} from "react-icons/md";
import {TbCurrencyTaka} from "react-icons/tb";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";



function ModalForm({isSubmit, setIsSubmit}) {
    // Language Init
    const { t, i18n } = useTranslation();

    //Add Model Init
    let [isOpen, setIsOpen] = useState(false);

    //Submit Spinner Init
    const [spinner,SetSpinner] = useState(false);

    //Add Model close function
    function closeModal() {
        setIsOpen(false)
    }

    //Add Model Open function
    function openModal() {
        setIsOpen(true)
    }

    // GET Item Type dropdown Value
    const [itemTypes, setItemType] = useState([]);

    //Status init
    const [enabled, setEnabled] = useState(false);


    //Form validation
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t("NameIsRequired")),
        price: Yup.string().required(t('PriceIsRequired')),
        unit: Yup.string().required(t('UnitIsRequired')),
        item: Yup.string().required(t('SelectItemType'))
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    //Form Data Submit
    function formSubmit(data) {
        SetSpinner(true);
        const formData = {
            items_name: data.name,
            prices: data.price,
            item_types: data.item,
            item_unit: data.unit,
            status : enabled?1:0,
        }
    }

    return (
        <>

            <button type={'button'}  onClick={openModal}
                     className="inline-flex items-center px-2 py-1 bg-indigo-100 hover:bg-indigo-200 text-indigo-700 text-xs">
                <HiOutlinePlus  size={12} className={'mr-1'} ></HiOutlinePlus>{t("Add")}
            </button>

            <Transition  show={isOpen} as={Fragment} >
                <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        {t('CreateNewItem')}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                            <div className={'pt-3 sm:border-t sm:border-gray-200 '}>

                                                {/* Name Section */}
                                                <div className="mb-3 xl:w-96">
                                                    <label htmlFor="first-name" className="inline-flex  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">{t('Name')}
                                                        <span className="text-red-500 flex mt-1 ml-1">*</span></label>

                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                        {errors.name?.type === 'required' && <div className="flex bg-red-50 text-red-900 text-xs border border-1 border-red-300  py-1 px-2 right-0 absolute z-30 w-full -top-6">
                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                        </div>}
                                                        <div
                                                            className="absolute right-0 text-gray-400 flex items-center pr-3 h-full cursor-pointer">
                                                            <FiAirplay size={16}></FiAirplay>
                                                        </div>
                                                        <input
                                                            {...register("name")}
                                                            type="text"
                                                            className={`form-control block w-full px-2 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none focus:ring-0 ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                            name="name"
                                                            placeholder={t("EnterItemName")}
                                                        />
                                                    </div>
                                                </div>

                                                {/* Price Section*/}
                                                <div className="mb-3 xl:w-96">
                                                    <label htmlFor="first-name" className="inline-flex  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">{t('Price')}
                                                        <span className="text-red-500 flex mt-1 ml-1">*</span></label>
                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                        {errors.price?.type === 'required' && <div
                                                            className="flex bg-red-50 text-red-900 text-xs border border-1 border-red-300  py-1 px-2 right-0 absolute z-30 w-full -top-6">
                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.price?.message}
                                                        </div>}
                                                        <div
                                                            className="absolute right-0 text-gray-400 flex items-center pr-3 h-full cursor-pointer">
                                                            <TbCurrencyTaka size={16}></TbCurrencyTaka>
                                                        </div>
                                                        <input
                                                            {...register("price")}
                                                            type="text"
                                                            className={`form-control block w-full px-2 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none focus:ring-0 ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                            name="price"
                                                            placeholder={t("EnterPrice")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-3 w-full items-center">
                                                    <label htmlFor="first-name" className="form-input-sm-label-required">{t('Name')}
                                                    </label>
                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                        <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                            {errors.country?.type === 'required' && <div
                                                                className="form-input-sm-error">
                                                                <HiOutlineExclamation  size={16} className={'mr-2'}></HiOutlineExclamation> {errors.country?.message}
                                                            </div>}
                                                            <select
                                                                {...register("country")}
                                                                name="country"
                                                                autoComplete="country-name"
                                                                className={`form-input-sm-control ${errors.country ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}>
                                                                <option value="">Select Country</option>
                                                                <option value="United States">United States</option>
                                                                <option value="Canada">Canada</option>
                                                                <option value="Mexico">Mexico</option>
                                                            </select>
                                                            <ModalForm></ModalForm>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Item Type Section */}


                                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                                                    <label htmlFor="acceptTerms" className="form-check-label">&nbsp;</label>
                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0 text-right">
                                                        <button type='reset'
                                                                className={'px-2 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                onClick={closeModal}><FiX size={16} className={'mr-2'}></FiX>
                                                            <span>{t('Cancel')}</span>
                                                        </button>
                                                        <button type='submit' className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                            {spinner ? (<AiOutlineLoading3Quarters className={'mr-2 animate-spin'}
                                                                                                   size={16}></AiOutlineLoading3Quarters>
                                                            ) : (<FiSave size={16} className={'mr-2'}></FiSave>)}
                                                            <span>{t('Save')}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default ModalForm