import React, {Fragment, useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiOutlineExclamation,
    HiOutlineOfficeBuilding, HiOutlinePlus,
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {FiArrowDownRight, FiArrowRight, FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters, AiOutlineFileSearch, AiOutlineMinus} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import {useNavigate} from "react-router";
import {Select, Tooltip, ScrollArea, LoadingOverlay} from "@mantine/core";
import {Tab} from "@headlessui/react";
import {format} from 'date-fns';
import {showNotification} from "@mantine/notifications";



function Update(){
    const {roles, token, loggedUserId, domainId} = JSON.parse(localStorage.getItem('user'));
    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })
    const hasRoleClient = roles.find(role => {
        return role === 'ROLE_CLIENT';
    })

    const navigate = useNavigate();

    const {t, i18n} = useTranslation();
    const [spinner, setSpinner] = useState(false);

    const [clients, setClients] = useState([]);

    const [startTimeMax, setStartTimeMax] = useState('');
    const [endTimeMin, setEndTimeMin] = useState('');

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [stateData, setStateData] = useState('');
    const [cityData, setCityData] = useState('');
    const [cities, setCities] = useState([]);

    const [validate,setValidate] = useState(false);
    const [country, setCountry] = useState('');
    const [showLoader, setShowLoader] = useState(false);



    const getCountries = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/countries/dropdown`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                setCountries(res.data.data);
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const [domainDetails, setDomaindetails] = useState({});
    const getDomainDetails = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/domains/${domainId}`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                console.log(res)
                setDomaindetails(res.data.data);
                setValue('country', res.data.data.country);
                setValue('address', res.data.data.address);
                setCountry(res.data.data.country);

            })
            .catch(function (error) {
                console.log(error)
            });
    }
    useEffect(() => {
        getCountries();
        getDomainDetails();
    }, []);

    let fullFormHeight = localStorage.getItem('fullFormHeight');
    fullFormHeight = fullFormHeight.slice(0, -2);
    fullFormHeight = fullFormHeight - 77;
    fullFormHeight = fullFormHeight + "px";

    //Form validation
    const validationSchema = Yup.object().shape({
        address: Yup.string().required(t('AddressIsRequired')),
        country: Yup.string().required(t('SelectCountry')),
    });


    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState, setValue} = useForm(formOptions);
    const {errors} = formState;




    //Form Data Submit
    function formSubmit(payload) {
        setSpinner(true);
        setShowLoader(true);

        console.log(payload)
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/domains/${domainId}`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: payload
        })
            .then(res => {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('Success')),
                    message: (t(res.data.message)),
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'green',
                });
                setSpinner(false);
                setShowLoader(false);


            })
            .catch(function (error) {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('Success')),
                    message: (t(error.response.data.message)),
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
                setSpinner(false);
                setShowLoader(false);
            });
    }




    return (
        <main className="w-full" >
            <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                <div className={"flex-1"}>
                    <div
                        className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                        <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Domain')}
                            <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('UpdateDomainInformation')}</sub>
                            </span>
                    </div>
                </div>
            </div>
            <div className="min-h-full w-full">

                <div className="flex w-full md:w-auto">
                    <div className="bg-red-100 flex-1">
                        <div className="min-w-0 flex-1 bg-white xl:flex">
                            <div className="bg-white lg:min-w-0 lg:flex-1">
                                <div className="h-full px-4 sm:px-6 lg:px-4">
                                    <div className="relative">
                                        <div className={'flex flex-1 overflow-hidden w-full'}>
                                            <div className="w-full mr-0 mx-auto border">
                                                <div>
                                                    <div className="hidden sm:block">
                                                        <div className="border-b border-gray-200">
                                                        <form id="horizontal-form" onSubmit={handleSubmit(formSubmit)}>
                                                            { <LoadingOverlay visible={showLoader} overlayBlur={2} loaderProps={{ size: 'sm', color: 'indigo' }} overlayColor="bg-indigo-100"/> }

                                                            <ScrollArea style={{ height: fullFormHeight }} scrollbarSize={4}>
                                                                <div className="py-3 relative flex flex-col min-w-0 break-words w-full border-0">
                                                                    <div className="mt-10 sm:mt-0">
                                                                        <div className="md:grid md:grid-cols-5 md:gap-6">
                                                                            <div className="md:col-span-1">
                                                                                <div className="ml-4 px-4 sm:px-0">
                                                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t('DomainInformation')}</h3>
                                                                                    {/* <p className="mt-1 text-sm text-gray-600">{t('EnterJobsLocationRelatedDetails')}</p> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className="mt-5 md:col-span-4 md:mt-0">
                                                                                <div className="shadow sm:overflow-hidden sm:rounded">
                                                                                    <div className="bg-gray-50 px-4 py-5 sm:p-6">
                                                                                        <div className='flex flex-wrap -mx-0.5 col-span-2'>
                                                                                            <div className="w-1/3 items-center">
                                                                                                <label className="form-input-sm-label">{t('Email')}: {domainDetails['email']}</label>
                                                                                            </div>
                                                                                            <div className="w-1/3 items-center">
                                                                                                <label className="form-input-sm-label">{t('Name')}: {domainDetails['name']}</label>
                                                                                            </div>

                                                                                            <div className="w-1/3 items-center">
                                                                                                <label className="form-input-sm-label">{t('CompanyName')}: {domainDetails['companyName']}</label>
                                                                                            </div>
                                                                                        </div>


                                                                                        <div className='flex flex-wrap -mx-0.5 col-span-2'>
                                                                                            <div className="w-1/3 items-center">
                                                                                                <label htmlFor="countryId" className="form-input-sm-label-required">{t('Country')}</label>
                                                                                                <div className="relative mr-3 mt-1 sm:col-span-2 sm:mt-0">
                                                                                                    {
                                                                                                        errors.country ?.type === 'required' &&
                                                                                                        <div className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.country?.message}
                                                                                                        </div>
                                                                                                    }
                                                                                                    <div className="relative items-stretch focus-within:z-10">

                                                                                                        <Select
                                                                                                            {...register("country")}
                                                                                                            placeholder={t('ChooseCountry')}
                                                                                                            searchable clearable
                                                                                                            allowDeselect
                                                                                                            nothingFound="No options"
                                                                                                            data={countries.map((country, index) => ({label: country.name, value: country.id + '-' + country.name}))}
                                                                                                            transition="pop-top-left"
                                                                                                            transitionDuration={80}
                                                                                                            transitionTimingFunction="ease"
                                                                                                            maxDropdownHeight={150}
                                                                                                            onChange={e => {
                                                                                                                setCountry(e)
                                                                                                                setValue('country', e)
                                                                                                            }}
                                                                                                            // value={ domainDetails['country'] ? domainDetails['country'] : '' }
                                                                                                            value={ country }
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="w-full items-center">
                                                                                            <label htmlFor="address" className="form-input-sm-label-required">{t('Address')}</label>
                                                                                            <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                {
                                                                                                    errors.address?.type === 'required' &&
                                                                                                    <div className="form-input-sm-error">
                                                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.address?.message}
                                                                                                    </div>
                                                                                                }
                                                                                                <textarea
                                                                                                    {...register("address")}
                                                                                                    className={`form-input-sm-control-textarea `}
                                                                                                    name="address"
                                                                                                    id="address"
                                                                                                    placeholder={t("EnterAddress")}
                                                                                                    defaultValue={ domainDetails['address'] ? domainDetails['address'] : '' }
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ScrollArea>
                                                            <footer className="relative mt-1 border border-t">
                                                                <div className="mr-3">
                                                                    <div className="text-right pt-0.5 mb-0.5">
                                                                    <button
                                                                        disabled={spinner}
                                                                        type='submit'
                                                                        className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-sm">
                                                                            { spinner ? (<AiOutlineLoading3Quarters className={'mr-2 animate-spin'} size={16} />) : (<FiSave size={16} className={'mr-2'} />) }
                                                                        <span>{t('Save')}</span>
                                                                    </button>
                                                                    </div>
                                                                </div>
                                                            </footer>
                                                        </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};
export default Update;
