import React, {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {HiOutlineExclamation, HiOutlineOfficeBuilding, HiChevronLeft, HiArrowCircleRight, HiOutlineRefresh} from "react-icons/hi";

import {FiSave, FiX} from "react-icons/fi";

import {AiOutlineLoading3Quarters, AiOutlineMinus} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import  {Toaster} from "react-hot-toast";
import { ScrollArea, LoadingOverlay} from "@mantine/core";
import {showNotification} from "@mantine/notifications";


function Register() {

    const navigate = useNavigate();
    const {roles, token} = JSON.parse(localStorage.getItem('user'));
    const {t, i18n} = useTranslation();
    const fullFormHeight = localStorage.getItem('fullFormHeight');


    //Submit Spinner Init
    const [spinner, setSpinner] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    //Form validation
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t("EnterName")),
        email: Yup.string().email(t('EnterValidEmail')).required(t("EnterEmail")),
        phone: Yup.string().required(t("EnterMobileNumber")),
    });

    let formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState, setValue, setError} = useForm(formOptions);
    const {errors} = formState;

    const [recall, setRecall] = useState(false);

    //Form Data Submit
    function formSubmit(data) {
        setShowLoader(true);
        setSpinner(true);
        
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/register-administrator`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: data
        })
            .then(res => {
                navigate('/moderator');
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: res.data.status === 201 ? (t('Success') + ' !') : (t('Warning')),
                    message: (t(res.data.message)),
                    autoClose: 2000,
                    disallowClose: true,
                    color: res.data.status === 201 ? 'green' : 'red',
                });
                
            })
            .catch(function (error) {
                setSpinner(false);
                setShowLoader(false);
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('Warning')),
                    message: (t(error.response.data.message)),
                    autoClose: 2000,
                    disallowClose: true,
                    color: 'red',
                });
            })
    }

    return (
        <main className="w-full">
            <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                <div className={"flex-1"}>
                    <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                        <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                            {t('Moderator')}
                            <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>
                                {t('ManageModeratorInformation')}
                            </sub>
                        </span>
                    </div>
                </div>
                <div className={"right flex mr-8"}>
                    <div className="flex items-center justify-center">
                        <div className="inline-flex border-1 border-red-300" role="group">
                            <Link to='/moderator'
                                    className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                            >
                                <HiChevronLeft size={12} className={'mr-1'}/>{t('Back')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="min-h-full w-full">
                <div className="flex w-full md:w-auto">
                    <div className="bg-red-100 flex-1">
                        <div className="min-w-0 flex-1 bg-white xl:flex">
                            <div className="bg-white lg:min-w-0 lg:flex-1">
                                <div className="h-full px-4 sm:px-6 lg:px-4">
                                    <div className="relative">
                                        <div className={'flex flex-1 w-full'}>
                                            <div className="w-full mr-0 mx-auto border">
                                                <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                    { <LoadingOverlay visible={showLoader} overlayBlur={2} loaderProps={{ size: 'sm', color: 'indigo' }} overlayColor="bg-indigo-100"/> }

                                                    <ScrollArea style={{ height: fullFormHeight }} scrollbarSize={4}>
                                                        <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full bg-gray-200">
                                                            <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
                                                                <div className="md:col-span-1">
                                                                    <div className="px-4 sm:px-0 ml-2">
                                                                        <h3 className="text-lg font-medium leading-6 text-gray-900">{t("ModeratorInformation")}</h3>
                                                                        <p className="mt-1 text-sm text-gray-600">* {t('LoginCredentialsWillBeSentToEmail')}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-5 md:col-span-4 md:mt-0">
                                                                    <div
                                                                        className="shadow     sm:rounded-md">
                                                                        <div
                                                                            className="bg-gray-50 px-4 py-5 sm:p-6">
                                                                            <div className="mb-3 w-full items-center">
                                                                                <label htmlFor="name"
                                                                                        className="form-input-sm-label-required">{t('Name')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    {errors.name?.type === 'required' &&
                                                                                    <div className="form-input-sm-error">
                                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.name?.message}
                                                                                    </div>}
                                                                                    <input
                                                                                        type="text"
                                                                                        {...register("name")}
                                                                                        className={`form-input-sm-control ${errors.companyName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                        name="name"
                                                                                        id="name"
                                                                                        placeholder={t("EnterName")}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                <div
                                                                                    className="mb-3 w-1/2 items-center">
                                                                                    <label htmlFor="phone"
                                                                                            className="form-input-sm-label-required">{t('Phone')}</label>
                                                                                    <div
                                                                                        className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                        {errors.phone?.type === 'required' &&
                                                                                        <div className="form-input-sm-error">
                                                                                            <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.phone?.message}
                                                                                        </div>}

                                                                                        <input
                                                                                            {...register("phone")}
                                                                                            type="text"
                                                                                            className={`form-input-sm-control ${errors.phone ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                            name="phone"
                                                                                            id="phone"
                                                                                            placeholder={t("EnterPhoneNumber")}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div
                                                                                    className="mb-3 w-1/2 items-center">
                                                                                    <label htmlFor="email"
                                                                                            className="form-input-sm-label-required">{t('Email')}</label>
                                                                                    <div
                                                                                        className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                        {errors.email?.type === 'required' &&
                                                                                        <div className="form-input-sm-error">
                                                                                            <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.email?.message}
                                                                                        </div>}

                                                                                        {errors.email?.type === 'email' &&
                                                                                        <div className="form-input-sm-error">
                                                                                            <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.email?.message}
                                                                                        </div>}

                                                                                        <input
                                                                                            {...register("email")}
                                                                                            type="text"
                                                                                            className={`form-input-sm-control ${errors.email ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                            name="email"
                                                                                            id="email"
                                                                                            placeholder={t("EnterEmail")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ScrollArea>

                                                    <footer className="relative mt-1">
                                                        <div className="mr-3">
                                                            <div
                                                                className="text-right pt-0.5 mb-0.5">
                                                                <button type='reset'
                                                                        className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                ><HiOutlineRefresh size={16} className={'mr-2'}/>
                                                                    <span>{t('Reset')}</span>
                                                                </button>
                                                                <button
                                                                    disabled={spinner}
                                                                    type='submit'
                                                                    className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-sm">
                                                                    {
                                                                        spinner ? (<AiOutlineLoading3Quarters className={'mr-2 animate-spin'} size={16} />) : (<FiSave size={16} className={'mr-2'} />)
                                                                    }
                                                                    <span>{t('Save')}</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </footer>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Register