import React from "react";
import {
    HiOutlineHome,
    HiOutlineCurrencyBangladeshi,
    HiOutlineUserGroup,
    HiOutlineCalendar,
    HiOutlineCurrencyDollar,
    HiDocumentText,
    HiOutlineDocumentText,
    HiOutlineShoppingBag,
    HiOutlineCash,
    HiOutlineDocumentReport,
    HiUsers,
    HiOutlineClipboardList,
    HiOutlineUserCircle,
    HiOutlineUsers
} from "react-icons/hi";
import {FiSettings} from "react-icons/fi";
import {BiBuildingHouse} from "react-icons/bi";
import {AiOutlineFileSearch} from "react-icons/ai";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router";

function LeftMenu() {
    const {t, i18n} = useTranslation();
    // const roles = JSON.parse(localStorage.getItem("user")).roles;
    const {roles, token, loggedUserId} = JSON.parse(localStorage.getItem('user'));

    const leftSidebarHeight = localStorage.getItem('leftSidebarHeight');
    const hasRoleModerator = roles.find(role => {
        return role === "ROLE_MODERATOR";
    })
    const hasRoleCandidate = roles.find(role => {
        return role === "ROLE_CANDIDATE";
    })
    const hasRoleClient = roles.find(role => {
        return role === "ROLE_CLIENT";
    })

    const hasRoleClientAdmin = ["ROLE_ADMIN", "ROLE_CLIENT"].every(role => {
        return roles.includes(role);
    })

    const hasRoleSuperAdmin = roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })

    const location = useLocation();

    const candidateMenuItem = [
        <Link
            to="/home"
            className={`${location.pathname.startsWith('/home') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineHome size={24}/>
            <span className="mt-2">{t('home')}</span>
        </Link>,

        <Link
            to="/appointments"
            className={`${location.pathname.startsWith('/appointments') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineCalendar size={24}/>
            <span className="mt-2">{t('Appointments')}</span>
        </Link>,

        <Link
            to="/time-sheet"
            className={`${location.pathname.startsWith('/time-sheet') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineClipboardList size={24}/>
            <span className="mt-2">{t('TimeSheet')}</span>
        </Link>,

        <Link
            to="/crm"
            className={`${location.pathname.startsWith('/crm') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineUsers size={24}/>
            <span className="mt-2">{t('CRM')}</span>
        </Link>,

        <Link
            to="/payroll"
            className={`${location.pathname.startsWith('/payroll') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineCurrencyDollar size={24}/>
            <span className="mt-2">{t('Payroll')}</span>
        </Link>
    ];

    const clientMenuItem = [
        <Link
            to="/home"
            className={`${location.pathname.startsWith('/home') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineHome size={24}/>
            <span className="mt-2">{t('home')}</span>
        </Link>,

        <Link
            to="/appointments"
            className={`${location.pathname.startsWith('/appointments') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineCalendar size={24}/>
            <span className="mt-2">{t('Appointments')}</span>
        </Link>,

        <Link
            to="/time-sheet"
            className={`${location.pathname.startsWith('/time-sheet') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineClipboardList size={24}/>
            <span className="mt-2">{t('TimeSheet')}</span>
        </Link>,

        <Link
            to="/job-post"
            className={`${location.pathname.startsWith('/job-post') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <AiOutlineFileSearch size={24}/>
            <span className="mt-2">{t('JobPost')}</span>
        </Link>,

        <Link
            to="/crm"
            className={`${location.pathname.startsWith('/crm') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineUsers size={24}/>
            <span className="mt-2">{t('CRM')}</span>
        </Link>,

        <Link
            to="/invoice"
            className={`${location.pathname.startsWith('/invoice') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineDocumentText size={24}/>
            <span className="mt-2">{t('Invoice')}</span>
        </Link>,

        <Link
            to="/employee"
            className={`${location.pathname.startsWith('/employee') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiUsers size={24}/>
            <span className="mt-2">{t('Employees')}</span>
        </Link>
    ];

    const moderatorMenuItem = [
        <Link
            to="/home"
            className={`${location.pathname.startsWith('/home') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineHome size={24}/>
            <span className="mt-2">{t('home')}</span>
        </Link>,

        <Link
            to="/appointments"
            className={`${location.pathname.startsWith('/appointments') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineCalendar size={24}/>
            <span className="mt-2">{t('Appointments')}</span>
        </Link>,

        <Link
            to="/time-sheet"
            className={`${location.pathname.startsWith('/time-sheet') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineClipboardList size={24}/>
            <span className="mt-2">{t('TimeSheet')}</span>
        </Link>,

        <Link
            to="/job-post"
            className={`${location.pathname.startsWith('/job-post') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <AiOutlineFileSearch size={24}/>
            <span className="mt-2">{t('JobPost')}</span>
        </Link>,

        <Link
            to="/client"
            className={`${location.pathname.startsWith('/client') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineUserGroup size={24}/>
            <span className="mt-2">{t('clients')}</span>
        </Link>,

        <Link
            to="/candidate"
            className={`${location.pathname.startsWith('/candidate') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineUserGroup size={24}/>
            <span className="mt-2">{t('Candidate')}</span>
        </Link>,

        <Link
            to="/crm"
            className={`${location.pathname.startsWith('/crm') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineUsers size={24}/>
            <span className="mt-2">{t('CRM')}</span>
        </Link>,

        <Link
            to="/invoice"
            className={`${location.pathname.startsWith('/invoice') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineDocumentText size={24}/>
            <span className="mt-2">{t('Invoice')}</span>
        </Link>,

        <Link
            to="/payroll"
            className={`${location.pathname.startsWith('/payroll') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineCurrencyDollar size={24}/>
            <span className="mt-2">{t('Payroll')}</span>
        </Link>,

        <Link
            to="/inventory"
            className={`${location.pathname.startsWith('/inventory') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineShoppingBag size={24}/>
            <span className="mt-2">{t('Inventory')}</span>
        </Link>,

        <Link
            to="/bookkeeping"
            className={`${location.pathname.startsWith('/bookkeeping') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineDocumentReport size={24}/>
            <span className="mt-2">{t('Bookkeeping')}</span>
        </Link>,

        <Link
            to="/moderator"
            className={`${location.pathname.startsWith('/moderator') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
            <HiOutlineUserCircle size={24}/>
            <span className="mt-2">{t('Moderator')}</span>
        </Link>
    ];

    return (
        <aside className="hidden sm:block bg-indigo-100 w-26">
            <div className="">
                <div className="hidden overflow-y-hidden md:block">
                    <div className="flex w-full flex-col items-center">
                        <div className="relative flex flex-col min-w-0 break-words sidebar-div-scroll hover:scroll-auto" style={{height: leftSidebarHeight}}>

                            { hasRoleCandidate && candidateMenuItem }
                            { hasRoleClient && clientMenuItem }
                            { hasRoleModerator && moderatorMenuItem }

                            {/*{
                                hasRoleSuperAdmin && (
                                    <Link
                                        to="/moderator"
                                        className={`${location.pathname.startsWith('/moderator') ? "bg-indigo-800 text-white" : "bg-indigo-200 text-indigo-700"} mb-1 hover:bg-indigo-800 hover:text-white group w-full p-3 rounded flex flex-col items-center text-xs font-medium`}>
                                        <HiOutlineUserCircle size={24}/>
                                        <span className="mt-2">{t('Moderator')}</span>
                                    </Link>
                                )
                            }*/}
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    )
}

export default LeftMenu