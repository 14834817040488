import React from "react";
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import Header from "./Header";
import LeftMenu from "./LeftMenu";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import {Toaster} from "react-hot-toast";

function Main() {
    const user = localStorage.getItem("user");
    const location = useLocation();

    if(!user){
        return <Navigate replace to="/login"/>;
    }else{
        if(location.pathname === '/'){
            return <Navigate replace to="/home"/>;

        }
    }
    const {roles} = JSON.parse(user);

    const hasRoleCandidate = roles.find(role => {
        return role === 'ROLE_CANDIDATE';
    })

    const hasRoleClient = roles.find(role => {
        return role === 'ROLE_CLIENT';
    })
    return (
        <>
            <Toaster toastOptions={{ className: 'w-1/2' }} containerStyle={{ top : 63 }} />

            <div className="App">
                <div id="toastForCreate"
                     className="hidden fixed right-10 bottom-10 px-5 py-4 border-r-8 border-blue-500 bg-white drop-shadow-lg z-50">
                    <p className="text-sm">
                    <span
                        className="mr-2 inline-block px-3 py-1 rounded-full bg-blue-500 text-white font-extrabold">i</span>
                        Data insert successfully.
                    </p>
                </div>

                <div id="toastForUpdate"
                     className="hidden fixed right-10 bottom-10 px-5 py-4 border-r-8 border-blue-500 bg-white drop-shadow-lg z-50">
                    <p className="text-sm">
                    <span
                        className="mr-2 inline-block px-3 py-1 rounded-full bg-blue-500 text-white font-extrabold">i</span>
                        Data update successfully.
                    </p>
                </div>

                <div id="toastForDelete"
                     className="hidden fixed right-10 bottom-10 px-5 py-4 border-r-8 border-blue-500 bg-white drop-shadow-lg z-50">
                    <p className="text-sm">
                    <span
                        className="mr-2 inline-block px-3 py-1 rounded-full bg-blue-500 text-white font-extrabold">i</span>
                        Data delete successfully.
                    </p>
                </div>
                <div>
                    <div className="flex flex-col h-screen">
                        <Header/>
                        <div className="flex flex-1 overflow-hidden">
                            <LeftMenu/>
                            <Outlet/>
                            {
                                (!hasRoleCandidate && !hasRoleClient ) && <Sidebar/>
                            }

                        </div>
                        {/*<Footer/>*/}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Main