import {Document, Page, PDFViewer, Text, View, Image} from "@react-pdf/renderer";
import styles from "../../../pdf-styles";
import {Grid, ScrollArea, Select, TextInput, Textarea, Box, Group, ActionIcon} from "@mantine/core";
import React, {Fragment, useEffect, useState} from "react";
import {
    HiChevronLeft,
    HiEye,
    HiOutlineExclamation,
    HiOutlineOfficeBuilding,
    HiOutlinePlus,
    HiPencil,
    HiOutlineCurrencyDollar, HiDocumentDownload
} from "react-icons/hi";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import axios from "axios";
import {FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {useForm} from "react-hook-form";
import {showNotification} from "@mantine/notifications";
import {DatePicker} from "@mantine/dates";
import dayjs from "dayjs";
import {useNavigate} from "react-router";
import {DataTable} from "mantine-datatable";
import {onHidden} from "web-vitals/dist/modules/lib/onHidden";


function InvoiceShow() {

    const {domainId, loggedUserId ,roles, token} = JSON.parse(localStorage.getItem('user'));
    const hasRoleModerator = roles.find(role => {
        return role === "ROLE_MODERATOR";
    })
    const {invoiceId} = useParams();
    const {t, i18n} = useTranslation();
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const fullFormHeight = localStorage.getItem('fullFormHeight');
    const navigate = useNavigate();
    const [taxData, setTaxData] = useState(0.00);
    const [viewMode, setViewMode] = useState('decimal');


    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/invoice/${invoiceId}/show`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                if (res.status === 200){
                    setInvoiceDetails(res.data.data)
                }
            });
    }, [])

    var lineTotalAmount = invoiceDetails.subTotalAmount;
    var lineTotalAmountDecimalHour = invoiceDetails.subTotalDecimalHourAmount;
    var taxRate = invoiceDetails.taxRate?invoiceDetails.taxRate:0;

    var taxAmount = ((lineTotalAmount*taxRate)/100).toFixed(2);
    var taxAmountDecimalHour = ((lineTotalAmountDecimalHour*taxRate)/100).toFixed(2);

    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div
                            className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Invoice')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('InvoiceInformation')}</sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <Link to={`/invoice/`+invoiceDetails.id+`/pdf`}
                                      state = { viewMode==='hour' ? {viewMode: 'hour'} : {viewMode: 'decimal'}}
                                      className="inline-flex items-center text-right font-normal text-red-600 ">
                                    <HiDocumentDownload className="h-5 w-5" aria-hidden="true"/> {t('GeneratePDF')}
                                </Link>
                                {hasRoleModerator && invoiceDetails.paymentStatus && invoiceDetails.paymentStatus!=="PAID" &&
                                <Link to={'/invoice/'+invoiceDetails.id+'/payment/create'}>
                                    <button
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    >
                                        <HiOutlineCurrencyDollar size={16} className={'mr-1'}/>{t('RecordPayment')}
                                    </button>
                                </Link>

                                }
                                {hasRoleModerator &&
                                <Link to={'/invoice/'+invoiceDetails.id+'/summery'}>
                                    <button
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    >
                                        <HiEye size={16} className={'mr-1'}/>{t('SummeryInvoice')}
                                    </button>
                                </Link>
                                }
                                {hasRoleModerator && invoiceDetails.paymentStatus && (invoiceDetails.paymentStatus ==="DISPUTED" || invoiceDetails.paymentStatus ==="CREATED") &&
                                <Link to={'/invoice/'+invoiceDetails.id+'/edit'}>
                                    <button
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    >
                                        <HiPencil size={16} className={'mr-1'}/>{t('EditInvoice')}
                                    </button>
                                </Link>
                                }
                                { hasRoleModerator &&
                                <Link to={'/invoice/create'}>
                                    <button
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    >
                                        <HiOutlinePlus size={16} className={'mr-1'}/>{t('NewInvoice')}
                                    </button>
                                </Link>
                                }


                                <Link to={'/invoice'}>
                                    <button
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    >
                                        <HiChevronLeft size={16} className={'mr-1'}/>{t('Back')}
                                    </button>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-full w-full">
                    <div className="bg-white px-2 lg:px-4 lg:py-2">
                        <button onClick={()=>setViewMode('decimal')}
                            className={`inline-flex items-center ml-6 rounded border-none py-2 px-4 text-sm font-normal shadow-sm bg-indigo-100 ${viewMode==='decimal' && "bg-indigo-600 text-white"}`}
                        >
                            {t('Decimal')}
                        </button>
                        { hasRoleModerator &&
                            <button onClick={()=>setViewMode('hour')}
                                    className={`inline-flex items-center ml-6 rounded border-none py-2 px-4 text-sm font-normal shadow-sm bg-indigo-100 ${viewMode==='hour' && "bg-indigo-600 text-white"}`}
                            >
                                {t('Hour')}
                            </button>
                        }

                    </div>
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 sm:px-6 lg:px-4">
                                        <div className="">

                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border">
                                                    <ScrollArea style={{ height: fullFormHeight }} scrollbarSize={4}>
                                                        <div className="">
                                                            { viewMode==='decimal' &&
                                                                <div className="bg-white px-2 py-2 lg:p-4">
                                                                    <Grid columns={24}>
                                                                        <Grid.Col span={14}>
                                                                            <div className="w-full">
                                                                                <img src={"/assets/images/simple-staffing.png"} alt="Simple Staffing" />
                                                                                <h2 className="text-lg">SIMPLE STAFFING USA LLC</h2>
                                                                                <p className="text-sm">
                                                                                    3235 Satellite Blvd. Building 400
                                                                                </p>
                                                                                <p className="text-sm">
                                                                                    Suite 300 Duluth, GA
                                                                                </p>
                                                                                <p className="text-sm">
                                                                                    {t('Phone')}: 1.888.902.7759
                                                                                </p>
                                                                                <p className="text-sm">
                                                                                    {t('Email')}: billing@simplestaffingllc.com
                                                                                </p>
                                                                            </div>
                                                                            <div className="w-full mt-10">
                                                                                <h2 className="text-lg"><strong>BILL TO:</strong></h2>
                                                                                <h2 className="text-lg">{invoiceDetails.clientName}</h2>
                                                                                <p className="text-sm">{invoiceDetails.clientAddress}</p>
                                                                                <p className="text-sm">Phone: {invoiceDetails.clientPhone}</p>
                                                                                <p className="text-sm">Email: {invoiceDetails.clientEmail}</p>
                                                                            </div>
                                                                        </Grid.Col>
                                                                        <Grid.Col span={10}>
                                                                            <div className="text-center">
                                                                                <h1>Invoice</h1>
                                                                                <table>
                                                                                    <tbody>
                                                                                    <tr>
                                                                                        <td className="text-right">INVOICE NO : </td>
                                                                                        <td className="text-left">{invoiceDetails.invoiceId}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="text-right">{t('Date')} : </td>
                                                                                        <td className="text-left">
                                                                                            {invoiceDetails.invoiceDate ? format(new Date(invoiceDetails.invoiceDate.date), 'MMMM dd, Y') : ''}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="text-right">{t('InvoicePeriod')} : </td>
                                                                                        <td className="text-left">{invoiceDetails.startTime ? format(new Date(invoiceDetails.startTime.date), 'MMM dd, Y') : ''} To  {invoiceDetails.endTime ? format(new Date(invoiceDetails.endTime.date), 'MMM dd, Y') : ''}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="text-right">{t('InvoiceDueDate')} : </td>
                                                                                        <td className="text-left">
                                                                                            {invoiceDetails.dueDate ? format(new Date(invoiceDetails.dueDate.date), 'MMMM dd, Y') : ''}
                                                                                        </td>
                                                                                    </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </Grid.Col>
                                                                    </Grid>
                                                                    <div className="w-full mt-2" style={{overflow:scroll}}>
                                                                        <table className="border-collapse border border-slate-400 min-w-full" >
                                                                            <thead className="bg-white">
                                                                            <tr>

                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('CandidateName')}
                                                                                </th>
                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('Date')}
                                                                                </th>
                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('JobTitle')}
                                                                                </th>
                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('Designation')}
                                                                                </th>

                                                                                <th colSpan={1} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('RegularRate')}
                                                                                </th>
                                                                                <th colSpan={1} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('OverTimeRate')}
                                                                                </th>

                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('TotalHours')}
                                                                                </th>
                                                                                <th colSpan={1} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('Regular')}
                                                                                </th>
                                                                                <th colSpan={1} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('OverTime')}
                                                                                </th>
                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('TotalAmount')}

                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>

                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>

                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                {/*<th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>*/}

                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                {/*<th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>*/}
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody className="bg-white">


                                                                            {invoiceDetails && invoiceDetails.details && Object.keys(invoiceDetails.details).map((detailsKey, index) => {


                                                                                return (
                                                                                    <Fragment key={index}>
                                                                                        {detailsKey==='invoiceDetails' && Object.keys(invoiceDetails.details[detailsKey]).map((employeeId, index)=> {

                                                                                            {var candidateWiseCount = 0}
                                                                                            {var lineTotalHour = 0}
                                                                                            {var lineTotalMin = 0}
                                                                                            {var countOvertimeHour = 0}

                                                                                            return (
                                                                                                <Fragment key={index}>

                                                                                                    {invoiceDetails.details[detailsKey][employeeId] && Object.keys(invoiceDetails.details[detailsKey][employeeId]).map((checkInTime, index)=>{

                                                                                                        return (
                                                                                                            <Fragment key={index}>
                                                                                                                {invoiceDetails.details[detailsKey][employeeId][checkInTime] && Object.values(invoiceDetails.details[detailsKey][employeeId][checkInTime]).map((element, index)=>{
                                                                                                                    { candidateWiseCount = candidateWiseCount+1}


                                                                                                                    {var candidateDataLength = invoiceDetails && invoiceDetails.details && invoiceDetails.details['candidateDataLength'] && Object.values(invoiceDetails.details['candidateDataLength'][employeeId]) ? Object.values(invoiceDetails.details['candidateDataLength'][employeeId]).length:''}

                                                                                                                    return(
                                                                                                                        <Fragment key={index}>
                                                                                                                            <tr key={index} className="border-t border-gray-200">

                                                                                                                                {candidateWiseCount===1 &&
                                                                                                                                    <td rowSpan={candidateDataLength} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                        {element.candidateName}
                                                                                                                                    </td>
                                                                                                                                }
                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {checkInTime}
                                                                                                                                </td>
                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.jobTitle}
                                                                                                                                </td>
                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.designationName}
                                                                                                                                </td>
                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.regularHourRate}
                                                                                                                                </td>
                                                                                                                                {/*<td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.regularMinRate}
                                                                                                                                </td>*/}

                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.overtimeHourRate}
                                                                                                                                </td>
                                                                                                                                {/*<td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.overtimeMinRate}
                                                                                                                                </td>*/}

                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.minuteFormatToHourFormat}
                                                                                                                                </td>

                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.regularHourMinToDecimal}
                                                                                                                                </td>
                                                                                                                                {/*<td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.regularMin}
                                                                                                                                </td>*/}

                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.overtimeHourMinToDecimal}
                                                                                                                                </td>
                                                                                                                                {/*<td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.overtimeMin}
                                                                                                                                </td>*/}

                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900 text-right">
                                                                                                                                    {element.amountDecimalHour>0?element.amountDecimalHour:((element.regularHourMinToDecimal*element.regularHourRate)+(element.overtimeHourMinToDecimal*element.overtimeHourRate)).toFixed(2)}
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </Fragment>
                                                                                                                    )


                                                                                                                })
                                                                                                                }
                                                                                                            </Fragment>
                                                                                                        )
                                                                                                    })}
                                                                                                </Fragment>
                                                                                            )

                                                                                        })}
                                                                                    </Fragment>
                                                                                )

                                                                            })}


                                                                            </tbody>
                                                                            <tfoot>
                                                                            <tr>
                                                                                <th className="border border-slate-300 text-right px-4" colSpan={6}>{t('TotalInvoiceHours')}</th>
                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-left">{invoiceDetails.totalHoursMinuteFormatToHourFormat}</th>
                                                                                <th className="border border-slate-300 text-right px-4" colSpan={2}>{t('TotalInvoiceAmount')}</th>
                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right">{lineTotalAmountDecimalHour && lineTotalAmountDecimalHour>0?lineTotalAmountDecimalHour.toFixed(2):''}</th>
                                                                            </tr>
                                                                            <tr>

                                                                                <th colSpan={9} className="border border-slate-300 text-right px-4">{t('Tax')} ({invoiceDetails.taxRate?invoiceDetails.taxRate:0}%)</th>
                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6">
                                                                                    {/*$ {(invoiceDetails.taxAmount?invoiceDetails.taxAmount:0).toFixed(2)}*/}
                                                                                    $ {taxAmountDecimalHour}
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th colSpan={9} className="border border-slate-300 text-right px-4">{t('Total')}</th>
                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6">
                                                                                    $ {(lineTotalAmountDecimalHour+ Number(taxAmountDecimalHour)).toFixed(2)}
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th colSpan={9} className="border border-slate-300 text-right px-4">{t('PaymentMade')}</th>
                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6 text-red-700">
                                                                                    (-) $ {invoiceDetails.totalPaymentAmount}
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th colSpan={9} className="border border-slate-300 text-right px-4">{t('BalanceDue')}</th>
                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6">
                                                                                    $ {((lineTotalAmountDecimalHour + Number(taxAmountDecimalHour)) - invoiceDetails.totalPaymentAmount).toFixed(2)}
                                                                                </th>
                                                                            </tr>
                                                                            </tfoot>
                                                                        </table>
                                                                    </div>
                                                                    <Grid columns={24}>
                                                                        <Grid.Col span={24}>
                                                                            <div className="w-full mt-10">
                                                                                <h3><strong>{t('AmountInWords')}:</strong> {invoiceDetails.amountInWordDecimalHour}</h3>
                                                                            </div>
                                                                            <div className="w-full mt-3">
                                                                                <p><strong>{t('TermsAndConditions')}:</strong> {invoiceDetails.termsAndConditions}</p>
                                                                            </div>
                                                                            {/*<div className="w-full mt-3">
                                                                                <p><strong>{t('Comments')}: </strong> {invoiceDetails.remarks}</p>
                                                                            </div>*/}
                                                                            <div className="w-full mt-10">
                                                                                <h3><strong>{t('NoticeOfAssignment')}:</strong></h3>
                                                                                <p className="text-sm">
                                                                                    {invoiceDetails.remarks}
                                                                                </p>
                                                                            </div>
                                                                        </Grid.Col>
                                                                        <Grid.Col span={24}>
                                                                            <div className="w-full mt-10">
                                                                                <h3>{t('PaymentHistory')}</h3>
                                                                                <table className="border-collapse border border-slate-400 min-w-full">
                                                                                    <thead className="bg-white">
                                                                                    <tr>
                                                                                        <th scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                            {t('SL')}
                                                                                        </th>
                                                                                        <th scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                            {t('PaymentDate')}
                                                                                        </th>
                                                                                        <th scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                            {t('ReceiveBy')}
                                                                                        </th>
                                                                                        <th scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                            {t('PaymentMode')}
                                                                                        </th>
                                                                                        <th scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                            {t('BankCharge')}
                                                                                        </th>
                                                                                        <th scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                            {t('Amount')}
                                                                                        </th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody className="bg-white">

                                                                                    {invoiceDetails.payments && invoiceDetails.payments.map((element, index) => (
                                                                                        <tr key={index} className="border-t border-gray-200">
                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                {index+1}
                                                                                            </td>
                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                {element.paymentDate ? format(new Date(element.paymentDate.date), 'dd, MMMM Y') : ''}
                                                                                            </td>
                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                {element.createdByName ? element.createdByName : ''}
                                                                                            </td>
                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                {element.paymentType ? element.paymentType : ''}
                                                                                            </td>
                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900">
                                                                                                $ {element.bankCharge?element.bankCharge:'0.00'}
                                                                                            </td>
                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900">
                                                                                                $ {element.amount}
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}

                                                                                    </tbody>
                                                                                    <tfoot>
                                                                                    <tr>

                                                                                        <th colSpan={5} className="border border-slate-300 text-right py-2 px-2">{t('Total')}</th>
                                                                                        <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900">
                                                                                            $ {invoiceDetails.totalPaymentAmount}
                                                                                        </th>
                                                                                    </tr>
                                                                                    </tfoot>
                                                                                </table>
                                                                            </div>

                                                                        </Grid.Col>
                                                                    </Grid>
                                                                </div>
                                                            }
                                                            {hasRoleModerator && viewMode==='hour' &&
                                                                <div className="bg-white px-2 py-2 lg:p-4">
                                                                    <Grid columns={24}>
                                                                        <Grid.Col span={14}>
                                                                            <div className="w-full">
                                                                                <img src={"/assets/images/simple-staffing.png"} alt="Simple Staffing" />
                                                                                <h2 className="text-lg">SIMPLE STAFFING USA LLC</h2>
                                                                                <p className="text-sm">
                                                                                    3235 Satellite Blvd. Building 400
                                                                                </p>
                                                                                <p className="text-sm">
                                                                                    Suite 300 Duluth, GA
                                                                                </p>
                                                                                <p className="text-sm">
                                                                                    {t('Phone')}: 1.888.902.7759
                                                                                </p>
                                                                                <p className="text-sm">
                                                                                    {t('Email')}: billing@simplestaffingllc.com
                                                                                </p>
                                                                            </div>
                                                                            <div className="w-full mt-10">
                                                                                <h2 className="text-lg"><strong>BILL TO:</strong></h2>
                                                                                <h2 className="text-lg">{invoiceDetails.clientName}</h2>
                                                                                <p className="text-sm">{invoiceDetails.clientAddress}</p>
                                                                                <p className="text-sm">Phone: {invoiceDetails.clientPhone}</p>
                                                                                <p className="text-sm">Email: {invoiceDetails.clientEmail}</p>
                                                                            </div>
                                                                        </Grid.Col>
                                                                        <Grid.Col span={10}>
                                                                            <div className="text-center">
                                                                                <h1>Invoice</h1>
                                                                                <table>
                                                                                    <tbody>
                                                                                    <tr>
                                                                                        <td className="text-right">INVOICE NO : </td>
                                                                                        <td className="text-left">{invoiceDetails.invoiceId}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="text-right">{t('Date')} : </td>
                                                                                        <td className="text-left">
                                                                                            {invoiceDetails.invoiceDate ? format(new Date(invoiceDetails.invoiceDate.date), 'MMMM dd, Y') : ''}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="text-right">{t('InvoicePeriod')} : </td>
                                                                                        <td className="text-left">{invoiceDetails.startTime ? format(new Date(invoiceDetails.startTime.date), 'MMM dd, Y') : ''} To  {invoiceDetails.endTime ? format(new Date(invoiceDetails.endTime.date), 'MMM dd, Y') : ''}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="text-right">{t('InvoiceDueDate')} : </td>
                                                                                        <td className="text-left">
                                                                                            {invoiceDetails.dueDate ? format(new Date(invoiceDetails.dueDate.date), 'MMMM dd, Y') : ''}
                                                                                        </td>
                                                                                    </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </Grid.Col>
                                                                    </Grid>
                                                                    <div className="w-full mt-2" style={{overflow:scroll}}>
                                                                        <table className="border-collapse border border-slate-400 min-w-full" >
                                                                            <thead className="bg-white">
                                                                            <tr>

                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('CandidateName')}
                                                                                </th>
                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('Date')}
                                                                                </th>
                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('JobTitle')}
                                                                                </th>
                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('Designation')}
                                                                                </th>

                                                                                <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('RegularRate')}
                                                                                </th>
                                                                                <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('OverTimeRate')}
                                                                                </th>

                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('TotalHours')}
                                                                                </th>
                                                                                <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('Regular')}
                                                                                </th>
                                                                                <th colSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('OverTime')}
                                                                                </th>
                                                                                <th rowSpan={2} scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                    {t('TotalAmount')}

                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>

                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Hours")}</th>
                                                                                <th className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">{t("Min")}</th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody className="bg-white">


                                                                            {invoiceDetails && invoiceDetails.details && Object.keys(invoiceDetails.details).map((detailsKey, index) => {


                                                                                return (
                                                                                    <Fragment key={index}>
                                                                                        {detailsKey==='invoiceDetails' && Object.keys(invoiceDetails.details[detailsKey]).map((employeeId)=> {

                                                                                            {var candidateWiseCount = 0}
                                                                                            {var lineTotalHour = 0}
                                                                                            {var lineTotalMin = 0}
                                                                                            {var countOvertimeHour = 0}

                                                                                            return (
                                                                                                <Fragment key={employeeId}>

                                                                                                    {invoiceDetails.details[detailsKey][employeeId] && Object.keys(invoiceDetails.details[detailsKey][employeeId]).map((checkInTime, index)=>{

                                                                                                        return (
                                                                                                            <Fragment key={index}>
                                                                                                                {invoiceDetails.details[detailsKey][employeeId][checkInTime] && Object.values(invoiceDetails.details[detailsKey][employeeId][checkInTime]).map((element, index)=>{
                                                                                                                    { candidateWiseCount = candidateWiseCount+1}


                                                                                                                    {var candidateDataLength = invoiceDetails && invoiceDetails.details && invoiceDetails.details['candidateDataLength'] && Object.values(invoiceDetails.details['candidateDataLength'][employeeId]) ? Object.values(invoiceDetails.details['candidateDataLength'][employeeId]).length:''}

                                                                                                                    return(
                                                                                                                        <Fragment key={index}>
                                                                                                                            <tr key={index} className="border-t border-gray-200">

                                                                                                                                {candidateWiseCount===1 &&
                                                                                                                                    <td rowSpan={candidateDataLength} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                        {element.candidateName}
                                                                                                                                    </td>
                                                                                                                                }
                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {checkInTime}
                                                                                                                                </td>
                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.jobTitle}
                                                                                                                                </td>
                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.designationName}
                                                                                                                                </td>
                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.regularHourRate}
                                                                                                                                </td>
                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.regularMinRate}
                                                                                                                                </td>

                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.overtimeHourRate}
                                                                                                                                </td>
                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.overtimeMinRate}
                                                                                                                                </td>

                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.hourForDisplay}
                                                                                                                                </td>

                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.regularHour}
                                                                                                                                </td>
                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.regularMin}
                                                                                                                                </td>

                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.overtimeHour}
                                                                                                                                </td>
                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                                                    {element.overtimeMin}
                                                                                                                                </td>

                                                                                                                                <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900 text-right">
                                                                                                                                    {element.amount}
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </Fragment>
                                                                                                                    )


                                                                                                                })
                                                                                                                }
                                                                                                            </Fragment>
                                                                                                        )
                                                                                                    })}
                                                                                                </Fragment>
                                                                                            )

                                                                                        })}
                                                                                    </Fragment>
                                                                                )

                                                                            })}


                                                                            </tbody>
                                                                            <tfoot>
                                                                            <tr>
                                                                                <th className="border border-slate-300 text-right px-4" colSpan={8}>{t('TotalInvoiceHours')}</th>
                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-left">{invoiceDetails.totalHoursForDisplay}</th>
                                                                                <th className="border border-slate-300 text-right px-4" colSpan={4}>{t('TotalInvoiceAmount')}</th>
                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-700 text-gray-900 text-right">{lineTotalAmount}</th>
                                                                            </tr>
                                                                            <tr>

                                                                                <th colSpan={13} className="border border-slate-300 text-right px-4">{t('Tax')} ({invoiceDetails.taxRate?invoiceDetails.taxRate:0}%)</th>
                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6">
                                                                                    {/*$ {(invoiceDetails.taxAmount?invoiceDetails.taxAmount:0).toFixed(2)}*/}
                                                                                    $ {taxAmount}
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th colSpan={13} className="border border-slate-300 text-right px-4">{t('Total')}</th>
                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6">
                                                                                    $ {(lineTotalAmount + Number(taxAmount)).toFixed(2)}
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th colSpan={13} className="border border-slate-300 text-right px-4">{t('PaymentMade')}</th>
                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6 text-red-700">
                                                                                    (-) $ {invoiceDetails.totalPaymentAmount}
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th colSpan={13} className="border border-slate-300 text-right px-4">{t('BalanceDue')}</th>
                                                                                <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900 sm:pl-6">
                                                                                    $ {((lineTotalAmount + Number(taxAmount))-invoiceDetails.totalPaymentAmount).toFixed(2)}
                                                                                </th>
                                                                            </tr>
                                                                            </tfoot>
                                                                        </table>
                                                                    </div>
                                                                    <Grid columns={24}>
                                                                        <Grid.Col span={24}>
                                                                            <div className="w-full mt-10">
                                                                                <h3><strong>{t('AmountInWords')}:</strong> {invoiceDetails.amountInWord}</h3>
                                                                            </div>
                                                                            <div className="w-full mt-3">
                                                                                <p><strong>{t('TermsAndConditions')}:</strong> {invoiceDetails.termsAndConditions}</p>
                                                                            </div>
                                                                            {/*<div className="w-full mt-3">
                                                                                <p><strong>{t('Comments')}: </strong> {invoiceDetails.remarks}</p>
                                                                            </div>*/}
                                                                            <div className="w-full mt-10">
                                                                                <h3><strong>{t('NoticeOfAssignment')}:</strong></h3>
                                                                                <p className="text-sm">{invoiceDetails.remarks}</p>
                                                                            </div>
                                                                        </Grid.Col>
                                                                        <Grid.Col span={24}>
                                                                            <div className="w-full mt-10">
                                                                                <h3>{t('PaymentHistory')}</h3>
                                                                                <table className="border-collapse border border-slate-400 min-w-full">
                                                                                    <thead className="bg-white">
                                                                                    <tr>
                                                                                        <th scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                            {t('SL')}
                                                                                        </th>
                                                                                        <th scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                            {t('PaymentDate')}
                                                                                        </th>
                                                                                        <th scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                            {t('ReceiveBy')}
                                                                                        </th>
                                                                                        <th scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                            {t('PaymentMode')}
                                                                                        </th>
                                                                                        <th scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                            {t('BankCharge')}
                                                                                        </th>
                                                                                        <th scope="col" className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                                                                                            {t('Amount')}
                                                                                        </th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody className="bg-white">

                                                                                    {invoiceDetails.payments && invoiceDetails.payments.map((element, index) => (
                                                                                        <tr key={index} className="border-t border-gray-200">
                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                {index+1}
                                                                                            </td>
                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                {element.paymentDate ? format(new Date(element.paymentDate.date), 'dd, MMMM Y') : ''}
                                                                                            </td>
                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                {element.createdByName ? element.createdByName : ''}
                                                                                            </td>
                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                                                                                                {element.paymentType ? element.paymentType : ''}
                                                                                            </td>
                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900">
                                                                                                $ {element.bankCharge?element.bankCharge:'0.00'}
                                                                                            </td>
                                                                                            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900">
                                                                                                $ {element.amount}
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}

                                                                                    </tbody>
                                                                                    <tfoot>
                                                                                    <tr>

                                                                                        <th colSpan={5} className="border border-slate-300 text-right py-2 px-2">{t('Total')}</th>
                                                                                        <th className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm text-right font-medium text-gray-900">
                                                                                            $ {invoiceDetails.totalPaymentAmount}
                                                                                        </th>
                                                                                    </tr>
                                                                                    </tfoot>
                                                                                </table>
                                                                            </div>

                                                                        </Grid.Col>
                                                                    </Grid>
                                                                </div>
                                                            }

                                                        </div>
                                                    </ScrollArea>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default InvoiceShow