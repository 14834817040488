import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {
    HiOutlineX,
} from "react-icons/hi";
import {Fragment} from 'react'
import {format} from 'date-fns';
import button from "@material-tailwind/react";
import {ScrollArea, Table} from "@mantine/core";

export default function DesignationHistoryModel({setDesignationHistoryModel, designationHistoryExtendId,designationHistoryID}) {
    const {t, i18n} = useTranslation();
    const {roles, token} = JSON.parse(localStorage.getItem('user'));

    const modalHeight = localStorage.getItem('modalHeight');
    const [designationsWiseRate, setDesignationsWiseRate] = useState([]);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/designation-history`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "extended_id": designationHistoryExtendId,
                "designation_id":designationHistoryID
            }
        })
            .then(res => {
                setDesignationsWiseRate(res.data.data)
            });
    }, []);

    const DesignationRate = designationsWiseRate? designationsWiseRate.map((element,index) => (
        <tr key={element.id}>
            <td>{++index}</td>
            <td>{element.designationName}</td>
            <td>$ {element.rate}</td>
            <td>{element.createdAt ? format(new Date(element.createdAt.date), 'MM/dd/Y') : ''}</td>
            <td>{element.createdAt ? format(new Date(element.createdAt.date), 'H:mm a') : ''}</td>
        </tr>
    )):'';


    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]"
            >
                <div className="relative w-6/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('DesignationHistory')}</h5>
                                <button
                                    onClick={() => setDesignationHistoryModel(false)}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">

                                        <main role="main" className="w-full pt-1 px-2">
                                            <ScrollArea style={{height: '200px'}} scrollbarSize={4}>

                                                <Fragment>
                                                    <div className="bg-gray-100">
                                                        <div className=" bg-white">
                                                            <div className="relative">
                                                                <div className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                        <div className="flex-1 bg-white px-6 py-2 basis-2/3">
                                                                            <Table>
                                                                                <thead>
                                                                                <tr>
                                                                                    <th>{t('SL')}</th>
                                                                                    <th>{t('Designation')}</th>
                                                                                    <th>{t('HourlyRate')}</th>
                                                                                    <th>{t('Date')}</th>
                                                                                    <th>{t('Time')}</th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>{DesignationRate}</tbody>
                                                                            </Table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            </ScrollArea>
                                        </main>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
        </>
    );
}
