import React, {Fragment} from "react";
import {useState} from "react";
import {Tab} from "@headlessui/react";
import {Link} from 'react-router-dom';
import {HiArrowSmRight, HiChevronDoubleLeft, HiChevronDoubleRight} from "react-icons/hi";
import {useTranslation} from "react-i18next";


const Sidebar = () => {
    const {t, i18n} = useTranslation();

    const [showSidebar, setShowSidebar] = useState(false);
    const roles = JSON.parse(localStorage.getItem("user")).roles;

    const hasRole = roles.find(role => {
        return role === "ROLE_ADMIN";
    })
    const hasRoleModerator = roles.find(role => {
        return role === "ROLE_MODERATOR";
    })
    // console.log(hasRole);
    return (
        <>
            {showSidebar ? (
                <div className="flex z-5 relative" onClick={() => setShowSidebar(!showSidebar)}>
                    <button type="button"
                            className="rounded rounded-r-none inset-y-0 right-0 h-12 absolute inline-block px-1 py-2 bg-indigo-200 text-indigo font-medium text-xs leading-tight uppercase hover:bg-indigo-300 focus:bg-indigo-200 focus:outline-none focus:ring-0 active:bg-indigo-200 transition duration-150 ease-in-out">
                        <HiChevronDoubleRight className="text-indigo-500"/>
                    </button>
                </div>
            ) : (
                <div className="flex z-5 relative" onClick={() => setShowSidebar(!showSidebar)}>
                    <button type="button"
                            className="rounded rounded-r-none inset-y-0 right-0 h-12 absolute inline-block px-1 py-2 bg-indigo-200 text-indigo font-medium text-xs leading-tight uppercase hover:bg-indigo-300 focus:bg-indigo-200 focus:outline-none focus:ring-0 active:bg-indigo-200 transition duration-150 ease-in-out">
                        <HiChevronDoubleLeft className="text-indigo-500"/>
                    </button>
                </div>
            )}
            <div
                className={`top-0 right-0  h-full ease-in-out duration-300 ${
                    showSidebar ? "translate-x-0 w-60" : "translate-x-full w-0"
                }`}
            >
                <aside className="sm:block  h-full  overflow-y-hidden bg-indigo-50">
                    <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                        <div className="h-full lg:w-60">
                            <div className="relative h-full">
                                <Tab.Group>
                                    <Tab.List className={'flex bg-indigo-100 h-12 mb-2'}>
                                        <Tab as={Fragment}>
                                            {({selected}) => (
                                                <button
                                                    className={selected ? ' h-12 border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-700 text-indigo-500' : 'border-b-2  py-1.5 p-3.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'}>Creates</button>
                                            )}
                                        </Tab>
                                        <Tab as={Fragment}>
                                            {({selected}) => (
                                                <button
                                                    className={selected ? 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-700 text-indigo-500' : 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'}>Views</button>
                                            )}
                                        </Tab>
                                        <Tab as={Fragment}>
                                            {({selected}) => (
                                                <button
                                                    className={selected ? 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-700 text-indigo-500' : 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'}>Reports</button>
                                            )}
                                        </Tab>
                                    </Tab.List>
                                    <Tab.Panels>
                                        <Tab.Panel>
                                            <ul className="flex flex-col py-4">
                                                {/*<li className="px-5">
                                                    <div className="flex flex-row items-center h-8">
                                                        <div
                                                            className="text-sm font-light tracking-wide text-gray-500">Create
                                                        </div>
                                                    </div>
                                                </li>*/}
{/*
                                                <li className="px-5">
                                                    <div className="flex flex-row items-center h-8">
                                                        <div
                                                            className="text-sm font-light tracking-wide text-gray-500">Menu
                                                        </div>
                                                    </div>
                                                </li>
*/}
{/*
                                                <li>
                                                    <Link to="html/page-form"
                                                          className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span
                                                            className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                            size={16}/></span>
                                                        <span
                                                            className="ml-2 text-sm tracking-wide truncate">Page Form</span>
                                                    </Link>
                                                </li>
*/}
{/*
                                                <li>
                                                    <Link to="html/single-form"
                                                          className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span
                                                            className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                            size={16}/></span>
                                                        <span className="ml-2 text-sm tracking-wide truncate">Single Form</span>
                                                    </Link>
                                                    <Link to="html/two-form"
                                                          className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span
                                                            className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                            size={16}/></span>
                                                        <span className="ml-2 text-sm tracking-wide truncate">Two Column Form</span>
                                                    </Link>
                                                    {hasRole && (
                                                        <>
                                                            <Link to="/domain"
                                                                  className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                                <span
                                                                    className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                                    size={16}/></span>
                                                                <span
                                                                    className="ml-2 text-sm tracking-wide truncate">Domain</span>
                                                            </Link>

                                                        </>
                                                    )}
                                                </li>
*/}
                                                {/*<li className="px-5">
                                                    <div className="flex flex-row items-center h-8">
                                                        <div
                                                            className="text-sm font-light tracking-wide text-gray-500">Appointment
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <Link to="/domain/add-appointment"
                                                          className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span
                                                            className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                            size={16}/></span>
                                                        <span className="ml-2 text-sm tracking-wide truncate">Add Appointment</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/domain/update-appointment"
                                                          className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span
                                                            className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                            size={16}/></span>
                                                        <span className="ml-2 text-sm tracking-wide truncate">Update Appointment</span>
                                                    </Link>
                                                </li>*/}

                                                <li className="px-5">
                                                    <div className="flex flex-row items-center h-8">
                                                        <div className="text-sm font-light tracking-wide text-gray-500">Master Data
                                                        </div>
                                                    </div>
                                                </li>
{/*
                                                <li>
                                                    <Link to="/master-data/particular"
                                                          className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span
                                                            className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                            size={16}/></span>
                                                        <span
                                                            className="ml-2 text-sm tracking-wide truncate">Particular</span>
                                                    </Link>
                                                </li>
*/}
{/*
                                                <li>
                                                    <Link to="/master-data/new-user"
                                                          className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span
                                                            className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                            size={16}/></span>
                                                        <span
                                                            className="ml-2 text-sm tracking-wide truncate">New User</span>
                                                    </Link>
                                                </li>
*/}

{/*
                                                <li>
                                                    <Link to="/master-data/setting"
                                                          className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span
                                                            className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                            size={16}/></span>
                                                        <span
                                                            className="ml-2 text-sm tracking-wide truncate">Setting</span>
                                                    </Link>
                                                </li>
*/}

{/*
                                                <li>
                                                    <Link to="/master-data/user-permission"
                                                          className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span
                                                            className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                            size={16}/></span>
                                                        <span
                                                            className="ml-2 text-sm tracking-wide truncate">{t('UserPermission')}</span>
                                                    </Link>
                                                </li>
*/}
{/*
                                                <li>
                                                    <Link to="/master-data/user-group"
                                                          className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span
                                                            className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                            size={16}/></span>
                                                        <span
                                                            className="ml-2 text-sm tracking-wide truncate">{t('UserGroup')}</span>
                                                    </Link>
                                                </li>
*/}


                                                <li>
                                                    <Link to="/master-data/core" className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span className="inline-flex justify-center items-center ml-4"><HiArrowSmRight size={16}/></span>
                                                        <span className="ml-2 text-sm tracking-wide truncate">{t('Core')}</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/master-data/app-module-child" className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span className="inline-flex justify-center items-center ml-4"><HiArrowSmRight size={16}/></span>
                                                        <span className="ml-2 text-sm tracking-wide truncate">{t('ModuleChild')}</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="domain/setting" className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span className="inline-flex justify-center items-center ml-4"><HiArrowSmRight size={16}/></span>
                                                        <span className="ml-2 text-sm tracking-wide truncate">{t('DomainSetting')}</span>
                                                    </Link>
                                                </li>

                                                {
                                                    hasRoleModerator &&
                                                        <Fragment>
                                                            {/*Client*/}
                                                            {/*<li className="px-5">
                                                        <div className="flex flex-row items-center h-8">
                                                            <div
                                                                className="text-sm font-light tracking-wide text-gray-500">Client
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <Link to="/client"
                                                              className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                            <span
                                                                className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                                size={16}/></span>
                                                            <span
                                                                className="ml-2 text-sm tracking-wide truncate">Client</span>
                                                        </Link>
                                                        <Link to="/client/register"
                                                              className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                            <span
                                                                className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                                size={16}/></span>
                                                            <span className="ml-2 text-sm tracking-wide truncate">RegisterClient</span>
                                                        </Link>
                                                    </li>*/}

                                                            {/*Candidate*/}
                                                            <li className="px-5">
                                                                <div className="flex flex-row items-center h-8">
                                                                    <div
                                                                        className="text-sm font-light tracking-wide text-gray-500">Agency
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <Link to="/master-data/designation" className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                                    <span className="inline-flex justify-center items-center ml-4"><HiArrowSmRight size={16}/></span>
                                                                    <span className="ml-2 text-sm tracking-wide truncate">{t('Designation')}</span>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/candidate" className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                                    <span className="inline-flex justify-center items-center ml-4"><HiArrowSmRight size={16}/></span>
                                                                    <span className="ml-2 text-sm tracking-wide truncate">Candidate</span>
                                                                </Link>
                                                                <Link to="/candidate/register" className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                                    <span className="inline-flex justify-center items-center ml-4"><HiArrowSmRight size={16}/></span>
                                                                    <span className="ml-2 text-sm tracking-wide truncate">{t('RegisterCandidate')}</span>
                                                                </Link>
                                                            </li>
                                                            <li className="px-5">
                                                                <div className="flex flex-row items-center h-8">
                                                                    <div className="text-sm font-light tracking-wide text-gray-500">{t('FileUpload')}</div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <Link to="/candidate/upload" className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                                    <span className="inline-flex justify-center items-center ml-4"><HiArrowSmRight size={16}/></span>
                                                                    <span className="ml-2 text-sm tracking-wide truncate">{t('CandidateUpload')}</span>
                                                                </Link>
                                                                {/*<Link to="/candidate" className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                                    <span className="inline-flex justify-center items-center ml-4"><HiArrowSmRight size={16}/></span>
                                                                    <span className="ml-2 text-sm tracking-wide truncate">{t('ClientUpload')}</span>
                                                                </Link>*/}

                                                            </li>
                                                            <li className="px-5">
                                                                <div className="flex flex-row items-center h-8">
                                                                    <div className="text-sm font-light tracking-wide text-gray-500">{t('Notification')}</div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <Link to="/notification" className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                                    <span className="inline-flex justify-center items-center ml-4"><HiArrowSmRight size={16}/></span>
                                                                    <span className="ml-2 text-sm tracking-wide truncate">{t('Template')}</span>
                                                                </Link>
                                                            </li>
                                                            <li className="px-5">
                                                                <div className="flex flex-row items-center h-8">
                                                                    <div className="text-sm font-light tracking-wide text-gray-500">{t('CRM')}</div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <Link to="master-data/crm" className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                                    <span className="inline-flex justify-center items-center ml-4"><HiArrowSmRight size={16}/></span>
                                                                    <span className="ml-2 text-sm tracking-wide truncate">{t('MasterData')}</span>
                                                                </Link>
                                                            </li>
                                                            <li className="px-5">
                                                                <div className="flex flex-row items-center h-8">
                                                                    <div className="text-sm font-light tracking-wide text-gray-500">{t('Bookkeeping')}</div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <Link to="master-data/account-head" className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                                    <span className="inline-flex justify-center items-center ml-4"><HiArrowSmRight size={16}/></span>
                                                                    <span className="ml-2 text-sm tracking-wide truncate">{t('Head')}</span>
                                                                </Link>
                                                            </li>
                                                        </Fragment>
                                                }

                                            </ul>
                                        </Tab.Panel>
                                        <Tab.Panel>
                                            <ul className="flex flex-col py-4 space-y-1">
                                                <li className="px-5">
                                                    <div className="flex flex-row items-center h-8">
                                                        <div
                                                            className="text-sm font-light tracking-wide text-gray-500">Listing
                                                            View
                                                        </div>
                                                    </div>
                                                </li>
                                            <li className="px-5">
                                                <div className="flex flex-row items-center h-8">
                                                    <div className="text-sm font-light tracking-wide text-gray-500">Company Profile
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <a href="/company-profile"
                                                   className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                    <span className="inline-flex justify-center items-center ml-4"><HiArrowSmRight size={16}/></span>
                                                    <span className="ml-2 text-sm tracking-wide truncate">Company Profile Form</span>
                                                </a>
                                            </li>

                                            <li className="px-5">
                                                <div className="flex flex-row items-center h-8">
                                                    <div className="text-sm font-light tracking-wide text-gray-500">Job Post
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <a href="/job-post/create-job-post"
                                                   className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                    <span className="inline-flex justify-center items-center ml-4"><HiArrowSmRight size={16}/></span>
                                                    <span className="ml-2 text-sm tracking-wide truncate">Create Job Post</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/job-post/update-job-post"
                                                   className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                    <span className="inline-flex justify-center items-center ml-4"><HiArrowSmRight size={16}/></span>
                                                    <span className="ml-2 text-sm tracking-wide truncate">Update Job Post</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <ul className="flex flex-col py-4 space-y-1">
                                            <li className="px-5">
                                                <div className="flex flex-row items-center h-8">
                                                    <div className="text-sm font-light tracking-wide text-gray-500">Reports
                                                    </div>
                                                </div>
                                                </li>
                                                <li>
                                                    <a href="#"
                                                       className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span
                                                            className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                            size={16}/></span>
                                                        <span
                                                            className="ml-2 text-sm tracking-wide truncate">Dashboard</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#"
                                                       className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span
                                                            className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                            size={16}/></span>
                                                        <span
                                                            className="ml-2 text-sm tracking-wide truncate">Dashboard</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#"
                                                       className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span
                                                            className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                            size={16}/></span>
                                                        <span
                                                            className="ml-2 text-sm tracking-wide truncate">Reports</span>
                                                    </a>
                                                </li>
                                                <li className="px-5">
                                                    <div className="flex flex-row items-center h-8">
                                                        <div
                                                            className="text-sm font-light tracking-wide text-gray-500">Menu
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <a href="#"
                                                       className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span
                                                            className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                            size={16}/></span>
                                                        <span
                                                            className="ml-2 text-sm tracking-wide truncate">Dashboard</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#"
                                                       className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span
                                                            className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                            size={16}/></span>
                                                        <span
                                                            className="ml-2 text-sm tracking-wide truncate">Dashboard</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#"
                                                       className="relative flex flex-row items-center h-6 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                                                        <span
                                                            className="inline-flex justify-center items-center ml-4"><HiArrowSmRight
                                                            size={16}/></span>
                                                        <span
                                                            className="ml-2 text-sm tracking-wide truncate">Dashboard</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </Tab.Panel>
                                    </Tab.Panels>
                                </Tab.Group>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
        </>
    );
};

export default Sidebar;