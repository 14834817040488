
import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import Login from './Login';
import {
    HiUserCircle,
    HiOutlineUserCircle,
    HiOutlineLogout,
    HiOutlinePencilAlt
} from "react-icons/hi";

import CandidateViewModal from '../modules/candidate/modal/ViewCandidate';
import ClientViewModal from '../modules/client/modal/ViewClient';
import PasswordUpdateModal from './modal/PasswordUpdateModal';

export default function ProfileDropdown() {
    const {name, loggedUserId, roles, imageName} = JSON.parse(localStorage.getItem('user'));

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [showUserProfile, setShowUserProfile] = useState(false);
    const [showPasswordUpdateModal, setShowPasswordUpdateModal] = useState(false);

    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })

    const hasRoleClient = roles.find(role => {
        return role === 'ROLE_CLIENT';
    })

    const hasRoleCandidate = roles.find(role => {
        return role === 'ROLE_CANDIDATE';
    })

    function Logout(e) {
        localStorage.clear();
        navigate("/login")
        // return <Navigate replace to="login" />;
    }

    return (
        <div className="top-10 text-right">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="group w-full rounded-md  py-2 text-left text-sm font-medium text-gray-700 focus:outline-none">
                        <span className="flex w-full items-center justify-between">
                    <span className="flex min-w-0 items-center justify-between space-x-3">
                      <span className="flex min-w-0 flex-1 flex-col">
                        <span className="truncate text-sm font-medium text-gray-900">{name}</span>
                        {/*<span className="truncate text-sm text-gray-500">@sohel</span>*/}
                      </span>

                        <img
                            className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                            // src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
                            // src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
                            src={
                                imageName ? (
                                (hasRoleCandidate && `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${imageName}`) |
                                (hasRoleClient && `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/client/profile/${imageName}`) |
                                (hasRoleModerator && `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/moderator/profile/${imageName}`)
                                ) : (
                                    "/assets/images/avatar.svg"
                                )

                            }
                            alt="avatar"
                        />

                    </span>
                    <ChevronDownIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                    />
                  </span>
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {/*<div className="px-1 py-1 ">
                            <Menu.Item>
                                {({ active }) => (
                                    <span className={`${ active ? 'bg-violet-500 text-white' : 'text-gray-900' } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >{name}</span>
                                )}
                            </Menu.Item>
                        </div>*/}
                        <div className="px-1 py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${active ? 'bg-violet-500 text-white' : 'text-gray-900' } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        onClick={()=> setShowUserProfile(prevState => !prevState)}
                                    >
                                        {active ? (
                                            <HiOutlineUserCircle
                                                className="mr-2 h-5 w-5 text-indigo-800"
                                                aria-hidden="true"
                                            />

                                        ) : (
                                            <HiOutlineUserCircle
                                                className="mr-2 h-5 w-5 text-indigo-800"
                                                aria-hidden="true"
                                            />

                                        )}
                                        {t('MyProfile')}
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                        <div className="px-1 py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${active ? 'bg-violet-500 text-white' : 'text-gray-900' } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        onClick={()=> setShowPasswordUpdateModal(prevState => !prevState)}
                                    >
                                        {active ? (
                                            <HiOutlinePencilAlt
                                                className="mr-2 h-5 w-5 text-indigo-800"
                                                aria-hidden="true"
                                            />

                                        ) : (
                                            <HiOutlinePencilAlt
                                                className="mr-2 h-5 w-5 text-indigo-800"
                                                aria-hidden="true"
                                            />

                                        )}
                                        {t('ChangePassword')}
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                {({ active }) => (
                                    <button onClick={(e) => Logout(e)}
                                            className={`${
                                                active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        {active ? (
                                            <HiOutlineLogout
                                                className="mr-2 h-5 w-5 text-indigo-800"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <HiOutlineLogout
                                                className="mr-2 h-5 w-5 text-indigo-800"
                                                aria-hidden="true"
                                            />
                                        )}
                                        {t('Logout')}
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>

            { showUserProfile && hasRoleCandidate && <CandidateViewModal candidateid={loggedUserId} setShowModal={setShowUserProfile} />}
            { showUserProfile && hasRoleClient && <ClientViewModal clientID={loggedUserId} setShowModal={setShowUserProfile} />}
            { showPasswordUpdateModal && <PasswordUpdateModal setShowModal={setShowPasswordUpdateModal} />}
            
        </div>
    )
}