import React, { Fragment, useEffect, useState } from "react";

import CompleteListView from './CompleteListView';
import CompleteWeekView from './CompleteWeekView';


function CompleteView() {

    const [view, setView] = useState('list');

    return (
        <Fragment>
            { view === 'list' && <CompleteListView setView={setView} /> }
            { view === 'week' && <CompleteWeekView setView={setView} /> }
        </Fragment>
    );

}
export default CompleteView