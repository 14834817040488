import React, { Fragment } from "react";
import ModeratorView from "./inc/moderator/ModeratorView";
import ClientView from "./inc/client/ClientView";
import CandidateView from "./inc/candidate/CandidateView";



const PER_PAGE = 20;
function Index() {
    const { token, roles, loggedUserId, domainId } = JSON.parse(localStorage.getItem('user'));
    const hasRoleClient = roles.find(role => {
        return role === 'ROLE_CLIENT';
    })
    const hasRoleCandidate = roles.find(role => {
        return role === 'ROLE_CANDIDATE';
    })
    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })

    return (
        <Fragment>
            {hasRoleModerator && <ModeratorView />}
            {hasRoleClient && <ClientView />}
            {hasRoleCandidate && <CandidateView />}
        </Fragment>
    );

}
export default Index