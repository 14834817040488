import React from "react";
export default function ActionModal(props) {
    const data = props.data;
    console.log(data)
    return (
        <>
            
            {props.show ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray-700"
                    >
                        <div className="relative  w-screen">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full  bg-white outline-none focus:outline-none">
                                <div className="relative p-6 flex-auto flex">
                                    <div role="alert" className="container mx-auto">
                                        <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                                            <div className="w-full flex justify-start text-gray-600 mb-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-wallet" width="52"
                                                     height="52" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none"
                                                     stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z"/>
                                                    <path
                                                        d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"/>
                                                    <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4"/>
                                                </svg>
                                            </div>

<h1>{data.title+' '+data.userId+' '+data.id}</h1>

                                            <h1 className="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4">Enter Billing
                                                Details</h1>
                                            <label htmlFor="name" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">Owner
                                                Name</label>

                                           <div className="flex">
                                           <div className="w-1/2 p-3">
                                            <input id="name" value={data.title?data.title:null}
                                                   className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                                                   placeholder="James"/>
                                            <label htmlFor="email2" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">Card
                                                Number</label>
                                            <div className="relative mb-5 mt-2">
                                                <div className="absolute text-gray-600 flex items-center px-4 border-r h-full">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-credit-card"
                                                         width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                         fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z"/>
                                                        <rect x="3" y="5" width="18" height="14" rx="3"/>
                                                        <line x1="3" y1="10" x2="21" y2="10"/>
                                                        <line x1="7" y1="15" x2="7.01" y2="15"/>
                                                        <line x1="11" y1="15" x2="13" y2="15"/>
                                                    </svg>
                                                </div>
                                                <input id="email2" value={data.userId?data.userId:null}
                                                       className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-16 text-sm border-gray-300 rounded border"
                                                       placeholder="XXXX - XXXX - XXXX - XXXX"/>
                                            </div>
                                            <label htmlFor="expiry" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">Expiry
                                                Date</label>
                                            <div className="relative mb-5 mt-2">
                                                <div className="absolute right-0 text-gray-600 flex items-center pr-3 h-full cursor-pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-event"
                                                         width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                         fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z"/>
                                                        <rect x="4" y="5" width="16" height="16" rx="2"/>
                                                        <line x1="16" y1="3" x2="16" y2="7"/>
                                                        <line x1="8" y1="3" x2="8" y2="7"/>
                                                        <line x1="4" y1="11" x2="20" y2="11"/>
                                                        <rect x="8" y="15" width="2" height="2"/>
                                                    </svg>
                                                </div>
                                                <input id="expiry"
                                                       className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                                                       placeholder="MM/YY"/>
                                            </div>
                                            <label htmlFor="cvc"
                                                   className="text-gray-800 text-sm font-bold leading-tight tracking-normal">CVC</label>
                                            <div className="relative mb-5 mt-2">
                                                <div className="absolute right-0 text-gray-600 flex items-center pr-3 h-full cursor-pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-circle"
                                                         width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                         fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z"></path>
                                                        <circle cx="12" cy="12" r="9"></circle>
                                                        <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                                        <polyline points="11 12 12 12 12 16 13 16"></polyline>
                                                    </svg>
                                                </div>
                                                <input id="cvc"
                                                       className="mb-8 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                                                       placeholder="MM/YY"/>
                                            </div>

                                            </div>
                                            <div className="w-1/2 p-3"><input id="name" value={data.id?data.id:null}
                                                   className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                                                   placeholder="James"/>
                                            <label htmlFor="email2" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">Card
                                                Number</label>
                                            <div className="relative mb-5 mt-2">
                                                <div className="absolute text-gray-600 flex items-center px-4 border-r h-full">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-credit-card"
                                                         width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                         fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z"/>
                                                        <rect x="3" y="5" width="18" height="14" rx="3"/>
                                                        <line x1="3" y1="10" x2="21" y2="10"/>
                                                        <line x1="7" y1="15" x2="7.01" y2="15"/>
                                                        <line x1="11" y1="15" x2="13" y2="15"/>
                                                    </svg>
                                                </div>
                                                <input id="email2"
                                                       className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-16 text-sm border-gray-300 rounded border"
                                                       placeholder="XXXX - XXXX - XXXX - XXXX"/>
                                            </div>
                                            <label htmlFor="expiry" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">Expiry
                                                Date</label>
                                            <div className="relative mb-5 mt-2">
                                                <div className="absolute right-0 text-gray-600 flex items-center pr-3 h-full cursor-pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-event"
                                                         width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                         fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z"/>
                                                        <rect x="4" y="5" width="16" height="16" rx="2"/>
                                                        <line x1="16" y1="3" x2="16" y2="7"/>
                                                        <line x1="8" y1="3" x2="8" y2="7"/>
                                                        <line x1="4" y1="11" x2="20" y2="11"/>
                                                        <rect x="8" y="15" width="2" height="2"/>
                                                    </svg>
                                                </div>
                                                <input id="expiry"
                                                       className="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                                                       placeholder="MM/YY"/>
                                            </div>
                                            <label htmlFor="cvc"
                                                   className="text-gray-800 text-sm font-bold leading-tight tracking-normal">CVC</label>
                                            <div className="relative mb-5 mt-2">
                                                <div className="absolute right-0 text-gray-600 flex items-center pr-3 h-full cursor-pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-circle"
                                                         width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                         fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z"></path>
                                                        <circle cx="12" cy="12" r="9"></circle>
                                                        <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                                        <polyline points="11 12 12 12 12 16 13 16"></polyline>
                                                    </svg>
                                                </div>
                                                <input id="cvc"
                                                       className="mb-8 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                                                       placeholder="MM/YY"/>
                                            </div>
  
</div>
                                           </div>

                                            
                                            <div className="flex items-center justify-start w-full text-right">
                                                <button
                                                    className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm">Submit
                                                </button>
                                                <button onClick={props.close}
                                                    className="focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
                                                      >Cancel
                                                </button>
                                            </div>
                                            <button onClick={props.close}
                                                className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
                                                 aria-label="close modal" role="button">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="20"
                                                     height="20" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" fill="none"
                                                     stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z"/>
                                                    <line x1="18" y1="6" x2="6" y2="18"/>
                                                    <line x1="6" y1="6" x2="18" y2="18"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </>
            ) : null}
        </>
    );
}