import React, { useEffect, useState } from "react";
import {HiOutlineArrowRight, HiOutlineX, HiOutlineSearch, HiOutlineAtSymbol, HiOutlineCheck, HiStar} from "react-icons/hi";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useParams} from "react-router";
import {showNotification} from "@mantine/notifications";
import {ScrollArea, TextInput, Loader, LoadingOverlay, Checkbox, Modal} from '@mantine/core';
import {format} from 'date-fns';
import CandidateListTr from "./inc/CandidateListTr";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiArrowRight } from "react-icons/fi";
import {
    HiOutlinePlus
} from "react-icons/hi";

export default function PreviousEventAddCandidateModal({setShowModal, reqDesignation, setRecallCandidates}) {
  
    const {t, i18n} = useTranslation();
    const {token, loggedUserId} = JSON.parse(localStorage.getItem('user'))
    const {jobId} = useParams();
    const [showCandidateProfile, setShowCandidateProfile] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [candidateId, setCandidateId] = useState(null);
    const [candidates, setCandidates] = useState([]);
    const [assignCandidates, setAssignCandidates] = useState([]);
    const [spinner, setSpinner] = useState(false);


    // const [candidateFilterdList, setCandidateFilterdList] = useState(candidates);
    const [candidateFilterdList, setCandidateFilterdList] = useState([]);


    // designation-wise-candidate-list

    const getCandidates = (designationSlug) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/designation-wise-candidate-list`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {slugs: [designationSlug]}
        })
            .then(res => {
                setCandidates(res.data.data);
                setCandidateFilterdList(res.data.data);
                setShowLoader(false)
                
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const addCandidate = () => {
        if(assignCandidates.length === 0){
            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('Error')),
                message: (t('PleaseSelectCandidate')),
                autoClose: 2000,
                disallowClose: true,
                color: 'red',
            });
            return;
        }

        const data = {
            jobId: reqDesignation['jobId'],
            reqDesignationId: reqDesignation['id'],
            designationSlug: reqDesignation['designationSlug'],
            loggedUserId,
            candidates: assignCandidates
        }

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/assign-candidate-in-previous-job`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: data
        })
            .then(res => {
                setRecallCandidates(prevState => !prevState)
                setShowModal(false);
            })
            .catch(function (error) {
                console.log(error)
            });

        console.log(data);
    }

    const searchCandidate = (e) => {
        // console.log(candidateList)
   
        // setSearching(true);
        const query = e.target.value;
        // let updatedList = [...candidates];
        let filterUpdatedList = candidates.length > 0 && candidates.filter(candidate => {
            // return (item['name'].toLowerCase().indexOf(query.toLowerCase()) !== -1) || (item['name'].toLowerCase().indexOf(query.toLowerCase()) !== -1) || (item['phoneNumber'].indexOf(query) !== -1);
            return (candidate['userName'].toLowerCase().includes(query.toLowerCase())) || (candidate['email'].toLowerCase().includes(query.toLowerCase())) || (candidate['phone'].includes(query));

        });

        setCandidateFilterdList(filterUpdatedList);

        // setSearching(false);
    }

    useEffect(()=>{
        getCandidates(reqDesignation['designationSlug']);
    }, [])

   
    return (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.8]">                
            <div className="relative w-3/4">
                {/*content*/}
                <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                    <div className="pt-4 modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                        <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                            <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{reqDesignation['designationName']}</h5>
                            <button
                                onClick={() => {
                                    // setRecall(prevState => !prevState)
                                    setShowModal(false)
                                }}
                                className="inline-flex items-center text-right font-normal text-gray-600 ">
                                <HiOutlineX className="h-5 w-5" aria-hidden="true"/>
                            </button>
                        </div>




                        <div className="modal-body relative">
                                <div className="relative flex w-full">
                                    <TextInput
                                        onChange={(e) => e && searchCandidate(e)}
                                        placeholder=" name, email or phone"
                                        className="w-full px-2"
                                        icon={<HiOutlineSearch className="ml-1"/>}
                                        iconWidth={15}
                                    />
                                </div>

                                <ScrollArea scrollbarSize={4} style={{height: 500}}>

                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t('Name')}</th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{t('Phone')}</th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"><div className="flex"><span>{t('CheckIn')}</span> <HiStar size={7} color="red" /></div></th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"><div className="flex"><span>{t('CheckOut')}</span> <HiStar size={7} color="red" /></div></th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{t('BreakDuration')}({t('min')}.)</th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
                                        </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white relative">
                                            <LoadingOverlay visible={showLoader} overlayBlur={2} className="h-full" loaderProps={{ color: 'indigo', size: 'xs' }} overlayColor="bg-indigo-100" h={100}/>

                                            {
                                                candidateFilterdList.map(candidate => (
                                                    <CandidateListTr key={candidate['userId']} candidate={candidate} assignCandidates={assignCandidates} setAssignCandidates={setAssignCandidates} />
                                                ))
                                            }

                                            {
                                                candidateFilterdList.length === 0 && (
                                                    <tr>
                                                        <td colSpan={6} className="text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm">
                                                            {t('NoAvailableCandidates')}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                    
                                </ScrollArea>
                                <footer className="relative mt-1">
                                    <div className="text-right pt-0.5 mb-0.5">
                                        <button
                                            type="button"
                                            className="px-6 py-1 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-sm"
                                            onClick={addCandidate}
                                        >
                                            {spinner ? (<AiOutlineLoading3Quarters className={'mr-1 animate-spin'} size={16} />) : (<HiOutlinePlus size={12} className={'mr-1'} />)}
                                            <span>{t('Add')}</span>
                                        </button>
                                    </div>
                                </footer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
