import React, { Fragment, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { format, lastDayOfMonth } from 'date-fns';
import { DataTable } from "mantine-datatable";
import { Box, Group, Text, Select, ScrollArea, TextInput, Grid, Drawer, useMantineTheme, NumberInput, Tooltip } from "@mantine/core";
import axios from "axios";

import NoShowModal from "../../../modals/NoShowModal";
import DisputeModal from "../../../../crm/modals/DisputeModal";





const PER_PAGE = 20;
function ProcessingView() {
    const { t, i18n } = useTranslation();
    const { token, roles, loggedUserId, domainId } = JSON.parse(localStorage.getItem('user'));
    const [showDisputeModal, setShowDisputeModal] = useState(false);
    const [showNoShowModal, setShowNoShowModal] = useState(false);


    const theme = useMantineTheme();

    const [timeSheet, setTimeSheet] = useState({});

    const fullTableHeight = localStorage.getItem('fullTableHeight');
    const [page, setPage] = useState(1);
    const drawerHeight = localStorage.getItem('drawerHeight');

    const [data, setData] = useState([]);
    const [jobId, setJobId] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [sortStatus, setSortStatus] = useState({ columnAccessor: 'jobTitle', direction: 'asc' });
    const [refreshList, setRefreshList] = useState(false);


    const [spinner, setSpinner] = useState(false);



    const [clients, setClients] = useState([]);

    const getClients = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/client-list-for-filter-by-candidate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                candidateId: loggedUserId
            }
        })
            .then(res => {
                let newArray = res.data.data;
                if(0 < newArray.length){
                    newArray.unshift({userId: -1, userName: 'All'});
                }
                
                setClients(res.data.data)
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    useEffect(() => {
        getClients()
    }, [])


    const [jobList, setJobList] = useState([]);
    const [clientId, setClientId] = useState(null);
    const getJobList = (clientId) => {
        setClientId(clientId);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-list-for-time-sheet`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                loggedUserId,
                roles,
                flag: 'processing',
                clientId
            }
        })
            .then(res => {
                setJobList(res.data.data);
            })
            .catch(function (err) {
                console.log(err);

            });
    }

    const timeSheetparams = {
        roles,
        loggedUserId,
        jobId,
        per_page: PER_PAGE,
        page: page,
        flag: 'processing'
    }

    function jobFilterSubmit(id) {
        setJobId(id)
        timeSheetparams['jobId'] = id;

        // console.log(timeSheetparams);
        getTimeSheet(timeSheetparams);
    }

    const getTimeSheet = (params) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-time-sheet`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: params
        })
            .then(res => {
                setData(res.data);
                setFetching(false);
            })
            .catch(function (err) {
                console.log(err);

            });
    }

    const disputeModal = (timeSheet) => {
        setTimeSheet(timeSheet);
        setShowDisputeModal(prevState => !prevState);
    }

    const noShowModal = (timeSheet) => {
        setTimeSheet(timeSheet);
        setShowNoShowModal(prevState => !prevState);
    }

    useEffect(() => {
        getJobList();
        getTimeSheet(timeSheetparams);
    }, []);


    useEffect(() => {
        getTimeSheet(timeSheetparams);
    }, [fetching, refreshList]);

    useEffect(() => {
        setFetching(true);
    }, [sortStatus]);

    return (
        <Fragment>
            <div className="my-2 p-1 bg-gray-400 rounded">
                <Grid align="center">
                    <Grid.Col span="auto">
                        <Select
                            placeholder={t('SelectClient')}
                            data={clients.map((client, index) => ({ value: client['userId'], label: client['userName'] }))}
                            onChange={(e) => getJobList(e)}
                            // onChange={(e) => console.log(e)}
                            nothingFound={t('NoClients')}
                            maxDropdownHeight={280}
                            searchable
                        />
                    </Grid.Col>
                    <Grid.Col span="auto">
                        <Select
                            placeholder={t('SelectJob')}
                            data={jobList.map((job, index) => ({ value: job['id'], label: job['title'] }))}
                            onChange={(e) => jobFilterSubmit(e)}
                            value={jobId}
                            nothingFound="No Jobs"
                            maxDropdownHeight={280}
                            searchable
                        />
                    </Grid.Col>
                </Grid>
            </div>
            <Box sx={{ height: fullTableHeight }}>
                <DataTable
                    noRecordsText={t('NoRecords') + ' !'}
                    withBorder
                    records={data.data}
                    striped
                    styles={(theme) => ({
                        root: {
                            border: '1px solid #e0e7ff',
                            borderRadius: 2,
                        },
                        header: {
                            background: '#e0e7ff',
                            backgroundColor: "#e0e7ff",
                            color: theme.colors.indigo[6],
                        },
                        pagination: {
                            color: theme.colors.gray[3],
                            paddingTop: "5px",
                            paddingBottom: "5px"
                        },
                        item: {
                            '&[data-active]': {
                                backgroundImage: theme.fn.gradient({ from: 'red', to: 'yellow' }),
                            },
                        },
                    })}
                    columns={[
                        {
                            accessor: '',
                            title: (t('No.')),
                            width: 45,
                            render: (item) => (data.data.indexOf(item) + 1) + PER_PAGE * (page - 1)
                        },
                        {
                            accessor: 'jobTitle',
                            title: (t('JobTitle')),
                            // render: ({jobTitle}) => <Link to={`/job-post`} className={`text-red`}>{jobTitle}</Link>,

                        },
                        {
                            accessor: 'clientName',
                            title: (t('ClientName')),
                            // sortable: true
                            // render: ({jobTitle}) => <Link to={`/job-post`} className={`text-red`}>{jobTitle}</Link>,

                        },
                        {
                            accessor: 'designationName',
                            title: (t('Designation')),
                            // sortable: true
                        },
                        {
                            accessor: 'workingDate',
                            title: (t('Date')),
                            // render: ({ workingDate }) => workingDate && format(new Date(workingDate.date), 'MM/dd/yyyy'),
                        },
                        {
                            accessor: 'inTime',
                            title: (t('CheckInTime')),
                        },
                        {
                            accessor: 'checkInTime',
                            // width: 100,
                            title: (t('InTime')),
                        },
                        {
                            accessor: 'checkOutTime',
                            title: (t('OutTime')),
                            // width: 100,

                        },
                        {
                            accessor: 'breakDuration',
                            title: (t('BreakDuration')),
                            // width: 120,
                            render: (timeSheet) => timeSheet['breakDuration'] >= 0 && timeSheet['breakDuration'] + ' min',
                        },
                        // {
                        //     accessor: 'totalHour',
                        //     title: (t('Hour')),
                        //     // width: 100,
                        //     render: ({ totalHour }) => totalHour ? totalHour : 0
                        // },
                        {
                            accessor: 'disputeNo',
                            title: (t('disputeNo')),
                            render: (timeSheet) => timeSheet['disputeNo'] && (
                                <Tooltip
                                    multiline
                                    width={500}
                                    withArrow
                                    position="left"
                                    color="indigo"
                                    className="text-left"
                                    transitionProps={{ duration: 200 }}
                                    disabled={!!!timeSheet['disputeDetails']}
                                    label={ timeSheet['disputeDetails'] && timeSheet['disputeDetails'] }
                                    >
                                        <span className="w-20 justify-center inline-flex items-center ml-1 rounded border-none bg-yellow-400 py-1 px-2 text-xs font-normal text-white shadow-sm focus:outline-none focus:ring-0">{timeSheet['disputeNo']}</span>
                                </Tooltip>
                            )
                        },

                        /*{
                            accessor: 'rate',
                            title: (t('Rate')),
                            render: ({rate}) => rate ? ("$" + rate) : 0,

                        },
                        {
                            accessor: 'totalAmount',
                            title: (t('Amount')),
                            render: ({totalAmount}) => totalAmount ? ("$" + totalAmount) : 0,

                        },*/
                        {
                            accessor: 'actions',
                            title: <Text mr="xs">{t('Action')}</Text>,
                            // textAlignment: 'right',
                            // width: 160,
                            render: (timeSheet) => (
                                <Group spacing={1}>

                                    {
                                        timeSheet['finalApprove'] && timeSheet['checkInTime'] && timeSheet['checkOutTime'] && !timeSheet['noShow'] && !timeSheet['dispute'] && (
                                            <Tooltip
                                                multiline
                                                width={500}
                                                withArrow
                                                position="left"
                                                color="indigo"
                                                className="text-left"
                                                transitionProps={{ duration: 200 }}
                                                disabled={!!!timeSheet['disputeRemark']}
                                                label={ timeSheet['disputeRemark'] && timeSheet['disputeRemark'] }
                                                >
                                                    <span className="w-20 justify-center inline-flex items-center ml-1 rounded border-none bg-green-700 py-1 px-2 text-xs font-normal text-white shadow-sm focus:outline-none focus:ring-0">
                                                        {t('Approved')}
                                                    </span>
                                            </Tooltip>
                                            
                                            
                                            
                                        )

                                    }
                                    {
                                        !timeSheet['finalApprove'] && !timeSheet['checkInTime'] && !timeSheet['checkOutTime'] && timeSheet['noShow'] && !timeSheet['dispute'] && (
                                            <Tooltip
                                                multiline
                                                width={220}
                                                withArrow
                                                position="left"
                                                transitionProps={{ duration: 200 }}
                                                label={timeSheet['noShowRemark']}
                                                >
                                                    <span
                                                        className="w-20 justify-center text-gray-600 bg-gray-300 inline-flex items-center ml-1 rounded border-none py-1 px-2 text-xs font-normal text-white shadow-sm focus:outline-none focus:ring-0"
                                                    >
                                                        {t('NoShow')}
                                                    </span>
                                            </Tooltip>
                                            
                                        )
                                    }

                                    {
                                        timeSheet['dispute'] && (
                                            <span
                                                // onClick={() => disputeModal(timeSheet)}
                                                className="w-20 justify-center inline-flex items-center ml-1 rounded border-none bg-amber-800 py-1 px-2 text-xs font-normal text-white shadow-sm focus:outline-none focus:ring-0"
                                            >
                                                {t('Disputed')}
                                            </span>
                                        )
                                    }



                                </Group>
                            ),
                        },

                    ]}
                    totalRecords={data.totalRecords}
                    recordsPerPage={PER_PAGE}
                    page={page}
                    onPageChange={(p) => {
                        setPage(p)
                        setFetching(true)
                    }}
                    fetching={fetching}
                    loaderSize="sm"
                    loaderColor="indigo"
                    loaderBackgroundBlur={2}
                // sortStatus={sortStatus}
                // onSortStatusChange={setSortStatus}
                />
            </Box>

            {showNoShowModal && <NoShowModal setShowModal={setShowNoShowModal} detailsData={timeSheet} />}
            {showDisputeModal && <DisputeModal setShowModal={setShowDisputeModal} disputeDetailsData={timeSheet} flag={`time-sheet`} setRefreshList={setRefreshList} />}
        </Fragment>
    );

}
export default ProcessingView