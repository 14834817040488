import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format, lastDayOfMonth, startOfWeek, endOfWeek } from 'date-fns';
import { Select, ScrollArea, Grid, LoadingOverlay, Switch, Text } from "@mantine/core";
import axios from "axios";
import {
    HiOutlineClipboardList,
    HiOutlineCalendar
} from "react-icons/hi";



function CompleteWeekView({setView}) {
    const { t, i18n } = useTranslation();
    const { token, roles, loggedUserId, domainId } = JSON.parse(localStorage.getItem('user'));


    const fullTableHeight = localStorage.getItem('fullTableHeight');

    const [jobId, setJobId] = useState(null);


    const [dateRange, setDateRange] = useState([]);
    const [minDateRange, setMinDateRange] = useState('');
    const [maxDateRange, setMaxDateRange] = useState('');
    const [weeklyTimeSheetData, setWeeklyTimeSheetData] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [showDecimalFormat, setShowDecimalFormat] = useState(true);



    const [jobList, setJobList] = useState([]);
    const getJobList = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-list-for-time-sheet`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                loggedUserId,
                roles,
                flag: 'complete',
            }
        })
            .then(res => {
                let newArray = res.data.data;
                if(0 < newArray.length){
                    newArray.unshift({id: -1, title: 'All'});
                }
                
                setJobList(newArray);
            })
            .catch(function (err) {
                console.log(err);

            });
    }
    

    function weekViewSubmit(){
        setShowLoader(true);
        if(dateRange.length > 0 ){
            timeSheetparams['dateRange'] = dateRange;
        }

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-time-sheet-weekly`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: timeSheetparams
        })
            .then(res => {
                // console.log(res)
                setWeeklyTimeSheetData(res.data.data);
                setShowLoader(false);

            })
            .catch(function (err) {
                console.log(err);

            });
    }

    useEffect(() => {
        weekViewSubmit();
        getJobList();
    }, []);


    const timeSheetparams = {
        roles,
        loggedUserId,
        jobId,
        flag: 'complete',
        dateRange: [format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd HH:mm:ss'), format(endOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd HH:mm:ss')]
    }
    return (
        <Fragment>
            <div className="my-2 p-1 bg-gray-400 rounded">
                <Grid align="center">
                    <Grid.Col span={6}>
                        <Select
                            placeholder={t('SelectJob')}
                            data={jobList.map((job, index) => ({ value: job['id'], label: job['title'] }))}
                            onChange={(e) => setJobId(e)}
                            value={jobId}
                            nothingFound={t('NoJobs')}
                            maxDropdownHeight={280}
                            searchable
                        />
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <input
                            onChange={(e) => {
                                dateRange[0]= format(new Date(e.target.value), 'yyyy-MM-dd 00:00:00');
                                setMinDateRange(e.target.value)
                            }}
                            type="date"
                            className={`form-input-sm-control`}
                            
                            // value={element.startTime ? format(new Date(element.startTime.date), "yyyy-MM-dd") : ''}
                            // min={format(new Date(jobDetails.startTime.date), "yyyy-MM-dd")}
                            max={maxDateRange && format(new Date(maxDateRange), "yyyy-MM-dd")}
                        />
                        
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <input
                            onChange={(e) => {
                                dateRange[1]= format(new Date(e.target.value), 'yyyy-MM-dd 23:59:59');
                                setMaxDateRange(e.target.value);
                            }}
                            type="date"
                            className={`form-input-sm-control`}
                            // value={element.startTime ? format(new Date(element.startTime.date), "yyyy-MM-dd") : ''}
                            min={minDateRange && format(new Date(minDateRange), "yyyy-MM-dd")}
                            // max={format(new Date(jobDetails.endTime.date), "yyyy-MM-dd")}
                        />
                    </Grid.Col>
                    <Grid.Col span="auto" className='text-right flex justify-between'>
                        <button
                            onClick={(e) => weekViewSubmit()}
                            className={`w-full disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center rounded border-none bg-red-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-0`}
                        >
                            <HiOutlineCalendar size={12} className={'mr-1'} />{t('Week')}
                        </button>
                        <button
                            onClick={(e) => {
                                setView('list')
                            }}
                            className={`w-full ml-1 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center rounded border-none bg-gray-600 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-0`}
                        >
                            <HiOutlineClipboardList size={12} className={'mr-1'} />{t('List')}
                        </button>
                    </Grid.Col>
                    
                </Grid>
            </div>

            <ScrollArea scrollbarSize={4} style={{height: fullTableHeight}} className="relative">
            { <LoadingOverlay visible={showLoader} overlayBlur={2} loaderProps={{ size: 'sm', color: 'indigo' }} overlayColor="bg-indigo-100"/> }
                <div className="">
                    <div className="flow-root">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">

                                <table className="min-w-full divide-y divide-gray-300 border border-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                    <th scope="col" className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t('Name')}</th>
                                    <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">{t('JobTitle')}</th>
                                    <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">{t('Position')}</th>
                                    <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">{t('Date')}</th>
                                    <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">{t('InTime')}</th>
                                    <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">{t('OutTime')}</th>
                                    <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">{t('Break')}</th>
                                    <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">
                                        <Switch radius={`sm`} w={85} onChange={() => setShowDecimalFormat(!showDecimalFormat)} size="lg" color={`indigo.2`} checked={showDecimalFormat} onLabel={<Text fz={12} fw={600}>{t('Decimal')}</Text>} offLabel={<Text fz={12} fw={600}>{t('Hour')}</Text>} />
                                    </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {
                                        weeklyTimeSheetData && Object.keys(weeklyTimeSheetData).map((name, index) => (
                                            weeklyTimeSheetData[name].map((item, itemIndex) => (
                                                <tr key={itemIndex} className={itemIndex % 2 === 0 ? undefined : 'bg-gray-50'}>
                                                    {itemIndex === 0 && <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 border-r border-gray-200" rowSpan={weeklyTimeSheetData[name].length}>{item['name']}</td> }
                                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{item['jobTitle']}</td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{item['designationName']}</td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{item['workingDate'] && format(new Date(item['workingDate'].date), 'MM/dd/yyyy')}</td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{item['checkInTime']}</td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{item['checkOutTime']}</td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{item['breakDuration']}</td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{showDecimalFormat ? item['totalHourDecimalFormat'] : item['totalHour']}</td>
                                                    
                                                </tr>
                                            ))
                                            
                                        ))
                                    }
                                    
                    
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollArea>
        </Fragment>
    );

}
export default CompleteWeekView