import React from "react";

const InputRadio = ({ htmlId, labelText, name, checked }) => {
    return (
        <div className="flex items-center">
            <input
                id={htmlId}
                name={name}
                type="radio"
                defaultChecked={checked}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <label
                htmlFor={htmlId}
                className="ml-3 block text-sm font-medium text-gray-700"
            >
                {labelText}
            </label>
        </div>
    );
};

export default InputRadio;
