import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingOverlay } from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import { useParams } from "react-router";

export default function CandidateList({candidate, designation, setCandidateId, setShowCandidateProfile}){
    const { t, i18n } = useTranslation();
    const {token, loggedUserId} = JSON.parse(localStorage.getItem('user'))

    const {jobId} = useParams();
    const [recallAssignedCandidate, setRecallAssignedCandidate] = useState(false);
    const [showSelectLoader, setShowSelectLoader] = useState(false);
    const [assignedCandidateList, setAssignedCandidateList] = useState([]);

    const getAssignedCandidate = () => {
        // job-assign-candidate
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-assign-candidate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {jobId}
        })
            .then(res => {
                if(res.data.data){
                    setAssignedCandidateList(res.data.data[designation.id + '-' + designation.slug] ? res.data.data[designation.id + '-' + designation.slug] : []);
                }
                setShowSelectLoader(false);
            })
            .catch(function (error) {
                console.log(error)
                setShowSelectLoader(false);

            })
    }

    const addCandidate = (checked, candidate) => {
        const data = {
            jobId: Number(jobId),
            designation,
            candidate,
            loggedUserId
        }
        if (checked){
            setShowSelectLoader(true);
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-assign-candidate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: data
        })
            .then(res => {
                if (res.data.status === 409){
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Exist') + ' !'),
                        message: (t('AlreadyAssigned')),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });
                }else if (res.data.status === 406){
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Warning') + ' !'),
                        message: (t(res.data.message)),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });
                }else if (res.data.status === 201){
                    setRecallAssignedCandidate(prevState => !prevState);

                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Success') + ' !'),
                        message: (t('Assigned')),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'green',
                    });
                }

            setShowSelectLoader(false);


            })
            .catch(function (error) {
                console.log(error)
            })
        }

    }

    useEffect(()=>{
        getAssignedCandidate(jobId);
    },[recallAssignedCandidate]);

    return (
        <tr>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                <div
                className="flex items-center cursor-pointer"
                onClick={(e) => {
                    setCandidateId(candidate['userId'])
                    setShowCandidateProfile(true)
                }}
                >
                    <div className="h-10 w-10 flex-shrink-0">
                        <img className="h-10 w-10 rounded-full"
                            //  src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                src={candidate['photo'] ? `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${candidate['photo']}` : "/assets/images/avatar.svg"}
                                alt="" />
                    </div>
                    <div className="ml-4">
                        <div className="font-medium text-gray-900 hover:underline hover:text-indigo-900">{candidate.name}<span className="ml-2 inline-flex items-center rounded bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800">{t('Details')}</span></div>
                        <div className="text-gray-500">{candidate.email}</div>
                    </div>
                </div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div className="text-gray-900">{candidate.phone}</div>
            </td>
            <td className="relative whitespace-nowrap p-2 text-right text-sm font-medium w-12">
                {/*<input disabled={true} type="checkbox" className="disabled:cursor-not-allowed disabled:bg-gray-500 disabled:text-gray-500 h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" onChange={(e) => assignCandidate(e.target.checked, candidate)}/>*/}
                {/* <input type="checkbox" disabled={assignedCandidateList.findIndex(assignedCandidate => assignedCandidate['userId'] === candidate['userId'])}  className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" onChange={e => additionalCandidate(e.target.checked, candidate)}/> */}
                <div className="relative h-6 w-6">
                    {/* {console.log(assignedCandidateList.findIndex(assignedCandidate => assignedCandidate.userId === candidate.id))} */}
                    <input
                        type="checkbox"
                        checked={ assignedCandidateList.length > 0 && assignedCandidateList.findIndex(assignedCandidate => assignedCandidate.userId === candidate['userId']) > -1 }
                        className="disabled:cursor-not-allowed disabled:bg-gray-500 disabled:text-gray-500 h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-transparent"
                        disabled={ assignedCandidateList.length > 0 && assignedCandidateList.findIndex(assignedCandidate => assignedCandidate.userId === candidate['userId']) > -1 }
                        onChange={e => addCandidate(e.target.checked, candidate)}
                    />
                    <LoadingOverlay visible={showSelectLoader} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'xs' }} overlayColor="bg-indigo-100" />
                </div>
            </td>
        </tr>
    );
}