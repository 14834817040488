import { useState } from "react";
import {
    ActionIcon,
    Anchor,
    Button,
    Divider,
    Group,
    Popover,
    Radio,
    RadioGroup,
    TextInput
} from "@mantine/core";
import { useSetState } from "@mantine/hooks";
import { BiFilterAlt as FilterIcon } from "react-icons/bi";

const StringFilter = (props) => {
    const {
        column: { filterValue, setFilter }
    } = props;
    const [opened, setOpened] = useState(false);
    const [state, setState] = useSetState(
        filterValue || { operator: "cont", value: "" }
    );

    const handleClose = () => {
        setState(filterValue || { operator: "cont", value: "" });
        setOpened(false);
    };

    const handleClear = () => {
        setFilter(undefined);
        setState({ operator: "cont", value: "" });
        setOpened(false);
    };

    const handleApply = () => {
        setFilter(state);
        setOpened(false);
    };

    return (
        <Popover
            target={
                <ActionIcon
                    variant={filterValue ? "light" : "hover"}
                    color={filterValue ? "blue" : "gray"}
                    onClick={() => setOpened((o) => !o)}
                >
                    <FilterIcon />
                </ActionIcon>
            }
            opened={opened}
            onClose={handleClose}
            onClick={(e) => e.stopPropagation()}
            position="bottom"
            transition="scale-y"
            // zIndex={10000}
        >
            <Radio
                description="Select your option"
                variant="vertical"
                size="sm"
                value={state.operator}
                onChange={(o) => setState({ operator: o })}
            >
                <Radio value="cont">Contains</Radio>
                <Radio value="not_cont">Does not contain</Radio>
                <Radio value="start">Starts with</Radio>
                <Radio value="end">Ends with</Radio>
                <Radio value="eq">Equals</Radio>
                <Radio value="not_eq">Not equal</Radio>
            </Radio>
            <Divider my="sm" />
            <TextInput
                placeholder="Enter text"
                mb="sm"
                data-autoFocus
                value={state.value}
                onChange={(e) => setState({ value: e.target.value })}
            />
            <Group position="apart">
                <Anchor component="button" color="gray" onClick={handleClear}>
                    Clear
                </Anchor>
                <Button onClick={handleApply}>Apply</Button>
            </Group>
        </Popover>
    );
};

export default StringFilter;
