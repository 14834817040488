import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Tooltip, ScrollArea, LoadingOverlay, Popover, HoverCard} from '@mantine/core';
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {notEmpty} from "@mantine/form";
import ChevronDoubleRightIcon from "@heroicons/react/20/solid/ChevronDoubleRightIcon";
import ChevronDoubleLeftIcon from "@heroicons/react/20/solid/ChevronDoubleLeftIcon";
import {HiArrowCircleRight, HiArrowSmRight, HiOutlineOfficeBuilding, HiOutlinePlus, HiOutlineX, HiChevronRight} from "react-icons/hi";
import ViewJob from "./../job-post/modal/ViewJob";
import JobTitle from './inc/JobTitle';



// const selectedDay = days.find((day) => day.isSelected)


function Index() {
    let jobTitleBackgroundClass = 'bg-indigo-200';

    const {domainId, loggedUserId, roles, token} = JSON.parse(localStorage.getItem('user'));

    const {t, i18n} = useTranslation();

    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState((new Date()).getFullYear());
    const [days, setDays] = useState([]);
    // const selectedDay = days.find((day) => day.isSelected);
    const calendarHeight = localStorage.getItem('calendarHeight');
    const [showModal, setShowModal] = useState(false);
    const [calendarChangeButton, setCalendarChangeButton] = useState(true);
    const [showJobListModal, setShowJobListModal] = useState(false);

    const navigate = useNavigate();

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    // const selectedDay = true


    const [candidateJobList, setCandidateJobList] = useState([]);
    const getCandidateJobs = () => {
        // candidate-job-list
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/candidate-job-list`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                userId: loggedUserId,
                flags: ['applied', 'accepted', 'continue', 'confirmed', 'preferred']
            }
        })
            .then(res => {
                setCandidateJobList(res.data.data)
            })
            .catch(function (err) {
                console.log(err);

            });
    }

    const [candidateMatchJobList, setCandidateMatchJobList] = useState([]);
    const getCandidateMatchJobs = () => {
        // candidate-job-list
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/candidate-job-list`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                userId: loggedUserId,
                flags: []
            }
        })
            .then(res => {
                setCandidateMatchJobList(res.data.data)
            })
            .catch(function (err) {
                console.log(err);

            });
    }


    const getJobs = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-calendar`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "month": month,
                "year": year,
                "domainId": domainId,
                "loggedUserId": loggedUserId,
                "roles": roles
            }
        })
            .then(res => {
                setDays(res.data.data)
                setMonth(res.data.month)
                setYear(res.data.year)

                setTimeout(() => setCalendarChangeButton(false), 1)
                // console.log(res)
            })
            .catch(function (error) {
                console.log(error)
            });
    }
    useEffect(() => {
        getJobs();
        if(hasRoleCandidate){
            getCandidateMatchJobs();
            getCandidateJobs();
        }

    }, [month]);


    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })

    const hasRoleClient = roles.find(role => {
        return role === 'ROLE_CLIENT';
    })
    const hasRoleCandidate = roles.find(role => {
        return role === 'ROLE_CANDIDATE';
    })

    const [jobDetails, setJobDetails] = useState({});
    //get job details
    const jobView = (jobId) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/show-job`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                loggedUserId
            }
        })
            .then(res => {
                if (res.status === 200){
                    setJobDetails(res.data.data)
                    setShowModal(!!setJobDetails)
                }
            });
    }




    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-between text-gray-600 mb-1">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Appointments')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('AppointmentInCalendar')}</sub></span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                {
                                    (hasRoleModerator || hasRoleClient) && (
                                        <Link to ='/job-post/create'
                                              className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                        >
                                            <HiOutlinePlus size={12} className={'mr-1'}/>{t('AddAppointment')}
                                        </Link>
                                        /*<button
                                            onClick={(e) => createJobPost(e)}
                                            type="button"
                                            className="inline-flex items-center ml-6 rounded border border-transparent bg-indigo-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            <HiOutlinePlus size={12} className={'mr-1'}/>{t('AddAppointment')}
                                        </button>*/
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-full flex-1">
                    <header className="px-3 mb-1 lg:flex-none">
                        <div className="flex items-center rounded shadow-sm md:items-stretch">
                            <button
                                disabled={calendarChangeButton}
                                onClick={() => {
                                    setCalendarChangeButton(true)
                                    setMonth(month === 1 ? 12 : (month - 1))
                                    setYear(month === 1 ? (year - 1) : year)
                                }}
                                type="button"
                                className="disabled:cursor-wait disabled:bg-indigo-700 disabled:text-gray-500 flex items-center justify-center rounded-l border border-r-0 border-blue-200 bg-indigo-700 text-white py-2 pl-3 pr-4 hover:bg-indigo-800 focus:relative md:w-9 md:px-2"
                            >
                                <span className="sr-only">{t('Yesterday')}</span>
                                <ChevronDoubleLeftIcon className="h-5 w-5 text-gray-50" aria-hidden="true"/>
                            </button>

                            <time className="w-[150px] text-center hidden border-t border-b border-blue-200 bg-indigo-800 text-white px-5 py-2 text-sm font-medium focus:relative md:block">
                                {(new Date(year + '-' + month + '-01')).toLocaleString('en-US', {month: 'long'}) + ' ' + (new Date(year + '-' + month + '-01')).getFullYear()}
                            </time>


                            <span
                                className="relative -mx-px h-5 w-px bg-gray-300 md:hidden"/>
                            <button
                                disabled={calendarChangeButton}
                                onClick={() => {
                                    setCalendarChangeButton(true)
                                    setMonth(month === 12 ? 1 : (month + 1))
                                    setYear(month === 12 ? (year + 1) : year)
                                }}
                                type="button"
                                className="disabled:cursor-wait disabled:bg-indigo-700 disabled:text-gray-500 flex items-center justify-center rounded-r border border-l-0 border-blue-200 bg-indigo-700 text-white py-2 pl-4 pr-3 hover:bg-indigo-800 focus:relative md:w-9 md:px-2"
                            >
                                <span className="sr-only">{t('Tomorrow')}</span>
                                <ChevronDoubleRightIcon
                                    className="h-5 w-5 text-gray-50"
                                    aria-hidden="true"/>
                            </button>
                        </div>
                    </header>

                    <div className="shadow lg:flex lg:flex-auto lg:flex-col mx-3">
                        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                            <div className="bg-white py-2">
                                S<span className="sr-only sm:not-sr-only">un</span>
                            </div>
                            <div className="bg-white py-2">
                                M<span className="sr-only sm:not-sr-only">on</span>
                            </div>
                            <div className="bg-white py-2">
                                T<span className="sr-only sm:not-sr-only">ue</span>
                            </div>
                            <div className="bg-white py-2">
                                W<span className="sr-only sm:not-sr-only">ed</span>
                            </div>
                            <div className="bg-white py-2">
                                T<span className="sr-only sm:not-sr-only">hu</span>
                            </div>
                            <div className="bg-white py-2">
                                F<span className="sr-only sm:not-sr-only">ri</span>
                            </div>
                            <div className="bg-white py-2">
                                S<span className="sr-only sm:not-sr-only">at</span>
                            </div>

                        </div>

                        <ScrollArea scrollbarSize={4} style={{ height: calendarHeight }}>
                            {/*<div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none inner-div-scroll overscroll-auto hover:overscroll-contain scrollbar-thumb-rounded-full scrollbar-track-rounded-full" style={{height: calendarHeight}}>*/}
                            <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none relative h-screen">
                                { calendarChangeButton && <LoadingOverlay visible={calendarChangeButton} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'sm' }} overlayColor="bg-indigo-100"/> }

                                {
                                    days && days.map((day, index) => (
                                        <div
                                            key={day.date}
                                            className={classNames(
                                                day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                                                'relative py-2 px-1 h-[121px] font-normal'
                                            )}
                                        >
                                            {/*<ScrollArea style={{ height: 100 }} scrollbarSize={4}>*/}
                                            <time
                                                dateTime={day.date}
                                                className={
                                                    day.isToday
                                                        ? 'flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white'
                                                        : undefined
                                                }
                                            >
                                                {day.date.split('-').pop().replace(/^0/, '')}
                                            </time>
                                            {/* {console.log(day, candidateJobList)}  */}

                                            {Object.values(day.jobs).map((job, index) => (
                                                1 >= index && (
                                                    // <h2>test</h2>
                                                    <JobTitle key={index} job={job} candidateJobList={candidateJobList} jobView={jobView} showListIcon={false}/>
                                                )
                                            ))}
                                            { Object.values(day.jobs).length > 2 &&
                                                <Fragment>
                                                    
                                                    <HoverCard width={350} shadow="md">
                                                        <HoverCard.Target>
                                                            <li className="cursor-pointer text-gray-500" onClick={() => setShowJobListModal(true)}>+ {Object.values(day.jobs).length - 2} more</li>
                                                        </HoverCard.Target>
                                                        <HoverCard.Dropdown>
                                                            {
                                                                    Object.values(day.jobs).map((job, index) => (
                                                                        <JobTitle key={index} job={job} candidateJobList={candidateJobList} jobView={jobView} showListIcon={true}/>
                                                                        
                                                                        
                                                                    ))
                                                                }
                                                            {/*{Object.values(day.jobs).map((job, index) => (
                                                                <div key={index} className="mb-2 text-left pl-1 mb-1 rounded rounded-sm" onClick={() => jobView(job.jobId)}>
                                                                    <a href="#" className="text-black"><span>{job.jobTitle.substring(0, 25)}{job.jobTitle.length > 25 && '...'}</span></a>
                                                                </div>
                                                            ))}*/}
                                                        </HoverCard.Dropdown>
                                                    </HoverCard>
                                                </Fragment>





                                                    // <li className="text-gray-500" onClick={() => setShowJobListModal(true)}>+ {Object.values(day.jobs).length - 2} more</li>
                                            }

                                        </div>

                                    ))
                                }

                            </div>
                        </ScrollArea>
                    </div>
                </div>
            </main>

            {showModal && <ViewJob setShowModal={setShowModal} jobDetails={jobDetails} roles={roles} jobView={jobView}/>}
        </>
    )
}

export default Index