import React, {useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiEye,
    HiOutlineChartSquareBar, HiOutlineExclamation,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiPencil, HiTrash
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Group, Text,Box,ScrollArea} from "@mantine/core";
import {FiAirplay, FiSave, FiX} from "react-icons/fi";
import {TbCurrencyTaka} from "react-icons/tb";
import {MdOutlineMonitorWeight} from "react-icons/md";
import {Switch} from "@headlessui/react";
import {AiOutlineLoading3Quarters, AiOutlineMinus} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import container from "postcss/lib/container";


const PAGE_SIZE = 1;

function UpdateAppointment() {

    const {t, i18n} = useTranslation();
    const innerContainer = useRef(100);
    const height = innerContainer.current.clientHeight;
    var tableHeight = (height-63);
    var fromHeight = height-153+"px";


    const [items, setItemTypes] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isAddForm, setAddForm] = useState(true);

    //Add Model Init
    let [isOpen, setIsOpen] = useState(false);

    //Submit Spinner Init
    const [spinner, SetSpinner] = useState(false);

    //Add Model close function
    function closeModal() {
        setIsOpen(false)
    }

    //Add Model Open function
    function openModal() {
        setIsOpen(true)
    }

    const [page, setPage] = useState(1);
    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setItemTypes(items.slice(from, to));
    }, [page]);

    //Form validation
    const validationSchema = Yup.object().shape({
        title: Yup.string().required(t("TitleIsRequired")),
        workingDate: Yup.string().required(t("FromDateIsRequired")),
        workingDateTo: Yup.string().required(t("ToDateIsRequired")),
        shift: Yup.string().required(t('ShiftIsRequired'))
       
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const [noOfRows, setNoOfRows] = useState(1);


    //Form Data Submit
    function formSubmit(data) {
        // SetSpinner(true);
         console.log(data)
        const formData = {
            projectName: data.name,
            workName : data.name,
            status:1
        };

        
    }

    return (
        <>

        <main className="flex flex-1 " ref={innerContainer}>
                        <div className="min-h-full w-full">
                            <div className="flex w-full md:w-auto">
                                <div className="bg-red-100 flex-1">
                                    <div className="min-w-0 flex-1 bg-white xl:flex">
                                        <div className="bg-white lg:min-w-0 lg:flex-1">
                                            <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                                                <div className="relative">
                                                    <div className="w-full flex -ml-4 pl-4 h-12 bg-indigo-50 justify-start text-gray-600 mb-3">
                                                        <div className={"flex-1"}>
                                                            <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                                                                <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                                <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                                                    {t('UpdateAppointment')}
                                                                    <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('EditAppointmentsOfEmployees')}</sub></span>
                                                            </div>
                                                        </div>
                                                        <div className={"right flex mr-8"}>

                                                            <div className="flex items-center justify-center">
                                                                <div className="inline-flex border-1 border-red-300" role="group">
                                                                {/* If any button is to be added */}

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'flex flex-1 overflow-hidden w-full'}>
                                                        

                                                        {isAddForm ?
                                                <div className="w-full mr-0 mx-auto border">
                                                    <header className="relative mb-1 bg-indigo-600">
                                                        <div
                                                            className="text-gray-400  text-right sm:px-2 sm:py-1">
                                                            <div
                                                                className="text-center flex justify-center">
                                                                <div className="text-white   text-md  text-center font-bold items-center leading-7">  {t('UpdateAppointment')}</div>
                                                                <br/>
                                                            </div>
                                                        </div>
                                                    </header>
                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form" >
                                                        <div className= "relative flex flex-col min-w-0 break-words w-full bg-gray-200 border-0 inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full" style={{height:fromHeight}} >
                                                        
                                                        <main
                                                            className="flex flex-1  w-5/6 ml-1" > 

                                                        {/* Left Side Of Form */}
                                                        <section
                                                            aria-labelledby="primary-heading"
                                                            className="flex h-full w-1/2  flex-1 flex-col overflow-y-auto">
                                                            <div
                                                                className="relative flex h-full  flex-col overflow-y-full border-gray-400 bg-gray-200 font-bold">
                                                                <div className={'pt-3 px-8 py-4 sm:border-gray-400'}>
                                                                    <div className='col-md-6 gap-2'>
                                                                 <h1>{t('AppointmentInformation')}</h1>
                                                                 </div>
                                                                </div>
                                                            </div>
                                                            </section>


                                                                    {/* Right Side Of Form */}
                                                                    <section
                                                                        className="flex h-full  flex-1 flex-col w-full">
                                                                        <div
                                                                            className="relative flex h-full  flex-col overflow-y-auto  border-gray-200 bg-white">
                                                                            <div className={'pt-3 px-8  sm:border-gray-200 '}>

                                                                                {/* Title Section */}
                                                                                <div className="mb-3 w-full items-center">
                                                                                    <label htmlFor="" className="form-input-sm-label-required">{t('Title')}</label>

                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                        {errors.title?.type === 'required' && <div className="form-input-sm-error">
                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.title?.message}
                                                                                        </div>}
                                                                                        
                                                                                        <input
                                                                                            {...register("title")}
                                                                                            type="text"
                                                                                            className={`form-input-sm-control ${errors.title ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                            name="title" id ="title"
                                                                                            placeholder={t("")}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                {/* Employer Name Section */}
                                                                                <div className="mb-3 w-full items-center">
                                                                                    <label htmlFor="customer" className="form-input-sm-label">{t('EmployerName')}</label>

                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                            {...register("customer")}
                                                                                            type="text"
                                                                                            className={`form-input-sm-control`}
                                                                                            name="customer" id ="customer"
                                                                                            placeholder={t("")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                                 {/* Appointment Date Section */}
                                                                                 <div className="mb-3 w-full items-center">
                                                                                    <label htmlFor="workingDate" className="form-input-sm-label">{t('AppointmentDate')}</label>

                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                            {...register("workingDate")}
                                                                                            type="date"
                                                                                            className={`form-input-sm-control`}
                                                                                            name="workingDate" id ="workingDate"
                                                                                            placeholder={t("")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                


                                                                                 {/* Working Duration Section */}
                                                                                 <div className='py-2'>
                                                                                <label htmlFor="workingDuration" className="form-input-sm-label">{t('WorkingDuration')}</label>
                                                                               
                                                                                </div>
                                                                                    {/* Shift & Designation */}
                                                                                    <div className='flex flex-wrap -mx-0.2 mb-2 col-span-2'>
                                                                                <div className="mb-3 w-1/3 items-center">
                                                                                    <label htmlFor="shift" className="form-input-sm-label-required">{t('Shift')}</label>
                                                                                    <div className="flex mt-1 sm:col-span-2 sm:mt-0"> 
                                                                                    <select
                                                                                        {...register("shift")}
                                                                                        name="shift" id="shift"
                                                                                        className={`form-input-sm-control`}>
                                                                                        <option value="">{t('Select')}</option>
                                                                                        <option value="1">{t('DayShift')}</option>
                                                                                        <option value="2">{t('NightShift')}</option>
                                                                                    </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="mb-3 w-1/3 ml-5 items-center">
                                                                                    <label htmlFor="designation" className="form-input-sm-label">{t('Designation')}</label>
                                                                                    <div className="inline-block mt-1 sm:col-span-2 sm:mt-0">  
                                                                                        <input
                                                                                            {...register("designation")}
                                                                                            type="input"
                                                                                            className={`form-input-sm-control`}
                                                                                            name="designation" id ="designation"
                                                                                            placeholder={t("")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                </div>


                                                                                {/* In Time & Out Time */}
                                                                               <div className='flex flex-wrap -mx-0.2 mb-2 col-span-2'>
                                                                                <div className="mb-3 w-1/3 items-center">
                                                                                    <label htmlFor="inTime" className="form-input-sm-label-required">{t('InTime')}</label>
                                                                                    <div className="inline-block mt-1 sm:col-span-2 sm:mt-0"> 
                                                                                        <input
                                                                                            {...register("inTime")}
                                                                                            type="time"
                                                                                            className={`form-input-sm-control`}
                                                                                            name="inTime" id ="inTime"
                                                                                            placeholder={t("")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="mb-3 w-1/3 ml-5 items-center">
                                                                                    <label htmlFor="outTime" className="form-input-sm-label-required">{t('OutTime')}</label>
                                                                                    <div className="inline-block mt-1 sm:col-span-2 sm:mt-0">  
                                                                                        <input
                                                                                            {...register("outTime")}
                                                                                            type="time"
                                                                                            className={`form-input-sm-control`}
                                                                                            name="outTime" id ="outTime"
                                                                                            placeholder={t("")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                </div>
                                                                                
                                                                                

                                                                                    {/* Status Section */}
                                                                                <div className="mb-3 w-full items-center">
                                                                                    <label htmlFor="status" className="form-input-sm-label">{t('Status')}</label>

                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0"> 
                                                                                    <select
                                                                                        {...register("status")}
                                                                                        name="status" id="status"
                                                                                        className={`form-input-sm-control`}>
                                                                                        <option value="">{t('Select')}</option>
                                                                                        <option value="1">{t('Active')}</option>
                                                                                        <option value="2">{t('Inactive')}</option>
                                                                                    </select>
                                                                                    </div>
                                                                                </div>


                                                                                {/* Comment Section */}
                                                                                <div className="mb-3 w-full items-center">
                                                                                    <label htmlFor="comment" className="form-input-sm-label">{t('Comment')}</label>

                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                        <textarea
                                                                                            {...register("comment")}
                                                                                            type="text"
                                                                                            className={`form-input-sm-control `}
                                                                                            name="comment" id="comment"
                                                                                            placeholder={t("")}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        </section>
                                                                        
                                                                        </main>
                                                                        <br></br>

                                                                        {/* Employee Information Section */}
                                                                        <div className="container mx-auto px-8 justify-items-right">
                                                                            <section class="px-8 -mx-0.5 mb-6">
                                                                            <div class="font-semibold text-left">{t('EmployeeInformation')}</div>
                                                                            <div class="flex flex-col justify-center h-full">
                                                                        <table class="table-flex w-full">
                                                                                    <thead class="text-xs font-semibold uppercase text-white bg-indigo-500">
                                                                                    <tr>
                                                                                    <th></th>
                                                                                
                                                                                    <th class="p-2">
                                                                                        <div class="font-semibold text-left">{t('Employee')}</div>
                                                                                            
                                                                                    </th>
                                                                                    <th class="p-2">
                                                                                        <div class="font-semibold text-left">{t('InTime')}</div>
                                                                                            
                                                                                    </th>

                                                                                    <th class="p-2">
                                                                                        <div class="font-semibold text-left">{t('OutTime')}</div>
                                                                                            
                                                                                    </th>
                                                                                    <th class="p-2">
                                                                                        <div class="font-semibold text-left">
                                                                                             {/* Button For Add New */}
                                                                                        <button type="button" class="inline-flex items-center px-2 py-1 bg-indigo-800 hover:bg-indigo-600 text-white text-xs" onClick={() => setNoOfRows(noOfRows + 1)}>
                                                                                            <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('AddMore')}
                                                                                            </button>

                                                                                
                                                                                            <button type="button" class="inline-flex items-center px-2 py-1 bg-red-500 hover:bg-red-200 text-white text-xs" onClick={() => setNoOfRows(noOfRows - 1)}>
                                                                                            <AiOutlineMinus size={12} className={'mr-1'}></AiOutlineMinus>{t('Delete')}
                                                                                                </button>
                                                                                        </div>
                                                                                            
                                                                                    </th>
                                                                                        
                                                                                    

                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>

                                                                                    {[...Array(noOfRows)].map((elementInArray, index) => {
                                                                                    
                                                                                        return (
                                                                                            <tr>
                                                                                            <th scope="row">{index}</th>
                                                                                            {/* Add Employee Section*/}
                                                                                            <td>
                                                                                            <select
                                                                                            {...register("employee")}
                                                                                            name="employee"
                                                                                            className={`form-input-sm-control`}>
                                                                                            <option value="">{t('ChooseAEmployee')}</option>
                                                                                            <option value="1">Bones Restaurant</option>
                                                                                            <option value="2">Lanier Islands'</option>
                                                                                            <option value="3">Park Spring</option>
                                                                                            <option value="4">The Commerce Club</option>
                                                                                            </select>
                                                                                            </td>


                                                                                            {/* In Time */}
                                                                                            <td>
                                                                                            <div className='w-1/2'>
                                                                                            <input
                                                                                            {...register("inTime")}
                                                                                            type="time"
                                                                                            className={`form-input-sm-control`}
                                                                                            name="inTime" id ="inTime"
                                                                                            placeholder={t("")}
                                                                                            />
                                                                                            </div>
                                                                                            </td>
                                                                                            
                                                                                            {/* Out Time */}
                                                                                            <td>
                                                                                                <div className='flex-left w-1/2'>
                                                                                            <input
                                                                                            {...register("outTime")}
                                                                                            type="time"
                                                                                            className={`form-input-sm-control`}
                                                                                            name="outTime" id ="outTime"
                                                                                            placeholder={t("")}
                                                                                            />
                                                                                            </div>
                                                                                            </td>
                                                                                            

                                                                                        </tr>
                                                                                            );
                                                                                        })}

                                                                                        
                                                                                        </tbody>
                                                                                        </table>

                                                                                        </div>
                                                                                        <br></br>
                                                                                        </section>

                                                                                       


                                                                        {/* Timesheet Attachments */}                                                                       
                                                                                </div> 

                                                                                <div className="container mx-auto px-8 justify-items-right">
                                                                            <section class="px-8 -mx-0.5 mb-6">
                                                                            <div class="font-semibold text-left">{t('TimesheetAttachments')}</div>
                                                                            <div class="flex flex-col justify-center h-full">
                                                                        <table class="table-flex w-full">
                                                                                    <thead class="text-xs font-semibold uppercase text-white bg-indigo-500">
                                                                                    <tr>
                                                                                    <th></th>
                                                                                
                                                                                    <th class="p-2">
                                                                                        <div class="font-semibold text-left">{t('FileLabel')}</div>
                                                                                            
                                                                                    </th>
                                                                                    <th class="p-2">
                                                                                        <div class="font-semibold text-left">{t('Upload')}</div>
                                                                                            
                                                                                    </th>

                                                                                    
                                                                                    <th class="p-2">
                                                                                        <div class="font-semibold text-left">
                                                                                             {/* Button For Add New */}
                                                                                        <button type="button" class="inline-flex items-center px-2 py-1 bg-indigo-800 hover:bg-indigo-600 text-white text-xs" onClick={() => setNoOfRows(noOfRows + 1)}>
                                                                                            <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('AddMore')}
                                                                                            </button>

                                                                                
                                                                                            <button type="button" class="inline-flex items-center px-2 py-1 bg-red-500 hover:bg-red-200 text-white text-xs" onClick={() => setNoOfRows(noOfRows - 1)}>
                                                                                            <AiOutlineMinus size={12} className={'mr-1'}></AiOutlineMinus>{t('Delete')}
                                                                                                </button>
                                                                                        </div>
                                                                                            
                                                                                    </th>
                                                                                        
                                                                                    

                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>

                                                                                    {[...Array(noOfRows)].map((elementInArray, index) => {
                                                                                    
                                                                                        return (
                                                                                            <tr>
                                                                                            <th scope="row">{index}</th>
                                                                                            {/* File Label Section*/}
                                                                                            <td>
                                                                                            <input
                                                                                            {...register("labelName")}
                                                                                            type="text"
                                                                                            className={`form-input-sm-control`}
                                                                                            name="labelName" id ="labelName"
                                                                                            placeholder={t("")}
                                                                                            />
                                                                                            </td>


                                                                                            {/* Upload Section */}
                                                                                            <td>
                                                                                            <div className='w-1/2'>
                                                                                            <input
                                                                                            {...register("file")}
                                                                                            type="file"
                                                                                            className={`form-input-sm-control`}
                                                                                            name="file" id ="file"
                                                                                            placeholder={t("")}
                                                                                            />
                                                                                            </div>
                                                                                            </td>
                                                                                              

                                                                                        </tr>
                                                                                            );
                                                                                        })}

                                                                                        
                                                                                        </tbody>
                                                                                        </table>

                                                                                        </div>
                                                                                        <br></br>
                                                                                        </section>

                                                                                       
                                                                                                                                                        
                                                                                </div>                                             
                                                                            </div>
                                                                            
                                                                                <footer className="relative mt-1">
                                                                                    <div className="container mx-auto justify-items-center">
                                                                                        <div
                                                                                            className="text-right border-t pt-0.5 mb-0.5">
                                                                                            <button onClick={event => setAddForm(prevCheck => !prevCheck)} type='reset'
                                                                                                    className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                                    ><FiX
                                                                                                size={16}
                                                                                                className={'mr-2'}></FiX>
                                                                                                <span>{t('Cancel')}</span>
                                                                                            </button>
                                                                                            <button type='submit'
                                                                                                    className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                                {spinner ? (
                                                                                                    <AiOutlineLoading3Quarters
                                                                                                        className={'mr-2 animate-spin'}
                                                                                                        size={16}></AiOutlineLoading3Quarters>
                                                                                                ) : (<FiSave size={16}
                                                                                                            className={'mr-2'}></FiSave>)}
                                                                                                <span>{t('Save')}</span>
                                                                                            </button>

                                                                                        </div>
                                                                                    </div>
                                                                                </footer>

                                                                            </form>
                                                                        </div>
                                                                
                                                            
                                                        
                                                        : ''}


                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </main>
        </>
    )
}
export default UpdateAppointment