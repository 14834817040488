import React, {useEffect, useRef} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiOutlineExclamation,
    HiOutlineUser,
    HiChevronLeft, HiEye, HiArrowCircleLeft, HiArrowCircleRight,HiOutlinePlus
} from "react-icons/hi";

import {FiSave, FiX} from "react-icons/fi";

import {AiOutlineLoading3Quarters, AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import {Toaster} from "react-hot-toast";
import {ScrollArea, Select, MultiSelect, Stepper, Button, Group, Radio, ActionIcon,Text, useMantineTheme} from '@mantine/core';
import {showNotification} from "@mantine/notifications";
import {useLocation} from "react-router";
import {format} from "date-fns";
import ViewCandidate from "./modal/ViewCandidate";
import { IconUpload, IconPhoto, IconX } from '@tabler/icons';
import {Dropzone, DropzoneProps, IMAGE_MIME_TYPE, MIME_TYPES} from '@mantine/dropzone';
import { Switch } from '@mantine/core';
import DesignationHistoryModel from "./modal/DesignationHistoryModel";



function Update() {
    const {candidateId} = useParams();
    const { state } = useLocation();
    const theme = useMantineTheme();

    const [active, setActive] = useState(state.type=='list'?0:1);
    const nextStep = () => setActive((current) => (current < 5 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    const [designations, setDesignation] = useState([]);
    const [educationType, setEducationType] = useState([]);
    const [unitedCitizen, setUnitedCitizen] = useState(false);
    const [previousWork, setPreviousWork] = useState(false);
    const [convictedFelony, setConvictedFelony] = useState(false);
    const [candidate, setCandidate] = useState({});
    const [designationsWiseRate, setDesignationsWiseRate] = useState([]);
    const [recall, setRecall] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [validationDesignation, setValidationDesignation] = useState([]);
    const [validationRate, setValidationRate] = useState([]);
    const [designationHistoryModel,setDesignationHistoryModel] = useState(false)
    const [designationHistoryID,setDesignationHistoryID] = useState(null)
    const [designationHistoryExtendId,setDesignationHistoryExtendId] = useState(null)
    const [clickDiseabled,setClickDiseabled] = useState(false)

    const navigate = useNavigate();
    const {roles, token} = JSON.parse(localStorage.getItem('user'));
    const {t, i18n} = useTranslation();
    const fullFormHeight = localStorage.getItem('fullFormHeight');

    const viewport = useRef(null);
    const scrollToTop = () => viewport.current.scrollTo({ top: 0, behavior: 'smooth' });

    //Submit Spinner Init
    const [spinner, setSpinner] = useState(false);

    // start get gender dropdown
    const [genderData, setGenderData] = useState('');
    const [genders, setGenders] = useState([]);
    let genderDropdown = [];
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=gender&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setGenders(res.data.data);
                }

            });
    }, []);

    if (genders) {
        genderDropdown =
            genders.map((type, index) => {
                return ({
                    'label': type.name, 'value': type.id
                })
            })
    }

    // on change gender data get
    const GenderDataHandel = (e) => {
        setGenderData(e);
        setValue('gender',e)
        if (e == '' || e == null){
            setError("gender", Yup.string().required(t("ChooseGender")).nullable() );
        }else {
            setError("gender", null);
        }
    }
    // End get gender dropdown


    // start get country dropdown
    const [countryData, setCountryData] = useState('');
    const [countries, setCountries] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setCountries(res.data.data);
            });
    }, []);

    let countryDropdown =
        countries.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })

    // on change country data get
    const [stateData, setStateData] = useState('');
    const [states, setStates] = useState([]);
    const [cityData, setCityData] = useState('');

    const [citySearchValue, setCitySearchValue] = useState('');
    const [cityDropdown, setCityDropdown] = useState([]);

    const CityDataHandelForSearch = (e) => {
        setCitySearchValue(e);
        if (citySearchValue.length >1){
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/city/dropdown-with-keyword?country_id=${countryData}&keyword=${citySearchValue}&state_id=${stateData}`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
            })
                .then(res => {
                    if (res.status === 200) {
                        let data =
                            res.data.data.map((head, index) => {
                                return ({
                                    'label': head.name, 'value': head.id
                                })
                            })
                        setCityDropdown(data);
                    }
                })
        }
    }

    const CountryDataHandel = (e) => {
        setCountryData(e);
        setStates([])
        setValue('state',null)
        setStateData('')


        setCityDropdown([])
        setValue('city',null)
        setCityData('')

        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/state/dropdown?country_id=${e}`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    let stateDropdown =
                        res.data.data.map((type, index) => {
                            return ({
                                'label': type.name, 'value': type.id
                            })
                        })
                    setStates(stateDropdown)
                }
            });

        setValue('country',e)
        if (e == '' || e == null){
            setError("country", Yup.string().required(t("ChooseCountry")).nullable() );
        }else {
            setError("country", null);
        }
    }

    // on change state data get
    const StateDataHandel = (e) => {
        setCityDropdown([])
        setValue('city',null)
        setCityData('')
        setStateData(e);
        setValue('state',e)
    }


    //city data handel
    const CityDataHandel = (e) => {
        setCityData(e);
        setValue('city',e)
        if (e == '' || e == null){
            setError("city", Yup.string().required(t("ChooseCity")).nullable() );
        }else {
            setError("city", null);
        }
    }
    // End get country dropdown


    // start get highest education dropdown
    const [highestEducationData, setHighestEducationData] = useState('');
    const [highestEducation, setHighestEducation] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=highest-education&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setHighestEducation(res.data.data);
                }
            });
    }, []);

    let highestEducationDropdown =
        highestEducation.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })

    // on change gender data get
    const HighestEducationDataHandel = (e) => {
        setHighestEducationData(e);
        setValue('highest_education',e)
        if (e == '' || e == null){
            setError("highest_education", Yup.string().required(t("ChooseHighestEducation")).nullable() );
        }else {
            setError("highest_education", null);
        }
    }
    // End get highest education dropdown


    // start get highest education dropdown
    const [immigrationStatusData, setImmigrationStatusData] = useState('');
    const [immigrationStatus, setImmigrationStatus] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=immigration-status&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setImmigrationStatus(res.data.data);
                }
            });
    }, []);

    let immigrationStatusDropdown =
        immigrationStatus.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })

    // on change gender data get
    const ImmigrationStatusDataHandel = (e) => {
        setImmigrationStatusData(e);
        setValue('immigration_status',e)
        if (e == '' || e == null){
            setError("immigration_status", Yup.string().required(t("ChooseImmigrationStatus")).nullable() );
        }else {
            setError("immigration_status", null);
        }
    }
    // End get highest education dropdown

    // start get employee status dropdown
    const [employeeStatusData, setEmployeeStatusData] = useState('');
    const [employeeStatus, setEmployeeStatus] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=candidate-status&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setEmployeeStatus(res.data.data);
                }
            });
    }, []);

    let employeeStatusDropdown =
        employeeStatus.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })

    // on change gender data get
    const EmployeeStatusDataHandel = (e) => {
        setEmployeeStatusData(e);
        setValue('employee_status',e)
        if (e == '' || e == null){
            setError("employee_status", Yup.string().required(t("ChooseEmployeeStatus")).nullable());
        }else {
            setError("employee_status", null);
        }
    }
    // End get employee status dropdown


    // start get source dropdown
    const [sourceData, setSourceData] = useState('');
    const [sources, setSource] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=source&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setSource(res.data.data);
                }
            });
    }, []);

    let sourcesDropdown =
        sources.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })

    // on change gender data get
    const SourceDataHandel = (e) => {
        setSourceData(e);
        setValue('source',e)
    }
    // End get source dropdown

    // start get source dropdown
    const [languageData, setLanguageData] = useState([]);
    const [languages, setLanguage] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=language`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setLanguage(res.data.data);
                }
            });
    }, []);

    let languageDropdown =
        languages.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id
            })
        })
    // language data control

    const LanguageDataHandel = (e) => {
        setLanguageData(e);
        setValue('language',e)
    }

    // End get source dropdown

    // start get work shift dropdown
    const [workShiftData, setWorkShiftData] = useState([]);
    const [workShift, setWorkShift] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown?slug=work-shifts&type=normal`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setWorkShift(res.data.data);
                }
            });
    }, []);
    const WorkShiftDataHandel = (e, label) => {
        if (e.target.checked === true) {
            setWorkShiftData({...workShiftData,[e.target.value] : label}) ;
            setError("work_shift", null);
        } else {
            delete workShiftData[e.target.value];
            setWorkShiftData({...workShiftData}) ;
            if (Object.values(workShiftData).length === 0 ){
                setValue('work_shift',[])
                setError("work_shift", Yup.array().required(t("ShiftAbleToWorkValidation")).nullable());
            }
        }
    }
    // End get work shift dropdown

    useEffect(() => {
        // get candidate details by raju
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/${candidateId}/candidate/show`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            }
        })
            .then(res => {
                setRecall(false)
                setWorkShiftData(res.data.data.work_shift);
                setCandidate(res.data.data);
                setUnitedCitizen(res.data.data.united_citizen)
                setPreviousWork(res.data.data.previous_work_this_company)
                setConvictedFelony(res.data.data.have_convicted_felony)
                setValue('first_name', res.data.data.firstName)
                setValue('middle_name', res.data.data.middleName)
                setValue('last_name', res.data.data.lastName)
                setValue('email', res.data.data.email)
                setValue('secondary_email', res.data.data.secondaryEmail)
                setValue('phone', res.data.data.phoneNumber)
                setValue('date_of_birth', res.data.data.dateOfBirth ? format(new Date(res.data.data.dateOfBirth.date), 'yyyy-MM-dd') : '')
                setValue('gender', res.data.data.gender)
                setGenderData(res.data.data.gender);
                // setValue('emergency_contact', res.data.data.emergencyContact)
                // setValue('highest_education', res.data.data.highestEducation)
                setHighestEducationData(res.data.data.highestEducation)
                setValue('immigration_status', res.data.data.immigrationStatus)
                setImmigrationStatusData(res.data.data.immigrationStatus)
                setValue('cert_license', res.data.data.certificateLicense)
                setValue('street_address', res.data.data.streetAddress)
                setValue('country', res.data.data.country)
                setCountryData(res.data.data.country)
                setValue('state', res.data.data.state)
                setStateData(res.data.data.state)
                setValue('city', res.data.data.city)
                setCityData(res.data.data.city)
                setValue('zip', res.data.data.zipCode)
                setValue('language', res.data.data.language)
                setLanguageData(res.data.data.language)
                setValue('employee_status', res.data.data.employeeStatus)
                setEmployeeStatusData(res.data.data.employeeStatus)
                setValue('source', res.data.data.source)
                setSourceData(res.data.data.source)
                setValue('availability', res.data.data.availability)
                setValue('linkedin', res.data.data.linkedin)
                setValue('employee_id', res.data.data.employeeId)
                setValue('remark', res.data.data.remark)
                // setValue('reference_name', res.data.data.referenceName)
                // setValue('relation', res.data.data.referenceRelation)
                // setValue('reference_email', res.data.data.referenceEmail)
                // setValue('reference_mobile', res.data.data.referencePhone)


                axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/state/dropdown?country_id=${res.data.data.country}`,
                    {headers: {"Authorization": `Bearer ${token}`}})
                    .then(res => {
                        if (res.data.status === 200) {
                            let dataDropState = res.data.data ? res.data.data.map((type, index) => {
                                return ({
                                    'label': type.name, 'value': type.id
                                })
                            }):[]
                            setStates(dataDropState)
                        }
                    });

                let dataDrop = res.data.data.city && res.data.data.cityName ? [{id:Number(res.data.data.city),name:res.data.data.cityName}].map((type, index) => {
                    return ({
                        'label': type.name, 'value': type.id
                    })
                }):[]
                setCityDropdown(dataDrop)
            });

    }, [recall,active]);

    useEffect(() => {

        // get designations
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/master-data/dropdown?slug=designation&type=normal`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "slug": "designation",
                "type": "normal"
            }
        })
            .then(res => {
                setDesignation(res.data.data);
            });

        // get education type
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/master-data/dropdown`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "slug": "education-type"
            }
        })
            .then(res => {
                setEducationType(res.data.data);
            });

    }, [recall]);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/designation-wise-rate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "userId": candidateId,
                "type":'candidate'
            }
        })
            .then(res => {
                if (res.data.data.length>0) {
                    if (res.data.data[0].designationId && res.data.data[0].designationId != null) {
                        setValue('validatePosition', 'ok')
                        setError("validatePosition", null);
                    }
                }else{
                    setValue('validatePosition', null)
                }
                setDesignationsWiseRate(res.data.data);
            });
    }, [recall,active]);

    useEffect(() => {
        // get designations
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/master-data/dropdown?slug=designation&type=normal`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "slug": "designation",
                "type": "normal"
            }
        })
            .then(res => {
                setDesignation(res.data.data);
            });
        // }
    }, [recall]);

    const newDesignationWiseRate = () => {
        var table = document.getElementById("designationTable");
        var lastRowIndex = table.rows.length - 2;
        let callApi = false;

        console.log(lastRowIndex)

        if (lastRowIndex == 0) {
            callApi = true;
        } else {
            var designation = document.getElementById("designation_0");
            if (designation != null){
                var designationValue = designation.value;
                var designationText = designation.options[designation.selectedIndex].text;

                if (!designationValue || designationValue == '') {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Choose_designation_in_row_01')),
                        message: ('Error'),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'red',
                    });
                } else {

                    var approveRate = document.getElementById("approveRate_0").value;
                    if (!approveRate || approveRate == '') {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('Enter_approve_rate_in_row_01')),
                            message: ('Error'),
                            autoClose: 1000,
                            disallowClose: true,
                            color: 'red',
                        });
                    } else {
                        callApi = true;
                    }
                }
            }else {
                callApi = true
            }
        }
        if (callApi){
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/designation-wise-rate`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    userId: candidateId
                }
            })
                .then(res => {
                    console.log(res)
                    if (res.status === 200) {
                        setRecall(!recall)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    }

    const updateDesignationRate = (id, property, value) => {
        if (property=='designationId'){
            if (Object.keys(validationRate).length>0){
                setValue('validatePosition','ok')
                setError("validatePosition", null);
            }
            setValidationDesignation({...validationDesignation,[value] : value}) ;
        }
        if (property=='rate'){
            if (Object.keys(validationDesignation).length>0){
                setValue('validatePosition','ok')
                setError("validatePosition", null);
            }
            setValidationRate({...validationRate,[value] : value}) ;
        }
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/designation-wise-rate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                id,
                property,
                value
            }
        })
            .then(res => {
                if (res.status === 200) {
                    if (property === 'status'){
                        setRecall(true)
                    }
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }


    const removeDesignationWiseRate = (id) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/designation-wise-rate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                id
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setRecall(!recall)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const newEducationType = () => {
        var table = document.getElementById("educationTable");
        var lastRowIndex = table.rows.length - 2;
        let callApi = false;

        if (lastRowIndex == 0) {
            callApi = true;
        } else {
            // var designation = document.getElementById("designation_"+lastRowIndex);
            var designation = document.getElementById("education_type_0");
            var designationValue = designation.value;
            var designationText = designation.options[designation.selectedIndex].text;
            if (!designationValue || designationValue == '') {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('Choose_education_type_in_row_01')),
                    message: ('Error'),
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
            } else {
                var hourRate = document.getElementById("name_0").value;
                if (!hourRate || hourRate == '') {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Enter_name_in_row_01')),
                        message: ('Error'),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'red',
                    });
                } else {
                    var approveRate = document.getElementById("address_0").value;
                    if (!approveRate || approveRate == '') {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('Enter_address_in_row_01')),
                            message: ('Error'),
                            autoClose: 1000,
                            disallowClose: true,
                            color: 'red',
                        });
                    } else {
                        callApi = true;
                    }
                }
            }
        }
        if (callApi){
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/candidate/education/add`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    candidate_id: candidateId
                }
            })
                .then(res => {
                    if (res.status === 201) {
                        setRecall(!recall)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    }
    const updateEducationData = (id, property, value) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/update-candidate-education`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                id,
                property,
                value
            }
        })
            .then(res => {
                // console.log(res)
                if (res.status === 200) {
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    const removeCandidateWiseEducation = (id) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/candidate-education-delete`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                id
            }
        })
            .then(res => {
                if (res.status === 202) {
                    setRecall(!recall)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    const UpdateEmploymentHistory = (id, property, value,e) => {
        let validation = true;
        if (property == 'company_email'){
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if ( !re.test(value) ) {
                validation = false;
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('HtmlValidEmail')),
                    message: ('Error'),
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
            }
        }

        if (property == 'starting_salary' || property == 'ending_salary'){
            const re = /^[0-9\b]+$/;
            if (value === '' || re.test(value)) {
                console.log('number')
            }else {
                e.target.value = '';
                validation = false;
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('EnterValidNumber')),
                    message: ('Error'),
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
            }
        }

        if (property == 'company_phone'){
            if(/^(\d+-)*(\d+)$/.test(value)){
                console.log('text nei')
            } else {
                validation = false;
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('EnterValidNumber')),
                    message: ('Error'),
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
            }
        }

        if (validation) {
            axios({
                method: 'put',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/update-candidate-employment`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    id,
                    property,
                    value
                }
            })
                .then(res => {
                    if (res.status === 200) {
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    }

    const UpdateCandidateReference = (id, property, value) => {
        let validation = true;
        if (property == 'reference_mobile'){
            if(/^(\d+-)*(\d+)$/.test(value)){
                console.log('text nei')
            } else {
                validation = false;
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('EnterValidNumber')),
                    message: ('Error'),
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
            }
        }

        if (property == 'reference_email'){
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if ( !re.test(value) ) {
                validation = false;
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('HtmlValidEmail')),
                    message: ('Error'),
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
            }
        }
        if (validation) {
            axios({
                method: 'put',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/update-candidate-reference`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    id,
                    property,
                    value
                }
            })
                .then(res => {
                    if (res.status === 200) {
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    }
    const removeCandidateEmployment = (id) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/candidate-employment-delete`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                id
            }
        })
            .then(res => {
                if (res.status === 202) {
                    setRecall(!recall)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const removeCandidateReference = (id) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/candidate-reference-delete`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                id
            }
        })
            .then(res => {
                if (res.status === 202) {
                    setRecall(!recall)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    const newEmploymentHistory = () => {

        var table = document.getElementById("employeeTable");
        var lastRowIndex = table.rows.length - 2;
        let callApi = false;


        var job_title = document.getElementById("job_title_0");
        var job_title = job_title.value;
        if (!job_title || job_title == '') {
            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('Enter_job_title_in_row_01')),
                message: ('Error'),
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
        }else {
            var company_name = document.getElementById("company_name_0");
            var company_name = company_name.value;
            if (!company_name || company_name == '') {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('Enter_company_name_in_row_01')),
                    message: ('Error'),
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
            }else {
                callApi = true
            }
        }




        /*var company_name = document.getElementById("company_name_0");
        var company_name = company_name.value;
        if (!company_name || company_name == '') {
            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('Enter_company_name_in_row_01')),
                message: ('Error'),
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
        }else {
            var job_title = document.getElementById("job_title_0");
            var job_title = job_title.value;
            if (!job_title || job_title == '') {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('Enter_job_title_in_row_01')),
                    message: ('Error'),
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
            var company_phone = document.getElementById("company_phone_0");
            var company_phone = company_phone.value;
            if (!company_phone || company_phone == '') {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('Enter_company_phone_in_row_01')),
                    message: ('Error'),
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
            }else{
                var company_email = document.getElementById("company_email_0");
                var company_email = company_email.value;
                if (!company_email || company_email == '') {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Enter_company_email_in_row_01')),
                        message: ('Error'),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'red',
                    });
                }else {
                    var job_title = document.getElementById("job_title_0");
                    var job_title = job_title.value;
                    if (!job_title || job_title == '') {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('Enter_job_title_in_row_01')),
                            message: ('Error'),
                            autoClose: 1000,
                            disallowClose: true,
                            color: 'red',
                        });
                    }else {
                        var from_date = document.getElementById("from_date_0");
                        var from_date = from_date.value;
                        if (!from_date || from_date == '') {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Enter_from_date_in_row_01')),
                                message: ('Error'),
                                autoClose: 1000,
                                disallowClose: true,
                                color: 'red',
                            });
                        }else{
                            var to_date = document.getElementById("to_date_0");
                            var to_date = to_date.value;
                            if (!to_date || to_date == '') {
                                showNotification({
                                    id: 'load-data',
                                    loading: true,
                                    title: (t('Enter_to_date_in_row_01')),
                                    message: ('Error'),
                                    autoClose: 1000,
                                    disallowClose: true,
                                    color: 'red',
                                });
                            }else {
                                callApi = true;
                            }
                        }
                    }
                }
            }
        }*/


        if (callApi){
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/candidate/employment-history/add`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    candidate_id: candidateId
                }
            })
                .then(res => {
                    if (res.status === 201) {
                        setRecall(!recall)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    }

    const newCandidateReference = () => {

        var table = document.getElementById("referenceTable");
        var lastRowIndex = table.rows.length - 2;
        let callApi = false;
        // console.log(lastRowIndex)

        var reference_name = document.getElementById("reference_name_0");
        var reference_name = reference_name.value;
        if (!reference_name || reference_name == '') {
            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('EnterReferenceName')),
                message: ('Error'),
                autoClose: 1000,
                disallowClose: true,
                color: 'red',
            });
        }else {
            var relation = document.getElementById("relation_0");
            var relation = relation.value;
            if (!relation || relation == '') {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('EnterRelation')),
                    message: ('Error'),
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
            }else{
                var reference_mobile = document.getElementById("reference_mobile_0");
                var reference_mobile = reference_mobile.value;
                if (!reference_mobile || reference_mobile == '') {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('EnterMobileNumber')),
                        message: ('Error'),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'red',
                    });
                }else {
                    var reference_email = document.getElementById("reference_email_0");
                    var reference_email = reference_email.value;
                    if (!reference_email || reference_email == '') {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('EnterEmail')),
                            message: ('Error'),
                            autoClose: 1000,
                            disallowClose: true,
                            color: 'red',
                        });
                    }else {
                        callApi = true
                    }
                }
            }
        }

        if (callApi){
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/candidate/reference/add`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    candidate_id: candidateId
                }
            })
                .then(res => {
                    if (res.status === 201) {
                        setRecall(!recall)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    }

    //Form validation
    const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
    const onlyNumber = /^[0-9]+$/
    const validationSchemaStepOne = Yup.object().shape({
        first_name: Yup.string().required(t("EnterFirstName")),
        last_name: Yup.string().required(t("EnterLastName")),
        email: Yup.string().email().required(t("EnterEmail")),
        secondary_email: Yup.string().email(t('EnterValidSecondaryEmail')).required(t("EnterSecondaryEmail")),
        phone: Yup.string().required(t("EnterMobileNumber")).matches(phoneRegExp, t("PhoneNumberNotValid")),
        date_of_birth: Yup.string().required(t("ChooseDate")),
        gender: Yup.string().required(t("ChooseGender")).nullable(),
        // emergency_contact: Yup.string().required(t("EnterEmergencyContact")),
        // highest_education: Yup.string().required(t("ChooseHighestEducation")).nullable(),
        immigration_status: Yup.string().required(t("ChooseImmigrationStatus")).nullable(),
        // cert_license: Yup.string().required(t("EnterCertLicense")),
        employee_status: Yup.string().required(t("ChooseEmployeeStatus")).nullable(),
        country: Yup.string().required(t("ChooseCountry")).nullable(),
        city: Yup.string().required(t("ChooseCity")).nullable(),
        street_address: Yup.string().required(t("EnterStreetAddress")).nullable(),
        zip: Yup.string().required(t("EnterZipCode")).matches(onlyNumber, t("InvalidZipCode")).nullable(),
    });

    const validationSchemaStepTwo = Yup.object().shape({
        availability: Yup.string().required(t("EnterAvailability")).nullable(),
        validatePosition: Yup.string().required(t("ChooseAtLeastOnePosition")).nullable(),
        work_shift: Yup.array().required(t("ShiftAbleToWorkValidation")).nullable(),
    });

    const validationSchemaStepFour = Yup.object().shape({
        // reference_name: Yup.string().required(t("EnterReferenceName")),
        // relation: Yup.string().required(t("EnterRelation")),
        // reference_email: Yup.string().email().required(t("EnterEmail")),
        // reference_mobile: Yup.string().required(t("EnterValidPhoneNumber")),

    });

    let formOptions = {resolver: yupResolver(validationSchemaStepOne)};

    if (active == 1) {
        formOptions = {resolver: yupResolver(validationSchemaStepTwo)};
    }
    if (active == 4) {
        formOptions = {resolver: yupResolver(validationSchemaStepFour)};
    }


    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState,setValue ,setError} = useForm(formOptions);
    const {errors} = formState;

    //Form Data Submit
    function formSubmit(data) {
        setClickDiseabled(true)
        data['united_citizen'] = unitedCitizen;
        data['have_convicted_felony'] = convictedFelony;
        data['previous_work_this_company'] = previousWork;
        data['work_shift'] = workShiftData;
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/${candidateId}/update-candidate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: data
        })
            .then(res => {
                if (res.status === 200) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('UpdatedSuccessfully')),
                        message: (t('DataLoad')),
                        autoClose: 500,
                        disallowClose: true,
                        color: 'green',
                    });
                    setTimeout(function () {
                        if (active == 5){
                            navigate('/candidate')
                        }else {
                            setClickDiseabled(false)
                            scrollToTop()
                            nextStep()
                        }
                        setSpinner(false)
                    },1000)

                } else {
                    setClickDiseabled(false)
                    // console.log(res)
                }
            })
            .catch(function (error) {
                setClickDiseabled(false)
                setSpinner(false)
            })

    }

    const candidateProfileImageUpload = (file) => {
        const data = {
            file : file,
            candidate_id : candidateId
        };
        if (data){
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/candidate/profile-image/upload`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `multipart/form-data`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                data: data
            })
                .then(res => {
                    if (res.status === 200) {
                        setRecall(true)
                    } else {
                        console.log(res)
                    }

                })
                .catch(function (error) {
                    setSpinner(false)
                })
        }
    }

    const newAttachment = () => {
        var table = document.getElementById("attachmentTable");
        var lastRowIndex = table.rows.length - 2;
        let callApi = false;

        if (lastRowIndex == 0) {
            callApi = true;
        } else {
            var lebel = document.getElementById("label_0");
            var lebelValue = lebel.value;

            if (!lebelValue || lebelValue == '') {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('EnterLabelInRow01')),
                    message: ('Error'),
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
            } else {
                var attachment = document.getElementById("attachment_0").value;
                if (!attachment || attachment == '') {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('SelectAttachmentInRow01')),
                        message: ('Error'),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'red',
                    });
                } else {
                    callApi = true;
                }
            }
        }
        if (callApi){
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/new/attachment`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    user_id: candidateId
                }
            })
                .then(res => {
                    if (res.status === 201) {
                        setRecall(!recall)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    }

    const candidateAttachmentUpload = (attachmentID,property,file) => {
        const data = {
            id : attachmentID,
            property : property,
            file : file
        };
        console.log(data)
        if (data){
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/attachment/upload`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `multipart/form-data`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                data: data
            })
                .then(res => {
                    if (res.status === 200) {
                        setRecall(true)
                    } else {
                        console.log(res)
                    }

                })
                .catch(function (error) {
                    setSpinner(false)
                })
        }
    }
    const removeCandidateAttachment = (id) => {
        // console.log(id)
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/attachment-delete`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                id
            }
        })
            .then(res => {
                if (res.status === 202) {
                    setRecall(!recall)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const isCandidate = roles.find(role=>{
        return role === "ROLE_CANDIDATE";
    })


    return (
        <>
            <Toaster toastOptions={{className: 'w-1/2'}} containerStyle={{top: 63}}/>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineUser size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Candidate')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>
                                    {t('AllCandidateRelatedInformationCanBeFoundHere')}
                                </sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <button onClick={() => navigate(-1)}
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={12} className={'mr-1'}/>{t('Back')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 rounded sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border">
                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form" autoComplete="off">
                                                        <ScrollArea style={{ height: fullFormHeight }} scrollbarSize={4} viewportRef={viewport} type="always">
                                                        <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full border-0 bg-gray-200 ">



        <Stepper active={active} breakpoint="sm" color="green" size="sm">

            <Stepper.Step label={t("BasicInformation")} description= {t('BasicDetails')}>
                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2 mr-3">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0 ml-3">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("BasicInformation")}</h3>
                            <p className="mt-1 text-sm text-gray-600">
                                {t('BasicDetails')}
                            </p>
                        </div>
                    </div>

                    <div className="mt-5 md:col-span-4 md:mt-0">
                        <div className="shadow    sm:rounded">
                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="employee_id" className="form-input-sm-label">{t('candidateID')}</label>
                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                            <input
                                                {...register("employee_id")}
                                                type="text"
                                                readOnly={true}
                                                className={`form-input-sm-control`}
                                                name="employee_id"
                                                id="employee_id"
                                                placeholder={t("EnterCandidateID")}
                                            />
                                        </div>
                                    </div>

                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="email" className="form-input-sm-label-required">{t('Username')}</label>
                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                            {errors.email?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.email?.message}
                                            </div>}

                                            {errors.email?.type === 'email' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.email?.message}
                                            </div>}

                                            <input
                                                {...register("email")}
                                                type="text"
                                                className={`form-input-sm-control ${errors.email ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                name="email"
                                                readOnly={true}
                                                id="email"
                                                placeholder={t("EnterEmail")}
                                            />
                                        </div>
                                    </div>

                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="SecondaryEmail"
                                               className="form-input-sm-label-required">{t('SecondaryEmail')}</label>
                                        <div
                                            className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                            {errors.secondary_email?.type === 'required' &&
                                                <div
                                                    className="form-input-sm-error">
                                                    <HiOutlineExclamation
                                                        size={16}
                                                        className={'mr-2'}></HiOutlineExclamation> {errors.secondary_email?.message}
                                                </div>}

                                            {errors.secondary_email?.type === 'email' &&
                                                <div
                                                    className="form-input-sm-error">
                                                    <HiOutlineExclamation
                                                        size={16}
                                                        className={'mr-2'}></HiOutlineExclamation> {errors.secondary_email?.message}
                                                </div>}

                                            <input
                                                {...register("secondary_email")}
                                                type="text"
                                                className={`form-input-sm-control ${errors.secondary_email ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                name="secondary_email"
                                                id="secondary_email"
                                                placeholder={t("EnterSecondaryEmail")}
                                            />
                                        </div>
                                    </div>

                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="last_name"
                                               className="form-input-sm-label-required">{t('LastName')}</label>
                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                            {errors.last_name?.type === 'required' &&
                                                <div className="form-input-sm-error">
                                                    <HiOutlineExclamation size={16}
                                                                          className={'mr-2'}></HiOutlineExclamation> {errors.last_name?.message}
                                                </div>}

                                            <input
                                                {...register("last_name")}
                                                type="text"
                                                className={`form-input-sm-control ${errors.last_name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                id="lastName"
                                                placeholder={t("EnterLastName")}
                                            />
                                        </div>
                                    </div>


                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="middle_name"
                                               className="form-input-sm-label">{t('MiddleName')}</label>
                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                            <input
                                                {...register("middle_name")}
                                                type="text"
                                                className={`form-input-sm-control`}
                                                id="middleName"
                                                placeholder={t("EnterMiddleName")}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className=" w-1/3 items-center">
                                        <label htmlFor="first_name"
                                               className="form-input-sm-label-required">{t('FirstName')}</label>
                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">

                                            {errors.first_name?.type === 'required' &&
                                                <div className="form-input-sm-error">
                                                    <HiOutlineExclamation size={16}
                                                                          className={'mr-2'}></HiOutlineExclamation> {errors.first_name?.message}
                                                </div>}

                                            <input
                                                {...register("first_name")}
                                                type="text"
                                                className={`form-input-sm-control ${errors.first_name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                id="firstName"
                                                placeholder={t("EnterFirstName")}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>


                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="phone" className="form-input-sm-label-required">{t('Phone')}</label>
                                        <div className="relative mr-3 mt-1 sm:col-span-2 sm:mt-0">
                                            {errors.phone &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.phone?.message}
                                            </div>}

                                            <input
                                                {...register("phone")}
                                                type="text"
                                                className={`form-input-sm-control ${errors.phone ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                name="phone"
                                                id="phone"
                                                placeholder={t("EnterPhoneNumber")}
                                            />
                                        </div>
                                    </div>

                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="employee_status" className="form-input-sm-label-required">{t('CandidateStatus')}</label>
                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                            {errors.employee_status?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.employee_status?.message}
                                            </div>}

                                            <Select
                                                {...register("employee_status")}
                                                placeholder={t('ChooseEmployeeStatus')}
                                                searchable clearable
                                                allowDeselect
                                                nothingFound="No options"
                                                data={employeeStatusDropdown}
                                                transition="pop-top-left"
                                                transitionDuration={80}
                                                transitionTimingFunction="ease"
                                                onChange={EmployeeStatusDataHandel}
                                                value={employeeStatusData}
                                            />
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2 mr-3">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0 ml-3">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("OtherInformation")}</h3>
                            <p className="mt-1 text-sm text-gray-600">
                                {t('OtherDetails')}
                            </p>
                        </div>
                    </div>

                    <div className="mt-5 md:col-span-4 md:mt-0">
                        <div className="shadow    sm:rounded">
                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">

                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="gender" className="form-input-sm-label-required">{t('gender')}</label>
                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                            {errors.gender?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.gender?.message}
                                            </div>}

                                            <Select
                                                {...register("gender")}
                                                className={`${errors.gender ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                placeholder={t('ChooseGender')}
                                                searchable clearable
                                                allowDeselect
                                                nothingFound="No options"
                                                data={genderDropdown}
                                                transition="pop-top-left"
                                                transitionDuration={80}
                                                transitionTimingFunction="ease"
                                                onChange={GenderDataHandel}
                                                value={genderData}
                                                dropdownPosition="bottom"
                                            />

                                        </div>
                                    </div>
                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="date_of_birth" className="form-input-sm-label-required">{t('date_of_birth')}</label>
                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                            {errors.date_of_birth?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.date_of_birth?.message}
                                            </div>}
                                            <input
                                                {...register("date_of_birth")}
                                                type="date"
                                                className={`form-input-sm-control ${errors.date_of_birth ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                name="date_of_birth"
                                                id="date_of_birth"
                                            />
                                        </div>
                                    </div>

                                    {/*<div className=" w-1/3 items-center">
                                        <label htmlFor="emergency_contact" className="form-input-sm-label-required">{t('EmergencyContact')}</label>
                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                            {errors.emergency_contact &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.emergency_contact?.message}
                                            </div>}

                                            <input
                                                {...register("emergency_contact")}
                                                type="text"
                                                className={`form-input-sm-control ${errors.emergency_contact ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                name="emergency_contact"
                                                id="emergency_contact"
                                                // onChange={event => {
                                                //     if (event.target.value.length == 0){
                                                //         setError('emergency_contact',Yup.string().required(t("EnterEmergencyContact")))
                                                //     }
                                                // }}
                                                placeholder={t("EnterEmergencyContact")}
                                            />
                                        </div>
                                    </div>*/}


                                    <div className=" w-full items-center">
                                        <label htmlFor="language" className="form-input-sm-label">{t('Language')}</label>
                                        <MultiSelect
                                            {...register("language")}
                                            placeholder={t('ChooseLanguage')}
                                            searchable clearable
                                            nothingFound="No options"
                                            data={languageDropdown}
                                            transition="pop-top-left"
                                            transitionDuration={80}
                                            transitionTimingFunction="ease"
                                            onChange={LanguageDataHandel}
                                            value={languageData}
                                        />
                                    </div>




                                </div>

                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                    {/*<div className=" w-1/3 items-center">
                                        <label htmlFor="highest_education" className="form-input-sm-label-required">{t('HighestEducation')}</label>
                                        <div className="relative mr-3 mt-1 sm:col-span-2 sm:mt-0">
                                            {errors.highest_education?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.highest_education?.message}
                                            </div>}

                                            <Select
                                                {...register("highest_education")}
                                                className={`${errors.highest_education ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}

                                                placeholder={t('ChooseHighestEducation')}
                                                searchable clearable
                                                allowDeselect
                                                nothingFound="No options"
                                                data={highestEducationDropdown}
                                                transition="pop-top-left"
                                                transitionDuration={80}
                                                transitionTimingFunction="ease"
                                                onChange={HighestEducationDataHandel}
                                                value={highestEducationData}
                                            />
                                        </div>
                                    </div>*/}

                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="immigration_status" className="form-input-sm-label-required">{t('ImmigrationStatus')}</label>
                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                            {errors.immigration_status?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.immigration_status?.message}
                                            </div>}

                                            <Select
                                                {...register("immigration_status")}
                                                className={`${errors.immigration_status ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                placeholder={t('ChooseImmigrationStatus')}
                                                searchable clearable
                                                allowDeselect
                                                nothingFound="No options"
                                                data={immigrationStatusDropdown}
                                                transition="pop-top-left"
                                                transitionDuration={80}
                                                transitionTimingFunction="ease"
                                                onChange={ImmigrationStatusDataHandel}
                                                value={immigrationStatusData}
                                            />
                                        </div>
                                    </div>

                                    {/*<div className=" w-1/3 items-center">
                                        <label htmlFor="cert_license" className="form-input-sm-label-required">{t('CertLicense')}</label>
                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">

                                            {errors.cert_license?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.cert_license?.message}
                                            </div>}

                                            <input
                                                {...register("cert_license")}
                                                type="text"
                                                className={`form-input-sm-control ${errors.cert_license ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                name="cert_license"
                                                id="cert_license"
                                                placeholder={t("EnterCertLicense")}
                                            />
                                        </div>
                                    </div>*/}

                                    <div className=" w-2/3 items-center">
                                        <label htmlFor="linkedin" className="form-input-sm-label">{t('Linkedin')}</label>
                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                            <input
                                                {...register("linkedin")}
                                                className={`form-input-sm-control`}
                                                name="linkedin"
                                                placeholder={t("EnterLinkedin")}
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2 mr-3">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0 ml-3">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("LocationInformation")}</h3>
                            <p className="mt-1 text-sm text-gray-600">
                                {t('LocationDetails')}
                            </p>
                        </div>
                    </div>

                    <div className="mt-5 md:col-span-4 md:mt-0">
                        <div className="shadow    sm:rounded">
                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">

                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                    <div className=" w-full items-center">
                                        <label htmlFor="street_address" className="form-input-sm-label-required">{t('StreetAddress')}</label>
                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                            {errors.street_address?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.street_address?.message}
                                            </div>}
                                        <textarea
                                            {...register("street_address")}
                                            className={`form-input-sm-control`}
                                            name="street_address"
                                            placeholder={t("EnterStreetAddress")}
                                        />
                                        </div>
                                    </div>

                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="country" className="form-input-sm-label-required">{t('Country')}</label>
                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                            {errors.country?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.country?.message}
                                            </div>}

                                            <Select
                                                {...register("country")}
                                                placeholder={t('ChooseCountry')}
                                                className={`${errors.country ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                searchable clearable
                                                allowDeselect
                                                nothingFound="No options"
                                                data={countryDropdown}
                                                transition="pop-top-left"
                                                transitionDuration={80}
                                                transitionTimingFunction="ease"
                                                onChange={CountryDataHandel}
                                                value={countryData}
                                            />
                                        </div>
                                    </div>

                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="state" className="form-input-sm-label">{t('State')}</label>
                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                            <Select
                                                {...register("state")}
                                                placeholder={t('ChooseState')}
                                                searchable clearable
                                                allowDeselect
                                                nothingFound="No options"
                                                data={states}
                                                transition="pop-top-left"
                                                transitionDuration={80}
                                                transitionTimingFunction="ease"
                                                onChange={StateDataHandel}
                                                value={stateData}
                                            />
                                        </div>
                                    </div>

                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="city" className="form-input-sm-label-required">{t('City')}</label>
                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                             {errors.city?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.city?.message}
                                            </div>}
                                            <Select
                                                {...register("city")}
                                                placeholder={t("ChooseCity")}
                                                className={`${errors.city ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                searchable clearable
                                                allowDeselect
                                                nothingFound={t('PleaseTypeCityName')}
                                                withAsterisk
                                                data={cityDropdown}
                                                transition="pop-top-left"
                                                transitionDuration={80}
                                                transitionTimingFunction="ease"
                                                onSearchChange={CityDataHandelForSearch}
                                                onChange={e => {
                                                    CityDataHandel(e)
                                                }}
                                                value={cityData}
                                                searchValue={citySearchValue}
                                            />
                                        </div>
                                    </div>


                                    <div className=" w-1/3 items-center">
                                        <label htmlFor="zip" className="form-input-sm-label-required">{t('Zip')}</label>
                                        <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                            {errors.zip &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.zip?.message}
                                            </div>}
                                            <input
                                                {...register("zip")}
                                                type="text"
                                                className={`form-input-sm-control `}
                                                name="zip"
                                                id="zip"
                                                placeholder={t("EnterZip")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Stepper.Step>

            <Stepper.Step label={t("PositionInformation")} description={t('PositionDetails')}>
                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2 mr-3">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0 ml-3">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("PositionInformation")}</h3>
                            <p className="mt-1 text-sm text-gray-600">
                                {t('PositionDetails')}
                            </p>
                        </div>
                    </div>


                    <div className="mt-5 md:col-span-4 md:mt-0">
                        <div className="shadow    sm:rounded">
                            <div className="space-y-1 bg-gray-50 sm:p-6">
                                <div className="mx-auto justify-items-right w-full">
                                    <section className="-mx-0.5">
                                        <div className="flex flex-col justify-center h-full">

                                            <div className=" w-full items-center">
                                                <div className="relative mt-1  sm:col-span-2 sm:mt-0">
                                                    {errors.validatePosition?.type === 'required' &&
                                                    <div className="form-input-sm-error">
                                                        <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.validatePosition?.message}
                                                    </div>}
                                                    <input
                                                        {...register("validatePosition")}
                                                        type="hidden"
                                                    />
                                                </div>
                                            </div>

                                            <table className="table-full w-full" id="designationTable">
                                                <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                <tr>

                                                    <th className="p-2">
                                                        <div className="font-semibold text-left">{t('Designation')}</div>
                                                    </th>
                                                    <th className="p-2">
                                                        <div className="font-semibold text-left">{t('HourlyRate')}</div>
                                                    </th>
                                                    <th align={`right`} className="p-2">

                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                { designationsWiseRate && designationsWiseRate.length > 0 && designationsWiseRate.map((designation, index) => {
                                                    return (
                                                        <tr className={"bg-gray-100"} key={designation.id}>
                                                            <td>
                                                                {
                                                                    designation.udTotal > 0 ?
                                                                        <span className={`designation-margin-left`}>{designation.designationName}</span>
                                                                        :
                                                                        <select
                                                                            name="designation"
                                                                            className={`form-input-sm-control`}
                                                                            onChange={(e) => updateDesignationRate(designation.id, "designationId", Number(e.target.value))}
                                                                            defaultValue={designation.designationId?designation.designationId:null}
                                                                            id={`designation_${index}`}
                                                                        >
                                                                            <option value="">{t('SelectDesignation')}</option>

                                                                            {designations && designations.map((item, index) => {
                                                                                return <option value={item.id} key={item.id}>{item.name}</option>;

                                                                            })}
                                                                        </select>
                                                                }
                                                            </td>

                                                            <td width={150}>
                                                                {
                                                                    isCandidate ? (designation.rate ? designation.rate : '') : (
                                                                        <input
                                                                            onBlur={(e) => updateDesignationRate(designation.id, "rate", Number(e.target.value))}
                                                                            type="text"
                                                                            className={`form-input-sm-control`}
                                                                            id={`approveRate_${index}`}
                                                                            defaultValue={designation.rate ? designation.rate : ''}
                                                                        />
                                                                    )
                                                                }

                                                            </td>
                                                            <td align={"right"} className="pr-2" width={150}>
                                                                {
                                                                    designation.udTotal > 0 ?
                                                                        <>

                                                                            <button
                                                                                type="button"
                                                                                className=" px-1.5 py-1.5 bg-blue-500 hover:bg-blue-600 text-white text-xs"
                                                                                onClick={ e => {
                                                                                    setDesignationHistoryExtendId(designation.userExtendedInfoId)
                                                                                    setDesignationHistoryID(designation.designationId)
                                                                                    setDesignationHistoryModel(true)
                                                                                } }
                                                                            >
                                                                                <HiEye size={12}/>
                                                                            </button>
                                                                            <Switch
                                                                                className="mb-2 ml-2 inline-block"
                                                                                size="md"
                                                                                radius="xs"
                                                                                color="indigo"
                                                                                onLabel="Active"
                                                                                offLabel="Inactive"
                                                                                checked={designation.status ? true : false}
                                                                                onChange={(event) => {
                                                                                    updateDesignationRate(designation.id,'status',event.currentTarget.checked)
                                                                                }}
                                                                            />

                                                                        </>
                                                                        :
                                                                    <>
                                                                    <button
                                                                    type="button"
                                                                    className=" px-1.5 py-1.5 bg-blue-500 hover:bg-blue-600 text-white text-xs"
                                                                    onClick={ e => {
                                                                    setDesignationHistoryExtendId(designation.userExtendedInfoId)
                                                                    setDesignationHistoryID(designation.designationId)
                                                                    setDesignationHistoryModel(true)
                                                                } }
                                                                    >
                                                                    <HiEye size={12}/>
                                                                    </button>
                                                                        <button
                                                                            type="button"
                                                                            className="ml-2 px-1.5 py-1.5 bg-red-500 hover:bg-red-600 text-white text-xs"
                                                                            onClick={ e => removeDesignationWiseRate(designation.id) }
                                                                        >
                                                                            <AiOutlineMinus size={12}/>
                                                                        </button>
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                }) }

                                                <tr>
                                                    <td colSpan={"4"}>
                                                        <a className=" mt-3 flex font-bold text-indigo-500 cursor-pointer hover:text-indigo-800 hover:underline"
                                                           onClick={e => newDesignationWiseRate()}>
                                                            <HiOutlinePlus size={16} className={'mr-1 inline-block add-more-margin-top'}/> {t('AddMore')}
                                                        </a>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <br/>
                                    </section>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2 mr-3">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0 ml-3">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("CandidateJobInformation")}</h3>
                            <p className="mt-1 text-sm text-gray-600">
                                {t('CandidateJobInformationDetails')}
                            </p>
                        </div>
                    </div>

                    <div className="mt-5 md:col-span-4 md:mt-0">
                        <div className="shadow    sm:rounded">
                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">

                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                    <div className=" w-1/2 items-center">
                                        <label htmlFor="source" className="form-input-sm-label">{t('Source')}</label>
                                        <div className="relative mt-1 sm:col-span-2 mr-3 sm:mt-0">
                                            <Select
                                                {...register("source")}
                                                placeholder={t('ChooseSource')}
                                                searchable clearable
                                                allowDeselect
                                                nothingFound="No options"
                                                data={sourcesDropdown}
                                                transition="pop-top-left"
                                                transitionDuration={80}
                                                transitionTimingFunction="ease"
                                                onChange={SourceDataHandel}
                                                value={sourceData}
                                            />
                                        </div>
                                    </div>

                                    <div className=" w-1/2 items-center">
                                        <label htmlFor="availability" className="form-input-sm-label-required">{t('Availability')}</label>
                                        <div className="relative mt-1  sm:col-span-2 sm:mt-0">
                                            {errors.availability &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.availability?.message}
                                            </div>}
                                            <input
                                                {...register("availability")}
                                                className={`form-input-sm-control ${errors.availability ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'}`}
                                                name="availability"
                                                placeholder={t("EnterAvailability")}
                                            />
                                        </div>
                                    </div>

                                    <div className=" w-full items-center">
                                        <label htmlFor="remark" className="form-input-sm-label">{t('Remarks')}</label>
                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                        <textarea
                                            {...register("remark")}
                                            className={`form-input-sm-control candidate-remark`}
                                            placeholder={t("EnterRemarks")}
                                            rows={5}
                                        />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2 mr-3">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0 ml-3">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("WorkShiftsInformation")}</h3>
                            <p className="mt-1 text-sm text-gray-600">
                                {t('WorkShiftsDetails')}
                            </p>
                        </div>
                    </div>


                    <div className="mt-5 md:col-span-4 md:mt-0">
                        <div className="shadow    sm:rounded">
                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                    <div className="mb-3 w-full items-center">
                                        <label htmlFor="ShiftAbleToWork" className="form-input-sm-label-required">{t('ShiftAbleToWork')}</label>
                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                            {errors.work_shift?.type === 'required' &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.work_shift?.message}
                                            </div>}
                                            <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white rounded
        border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">

                                                {
                                                    workShift.map((workValue, index) => {
                                                        return (
                                                            <li className="w-full dark:border-gray-600"
                                                                key={workValue.id}>
                                                                <div
                                                                    className="flex items-center pl-3">
                                                                    <input
                                                                        {...register("work_shift")}
                                                                        onChange={(e) => WorkShiftDataHandel(e, workValue.name)}
                                                                        id={workValue.slug}
                                                                        type="checkbox"
                                                                        value={workValue.id}
                                                                        checked={workShiftData && workShiftData.hasOwnProperty(workValue.id)?true:false}
                                                                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                                                                    <label
                                                                        htmlFor={workValue.slug}
                                                                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">{workValue.name}</label>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>


                                    <div className="mb-3 w-full items-center">
                                        <label htmlFor="AreCitizenOfTheUnitedStates" className="form-input-sm-label-required">{t('AreCitizenOfTheUnitedStates')} </label>

                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                            {errors.united_citizen &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.united_citizen?.message}
                                            </div>}
                                            <Radio.Group
                                                value={unitedCitizen===true?"yes":"no"}
                                                onChange={(event) => {
                                                    setUnitedCitizen(event==='yes'?true:false)
                                                } }
                                                name="united_citizen"
                                                withAsterisk
                                            >
                                                <Radio value="yes" label="Yes" />
                                                <Radio value="no" label="No" />
                                            </Radio.Group>

                                        </div>
                                    </div>

                                    <div className="mb-3 w-full items-center">
                                        <label htmlFor="HavePreviousWorkThisCompany" className="form-input-sm-label-required">{t('HavePreviousWorkThisCompany')}</label>
                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                            {errors.previous_work_this_company &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.previous_work_this_company?.message}
                                            </div>}
                                            <Radio.Group
                                                value={previousWork===true?"yes":"no"}
                                                onChange={(event) => { setPreviousWork(event==='yes'?true:false)} }
                                                name="previous_work"
                                                withAsterisk
                                            >
                                                <Radio value="yes" label="Yes" />
                                                <Radio value="no" label="No" />
                                            </Radio.Group>
                                        </div>
                                    </div>

                                    <div className="mb-3 w-full items-center">
                                        <label htmlFor="HaveConvictedFelony" className="form-input-sm-label-required">{t('HaveConvictedFelony')}</label>

                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                            {errors.have_convicted_felony &&
                                            <div className="form-input-sm-error">
                                                <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.have_convicted_felony?.message}
                                            </div>}
                                            <Radio.Group
                                                value={convictedFelony===true?"yes":"no"}
                                                onChange={(event) => { setConvictedFelony(event==='yes'?true:false)} }
                                                name="convictedFelony"
                                                withAsterisk
                                            >
                                                <Radio value="yes" label="Yes" />
                                                <Radio value="no" label="No" />
                                            </Radio.Group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Stepper.Step>

            <Stepper.Step label={t("EducationInformation")} description={t('EducationInformationDetails')}>
                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2 mr-3">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0 ml-3">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("EducationInformation")}</h3>
                            <p className="mt-1 text-sm text-gray-600">
                                {t('EducationInformationDetails')}
                            </p>
                        </div>
                    </div>



                    <div className="mt-5 md:col-span-4 md:mt-0">
                        <div className="shadow    sm:rounded">
                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                    <div className="mx-auto justify-items-right w-full">
                                        <section className="-mx-0.5">
                                            <div className="flex flex-col justify-center h-full">
                                                <table className="table-full w-full" id="educationTable">
                                                    <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                    <tr>

                                                        <th className="p-2">
                                                            <div className="font-semibold text-left">{t('EducationType')}</div>
                                                        </th>
                                                        <th className="p-2">
                                                            <div className="font-semibold text-left">{t('InstitutionName')}</div>
                                                        </th>
                                                        <th className="p-2">
                                                            <div className="font-semibold text-left">{t('DegreeAttained')}</div>
                                                        </th>
                                                        <th align={`right`} className="p-2">

                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    { candidate.candidateEducation && candidate.candidateEducation.length > 0 && candidate.candidateEducation.map((edu, index) => {
                                                        return (
                                                            <tr className={"bg-gray-100"} key={edu.id}>

                                                                <td>
                                                                    <select
                                                                        name="designation"
                                                                        className={`form-input-sm-control`}
                                                                        onChange={(e) => updateEducationData(edu.id, "education_type_id", Number(e.target.value))}
                                                                        defaultValue={edu.instituteTypeId}
                                                                        id={`education_type_${index}`}
                                                                    >
                                                                        <option value="">{t('SelectEducationType')}</option>

                                                                        {educationType && educationType.map((item, index) => {
                                                                            return <option value={item.id} key={item.id}>{item.name}</option>;

                                                                        })}
                                                                    </select>
                                                                </td>
                                                                <td >
                                                                    <input
                                                                        onChange={(e) => updateEducationData(edu.id, "name",e.target.value)}
                                                                        type="text"
                                                                        className={`form-input-sm-control`}
                                                                        name="name"
                                                                        id={`name_${index}`}
                                                                        defaultValue={edu.title ? edu.title : ''}

                                                                    />
                                                                </td>

                                                                <td >
                                                                    {
                                                                        isCandidate ? (edu.address ? edu.address : '') : (
                                                                            <input
                                                                                onChange={(e) => updateEducationData(edu.id, "address",e.target.value)}
                                                                                type="text"
                                                                                className={`form-input-sm-control`}
                                                                                name="address"
                                                                                id={`address_${index}`}
                                                                                defaultValue={edu.address ? edu.address : ''}
                                                                            />
                                                                        )
                                                                    }
                                                                </td>
                                                                <td align={"right"} className="pr-2" width={20}>
                                                                    <button
                                                                        type="button"
                                                                        className="px-1.5 py-1.5 bg-red-500 hover:bg-red-600 text-white text-xs"
                                                                        onClick={ e => removeCandidateWiseEducation(edu.id) }
                                                                    >
                                                                        <AiOutlineMinus size={12} className={'mr-1'}/>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }) }

                                                    <tr>
                                                        <td colSpan={"4"}>
                                                            <a className=" mt-3 flex font-bold text-indigo-500 cursor-pointer hover:text-indigo-800 hover:underline"
                                                               onClick={e => newEducationType()}>
                                                                <HiOutlinePlus size={16} className={'mr-1 inline-block add-more-margin-top'}/> {t('AddMore')}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br/>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Stepper.Step>

            <Stepper.Step label={t("EmploymentHistory")} description= {t('EmploymentHistoryDetails')}>
                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2 mr-3">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0 ml-3">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("EmploymentHistory")}</h3>
                            <p className="mt-1 text-sm text-gray-600">
                                {t('EmploymentHistoryDetails')}
                            </p>
                        </div>
                    </div>


                    <div className="mt-5 md:col-span-4 md:mt-0 ">

                        { candidate.employmentHistory && candidate.employmentHistory.length > 0 && candidate.employmentHistory.map((emphis, index) => {
                            return (
                                <>
                                    <table id="employeeTable">
                                        <tr  key={emphis.id}>
                                            <td>
                                                {/*<nav className=" relative w-full flex flex-wrap items-center justify-between py-4 bg-gray-100 text-gray-500 hover:text-gray-700 focus:text-gray-700 shadow-lg navbar navbar-expand-lg navbar-light">
                                                    <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6">
                                                        <span></span> {emphis.companyName}
                                                        <div className="flex items-center relative">
                                                            <div className="dropdown relative">
                                                                {
                                                                    index === 0 ?

                                                                        <button type='button' onClick={e => newEmploymentHistory()}
                                                                                className="px-1.5 py-1.5 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-xs">
                                                                            <HiOutlinePlus size={16} className={'mr-1 inline-block add-more-margin-top'}/> {t('AddMore')}
                                                                        </button>:''
                                                                }
                                                                {
                                                                    candidate.employmentHistory.length !=1 ? <button
                                                                        type="button"
                                                                        className="px-1.5 py-1.5 text-white inline-flex transition duration-150 ease-in-out bg-red-400 items-center border  rounded text-xs"
                                                                        onClick={ e => removeCandidateEmployment(emphis.id) }
                                                                    >
                                                                        <AiOutlineMinus size={12}/>
                                                                        <span>&nbsp;</span>
                                                                    </button> : ''
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </nav>*/}


                                                <div className="shadow    sm:rounded border mb-2" key={index} id={emphis.id}>
                                                    <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                                        {
                                                            candidate.employmentHistory.length !=1 ?
                                                                <div className="text-right">
                                                                <button
                                                                    type="button"
                                                                    className=" px-1.5 py-1.5 text-white inline-flex transition duration-150 ease-in-out bg-red-400 items-center border  rounded text-xs"
                                                                    onClick={ e => removeCandidateEmployment(emphis.id) }
                                                                >
                                                                    <AiOutlineMinus size={12}/>
                                                                    <span>&nbsp;</span>
                                                                </button></div> : ''
                                                        }
                                                        <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>


                                                            <div className="w-full items-center">
                                                                <label htmlFor="job_title" className="form-input-sm-label">{t('JobTitle')}</label>
                                                                <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                    {errors.job_title?
                                                                        <div className="form-input-sm-error">
                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.job_title}
                                                                        </div>:''}
                                                                    <input
                                                                        type="text"
                                                                        className={`form-input-sm-control`}
                                                                        name="job_title"
                                                                        id={`job_title_${index}`}
                                                                        onBlur={(e) => UpdateEmploymentHistory(emphis.id, "job_title",e.target.value,e)}
                                                                        placeholder={t("EnterJobTitle")}
                                                                        defaultValue={emphis.jobTitle?emphis.jobTitle:''}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className=" w-full items-center">
                                                                <label htmlFor="company_name" className="form-input-sm-label">{t('CompanyName')}</label>
                                                                <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                    {errors.company_name?
                                                                        <div className="form-input-sm-error">
                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.company_name}
                                                                        </div>:''}
                                                                    <input
                                                                        type="text"
                                                                        className={`form-input-sm-control`}
                                                                        onBlur={(e) => UpdateEmploymentHistory(emphis.id, "company_name",e.target.value,e)}
                                                                        name="company_name"
                                                                        id={`company_name_${index}`}
                                                                        placeholder={t("EnterCompanyName")}
                                                                        defaultValue={emphis.companyName?emphis.companyName:''}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className=" w-1/2 items-center">
                                                                <label htmlFor="company_phone" className="form-input-sm-label">{t('Phone')}</label>
                                                                <div className="relative mt-1 sm:col-span-2 mr-3 sm:mt-0">
                                                                    {errors.company_phone?
                                                                        <div className="form-input-sm-error">
                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.company_phone}
                                                                        </div>:''}
                                                                    <input
                                                                        type="text"
                                                                        className={`form-input-sm-control`}
                                                                        name="company_phone"
                                                                        id={`company_phone_${index}`}

                                                                        onChange={(e) => UpdateEmploymentHistory(emphis.id, "company_phone",e.target.value,e)}
                                                                        placeholder={t("EnterPhone")}
                                                                        defaultValue={emphis.phone?emphis.phone:''}
                                                                    />
                                                                </div>
                                                            </div>


                                                            <div className=" w-1/2 items-center">
                                                                <label htmlFor="company_email" className="form-input-sm-label">{t('Email')}</label>
                                                                <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                    {errors.company_email?
                                                                        <div className="form-input-sm-error">
                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.company_email}
                                                                        </div>:''}
                                                                    <input
                                                                        type="text"
                                                                        className={`form-input-sm-control`}
                                                                        name="company_email"
                                                                        id={`company_email_${index}`}

                                                                        onBlur={(e) => UpdateEmploymentHistory(emphis.id, "company_email",e.target.value,e)}
                                                                        placeholder={t("EnterEmail")}
                                                                        defaultValue={emphis.email?emphis.email:''}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="w-1/2 items-center">
                                                                <label htmlFor="from_date" className="form-input-sm-label">{t('FromDate')}</label>
                                                                <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                    {errors.from_date?
                                                                        <div className="form-input-sm-error">
                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.from_date}
                                                                        </div>:''}
                                                                    <input
                                                                        type="date"
                                                                        className={`form-input-sm-control`}
                                                                        name="from_date"
                                                                        id={`from_date_${index}`}
                                                                        onChange={(e) => UpdateEmploymentHistory(emphis.id, "from_date",e.target.value,e)}
                                                                        defaultValue={emphis.fromDate ? format(new Date(emphis.fromDate.date), 'yyyy-MM-dd') : ''}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="w-1/2 items-center">
                                                                <label htmlFor="to_date" className="form-input-sm-label">{t('ToDate')}</label>
                                                                <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                    {errors.to_date?
                                                                        <div className="form-input-sm-error">
                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.to_date}
                                                                        </div>:''}
                                                                    <input
                                                                        className={`form-input-sm-control`}
                                                                        name="to_date"
                                                                        id={`to_date_${index}`}
                                                                        onChange={(e) => UpdateEmploymentHistory(emphis.id, "to_date",e.target.value,e)}
                                                                        type="date"
                                                                        defaultValue={emphis.toDate ? format(new Date(emphis.toDate.date), 'yyyy-MM-dd') : ''}
                                                                    />
                                                                </div>
                                                            </div>


                                                            <div className="w-1/2 items-center">
                                                                <label htmlFor="supervisor" className="form-input-sm-label">{t('Supervisor')}</label>
                                                                <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                    {errors.supervisor?
                                                                        <div className="form-input-sm-error">
                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.supervisor}
                                                                        </div>:''}
                                                                    <input
                                                                        type="text"
                                                                        className={`form-input-sm-control`}
                                                                        name="supervisor"
                                                                        onBlur={(e) => UpdateEmploymentHistory(emphis.id, "supervisor",e.target.value,e)}
                                                                        placeholder={t("EnterSupervisor")}
                                                                        defaultValue={emphis.supervisor?emphis.supervisor:''}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="w-1/2 items-center">
                                                                <label htmlFor="responsibilities" className="form-input-sm-label">{t('Responsibilities')}</label>
                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                    {errors.responsibilities?
                                                                        <div className="form-input-sm-error">
                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.responsibilities}
                                                                        </div>:''}
                                                                    <input
                                                                        type="text"
                                                                        className={`form-input-sm-control`}
                                                                        name="responsibilities"
                                                                        onBlur={(e) => UpdateEmploymentHistory(emphis.id, "responsibilities",e.target.value,e)}
                                                                        placeholder={t("EnterResponsibilities")}
                                                                        defaultValue={emphis.responsibilities?emphis.responsibilities:''}
                                                                    />
                                                                </div>
                                                            </div>


                                                            <div className="w-1/3 items-center">
                                                                <label htmlFor="starting_salary" className="form-input-sm-label">{t('StartingSalary')}</label>
                                                                <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                    {errors.starting_salary?
                                                                        <div className="form-input-sm-error">
                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.starting_salary}
                                                                        </div>:''}
                                                                    <input
                                                                        type="text"
                                                                        className={`form-input-sm-control`}
                                                                        name="starting_salary"

                                                                        onChange={(e) => UpdateEmploymentHistory(emphis.id, "starting_salary",e.target.value,e)}
                                                                        placeholder={t("EnterStartingSalary")}
                                                                        defaultValue={emphis.startingSalary?emphis.startingSalary:''}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="w-1/3 items-center">
                                                                <label htmlFor="ending_salary" className="form-input-sm-label">{t('EndingSalary')}</label>
                                                                <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                    {errors.ending_salary?
                                                                        <div className="form-input-sm-error">
                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.ending_salary}
                                                                        </div>:''}
                                                                    <input
                                                                        type="text"
                                                                        className={`form-input-sm-control`}
                                                                        name="ending_salary"
                                                                        onChange={(e) => UpdateEmploymentHistory(emphis.id, "ending_salary",e.target.value,e)}
                                                                        placeholder={t("EnterEndingSalary")}
                                                                        defaultValue={emphis.endingSalary?emphis.endingSalary:''}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="w-1/3 items-center">
                                                                <label htmlFor="reason_for_leaving" className="form-input-sm-label">{t('ReasonForLeaving')}</label>
                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                    {errors.reason_for_leaving?
                                                                        <div className="form-input-sm-error">
                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.reason_for_leaving}
                                                                        </div>:''}
                                                                    <input
                                                                        type="text"
                                                                        className={`form-input-sm-control`}
                                                                        name="reason_for_leaving"
                                                                        onBlur={(e) => UpdateEmploymentHistory(emphis.id, "reason_for_leaving",e.target.value,e)}
                                                                        placeholder={t("EnterReasonForLeaving")}
                                                                        defaultValue={emphis.reasonForLeaving?emphis.reasonForLeaving:''}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    {
                                                        index === 0 ?

                                                            <button type='button' onClick={e => newEmploymentHistory()}
                                                                    className="px-1.5 py-1.5 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-xs">
                                                                <HiOutlinePlus size={16} className={'mr-1 inline-block add-more-margin-top'}/> {t('AddMore')}
                                                            </button>:''
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </>
                            );
                        }) }
                    </div>
                </div>
            </Stepper.Step>

            <Stepper.Step label={t("ReferencesInformation")} description={t('ReferencesInformationDetails')}>
                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2 mr-3">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0 ml-3">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("ReferencesInformation")}</h3>
                            <p className="mt-1 text-sm text-gray-600">
                                {t('ReferencesInformationDetails')}
                            </p>
                        </div>
                    </div>

                    <div className="mt-5 md:col-span-4 md:mt-0 ">

                        { candidate.candidateReference && candidate.candidateReference.length > 0 && candidate.candidateReference.map((reference, index) => {
                            return (
                                <>
                                    <table id="referenceTable" className="table-full w-full">
                                        <tr key={reference.id}>
                                            <td>
                                                {/*<nav className=" relative w-full flex flex-wrap items-center justify-between py-4 bg-gray-100 text-gray-500 hover:text-gray-700 focus:text-gray-700 shadow-lg navbar navbar-expand-lg navbar-light">
                                                    <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6">
                                                        <span></span>
                                                        <div className="flex items-center relative">
                                                            <div className="dropdown relative">
                                                                {
                                                                    index === 0 ?

                                                                        <button type='button' onClick={e => newCandidateReference()}
                                                                                className="px-1.5 py-1.5 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-xs">
                                                                            <HiOutlinePlus size={16} className={'mr-1 inline-block add-more-margin-top'}/> {t('AddMore')}
                                                                        </button>:''
                                                                }
                                                                {
                                                                    candidate.candidateReference.length !=1 ? <button
                                                                        type="button"
                                                                        className="px-1.5 py-1.5 text-white inline-flex transition duration-150 ease-in-out bg-red-400 items-center border  rounded text-xs"
                                                                        onClick={ e => removeCandidateReference(reference.id) }
                                                                    >
                                                                        <AiOutlineMinus size={12}/>
                                                                        <span>&nbsp;</span>
                                                                    </button> : ''
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </nav>*/}


                                                <div className="shadow    sm:rounded border mb-2" key={index} id={reference.id}>
                                                    <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                                        {
                                                            candidate.candidateReference.length !=1 ? <div className="text-right"> <button
                                                                type="button"
                                                                className="px-1.5 py-1.5 text-white inline-flex transition duration-150 ease-in-out bg-red-400 items-center border  rounded text-xs"
                                                                onClick={ e => removeCandidateReference(reference.id) }
                                                            >
                                                                <AiOutlineMinus size={12}/>
                                                                <span>&nbsp;</span>
                                                            </button></div> : ''
                                                        }
                                                        <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>

                                                            <div className=" w-1/2 items-center">
                                                                <label htmlFor="reference_name" className="form-input-sm-label">{t('Name')}</label>
                                                                <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                    {errors.reference_name?.type === 'required' &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.reference_name?.message}
                                                                    </div>}
                                                                    <input
                                                                        className={`form-input-sm-control ${errors.reference_name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'}`}
                                                                        onBlur={(e) => UpdateCandidateReference(reference.id, "reference_name",e.target.value)}

                                                                        id={`reference_name_${index}`}
                                                                        placeholder={t("EnterReferenceName")}
                                                                        defaultValue={reference?reference.referenceName:''}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className=" w-1/2 items-center">
                                                                <label htmlFor="relation" className="form-input-sm-label">{t('Relation')}</label>
                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                    {errors.relation?.type === 'required' &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.relation?.message}
                                                                    </div>}
                                                                    <input
                                                                        className={`form-input-sm-control ${errors.relation ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'}`}
                                                                        onBlur={(e) => UpdateCandidateReference(reference.id, "relation",e.target.value)}

                                                                        id={`relation_${index}`}
                                                                        placeholder={t("EnterRelation")}
                                                                        defaultValue={reference?reference.referenceRelation:''}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className=" w-1/2 items-center">
                                                                <label htmlFor="reference_mobile" className="form-input-sm-label">{t('Mobile')}</label>
                                                                <div className="relative mt-1 sm:col-span-2 mr-3 sm:mt-0">
                                                                    {errors.reference_mobile?.type === 'required' &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.reference_mobile?.message}
                                                                    </div>}
                                                                    <input
                                                                        className={`form-input-sm-control ${errors.reference_mobile ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'}`}
                                                                        onBlur={(e) => UpdateCandidateReference(reference.id, "reference_mobile",e.target.value)}

                                                                        id={`reference_mobile_${index}`}
                                                                        name="reference_mobile"
                                                                        placeholder={t("EnterMobileNumber")}
                                                                        defaultValue={reference?reference.referencePhone:''}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className=" w-1/2 items-center">
                                                                <label htmlFor="reference_email" className="form-input-sm-label">{t('Email')}</label>
                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                    {errors.reference_email?.type === 'required' &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.reference_email?.message}
                                                                    </div>}
                                                                    <input
                                                                        className={`form-input-sm-control ${errors.reference_email ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'}`}
                                                                        onBlur={(e) => UpdateCandidateReference(reference.id, "reference_email",e.target.value)}

                                                                        id={`reference_email_${index}`}
                                                                        name="reference_email"
                                                                        placeholder={t("EnterEmail")}
                                                                        defaultValue={reference?reference.referenceEmail:''}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    {
                                                        index === 0 ?

                                                            <button type='button' onClick={e => newCandidateReference()}
                                                                    className="px-1.5 py-1.5 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-xs">
                                                                <HiOutlinePlus size={16} className={'mr-1 inline-block add-more-margin-top'}/> {t('AddMore')}
                                                            </button>:''
                                                    }

                                                </div>
                                            </td>
                                        </tr>

                                    </table>
                                </>
                            );
                        }) }
                    </div>

                </div>
            </Stepper.Step>

            <Stepper.Step label={t("ImageAndAttachmentInformation")} description={t('ImageAndAttachmentDetails')}>
                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2 mr-3">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0 ml-3">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("ProfileInformation")}</h3>
                            <p className="mt-1 text-sm text-gray-600">
                                {t('ProfileDetails')}
                            </p>
                        </div>
                    </div>

                    <div className="mt-5 md:col-span-4 md:mt-0">
                        <div className="shadow    sm:rounded">
                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">

                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                    <div className=" w-1/2 items-center">
                                        <Dropzone
                                            onDrop={(file) => candidateProfileImageUpload(file[0])}
                                            onReject={(files) => console.log('rejected files', files)}
                                            maxSize={3 * 1024 ** 2}
                                            accept={{
                                                'image/*': []
                                            }}
                                            multiple={false}
                                        >
                                            <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
                                                <Dropzone.Accept>
                                                    <IconUpload
                                                        size={50}
                                                        stroke={1.5}
                                                        color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                    />
                                                </Dropzone.Accept>
                                                <Dropzone.Reject>
                                                    <IconX
                                                        size={50}
                                                        stroke={1.5}
                                                        color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                    />
                                                </Dropzone.Reject>
                                                <Dropzone.Idle>
                                                    <IconPhoto size={50} stroke={1.5} />
                                                </Dropzone.Idle>

                                                <div>
                                                    <Text size="xl" inline>
                                                        {t('DragProfileImage')}
                                                    </Text>
                                                    <Text size="sm" color="dimmed" inline mt={7}>
                                                        Attach as many files as you like, each file should not exceed 5mb
                                                    </Text>
                                                </div>
                                            </Group>
                                        </Dropzone>
                                    </div>

                                    <div className=" w-1/2 items-center">
                                        <div className="rounded-t-lg bg-white px-6 pt-6 pb-4">
                                            <div>
                                                <ul role="list" className="mt-2">
                                                    {candidate.profileImage ?
                                                        <img className="mx-auto h-1/2 w-1/2 mb-8" src={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${candidate.profileImage}`} alt="Simple Staffing"/> :                                                                         <img className="mx-auto h-1/2 w-1/2 mb-8" src={"/assets/images/No_Image.jpg"} alt="Simple Staffing"/>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:grid md:grid-cols-5 md:gap-6 mt-2 mr-3">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0 ml-3">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("AttachmentInformation")}</h3>
                            <p className="mt-1 text-sm text-gray-600">
                                {t('AttachmentDetails')}
                            </p>
                        </div>
                    </div>

                    <div className="mt-5 md:col-span-4 md:mt-0">
                        <div className="shadow    sm:rounded">
                            <div className="space-y-1 bg-gray-50 px-4 py-5 sm:p-6">
                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                    <div className="mx-auto justify-items-right w-full">
                                        <section className="-mx-0.5">
                                            <div className="flex flex-col justify-center h-full">
                                                <table className="table-full w-full" id="attachmentTable">
                                                    <thead className="text-xs font-semibold  text-black bg-gray-200">
                                                    <tr>

                                                        <th className="p-2">
                                                            <div className="font-semibold text-left">{t('AttachmentLebel')}</div>
                                                        </th>
                                                        <th className="p-2">
                                                            <div className="font-semibold text-left">{t('FileName')}</div>
                                                        </th>
                                                        <th className="p-2">
                                                            <div className="font-semibold text-left">{t('Attachment')}</div>
                                                        </th>
                                                        <th align={`right`} className="p-2">

                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    { candidate.candidateAttachment && candidate.candidateAttachment.length > 0 && candidate.candidateAttachment.map((attachment, index) => {
                                                        return (
                                                            <tr className={"bg-gray-100"} key={attachment.id}>

                                                                <td>
                                                                    <input
                                                                        onBlur={(e) => candidateAttachmentUpload(attachment.id, "label",e.target.value)}
                                                                        type="text"
                                                                        className={`form-input-sm-control`}
                                                                        name="name"
                                                                        id={`label_${index}`}
                                                                        defaultValue={attachment.label ? attachment.label : ''}

                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input type="hidden" id={`attachment_${index}`} value={attachment && attachment.attachment ?attachment.attachment:'' }/>
                                                                    <span className="ml-6">
                                                                        <a href={`${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/user-attachment/${attachment.attachment}`} target="new">
                            {attachment && attachment.attachment ?attachment.attachment:'' }
                        </a>

                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <Dropzone
                                                                        onDrop={(file) => candidateAttachmentUpload(attachment.id,'file',file[0])}
                                                                        onReject={(files) => console.log('rejected files', files)}
                                                                        // accept={[MIME_TYPES.csv,MIME_TYPES.pdf,MIME_TYPES.doc,MIME_TYPES.docx,MIME_TYPES.xls,MIME_TYPES.xlsx,MIME_TYPES.ppt,MIME_TYPES.pptx,MIME_TYPES.png,MIME_TYPES.jpeg,MIME_TYPES.mp4]}
                                                                        accept={[MIME_TYPES.pdf,MIME_TYPES.doc,MIME_TYPES.docx,MIME_TYPES.png,MIME_TYPES.jpeg]}
                                                                        multiple={false}
                                                                    >
                                                                        <Group position="center" spacing="sm" style={{ pointerEvents: 'none' }}>
                                                                            <Dropzone.Accept>
                                                                                <IconUpload
                                                                                    stroke={1.5}
                                                                                    color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                />
                                                                            </Dropzone.Accept>
                                                                            <Dropzone.Reject>
                                                                                <IconX
                                                                                    stroke={1.5}
                                                                                    color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                />
                                                                            </Dropzone.Reject>
                                                                            <Dropzone.Idle>
                                                                                <IconPhoto  stroke={1.5} />
                                                                            </Dropzone.Idle>

                                                                            <div>
                                                                                <Text size="sm" inline>
                                                                                    {t('DragAttachment')}
                                                                                </Text>
                                                                            </div>
                                                                        </Group>
                                                                    </Dropzone>
                                                                </td>


                                                                <td align={"right"} className="pr-2" width={20}>
                                                                    <button
                                                                        type="button"
                                                                        className="px-1.5 py-1.5 bg-red-500 hover:bg-red-600 text-white text-xs"
                                                                        onClick={ e => removeCandidateAttachment(attachment.id) }
                                                                    >
                                                                        <AiOutlineMinus size={12} className={'mr-1'}/>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }) }
                                                    <tr>
                                                        <td colSpan={"4"}>
                                                            <a className=" mt-3 flex font-bold text-indigo-500 cursor-pointer hover:text-indigo-800 hover:underline"
                                                               onClick={e => newAttachment()}>
                                                                <HiOutlinePlus size={16} className={'mr-1 inline-block add-more-margin-top'}/> {t('AddMore')}                                                            </a>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br/>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Stepper.Step>
        </Stepper>



                                                        </div>
                                                        </ScrollArea>
                                                        <footer className="relative mt-1 border-t">
                                                            <div className="mr-6 flow-root">
        <div className="float-left pt-0.5 mb-0.5 ">
            <Button onClick = {(e) =>{setShowModal(true);}} className="px-6 py-2 ml-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-blue-600 bg-blue-700 items-center border  rounded text-sm mr-2"><HiEye size={16} className={'mr-2'} /><span> {t('Preview')}</span></Button>
        </div>
                                                                <div className="float-right  pt-0.5 mb-0.5">

            {
                active !=0 ? <Button  onClick={ (e) =>{
                    setRecall(true)
                    prevStep()
                } } className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-red-600 bg-red-700 items-center border  rounded text-sm mr-2"> <HiArrowCircleLeft size={16} className={'mr-2'}/><span> {t('Previous')}</span></Button>:''
            }

            <button type='submit'
                    disabled={clickDiseabled}
                    className="inline-flex items-center rounded bg-green-700 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-0">
                {spinner ? (
                    <AiOutlineLoading3Quarters className={'mr-2 animate-spin'} size={16}/>
                ) : ( active==5 ? <FiSave size={16} className={'mr-2'}/> :<HiArrowCircleRight size={16} className={'mr-2'}/>)}
                <span>{ active==5?  t('Submit'): t('Next')}</span>
            </button>
        </div>
                                                            </div>
                                                        </footer>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {showModal ? <ViewCandidate setShowModal={setShowModal} candidateid={candidate.id}/> : ''}
            {designationHistoryModel ? <DesignationHistoryModel
                setDesignationHistoryModel={setDesignationHistoryModel}
                designationHistoryExtendId={designationHistoryExtendId}
                designationHistoryID={designationHistoryID}
            /> : ''}

        </>
    )
}

export default Update