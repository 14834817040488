import React, {useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {HiOutlineExclamation, HiOutlineOfficeBuilding, HiChevronLeft, HiArrowCircleRight} from "react-icons/hi";

import {FiSave, FiX} from "react-icons/fi";

import {AiOutlineLoading3Quarters, AiOutlineMinus} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import  {Toaster} from "react-hot-toast";
import { ScrollArea, Select, Stepper, Text} from "@mantine/core";
import {showNotification} from "@mantine/notifications";


function Update() {
    const navigate = useNavigate();
    const {roles, token} = JSON.parse(localStorage.getItem('user'));
    const {t, i18n} = useTranslation();
    const fullFormHeight = localStorage.getItem('fullFormHeight');
    const {notificationId} = useParams();

    //DROPDOWN DATA START

    const [services, setServices] = useState([]);
    const [serviceData, setServiceData] = useState();
    let serviceDropdown = [];
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/notification/master-data/dropdown?type=service-name`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setServices(res.data.data)


                }
            });
    }, []);
    if (services) {
        serviceDropdown =
            services.map((type, index) => {
                return ({
                    'label': type.name, 'value': type.id
                })
            })
    }

    const [modules, setModules] = useState([]);
    const [moduleData, setModuleData] = useState();
    let moduleDropdown = [];
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/notification/master-data/dropdown?type=module-name`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setModules(res.data.data)
                }
            });
    }, []);
    if (modules) {
        moduleDropdown =
            modules.map((type, index) => {
                return ({
                    'label': type.name, 'value': type.id
                })
            })
    }

    const ServiceDataHandel = (e) => {
        setServiceData(e);
        setValue('service_id',e)
        if (e == '' || e == null){
            setError("service_id", Yup.string().required(t("ChooseService")).nullable());
        }else {
            setError("service_id", null);
        }
    }

    const ModuleDataHandel = (e) => {
        setModuleData(e);
        setValue('module_id',e)
        if (e == '' || e == null){
            setError("module_id", Yup.string().required(t("ChooseModule")).nullable());
        }else {
            setError("module_id", null);
        }
    }
    //DROPDOWN DATA END

    useEffect(()=>{
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/notification/${notificationId}`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                if (res.status === 200) {
                    setValue('name',res.data.data.name)
                    setValue('email_content',res.data.data.email_content)
                    setValue('sms_content',res.data.data.sms_content)
                    setValue('push_content',res.data.data.push_content)
                    setServiceData(res.data.data.service_id)
                    setValue('service_id',res.data.data.service_id)
                    setModuleData(res.data.data.module_id)
                    setValue('module_id',res.data.data.module_id)
                } else {
                    console.log(res)
                }
            })
            .catch(function (error) {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('error')),
                    message: error.response.data.message,
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
                setSpinner(false)
            })
    },[])


    //Submit Spinner Init
    const [spinner, setSpinner] = useState(false);

    //Form validation
    const validationSchemaFirstStep = Yup.object().shape({
        service_id: Yup.string().required(t("ChooseService")),
        module_id: Yup.string().required(t("ChooseModule")),
        name: Yup.string().required(t("EnterName")),
        email_content: Yup.string().required(t("EnterEmailContent")),
        sms_content: Yup.string().required(t("EnterSmsContent")),
        push_content: Yup.string().required(t("EnterPushContent")),
    });

    let formOptions = {resolver: yupResolver(validationSchemaFirstStep)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState,setValue ,setError} = useForm(formOptions);
    const {errors} = formState;


    //Form Data Submit
    function formSubmit(data) {
        setSpinner(true);
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/notification/${notificationId}`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: data
        })
            .then(res => {
                if (res.status === 200) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('SuccessfullyDataAdded')),
                        message: (t('DataLoad')),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'green',
                    });
                    setSpinner(false);
                    navigate('/notification')
                } else {
                    console.log(res)
                }
            })
            .catch(function (error) {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (t('error')),
                    message: error.response.data.message,
                    autoClose: 1000,
                    disallowClose: true,
                    color: 'red',
                });
                setSpinner(false)
            })
    }

    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Notification')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>
                                    {t('ManageNotificationInformation')}
                                </sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <Link to='/client'
                                      className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={12} className={'mr-1'}/>{t('Back')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 sm:px-6 lg:px-4">
                                        <div className="relative">

                                            <div className={'flex flex-1 w-full'}>
                                                <div className="w-full mr-0 mx-auto border">

                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                        <ScrollArea style={{ height: fullFormHeight }} scrollbarSize={4}>
                                                            <div className="py-3 pl-3 pr-3 relative flex flex-col min-w-0 break-words w-full bg-gray-200">

    {/*                COMPANY INFORMATION START                   */}
    <div className="md:grid md:grid-cols-5 md:gap-6 mt-2">
        <div className="md:col-span-1">
            <div className="px-4 sm:px-0 ml-2">
                <h3 className="text-lg font-medium leading-6 text-gray-900">{t("NotificationInformation")}</h3>
                <p className="mt-1 text-sm text-gray-600">
                    {t('NotificationDetails')}
                </p>
            </div>
        </div>
        <div className="mt-5 md:col-span-4 md:mt-0">
            <div className="shadow     sm:rounded-md">
                <div className="bg-gray-50 px-4 py-5 sm:p-6">

                    <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                        <div className="mb-3 w-1/2 items-center">
                            <label htmlFor="service_id" className="form-input-sm-label-required">{t('ServiceName')}</label>
                            <div
                                className="relative mt-1 sm:col-span-2 mr-3 sm:mt-0">
                                {errors.service_id?.type === 'required' &&
                                <div className="form-input-sm-error">
                                    <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.service_id?.message}
                                </div>}
                                <Select
                                    {...register("service_id")}
                                    placeholder={t('ChooseService')}
                                    className={`${errors.service_id ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                    searchable clearable
                                    allowDeselect
                                    nothingFound="No options"
                                    data={serviceDropdown}
                                    transition="pop-top-left"
                                    transitionDuration={80}
                                    transitionTimingFunction="ease"
                                    onChange={ServiceDataHandel}
                                    value={serviceData}
                                />
                            </div>
                        </div>

                        <div className="mb-3 w-1/2 items-center">
                            <label htmlFor="CompanyStatus" className="form-input-sm-label-required">{t('ModuleName')}</label>
                            <div
                                className="relative mt-1 sm:col-span-2 sm:mt-0">
                                {errors.module_id?.type === 'required' &&
                                <div className="form-input-sm-error">
                                    <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.module_id?.message}
                                </div>}
                                <Select
                                    {...register("module_id")}
                                    placeholder={t('ChooseModule')}
                                    className={`${errors.module_id ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                    searchable clearable
                                    allowDeselect
                                    nothingFound="No options"
                                    data={moduleDropdown}
                                    transition="pop-top-left"
                                    transitionDuration={80}
                                    transitionTimingFunction="ease"
                                    onChange={ModuleDataHandel}
                                    value={moduleData}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="mb-3 w-full items-center">
                        <label htmlFor="CompanyName" className="form-input-sm-label-required">{t('EmailSubject')}</label>
                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                            {errors.name?.type === 'required' &&
                            <div className="form-input-sm-error">
                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.name?.message}
                            </div>}
                            <input
                                type="text"
                                {...register("name")}
                                className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                placeholder={t("EnterEmailSubject")}
                            />
                        </div>
                    </div>

                    <div className="mb-3 w-full items-center">
                        <label htmlFor="email_content" className="form-input-sm-label-required">{t('EmailContent')}</label>
                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                            {errors.email_content?.type === 'required' &&
                            <div className="form-input-sm-error">
                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.email_content?.message}
                            </div>}
                            <textarea
                                type="text"
                                {...register("email_content")}
                                className={`form-input-sm-control candidate-remark ${errors.email_content ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                placeholder={t("EnterEmailContent")}
                                rows={5}
                            />
                        </div>
                    </div>

                    <div className="mb-3 w-full items-center">
                        <label htmlFor="sms_content" className="form-input-sm-label-required">{t('SmsContent')}</label>
                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                            {errors.sms_content?.type === 'required' &&
                            <div className="form-input-sm-error">
                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.sms_content?.message}
                            </div>}
                            <textarea
                                type="text"
                                {...register("sms_content")}
                                className={`form-input-sm-control candidate-remark ${errors.sms_content ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                placeholder={t("EnterSmsContent")}
                            />
                        </div>
                    </div>

                    <div className="mb-3 w-full items-center">
                        <label htmlFor="push_content" className="form-input-sm-label-required">{t('PushContent')}</label>
                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                            {errors.push_content?.type === 'required' &&
                            <div className="form-input-sm-error">
                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.push_content?.message}
                            </div>}

                            <textarea
                                type="text"
                                {...register("push_content")}
                                className={`form-input-sm-control candidate-remark ${errors.push_content ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                placeholder={t("EnterPushContent")}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    {/*                COMPANY INFORMATION END                   */}


                                                            </div>
                                                        </ScrollArea>

                                                        <footer className="relative mt-1">
                                                            <div className="mr-3">
                                                                <div className="text-right pt-0.5 mb-0.5">
                                                                    <button type='reset' className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}><FiX size={16} className={'mr-2'}/>
                                                                        <span>{t('Cancel')}</span>
                                                                    </button>
                                                                    <button type='submit' className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-sm">
                                                                        {spinner ? (
                                                                            <AiOutlineLoading3Quarters
                                                                                className={'mr-2 animate-spin'}
                                                                                size={16}/>
                                                                        ) : (<HiArrowCircleRight size={16} className={'mr-2'}/>)}
                                                                        <span>{t('Submit')}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </footer>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default Update