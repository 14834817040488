import React, {useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {
    HiEye,
    HiOutlineChartSquareBar, HiOutlineExclamation,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiOutlinePlus, HiOutlineFilter,
    HiPencil, HiTrash,HiCheck,HiLockClosed
} from "react-icons/hi";
import {Link, useNavigate} from "react-router-dom";
import sortBy from 'lodash.sortby';
import {DataTable} from "mantine-datatable";
import {ActionIcon, Box, Group, Text, Checkbox, Grid, TextInput, ScrollArea, Select, Drawer} from "@mantine/core";
import {useForm} from "react-hook-form";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import toast, { Toaster } from 'react-hot-toast';
import ViewCandidate from "./modal/ViewCandidate";
import { useDebouncedValue } from '@mantine/hooks';
import {openConfirmModal} from "@mantine/modals";
import {showNotification} from "@mantine/notifications";
import {FiX} from "react-icons/fi";
import { format } from 'date-fns';


const PER_PAGE = 20;

function Index() {
    const notify = () => toast.error('Error',{
        "position": 'top-center',
        "duration": 2000,
    });

    const [opened, setOpened] = useState(false);
    const navigate = useNavigate();
    const {roles, token} = JSON.parse(localStorage.getItem('user'));
    const drawerHeight = localStorage.getItem('drawerHeight');

    const {t, i18n} = useTranslation();
    // const [page, setPage] = useState(localStorage.getItem('item_page') ? localStorage.getItem('item_page') : 1);
    const [page, setPage] = useState(1);
    const [activeTab, setActiveTab] = useState('all');
    const [candidateList, setCandidateList] = useState([]);
    const [sortStatus, setSortStatus] = useState({ columnAccessor: 'createdAt', direction: 'desc' });
    const [totalCandidates, setTotalCandidates] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [candidateStatusList, setCandidateStatusList] = useState([]);
    const [candidateID,setCandidateID] = useState('');

    const [emailData, setEmailData] = useState(null);
    const EmailDataHandel = (e) => {
        setEmailData(e.target.value);
    }

    const [nameData, setNameData] = useState(null);
    const NameDataHandel = (e) => {
        setNameData(e.target.value);
    }

    const [employeeIdData, setEmployeeIdData] = useState(null);
    const EmployeeIdDataHandel = (e) => {
        setEmployeeIdData(e.target.value);
    }

    const [phoneNumberData, setPhoneNumberData] = useState(null);
    const PhoneNumberDataHandel = (e) => {
        setPhoneNumberData(e.target.value);
    }

    const [spinner, setSpinner] = useState(false);
    const [refresh, setRefresh] = useState(false);

    //Form validation
    const validationSchema = Yup.object().shape({
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState ,setValue } = useForm(formOptions);
    const { errors } = formState;

    function formSubmit(data) {
        setSpinner(true);
        setFetching(true);
        if (page === 1){
            setRefresh(true);
        }else {
            setPage(1);
        }
        setOpened(false)
    }


    const getCandidateStatusList = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/master-data`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "type_slug": "candidate-status",
            }
        })
            .then(res => {
                let arr = res.data.data;
                arr.reverse();
                arr.push({id: 'all', name: 'All', slug: 'all', status: true, typeName: 'Candidate Status'});
                arr.reverse();
                setCandidateStatusList(arr)
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    useEffect(() => {
        getCandidateStatusList();
    }, []);

    useEffect(() => {
        setFetching(true);
    }, [sortStatus]);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/candidate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "per_page": PER_PAGE,
                "page": page,
                "status": activeTab,
                "email": emailData?emailData:'',
                "phoneNumber": phoneNumberData?phoneNumberData:'',
                "name": nameData?nameData:'',
                "employeeId": employeeIdData?employeeIdData:'',
                "order_type": sortStatus.columnAccessor?sortStatus.columnAccessor:'',
                "order_by": sortStatus.direction?sortStatus.direction:'',
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setTimeout(() => {
                        setCandidateList(res.data.data)
                        setTotalCandidates(res.data.total)

                        setSpinner(false);
                        setRefresh(false);
                        setFetching(false);
                    },500)
                }
            })
            .catch(function (error) {
                console.log(error)
            })

    }, [page, activeTab, refresh, sortStatus]);

    useEffect(()=> {
        setPage(1)
    },[activeTab])

    const [records, setRecords] = useState(candidateList);
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);

    useEffect(() => {
        setRecords(
            candidateList.filter(({ name, email, phoneNumber }) => {
                if (
                    debouncedQuery !== '' &&
                    !`${name} ${email} ${phoneNumber}`
                        .toLowerCase()
                        .includes(debouncedQuery.trim().toLowerCase())
                ) {
                    return false;
                }
                return true;
            })
        );


    }, [debouncedQuery]);


    const CandidateDelete = (e, id) => {
        e.preventDefault();
        openConfirmModal({
            title: (t('DeleteThisData')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureYouWantToDelete')}
                </Text>
            ),
            labels: {confirm: (t('Delete')), cancel: (t('NoDontDeleteIt'))},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () =>
                (
                    axios({
                        method: 'delete',
                        url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/delete`,
                        headers: {
                            "Accept": `application/json`,
                            "Content-Type": `application/json`,
                            "Access-Control-Allow-Origin": '*',
                            "Authorization": `Bearer ${token}`
                        },
                        data:{
                            user_id : id
                        }
                    })
                        .then(res => {
                            if (res.status === 202) {
                                setRefresh(true)
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                )
            ,
        });
    }




    const fullTableHeight = localStorage.getItem('fullTableHeight');
    return (
        <>
        <Toaster toastOptions={{ className: 'w-1/2' }} containerStyle={{ top : 63 }} />
            <main className="w-full" >
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                                            {t('Candidate')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('AllCandidateRelatedInformationCanBeFoundHere')}</sub></span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <button
                                    onClick={() => setOpened(true)}
                                    className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0">
                                    < HiOutlineFilter size={12} className={'mr-1'}/>{t('Filter')}
                                </button>

                                <Link to ='/candidate/register' className="inline-flex items-center ml-3 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiOutlinePlus size={12} className={'mr-1'}/>{t('AddCandidate')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full px-4">
                    <Grid align="center">
                        <Grid.Col xs={8} sm={8}>
                            <nav className="isolate flex divide-x divide-gray-200 rounded-t-lg shadow" aria-label="Tabs">
                                {
                                    candidateStatusList.length > 0 && candidateStatusList.map((status, index) => (
                                        <button
                                            key={index}
                                            className="text-gray-900 rounded group relative min-w-0 flex-1 overflow-hidden bg-gray-100 py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                            aria-current="page"
                                            onClick={() => {
                                                setActiveTab(status.slug)
                                                setFetching(true)
                                            }}
                                        >
                                            <span>{t(status.name)}</span>
                                            {activeTab === status.slug  && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5"/> }

                                        </button>
                                    ))
                                }
                            </nav>
                        </Grid.Col>
                        <Grid.Col xs={4} sm={4}>

                        </Grid.Col>
                    </Grid>


                    <Drawer
                        opened={opened}
                        onClose={() => setOpened(false)}
                        title="Filter Candidate"
                        padding="xl"
                        size="xl"
                        overlayColor={"black"}
                        overlayOpacity={0.55}
                        overlayBlur={3}
                        position={"right"}
                        transitionDuration={250}
                        transitionTimingFunction="ease"
                    >
                        <form onSubmit={handleSubmit(formSubmit)}>
                            <ScrollArea style={{ height: drawerHeight }}>


                                <div className="mb-2">
                                    <TextInput
                                        {...register("name")}
                                        onChange={NameDataHandel}
                                        placeholder={t("Enter Name or Surname")}
                                        value={nameData?nameData:''}
                                        withAsterisk={false}
                                        label={t("Name or Surname")}
                                    />
                                </div>

                                <div className="mb-2">
                                    <TextInput
                                        {...register("employeeId")}
                                        onChange={EmployeeIdDataHandel}
                                        placeholder={t("CandidateId")}
                                        value={employeeIdData?employeeIdData:''}
                                        withAsterisk={false}
                                        label={t("CandidateId")}
                                    />
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="Email" className="form-input-sm-label">{t('Email')}</label>
                                    <TextInput
                                        {...register("email")}
                                        onChange={EmailDataHandel}
                                        placeholder={t("EnterEmail")}
                                        value={emailData?emailData:''}
                                        withAsterisk
                                    />
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="Phone Number" className="form-input-sm-label">{t('Phone Number')}</label>
                                    <TextInput
                                        {...register("phoneNumber")}
                                        onChange={PhoneNumberDataHandel}
                                        placeholder={t("EnterPhoneNumber")}
                                        value={phoneNumberData?phoneNumberData:''}
                                        withAsterisk
                                    />
                                </div>

                            </ScrollArea>



                            <div className="mb-2 mt-5 text-right">

                                <Grid>
                                    <Grid.Col span={6}>

                                    </Grid.Col>
                                    <Grid.Col span={3}>
                                        <button type='reset' className="px-6 py-2 bg-red-800 text-white inline-flex focus:ring-red-700 transition duration-150 ease-in-out hover:bg-red-600 bg-red-700 items-center border  rounded text-sm"
                                                onClick={(e)=>{
                                                    setNameData(null)
                                                    setPhoneNumberData(null)
                                                    setEmployeeIdData(null)
                                                    setEmailData(null)

                                                    setValue('email',null)
                                                    setValue('employeeId',null)
                                                    setValue('name',null)
                                                    setValue('phoneNumber',null)
                                                }}
                                        >
                                            <FiX size={16}
                                                 className={'mr-2'}/>
                                            <span>{t('Clear')}</span>
                                        </button>

                                    </Grid.Col>
                                    <Grid.Col span={3}>
                                        <button type='submit' className="px-6 py-2 bg-indigo-800 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                            {spinner ? (
                                                <HiOutlineFilter
                                                    className={'mr-2 animate-spin'}
                                                    size={16}/>
                                            ) : (<HiOutlineFilter size={16} className={'mr-2'}/>)}
                                            <span>{t('Filter')}</span>
                                        </button>
                                    </Grid.Col>
                                </Grid>


                            </div>
                        </form>

                    </Drawer>

                    <Box sx={{height: fullTableHeight}}>

                        <DataTable
                            withBorder
                            striped
                            styles={(theme) => ({
                                root: {
                                    border: '1px solid #e0e7ff',
                                    borderRadius: 2,
                                },
                                header: {
                                    background: '#e0e7ff',
                                    backgroundColor: "#e0e7ff",
                                    color: theme.colors.indigo[6],
                                },
                                pagination: {
                                    color: theme.colors.gray[3],
                                    paddingTop : "5px",
                                    paddingBottom : "5px"
                                },
                                item: {
                                    '&[data-active]': {
                                        backgroundImage: theme.fn.gradient({ from: 'red', to: 'yellow' }),
                                    },
                                },
                            })}
                            records={candidateList}
                            // records={records}
                            columns={[
                                {
                                    accessor: '',
                                    title: (t('SN.')),
                                    width: 45,
                                    render: (candidate) => (candidateList.indexOf(candidate) + 1) + PER_PAGE * (page - 1)
                                },
                                {
                                    accessor: 'name',
                                    title: (t('Name')),
                                    sortable: true
                                },
                                {
                                    accessor: 'genderName',
                                    title: (t('Gender')),
                                },
                                {
                                    accessor: 'email',
                                    title : (t('Username')),
                                    sortable: true
                                },
                                {
                                    accessor: 'secondaryEmail',
                                    sortable: true
                                },
                                {
                                    accessor: 'phoneNumber',
                                    title: (t('PhoneNumber')),
                                    sortable: true
                                },
                                {
                                    accessor: 'employeeStatus',
                                    title : (t('Status')),
                                    width: 100,
                                    // render: ({enable}) => enable ? 'Enable' : 'Disable'
                                },{
                                    accessor: 'cityName',
                                    title : (t('City')),
                                },{
                                    accessor: 'createdAt',
                                    title: (t('JoinDate')),
                                    render: ({ createdAt }) => createdAt ? format(new Date(createdAt.date), 'MM/dd/Y') : '',
                                },
                                {
                                    accessor: 'actions',
                                    title: <Text mr="xs">{t('Action')}</Text>,
                                    textAlignment: 'right',
                                    render: (candidate) => (
                                        <>

                                        <Group spacing={4} position="right" noWrap>

                                            {/*<Switch
                                                size="lg"
                                                radius="xs"
                                                color="cyan"
                                                onLabel="Enable"
                                                offLabel="Disable"
                                                checked={candidate.enable ? true : false}
                                            />*/}

                                           {/* {
                                                candidate.enable ?
                                                    <ActionIcon  color="green" >
                                                        <HiCheck size={16}/>
                                                    </ActionIcon>
                                                    :
                                                    <ActionIcon  color="red" >
                                                        <HiLockClosed size={16}/>
                                                    </ActionIcon>
                                            }*/}


                                            <ActionIcon color="green" >
                                                <HiPencil size={16} onClick = {(e) => {navigate(candidate.id + '/edit',{
                                                    state: {
                                                        type:"list"
                                                    }
                                                })}}/>
                                            </ActionIcon>

                                            <ActionIcon color="blue">
                                                <HiEye size={16} onClick = {(e) =>{
                                                    setShowModal(true);
                                                    setCandidateID(candidate.id)
                                                }} />
                                            </ActionIcon>

                                            <ActionIcon color="red">
                                                <HiTrash size={16} onClick={(e) => CandidateDelete(e, candidate.id)} />
                                            </ActionIcon>
                                        </Group>
                                        </>
                                    ),
                                },

                            ]}
                            totalRecords={totalCandidates}
                            recordsPerPage={PER_PAGE}
                            page={page}
                            onPageChange={(p) => {
                                setPage(p)
                                setFetching(true)
                                }
                            }
                            fetching={fetching}
                            loaderSize="sm"
                            loaderColor="indigo"
                            loaderBackgroundBlur={2}
                            sortStatus={sortStatus}
                            onSortStatusChange={setSortStatus}
                        />
                    </Box>
                </div>
            </main>
            {/*{showModal ? <ViewCandidate setShowModal={setShowModal} candidateDetails={candidateDetails}/> : ''}*/}
            {showModal ? <ViewCandidate setShowModal={setShowModal} candidateid={candidateID}/> : ''}

        </>
    )
}

export default Index