import React, {Fragment, useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiEye, HiOutlineExclamation,
    HiOutlineOfficeBuilding, HiOutlineUpload,
    HiPencil, HiTrash,
    HiOutlineX,
    HiOutlineDocumentAdd,
    HiOutlineRefresh
} from "react-icons/hi";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Group, Text, Box, TextInput, ScrollArea, useMantineTheme} from "@mantine/core";
import {Dropzone, DropzoneProps, MIME_TYPES} from "@mantine/dropzone";
import { FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import View from "../master-data/designation/DesignationView";
import {createStyles} from "@mantine/styles";
import {showNotification} from '@mantine/notifications';
import {openConfirmModal} from "@mantine/modals";



function Upload() {

    const theme = useMantineTheme();
    const {t, i18n} = useTranslation();
    const {roles, token} = JSON.parse(localStorage.getItem('user'));
    const [isUpload, setIsUpload] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const [listRefresh, setListRefresh] = useState(false);
    const formHeight = localStorage.getItem('formHeight');
    const formTableHeight = localStorage.getItem('formTableHeight');
    const [uploadedFiles,setUploadedFiles] = useState([]);
    const [file, setFile] = useState(null);
    const [filePath, setFilePath] = useState(null);
    const [fetching, setFetching] = useState(true);

    const addFormControl = (e) => {
        setIsUpload(true);
    }

    // console.log(uploadedFiles)

// FOR DATA TABLE DATA SHOW START
    const PAGE_SIZE = 10;
    const [page, setPage] = useState(localStorage.getItem('designation_page') ? localStorage.getItem('designation_page') : 1);

    const [designations, setDesignation] = useState([]);
    const [totalItem, setTotalItem] = useState(0);


//Form validation
    const validationSchema = Yup.object().shape({
        // file: Yup.string().required(t("PleaseUploadFile")),
        file: Yup.mixed().required(t('PleaseUploadFile')),
    });

    const formOptions = {resolver: yupResolver(validationSchema)};

// get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState,setValue} = useForm(formOptions);
    const {errors} = formState;



    const getUploadedFiles = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/candidate/upload`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `multipart/form-data`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                setUploadedFiles(res.data.data);
                setFetching(false)
            })
            .catch(function (error) {
                console.log(error)
            })
    }

//Form Data Submit
    function formSubmit(data) {
        setSpinner(true);
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/candidate/upload`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `multipart/form-data`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: data
        })
            .then(res => {
                setSpinner(false);
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: res.data.status === 200 ? (t('Success')) : (t('Error')),
                    message: (t(res.data.message)),
                    autoClose: 2000,
                    disallowClose: true,
                    color: res.data.status === 200 ? 'green' : 'red',
                });
                getUploadedFiles();
            })
            .catch(function (error) {
                console.log(error)
            })


    }


    const useStyles = createStyles((theme) => ({
        table: {
            border: `1px solid ${theme.colors.red[6]}`,
            borderRadius: 1,
        },
    }));

    const [viewModel, setViewModel] = useState(false);
    const [viewData, setViewData] = useState(null);

    useEffect(() => {
        getUploadedFiles();
    },[])



    const candidateFileProcess = (id) => {
        openConfirmModal({
            title: (t('CandidateConfirmMessage')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureYouCandidateConfirmMessage')}
                </Text>
            ),
            labels: {confirm: (t('Confirm')), cancel: (t('Cancel'))},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () =>{
                setFetching(true)
                axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/candidate/upload-file-process`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `multipart/form-data`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    params : {
                        id : id
                    }
                })
                    .then(res => {
                        getUploadedFiles()
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: 'Success',
                            message: 'Data process successfully',
                            autoClose: 1500,
                            disallowClose: true,
                            color: 'green',
                        });
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }

        });

    }


    return (
        <>
            {viewModel ? <View data={viewData} setViewModel={setViewModel}/> : ''}

            <main className="w-full bg-blue-50" >
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-between text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-800 w-full'}>
                                {t('CandidateUpload')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('CandidateUploadHere')}</sub></span>
                        </div>
                    </div>

                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <button
                                    onClick={event => addFormControl(event)}
                                    className="inline-flex items-center ml-6 rounded border-none bg-indigo-600 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiOutlineUpload size={12} className={'mr-1'}/>{t('Upload')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className={'flex flex-1 overflow-hidden '}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex">
                                                            <div className="w-full">
                                                                <Box sx={{height: formTableHeight}}>
                                                                    <DataTable
                                                                        withBorder
                                                                        paginationSize="md"
                                                                        withColumnBorders
                                                                        striped
                                                                        styles={(theme) => ({
                                                                            root: {
                                                                                border: '1px solid #e0e7ff',
                                                                                borderRadius: 2,
                                                                            },
                                                                            header: {
                                                                                background: '#e0e7ff',
                                                                                backgroundColor: "#e0e7ff",
                                                                                color: theme.colors.indigo[6],
                                                                            },
                                                                            pagination: {
                                                                                color: theme.colors.gray[3],
                                                                                paddingTop : "5px",
                                                                                paddingBttom : "5px"
                                                                            },
                                                                            item: {
                                                                                '&[data-active]': {
                                                                                    backgroundImage: theme.fn.gradient({ from: 'red', to: 'yellow' }),
                                                                                },
                                                                            },
                                                                        })}
                                                                        records={uploadedFiles}
                                                                        columns={[
                                                                            {
                                                                                accessor: '',
                                                                                title: 'No.',
                                                                                textAlignment: 'center',
                                                                                render: (item) => (uploadedFiles.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                            },
                                                                            {
                                                                                accessor: 'name',
                                                                                title: <Text mr="xs">{t('Name')}</Text>
                                                                            },
                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text mr="xs">{t('Action')}</Text>,
                                                                                textAlignment: 'left',
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="center" noWrap>
                                                                                        {
                                                                                            item.process == 1 &&
                                                                                            <ActionIcon color="green" >
                                                                                            <span type='submit' className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm" onClick={(e)=>{
                                                                                                candidateFileProcess(item.id)
                                                                                            }}>
                                                                                                <HiOutlineRefresh size={16} className={'mr-2'}/>
                                                                                                <span>{t('Process')}</span>
                                                                                            </span>
                                                                                            </ActionIcon>
                                                                                        }

                                                                                    </Group>
                                                                                ),
                                                                            },

                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => setPage(p)}
                                                                        fetching={fetching}
                                                                        loaderSize="sm"
                                                                        loaderColor="indigo"
                                                                        loaderBackgroundBlur={2}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    isUpload && (
                                                        <aside className="sm:block  h-full  transition-all">
                                                            <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                                <div className="h-full ">
                                                                    <section className="w-96 ml-3">
                                                                        <div className="w-full mr-0 mx-auto border rounded">
                                                                            <header className="relative bg-indigo-50">
                                                                                <div className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                    <div className="text-center flex justify-between">
                                                                                        <div className="text-indigo-800 text-sm font-bold items-center leading-7">  {t('UploadHere')}</div>
                                                                                        <button
                                                                                            onClick={() => setIsUpload(false)}
                                                                                            className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                            type="button">
                                                                                            <FiX/>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </header>
                                                                            <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                                                <ScrollArea style={{ height: formHeight }}>
                                                                                    <div className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>
                                                                                        <div className="mb-3 w-full items-center">
                                                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                {
                                                                                                    errors.file?.type === 'required' &&
                                                                                                    <div className="mt-4 form-input-sm-error">
                                                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.file?.message}
                                                                                                    </div>
                                                                                                }


                                                                                                <Dropzone
                                                                                                    loading={spinner}
                                                                                                    onDrop={(file) => {
                                                                                                        setFilePath(file[0].path)
                                                                                                        setFile(file[0])
                                                                                                        setValue("file", file[0])
                                                                                                    }}
                                                                                                    // onDrop={(file) => setValue("file", file[0])}
                                                                                                    accept={[MIME_TYPES.csv]}
                                                                                                    multiple={false}

                                                                                                >
                                                                                                    <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
                                                                                                        <Dropzone.Accept>
                                                                                                            <HiOutlineUpload
                                                                                                                size={40}
                                                                                                                color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                            />
                                                                                                        </Dropzone.Accept>
                                                                                                        <Dropzone.Reject>
                                                                                                            <HiOutlineX
                                                                                                                size={40}
                                                                                                                color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                                                                                            />
                                                                                                        </Dropzone.Reject>
                                                                                                        <Dropzone.Idle>
                                                                                                            <HiOutlineDocumentAdd size={40} className="text-indigo-800"/>
                                                                                                        </Dropzone.Idle>

                                                                                                        {/*<div className="text-center">
                                                                                                            <Text size="xl" inline className="text-indigo-800">{t('DragFileHereOrClickToSelectFile')}</Text>
                                                                                                            <Text size="sm" inline mt={7} className="text-indigo-300">
                                                                                                                {t('Only')} <span className="font-bold font-italic">CSV</span> {t('formatAcceptable')}
                                                                                                            </Text>
                                                                                                        </div>*/}
                                                                                                        <div className="text-center">
                                                                                                            {
                                                                                                                file ? <Text size="xl" inline className="text-indigo-800">{t('FileSelected')} ({filePath})</Text> : (
                                                                                                                    <Fragment>
                                                                                                                        <Text size="xl" inline className="text-indigo-800">{t('DragFileHereOrClickToSelectFile')}</Text>
                                                                                                                        <Text size="sm" inline mt={7} className="text-indigo-300">
                                                                                                                            {t('Only')} <span className="font-bold font-italic">CSV</span> {t('formatAcceptable')}
                                                                                                                        </Text>
                                                                                                                    </Fragment>
                                                                                                                )
                                                                                                            }

                                                                                                        </div>
                                                                                                    </Group>
                                                                                                </Dropzone>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </ScrollArea>
                                                                                <footer className="relative mt-1">
                                                                                    <div className="container mx-auto">
                                                                                        <div
                                                                                            className="text-right border-t pt-0.5 mb-0.5">
                                                                                            <button
                                                                                                onClick={() => setIsUpload(false)}
                                                                                                type='reset'
                                                                                                className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                            ><FiX size={16}
                                                                                                  className={'mr-2'}/>
                                                                                                <span>{t('Cancel')}</span>
                                                                                            </button>
                                                                                            <button type='submit'
                                                                                                    className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                                {spinner ? (
                                                                                                    <AiOutlineLoading3Quarters
                                                                                                        className={'mr-2 animate-spin'}
                                                                                                        size={16}/>
                                                                                                ) : (<HiOutlineRefresh size={16}
                                                                                                             className={'mr-2'}/>)}
                                                                                                <span>{t('Upload')}</span>
                                                                                            </button>

                                                                                        </div>
                                                                                    </div>
                                                                                </footer>

                                                                            </form>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </aside>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Upload