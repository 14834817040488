import React, {useEffect, useState, Fragment} from "react";
import {useTranslation} from "react-i18next";
import {
    HiLocationMarker,
    HiCalendar,
    HiOutlineX, HiTrash, HiOutlineCheck, HiEye,
    HiOutlineCalendar,
    HiOutlineUserCircle,
    HiOutlineClock,
    HiOutlineCurrencyDollar
} from "react-icons/hi";
import {format} from 'date-fns';
import {ActionIcon, Switch, Tooltip, Popover, Text, ScrollArea, Accordion, Grid} from "@mantine/core";
import axios from "axios";
import {showNotification} from "@mantine/notifications";
import button from "@material-tailwind/react";
import {Tab} from "@headlessui/react";



export default function ViewJobAdmin({setShowModal, jobDetails, roles}) {
    const {token, loggedUserId} = JSON.parse(localStorage.getItem('user'));
    const {t, i18n} = useTranslation();
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const [recall, setRecall] = useState(false);
    const [candidateList, setCandidateList] = useState([]);

    const assignedCandidateAcceptance = (status, userId, rowId, acceptance) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-assigned-candidate-acceptance`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                status,
                userId,
                rowId,
                acceptance
            }
        })
            .then(res => {
                if (res.data.status === 202){
                    setRecall(!recall)
                }
            });
    }
    const designationWiseAllCandidateByJob = (jobId) => {
        // designation-wise-all-candidate
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/designation-wise-all-candidate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "jobId": Number(jobId)
            }
        })
            .then(res => {
                setCandidateList(res.data.data);


            });
    }
    const assignCandidate = (rowId, candidateId, designationId, designationSlug, status) => {
        // console.log({
        //     rowId,
        //     candidateId,
        //     designationId,
        //     designationSlug,
        //     status
        // })
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-assign-candidate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                rowId,
                candidateId,
                designationId,
                designationSlug,
                process: status
            }
        })
            .then(res => {
                setRecall(! recall);
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    useEffect(()=>{
        designationWiseAllCandidateByJob(jobDetails.id)
    },[])

    const modalHeight = localStorage.getItem('modalHeight');

    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    {/*content*/}
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div
                                className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{jobDetails.title}</h5>
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <aside className="w-full sm:w-1/3 md:w-1/4 px-2">
                                            <div className="sticky top-0 w-full">
                                                <div className="mt-2">
                                                    <div className="mx-auto  max-w-lg lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4 lg:mx-0 lg:mt-10 lg:max-w-none">
                                                        <div className="relative z-10 rounded-lg shadow-xl">
                                                            <div className="pointer-events-none absolute inset-0 rounded-lg border-2 border-indigo-600" aria-hidden="true"/>
                                                            <div
                                                                className="absolute inset-x-0 top-0 translate-y-px transform">
                                                                <div
                                                                    className="flex -translate-y-1/2 transform justify-center">
                                                                        <span className="inline-flex rounded-full bg-indigo-600 px-4 py-1 text-base font-semibold text-white"><HiLocationMarker  size={20} className={"mr-1"}/>{jobDetails.city ? jobDetails.city.split('-')[1] : ''}, {jobDetails.country ? jobDetails.country.split('-')[1] : ''}</span>
                                                                </div>
                                                            </div>
                                                            <div className="rounded-t-lg bg-white px-6 pt-10 pb-4">
                                                                <div>
                                                                    <div className="flex items-center">
                                                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('Availability')}</h4>
                                                                        <div className="flex-1 border-t-2 border-gray-200"/>
                                                                    </div>
                                                                    <ul role="list" className="mt-2">
                                                                        <li className="flex items-start lg:col-span-1 mb-2">
                                                                            <div className="flex-shrink-0">
                                                                                <HiOutlineCalendar size={20} className={`bg-indigo-500 text-white`}/>
                                                                            </div>
                                                                            <p className="ml-3 text-sm text-gray-700">{t('Start')}: {jobDetails.startTime ? format(new Date(jobDetails.startTime.date), 'dd, MMMM Y') : ''}</p>
                                                                        </li>
                                                                        <li className="flex items-start lg:col-span-1">
                                                                            <div className="flex-shrink-0">
                                                                                <HiOutlineCalendar size={20} className={`bg-indigo-500 text-white`}/>
                                                                            </div>
                                                                            <p className="ml-3 text-sm text-gray-700">{t('End')}: {jobDetails.endTime ? format(new Date(jobDetails.endTime.date), 'dd, MMMM Y') : ''}</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="rounded-b-lg border-t-2 border-gray-100 bg-gray-50">
                                                                <p className="block w-full rounded-lg border border-transparent bg-indigo-600 px-6 py-4 text-center text-xl font-medium leading-6 text-white hover:bg-indigo-700"> {jobDetails.vacancy ? jobDetails.vacancy : ''} {t('Person')}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4 py-1 border-t border-blueGray-200 text-left">&nbsp;</div>
                                                    <ScrollArea style={{ height: 250 }} scrollbarSize={4}>
                                                        <div className="mt-1 py-1 text-left">
                                                            <div className="flex flex-wrap">
                                                                <div className="w-full">
                                                                    {jobDetails.locationMap ? jobDetails.locationMap : 'Location Map Here'}
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-wrap">
                                                                <div className="w-full">
                                                                    <p className="text-slate-800">{jobDetails.description ? jobDetails.description : ''}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ScrollArea>

                                                </div>
                                            </div>

                                        </aside>
                                        <main role="main" className="w-full sm:w-2/3 md:w-3/4 pt-1 px-2 mt-10">
                                            <ScrollArea style={{ height: modalHeight }} scrollbarSize={4}>
                                                <Accordion variant="contained">
                                                    {
                                                        jobDetails.designations && jobDetails.designations.map((designation, index)=>(
                                                            <Accordion.Item value={designation.designationName} key={designation.id}>
                                                                <Accordion.Control>
                                                                    <div>
                                                                        <Text>{designation.designationName}</Text>
                                                                        <Grid>
                                                                            <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                <Text size="sm" color="dimmed" weight={400}>{designation.description}</Text>
                                                                            </Grid.Col>
                                                                            <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                <Text size="sm" color="dimmed" weight={400}>
                                                                                    <div className="flex items-center">
                                                                                        <h4 className="flex-shrink-0 pr-4 text-base font-semibold text-indigo-600">{t('ScheduleInformation')}</h4>
                                                                                        <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                    </div>
                                                                                    <ul role="list"
                                                                                        className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                                        <li className="flex items-start lg:col-span-1">
                                                                                            <div className="flex-shrink-0">
                                                                                                <HiOutlineUserCircle size={20} className={`bg-indigo-500 text-white rounded-full`}/>
                                                                                            </div>
                                                                                            <p className="ml-3">{t('NoOfPerson')}: {designation.noOfPerson}</p>
                                                                                        </li>

                                                                                        <li className="flex items-start lg:col-span-1">
                                                                                            <div className="flex-shrink-0">
                                                                                                <HiOutlineClock size={20} className={`bg-indigo-500 text-white rounded-full`}/>
                                                                                            </div>
                                                                                            <p className="ml-3">{t('Start')}: {designation.startTime ? format(new Date(designation.startTime.date), 'dd/MM/Y hh:mm a') : ''}</p>
                                                                                        </li>

                                                                                        <li className="flex items-start lg:col-span-1">
                                                                                            <div className="flex-shrink-0">
                                                                                                <HiOutlineCurrencyDollar size={20} className={`bg-indigo-500 text-white rounded-full`}/>
                                                                                            </div>
                                                                                            <p className="ml-3">{t('Rate')}: {designation.rate ? '$'+designation.rate : ''}</p>
                                                                                        </li>

                                                                                        <li className="flex items-start lg:col-span-1">
                                                                                            <div className="flex-shrink-0">
                                                                                                <HiOutlineClock size={20} className={`bg-indigo-500 text-white rounded-full`}/>
                                                                                            </div>
                                                                                            <p className="ml-3">{t('End')}: {designation.endTime ? format(new Date(designation.endTime.date), 'dd/MM/Y hh:mm a') : ''}</p>
                                                                                        </li>
                                                                                    </ul>
                                                                                </Text>
                                                                            </Grid.Col>
                                                                        </Grid>
                                                                    </div>
                                                                </Accordion.Control>
                                                                <Accordion.Panel>
                                                                    {
                                                                        designation.candidates && (
                                                                            <Tab.Group
                                                                                // selectedIndex={selectedTabIndex}
                                                                                // onChange={setSelectedTabIndex}
                                                                            >
                                                                                <Tab.List className="flex bg-indigo-100 h-12">
                                                                                    {
                                                                                        Object.keys(designation.candidates).sort().map((flag, index) => (
                                                                                            <Tab as={Fragment} key={index}>
                                                                                                {({selected}) => (
                                                                                                    <button className={selected ? ' h-12 border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-700 text-indigo-700' : 'py-1.5 p-3.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'}>
                                                                                                        {t(flag)}
                                                                                                    </button>
                                                                                                )}
                                                                                            </Tab>
                                                                                        ))
                                                                                    }

                                                                                </Tab.List>
                                                                                <Tab.Panels>
                                                                                    {
                                                                                        Object.keys(designation.candidates).sort().map((flag, index) => (
                                                                                            <Tab.Panel key={index}>
                                                                                                <table className="min-w-full divide-y divide-gray-300">
                                                                                                    <thead className="bg-gray-50">
                                                                                                    <tr className="bg-indigo-50">
                                                                                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                                            {t('NameOfCandidate')}
                                                                                                        </th>
                                                                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                                                            {t('Email')}
                                                                                                        </th>
                                                                                                        {
                                                                                                            hasRoleModerator && (
                                                                                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                                                                    {t('HourRate')}
                                                                                                                </th>
                                                                                                            )
                                                                                                        }

                                                                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                                                            {t('CandidateAcceptance')}

                                                                                                        </th>
                                                                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">

                                                                                                            {t('ClientAcceptance')}

                                                                                                        </th>
                                                                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">

                                                                                                            {t('ModeratorAcceptance')}

                                                                                                        </th>
                                                                                                        <th></th>
                                                                                                        <th></th>
                                                                                                    </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                    {designation.candidates[flag] && designation.candidates[flag].length > 0 && designation.candidates[flag].map((candidate, index) => (
                                                                                                        <tr
                                                                                                            key={index}
                                                                                                            className="border-gray-200 border-t"
                                                                                                        >
                                                                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 w-[250px]">
                                                                                                                <select
                                                                                                                    className="w-full"
                                                                                                                    onChange={e =>{assignCandidate(candidate.id, Number(e.target.value), designation.id, designation.designationSlug, 'preferred')}}
                                                                                                                >
                                                                                                                    <option value="">Any One</option>
                                                                                                                    {
                                                                                                                        candidateList && candidateList[designation.designationSlug] && candidateList[designation.designationSlug].map((item, index)=>(
                                                                                                                            <option selected={candidate.userId === item.id} key={item.id} value={item.id}>{item.name}</option>
                                                                                                                        ))
                                                                                                                    }
                                                                                                                </select>
                                                                                                            </td>
                                                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{candidate.email}</td>
                                                                                                            {
                                                                                                                hasRoleModerator && (
                                                                                                                    <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">${candidate.rate}</td>
                                                                                                                )
                                                                                                            }
                                                                                                            <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{candidate.candidateConfirmationBy ? 'Yes' : 'No'}</td>
                                                                                                            <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                                {
                                                                                                                    <Switch
                                                                                                                        size="md"
                                                                                                                        checked={!!candidate.clientConfirmationBy}
                                                                                                                        color={'bg-indigo-800'}
                                                                                                                        onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                                                        offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                                        onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'client-acceptance')}
                                                                                                                        // onClick={(e)=> console.log(e.target.checked, loggedUserId, candidate.id, 'client-acceptance')}
                                                                                                                    />
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                                {
                                                                                                                    hasRoleModerator ? (
                                                                                                                        <>
                                                                                                                            <Switch
                                                                                                                                size="md"
                                                                                                                                checked={!!candidate.moderatorConfirmationBy}
                                                                                                                                color={'bg-indigo-800'}
                                                                                                                                onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                                                                offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                                                onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'moderator-acceptance')}
                                                                                                                            />
                                                                                                                            {candidate.moderatorName ? candidate.moderatorName : ''}
                                                                                                                        </>

                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            {candidate.moderatorConfirmationBy ? 'Yes' : 'No'}
                                                                                                                            <br/>
                                                                                                                            {candidate.moderatorName ? candidate.moderatorName : ''}

                                                                                                                        </>
                                                                                                                    )

                                                                                                                }

                                                                                                            </td>
                                                                                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                                                                                <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                                                                                </a>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    ))}
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </Tab.Panel>
                                                                                        ))
                                                                                    }

                                                                                </Tab.Panels>
                                                                            </Tab.Group>
                                                                        )
                                                                    }
                                                                </Accordion.Panel>

                                                                {/*<Accordion.Panel>Colors, fonts, shadows and many other parts are customizable to fit your design needs</Accordion.Panel>*/}
                                                            </Accordion.Item>
                                                        ))
                                                    }
                                                </Accordion>





                                                {/*{
                                                    jobDetails.designations && jobDetails.designations.map((designation, index)=>(
                                                        <Fragment key={designation.id}>
                                                            <div className="bg-gray-100">
                                                                <div className="mt-2 bg-white">
                                                                    <div className="relative">
                                                                        <div className="relative mx-auto max-w-7xl px-2 lg:px-2">
                                                                            <div
                                                                                className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none flex-row">
                                                                                <div className="flex-1 bg-white px-6 py-2 lg:p-6 basis-2/3">
                                                                                    <h3 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">{designation.designationName}</h3>
                                                                                    <p className="mt-3 text-base text-gray-500">{designation.description}</p>
                                                                                    <div className="mt-4">
                                                                                        <div className="flex items-center">
                                                                                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('ScheduleInformation')}</h4>
                                                                                            <div className="flex-1 border-t-2 border-gray-200"/>
                                                                                        </div>
                                                                                        <ul role="list"
                                                                                            className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <div className="flex-shrink-0">
                                                                                                    <HiOutlineUserCircle size={20} className={`bg-indigo-500 text-white rounded-full`}/>
                                                                                                </div>
                                                                                                <p className="ml-3 text-sm text-gray-700">{t('NoOfPerson')}: {designation.noOfPerson}</p>
                                                                                            </li>

                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <div className="flex-shrink-0">
                                                                                                    <HiOutlineClock size={20} className={`bg-indigo-500 text-white rounded-full`}/>
                                                                                                </div>
                                                                                                <p className="ml-3 text-sm text-gray-700">{t('Start')}: {designation.startTime ? format(new Date(designation.startTime.date), 'dd/MM/Y hh:mm a') : ''}</p>
                                                                                            </li>

                                                                                            <li className="flex items-start lg:col-span-1">

                                                                                            </li>

                                                                                            <li className="flex items-start lg:col-span-1">
                                                                                                <div className="flex-shrink-0">
                                                                                                    <HiOutlineClock size={20} className={`bg-indigo-500 text-white rounded-full`}/>
                                                                                                </div>
                                                                                                <p className="ml-3 text-sm text-gray-700">{t('End')}: {designation.endTime ? format(new Date(designation.endTime.date), 'dd/MM/Y hh:mm a') : ''}</p>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="bg-gray-50 py-8 px-6 text-center lg:flex lg:flex-shrink-0 lg:flex-col lg:justify-center lg:p-12 basis-1/3">
                                                                                    <div
                                                                                        className="mt-4 flex items-center justify-center text-5xl font-bold tracking-tight text-gray-900">
                                                                                        <span>{designation.rate ? '$'+designation.rate : ''}</span>
                                                                                        { designation.rate && <span className="ml-3 text-xl font-medium tracking-normal text-gray-500">USD</span>}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    ))
                                                }*/}
                                            </ScrollArea>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
        </>
    );
}
