import React, {useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiEye,
    HiOutlineChartSquareBar, HiOutlineExclamation,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiPencil, HiTrash
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Group, Text,Box,ScrollArea} from "@mantine/core";
import {FiAirplay, FiSave, FiX} from "react-icons/fi";
import {TbCurrencyTaka} from "react-icons/tb";
import {MdOutlineMonitorWeight} from "react-icons/md";
import {Switch} from "@headlessui/react";
import {AiOutlineLoading3Quarters, AiOutlineMinus} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import container from "postcss/lib/container";


const PAGE_SIZE = 1;

function CompanyProfile() {

    const {t, i18n} = useTranslation();
    // const innerContainer = useRef(100);
    // const height = innerContainer.current.clientHeight;
    // var tableHeight = (height-63);
    // var fromHeight = height-153+"px";

    const tableHeight = '';
    const fromHeight = '';
    const innerContainer = useRef(100);



    const [items, setItemTypes] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isAddForm, setAddForm] = useState(true);

    //Add Model Init
    let [isOpen, setIsOpen] = useState(false);

    //Submit Spinner Init
    const [spinner, SetSpinner] = useState(false);

    //Add Model close function
    function closeModal() {
        setIsOpen(false)
    }

    //Add Model Open function
    function openModal() {
        setIsOpen(true)
    }

    const [page, setPage] = useState(1);
    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setItemTypes(items.slice(from, to));
    }, [page]);

    //Form validation
    const validationSchema = Yup.object().shape({
        companyName: Yup.string().required(t("CompanyNameIsRequired")),
        companyType: Yup.string().required(t("SelectCompanyType")),
        country: Yup.string().required(t('SelectCountry')),
        address : Yup.string().required(t('AddressIsRequired')),
        mobile : Yup.string().required(t('MobileIsRequired')),
        bankName: Yup.string().required(t("BankNameIsRequired")),
        branchName: Yup.string().required(t("BranchNameIsRequired")),
        accountName : Yup.string().required(t("AccountNameIsRequired")),
        accountNumber : Yup.string().required(t("AccountNumberIsRequired"))

       
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const [noOfRows, setNoOfRows] = useState(1);
    const [status, setStatus] = useState(true);


    //Form Data Submit
    function formSubmit(data) {
        // SetSpinner(true);
         console.log(data)
        const formData = {
            companyName: data.name,
            country : data.name,
            companyType: data.name,
            mobile: data.name,
            status:1
        };

        
    }

    return(
        <>
        
        {/*<main className="flex flex-1 " ref={innerContainer}>*/}
        <main className="flex flex-1">
            <div className="min-h-full w-full">
                <div className="flex w-full md:w-auto">
                    <div className="bg-red-100 flex-1">
                        <div className="min-w-0 flex-1 bg-white xl:flex">
                          <div className="bg-white lg:min-w-0 lg:flex-1">
                             <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                              <div className="relative">
                                <div className="w-full flex -ml-4 pl-4 h-12 bg-indigo-50 justify-start text-gray-600 mb-3">
                                 <div className={"flex-1"}>
                                   <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                                      <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                        <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                            {t('CompanyProfile')}
                                        <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('CompanySettingsAndRelatedInformation')}</sub></span>
                                            </div>
                                                </div>
                                                   
                                                    </div>
                                                    <div className={'flex flex-1 overflow-hidden w-full'}>
                                                        

                                            {isAddForm ?
                                            <div className="w-full mr-0 mx-auto border">
                                                <header className="relative mb-1 bg-indigo-600">
                                                        <div
                                                            className="text-gray-400  text-right sm:px-2 sm:py-1">
                                                            <div
                                                                className="text-center flex justify-center">
                                                                <div className="text-white   text-md  text-center font-bold items-center leading-7">  {t('CompanySettings')}</div>
                                                                <br/>
                                                            </div>
                                                        </div>
                                                    </header>

                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form" >
                                                    {/*<div  className= "px-8 py-3 relative flex flex-col min-w-0 break-words w-full bg-gray-200 border-0 inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full" style={{height:fromHeight}} > */}
                                                    <div  className= "px-8 py-3 relative flex flex-col min-w-0 break-words w-full bg-gray-200 border-0 inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                                                    <div>
                                                        <div className="md:grid md:grid-cols-3 md:gap-6">
                                                        <div className="md:col-span-1">
                                                            <div className="px-4 sm:px-0">
                                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("BasicInformation")}</h3>
                                                            <p className="mt-1 text-sm text-gray-600">
                                                                {t('EnterCompanysBasicDetails')}
                                                            </p>
                                                            </div>
                                                        </div>
                                                         {/* Company Information Form Start */}
                                                        <div className="mt-5 md:col-span-2 md:mt-0">
                                                            <div className="shadow sm:overflow-hidden sm:rounded-md">
                                                                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">

                                                                    {/* Company Name section */} 

                                                                    <div
                                                                        className="mb-3 w-full items-center">
                                                                        <label htmlFor="companyName"
                                                                                className="form-input-sm-label-required">{t('CompanyName')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.companyName?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.companyName?.message}
                                                                            </div>}
                                                                            <div
                                                                                className="form-input-sm-right-icon">
                                                                                <FiAirplay
                                                                                    size={16}></FiAirplay>
                                                                            </div>
                                                                            <input
                                                                                {...register("companyName")}
                                                                                type="text"
                                                                                className={`form-input-sm-control ${errors.companyName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                name="companyName"
                                                                                id ="companyName"
                                                                                placeholder={t("EnterCompanyName")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {/* Company Type Section */}
                                                                    <div
                                                                        className="mb-3 w-full items-center">
                                                                        <label htmlFor="companyType"
                                                                                className="form-input-sm-label-required">{t('CompanyType')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.companyType?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.companyType?.message}
                                                                            </div>}
                                                                            <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                                    
                                                                                    <select
                                                                                        {...register("companyType")}
                                                                                        name="companyType"
                                                                                        id ="companyType"
                                                                                        className={`form-input-sm-control ${errors.companyType ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} ` }>
                                                                                        <option value="">{t('SelectCompanyType')}</option>
                                                                                        <option value="1">Private Company</option>
                                                                                        <option value="2">Government Company</option>
                                                                                        <option value="3">LLC</option>
                                                                                    </select>
                                                                                    
                                                                                </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* Mobile and Email Section*/}
                
                                                                   <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                   <div
                                                                        className="mb-3 w-1/3 items-center">
                                                                        <label htmlFor="mobile"
                                                                                className="form-input-sm-label-required">{t('Mobile')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.mobile?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.mobile?.message}
                                                                            </div>}
                                                                            
                                                                            <input
                                                                                {...register("mobile")}
                                                                                type="text"
                                                                                className={`form-input-sm-control ${errors.mobile ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                name="mobile"
                                                                                id = "mobile"
                                                                                placeholder={t("EnterMobileNumber")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className="mb-3 w-1/3 ml-12 items-center">
                                                                        <label htmlFor="email"
                                                                                className="form-input-sm-label-required">{t('Email')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0"> 
                                                                            {errors.email?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.email?.message}
                                                                            </div>}
                                                                            <input
                                                                                {...register("email")}
                                                                                type="text"
                                                                                className={`form-input-sm-control ${errors.email ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                name="email"
                                                                                id = "email"
                                                                                placeholder={t("EnterEmailAddress")}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    </div>

                                                                    {/* Country Section */}
                                                                    <div
                                                                        className="mb-3 w-full items-center">
                                                                        <label htmlFor="country"
                                                                                className="form-input-sm-label-required">{t('Country')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.country?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.country?.message}
                                                                            </div>}
                                                                            <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                                    
                                                                                    <select
                                                                                        {...register("country")}
                                                                                        name="country"
                                                                                        id ="country"
                                                                                        className={`form-input-sm-control ${errors.country ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} ` }>
                                                                                        <option value="">{t('SelectCountry')}</option>
                                                                                        <option value="1">Australia</option>
                                                                                        <option value="2">Bangladesh</option>
                                                                                        <option value="3">Canada</option>
                                                                                        <option value="3">United States of America</option>
                                                                                        <option value="3">United Kingdom</option>
                                                                                        <option value="3">Sweden</option>
                                                                                    </select>
                                                                                    
                                                                                </div>
                                                                        </div>
                                                                    </div>


                                                                    {/* Address and Street Address Section */}
                                                                    <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                    <div
                                                                        className="mb-3 w-1/3 items-center">
                                                                        <label htmlFor="address"
                                                                                className="form-input-sm-label-required">{t('Address')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.address?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.address?.message}
                                                                            </div>}
                                                                            
                                                                            <textarea
                                                                                {...register("address")}
                                                                                type="text"
                                                                                className={`form-input-sm-control ${errors.address ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                name="address"
                                                                                id = "address"
                                                                                placeholder={t("EnterAddress")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className="mb-3 w-1/3 ml-12 items-center">
                                                                        <label htmlFor="streetAddress"
                                                                                className="form-input-sm-label">{t('StreetAddress')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0"> 
                                                                            <textarea
                                                                                {...register("streetAddress")}
                                                                                type="text"
                                                                                className={`form-input-sm-control `}
                                                                                name="streetAddress"
                                                                                id = "streetAddress"
                                                                                placeholder={t("EnterStreetAddress")}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    </div>

                                                                    {/* City, State and Zip Code Section */}
                                                                   
                                                                   <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                   <div
                                                                        className="mb-3 w-1/4 items-center">
                                                                        <label htmlFor="city"
                                                                                className="form-input-sm-label">{t('City')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">                                                                            
                                                                            <input
                                                                                {...register("city")}
                                                                                type="text"
                                                                                className={`form-input-sm-control`}
                                                                                name="city"
                                                                                id = "city"
                                                                                placeholder={t("EnterCity")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className="mb-3 w-1/4 ml-12 items-center">
                                                                        <label htmlFor="state"
                                                                                className="form-input-sm-label">{t('State')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">                                                                            
                                                                            <input
                                                                                {...register("state")}
                                                                                type="text"
                                                                                className={`form-input-sm-control`}
                                                                                name="state"
                                                                                id = "state"
                                                                                placeholder={t("EnterState")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className="mb-3 w-1/4 ml-12 items-center">
                                                                        <label htmlFor="zipCode"
                                                                                className="form-input-sm-label">{t('ZipCode')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0"> 
                                                                            <input
                                                                                {...register("zipCode")}
                                                                                type="text"
                                                                                className={`form-input-sm-control`}
                                                                                name="zipCode"
                                                                                id = "zipCode"
                                                                                placeholder={t("EnterZipCode")}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    </div>

                                                                        {/* SSN Section */}
                                                                        <div
                                                                        className="mb-3 w-full items-center">
                                                                        <label htmlFor="ssn"
                                                                                className="form-input-sm-label">{t('SSN')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            <input
                                                                                {...register("ssn")}
                                                                                type="text"
                                                                                className={`form-input-sm-control `}
                                                                                name="ssn"
                                                                                id ="ssn"
                                                                                placeholder={t("SocialSecurityNumber")}
                                                                            />
                                                                        </div>
                                                                    </div>


                                                                        {/* Description Section */}
                                                                        <div
                                                                        className="mb-3 w-full items-center">
                                                                        <label htmlFor="description"
                                                                                className="form-input-sm-label">{t('Description')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            <textarea
                                                                                {...register("description")}
                                                                                type="text"
                                                                                className={`form-input-sm-control`}
                                                                                name="description"
                                                                                placeholder={t("")}
                                                                            />
                                                                        </div>
                                                                    </div>                                                                    

                                                                        {/* Company Website Section */}
                                                                        <div
                                                                        className="mb-3 w-full items-center">
                                                                        <label htmlFor="companyWebsite"
                                                                                className="form-input-sm-label">{t('CompanyWebsite')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            <input
                                                                                {...register("companyWebsite")}
                                                                                type="url"
                                                                                className={`form-input-sm-control `}
                                                                                name="companyWebsite"
                                                                                id ="companyWebsite"
                                                                                placeholder={t("EnterURL")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {/* Company Founding Date and Employee Size Section*/}
                
                                                                   <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                   <div
                                                                        className="mb-3 w-1/3 items-center">
                                                                        <label htmlFor="companyFoundingDate"
                                                                                className="form-input-sm-label">{t('CompanyFoundingDate')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            
                                                                            
                                                                            <input
                                                                                {...register("companyFoundingDate")}
                                                                                type="date"
                                                                                className={`form-input-sm-control`}
                                                                                name="companyFoundingDate"
                                                                                id = "companyFoundingDate"
                                                                                placeholder={t("")}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="mb-3 w-1/3 ml-12 items-center">
                                                                        <label htmlFor="employeeSize"
                                                                                className="form-input-sm-label">{t('EmployeeSize')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">                                                                                     
                                                                            
                                                                            <select
                                                                                        {...register("country")}
                                                                                        name="country"
                                                                                        id ="country"
                                                                                        className={`form-input-sm-control` }>
                                                                                        <option value="">{t('SelectEmployeeSize')}</option>
                                                                                        <option value="1">1-10</option>
                                                                                        <option value="2">11-50</option>
                                                                                        <option value="3">51-100</option>
                                                                                        <option value="3">101-200</option>
                                                                                        <option value="3">200+</option>
                                                                                    </select>
                                                                               
                                                                        </div>
                                                                    </div>
                                                                    </div>

                                                                    {/* Company Contractual Person */}

                                                                    <div
                                                                        className="mb-3 w-full items-center">
                                                                        <label htmlFor="companyContractualPerson"
                                                                                className="form-input-sm-label">{t('CompanyContractualPerson')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                            {[...Array(noOfRows)].map((elementInArray, index) => {
                                                                            
                                                                            return (
                                                                                <tr>
                                                                                <th scope="row">{index}</th>
                                                                                <td>
                                                                                <input
                                                                                {...register("companyContractualPerson")}
                                                                                type="text"
                                                                                className={`form-input-sm-control`}
                                                                                name="companyContractualPerson" 
                                                                                id="companyContractualPerson"
                                                                                placeholder={t("EnterContractualPerson")}
                                                                                 />
                                                                                </td>

                                                                            </tr>
                                                                                );
                                                                            })}
                                                                            <button type="button" class="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs" onClick={() => setNoOfRows(noOfRows + 1)}>
                                                                                    <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('Add')}
                                                                                    </button>
                                                                                    <button type="button" class="inline-flex items-center px-2 py-1 bg-red-500 hover:bg-red-200 text-white text-xs" onClick={() => setNoOfRows(noOfRows - 1)}>
                                                                                    <AiOutlineMinus size={12} className={'mr-1'}></AiOutlineMinus>{t('Delete')}
                                                                                        </button>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        </div>
                                                    </div>

                                                    <div className="hidden sm:block" aria-hidden="true">
                                                        <div className="py-5">
                                                        <div className="border-t border-gray-200" />
                                                        </div>
                                                    </div>

                                                    {/* Images Section */}
                                                    <div className="mt-10 sm:mt-0">
                                                        <div className="md:grid md:grid-cols-3 md:gap-6">
                                                        <div className="md:col-span-1">
                                                            <div className="px-4 sm:px-0">
                                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t('Images')}</h3>
                                                            <p className="mt-1 text-sm text-gray-600">{t('UploadRelevantImagesHere')}</p>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="mt-5 md:col-span-2 md:mt-0">
                                                            <div className="shadow sm:overflow-hidden sm:rounded-md">
                                                                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                                                              
                                                                    {/* Logo Section */}      
                                                                    <div
                                                                        className="mb-3 w-2/3 items-center">
                                                                        <label htmlFor="logo"
                                                                                className="form-input-sm-label">{t('Logo')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            <input
                                                                                {...register("logo")}
                                                                                type="file"
                                                                                className={`form-input-sm-control `}
                                                                                name="logo"
                                                                                id ="logo"
                                                                                placeholder={t("")}
                                                                            />
                                                                        </div>
                                                                    </div>
                                    
                                                                    {/* Banner Section */}      
                                                                    <div
                                                                        className="mb-3 w-2/3 items-center">
                                                                        <label htmlFor="banner"
                                                                                className="form-input-sm-label">{t('Banner')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            <input
                                                                                {...register("banner")}
                                                                                type="file"
                                                                                className={`form-input-sm-control `}
                                                                                name="banner"
                                                                                id ="banner"
                                                                                placeholder={t("")}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                   
                                                                        {/* Company Header/Footer Section */}      
                                                                        <div
                                                                        className="mb-3 w-2/3 items-center">
                                                                        <label htmlFor="companyHeaderFooter"
                                                                                className="form-input-sm-label">{t('CompanyHeader/Footer')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            <input
                                                                                {...register("companyHeaderFooter")}
                                                                                type="file"
                                                                                className={`form-input-sm-control `}
                                                                                name="companyHeaderFooter"
                                                                                id ="companyHeaderFooter"
                                                                                placeholder={t("")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                             
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        </div>
                                                    </div>

                                                    <div className="hidden sm:block" aria-hidden="true">
                                                        <div className="py-5">
                                                        <div className="border-t border-gray-200" />
                                                        </div>
                                                    </div>

                                                    <div className="mt-10 sm:mt-0">
                                                        <div className="md:grid md:grid-cols-3 md:gap-6">
                                                        <div className="md:col-span-1">

                                                            {/* Social Media Section */}
                                                            <div className="px-4 sm:px-0">
                                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t('SocialMedia')}</h3>
                                                            <p className="mt-1 text-sm text-gray-600">{t('CompanysSocialMediaInformation')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="mt-5 md:col-span-2 md:mt-0">
                                                            
                                                            <div className="overflow-hidden shadow sm:rounded-md">
                                                                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                                                                 
                                                                 {/* Facebook section */} 

                                                                 <div
                                                                        className="mb-3 w-2/3 items-center">
                                                                        <label htmlFor="facebook"
                                                                                className="form-input-sm-label">{t('Facebook')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                                                                                                                                  <input
                                                                                {...register("username")}
                                                                                type="text"
                                                                                className={`form-input-sm-control`}
                                                                                name="facebook"
                                                                                id ="facebook"
                                                                                placeholder={t("EnterURL")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                {/* Twitter Section */}

                                                                <div
                                                                        className="mb-3 w-2/3 items-center">
                                                                        <label htmlFor="twitter"
                                                                                className="form-input-sm-label">{t('Twitter')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                                                                                                                                  <input
                                                                                {...register("twitter")}
                                                                                type="text"
                                                                                className={`form-input-sm-control`}
                                                                                name="twitter"
                                                                                id ="twitter"
                                                                                placeholder={t("EnterURL")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                        {/* Linkedin Section */}

                                                                <div
                                                                        className="mb-3 w-2/3 items-center">
                                                                        <label htmlFor="linkedin"
                                                                                className="form-input-sm-label">{t('Linkedin')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                                                                                                                                  <input
                                                                                {...register("inkedin")}
                                                                                type="text"
                                                                                className={`form-input-sm-control`}
                                                                                name="linkedin"
                                                                                id ="linkedin"
                                                                                placeholder={t("EnterURL")}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                
                                                                </div>
                                                              
                                                            </div>

                                                            
                                                        </div>
                                                        </div>
                                                    </div>

                                                    <div className="hidden sm:block" aria-hidden="true">
                                                        <div className="py-5">
                                                        <div className="border-t border-gray-200" />
                                                        </div>
                                                    </div>

                                                    <div className="mt-10 sm:mt-0">
                                                        <div className="md:grid md:grid-cols-3 md:gap-6">
                                                        <div className="md:col-span-1">

                                                            {/* Billing Information Section */}
                                                            <div className="px-4 sm:px-0">
                                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t('BillingInformation')}</h3>
                                                            <p className="mt-1 text-sm text-gray-600">{t('CompanysBankRelatedInformation')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="mt-5 md:col-span-2 md:mt-0">
                                                            
                                                            <div className="overflow-hidden shadow sm:rounded-md">
                                                                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                                                                 
                                                                 {/* Bank Name section */} 
                                                                 <div
                                                                        className="mb-3 w-full items-center">
                                                                        <label htmlFor="bankName"
                                                                                className="form-input-sm-label-required">{t('BankName')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.bankName?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.bankName?.message}
                                                                            </div>}
                                                                         
                                                                            <input
                                                                                {...register("bankName")}
                                                                                type="text"
                                                                                className={`form-input-sm-control ${errors.bankName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                name="bankName"
                                                                                id ="bankName"
                                                                                placeholder={t("EnterBankName")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                {/* Branch Section */}
                                                                <div
                                                                        className="mb-3 w-full items-center">
                                                                        <label htmlFor="branchName"
                                                                                className="form-input-sm-label-required">{t('BranchName')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.branchName?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.branchName?.message}
                                                                            </div>}

                                                                            <input
                                                                                {...register("branchName")}
                                                                                type="text"
                                                                                className={`form-input-sm-control ${errors.branchName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                name="branchName"
                                                                                id ="branchName"
                                                                                placeholder={t("EnterBranchName")}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                               

                                                                {/* Account Name Section */}
                                                                <div
                                                                        className="mb-3 w-full items-center">
                                                                        <label htmlFor="accountName"
                                                                                className="form-input-sm-label-required">{t('AccountName')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.accountName?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.accountName?.message}
                                                                            </div>}
                                                                            <input
                                                                                {...register("accountName")}
                                                                                type="text"
                                                                                className={`form-input-sm-control ${errors.accountName ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                name="accountName"
                                                                                id ="accountName"
                                                                                placeholder={t("EnterAccountName")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                {/* Account Number Section */}


                                                                <div
                                                                        className="mb-3 w-full items-center">
                                                                        <label htmlFor="accountNumber"
                                                                                className="form-input-sm-label-required">{t('AccountNumber')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.accountNumber?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.accountNumber?.message}
                                                                            </div>}
                                                                      
                                                                            <input
                                                                                {...register("accountNumber")}
                                                                                type="text"
                                                                                className={`form-input-sm-control ${errors.accountNumber ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                name="accountNumber"
                                                                                id ="accountNumber"
                                                                                placeholder={t("EnterAccountNumber")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                
                                                                
                                                                </div>
                                                              
                                                            </div>

                                                            
                                                        </div>
                                                        </div>
                                                    </div>

                                                    <div className="hidden sm:block" aria-hidden="true">
                                                        <div className="py-5">
                                                        <div className="border-t border-gray-200" />
                                                        </div>
                                                    </div>

                                                    <div className="mt-10 sm:mt-0">
                                                        <div className="md:grid md:grid-cols-3 md:gap-6">
                                                        <div className="md:col-span-1">

                                                            {/* Theme Section */}
                                                            <div className="px-4 sm:px-0">
                                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t('Theme')}</h3>
                                                            <p className="mt-1 text-sm text-gray-600">{t('SetThemeAccordingYourChoice')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="mt-5 md:col-span-2 md:mt-0">
                                                            
                                                            <div className="overflow-hidden shadow sm:rounded-md">
                                                                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                                                                 
                                                                 {/* Select Theme section */} 

                                                                 <div
                                                                        className="mb-3 w-2/3 items-center">
                                                                        <label htmlFor="theme"
                                                                                className="form-input-sm-label">{t('Theme')}</label>

<div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">                                                                                     
                                                                            
                                                                            <select
                                                                                        {...register("theme")}
                                                                                        name="theme"
                                                                                        id ="theme"
                                                                                        className={`form-input-sm-control` }>
                                                                                        <option value="">{t('SelectTheme')}</option>
                                                                                        <option value="1">Light</option>
                                                                                        <option value="2">Dark</option>
                                                                                        <option value="3">Default</option>
                                                                                    </select>
                                                                               
                                                                        </div>
                                                                    </div>

                                                                {/* Customize Theme Section */}

                                                                <div
                                                                        className="mb-3 w-2/3 items-center">
                                                                        <label htmlFor="customizeTheme"
                                                                                className="form-input-sm-label">{t('CustomizeTheme')}</label>

                                                                            
                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">                                                                                     
                                                                            
                                                                            <select
                                                                                        {...register("customizeTheme")}
                                                                                        name="customizeTheme"
                                                                                        id ="customizeTheme"
                                                                                        className={`form-input-sm-control` }>
                                                                                        <option value="">{t('SelectCustomTheme')}</option>
                                                                                        <option value="1">Free Trial</option>
                                                                                        <option value="2">Paid</option>
                                                                                        <option value="3">Others</option>
                                                                                    </select>
                                                                               
                                                                        </div>
                                                                        
                                                                    </div>

                                                                        {/* Custom Print Section */}

                                                                <div
                                                                        className="mb-3 w-2/3 items-center">
                                                                        <label htmlFor="customPrint"
                                                                                className="form-input-sm-label">{t('CustomPrint')}</label>

                                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">                                                                                     
                                                                            
                                                                            <select
                                                                                        {...register("customPrint")}
                                                                                        name="customPrint"
                                                                                        id ="customPrint"
                                                                                        className={`form-input-sm-control` }>
                                                                                        <option value="">{t('SelectCustomPrint')}</option>
                                                                                        <option value="1">Free Trial</option>
                                                                                        <option value="2">Paid</option>
                                                                                        <option value="3">Others</option>
                                                                                    </select>
                                                                               
                                                                        </div>
                                                                       
                                                                    </div>
                                                                
                                                                </div>
                                                              
                                                            </div>

                                                            
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
 
                                                    <footer className="relative mt-1">
                                                                                    <div className="container mx-auto justify-items-center">
                                                                                        <div
                                                                                            className="text-right border-t pt-0.5 mb-0.5">
                                                                                            <button onClick={event => setAddForm(prevCheck => !prevCheck)} type='reset'
                                                                                                    className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                                    ><FiX
                                                                                                size={16}
                                                                                                className={'mr-2'}></FiX>
                                                                                                <span>{t('Cancel')}</span>
                                                                                            </button>
                                                                                            <button type='submit'
                                                                                                    className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                                {spinner ? (
                                                                                                    <AiOutlineLoading3Quarters
                                                                                                        className={'mr-2 animate-spin'}
                                                                                                        size={16}></AiOutlineLoading3Quarters>
                                                                                                ) : (<FiSave size={16}
                                                                                                            className={'mr-2'}></FiSave>)}
                                                                                                <span>{t('Save')}</span>
                                                                                            </button>

                                                                                        </div>
                                                                                    </div>
                                                                                </footer>
                                                    </form>
                                                    </div>
                                                                                                                        
                                                        : ''}


                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </main>
        
        </>
    )
}

export default CompanyProfile