import React, {Fragment, useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiOutlineExclamation,
    HiOutlineOfficeBuilding,
    HiOutlinePlus,
    HiPencil,
    HiOutlineUserGroup,
    HiOutlineX, HiLocationMarker, HiCalendar, HiTrash, HiOutlineCheck
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {FiSave, FiX, FiArrowLeft} from "react-icons/fi";
import {AiOutlineLoading3Quarters, AiOutlineMinus,AiOutlineFileSearch} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import {useParams, useNavigate} from "react-router";
import {format} from 'date-fns';
import {ActionIcon, Select, Switch, Group, Tooltip, Text} from "@mantine/core";
import CandidatePreferableConfirmation from "./modal/CandidatePreferableConfirmation";
import AddJobCandidate from "./modal/AddJobCandidate";
import ViewJob from "./modal/ViewJob";
import {
    HiEye,
} from "react-icons/hi";
import {showNotification} from "@mantine/notifications";
import {Tab} from "@headlessui/react";


export default function AddPreferableCandidate({jobDetails, recall, setRecall, updateJobDetails}) {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const [spinner, setSpinner] = useState(false);
    const {roles, token, loggedUserId} = JSON.parse(localStorage.getItem('user'));
    const {jobId} = useParams();

    const navigate = useNavigate();

    const {t, i18n} = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [showAdditionalCandidateModal, setShowAdditionalCandidateModal] = useState(false);

    const [requiredDesignationId, setRequiredDesignationId] = useState();
    const [designation, setDesignation] = useState({});
    const [candidateList, setCandidateList] = useState({});
    const [candidates, setCandidates] = useState([]);

    const [enabled, setEnabled] = useState(false)



    const designationWiseCandidate = (list) => {
/*
        setRequiredDesignationId(reqDesignationAutoId);

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/designation-wise-candidate-list`, this api structure is updated for match candidate invitation
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "designationId": designationId
            }
        })
            .then(res => {
                // setCandidateList(res.data.data)
                console.log(res.data.data)
                // setShowModal(true)

            });*/
    }


    const removeCandidate = (candidate) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-assign-candidate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: candidate
        })
            .then(res => {
                if (res.data.status === 404){
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Exist') + ' !'),
                        message: (t('NotFound')),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });
                }else if (res.data.status === 200){
                    setRecall(!recall)
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Success') + ' !'),
                        message: (t('Removed')),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'green',
                    });
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }


    const assignCandidate = (rowId, candidateId, designationId, designationSlug, status) => {
        // console.log({
        //     rowId,
        //     candidateId,
        //     designationId,
        //     designationSlug,
        //     status
        // })
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-assign-candidate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                rowId,
                candidateId,
                designationId,
                designationSlug,
                process: status,
                loggedUserId,
                jobId
            }
        })
            .then(res => {
                if (409 === res.data.status){
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (res.data.message),
                        message: ('Error'),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });
                }
                if (202 === res.data.status){
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (res.data.message),
                        message: ('Success'),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'green',
                    });
                }
                setRecall(! recall);
            })
            .catch(function (error) {
                console.log(error)
            })
    }


    const designationWiseAllCandidateByJob = (jobId) => {
        // designation-wise-all-candidate
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/designation-wise-all-candidate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "jobId": Number(jobId)
                // "jobId": 990
            }
        })
            .then(res => {
                setCandidateList(res.data.data);


            });
    }

    const [jobStatusList, setJobStatusList] = useState([]);
    const getJobStatusList = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/master-data`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "type_slug": 'job-status'
            }
        })
            .then(res => {
                setJobStatusList(res.data.data);
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    useEffect(()=>{
        designationWiseAllCandidateByJob(jobId);
        getJobStatusList();
    },[])

    let fullFormHeight = localStorage.getItem('fullFormHeight');
    fullFormHeight = fullFormHeight.slice(0, -2);
    fullFormHeight = fullFormHeight - 77;
    fullFormHeight = fullFormHeight + "px";

    const [details, setDetails] = useState({});
    const jobView = (jobId) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/show-job`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                loggedUserId
            }
        })
            .then(res => {
                if (res.status === 200){
                    setDetails(res.data.data)
                    setShowModal(!!details)
                }
            });
    }

    const assignedCandidateAcceptance = (status, userId, rowId, acceptance) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-assigned-candidate-acceptance`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                status,
                userId,
                rowId,
                acceptance
            }
        })
            .then(res => {
                if (res.data.status === 202){
                    setRecall(!recall)
                }
            });
    }

    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })

    return (
        <>
            <div className="relative">
                <div className={'flex flex-1 overflow-hidden w-full'}>
                    <div className="border-blueGray-200 text-left relative flex flex-col min-w-0 break-words w-full border-0 inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full"  style={{height: fullFormHeight}} >
                        <div className="w-full mr-0 mx-auto border">
                            <div className="text-center">
                                <h3 className="text-4xl font-semibold leading-normal text-blueGray-700 mb-2">{jobDetails.title}</h3>
                                <div className="bg-gray-50 flex flex-col justify-center relative overflow-hidden">
                                    <div className="max-w-7xl mx-auto">
                                        <div className="relative group">
                                            <div className="absolute -inset-1 bg-gradient-to-r blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"/>
                                            <div className="relative px-7 py-2 bg-white ring-gray-900/5 leading-none flex items-top justify-start space-x-6">
                                                <HiLocationMarker  size={24} className={"mr-1"}/>
                                                <div className="space-y-2 mt-1">
                                                    <p className="text-slate-800 font-bold uppercase">{jobDetails.city ? jobDetails.city.split('-')[1] : ''}, {jobDetails.country ? jobDetails.country.split('-')[1] : ''}</p>
                                                    {/*<p className="text-slate-800">Los Angeles, California</p>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center relative overflow-hidden">
                                    <div className="max-w-7xl mx-auto">
                                        <div className="relative group">
                                            <div className="absolute"/>
                                            <div className="relative px-7 py-2 bg-white  leading-none flex items-top justify-start space-x-6">
                                                <HiCalendar size={18}/>
                                                <div className="space-y-2 mt-1">
                                                    <p className="text-slate-800">{t('Start&EndDate')}: {jobDetails.startTime ? format(new Date(jobDetails.startTime.date), 'dd, MMMM Y') : ''} to  {jobDetails.endTime ? format(new Date(jobDetails.endTime.date), 'dd, MMMM Y') : ''} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center relative overflow-hidden">
                                    <div className="max-w-7xl mx-auto">
                                        <div className="relative group">
                                            <div className="relative px-7 py-2 bg-white  leading-none flex items-top justify-start space-x-6">
                                                <div className="space-y-2 mt-1">
                                                    <p className="text-slate-800">{t('NoOfVacancy')} - {jobDetails.vacancy ? jobDetails.vacancy : ''}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="py-2 border-t border-blueGray-200 text-left">
                                <div className="flex flex-wrap">
                                    <div className="w-full px-2">
                                        <p className="text-slate-800">{jobDetails.description ? jobDetails.description : ''}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="" >
                                <div className="flex-wrap">
                                    <div className="px-4 sm:px-6 lg:px-8">
                                        <div className="flex-col">
                                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                <div className="inline-block min-w-full py-2 align-middle">
                                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 ">
                                                        <Tab.Group
                                                            // selectedIndex={selectedTabIndex}
                                                            // onChange={setSelectedTabIndex}
                                                        >
                                                            <Tab.List className="flex bg-indigo-100 h-12">
                                                                {
                                                                    jobDetails.designations && jobDetails.designations.map((designation, index) => (
                                                                        <Tab as={Fragment}>
                                                                            {({selected}) => (
                                                                                <button className={selected ? ' h-12 border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-700 text-indigo-700' : 'py-1.5 p-3.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'}>
                                                                                    {designation.designationName}
                                                                                </button>
                                                                            )}
                                                                        </Tab>
                                                                    ))
                                                                }
                                                            </Tab.List>
                                                            <Tab.Panels>
                                                                {
                                                                    jobDetails.designations && jobDetails.designations.map((designation, index) => (
                                                                        <Tab.Panel>
                                                                            <table className="min-w-full">
                                                                                <thead className="bg-white">
                                                                                <tr className="border-t border-gray-200">
                                                                                    <th
                                                                                        colSpan={7}
                                                                                        scope="colgroup"
                                                                                        className="bg-gray-50 px-4 py-2 text-left text-sm  text-gray-900 sm:px-6"
                                                                                    >
                                                                                        <div className={'flex'}>
                                                                                            <div className={'w-4/12'}>
                                                                                                <p className={'font-normal'}>{t('Designation')}: {designation.designationName}</p>
                                                                                                <p className={'font-normal'}>{t('Description')}: {designation.description}</p>
                                                                                            </div>
                                                                                            <div className={'w-4/12'}>
                                                                                                <p className={'font-normal'}>{t('StartDate')}: {designation.startTime ? format(new Date(designation.startTime.date), 'dd/MM/Y') : ''}</p>
                                                                                                <p className={'font-normal'}>{t('EndDate')}: {designation.endTime ? format(new Date(designation.endTime.date), 'dd/MM/Y') : ''}</p>
                                                                                            </div>
                                                                                            <div className={'w-4/12'}>
                                                                                                <p className={'font-normal'}>{t('NoOfPerson')}: {designation.noOfPerson}</p>
                                                                                                <p className={'font-normal'}>{t('Rate')}: ${designation.rate}</p>
                                                                                            </div>
                                                                                        </div>

                                                                                    </th>
                                                                                    <th className="bg-gray-50 px-4 py-2 text-left text-sm  text-gray-900 sm:px-6"></th>
                                                                                    {/*<th className="bg-gray-50">
                                                                                        {
                                                                                            hasRoleModerator && (
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="px-2 py-2 rounded bg-indigo-800 text-white hover:bg-indigo-700 items-center text-sm"
                                                                                                    onClick={() => {
                                                                                                        setDesignation({
                                                                                                            id: designation.id,
                                                                                                            name: designation.designationName,
                                                                                                            slug: designation.designationSlug
                                                                                                        });
                                                                                                        setCandidates(candidateList[designation.designationSlug] ? candidateList[designation.designationSlug] : []);
                                                                                                        setShowAdditionalCandidateModal(true);
                                                                                                    }}
                                                                                                >
                                                                                                    <HiOutlineUserGroup size={16}/>
                                                                                                </button>
                                                                                            )
                                                                                        }
                                                                                    </th>*/}
                                                                                </tr>
                                                                                <tr className="bg-indigo-50">
                                                                                    <th scope="col" className="text-center py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                        {t('Name')}
                                                                                    </th>
                                                                                    <th scope="col" className="text-center px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                                        {t('Email')}
                                                                                    </th>
                                                                                    {
                                                                                        hasRoleModerator && (
                                                                                            <th scope="col" className="text-center px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                                                {t('HourRate')}

                                                                                            </th>
                                                                                        )
                                                                                    }

                                                                                    <th scope="col" className="text-center px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                                        {t('CandidateAcceptance')}

                                                                                    </th>
                                                                                    <th scope="col" className="text-center px-3 py-3.5 text-left text-sm font-semibold text-gray-900">

                                                                                        {t('ClientAcceptance')}

                                                                                    </th>
                                                                                    <th scope="col" className="text-center px-3 py-3.5 text-left text-sm font-semibold text-gray-900">

                                                                                        {t('ModeratorAcceptance')}

                                                                                    </th>
                                                                                    <th scope="col" className="text-center px-3 py-3.5 text-left text-sm font-semibold text-gray-900">

                                                                                        {t('Moderator')}

                                                                                    </th>
                                                                                    <th scope="col" className="text-center px-3 py-3.5 text-left text-sm font-semibold text-gray-900">

                                                                                        {t('FinalApprove')}

                                                                                    </th>
                                                                                    <th></th>
                                                                                    <th></th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                <tr className="border-t border-gray-200">
                                                                                    <td
                                                                                        colSpan={7}
                                                                                        scope="colgroup"
                                                                                        className="text-center bg-gray-50 px-4 py-2 text-md font-semibold text-gray-900"
                                                                                    >
                                                                                        {t('PreferredCandidates')}
                                                                                    </td>
                                                                                    <td className="text-center bg-gray-50 px-4 py-2 text-md font-semibold text-gray-900"></td>

                                                                                </tr>
                                                                                {designation.candidates.preferred ? designation.candidates.preferred.map((candidate, index) => (
                                                                                        <tr
                                                                                            key={index}
                                                                                            className="border-gray-200 border-t"
                                                                                        >
                                                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 w-[250px]">
                                                                                                {/*<select
                                                                                                    className="w-full"
                                                                                                    // onChange={e =>assignCandidate(candidate.id, Number(e.target.value), designation.id, designation.designationSlug, 'preferred')}
                                                                                                    onChange={e =>console.log(candidate.id, Number(e.target.value), designation.id, designation.designationSlug, 'preferred')}
                                                                                                >
                                                                                                    <option value="">Any One</option>
                                                                                                    {
                                                                                                        candidateList && candidateList[designation.designationSlug] && candidateList[designation.designationSlug].map((item, index)=>(
                                                                                                            <option selected={candidate.userId === item.id} key={item.id} value={item.id}>{item.name}</option>
                                                                                                        ))
                                                                                                    }
                                                                                                </select>*/}
                                                                                                <Select
                                                                                                    placeholder="Any one"
                                                                                                    searchable
                                                                                                    clearable
                                                                                                    nothingFound="No candidates"
                                                                                                    data={(Object.values(candidateList).length > 0 && !!candidateList[designation.designationSlug]) ? candidateList[designation.designationSlug].map((item, index)=>{
                                                                                                        return {value:item.id, label:item.name}
                                                                                                    }) : []}
                                                                                                    onChange={e=>assignCandidate(candidate.id, Number(e), designation.id, designation.designationSlug, 'preferred')}
                                                                                                    value={candidate.userId}
                                                                                                    maxDropdownHeight={200}
                                                                                                />

                                                                                            </td>
                                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{candidate.email}</td>
                                                                                            {
                                                                                                hasRoleModerator && (
                                                                                                    <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">${candidate.rate}</td>
                                                                                                )
                                                                                            }
                                                                                            <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                <Switch
                                                                                                    size="sm"
                                                                                                    checked={!!candidate.candidateConfirmationBy}
                                                                                                    color={'bg-indigo-800'}
                                                                                                    onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                                    offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                    onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'candidate-acceptance')}
                                                                                                />
                                                                                            </td>
                                                                                            <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                <Switch
                                                                                                    size="sm"
                                                                                                    checked={!!candidate.clientConfirmationBy}
                                                                                                    color={'bg-indigo-800'}
                                                                                                    onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                                    offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                    onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'client-acceptance')}
                                                                                                />
                                                                                            </td>
                                                                                            <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                <Switch
                                                                                                    disabled={!hasRoleModerator}
                                                                                                    size="sm"
                                                                                                    checked={!!candidate.moderatorConfirmationBy}
                                                                                                    color={'bg-indigo-800'}
                                                                                                    onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                                    offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                    onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'moderator-acceptance')}
                                                                                                />
                                                                                            </td>
                                                                                            <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                {candidate.moderatorName ? candidate.moderatorName : ''}
                                                                                            </td>
                                                                                            <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                <Switch
                                                                                                    disabled={!(!!candidate.candidateConfirmationBy && !!candidate.clientConfirmationBy && !!candidate.moderatorConfirmationBy)} //check all acceptance
                                                                                                    size="sm"
                                                                                                    checked={!!candidate.finalApprove}
                                                                                                    color={'bg-indigo-800'}
                                                                                                    onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                                    offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                    onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'final-approve')}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>

                                                                                    )) :
                                                                                    (
                                                                                        <tr className='border-gray-200 border-t'>
                                                                                            <td align="center" colSpan={8} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{t('NoRecordFound')}</td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    hasRoleModerator && (
                                                                                        <>
                                                                                            <tr className="border-t border-gray-200">
                                                                                                <td
                                                                                                    colSpan={7}
                                                                                                    scope="colgroup"
                                                                                                    className="text-center bg-gray-50 px-4 py-2 text-md font-semibold text-gray-900"
                                                                                                >
                                                                                                    {t('AdditionalCandidates')}
                                                                                                </td>
                                                                                                <td className="text-right bg-gray-50 px-4 py-2 text-md font-semibold text-gray-900">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="px-2 py-2 rounded bg-indigo-800 text-white hover:bg-indigo-700 items-center text-sm"
                                                                                                        onClick={() => {
                                                                                                            setDesignation({
                                                                                                                id: designation.id,
                                                                                                                name: designation.designationName,
                                                                                                                slug: designation.designationSlug
                                                                                                            });
                                                                                                            setCandidates(candidateList[designation.designationSlug] ? candidateList[designation.designationSlug] : []);
                                                                                                            setShowAdditionalCandidateModal(true);
                                                                                                        }}
                                                                                                    >
                                                                                                        <HiOutlineUserGroup size={16}/>
                                                                                                    </button>
                                                                                                </td>

                                                                                            </tr>

                                                                                            {designation.candidates.additional ? designation.candidates.additional.map((candidate, index) => (
                                                                                                    <tr
                                                                                                        key={index}
                                                                                                        className="border-gray-200 border-t"
                                                                                                    >
                                                                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 w-[250px]">
                                                                                                            {/*{candidate.name}*/}
                                                                                                            <select
                                                                                                                className="w-full"
                                                                                                                onChange={e =>{assignCandidate(candidate.id, Number(e.target.value), designation.id, designation.designationSlug, 'additional')}}
                                                                                                            >
                                                                                                                <option value="">Any One</option>
                                                                                                                {
                                                                                                                    candidateList && candidateList[designation.designationSlug] && candidateList[designation.designationSlug].map((item, index)=>(
                                                                                                                        <option selected={candidate.userId === item.id} key={item.id} value={item.id}>{item.name}</option>
                                                                                                                    ))
                                                                                                                }
                                                                                                            </select>
                                                                                                        </td>
                                                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{candidate.email}</td>
                                                                                                        <td align="center"
                                                                                                            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">${candidate.rate}</td>
                                                                                                        <td align="center"
                                                                                                            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{candidate.candidateConfirmationBy ? 'Yes' : 'No'}</td>
                                                                                                        <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                            <Switch
                                                                                                                size="sm"
                                                                                                                checked={!!candidate.clientConfirmationBy}
                                                                                                                color={'bg-indigo-800'}
                                                                                                                onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                                                offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                                onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'client-acceptance')}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                            <Switch
                                                                                                                disabled={!hasRoleModerator}
                                                                                                                size="sm"
                                                                                                                checked={!!candidate.moderatorConfirmationBy}
                                                                                                                color={'bg-indigo-800'}
                                                                                                                onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                                                offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                                onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'moderator-acceptance')}
                                                                                                            />

                                                                                                        </td>
                                                                                                        <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                            {candidate.moderatorName ? candidate.moderatorName : ''}
                                                                                                        </td>
                                                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                            <Switch
                                                                                                                disabled={!(!!candidate.candidateConfirmationBy && !!candidate.clientConfirmationBy && !!candidate.moderatorConfirmationBy)} //check all acceptance
                                                                                                                size="sm"
                                                                                                                checked={!!candidate.finalApprove}
                                                                                                                color={'bg-indigo-800'}
                                                                                                                onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                                                offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                                onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'final-approve')}
                                                                                                            />
                                                                                                        </td>
                                                                                                        {/*<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                                                                            <ActionIcon color="red">
                                                                                                                <HiTrash
                                                                                                                    size={16}
                                                                                                                    onClick={() => removeCandidate(candidate)}
                                                                                                                />
                                                                                                            </ActionIcon>
                                                                                                        </td>*/}
                                                                                                    </tr>

                                                                                                )) :
                                                                                                (
                                                                                                    <tr className='border-gray-200 border-t'>
                                                                                                        <td align="center"
                                                                                                            colSpan={7}
                                                                                                            className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{t('NoRecordFound')}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                }

                                                                                <tr className="border-t border-gray-200">
                                                                                    <td
                                                                                        colSpan={7}
                                                                                        scope="colgroup"
                                                                                        className="text-center bg-gray-50 px-4 py-2 text-md font-semibold text-gray-900"
                                                                                    >
                                                                                        {t('SelfInterested')}
                                                                                    </td>
                                                                                    <td className="text-center bg-gray-50 px-4 py-2 text-md font-semibold text-gray-900 "></td>

                                                                                </tr>
                                                                                {designation.candidates.self ? designation.candidates.self.map((candidate, index) => (
                                                                                        <tr
                                                                                            key={index}
                                                                                            className="border-gray-200 border-t"
                                                                                        >
                                                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 w-[250px]">
                                                                                                {candidate.name}
                                                                                            </td>
                                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{candidate.email}</td>
                                                                                            {
                                                                                                hasRoleModerator && <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">${candidate.rate}</td>
                                                                                            }
                                                                                            <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{candidate.candidateConfirmationBy ? 'Yes' : 'No'}</td>
                                                                                            <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                <Switch
                                                                                                    size="sm"
                                                                                                    checked={!!candidate.clientConfirmationBy}
                                                                                                    color={'bg-indigo-800'}
                                                                                                    onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                                    offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                    onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'client-acceptance')}
                                                                                                />
                                                                                            </td>
                                                                                            <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                <Switch
                                                                                                    disabled={!hasRoleModerator}
                                                                                                    size="sm"
                                                                                                    checked={!!candidate.moderatorConfirmationBy}
                                                                                                    color={'bg-indigo-800'}
                                                                                                    onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                                    offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                    onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'moderator-acceptance')}
                                                                                                />
                                                                                            </td>
                                                                                            <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                {candidate.moderatorName ? candidate.moderatorName : ''}
                                                                                            </td>
                                                                                            <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                <Switch
                                                                                                    disabled={!(!!candidate.candidateConfirmationBy && !!candidate.clientConfirmationBy && !!candidate.moderatorConfirmationBy)} //check all acceptance
                                                                                                    size="sm"
                                                                                                    checked={!!candidate.finalApprove}
                                                                                                    color={'bg-indigo-800'}
                                                                                                    onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                                    offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                    onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'final-approve')}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>

                                                                                    )) :
                                                                                    (
                                                                                        <tr className='border-gray-200 border-t'>
                                                                                            <td align="center" colSpan={7} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{t('NoRecordFound')}</td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                                </tbody>
                                                                            </table>
                                                                        </Tab.Panel>
                                                                    ))
                                                                }
                                                            </Tab.Panels>
                                                        </Tab.Group>


                                                        {/*
                                                        <table className="min-w-full">
                                                            <thead className="bg-white">
                                                            <tr>
                                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                    {t('NameOfCandidate')}
                                                                </th>
                                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                    {t('Email')}
                                                                </th>
                                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                    {t('HourRate')}

                                                                </th>
                                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                    {t('CandidateAcceptance')}

                                                                </th>
                                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">

                                                                    {t('ClientAcceptance')}

                                                                </th>
                                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">

                                                                    {t('ModeratorAcceptance')}

                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="bg-white">

                                                            {jobDetails.designations && jobDetails.designations.map((designation, index) => (

                                                                <Fragment key={index}>
                                                                    <tr className="border-t border-gray-200">
                                                                        <td
                                                                            colSpan={7}
                                                                            scope="colgroup"
                                                                            className="bg-gray-50 px-4 py-2 text-left text-sm  text-gray-900 sm:px-6"
                                                                        >
                                                                            <div className={'flex'}>
                                                                                <div className={'w-4/12'}>
                                                                                    <p className={'font-normal'}>{t('Designation')}: {designation.designationName}</p>
                                                                                    <p className={'font-normal'}>{t('Description')}: {designation.description}</p>
                                                                                </div>
                                                                                <div className={'w-4/12'}>
                                                                                    <p className={'font-normal'}>{t('StartDate')}: {designation.startTime ? format(new Date(designation.startTime.date), 'dd/MM/Y') : ''}</p>
                                                                                    <p className={'font-normal'}>{t('EndDate')}: {designation.endTime ? format(new Date(designation.endTime.date), 'dd/MM/Y') : ''}</p>
                                                                                </div>
                                                                                <div className={'w-4/12'}>
                                                                                    <p className={'font-normal'}>{t('NoOfPerson')}: {designation.noOfPerson}</p>
                                                                                    <p className={'font-normal'}>{t('Rate')}: ${designation.rate}</p>
                                                                                </div>
                                                                            </div>

                                                                        </td>
                                                                        <td className="bg-gray-50">
                                                                            {
                                                                                hasRoleModerator && (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="px-2 py-2 rounded bg-indigo-800 text-white hover:bg-indigo-700 items-center text-sm"
                                                                                        onClick={() => {
                                                                                            setDesignation({
                                                                                                id: designation.id,
                                                                                                name: designation.designationName,
                                                                                                slug: designation.designationSlug
                                                                                            });
                                                                                            setCandidates(candidateList[designation.designationSlug] ? candidateList[designation.designationSlug] : []);
                                                                                            setShowAdditionalCandidateModal(true);
                                                                                        }}
                                                                                    >
                                                                                        <HiOutlineUserGroup size={16}/>
                                                                                    </button>
                                                                                )
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="border-t border-gray-200">
                                                                        <td
                                                                            colSpan={8}
                                                                            scope="colgroup"
                                                                            className="text-center bg-gray-50 px-4 py-2 text-md font-semibold text-gray-900 "
                                                                        >
                                                                            {t('PreferredCandidates')}
                                                                        </td>

                                                                    </tr>
                                                                    {designation.candidates.preferred ? designation.candidates.preferred.map((candidate, index) => (
                                                                            <tr
                                                                                key={index}
                                                                                className="border-gray-200 border-t"
                                                                            >
                                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 w-[250px]">
                                                                                    <select
                                                                                        className="w-full"
                                                                                        onChange={e =>{assignCandidate(candidate.id, Number(e.target.value), designation.id, designation.designationSlug, 'preferred')}}
                                                                                    >
                                                                                        <option value="">Any One</option>
                                                                                        {
                                                                                            candidateList && candidateList[designation.designationSlug] && candidateList[designation.designationSlug].map((item, index)=>(
                                                                                                <option selected={candidate.userId === item.id} key={item.id} value={item.id}>{item.name}</option>
                                                                                            ))
                                                                                        }
                                                                                    </select>
                                                                                </td>
                                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{candidate.email}</td>
                                                                                <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">${candidate.rate}</td>
                                                                                <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{candidate.candidateConfirmationBy ? 'Yes' : 'No'}</td>
                                                                                <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                    {
                                                                                        <Switch
                                                                                            size="md"
                                                                                            checked={!!candidate.clientConfirmationBy}
                                                                                            color={'bg-indigo-800'}
                                                                                            onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                            offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                            onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'client-acceptance')}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                                <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                    {
                                                                                        hasRoleModerator ? (
                                                                                            <>
                                                                                                <Switch
                                                                                                    size="md"
                                                                                                    checked={!!candidate.moderatorConfirmationBy}
                                                                                                    color={'bg-indigo-800'}
                                                                                                    onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                                    offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                    onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'moderator-acceptance')}
                                                                                                />
                                                                                                {candidate.moderatorName ? candidate.moderatorName : ''}
                                                                                            </>

                                                                                        ) : (
                                                                                            <>
                                                                                                {candidate.moderatorConfirmationBy ? 'Yes' : 'No'}
                                                                                                <br/>
                                                                                                {candidate.moderatorName ? candidate.moderatorName : ''}

                                                                                            </>
                                                                                        )

                                                                                    }

                                                                                </td>
                                                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                                                    <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                                                    </a>
                                                                                </td>
                                                                            </tr>

                                                                    )) :
                                                                    (
                                                                        <tr className='border-gray-200 border-t'>
                                                                            <td align="center" colSpan={8} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{t('NoRecordFound')}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                    {
                                                                        hasRoleModerator && (
                                                                            <>
                                                                                <tr className="border-t border-gray-200">
                                                                                    <td
                                                                                        colSpan={8}
                                                                                        scope="colgroup"
                                                                                        className="text-center bg-gray-50 px-4 py-2 text-md font-semibold text-gray-900 "
                                                                                    >
                                                                                        {t('AdditionalCandidates')}
                                                                                    </td>

                                                                                </tr>

                                                                                {designation.candidates.additional ? designation.candidates.additional.map((candidate, index) => (
                                                                                        <tr
                                                                                            key={index}
                                                                                            className="border-gray-200 border-t"
                                                                                        >
                                                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 w-[250px]">
                                                                                                {candidate.name}
                                                                                                <select
                                                                                                    className="w-full"
                                                                                                    onChange={e =>{assignCandidate(candidate.id, Number(e.target.value), designation.id, designation.designationSlug, 'additional')}}
                                                                                                >
                                                                                                    <option value="">Any One</option>
                                                                                                    {
                                                                                                        candidateList && candidateList[designation.designationSlug] && candidateList[designation.designationSlug].map((item, index)=>(
                                                                                                            <option selected={candidate.userId === item.id} key={item.id} value={item.id}>{item.name}</option>
                                                                                                        ))
                                                                                                    }
                                                                                                </select>
                                                                                            </td>
                                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{candidate.email}</td>
                                                                                            <td align="center"
                                                                                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">${candidate.rate}</td>
                                                                                            <td align="center"
                                                                                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{candidate.candidateConfirmationBy ? 'Yes' : 'No'}</td>
                                                                                            <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                {
                                                                                                    <Switch
                                                                                                        size="md"
                                                                                                        checked={!!candidate.clientConfirmationBy}
                                                                                                        color={'bg-indigo-800'}
                                                                                                        onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                                        offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                        onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'client-acceptance')}
                                                                                                    />
                                                                                                }
                                                                                            </td>
                                                                                            <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                                {
                                                                                                    hasRoleModerator ? (
                                                                                                        <>
                                                                                                            <Switch
                                                                                                                size="md"
                                                                                                                checked={!!candidate.moderatorConfirmationBy}
                                                                                                                color={'bg-indigo-800'}
                                                                                                                onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                                                offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                                onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'moderator-acceptance')}
                                                                                                            />
                                                                                                            {candidate.moderatorName ? candidate.moderatorName : ''}
                                                                                                        </>

                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {candidate.moderatorConfirmationBy ? 'Yes' : 'No'}
                                                                                                            <br/>
                                                                                                            {candidate.moderatorName ? candidate.moderatorName : ''}

                                                                                                        </>
                                                                                                    )

                                                                                                }

                                                                                            </td>
                                                                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                                                                <ActionIcon color="red">
                                                                                                    <HiTrash
                                                                                                        size={16}
                                                                                                        onClick={() => removeCandidate(candidate)}
                                                                                                    />
                                                                                                </ActionIcon>
                                                                                            </td>
                                                                                        </tr>

                                                                                    )) :
                                                                                    (
                                                                                        <tr className='border-gray-200 border-t'>
                                                                                            <td align="center"
                                                                                                colSpan={8}
                                                                                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{t('NoRecordFound')}</td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    }

                                                                    <tr className="border-t border-gray-200">
                                                                        <td
                                                                            colSpan={8}
                                                                            scope="colgroup"
                                                                            className="text-center bg-gray-50 px-4 py-2 text-md font-semibold text-gray-900 "
                                                                        >
                                                                            {t('SelfInterested')}
                                                                        </td>

                                                                    </tr>
                                                                    {designation.candidates.self ? designation.candidates.self.map((candidate, index) => (
                                                                            <tr
                                                                                key={index}
                                                                                className="border-gray-200 border-t"
                                                                            >
                                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 w-[250px]">
                                                                                    {candidate.name}
                                                                                </td>
                                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{candidate.email}</td>
                                                                                <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">${candidate.rate}</td>
                                                                                <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{candidate.candidateConfirmationBy ? 'Yes' : 'No'}</td>
                                                                                <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                    {
                                                                                        <Switch
                                                                                            size="md"
                                                                                            checked={!!candidate.clientConfirmationBy}
                                                                                            color={'bg-indigo-800'}
                                                                                            onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                            offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                            onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'client-acceptance')}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                                <td align="center" className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                                    {
                                                                                        hasRoleModerator ? (
                                                                                            <>
                                                                                                <Switch
                                                                                                    size="md"
                                                                                                    checked={!!candidate.moderatorConfirmationBy}
                                                                                                    color={'bg-indigo-800'}
                                                                                                    onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true"/>}
                                                                                                    offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                    onChange={(e)=> assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate.id, 'moderator-acceptance')}
                                                                                                />
                                                                                                {candidate.moderatorName ? candidate.moderatorName : ''}
                                                                                            </>

                                                                                        ) : (
                                                                                            <>
                                                                                                {candidate.moderatorConfirmationBy ? 'Yes' : 'No'}
                                                                                                <br/>
                                                                                                {candidate.moderatorName ? candidate.moderatorName : ''}

                                                                                            </>
                                                                                        )

                                                                                    }

                                                                                </td>
                                                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                                                    <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                                                    </a>
                                                                                </td>
                                                                            </tr>

                                                                        )) :
                                                                        (
                                                                            <tr className='border-gray-200 border-t'>
                                                                                <td align="center" colSpan={8} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{t('NoRecordFound')}</td>
                                                                            </tr>
                                                                        )
                                                                    }

                                                                </Fragment>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                        */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <footer className="relative mt-1">
                    <div className="container mx-auto justify-items-center">
                        <div className="text-right pt-0.5 mb-0.5">
                            {/*<button onClick={() => {
                                navigate('/job-post')
                            }}
                                className={'px-6 py-2 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center text-sm'}
                            ><span>{t('Back')}</span>
                            </button>*/}

                            <button
                                onClick={() => navigate('/job-post')}
                                className="px-6 py-2 text-gray-400 inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-gray-50 bg-gray-25 items-center rounded text-sm"
                            >
                                <FiArrowLeft size={16} className={'mr-2'}/>
                                <span>{t('Back')}</span>
                            </button>

                            <button
                                onClick={() => jobView(jobId)}
                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-400 bg-indigo-300 items-center border rounded text-sm"
                            >
                                <HiEye size={16} className={'mr-2'}/>
                                <span>{t('Preview')}</span>
                            </button>
                            {
                                ! hasRoleModerator && (
                                    <button onClick={() => {
                                        navigate('/job-post')
                                        showNotification({
                                            id: 'load-data',
                                            loading: true,
                                            title: (t('Thanks') + ' !'),
                                            message: (t('JobIsWaitingForApproval.WhenItWillBeApprovedYouWillReceiveAnEmailNotification')),
                                            autoClose: 2000,
                                            disallowClose: true,
                                            color: 'green',
                                        });
                                    }}
                                            className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-sm"
                                    >
                                        {spinner ? (
                                            <AiOutlineLoading3Quarters
                                                className={'mr-2 animate-spin'}
                                                size={16}/>
                                        ) : (<FiSave size={16} className={'mr-2'}/>)}
                                        <span>{t('Save')}</span>
                                    </button>
                                )
                            }


                            {
                                hasRoleModerator && (
                                    <select
                                        onChange={(e) => {
                                            if (e.target.value){
                                                updateJobDetails("status", e.target.value);
                                                showNotification({
                                                    id: 'load-data',
                                                    loading: true,
                                                    title: (t('Success') + ' !'),
                                                    message: (t('Job is ') + e.target.value),
                                                    autoClose: 1000,
                                                    disallowClose: true,
                                                    color: 'green',
                                                });
                                            }

                                        }}
                                        className="relative -top-0.5 h-9 px-6 py-2 text-gray-900 inline-flex focus:border-transparent transition duration-150 ease-in-out border rounded text-sm">
                                        <option value="">Select process</option>

                                        {
                                            jobStatusList.length > 0 && jobStatusList.map((status, index) => (
                                                <option value={status.slug} selected={status.slug === jobDetails.status}>{status.name}</option>

                                            ))
                                        }
                                    </select>
                                )
                            }

                        </div>
                    </div>
                </footer>
            </div>

            {showModal ? <ViewJob setShowModal={setShowModal} jobDetails={details} roles={roles} jobView={jobView()}/> : ''}
            {showAdditionalCandidateModal ? <AddJobCandidate setShowModal={setShowAdditionalCandidateModal} candidates={candidates} designation={designation} recall={recall} setRecall={setRecall}/> : ''}


        </>
    );
}
