import React, { useState } from "react";
import { Tooltip } from "@mantine/core";
import { HiChevronRight } from "react-icons/hi";

export default function JobTitle({job, candidateJobList, jobView, showListIcon}){
    let jobTitleBackgroundClass = 'bg-indigo-500';

    if (job['jobStatusSlug'] === 'complete' || job['jobStatusSlug'] === 'archived') {
        jobTitleBackgroundClass = 'bg-gray-300';
    } else {
        if(candidateJobList.length > 0){

            let findJob = candidateJobList.find(candidateJob => candidateJob['id'] === job['jobId']);

            if(findJob){
                findJob['applyStatusSlug'] === 'confirmed' && (jobTitleBackgroundClass = 'bg-green-500');
                
                if(findJob['applyStatusSlug'] === 'applied' || findJob['applyStatusSlug'] === 'accepted' || findJob['applyStatusSlug'] === 'preferred'){
                    jobTitleBackgroundClass = 'bg-amber-500';
                }
            }else{
                if(job['jobStatusSlug'] === 'running'){
                    jobTitleBackgroundClass = 'bg-gray-500';
                }else{
                    jobTitleBackgroundClass = 'bg-red-500';
                }
            }
            
        }
    }

    return (
        <Tooltip label={job.jobTitle}>
            {/* <div key={index} className="cursor-pointer mb-2 text-left pl-1 mb-1 bg-indigo-200 rounded rounded-sm" onClick={() => jobView(job.jobId)}> */} 
            {/* <div key={index} className={`${candidateJobList.length > 0 && candidateJobList.find(candidateJob => candidateJob['id'] === job['jobId']) ? (candidateJobList.find(candidateJob => candidateJob['id'] === job['jobId'])['applyStatusSlug'] === 'confirmed' ? 'bg-green-300' : '') || (['applied', 'accepted', 'preferred'].some(slug => slug === candidateJobList.find(candidateJob => candidateJob['id'] === job['jobId'])['applyStatusSlug']) ? 'bg-amber-300' : '') || (['complete', 'archived'].some(slug => slug === candidateJobList.find(candidateJob => candidateJob['id'] === job['jobId'])['applyStatusSlug']) ? 'bg-gray-300' : '') : candidateMatchJobList.find(candidateMatchJob => candidateMatchJob['id'] === job['jobId']) ? 'bg-red-300' : job['jobStatusSlug'] === 'running' ? 'bg-gray-500' : 'bg-indigo-200'}  cursor-pointer mb-2 text-left pl-1 mb-1 rounded rounded-sm`} onClick={() => jobView(job.jobId)}> */}
            <div className={`${jobTitleBackgroundClass} cursor-pointer mb-2 text-left pl-1 mb-1 rounded rounded-sm flex`} onClick={() => jobView(job.jobId)}>
                {showListIcon && <HiChevronRight size={18} className="h-6 w-5 flex-none text-indigo-800"/>} <a href="#" className="text-black"><span>{job.jobTitle.substring(0, 25)}{job.jobTitle.length > 25 && '...'}</span></a>

            </div>
        </Tooltip>
    );
}