import React, {Fragment, useLayoutEffect, useState} from "react";
import {Routes, Route, Navigate, Outlet, useNavigate} from 'react-router-dom';
import Main from "./components/layouts/Main";
import DomainDashboard from "./components/modules/domain/Dashboard";
import Login from "./components/layouts/Login";
import GlobalForm from "./components/forms/GlobalForm";
import Domain from "./components/modules/domain/Domain";
import Dashboard from "./components/modules/domain/Dashboard";
import Paginate from "./components/ReactTable/Paginate";
import SingleForm from "./components/Html/SingleForm";
import TwoColumnForm from "./components/Html/TwoColumnForm";
import UserForm from "./components/Html/UserForm";
import PageForm from "./components/Html/PageForm";
import DayView from "./components/home/calendar-view/DayView";
import WeekView from "./components/home/calendar-view/WeekView";
import YearView from "./components/home/calendar-view/YearView";
import MonthViewModal from "./components/modules/domain/Views/MonthViewModal";
import AddAppointment from "./components/modules/appointment/AddAppointment";
import UpdateAppointment from "./components/modules/appointment/UpdateAppointment";
import Particular from "./components/modules/master-data/Particular/Particular";
import NewUser from "./components/modules/master-data/SystemUser/NewUser";
import Setting from "./components/modules/master-data/Setting/Setting";

import ClientIndex from "./components/modules/client/Index";
import ClientRegister from "./components/modules/client/Register";
import ClientUpdate from "./components/modules/client/Update";
import ClientEmployeeIndex from "./components/modules/client/employee/Index";
import ClientEmployeeRegister from "./components/modules/client/employee/Register";


import CandidateIndex from "./components/modules/candidate/Index";
import CandidateRegister from "./components/modules/candidate/Register";
import CandidateUpload from "./components/modules/candidate/Upload";
import Update from "./components/modules/candidate/Update";


import UserPermission from "./components/modules/master-data/user-permission/UserPermission";
import UserGroup from "./components/modules/master-data/user-group/UserGroup";
import CompanyProfile from "./components/modules/company-profile/CompanyProfile";

import JobIndex from "./components/modules/job-post/Index";
import JobUpdate from "./components/modules/job-post/Update";
import JobView from "./components/modules/job-post/ViewJob";
import CreateJobPost from "./components/modules/job-post/Create";
import PreferableCandidate from "./components/modules/job-post/AddPreferableCandidate";
import UpdateJobPost from "./components/modules/job-post/UpdateJobPost";

import TimeSheetIndex from './components/modules/time-sheet/Index';
import TimeSheetAdd from './components/modules/time-sheet/Add';
import PreviousEvent from './components/modules/time-sheet/PreviousEvent';

import Home from "./components/home/Home";
import AppointmentIndex from "./components/modules/appointment/Index";
import Designation from "./components/modules/master-data/designation/Designation";

import PayrollIndex from './components/modules/payroll/Index'
import PayrollCreate from './components/modules/payroll/Create'

import InvoiceIndex from './components/modules/invoice/Index'
import InvoiceCreate from './components/modules/invoice/Create'

import { MantineProvider, Text } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import Core from "./components/modules/master-data/core/Core";
import CreateMultiJobForm from "./components/modules/job-post/CreateMultiJobPostForm";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import Bookkeeping from "./components/modules/bookkeeping/Bookkeeping";
import Inventory from "./components/modules/inventory/Inventory";
import PayrollSummery from "./components/modules/payroll/PayrollSummery";
import jwt_decode from "jwt-decode";
import InvoiceSummery from "./components/modules/invoice/InvoiceSummery";
import InvoicePdf from "./components/modules/invoice/InvoicePdf";
import InvoiceUpdate from "./components/modules/invoice/InvoiceUpdate";
import InvoiceShow from "./components/modules/invoice/InvoiceShow";
import InvoicePaymentCreate from "./components/modules/invoice/InvoicePaymentCreate";

import NotificationIndex from './components/modules/notification/Index';
import NotificationCreate from './components/modules/notification/Create';
import NotificationUpdate from './components/modules/notification/Update';
import CrmMasterData from './components/modules/master-data/crm/MasterData';
import PayrollPdf from "./components/modules/payroll/PayrollPdf";
import PayrollUpdate from "./components/modules/payroll/PayrollUpdate";

import ModeratorIndex from "./components/modules/moderator/Index";
import ModeratorRegister from "./components/modules/moderator/Register";

import UpdateDomainSetting from "./components/modules/domain/Update";
import AppModuleChildren from "./components/modules/master-data/core/AppModuleChildren";

import CrmIndex from "./components/modules/crm/Index";
import ClientRegisterFromCrm from "./components/modules/crm/RegisterClient";
import CandidateRegisterFromCrm from "./components/modules/crm/RegisterCandidate";
import ClientIndexFromCrm from "./components/modules/crm/ClientIndex";
import CandidateIndexFromCrm from "./components/modules/crm/CandidateIndex";

import InvoiceSummeryPdf from "./components/modules/invoice/InvoiceSummeryPdf";

import BookkeepingAccountHead from './components/modules/master-data/bookkeeping/AccountHead';
import PayrollShow from "./components/modules/payroll/PayrollShow";




// const ProtectedRoute = ({ user, redirectPath = "login", children }) => {
//     if (!user) {
//         return <Navigate to={redirectPath} replace />;
//       }

//       return children ? children : <Outlet />;
// }
function App() {
    const navigate = useNavigate();

    let myTime = localStorage.getItem('!!exp##ti##@@');
    if (localStorage.getItem('user')){
        const {token} = JSON.parse(localStorage.getItem('user'));
        const decode_token = jwt_decode(token);
        if (decode_token.exp < Date.now() / 1000) {
            localStorage.clear();
            navigate("/login");
        }
    }

    /*if (myTime && (new Date().getTime() - base64_decode(myTime) > 60 * 60 * 1000)) {
        localStorage.clear();
        navigate("/login");
    }*/


    // let formHeight = 0;
    // let fullTableHeight = 0;
    // let fromTableHeight = 0;
    // let fullFormHeight = 0;
    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    let formHeight = useWindowSize()[1] - 255 + "px";
    let formTableHeight = useWindowSize()[1] - 170 + "px";
    // var fullTableHeight = useWindowSize()[1] - 205 + "px";
    let fullTableHeight = useWindowSize()[1] - 160 + "px";
    // var fullFormHeight = useWindowSize()[1] - 217 + "px";
    let fullFormHeight = useWindowSize()[1] - 170 + "px";
    let homeHeight = useWindowSize()[1] - 50 + "px";
 //   let homeHeight = useWindowSize()[1] + "px";
    // var calendarHeight = useWindowSize()[1] - 245 + "px";
    let calendarHeight = useWindowSize()[1] - 200 + "px";
    let rightSidebarHeight = useWindowSize()[1] - 245 + "px";
    // var leftSidebarHeight = useWindowSize()[1] - 110 + "px";
    let leftSidebarHeight = useWindowSize()[1] - 60 + "px";
    let modalHeight = useWindowSize()[1] - 190 + "px";
    let drawerHeight = useWindowSize()[1]-130 + "px";

    localStorage.setItem('formHeight',formHeight);
    localStorage.setItem('formTableHeight',formTableHeight);
    localStorage.setItem('fullTableHeight',fullTableHeight);
    localStorage.setItem('fullFormHeight',fullFormHeight);
    localStorage.setItem('homeHeight',homeHeight);
    localStorage.setItem('calendarHeight',calendarHeight);
    localStorage.setItem('modalHeight',modalHeight);
    localStorage.setItem('leftSidebarHeight',leftSidebarHeight);
    localStorage.setItem('drawerHeight',drawerHeight);


    // const user = localStorage.getItem("user");
    return (
        <>
            <MantineProvider withNormalizeCSS withGlobalStyles>
                <ModalsProvider>
                    <NotificationsProvider>
            <Routes>
                <Route path='login' element={<Login/>}/>
                <Route path="/" element={<Main/>}>
                    <Route path="global-form" element={<GlobalForm/>}/>
                    <Route path="home">
                        <Route path="" element={<Home/>}/>
                        <Route path="day-view" element={<DayView/>}/>
                        <Route path="week-view" element={<WeekView/>}/>
                        <Route path="year-view" element={<YearView/>}/>
                    </Route>
                    <Route path="appointments" element={<AppointmentIndex/>}/>
                    <Route path="inventory" element={<Inventory/>}/>
                    <Route path="bookkeeping" element={<Bookkeeping/>}/>

                    <Route path="domain">
                        <Route path="" element={<Dashboard/>}/>
                        <Route path="index" element={<Domain/>}/>
                        {/*<Route path="day-view" element={<DayView/>}/>*/}
                        {/*<Route path="week-view" element={<WeekView/>}/>*/}
                        {/*<Route path="year-view" element={<YearView/>}/>*/}
                        {/*<Route path="month-view-modal" element={<MonthViewModal/>}/>*/}
                        <Route path="add-appointment" element={<AddAppointment/>}/>
                        <Route path="update-appointment" element={<UpdateAppointment/>}/>
                        <Route path="setting" element={<UpdateDomainSetting/>}/>
                    </Route>
                    <Route path="client">
                        <Route path="" element={<ClientIndex/>}/>
                        <Route path="register" element={<ClientRegister/>}/>
                        <Route path=":clientId/edit" element={<ClientUpdate/>}/>
                        {/*<Route path="index" element={<home/>}/>*/}
                    </Route>
                    <Route path="moderator">
                        <Route path="" element={<ModeratorIndex/>}/>
                        <Route path="register" element={<ModeratorRegister/>}/>
                    </Route>
                    <Route path="employee">
                        <Route path="" element={<ClientEmployeeIndex/>}/>
                        <Route path="register" element={<ClientEmployeeRegister/>}/>
                    </Route>
                    <Route path="notification">
                        <Route path="" element={<NotificationIndex/>}/>
                        <Route path="create" element={<NotificationCreate/>}/>
                        <Route path=":notificationId/edit" element={<NotificationUpdate/>}/>

                    </Route>

                    <Route path="candidate">
                        <Route path="" element={<CandidateIndex/>}/>
                        <Route path="register" element={<CandidateRegister/>}/>
                        <Route path=":candidateId/edit" element={<Update/>}/>
                        <Route path="upload" element={<CandidateUpload/>}/>
                    </Route>

                    <Route path="master-data">
                        <Route path="particular" element={<Particular/>}/>
                        <Route path="new-user" element={<NewUser/>}/>
                        <Route path="setting" element={<Setting/>}/>
                        <Route path="index" element={<Domain/>}/>
                        <Route path="user-permission" element={<UserPermission/>}/>
                        <Route path="user-group" element={<UserGroup/>}/>
                        <Route path="designation" element={<Designation/>}/>
                        <Route path="core" element={<Core/>}/>
                        <Route path="app-module-child" element={<AppModuleChildren/>}/>
                        <Route path="crm" element={<CrmMasterData/>}/>
                        <Route path="account-head" element={<BookkeepingAccountHead/>}/>


                    </Route>

                    <Route path="company-profile">
                        <Route path="" element={<CompanyProfile/>}/>
                    </Route>

                    <Route path="job-post">
                        <Route path="" element={<JobIndex/>}/>
                        <Route path=":jobId/edit" element={<JobUpdate/>}/>
                        <Route path=":jobId/view" element={<JobView/>}/>
                        <Route path="create" element={<CreateJobPost/>}/>
                        <Route path=":jobId/preferable-candidate-list" element={<PreferableCandidate/>}/>
                        <Route path="create-multi-form" element={<CreateMultiJobForm/>}/>
                    </Route>

                    <Route path="time-sheet">
                        <Route path="" element={<TimeSheetIndex/>}/>
                        <Route path="add" element={<TimeSheetAdd/>}/>
                        <Route path=":jobId/previous-event" element={<PreviousEvent/>}/>
                    </Route>
                    <Route path="crm">
                        <Route path="" element={<CrmIndex/>}/>
                        <Route path="register-client" element={<ClientRegisterFromCrm/>}/>
                        <Route path="register-candidate" element={<CandidateRegisterFromCrm/>}/>
                        <Route path="client" element={<ClientIndexFromCrm/>}/>
                        <Route path="candidate" element={<CandidateIndexFromCrm/>}/>
                    </Route>

                    <Route path="payroll">
                        <Route path="" element={<PayrollIndex/>}/>
                        <Route path="create" element={<PayrollCreate/>}/>
                        <Route path="summery" element={<PayrollSummery />}/>
                        <Route path=":payrollId/pdf" element={<PayrollPdf/>}/>
                        <Route path=":payrollId/edit" element={<PayrollUpdate/>}/>
                        <Route path=":payrollId/view" element={<PayrollShow/>}/>
                    </Route>

                    <Route path="invoice">
                        <Route path="" element={<InvoiceIndex/>}/>
                        <Route path="create" element={<InvoiceCreate/>}/>
                        <Route path=":invoiceId/summery" element={<InvoiceSummery/>}/>
                        <Route path=":invoiceId/summery/pdf" element={<InvoiceSummeryPdf/>}/>
                        <Route path=":invoiceId/pdf" element={<InvoicePdf/>}/>
                        <Route path=":invoiceId/edit" element={<InvoiceUpdate/>}/>
                        <Route path=":invoiceId/view" element={<InvoiceShow/>}/>

                        <Route path=":invoiceId/payment/create" element={<InvoicePaymentCreate/>}/>
                    </Route>

                    <Route path="html">
                        <Route path="paginate" element={<Paginate/>}/>
                        <Route path="single-form" element={<SingleForm/>}/>
                        <Route path="two-form" element={<TwoColumnForm/>}/>
                        <Route path="page-form" element={<PageForm/>}/>
                        <Route path="user-form" element={<UserForm/>}/>
                    </Route>
                </Route>
            </Routes>
                    </NotificationsProvider>
                </ModalsProvider>
            </MantineProvider>
        </>
    );
}

export default App;
