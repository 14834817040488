import React, { Fragment, useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import button from "@material-tailwind/react";
import { HiHome, HiOutlineCalendar, HiOutlineExclamation, HiOutlineX, HiCheckCircle, HiOutlinePlus, HiOutlineSave } from "react-icons/hi";
import { ScrollArea, Table, LoadingOverlay, Select, Grid, Group, Text, NumberInput, Textarea, Loader, Button } from "@mantine/core";
import { AiFillSave, AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiArrowRight, FiFile, FiSave } from "react-icons/fi";
import * as Yup from "yup";
import { format, min } from 'date-fns';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import { FaFile, FaRegSave, FaSave } from "react-icons/fa";
import { getValue } from "@testing-library/user-event/dist/utils";

export default function JournalEntryModal({ setShowModal }) {
    const { t, i18n } = useTranslation();
    const { token, loggedUserId, roles } = JSON.parse(localStorage.getItem("user"));
    const modalHeight = localStorage.getItem("modalHeight");
    const [saveSpinner, setSaveSpinner] = useState(false);
    const [addSpinner, setAddSpinner] = useState(false);
    const ref = useRef();

    const [heads, setHeads] = useState([]);
    const [transactions, setTransactions] = useState({});
    const [remark, setRemark] = useState('');
    const [totalDebit, setTotalDebit] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);

    // console.log(headRef)

    const validationSchema = Yup.object().shape({
        head: Yup
                .number()
                .typeError(t('HeadIsRequired'))
                .required(t('HeadIsRequired')),
        amount: Yup
                    .number()
                    .typeError(t('AmountIsRequired'))
                    .required(t('AmountIsRequired'))
                    .moreThan(0, t('AmountMustBeMoreThanZero')),
        type: Yup
                .string()
                .required(t('TypeIsRequired'))
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState, setValue, getValues, setError, clearErrors, resetField } = useForm(formOptions);
    const { errors } = formState;

    const getHeads = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/bookkeeping/account-head-child-parent-group`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                setHeads(res.data.data);
            })
            .catch(function (err) {
                console.log(err);

            });
    }

    function formSubmit(payload) {
        setAddSpinner(true);
        payload['headName'] = ref.current.value;

        if(transactions[payload['type']]){
            transactions[payload['type']].push(payload)
        } else{
            transactions[payload['type']] = [payload] 
        }

        reset();
        setValue("head", null)
        setValue("type", null)
        setAddSpinner(false);

        calculateTotal();
    }

    const removeTransaction = (item, index) => {
        transactions[item['type']].splice(index, 1)
        setTransactions({...transactions});
        calculateTotal();

    }

    const saveTransactions = () => {
        // console.log({transactions, remark, createdBy: loggedUserId})

        if( !transactions.hasOwnProperty('DEBIT') || !transactions.hasOwnProperty('CREDIT') || transactions['DEBIT'].length === 0 || transactions['CREDIT'].length === 0 ){
            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('Warning')),
                message: (t('BadTransaction')),
                autoClose: 2000,
                disallowClose: true,
                color: 'red',
            });
            return;
        }
        setSaveSpinner(true);
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/bookkeeping/manual-transaction`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {transactions, remark, createdBy: loggedUserId}
        })
            .then(res => {
                if (201 === res.data.status) {

                    setTransactions({})
                    setRemark('')
                    setTotalDebit(0)
                    setTotalCredit(0)
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Success')),
                        message: (t(res.data.message)),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'green',
                    });
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: (t(res.data.message)),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });
                }
            })
            .catch(function (err) {
                console.log(err);
            });
            setSaveSpinner(false)
    }


    const calculateTotal = () => {
        setTotalDebit(transactions['DEBIT'] && transactions['DEBIT'].reduce((sum, transaction) => sum + transaction.amount, 0))
        setTotalCredit(transactions['CREDIT'] && transactions['CREDIT'].reduce((sum, transaction) => sum + transaction.amount, 0))
    }

    useEffect(() => {
        getHeads()
    }, [])


    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-3/4">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-gray-800 relative">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('Transactions')}</h5>
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0 relative">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <div role="main" className="w-full pt-1 px-2">
                                            <form onSubmit={handleSubmit(formSubmit)} className="h-full">
                                                {/* {
                                                    errors.amount &&
                                                    <div className="bg-red-50 flex items-center px-1 text-sm py-1 border border-red-300 text-red-800">
                                                        <HiOutlineExclamation size={16} className={'mr-2'} />
                                                        <span>{errors.amount?.message}</span>
                                                    </div>
                                                } */}
                                                <Grid align="flex-end" justify="flex-end">
                                                    <Grid.Col span="auto">
                                                        <div className="w-full items-center">
                                                            <label htmlFor="head" className="form-input-sm-label-required">{t('AccountHead')}</label>
                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                {
                                                                    errors.head &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.head?.message}
                                                                    </div>
                                                                }
                                                                <Select
                                                                    {...register("head")}
                                                                    placeholder={t('SelectHead')}
                                                                    data={heads}
                                                                    onChange={e => {
                                                                        e && clearErrors('head');
                                                                        setValue('head', e);
                                                                    }}
                                                                    nothingFound={t('NoHead')}
                                                                    maxDropdownHeight={280}
                                                                    searchable
                                                                    id="head"
                                                                    value={getValues('head')}
                                                                    ref={ref}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Grid.Col>
                                                    <Grid.Col span={4}>
                                                        <div className="w-full items-center">
                                                            <label htmlFor="narration" className="form-input-sm-label">{t('Narration')}</label>
                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                <Textarea
                                                                    {...register("narration")}
                                                                    placeholder={t('Narration')}
                                                                    maxRows={4}
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                    </Grid.Col>
                                                    <Grid.Col span="auto">
                                                        <div className="w-full items-center">
                                                            <label htmlFor="amount" className="form-input-sm-label-required">{t('Amount')}</label>
                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                {
                                                                    errors.amount &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.amount?.message}
                                                                    </div>
                                                                }
                                                                <NumberInput
                                                                    {...register("amount")}
                                                                    placeholder={t('Amount')}
                                                                    id="amount"
                                                                    onChange={e => {
                                                                        e && clearErrors('amount');
                                                                        setValue('amount', e)
                                                                    }}
                                                                    autoComplete="off"
                                                                    min={1}
                                                                    value={getValues('amount')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Grid.Col>
                                                    <Grid.Col span="auto">
                                                        <div className="w-full items-center">
                                                            <label htmlFor="type" className="form-input-sm-label-required">{t('Type')}</label>
                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                {
                                                                    errors.type &&
                                                                    <div className="form-input-sm-error">
                                                                        <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.type?.message}
                                                                    </div>
                                                                }
                                                                <Select
                                                                    {...register("type")}
                                                                    placeholder={t('SelectType')}
                                                                    data={['DEBIT', 'CREDIT']}
                                                                    onChange={e => {
                                                                        e && clearErrors('type');
                                                                        setValue('type', e);
                                                                    }}
                                                                    nothingFound={t('NoType')}
                                                                    id="type"
                                                                    value={getValues('type')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Grid.Col>
                                                    <Grid.Col span={1} align="right">
                                                        <button
                                                            type="submit"
                                                            className="disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-700 disabled:hover:bg-gray-300 px-2 py-1.5 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-sm"
                                                        >
                                                            {
                                                                addSpinner ? (<Loader mr="xs" size="xs" color="gray"/>) : (<HiOutlinePlus className={'mr-1'} />)
                                                            }
                                                            <span>{t('Add')}</span>
                                                                
                                                        </button>
                                                    </Grid.Col>
                                                </Grid>
                                                {
                                                    <ScrollArea h={300} scrollbarSize={4} className="w-full">
                                                        <table className="min-w-full divide-y divide-gray-300 border border-gray-200">
                                                            <thead className="bg-gray-50">
                                                                <tr>
                                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t('AccountHead')} </th>
                                                                    <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">{t('Narration')} </th>
                                                                    <th scope="col" className="w-40 py-3.5 text-left text-sm font-semibold text-gray-900">{t('Debit')} </th>
                                                                    <th scope="col" className="w-40 py-3.5 text-left text-sm font-semibold text-gray-900">{t('Credit')} </th>
                                                                    <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="divide-y divide-gray-200 bg-white relative">
                                                                {
                                                                    Object.values(transactions).length > 0 && Object.values(transactions).map((transaction, index) => (
                                                                        transaction.map((item, index) => (
                                                                            <tr key={index}>
                                                                            <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm sm:pl-6">{item['headName']}</td>
                                                                            <td className="whitespace-nowrap py-1 pr-3 text-sm">{item['narration']}</td>
                                                                            <td className="whitespace-nowrap py-1 pr-3 text-sm">
                                                                                {'DEBIT' === item['type'] &&
                                                                                    <NumberInput
                                                                                        placeholder={t('Amount')}
                                                                                        id="amount"
                                                                                        onChange={value => {
                                                                                            item['amount'] = value ? value : 0;
                                                                                            calculateTotal()
                                                                                        }}
                                                                                        autoComplete="off"
                                                                                        value={item['amount']}
                                                                                        min={0}
                                                                                    />
                                                                                }
                                                                            </td>
                                                                            <td className="whitespace-nowrap py-1 pr-3 text-sm ">
                                                                                {'CREDIT' === item['type'] &&
                                                                                    <NumberInput
                                                                                        placeholder={t('Amount')}
                                                                                        id="amount"
                                                                                        onChange={value => {
                                                                                            item['amount'] = value ? value : 0;
                                                                                            calculateTotal()
                                                                                        }}
                                                                                        autoComplete="off"
                                                                                        value={item['amount']}
                                                                                        min={0}

                                                                                    />
                                                                                }
                                                                            </td>
                                                                            <td className="whitespace-nowrap py-1 pr-3 text-sm text-right">
                                                                                <button 
                                                                                    type="button"
                                                                                    className="p-0.5 text-white inline-flex focus:ring-red-700 transition duration-150 ease-in-out hover:bg-red-600 bg-red-700 items-center border rounded text-xs"
                                                                                    onClick={(e) => removeTransaction(item, index) }
                                                                                ><HiOutlineX size={16}/>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                        ))
                                                                    ))
                                                                }
                                                                <tr className="font-semibold">
                                                                    <td colSpan={2} className="whitespace-nowrap py-1 pl-4 pr-3 text-sm sm:pl-6">{t('Total')}</td>
                                                                    <td className="whitespace-nowrap py-1 pr-3 text-sm">
                                                                        {
                                                                            // JSON.stringify(transactions['DEBIT']);
                                                                            // transactions['DEBIT'] ? transactions['DEBIT'].reduce((sum, transaction) => sum + transaction.amount, 0) : 0
                                                                            totalDebit
                                                                        }
                                                                    </td>
                                                                    <td className="whitespace-nowrap py-1 pr-3 text-sm">
                                                                        {
                                                                            // transactions['CREDIT'] ? transactions['CREDIT'].reduce((sum, transaction) => sum + transaction.amount, 0) : 0
                                                                            totalCredit
                                                                        }
                                                                    </td>
                                                                    <td className="whitespace-nowrap py-1 pr-3 text-sm"></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                        {/* {
                                                            JSON.stringify(transactions, null, '\t')
                                                        } */}
                                                    </ScrollArea>
                                                }
                                                
                                                <Grid>
                                                    <Grid.Col span={12}>
                                                        <Textarea
                                                            placeholder={t('Remark')}
                                                            label={t('Remark')}
                                                            autosize
                                                            minRows={2}
                                                            maxRows={4}
                                                            onBlur={e => setRemark(e.target.value)}
                                                            defaultValue={remark}
                                                        />
                                                    </Grid.Col>
                                                </Grid>
                                                
                                                
                                                <footer className="relative mt-1">
                                                    <div className="text-right pt-0.5 mb-0.5">
                                                        <button
                                                            type="button"
                                                            className="disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-700 disabled:hover:bg-gray-300 px-4 py-1.5 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-sm"
                                                            onClick={() => saveTransactions()}
                                                            disabled={saveSpinner || (totalCredit === 0 && totalDebit === 0) || totalCredit !== totalDebit}
                                                        >
                                                            { saveSpinner ? (<Loader className={'mr-1'} size={14} color="gray"/>) : (<FiSave className={'mr-1'}/>) }
                                                            <span>{t('Save')}</span>
                                                            
                                                        </button>
                                                    </div>
                                                    {/*</div>*/}
                                                </footer>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red" />
        </>
    );
}