import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {
    HiEye,
    HiOutlineChartSquareBar, HiOutlineExclamation,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiPencil, HiTrash,
    HiOutlineLogin,
    HiOutlineEye,
    HiOutlineEyeOff,
    HiOutlineAtSymbol
} from "react-icons/hi";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {Link, Navigate, useNavigate} from "react-router-dom";
import jwt_decode from "jwt-decode";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {TextInput, PasswordInput, Text, Center, Grid, Image, Tabs} from '@mantine/core';
import {IconAt, IconBrandAppstore, IconBrandGooglePlay} from '@tabler/icons-react';





function Login() {
    const navigate = useNavigate();

    const {t, i18n} = useTranslation();
    const user = localStorage.getItem("user");

    const [loginErrorMessage, setLogingErrorMessage] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    
    if(user){
        return <Navigate replace to="/home"/>;
    }

    //Form validation
    const validationSchema = Yup.object().shape({
        username: Yup.string().required(t("Required")),
        password: Yup.string().required(t("Required"))
    });

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState} = useForm(formOptions);
    const {errors} = formState;

    //Form Data Submit
    function formSubmit(data) {
        setSpinner(true);
        const formData = {
            username: data.username,
            password: data.password
        };

        const login_api_endpoint = process.env.REACT_APP_API_GATEWAY_URL + '/login_check';

        axios.post(login_api_endpoint,formData).then(res => {
            if (res.status === 200){
                const decode_token = jwt_decode(res.data.token);
                // console.log(decode_token.exp,new Date().getTime())

                const user = {
                    "token" : res.data.token,
                    "roles" : decode_token.roles,
                    "loggedUserId" : decode_token.id,
                    "name" : decode_token.name,
                    "imageName" : decode_token.imageName,
                    "domainId" : decode_token.domainId,
                }
                localStorage.setItem("user", JSON.stringify(user));
                // console.log(new Date().getTime(),decode_token.exp)
                let currentTime = base64_encode(new Date().getTime());
                localStorage.setItem('!!exp##ti##@@',currentTime)

                navigate("/home");
            }else{
                navigate("/login");

                console.log('Something wrong')
            }
        })
        .catch(function(error){
            setLogingErrorMessage(error.response.data.message);
            setSpinner(false);

            // console.log(error)
        });
    }

    return (
        <>

            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img className="mx-auto h-12 w-auto" src={"/assets/images/simple-staffing.png"} alt="Simple Staffing"/>
                    <h2 className="mt-2 text-center text-3xl font-bold tracking-tight text-gray-900"> Sign in</h2>
                    
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-2xl">
                    <Grid>
                        <Grid.Col span={8}>
                            {/*<div className="w-full bg-white py-8 px-4 shadow-2xl sm:rounded-lg sm:px-10">*/}
                            <div className="w-full bg-white py-8 px-4 shadow-2xl sm:rounded-lg sm:px-10">
                                {loginErrorMessage &&
                                    <Center bg={`red.1`} c={`red.9`} size={`lg`}>{loginErrorMessage}</Center>}
                                <form className="space-y-6" onSubmit={handleSubmit(formSubmit)}>

                                    <div>
                                        <label htmlFor="email"
                                               className="block text-sm font-medium text-gray-700">Email</label>

                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">

                                            <input
                                                {...register("username")}
                                                id="username"
                                                type="text"
                                                autoComplete="User Name"
                                                placeholder={`email`}
                                                className={`${errors.username ? 'border-red-500 focus:border-red-500' : 'border-gray-300 focus:border-indigo-600'} shadow block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm`}
                                            />
                                            {
                                                errors.username &&
                                                <div className="flex text-xs text-red-700 p-1">
                                                    <HiOutlineExclamation size={16}
                                                                          className={'mr-2'}/> {errors.username?.message}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="password"
                                               className="block text-sm font-medium text-gray-700">Password</label>
                                        <div className="mt-1">
                                            <div className="relative flex">
                                                <input
                                                    {...register("password")}
                                                    id="password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    autoComplete="current-password"
                                                    placeholder={`* * * * * * * *`}
                                                    className={`${errors.username ? 'border-red-500 focus:border-red-500' : 'border-gray-300 focus:border-indigo-600'} shadow block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm`}
                                                />

                                                <span onClick={(e) => setShowPassword(prevState => !prevState)}
                                                      className="cursor-pointer absolute right-0 top-0 bottom-0 inline-flex items-center px-3 text-gray-500 sm:text-sm">
                                            {
                                                showPassword ? <HiOutlineEyeOff/> : <HiOutlineEye/>
                                            }
                                            </span>
                                            </div>
                                            {
                                                errors.password &&
                                                <div className="flex text-xs text-red-700 p-1">
                                                    <HiOutlineExclamation size={16}
                                                                          className={'mr-2'}/> {errors.password?.message}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                    </div>

                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <input
                                                id="remember-me"
                                                name="remember-me"
                                                type="checkbox"
                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            />
                                            <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">Remember
                                                me</label>
                                        </div>

                                        <div className="text-sm">
                                            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">Forgot
                                                your password ?</a>
                                        </div>
                                    </div>

                                    <div>
                                        <button type="submit"
                                                className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                            {
                                                spinner ? (<AiOutlineLoading3Quarters className={'mr-2 animate-spin'}
                                                                                      size={16}/>) : (
                                                    <HiOutlineLogin size={16} className={'mr-2'}/>)
                                            }
                                            Sign in
                                        </button>
                                    </div>
                                </form>

                                {/*<div className="mt-6">
                            <div className="relative">
                                <div className="absolute inset-0 flex items-center">
                                    <div className="w-full border-t border-gray-300"/>
                                </div>
                                <div className="relative flex justify-center text-sm">
                                    <span className="bg-white px-2 text-gray-500"> Or continue with</span>
                                </div>
                            </div>

                            <div className="mt-6 grid grid-cols-3 gap-3"></div>
                            <a href="#"
                               className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50">
                                <span className="sr-only"> Sign in with Facebook </span>
                                <HiEye></HiEye>
                            </a>
                        </div>

                        <div>
                            <a href="#"
                               className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50">
                                <span className="sr-only"> Sign in with Twitter </span>
                                <HiEye></HiEye>
                            </a>
                        </div>

                        <div>
                            <a href="#"
                               className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50">
                                <span className="sr-only"> Sign in with GitHub </span>
                                <HiEye></HiEye>
                            </a>
                        </div>*/}
                            </div>
                        </Grid.Col>
                        <Grid.Col span="auto">
                            <Tabs defaultValue="android">
                                <Tabs.List>
                                    <Tabs.Tab value="android" icon={<IconBrandGooglePlay size={14} />}>Android</Tabs.Tab>
                                    <Tabs.Tab value="ios" icon={<IconBrandAppstore size={14} />}>iOS</Tabs.Tab>
                                </Tabs.List>

                                <Tabs.Panel value="android" pt="xs">
                                    <Image src={`/assets/images/${process.env.REACT_APP_ANDROID_APP_QR_CODE}`} />
                                </Tabs.Panel>

                                <Tabs.Panel value="ios" pt="xs">
                                    <Image src={`/assets/images/${process.env.REACT_APP_IOS_APP_QR_CODE}`} />
                                </Tabs.Panel>
                            </Tabs>
                        </Grid.Col>

                    </Grid>

                </div>
            </div>

        </>
    )
}

export default Login