import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom";

import {
    HiEye,
    HiOutlineUserAdd,
    HiOutlineChevronDown,
    HiOutlineChat,
    HiOutlineTicket,
    HiOutlineDotsVertical,
    HiOutlineUsers

} from "react-icons/hi";

import { HiOutlineListBullet } from "react-icons/hi2";

import {AiOutlineLoading3Quarters} from "react-icons/ai";

import {useTranslation} from "react-i18next";
import { DataTable } from "mantine-datatable";
import { 
    Divider, ActionIcon, Box, Group, Text, ScrollArea, LoadingOverlay,
    Grid, Button, Loader, Stack, List, Indicator, HoverCard, Popover 
} from "@mantine/core";
import CrmDisputeModal from "./modals/CrmDisputeModal";
import DisputeThreadModal from "./modals/DisputeThreadModal";
import DisputeResolveModal from "./modals/DisputeResolveModal";
import { button } from "@material-tailwind/react";
import ViewClient from "../client/modal/ViewClient";
import ViewCandidate from "../candidate/modal/ViewCandidate";

const PER_PAGE = 20;
function Index(){
    const {innerHeight} = window;
    const {domainId, loggedUserId, roles, token} = JSON.parse(localStorage.getItem('user'));
    const {t, i18n} = useTranslation();
    const homeHeight = localStorage.getItem('homeHeight');
    const fullTableHeight = localStorage.getItem('fullTableHeight');
    const hasRoleModerator = roles.find(role => {
        return role === "ROLE_MODERATOR";
    });
    const hasRoleClient = roles.find(role => {
        return role === "ROLE_CLIENT";
    });
    const hasRoleCandidate = roles.find(role => {
        return role === "ROLE_CANDIDATE";
    });


    const [page, setPage] = useState(1);
    const [fetching, setFetching] = useState(true);
    const [showDisputeModal, setShowDisputeModal] = useState(false);
    const [showDisputeThreadModal, setShowDisputeThreadModal] = useState(false);
    const [showDisputeResolveModal, setShowDisputeResolveModal] = useState(false);
    const [disputeId, setDisputeId] = useState(false);
    const [activeTab, setActiveTab] = useState('time-sheet');
    const [records, setRecords] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [threadId, setThreadId] = useState(null);
    const [clientId, setClientId] = useState(null);
    const [candidateId, setCandidateId] = useState(null);
    const [showClientProfile, setShowClientProfile] = useState(false);
    const [showCandidateProfile, setShowCandidateProfile] = useState(false);
    const [totalUnreadComments, setTotalUnreadComments] = useState(100);

    const getDisputeList = (activeTab) => {
        // setFetching(true)
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/crm/dispute`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                type: activeTab,
                per_page: PER_PAGE,
                page,
                loggedUserId,
                roles
                // createdBy: hasRoleModerator ? null : loggedUserId
            }
        })
            .then(res => {
                setRecords(res.data.data);
                setTotalRecords(res.data.totalData);
                setFetching(false);
            })
            .catch(function (err) {
                console.log(err);

            });
    }


    useEffect(() => {
        getDisputeList(activeTab);
    }, [fetching])


    return(
        <main className="w-full relative">
            <div className="mx-auto px-6 bg-indigo-50 pb-4" >
                {
                    hasRoleModerator && (
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mb-2 pt-6">
                            <div className="overflow-hidden rounded-md bg-white hover:bg-indigo-100 relative">
                                <Link to={"/crm/register-client"} className="cursor-pointer">
                                    <div className="p-5">
                                        <div className="flex items-center">
                                            <div className="">
                                                <HiOutlineUserAdd
                                                    className="h-5 w-5 text-indigo-500 hover:text-indigo-400"
                                                    aria-hidden="true"/>
                                            </div>
                                            <div className="ml-5 w-0 flex-1">
                                                <dl>
                                                    <dt className="truncate text-sm font-medium text-gray-500">{t('NewClient')}</dt>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="overflow-hidden rounded-md bg-white hover:bg-indigo-100 relative">
                                <Link to={"/crm/register-candidate"} className="cursor-pointer">
                                    <div className="p-5">
                                        <div className="flex items-center">
                                            <div className="">
                                                <HiOutlineUserAdd
                                                    className="h-5 w-5 text-indigo-500 hover:text-indigo-400"
                                                    aria-hidden="true"/>
                                            </div>
                                            <div className="ml-5 w-0 flex-1">
                                                <dl>
                                                    <dt className="truncate text-sm font-medium text-gray-500">{t('NewCandidate')}</dt>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            {/* <div onClick={() => setShowDisputeModal(true)} className="overflow-hidden rounded-md bg-white hover:bg-indigo-100 relative cursor-pointer">
                                <div className="p-5">
                                    <div className="flex items-center">
                                        <div className="">
                                            <HiOutlineTicket className="h-5 w-5 text-indigo-500 hover:text-indigo-400" aria-hidden="true"/>
                                        </div>
                                        <div className="ml-5 w-0 flex-1">
                                            <dl>
                                                <dt className="truncate text-sm font-medium text-gray-500">{t('GenerateDispute')}</dt>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div> */}


                            <div className="overflow-hidden rounded-md bg-white hover:bg-indigo-100 relative">
                                <Link to={"client"} className="cursor-pointer">
                                    <div className="p-5">
                                        <div className="flex items-center">
                                            <div className="">
                                                <HiOutlineUsers
                                                    className="h-5 w-5 text-indigo-500 hover:text-indigo-400"
                                                    aria-hidden="true"/>
                                            </div>
                                            <div className="ml-5 w-0 flex-1">
                                                <dl>
                                                    <dt className="truncate text-sm font-medium text-gray-500">{t('Clients')}</dt>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="overflow-hidden rounded-md bg-white hover:bg-indigo-100 relative">
                                <Link to={"candidate"} className="cursor-pointer">
                                    <div className="p-5">
                                        <div className="flex items-center">
                                            <div className="">
                                                <HiOutlineUsers
                                                    className="h-5 w-5 text-indigo-500 hover:text-indigo-400"
                                                    aria-hidden="true"/>
                                            </div>
                                            <div className="ml-5 w-0 flex-1">
                                                <dl>
                                                    <dt className="truncate text-sm font-medium text-gray-500">{t('Candidates')}</dt>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>


                            {/* <div className="col-start-4 relative">
                                <div className="flex justify-end">
                                    <Popover width={295} position="right-end" shadow="md">
                                        <Popover.Target className='bg-white w-1/12'>
                                            <HiOutlineListBullet className="h-5 w-5 text-indigo-500 hover:text-indigo-400" aria-hidden="true"/>
                                        </Popover.Target>
                                        <Popover.Dropdown>
                                            <List size="xs" spacing={5}>
                                                <List.Item>
                                                    <Link to={"client"} className="cursor-pointer block">{t('Clients')}</Link>
                                                </List.Item>
                                                <Divider my={10} />
                                                <List.Item>
                                                    <Link to={"#"} className="cursor-pointer">{t('Candidates')}</Link>
                                                </List.Item>
                                            </List>
                                        </Popover.Dropdown>
                                    </Popover>
                                </div>
                            </div> */}
                        </div>
                    )
                }
                <div className="w-full pt-5">
                    <Grid align="center" gutter={5}>
                        <Grid.Col span={2}>
                            <Button
                                type="button"
                                // leftIcon={fetching && activeTab === "time-sheet" && <Loader size="xs" color="indigo"/>}
                                className="w-full text-gray-900 rounded group relative min-w-0 flex-1 overflow-hidden bg-white py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                aria-current="page"
                                onClick={() => {
                                    setActiveTab('time-sheet')
                                    setFetching(true)
                                }}
                            >
                                {t('TimeSheet')}
                                {activeTab === 'time-sheet' && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5" />}

                            </Button>
                        </Grid.Col>
                        {
                            (hasRoleClient || hasRoleModerator) && (
                                <Grid.Col span={2}>
                                    <Button
                                        type="button"
                                        // leftIcon={fetching && activeTab === "invoice" && <Loader size="xs" color="indigo"/>}
                                        className="w-full text-gray-900 rounded group relative min-w-0 flex-1 overflow-hidden bg-white py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                        aria-current="page"
                                        onClick={() => {
                                            setActiveTab('invoice')
                                            setFetching(true)

                                        }}
                                    >
                                        {t('Invoice')}
                                        {activeTab === 'invoice' && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5" />}

                                    </Button>
                                </Grid.Col>
                            )
                        }
                        
                        {
                            (hasRoleCandidate || hasRoleModerator) && (
                                <Grid.Col span={2}>
                                    <Button
                                        type="button"
                                        // leftIcon={fetching && activeTab === "payroll" && <Loader size="xs" color="indigo"/>}
                                        className="w-full text-gray-900 rounded group relative min-w-0 flex-1 overflow-hidden bg-white py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                        aria-current="page"
                                        onClick={() => {
                                            setActiveTab('payroll')
                                            setFetching(true)
                                        }}
                                    >
                                        {t('Payroll')}
                                        {activeTab === 'payroll' && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5" />}

                                    </Button>
                                </Grid.Col>
                            )
                        }
                    </Grid>
                    <Box h={hasRoleModerator ? (innerHeight - 200) : (innerHeight - 105)}>
                        <DataTable
                            withBorder
                            records={records}
                            striped
                            styles={(theme) => ({
                                root: {
                                    border: '1px solid #e0e7ff',
                                    borderRadius: 2,
                                },
                                header: {
                                    background: '#e0e7ff',
                                    backgroundColor: "#e0e7ff",
                                    color: theme.colors.indigo[6],
                                },
                                pagination: {
                                    color: theme.colors.gray[3],
                                    paddingTop: "5px",
                                    paddingBttom: "5px"
                                },
                                item: {
                                    '&[data-active]': {
                                        backgroundImage: theme.fn.gradient({ from: 'red', to: 'yellow' }),
                                    },
                                },
                            })}
                            columns={[
                                {
                                    accessor: '',
                                    title: (t('No.')),
                                    width: 45,
                                    render: (item) => (records.indexOf(item) + 1) + PER_PAGE * (page - 1)
                                },
                                {
                                    accessor: 'disputeNo',
                                    title: (t('disputeNo')),
                                },
                                {
                                    accessor: 'clientName',
                                    title: (t('ClientName')),
                                    hidden: (!!hasRoleCandidate && activeTab === 'payroll') || 
                                    (!!hasRoleClient) || 
                                    ( hasRoleModerator && activeTab === 'payroll'),
                                    render: (record) => <Text className="cursor-pointer" onClick={(e) => {e.stopPropagation(); setClientId(record['clientId']); setShowClientProfile(true)}}>{record['clientName']}<span className="ml-2 inline-flex items-center rounded bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800">{t('Details')}</span></Text>,


                                },
                                {
                                    accessor: 'candidateName',
                                    title: (t('CandidateName')),
                                    hidden:  (!!hasRoleClient && activeTab === 'invoice') || 
                                    (!!hasRoleCandidate) || 
                                    ( hasRoleModerator && activeTab === 'invoice'),
                                    render: (record) => <Text className="cursor-pointer" onClick={(e) => {e.stopPropagation(); setCandidateId(record['candidateId']); setShowCandidateProfile(true)}}>{record['candidateName']}<span className="ml-2 inline-flex items-center rounded bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800">{t('Details')}</span></Text>,

                                },
                                // {
                                //     accessor: 'type',
                                //     title: (t('disputeFrom')),
                                //     render: ({type}) => type[0].toUpperCase() + type.slice(1)
                                // },
                                {
                                    accessor: 'createdAt',
                                    title: (t('disputeDate')),
                                },
                                {
                                    accessor: 'status',
                                    title: (t('status')),
                                    render: ({status}) => status && <span className={`${ status === 'complete' ? 'bg-green-500' : ''} ${status === 'in-progress' ? 'bg-yellow-500' : ''} inline-flex items-center rounded px-1 py-0.5 text-xs font-medium text-black w-20 justify-center`}>{status[0].toUpperCase() + status.slice(1)}</span>
                                },
                                {
                                    accessor: '',
                                    width: 10,
                                    render: (record) => (
                                        <Indicator className="z-0" position="top-start" withBorder processing size={20} color="red" label={record['totalUnreadComments']} disabled={record['totalUnreadComments'] <= 0 || (!showDisputeThreadModal && record['threadId'] === threadId)}>
                                            <button 
                                                type="button"
                                                className="px-1 py-1 text-indigo-900 inline-flex focus:ring-indigo-200 transition duration-150 ease-in-out hover:text-white hover:bg-indigo-700 bg-indigo-200 items-center border rounded text-xs"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setThreadId(record['threadId']);
                                                    setShowDisputeThreadModal(true)
                                                }}
                                            >
                                                <HiOutlineChat size={16}/>
                                            </button>
                                        </Indicator>
                                    )
                                },
                                {
                                    accessor: 'actions',
                                    title: (t('Action')),
                                    textAlignment: 'right',
                                    render: (item) => (
                                        <Group spacing={4} position="right" noWrap>
                                            <ActionIcon color="indigo">
                                                <HiOutlineChevronDown size={16}/>
                                            </ActionIcon>
                                        </Group>
                                    ),
                                },

                            ]}
                            onCellClick={(e) => console.log(e)}
                            rowExpansion={{
                                content: ({record}) => (
                                    <Stack p="xs" className="bg-indigo-100">
                                        <Grid>
                                            <Grid.Col span="auto">
                                                <Text><span className="font-semibold">{t('Type')}:</span> {record['type'] && record['type'][0].toUpperCase() + record['type'].slice(1)}</Text>
                                                {/* <Text>{t('ClientName')}: {record['clientName']}</Text> */}
                                                {/* <Text>{t('CandidateName')}: {record['candidateName']}</Text> */}
                                                { 'time-sheet' === activeTab &&
                                                <Text><span className="font-semibold">{t('Job')}:</span> {record['jobTitle']}</Text>
                                                }
                                            </Grid.Col>
                                            <Grid.Col span="auto">
                                                {/*<Text>{t('Position')}: {record['positionName']}</Text>*/}
                                                {'time-sheet' === activeTab && (
                                                    <>
                                                        <Text><span className="font-semibold">{t('Position')}:</span> {record['positionName']}</Text>
                                                        <Text><span className="font-semibold">{t('WorkingDate')}:</span> {record['workingDate']}</Text>
                                                    </>
                                                )
                                                }
                                            </Grid.Col>
                                            {
                                                ['invoice', 'payroll'].some(flag => flag === activeTab) &&
                                                    <Grid.Col span="auto">
                                                        {'invoice' === activeTab ? (
                                                            <>
                                                                <Text><span className="font-semibold">{t('InvoiceNo')}:</span> {record['invoiceNo']}</Text>
                                                                <Text><span className="font-semibold">{t('InvoiceDate')}:</span> {record['invoiceDate']}</Text>
                                                            </>
                                                        ) : ''}
                                                        {'payroll' === activeTab ? (
                                                            <>
                                                                <Text><span className="font-semibold">{t('PayrollNo')}:</span> {record['payrollNo']}</Text>
                                                                <Text><span className="font-semibold">{t('PayrollDate')}:</span> {record['payrollDate']}</Text>
                                                            </>
                                                        ) : ''}
                                                    </Grid.Col>
                                            }
                                            
                                            {
                                                'time-sheet' === activeTab && 
                                                <>
                                                    <Grid.Col span="auto">
                                                        <Text><span className="font-semibold">{t('InTime')}:</span> {record['inTime']}</Text>
                                                        <Text><span className="font-semibold">{t('OutTime')}:</span> {record['outTime']}</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span="auto">
                                                        <Text><span className="font-semibold">{t('BreakDuration')}:</span> {record['breakDuration']}</Text>
                                                        {/*<Text>{t('Hour')}: {record['totalHour']}</Text>*/}
                                                    </Grid.Col>
                                                </>
                                            }
                                            <Grid.Col span="auto" className="flex items-center justify-end">
                                                <Group spacing={1} position="right" noWrap>
                                                    { hasRoleModerator && 'complete' !== record['status'] && (
                                                        <button 
                                                            type="button"
                                                            className="px-1 py-1 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-xs"
                                                            onClick={() => {setShowDisputeResolveModal(true); setDisputeId(record['id'])}}
                                                        >{t('Dismiss')}
                                                        </button>
                                                    )}

                                                    
                                                </Group>
                                            </Grid.Col>
                                            
                                        </Grid>
                                        <Text><span className="font-semibold">{t('Details')}:</span> {record['details']}</Text>
                                        { 'complete' === record['status'] && <Text><span className="font-semibold">{t('Remark')}:</span> {record['remark']}</Text> }
                                    </Stack>
                                )
                            }}
                            totalRecords={totalRecords}
                            recordsPerPage={PER_PAGE}
                            page={page}
                            onPageChange={(p) => {
                                setPage(p)
                                setFetching(true)
                            }}
                            fetching={fetching}
                            loaderSize="sm"
                            loaderColor="indigo"
                            loaderBackgroundBlur={2}
                        />
                    </Box>
                </div>
            </div>
            {/* { showDisputeModal && <CrmDisputeModal setShowModal={setShowDisputeModal} setRefreshList={setFetching} />} */}
            { showDisputeThreadModal && threadId && <DisputeThreadModal setShowModal={setShowDisputeThreadModal} setRefreshList={setFetching} threadId={threadId} />}
            { showDisputeResolveModal && <DisputeResolveModal setShowModal={setShowDisputeResolveModal} setRefreshList={setFetching} disputeId={disputeId} />}
            { clientId && showClientProfile && <ViewClient setShowModal={setShowClientProfile} clientID={clientId} /> }
            { candidateId && showCandidateProfile && <ViewCandidate setShowModal={setShowCandidateProfile} candidateid={candidateId} /> }


        </main>
    )
}

export default Index