import React, { useState } from "react";
import {
    HiOutlineOfficeBuilding,
    HiOutlinePlus,
} from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { Grid, useMantineTheme } from "@mantine/core";

import RunningView from "./inc/RunningView";
import ProcessingView from "./inc/ProcessingView";
import CompleteView from "./inc/CompleteView";

function ClientView() {
    const { t, i18n } = useTranslation();

    const theme = useMantineTheme();
    const [activeTab, setActiveTab] = useState('running');

    return (
        <main className="w-full">
            <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-between text-gray-600 mb-1 items-center">
                <div className={"flex-none w-44"}>
                    <div className="flex text-gray-800 h-full tracking-normal leading-tight">
                        <HiOutlineOfficeBuilding size={24} className={'mr-2 text-indigo-300'} />
                        <span className={'font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                            {t('TimeSheet')}
                            <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('TimeSheetDetails')}</sub></span>
                    </div>
                </div>
                <div className="flex-auto w-full">
                    <Grid align="center">
                        <Grid.Col xs={4} sm={4} md={8} lg={8}>
                            <nav className="isolate flex divide-x divide-gray-200 rounded-t-lg shadow" aria-label="Tabs">
                                <button
                                    className="text-gray-900 rounded group relative min-w-0 flex-1 overflow-hidden bg-gray-100 py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                    aria-current="page"
                                    onClick={() => {
                                        setActiveTab('running');
                                    }}
                                >
                                    <span>{t('Running')}</span>
                                    {'running' === activeTab && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5" />}

                                </button>
                                <button
                                    className="text-gray-900 rounded group relative min-w-0 flex-1 overflow-hidden bg-gray-100 py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                    aria-current="page"
                                    onClick={() => {
                                        setActiveTab('processing');
                                    }}
                                >
                                    <span>{t('Processing')}</span>
                                    {'processing' === activeTab && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5" />}

                                </button>
                                <button
                                    className="text-gray-900 rounded group relative min-w-0 flex-1 overflow-hidden bg-gray-100 py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                    aria-current="page"
                                    onClick={() => {
                                        setActiveTab('complete');
                                    }}
                                >
                                    <span>{t('Complete')}</span>
                                    {'complete' === activeTab && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5" />}

                                </button>
                            </nav>
                        </Grid.Col>
                        <Grid.Col xs={4} sm={4}>
                        </Grid.Col>
                    </Grid>
                </div>
            </div>

            <div className="w-full px-1">
                {'running' === activeTab && <RunningView />}
                {'processing' === activeTab && <ProcessingView />}
                {'complete' === activeTab && <CompleteView />}
            </div>
        </main>

    );

}
export default ClientView