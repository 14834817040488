import {Document, Page, PDFViewer, Text, View, Image} from "@react-pdf/renderer";
import styles from "../../../pdf-styles";
import {Grid, ScrollArea} from "@mantine/core";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {HiChevronLeft, HiEye, HiOutlineOfficeBuilding} from "react-icons/hi";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import axios from "axios";
import {useLayoutEffect} from "@radix-ui/react-use-layout-effect";
import {useLocation} from "react-router";


function InvoiceSummeryPdf() {

    const {domainId, loggedUserId ,roles, token} = JSON.parse(localStorage.getItem('user'));
    let location = useLocation();
    const paramsState = location.state;

    const viewMode = paramsState ? paramsState.viewMode : '';

    const {invoiceId} = useParams();
    const {t, i18n} = useTranslation();
    const fullTableHeight = localStorage.getItem('fullTableHeight');
    const [invoice, setInvoice] = useState({});
    const [invoiceDetail, setInvoiceDetail] = useState({});

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/invoice/${invoiceId}/summery`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                if (res.status === 200){
                    setInvoice(res.data.data.invoiceInfo)
                    setInvoiceDetail(res.data.data)
                }
            });
    }, [])


    const convertSecondsToHourMin = (seconds) => {

        const totalMinutes = Math.floor(seconds / 60);

        const hours = Math.floor(totalMinutes / 60);
        const hourLength = hours.toString().length;

        const minutes = totalMinutes % 60;
        const minLength = minutes.toString().length;

        const hour = hourLength<2?'0'+hours:hours;
        const minute = minLength<2?'0'+minutes:minutes;

        return `${hour}:${minute}`
    }

    const convertSecondsToDecimal = (seconds) => {

        const totalMinutes = seconds / 60;

        const hours = totalMinutes/60;


        return hours.toFixed(2)
    }

    var lineTotalAmount = invoice.subTotalAmount?invoice.subTotalAmount:0;
    var lineTotalAmountDecimalHour = invoice.subTotalDecimalHourAmount && invoice.subTotalDecimalHourAmount>0?invoice.subTotalDecimalHourAmount:0;
    var taxRate = invoice.taxRate?invoice.taxRate:0;
    var taxAmount = ((lineTotalAmount*taxRate)/100).toFixed(2);
    var taxAmountDecimalHour = ((lineTotalAmountDecimalHour*taxRate)/100).toFixed(2);

    var grandTotalSecond = 0;
    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Invoice')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>
                                    {t('InvoiceInformation')}
                                </sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <Link to={'/invoice/'+invoice.id+'/summery'}>
                                    <button
                                        className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    >
                                        <HiEye size={16} className={'mr-1'}/>{t('ViewInvoice')}
                                    </button>
                                </Link>
                                <Link to='/invoice'
                                      className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={12} className={'mr-1'}/>{t('Back')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <PDFViewer
                    showToolbar={true}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Document>
                        <Page orientation='p' size='A4' style={styles.page}>

                            <View style={[styles.width_100, {width:'100%', display: 'flex', clear: 'both', flexDirection: "row"}]}>
                                <View style={styles.section_left}>
                                    <View>
                                        <View>
                                            <Text>
                                                <Image style={styles.image} src={"/assets/images/simple-staffing.png"} alt="Simple Staffing" />
                                                {/*<img src={"/assets/images/simple-staffing.png"} alt="Simple Staffing" />*/}
                                            </Text>
                                        </View>
                                        <View>
                                            <Text style={[styles.text_12, {marginBottom:'5px'}]}>SIMPLE STAFFING USA LLC</Text>
                                        </View>
                                        <View>
                                            <Text style={styles.text_10}>
                                                3235 Satellite Blvd. Building 400
                                            </Text>
                                            <Text style={styles.text_10}>Suite 300 Duluth, GA</Text>
                                            <Text style={styles.text_10}>{t('Phone')}: 1.888.902.7759</Text>
                                            <Text style={styles.text_10}>{t('Email')}: billing@simplestaffingllc.com</Text>
                                        </View>
                                    </View>

                                    <View>
                                        <View style={[{ marginTop: '15px' }]}>
                                            <Text style={[styles.text_12, {marginBottom:'5px'}]}>BILL TO:</Text>
                                            <Text style={[styles.text_12, {marginBottom:'5px'}]}>{invoice.clientName}</Text>
                                        </View>
                                        <View>
                                            <Text style={styles.text_10}>
                                                {invoice.clientAddress}
                                            </Text>
                                            <Text style={styles.text_10}>
                                                Phone: {invoice.clientPhone}
                                            </Text>
                                            <Text style={styles.text_10}>
                                                Email: {invoice.clientEmail}
                                            </Text>
                                        </View>
                                    </View>

                                </View>
                                <View style={styles.section_right}>
                                    <View>
                                        <View style={styles.text_16}>
                                            <Text style={[styles.textAlignCenter, styles.text_16, {marginBottom: '10px', color: 'red'}]}>
                                                {t('INVOICE')}
                                            </Text>
                                        </View>
                                        <View style={[styles.tableNoBorder, styles.table_100]}>
                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableColNoBorder, styles.width_40]}>
                                                    <Text style={[styles.tableCell, styles.textAlignRight]}>{t('INVOICE NO')} : </Text>
                                                </View>
                                                <View style={[styles.tableColNoBorder, styles.width_60]}>
                                                    <Text style={[styles.tableCell, styles.textAlignLeft]}>{invoice.invoiceId}</Text>
                                                </View>
                                            </View>

                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableColNoBorder, styles.width_40]}>
                                                    <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                        {t('Date')} :
                                                    </Text>
                                                </View>
                                                <View style={[styles.tableColNoBorder, styles.width_60]}>
                                                    <Text style={[styles.tableCell, styles.textAlignLeft]}>
                                                        {invoice.invoiceDate ? format(new Date(invoice.invoiceDate.date), 'MMMM dd, Y') : ''}
                                                    </Text>
                                                </View>
                                            </View>

                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableColNoBorder, styles.width_40]}>
                                                    <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                        {t('InvoicePeriod')} :
                                                    </Text>
                                                </View>
                                                <View style={[styles.tableColNoBorder, styles.width_60]}>
                                                    <Text style={[styles.tableCell, styles.textAlignLeft]}>
                                                        {invoice.startTime ? format(new Date(invoice.startTime.date), 'MMM dd, Y') : ''} To  {invoice.endTime ? format(new Date(invoice.endTime.date), 'MMM dd, Y') : ''}
                                                    </Text>
                                                </View>
                                            </View>

                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableColNoBorder, styles.width_40]}>
                                                    <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                        {t('InvoiceDueDate')} :
                                                    </Text>
                                                </View>
                                                <View style={[styles.tableColNoBorder, styles.width_60]}>
                                                    <Text style={[styles.tableCell, styles.textAlignLeft]}>
                                                        {invoice.dueDate ? format(new Date(invoice.dueDate.date), 'MMMM dd, Y') : ''}
                                                    </Text>
                                                </View>
                                            </View>

                                        </View>
                                    </View>
                                </View>
                            </View>
                            { viewMode === 'decimal' &&
                                <View style={[styles.width_100, {display: 'block', clear: 'both', marginTop: '20px'}]}>
                                    <View style={[styles.table, styles.width_100, styles.textAlignLeft]}>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_12, styles.colPositionRelative]}>
                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Date')}</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_90]}>

                                                <View style={[styles.tableRow, styles.width_100]}>


                                                    <View style={[styles.tableCol, styles.width_16, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('JobTitle')}</Text>*/}
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_10, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Designation')}</Text>*/}
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('RegularRate')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('OvertimeRate')}</Text>
                                                    </View>


                                                    <View style={[styles.tableCol, styles.width_8, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Total Hours')}</Text>*/}
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Regular')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Overtime')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder, styles.tableColRightNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('TotalAmount')}</Text>*/}
                                                    </View>
                                                </View>

                                                <View style={[styles.tableRow, styles.width_100]}>
                                                    <View style={[styles.tableCol, styles.width_16, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('JobDescription')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_10, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('NumberOfJob')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    {/*<View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>*/}
                                                    <View style={[styles.tableCol, styles.width_14, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    {/*<View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>*/}

                                                    <View style={[styles.tableCol, styles.width_8, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Total Hours')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    {/*<View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>*/}
                                                    <View style={[styles.tableCol, styles.width_14, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    {/*<View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>*/}

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder, styles.tableColRightNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('TotalAmount')}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>


                                        {invoiceDetail && invoiceDetail.details && Object.keys(invoiceDetail.details).map((jobId) => {
                                            {var candidateWiseCount = 0}
                                            {var jobWiseTotalSeconds = 0}
                                            {var jobWiseTotalAmount = 0}

                                            return (
                                                <Fragment key={jobId}>
                                                    <View style={[styles.tableRow, styles.width_100]}>
                                                        <View style={[styles.tableCol, styles.width_12]}>
                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('EventName')}</Text>
                                                        </View>
                                                        <View style={[styles.tableCol, styles.width_25]}>
                                                            <Text style={[styles.tableCell, styles.textAlignLeft]}>
                                                                {invoiceDetail.jobDetails && invoiceDetail.jobDetails[jobId]? invoiceDetail.jobDetails[jobId].jobTitle:''}
                                                            </Text>
                                                        </View>

                                                        <View style={[styles.tableCol, styles.width_15]}>
                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Location')}</Text>
                                                        </View>

                                                        <View style={[styles.tableCol, styles.width_25]}>
                                                            <Text style={[styles.tableCell, styles.textAlignLeft]}>
                                                                {invoiceDetail.jobDetails && invoiceDetail.jobDetails[jobId]? invoiceDetail.jobDetails[jobId].jobLocation:''}
                                                            </Text>
                                                        </View>

                                                        <View style={[styles.tableCol, styles.width_10]}>
                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Job')} #</Text>
                                                        </View>

                                                        <View style={[styles.tableCol, styles.width_15]}>
                                                            <Text style={[styles.tableCell, styles.textAlignLeft]}>

                                                            </Text>
                                                        </View>

                                                    </View>

                                                    {invoiceDetail.details[jobId] && Object.keys(invoiceDetail.details[jobId]).map((checkInTime, index)=> {
                                                        {var dateWiseCount = 0}
                                                        {var dataLength = invoiceDetail && invoiceDetail.details && invoiceDetail.details[jobId] && Object.values(invoiceDetail.details[jobId][checkInTime]) ? Object.values(invoiceDetail.details[jobId][checkInTime]).length:1}
                                                        return (
                                                            <Fragment key={index}>
                                                                <View style={[styles.tableRow]}>
                                                                    <View style={[styles.tableCol, styles.width_12, styles.colPositionRelative]}>

                                                                        <Text style={[styles.tableCell, styles.textAlignCenter, dataLength>1? styles.colPositionAbsolute:styles.colPositionRelative, dataLength>1?styles.colPositionTop35:'']}>

                                                                            {checkInTime}
                                                                        </Text>
                                                                    </View>
                                                                    <View style={[styles.tableCol, styles.width_90]}>

                                                                        {invoiceDetail.details[jobId][checkInTime] && Object.values(invoiceDetail.details[jobId][checkInTime]).map((element, designationId)=>{
                                                                            { dateWiseCount = dateWiseCount+1}
                                                                            { jobWiseTotalSeconds = jobWiseTotalSeconds + parseFloat(element.totalSecond)}
                                                                            { jobWiseTotalAmount = jobWiseTotalAmount + parseFloat(element.totalAmountDecimalHour)}
                                                                            { grandTotalSecond = grandTotalSecond + parseFloat(element.totalSecond)}
                                                                            return(
                                                                                <Fragment key={designationId}>
                                                                                    <View key={designationId} style={[styles.tableRow, styles.width_100]}>


                                                                                        <View style={[styles.tableCol, styles.width_16, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.designationName}
                                                                                            </Text>
                                                                                        </View>
                                                                                        <View style={[styles.tableCol, styles.width_10, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.noOfItems}
                                                                                            </Text>
                                                                                        </View>

                                                                                        <View style={[styles.tableCol, styles.width_14, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.regularHourRate}
                                                                                            </Text>
                                                                                        </View>
                                                                                        {/*<View style={[styles.tableCol, styles.width_7, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.regularMinRate}
                                                                                            </Text>
                                                                                        </View>*/}
                                                                                        <View style={[styles.tableCol, styles.width_14, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.overtimeHourRate}
                                                                                            </Text>
                                                                                        </View>
                                                                                        {/*<View style={[styles.tableCol, styles.width_7, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.overtimeMinRate}
                                                                                            </Text>
                                                                                        </View>*/}

                                                                                        <View style={[styles.tableCol, styles.width_8, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {/*{convertSecondsToHourMin(parseFloat(element.totalSecond))}*/}
                                                                                                {element.minuteFormatToHourFormat}
                                                                                            </Text>
                                                                                        </View>

                                                                                        <View style={[styles.tableCol, styles.width_14, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.regularHourMinToDecimal}
                                                                                            </Text>
                                                                                        </View>
                                                                                        {/*<View style={[styles.tableCol, styles.width_7, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.totalRegularMin}
                                                                                            </Text>
                                                                                        </View>*/}
                                                                                        <View style={[styles.tableCol, styles.width_14, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.overtimeHourMinToDecimal}
                                                                                            </Text>
                                                                                        </View>
                                                                                       {/*<View style={[styles.tableCol, styles.width_7, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.totalOvertimeMin}
                                                                                            </Text>
                                                                                        </View>*/}

                                                                                        <View style={[styles.tableCol, styles.width_12, styles.tableColRightNoBorder, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                                                                {element.totalAmountDecimalHour?parseFloat(element.totalAmountDecimalHour).toFixed(2):'0.00'}
                                                                                            </Text>
                                                                                        </View>
                                                                                    </View>


                                                                                </Fragment>
                                                                            )
                                                                        })}

                                                                    </View>
                                                                </View>
                                                            </Fragment>
                                                        )

                                                    })}


                                                    <View style={[styles.tableRow, styles.width_100]}>
                                                        <View style={[styles.tableCol, styles.width_61]}>
                                                            <Text style={[styles.tableCell, styles.textAlignRight]}>{t('Hours')}</Text>
                                                        </View>
                                                        <View style={[styles.tableCol, styles.width_7]}>
                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                {convertSecondsToDecimal(jobWiseTotalSeconds)}
                                                            </Text>
                                                        </View>

                                                        <View style={[styles.tableCol, styles.width_25]}>
                                                            <Text style={[styles.tableCell, styles.textAlignRight]}>{t('Amount')}</Text>
                                                        </View>

                                                        <View style={[styles.tableCol, styles.width_11]}>
                                                            <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                                {jobWiseTotalAmount.toFixed(2)}
                                                            </Text>
                                                        </View>


                                                    </View>
                                                </Fragment>
                                            )

                                        })}

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_61, {borderWidth: '1px'}]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('TotalInvoiceHours')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_7]}>
                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                    {convertSecondsToDecimal(grandTotalSecond)}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_25, {borderWidth: '1px'}]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('TotalInvoiceAmount')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_11]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {lineTotalAmountDecimalHour.toFixed(2)}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_92]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('Tax')} ({invoice.taxRate?invoice.taxRate:0}%)
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_11]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    $ {taxAmountDecimalHour}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_92]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('Total')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_11]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    $ {(lineTotalAmountDecimalHour+Number(taxAmountDecimalHour)).toFixed(2)}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_92]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('PaymentMade')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_11]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight, {color:'red'}]}>
                                                    (-) $ {invoice.totalPaymentAmount}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_92]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('BalanceDue')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_11]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    $ {((lineTotalAmountDecimalHour+Number(taxAmountDecimalHour))-invoice.totalPaymentAmount).toFixed(2)}
                                                </Text>
                                            </View>
                                        </View>

                                    </View>
                                </View>
                            }


                            { viewMode === 'hour' &&
                                <View style={[styles.width_100, {display: 'block', clear: 'both', marginTop: '20px'}]}>
                                    <View style={[styles.table, styles.width_100, styles.textAlignLeft]}>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_12, styles.colPositionRelative]}>
                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Date')}</Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_90]}>

                                                <View style={[styles.tableRow, styles.width_100]}>


                                                    <View style={[styles.tableCol, styles.width_16, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('JobTitle')}</Text>*/}
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_10, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Designation')}</Text>*/}
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('RegularRate')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('OvertimeRate')}</Text>
                                                    </View>


                                                    <View style={[styles.tableCol, styles.width_8, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Total Hours')}</Text>*/}
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Regular')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Overtime')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder, styles.tableColRightNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('TotalAmount')}</Text>*/}
                                                    </View>
                                                </View>

                                                <View style={[styles.tableRow, styles.width_100]}>
                                                    <View style={[styles.tableCol, styles.width_16, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('JobDescription')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_10, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('NumberOfJob')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_8, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Total Hours')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder, styles.tableColRightNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('TotalAmount')}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>


                                        {invoiceDetail && invoiceDetail.details && Object.keys(invoiceDetail.details).map((jobId) => {
                                            {var candidateWiseCount = 0}
                                            {var jobWiseTotalSeconds = 0}
                                            {var jobWiseTotalAmount = 0}

                                            return (
                                                <>
                                                    <View style={[styles.tableRow, styles.width_100]}>
                                                        <View style={[styles.tableCol, styles.width_12]}>
                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('EventName')}</Text>
                                                        </View>
                                                        <View style={[styles.tableCol, styles.width_25]}>
                                                            <Text style={[styles.tableCell, styles.textAlignLeft]}>
                                                                {invoiceDetail.jobDetails && invoiceDetail.jobDetails[jobId]? invoiceDetail.jobDetails[jobId].jobTitle:''}
                                                            </Text>
                                                        </View>

                                                        <View style={[styles.tableCol, styles.width_15]}>
                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Location')}</Text>
                                                        </View>

                                                        <View style={[styles.tableCol, styles.width_25]}>
                                                            <Text style={[styles.tableCell, styles.textAlignLeft]}>
                                                                {invoiceDetail.jobDetails && invoiceDetail.jobDetails[jobId]? invoiceDetail.jobDetails[jobId].jobLocation:''}
                                                            </Text>
                                                        </View>

                                                        <View style={[styles.tableCol, styles.width_10]}>
                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Job')} #</Text>
                                                        </View>

                                                        <View style={[styles.tableCol, styles.width_15]}>
                                                            <Text style={[styles.tableCell, styles.textAlignLeft]}>

                                                            </Text>
                                                        </View>

                                                    </View>

                                                    {invoiceDetail.details[jobId] && Object.keys(invoiceDetail.details[jobId]).map((checkInTime)=> {
                                                        {var dateWiseCount = 0}
                                                        {var dataLength = invoiceDetail && invoiceDetail.details && invoiceDetail.details[jobId] && Object.values(invoiceDetail.details[jobId][checkInTime]) ? Object.values(invoiceDetail.details[jobId][checkInTime]).length:1}
                                                        return (
                                                            <>
                                                                <View style={[styles.tableRow]}>
                                                                    <View style={[styles.tableCol, styles.width_12, styles.colPositionRelative]}>

                                                                        <Text style={[styles.tableCell, styles.textAlignCenter, dataLength>1? styles.colPositionAbsolute:styles.colPositionRelative, dataLength>1?styles.colPositionTop35:'']}>

                                                                            {checkInTime}
                                                                        </Text>
                                                                    </View>
                                                                    <View style={[styles.tableCol, styles.width_90]}>

                                                                        {invoiceDetail.details[jobId][checkInTime] && Object.values(invoiceDetail.details[jobId][checkInTime]).map((element, designationId)=>{
                                                                            { dateWiseCount = dateWiseCount+1}
                                                                            { jobWiseTotalSeconds = jobWiseTotalSeconds + parseFloat(element.totalSecond)}
                                                                            { jobWiseTotalAmount = jobWiseTotalAmount + parseFloat(element.totalAmount)}
                                                                            { grandTotalSecond = grandTotalSecond + parseFloat(element.totalSecond)}
                                                                            return(
                                                                                <>
                                                                                    <View style={[styles.tableRow, styles.width_100]}>


                                                                                        <View style={[styles.tableCol, styles.width_16, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.designationName}
                                                                                            </Text>
                                                                                        </View>
                                                                                        <View style={[styles.tableCol, styles.width_10, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.noOfItems}
                                                                                            </Text>
                                                                                        </View>

                                                                                        <View style={[styles.tableCol, styles.width_7, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.regularHourRate}
                                                                                            </Text>
                                                                                        </View>
                                                                                        <View style={[styles.tableCol, styles.width_7, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.regularMinRate}
                                                                                            </Text>
                                                                                        </View>
                                                                                        <View style={[styles.tableCol, styles.width_7, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.overtimeHourRate}
                                                                                            </Text>
                                                                                        </View>
                                                                                        <View style={[styles.tableCol, styles.width_7, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.overtimeMinRate}
                                                                                            </Text>
                                                                                        </View>

                                                                                        <View style={[styles.tableCol, styles.width_8, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {convertSecondsToHourMin(parseFloat(element.totalSecond))}
                                                                                            </Text>
                                                                                        </View>

                                                                                        <View style={[styles.tableCol, styles.width_7, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.totalRegularHour}
                                                                                            </Text>
                                                                                        </View>
                                                                                        <View style={[styles.tableCol, styles.width_7, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.totalRegularMin}
                                                                                            </Text>
                                                                                        </View>
                                                                                        <View style={[styles.tableCol, styles.width_7, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.totalOvertimeHour}
                                                                                            </Text>
                                                                                        </View>
                                                                                        <View style={[styles.tableCol, styles.width_7, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                                {element.totalOvertimeMin}
                                                                                            </Text>
                                                                                        </View>

                                                                                        <View style={[styles.tableCol, styles.width_12, styles.tableColRightNoBorder, dataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                            <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                                                                {element.totalAmount?parseFloat(element.totalAmount).toFixed(2):''}
                                                                                            </Text>
                                                                                        </View>
                                                                                    </View>


                                                                                </>
                                                                            )
                                                                        })}

                                                                    </View>
                                                                </View>
                                                            </>
                                                        )

                                                    })}


                                                    <View style={[styles.tableRow, styles.width_100]}>
                                                        <View style={[styles.tableCol, styles.width_61]}>
                                                            <Text style={[styles.tableCell, styles.textAlignRight]}>{t('Hours')}</Text>
                                                        </View>
                                                        <View style={[styles.tableCol, styles.width_7]}>
                                                            <Text style={[styles.tableCell, styles.textAlignLeft]}>
                                                                {convertSecondsToHourMin(jobWiseTotalSeconds)}
                                                            </Text>
                                                        </View>

                                                        <View style={[styles.tableCol, styles.width_25]}>
                                                            <Text style={[styles.tableCell, styles.textAlignRight]}>{t('Amount')}</Text>
                                                        </View>

                                                        <View style={[styles.tableCol, styles.width_11]}>
                                                            <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                                {jobWiseTotalAmount.toFixed(2)}
                                                            </Text>
                                                        </View>


                                                    </View>
                                                </>
                                            )

                                        })}





                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_61, {borderWidth: '1px'}]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('TotalInvoiceHours')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_7]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {convertSecondsToHourMin(grandTotalSecond)}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_25, {borderWidth: '1px'}]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('TotalInvoiceAmount')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_11]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {lineTotalAmount.toFixed(2)}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_92]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('Tax')} ({invoice.taxRate?invoice.taxRate:0}%)
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_11]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    $ {taxAmount}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_92]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('Total')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_11]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    $ {(lineTotalAmount+Number(taxAmount)).toFixed(2)}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_92]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('PaymentMade')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_11]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight, {color:'red'}]}>
                                                    (-) $ {invoice.totalPaymentAmount}
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_92]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('BalanceDue')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_11]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    $ {((lineTotalAmount+Number(taxAmount))-invoice.totalPaymentAmount).toFixed(2)}
                                                </Text>
                                            </View>
                                        </View>

                                    </View>
                                </View>
                            }

                            <View style={[styles.width_100, {display: 'block', clear: 'both', marginTop: '20px'}]}>

                                <Text style={[styles.text_13]}>
                                    {t('AmountInWords')}: { viewMode==='decimal'? invoice.amountInWordDecimalHour : invoice.amountInWord}
                                </Text>
                            </View>

                            <View style={[styles.width_100, {display: 'block', clear: 'both', marginTop: '10px'}]}>
                                <Text style={[styles.text_11]}>
                                    {t('TermsAndConditions')}: {invoice.termsAndConditions}
                                </Text>
                            </View>

                            {/*<View style={[styles.width_100, {display: 'block', clear: 'both', marginTop: '10px'}]}>
                                <Text style={[styles.text_11]}>
                                    {t('Comments')}: {invoice.remarks}
                                </Text>
                            </View>*/}

                            <View style={[styles.width_100, {display: 'block', clear: 'both', marginTop: '20px'}]}>

                                <Text style={[styles.text_12]}>
                                    {t('NoticeOfAssignment')}:
                                </Text>
                                <Text style={[styles.text_10]}>
                                    {invoice.remarks}
                                </Text>
                            </View>

                        </Page>
                    </Document>
                </PDFViewer>

            </main>

        </>
    )
}

export default InvoiceSummeryPdf