import React, { Fragment, useEffect, useRef } from 'react';

import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    HiOutlineExclamation, HiOutlineOfficeBuilding, HiOutlineUserGroup, HiOutlineX, HiOutlineCheck, HiChevronLeft, HiArrowCircleLeft, HiArrowCircleRight, HiOutlineUserCircle,
    HiOutlineClock, HiOutlineCurrencyDollar, HiEye, HiOutlineTable, HiCheckCircle, HiOutlineBookOpen, HiOutlineIdentification, HiOutlineBackspace, HiX,
    HiOutlineEye, HiOutlineChevronLeft, HiOutlineChevronRight, HiOutlineBan
} from "react-icons/hi";
import {HiArrowDownTray} from 'react-icons/hi2';
import { Link } from "react-router-dom";
import { FiSave, FiX } from "react-icons/fi";
import { AiOutlineLoading3Quarters, AiOutlineMinus, AiOutlineFileSearch } from "react-icons/ai";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useParams, useNavigate } from "react-router";
import { format } from 'date-fns';
import { Select, ScrollArea, Grid, Text, Switch, LoadingOverlay, TextInput, Textarea, Group, Radio, Loader } from "@mantine/core";
import CandidatePreferableConfirmation from "./modal/CandidatePreferableConfirmation";
import AddJobCandidate from "./modal/AddJobCandidate";
import TimeMatrixModal from "./modal/TimeMatrixModal";
import RemoveConfirmation from "./modal/RemoveConfirmation";
import JobSaveConfirmationModal from "./modal/JobSaveConfirmationModal";
import { Tab } from '@headlessui/react'
import { showNotification } from "@mantine/notifications";
import { FaTelegramPlane } from "react-icons/fa";
import ViewJob from "./modal/ViewJob";
import ViewCandidate from '../candidate/modal/ViewCandidate';
import DesignationList from './inc/DesignationList';
import PreviewPublishDesignationList from './inc/PreviewPublishDesignationList';
import {openConfirmModal} from '@mantine/modals';

const PAGE_SIZE = 1;

function Update() {
    const { roles, token, loggedUserId } = JSON.parse(localStorage.getItem('user'));
    const { jobId } = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [startTimeMax, setStartTimeMax] = useState('');
    const [endTimeMin, setEndTimeMin] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [data, setData] = useState([]);
    const [requiredDesignation, setRequiredDesignation] = useState([]);
    const [availableDesignation, setAvailableDesignation] = useState([]);
    const [designation, setDesignation] = useState({});
    // const [designationId, setDesignationId] = useState(null);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [showCandidatePreferModal, setShowCandidatePreferModal] = useState(false);
    const [candidateList, setCandidateList] = useState({});
    const [clients, setClients] = useState([]);
    const [recall, setRecall] = useState(false);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [stateData, setStateData] = useState('');
    const [cityData, setCityData] = useState('');
    const [cities, setCities] = useState([]);
    const [requiredDesignationId, setRequiredDesignationId] = useState();
    const [selectedTabIndex, setSelectedTabIndex] = useState(1);
    const [reqDesignationDescription, setReqDesignationDescription] = useState('');
    const [candidates, setCandidates] = useState([]);
    const [showCandidateListModal, setShowCandidateListModal] = useState(false);
    const [showJobSaveConfirmationModal, setShowJobSaveConfirmationModal] = useState(false);
    const [showJobViewModal, setShowJobViewModal] = useState(false);
    const [showTimeMatrixModal, setShowTimeMatrixModal] = useState(false);
    const [reqDesignationId, setReqDesignationId] = useState(null);
    const [showCandidateProfile, setShowCandidateProfile] = useState(false);
    const [reqDesignationShowLoader, setReqDesignationShowLoader] = useState(false);
    const [candidateId, setCandidateId] = useState(null);
    const [showPublishButton, setShowPublishButton] = useState(false);
    const [additionCandidates, setAdditionCandidates] = useState([]);




    const [jobDetails, setJobDetails] = useState({});
    const getJobDetails = (jobId) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/show-job`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                /* if (res.data.data.status === 'published'){
                     navigate('/job-post')
                 }*/
                setJobDetails(res.data.data)
                // console.log(res.data.data)

                // setCountryData(res.data.data.country)
                // setStateData(res.data.data.state)
                // setCityData(res.data.data.city)
                // setClientData(res.data.data.clientId)

                // const countryWithID = res.data.data.country.

                // CountryDataHandel(res.data.data.country)
                // CityDataHandel(res.data.data.state ? res.data.data.state : res.data.data.country)
                axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/state/dropdown?country_id=${res.data.data.country}`,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(res => {
                        if (res.data.status === 200) {
                            setStates(res.data.data)
                        }
                    });

                axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/city/dropdown?country_id=${res.data.data.country}`,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(res => {
                        if (res.data.status === 200) {
                            setCities(res.data.data)
                        }
                    });
                /*axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/state/dropdown?country_id=${Number(res.data.data.country)}`,
                    {headers: {"Authorization": `Bearer ${token}`}})
                    .then(res => {
                        if (res.data.status === 200) {
                            setStates(res.data.data)
                        }
                    });

                axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/city/dropdown?country_id=${Number(res.data.data.country)}`,
                    {headers: {"Authorization": `Bearer ${token}`}})
                    .then(res => {
                        if (res.data.status === 200) {
                            setCities(res.data.data)
                        }
                    });*/

                // console.log(res.data.data.country, res.data.data.state, res.data.data.city)

                getJobRequiredDesignation(jobId)
                // console.log(res.data.data.startTime.date)
                // setData(res.data.data)
                // console.log(data.startTime)

            });
    }


    const designationWiseAllCandidateByJob = (jobId) => {
        // designation-wise-all-candidate
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/designation-wise-all-candidate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "jobId": Number(jobId)
                // "jobId": 990
            }
        })
            .then(res => {
                setCandidateList(res.data.data);
            });
    }

    const rejectAssignedCandidate = (candidate, designation) => {
        openConfirmModal({
            title: (t('')),
            centered: true,
            children: <Text size="sm">{t('AreYouSure')}?</Text>,
            labels: {confirm: (t('Yes')), cancel: (t('No'))},
            // confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                candidate['designationName'] = designation['designationName'];
                candidate['startTime'] = designation['startTime'];
                axios({
                    method: 'delete',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-assign-candidate`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    data: candidate
                })
                    .then(res => {
                        if (res.data.status === 404) {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Warning') + ' !'),
                                message: (t('NotFound')),
                                autoClose: 2000,
                                disallowClose: true,
                                color: 'red',
                            });
                        } else if (res.data.status === 200) {
                            setRecall(!recall)
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Success') + ' !'),
                                message: (t('Removed')),
                                autoClose: 2000,
                                disallowClose: true,
                                color: 'green',
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
            ,
        }); 
    }



    useEffect(() => {
        getJobDetails(jobId);
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/dropdown`,
            { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                setCountries(res.data.data);
            });
    }, [recall]);

    let countryDropdown =
        countries.map((type, index) => {
            return ({
                // 'label': type.name, 'value': type.id
                'label': type.name, 'value': type.id + '-' + type.name
            })
        })
    const [countryData, setCountryData] = useState('');
    const CountryDataHandel = (e) => {
        if (e) {
            const countryWithId = e.split('-');
            updateJobDetails("country", e);
            setCountryData(e);
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/state/dropdown?country_id=${Number(countryWithId[0])}`,
                { headers: { "Authorization": `Bearer ${token}` } })
                .then(res => {
                    if (res.data.status === 200) {
                        setStates(res.data.data)
                    }
                });

            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/city/dropdown?country_id=${Number(countryWithId[0])}`,
                { headers: { "Authorization": `Bearer ${token}` } })
                .then(res => {
                    if (res.data.status === 200) {
                        setCities(res.data.data)
                    }
                });
        }

    }

    let stateDropdown =
        states.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id + '-' + type.name
            })
        })
    // on change state data get
    const StateDataHandel = (e) => {
        const stateWithId = e.split('-');
        updateJobDetails("state", e);
        setStateData(e);
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/state/wise/city/dropdown?state_id=${Number(stateWithId[0])}`,
            { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                if (res.data.status === 200) {
                    setCities(res.data.data)
                }
            });
    }

    let cityDropdown =
        cities.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id + '-' + type.name
            })
        })
    //city data handel
    const CityDataHandel = (e) => {
        updateJobDetails("city", e)
        setCityData(e);
    }

    const updateJobDetails = (property, value) => {

        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/update-job`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                property,
                value
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setRecall(!recall)
                }
            })
            .catch(function (error) {
                console.log(error)
            })

        
    }



    const runJob = () => {
        openConfirmModal({
            title: (t('')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSure')}?
                </Text>
            ),
            labels: {confirm: (t('Yes')), cancel: (t('No'))},
            confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
            // confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                if((new Date(jobDetails['currentDateTime'].date)).setHours(0,0,0,0) > (new Date(jobDetails['startTime'].date)).setHours(0,0,0,0)){
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Warning')),
                        message: (t('StartTimeExpired.JobCanNotBeRun')),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });

                    return;
                }


                axios({
                    method: 'put',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/update-job`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    data: {property: 'status', value: 'running'}
                })
                    .then(res => {
                        if(res.data.status === 202){
                            navigate(`/job-post/${jobId}/view`)

                        }else{
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t('Warning') + ' !'),
                                message: (t(res.data.message)),
                                autoClose: 2000,
                                disallowClose: true,
                                color: 'red',
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
        });
    }


    const jobRequiredDesignationUpdate = (id, property, value) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/job-required-designation`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                id,
                property,
                value
            }
        })
            .then(res => {
                getJobRequiredDesignation(jobId)
                setRecall(!recall);

            })
            .catch(function (error) {
                console.log(error)
            })

    }


    const getClient = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/client`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                status: "client"
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setClients(res.data.data)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    //get active clients
    useEffect(() => {
        getClient();

    }, []);

    useEffect(() => {
        designationWiseAllCandidateByJob(jobId);

    }, [recall]);





    let clientDropdown =
        clients.map((client, index) => {
            return ({
                'label': client.name, 'value': client.id
            })
        })

    const [clientData, setClientData] = useState('');
    const [isDisableNextButtonOnRequiredDesignationTab, setIsDisableNextButtonOnRequiredDesignationTab] = useState(true);
    const clientDataHandle = (e) => {
        // console.log(e)
        updateJobDetails('clientId', e);
        setClientData(e);
    }

    const [recallReqDesignation, setRecallReqDesignation] = useState(false);
    //get job required designations
    const getJobRequiredDesignation = (jobId) => {
        setReqDesignationShowLoader(true);
        // console.log(jobId)
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/job-required-designation`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                const data = res.data.data;

                if(data.length > 0){
                    const designationCount = data.length;
                    const completeRequiredFielsDesignations = data.filter(designation => designation['completeRequiredFields']);

                    setIsDisableNextButtonOnRequiredDesignationTab(!(designationCount === completeRequiredFielsDesignations.length));
                }
            
                setRequiredDesignation(res.data.data)
                // console.log(res.data.data)
                setReqDesignationShowLoader(false);

            });
    }

    useEffect(()=>{
        getJobRequiredDesignation(jobId);
    }, [recallReqDesignation]);

    const addNewRequiredDesignation = () => {
        // var table = document.getElementById("designationTable");
        // console.log(table)
        // var lastRowIndex = table.rows.length - 2;
        // console.log(lastRowIndex);
        // let callApi = true;

        /*console.log(lastRowIndex)
        if (lastRowIndex === 0) {
            callApi = true;
        } else {

            // var designation = document.getElementById("designation_"+lastRowIndex);
            var designation = document.getElementById("designation_0");
            var designationValue = designation.value;
            var designationText = designation.options[designation.selectedIndex].text;
            if (!designationValue || designationValue === '') {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: ('Choose designation in row 01'),
                    message: ('Error'),
                    autoClose: 2000,
                    disallowClose: true,
                    color: 'red',
                });
            } else {
                var numberofPerson = document.getElementById("noOfPerson_0").value;
                if (!numberofPerson || numberofPerson === '') {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: ('Enter number of person in row 01'),
                        message: ('Error'),
                        autoClose: 1000,
                        disallowClose: true,
                        color: 'red',
                    });
                } else {
                    var hourRate = document.getElementById("hourRate_0").value;
                    if (!hourRate || hourRate === '') {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: ('Enter hour rate in row 01'),
                            message: ('Error'),
                            autoClose: 1000,
                            disallowClose: true,
                            color: 'red',
                        });
                    } else {
                        callApi = true;
                    }
                }
            }
        }*/
        /*if (callApi){
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/job-required-designation`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    jobId: jobId
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        // document.getElementById("hourRate_0").value = "";
                        // document.getElementById("noOfPerson_0").value = "";
                        // document.getElementById("description_0").value = "";
                        getJobRequiredDesignation(jobId);
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }*/

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/job-required-designation`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                jobId: jobId
            }
        })
            .then(res => {
                if (res.status === 200) {
                    // document.getElementById("hourRate_0").value = "";
                    // document.getElementById("noOfPerson_0").value = "";
                    // document.getElementById("description_0").value = "";
                    getJobRequiredDesignation(jobId);
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const removeRequiredDesignation = () => {
        // console.log(requiredDesignationId);
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/job-required-designation`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                "id": requiredDesignationId
            }
        })
            .then(res => {
                if (res.status === 200) {
                    getJobRequiredDesignation(jobId);
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }


    //get job details
    /*  useEffect(() => {
          axios({
              method: 'get',
              url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/show-job`,
              headers: {
                  "Accept": `application/json`,
                  "Content-Type": `application/json`,
                  "Access-Control-Allow-Origin": '*',
                  "Authorization": `Bearer ${token}`
              },
          })
              .then(res => {
                  // console.log(res.data)
                  setCountryData(res.data.data.country)
                  setStateData(res.data.data.state)
                  setCityData(res.data.data.city)
                  setClientData(res.data.data.clientId)
                  // const countryWithID = res.data.data.country.
  
                  CountryDataHandel(res.data.data.country)
                  // CityDataHandel(res.data.data.state ? res.data.data.state : res.data.data.country)
  
                  // console.log(res.data.data.country, res.data.data.state, res.data.data.city)
  
                  getJobRequiredDesignation(jobId)
                  // console.log(res.data.data.startTime.date)
                  setData(res.data.data)
                  // console.log(data.startTime)
  
              });
      }, [])*/


    //get designation
    useEffect(() => {
        axios({
            method: 'get',
            // url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/master-data`,
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/master-data/dropdown`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "slug": "designation"
            }
        })
            .then(res => {
                setAvailableDesignation(res.data.data);
                // console.log(res.data.data)
            });
    }, []);

    let fullFormHeight = localStorage.getItem('fullFormHeight');
    fullFormHeight = fullFormHeight.slice(0, -2);
    fullFormHeight = fullFormHeight - 70;
    fullFormHeight = fullFormHeight + "px";

    const [assignedCandidate, setAssignedCandidate] = useState([])
    //get assigned candidate
    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-assign-candidate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "jobId": Number(jobId)
            }
        })
            .then(res => {
                setAssignedCandidate(res.data.data)
                // console.log(res)
                // console.log(assignedCandidate)


            });
    }, [showRemoveModal]);


    const assignedCandidateAcceptance = (status, loggedUserId, candidate, acceptance) => {

        openConfirmModal({
            title: (t('')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSure')}?
                </Text>
            ),
            labels: {confirm: (t('Yes')), cancel: (t('No'))},
            confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
            // confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                axios({
                    method: 'put',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-assigned-candidate-acceptance`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                        status,
                        loggedUserId,
                        candidate,
                        acceptance,
                        jobInfo: { id: jobDetails['id'], title: jobDetails['title'], description: jobDetails['description'] }
                    }
                })
                    .then(res => {
                        setRecall(!recall)
                        showNotification({
                            id: 'load-data',
                            loading: false,
                            title: res.data.status === 202 ? (t('Success')) : (t('Error')),
                            message: (t(res.data.message)),
                            autoClose: 2000,
                            disallowClose: true,
                            color: res.data.status === 202 ? 'green' : 'red',
                            icon: res.data.status === 202 ? <HiOutlineCheck /> : <HiOutlineBan />,
                        });
                       
                    })
                    .catch(function (error) {
                        console.log(error)
                    }); 
            }
        });

        
    }

    // console.log(jobDetails)

    const sendInvitation = (data) => {
        setSpinner(true);
        let designationsWithPreferredCandidates = {};
        data.map((designation, index) => {
            designationsWithPreferredCandidates[designation['designationName']] = {
                id: designation['designationAutoId'],
                name: designation['designationName'],
                slug: designation['designationSlug'],
                startTime: designation['startTime'],
                endTime: designation['endTime'],
                preferred: designation['candidates']['preferred'] && designation['candidates']['preferred']
            }

            /*preferredCandidates.push({
                [designation['designationName']] : {
                    id: designation['designationAutoId'],
                    name: designation['designationName'],
                    slug: designation['designationSlug'],
                    startTime: designation['startTime'],
                    endTime: designation['endTime'],
                    preferred: designation['candidates']['preferred'] && designation['candidates']['preferred']
                }
            })*/
        });

        const jobInfo = {
            id: jobDetails['id'],
            title: jobDetails['title'],
            description: jobDetails['description'],
            streetAddress: jobDetails['streetAddress'],
            locationMap: jobDetails['locationMap'],
            clientId: jobDetails['clientId'],
            clientName: jobDetails['clientName'],
            clientEmail: jobDetails['clientEmail'],
            clientPhone: jobDetails['clientPhone'],
        };



        // console.log({ designationsWithPreferredCandidates, jobInfo, senderId: loggedUserId })


        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-candidate-invitation`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: { designationsWithPreferredCandidates, jobInfo, senderId: loggedUserId }
        })
            .then(res => {
                console.log(res);
                setSpinner(false);
                if (202 === res.data.status | 200 === res.data.status) {
                    getJobDetails(jobId);
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Success')),
                        message: (t(res.data.message)),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'green',
                    });
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: (t('Something wrong')),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });
                }

            })
            .catch(function (error) {
                console.log(error)
            });
    }


    /*    const [page, setPage] = useState(1);
        useEffect(() => {
            const from = (page - 1) * PAGE_SIZE;
            const to = from + PAGE_SIZE;
            setItemTypes(items.slice(from, to));
        }, [page]);*/

    //Form validation
    const validationSchema = Yup.object().shape({
        // client: Yup.string().required(t('SelectClient')),
        // jobTitle: Yup.string().required(t("JobTitleIsRequired")),
        // jobDetails: Yup.string().required(t("JobDetailsIsRequired")),
        // address: Yup.string().required(t('AddressIsRequired')),
        // // streetAddress: Yup.string().required(t('StreetAddressIsRequired')),
        // country: Yup.string().required(t('SelectCountry')),
        // startTime: Yup.string().required(t("StartTimeIsRequired")),
        // endTime: Yup.string().required(t("EndTimeIsRequired")),
        // vacancy: Yup.string().required(t("VacancyIsRequired")),

    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState, setValue } = useForm(formOptions);
    const { errors } = formState;


    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })
    const hasRoleClient = roles.find(role => {
        return role === 'ROLE_CLIENT';
    })


    useEffect(() => {
        setValue('invitationOption', 'invitation-all-candidate');
        setValue('sendAll', false);
    }, [])


    function sendInvitationDesignationWise(data){
        setSpinner(true);
        const payload = {
            jobInfo: {id: jobDetails['id'], title: jobDetails['title'], clientName: jobDetails['clientName'], clientEmail: jobDetails['clientEmail']},
            designation: data['designation'],
            invitationOption: data['invitationOption'],
            preferredCandidates: data['preferredCandidates'],
            additionalCandidates: data['additionalCandidates'],
            senderId: loggedUserId
        }

        // console.log(payload)

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-candidate-designation-wise-invitation`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: payload
        })
            .then(res => {
                setSpinner(false);
                if (200 === res.data.status) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Success')),
                        message: (t(res.data.message)),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'green',
                    });
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: (t(res.data.message)),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });
                }

            })
            .catch(function (error) {
                console.log(error)
            });

    }

    const [publishSpinner, setPublishSpinner] = useState(false);

    // function jobPublish(data){
    function jobPublish(){
  
        openConfirmModal({
            title: (t('')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSure')}?
                </Text>
            ),
            labels: {confirm: (t('Yes')), cancel: (t('No'))},
            confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
            onCancel: () => console.log('Cancel'),
            onConfirm: () => {
                setPublishSpinner(true);

                if((new Date(jobDetails['currentDateTime'].date)).setHours(0,0,0,0) > (new Date(jobDetails['startTime'].date)).setHours(0,0,0,0)){
                    setPublishSpinner(false);
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Warning')),
                        message: (t('StartTimeExpired.JobCanNotBePublished')),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });

                    return;
                }

                axios({
                    method: 'put',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/update-job`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    data: { property: 'status', value: 'published', currentStatus: jobDetails['status']}
                })
                    .then(res => {
                        setPublishSpinner(false);
                        
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (res.data.status == 202 ? t('Success') : t('Error')),
                            message: (t(res.data.message)),
                            autoClose: 2000,
                            disallowClose: true,
                            color: res.data.status == 202 ? 'green' : 'red',
                        });

                        if(res.data.status == 202){
                            candidateInvitation(res.data.data)
                        }

                        getJobDetails(jobId);


                        

                       
        
                    })
                    .catch(function (error) {
                        console.log(error)
                    })

            }
            ,
        });
    }

    function candidateInvitation(jobInfo){

        const designations = jobDetails['designations'];

        let designationsWithPreferredCandidates = {};
        designations.map((designation, index) => {
            designationsWithPreferredCandidates[designation['designationName']] = {
                id: designation['designationAutoId'],
                name: designation['designationName'],
                slug: designation['designationSlug'],
                startTime: designation['startTime'],
                endTime: designation['endTime'],
                preferred: designation['candidates']['preferred'] && designation['candidates']['preferred']
            }
        });

            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-candidate-invitation`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Access-Control-Allow-Origin": '*',
                    "Authorization": `Bearer ${token}`
                },
                // data: { designationsWithPreferredCandidates, jobInfo, senderId: loggedUserId, sendAll: data['sendAll']}
                data: { designationsWithPreferredCandidates, jobInfo, senderId: loggedUserId}
            })
                .then(res => {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: ( t('Success')),
                        message: (t(res.data.message)),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'green',
                    });
                })
                .catch(function (error) {
                    console.log(error)
                })
    }








    const [fullPageLoader, setFullPageLoader] = useState(false);
    function jobCancel(){
        openConfirmModal({
            title: (t('')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSure')}?
                </Text>
            ),
            labels: {confirm: (t('Yes')), cancel: (t('No'))},
            confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                setFullPageLoader(true);
                    axios({
                        method: 'put',
                        url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/update-job`,
                        headers: {
                            "Accept": `application/json`,
                            "Content-Type": `application/json`,
                            "Access-Control-Allow-Origin": '*',
                            "Authorization": `Bearer ${token}`
                        },
                        data: { property: 'status', value: 'cancel', currentStatus: jobDetails['status']}
                    })
                        .then(res => {
                            navigate("/job-post")
            
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
            }
            ,
        });
    }

    return (
        <>
            <main className="w-full relative">
                <LoadingOverlay visible={fullPageLoader || publishSpinner} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'sm' }} overlayColor="bg-indigo-100" />

                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div
                            className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'} />
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('JobPost')}
                                <sub
                                    className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('JobPostRelatedInformation')}</sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <Link to="/job-post"
                                    className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={12} className={'mr-1'} />{t('Back')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 sm:px-6 lg:px-4">
                                        <div className="relative">
                                           
                                            <div className={'w-full'}>
                                              
                                                <div className={`w-full mr-0 border ${Object.values(jobDetails).length > 0 && jobDetails['status'] === 'published' ? 'border-green-500' : 'border-red-500'}`}>

                                                    <div>
                                                        <div className="hidden sm:block">
                                                            <div className="border-b border-gray-200">
                                                                <Tab.Group
                                                                    selectedIndex={selectedTabIndex}
                                                                    onChange={setSelectedTabIndex}
                                                                >
                                                                    <ScrollArea scrollbarSize={4}>
                                                                       
                                                                        <Tab.List className={'bg-white mb-2 relative'}>
                                                                          
                                                                            <Tab as={Fragment}>
                                                                                {({ selected }) => (
                                                                                    <button className={selected ? 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-500 text-indigo-700' : 'border-b-2  py-1.5 p-3.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'} onClick={() => setShowPublishButton(false)}>
                                                                                        {t('Basic')}
                                                                                    </button>
                                                                                )}
                                                                            </Tab>
                                                                            <Tab as={Fragment}>
                                                                                {({ selected }) => (
                                                                                    <button className={selected ? 'border-b-2 py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-500 text-indigo-700' : 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'} onClick={() => setShowPublishButton(false)}>
                                                                                        {t('RequiredDesignations')}
                                                                                    </button>
                                                                                )}
                                                                            </Tab>
                                                                            
                                                                            {
                                                                                jobDetails && jobDetails['designations'] && jobDetails['designations'].map((designation, index) => (
                                                                                    designation['designationSlug'] && (
                                                                                        <Tab as={Fragment} key={designation.id}>
                                                                                            {({ selected }) => (
                                                                                                <button
                                                                                                    className={selected ? 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-500 text-indigo-700' : 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'}
                                                                                                    onClick={() => {
                                                                                                        setShowPublishButton(false)
                                                                                                        setValue('designation', {
                                                                                                            id: designation['id'],
                                                                                                            designationAutoId: designation['designationAutoId'],
                                                                                                            name: designation['designationName'],
                                                                                                            slug: designation['designationSlug'],
                                                                                                            startTime: designation['startTime'],
                                                                                                            endTime: designation['endTime']
                                                                                                        })
                                                                                                    }}
                                                                                                    
                                                                                                >
                                                                                                    {t(designation['designationName'])}
                                                                                                </button>
                                                                                            )}
                                                                                        </Tab>
                                                                                    )
                                                                                ))
                                                                            }

                                                                            {
                                                                                Object.values(jobDetails).length > 0 && 'draft' !== jobDetails['status'] && (
                                                                                    <Tab as={Fragment}>
                                                                                        {({ selected }) => <button className={selected ? 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-500 text-indigo-700' : 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'} onClick={() => setShowPublishButton(true)}>{t(hasRoleClient ? 'Preview' : 'Preview&Publish')}</button>}
                                                                                    </Tab>
                                                                                )
                                                                            }

                                                                            {
                                                                                hasRoleModerator &&
                                                                                showPublishButton &&
                                                                                Object.values(jobDetails).length > 0 &&
                                                                                'in-progress' === jobDetails['status'] &&
                                                                                (
                                                                                    <Fragment>
                                                                                        <button
                                                                                            type='button'
                                                                                            disabled={publishSpinner || 'published' === jobDetails['status']}
                                                                                            className="absolute right-0 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center justify-center rounded bg-green-600 border-none py-1.5 px-2 text-sm font-normal text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-0"
                                                                                            onClick={() => jobPublish()}

                                                                                        >
                                                                                        {publishSpinner ? <Loader  size={16} className={'mr-2'} /> : <HiOutlineBookOpen  size={16} className={'mr-2'} />} {'published' === jobDetails['status'] ? t('Published') : t('Publish')}

                                                                                        </button>
                                                                                        
                                                                                    </Fragment>
                                                                                )
                                                                            }

                                                                            {
                                                                                hasRoleModerator &&
                                                                                showPublishButton &&
                                                                                Object.values(jobDetails).length > 0 &&
                                                                                'published' === jobDetails['status'] &&
                                                                                format(new Date(jobDetails['startTime'].date), 'MM/dd/Y') !== format(new Date(), 'MM/dd/Y') &&
                                                                                (

                                                                                    <span className="absolute right-0 isolate inline-flex rounded-md shadow-sm">
                                                                                        <button type="button" className="relative inline-flex items-center rounded-l-md bg-amber-500 px-3 py-2 text-sm font-normal text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-amber-400 focus:z-10" onClick={() => jobCancel()}><HiOutlineBackspace  size={16} className={'mr-2'}/>{t('Cancel')}</button>
                                                                                        <span className="bg-gray-100 text-gray-500 relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-normal text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10"><HiOutlineBookOpen  size={16} className={'mr-2'} /> {t('Published')}</span>
                                                                                    </span>

                                                                                    // <button
                                                                                    //     type='button'
                                                                                    //     disabled={true}
                                                                                    //     className="absolute right-0 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center justify-center rounded bg-green-600 border-none py-1.5 px-2 text-sm font-normal text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-0"

                                                                                    // >
                                                                                    // <HiOutlineBookOpen  size={16} className={'mr-2'} /> {t('Published')}

                                                                                    // </button>
                                                                                )
                                                                            }
                                                                            {/* {
                                                                                hasRoleModerator &&
                                                                                Object.values(jobDetails).length > 0 &&
                                                                                'published' === jobDetails['status'] &&
                                                                                (
                                                                                    <button
                                                                                        type='button'
                                                                                        className="absolute right-0 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center justify-center rounded bg-green-600 border-none py-1.5 px-2 text-sm font-normal text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-0"

                                                                                    >
                                                                                    <HiOutlineBackspace  size={16} className={'mr-2'} /> {t('Cancle')}

                                                                                    </button>
                                                                                )
                                                                            } */}

                                                                            {
                                                                                hasRoleModerator &&
                                                                                showPublishButton &&
                                                                                Object.values(jobDetails).length > 0 &&
                                                                                'published' === jobDetails['status'] &&
                                                                                format(new Date(jobDetails['startTime'].date), 'MM/dd/Y') === format(new Date(), 'MM/dd/Y') &&
                                                                                (
                                                                                    <Fragment>
                                                                                        <button
                                                                                            type='button'
                                                                                            className="absolute right-0 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center justify-center rounded bg-blue-600 border-none py-1.5 px-2 text-sm font-normal text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-0"
                                                                                            onClick={() => runJob()}

                                                                                        >
                                                                                        <HiOutlineIdentification  size={16} className={'mr-2'} /> {t('MakeItRunning')}

                                                                                        </button>
                                                                                        
                                                                                    </Fragment>
                                                                                )
                                                                            }

                                                                        </Tab.List>
                                                                        
                                                                    </ScrollArea>
                                                                    <Tab.Panels>
                                                                        <Tab.Panel>
                                                                            <form onSubmit={e => e.preventDefault()} id="horizontal-form">
                                                                                <ScrollArea style={{ height: fullFormHeight }} scrollbarSize={4}>
                                                                                    <div className="py-3 relative flex flex-col min-w-0 break-words w-full border-0">
                                                                                        <div>
                                                                                            <div className="md:grid md:grid-cols-5 md:gap-6">
                                                                                                <div className="md:col-span-1">
                                                                                                    <div className="ml-4 px-4 sm:px-0">
                                                                                                        <h3 className="text-lg font-medium leading-6 text-gray-900">{t("AppointmentInformation")}</h3>
                                                                                                        <p className="mt-1 text-sm text-gray-600">
                                                                                                            {t('EnterAppointmentRelatedDetails')}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="mt-5 md:col-span-4 md:mt-0">
                                                                                                    <div className="shadow sm:overflow-hidden sm:rounded">
                                                                                                        <div className="bg-gray-50 px-4 py-5 sm:p-6">
                                                                                                            {
                                                                                                                hasRoleModerator && (
                                                                                                                    <div className="w-full items-center">
                                                                                                                        <label htmlFor="client" className="form-input-sm-label-required">{t('Client')}</label>
                                                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                            {
                                                                                                                                errors.client?.type === 'required' &&
                                                                                                                                <div className="form-input-sm-error">
                                                                                                                                    <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.client?.message}
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                            <div
                                                                                                                                className="relative items-stretch focus-within:z-10">
                                                                                                                                <Select
                                                                                                                                    {...register("client")}
                                                                                                                                    placeholder={t('ChooseClient')}
                                                                                                                                    searchable clearable
                                                                                                                                    allowDeselect
                                                                                                                                    nothingFound="No options"
                                                                                                                                    data={clientDropdown}
                                                                                                                                    transition="pop-top-left"
                                                                                                                                    transitionDuration={80}
                                                                                                                                    transitionTimingFunction="ease"
                                                                                                                                    onChange={clientDataHandle}
                                                                                                                                    // value={clientData}
                                                                                                                                    value={jobDetails && jobDetails.clientId ? jobDetails.clientId : ''}
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }

                                                                                                            <div className="w-full items-center">
                                                                                                                <label htmlFor="jobTitle" className="form-input-sm-label-required">{t('JobTitle')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                    {errors.jobTitle?.type === 'required' &&
                                                                                                                        <div
                                                                                                                            className="form-input-sm-error">
                                                                                                                            <HiOutlineExclamation
                                                                                                                                size={16}
                                                                                                                                className={'mr-2'} /> {errors.jobTitle?.message}
                                                                                                                        </div>}
                                                                                                                    <input
                                                                                                                        {...register("jobTitle")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control ${errors.jobTitle ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                                        name="jobTitle"
                                                                                                                        id="jobTitle"
                                                                                                                        placeholder={t("EnterJobTitle")}
                                                                                                                        onChange={(e) => updateJobDetails("title", e.target.value)}
                                                                                                                        defaultValue={jobDetails && jobDetails.title ? jobDetails.title : ''}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className='flex flex-wrap -mx-0.5 col-span-2'>
                                                                                                                <div className="w-1/3 items-center">
                                                                                                                    <label htmlFor="startDate" className="form-input-sm-label-required">{t('StartDate')}</label>
                                                                                                                    <div className="relative mr-3 sm:col-span-2 sm:mt-0">

                                                                                                                        {errors.startTime?.type === 'required' &&
                                                                                                                            <div className="form-input-sm-error">
                                                                                                                                <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.startTime?.message}
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        <div className="relative items-stretch focus-within:z-10">
                                                                                                                            <input
                                                                                                                                disabled
                                                                                                                                {...register("startDate")}
                                                                                                                                type="date"
                                                                                                                                className={`form-input-sm-control disabled:bg-gray-100 disabled:cursor-not-allowed`}
                                                                                                                                name="startDate"
                                                                                                                                id="startDate"
                                                                                                                                placeholder={t("")}
                                                                                                                                onChange={e => {
                                                                                                                                    setEndTimeMin(e.target.value)
                                                                                                                                    updateJobDetails("startDate", new Date(e.target.value))
                                                                                                                                }}
                                                                                                                                defaultValue={jobDetails && jobDetails.startTime ? format(new Date(jobDetails.startTime.date), 'yyyy-MM-dd') : ''}
                                                                                                                                max={startTimeMax ? startTimeMax : ''}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="w-1/3 items-center">
                                                                                                                    <label htmlFor="endDate" className="form-input-sm-label-required">{t('EndDate')}</label>
                                                                                                                    <div className="relative mr-3 mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                        {errors.endTime?.type === 'required' &&
                                                                                                                            <div className="form-input-sm-error">
                                                                                                                                <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.endTime?.message}
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        <div className="relative items-stretch focus-within:z-10">
                                                                                                                            <input
                                                                                                                                disabled
                                                                                                                                {...register("endDate")}
                                                                                                                                type="date"
                                                                                                                                className={`form-input-sm-control disabled:bg-gray-100 disabled:cursor-not-allowed`}
                                                                                                                                name="endDate"
                                                                                                                                id="endDate"
                                                                                                                                placeholder={t("")}
                                                                                                                                onChange={e => {
                                                                                                                                    setStartTimeMax(e.target.value)
                                                                                                                                    updateJobDetails("endDate", e.target.value)
                                                                                                                                }}
                                                                                                                                defaultValue={jobDetails && jobDetails.endTime ? format(new Date(jobDetails.endTime.date), 'yyyy-MM-dd') : ''}
                                                                                                                                min={endTimeMin ? endTimeMin : ''}

                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                {/*<div className="mb-3 w-1/3 items-center">
                                                                                                                <label htmlFor="vacancy" className="form-input-sm-label-required">{t('Vacancy')}</label>
                                                                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                    {errors.vacancy?.type === 'required' &&
                                                                                                                    <div
                                                                                                                        className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation
                                                                                                                            size={16}
                                                                                                                            className={'mr-2'}/> {errors.vacancy?.message}
                                                                                                                    </div>
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className="relative mr-3 items-stretch focus-within:z-10">
                                                                                                                    <input
                                                                                                                        {...register("vacancy")}
                                                                                                                        type="text"
                                                                                                                        className={`form-input-sm-control`}
                                                                                                                        name="vacancy"
                                                                                                                        id="vacancy"
                                                                                                                        placeholder={t("EnterNumberOfVacancy")}
                                                                                                                        onChange={(e) => updateJobDetails("vacancy", e.target.value ? Number(e.target.value) : null)}
                                                                                                                        defaultValue={jobDetails && jobDetails.vacancy ? jobDetails.vacancy : ''}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>*/}
                                                                                                            </div>

                                                                                                            <div className="w-full items-center">
                                                                                                                <label htmlFor="jobDetails" className="form-input-sm-label-required">{t('JobDetails')}</label>
                                                                                                                <div
                                                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                    {errors.description?.type === 'required' &&
                                                                                                                        <div className="form-input-sm-error">
                                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.description?.message}
                                                                                                                        </div>}
                                                                                                                    <textarea
                                                                                                                        {...register("jobDetails")}
                                                                                                                        className={`form-input-sm-control-textarea ${errors.jobDetails ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                                        name="jobDetails"
                                                                                                                        id="jobDetails"
                                                                                                                        placeholder={t("EnterJobDetails")}
                                                                                                                        onChange={(e) => updateJobDetails("description", e.target.value)}
                                                                                                                        defaultValue={jobDetails && jobDetails.description ? jobDetails.description : ''}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="mb-3 w-full items-center">
                                                                                                                <label htmlFor="specialRequest" className="form-input-sm-label">{t('DoYouHaveAnySpecialRequest')}?</label>
                                                                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                    <textarea
                                                                                                                        {...register("specialRequest")}
                                                                                                                        className={`form-input-sm-control-textarea border-gray-300 focus:border-blue-600`}
                                                                                                                        name="specialRequest"
                                                                                                                        id="specialRequest"
                                                                                                                        placeholder={t("DoYouHaveAnySpecialRequest") + "?"}
                                                                                                                        onChange={(e) => updateJobDetails("specialRequest", e.target.value)}
                                                                                                                        defaultValue={jobDetails && jobDetails.specialRequest ? jobDetails.specialRequest : ''}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="hidden sm:block" aria-hidden="true">
                                                                                            <div className="py-5">
                                                                                                <div className="border-t border-gray-200" />
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* Job Location Section */}
                                                                                        <div className="mt-10 sm:mt-0">
                                                                                            <div className="md:grid md:grid-cols-5 md:gap-6">
                                                                                                <div className="md:col-span-1">
                                                                                                    <div className="ml-4 px-4 sm:px-0">
                                                                                                        <h3 className="text-lg font-medium leading-6 text-gray-900">{t('JobLocation')}</h3>
                                                                                                        <p className="mt-1 text-sm text-gray-600">{t('EnterJobsLocationRelatedDetails')}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="mt-5 md:col-span-4 md:mt-0">
                                                                                                    <div className="shadow  sm:rounded">
                                                                                                        <div className="bg-gray-50 px-4 py-5 sm:p-6">
                                                                                                            {/* <div className="w-full items-center">
                                                                                                                <label htmlFor="streetAddress" className="form-input-sm-label">{t('StreetAddress')}</label>
                                                                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                                                    <TextInput
                                                                                                                        {...register("streetAddress")}
                                                                                                                        name="streetAddress"
                                                                                                                        id="streetAddress"
                                                                                                                        placeholder={t("EnterStreetAddress")}
                                                                                                                        onChange={(e) => updateJobDetails("streetAddress", e.target.value)}
                                                                                                                        defaultValue={jobDetails && jobDetails.streetAddress ? jobDetails.streetAddress : ''}
                                                                                                                    />
                                                                                                                   
                                                                                                                </div>
                                                                                                            </div> */}

                                                                                                            {/* <div className='flex flex-wrap -mx-0.5 col-span-2'>
                                                                                                                <div className="w-1/3 items-center">
                                                                                                                    <label htmlFor="zipCode" className="form-input-sm-label">{t('ZipCode')}</label>

                                                                                                                    <div className="relative mr-3 mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                        {errors.zipCode?.type === 'required' &&
                                                                                                                            <div
                                                                                                                                className="form-input-sm-error">
                                                                                                                                <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.zipCode?.message}
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        <input
                                                                                                                            {...register("zipCode")}
                                                                                                                            type="text"
                                                                                                                            className={`form-input-sm-control`}
                                                                                                                            name="zipCode"
                                                                                                                            id="zipCode"
                                                                                                                            placeholder={t("EnterZipCode")}
                                                                                                                            onChange={(e) => updateJobDetails("zipCode", e.target.value)}
                                                                                                                            defaultValue={jobDetails && jobDetails.zipCode ? jobDetails.zipCode : ''}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> */}


                                                                                                            <div className='flex flex-wrap -mx-0.5 col-span-2'>
                                                                                                                <div className="w-2/3 items-center">
                                                                                                                    <label htmlFor="streetAddress" className="form-input-sm-label">{t('StreetAddress')}</label>
                                                                                                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">

                                                                                                                        <TextInput
                                                                                                                            {...register("streetAddress")}
                                                                                                                            name="streetAddress"
                                                                                                                            id="streetAddress"
                                                                                                                            placeholder={t("EnterStreetAddress")}
                                                                                                                            onChange={(e) => updateJobDetails("streetAddress", e.target.value)}
                                                                                                                            defaultValue={jobDetails && jobDetails.streetAddress ? jobDetails.streetAddress : ''}
                                                                                                                        />
                                                                                                                        {/* <textarea
                                                                                                                            {...register("streetAddress")}
                                                                                                                            className={`form-input-sm-control-textarea `}
                                                                                                                            name="streetAddress"
                                                                                                                            id="streetAddress"
                                                                                                                            placeholder={t("EnterStreetAddress")}
                                                                                                                            onChange={(e) => updateJobDetails("streetAddress", e.target.value)}
                                                                                                                            defaultValue={jobDetails && jobDetails.streetAddress ? jobDetails.streetAddress : ''}
                                                                                                                        /> */}
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                <div className="w-1/3 items-center">
                                                                                                                    <label htmlFor="zipCode" className="form-input-sm-label">{t('ZipCode')}</label>

                                                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                        {errors.zipCode?.type === 'required' &&
                                                                                                                            <div
                                                                                                                                className="form-input-sm-error">
                                                                                                                                <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.zipCode?.message}
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        <TextInput
                                                                                                                            {...register("zipCode")}
                                                                                                                            name="zipCode"
                                                                                                                            id="zipCode"
                                                                                                                            placeholder={t("EnterZipCode")}
                                                                                                                            onChange={(e) => updateJobDetails("zipCode", e.target.value)}
                                                                                                                            defaultValue={jobDetails && jobDetails.zipCode ? jobDetails.zipCode : ''}
                                                                                                                        />




                                                                                                                        {/* <input
                                                                                                                            {...register("zipCode")}
                                                                                                                            type="text"
                                                                                                                            className={`form-input-sm-control`}
                                                                                                                            name="zipCode"
                                                                                                                            id="zipCode"
                                                                                                                            placeholder={t("EnterZipCode")}
                                                                                                                            onChange={(e) => updateJobDetails("zipCode", e.target.value)}
                                                                                                                            defaultValue={jobDetails && jobDetails.zipCode ? jobDetails.zipCode : ''}
                                                                                                                        /> */}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>






                                                                                                            <div className='flex flex-wrap -mx-0.5 col-span-2'>
                                                                                                                <div className="w-1/3 items-center">
                                                                                                                    <label htmlFor="country" className="form-input-sm-label-required">{t('Country')}</label>
                                                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                        <div className="relative mr-3 items-stretch focus-within:z-10">
                                                                                                                            {/* <Select
                                                                                                                                {...register("country")}
                                                                                                                                placeholder={t('ChooseCountry')}
                                                                                                                                searchable clearable
                                                                                                                                allowDeselect
                                                                                                                                nothingFound="No options"
                                                                                                                                data={countryDropdown}
                                                                                                                                transition="pop-top-left"
                                                                                                                                transitionDuration={80}
                                                                                                                                transitionTimingFunction="ease"
                                                                                                                                onChange={CountryDataHandel}
                                                                                                                                value={jobDetails && jobDetails.country ? jobDetails.country : ''}
                                                                                                                            // value={countryData}
                                                                                                                            /> */}

                                                                                                                            <TextInput
                                                                                                                                disabled
                                                                                                                                className='font-bold'
                                                                                                                                value={jobDetails && jobDetails.country ? jobDetails.country.split('-')[1] : ''}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="w-1/3 items-center">
                                                                                                                    <label htmlFor="state" className="form-input-sm-label">{t('State')}</label>
                                                                                                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                                        <Select
                                                                                                                            placeholder={t('ChooseState')}
                                                                                                                            searchable clearable
                                                                                                                            allowDeselect
                                                                                                                            nothingFound="No options"
                                                                                                                            data={stateDropdown}
                                                                                                                            transition="pop-top-left"
                                                                                                                            transitionDuration={80}
                                                                                                                            transitionTimingFunction="ease"
                                                                                                                            onChange={StateDataHandel}
                                                                                                                            // value={stateData}
                                                                                                                            value={jobDetails && jobDetails.state ? jobDetails.state : ''}

                                                                                                                        />

                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                <div className="w-1/3 items-center">
                                                                                                                    <label htmlFor="city" className="form-input-sm-label">{t('City')}</label>
                                                                                                                    <div
                                                                                                                        className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                        {errors.city?.type === 'required' &&
                                                                                                                            <div className="form-input-sm-error">
                                                                                                                                <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.city?.message}
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        <Select
                                                                                                                            placeholder={t('ChooseCity')}
                                                                                                                            searchable clearable
                                                                                                                            allowDeselect
                                                                                                                            nothingFound="No options"
                                                                                                                            data={cityDropdown}
                                                                                                                            transition="pop-top-left"
                                                                                                                            transitionDuration={80}
                                                                                                                            transitionTimingFunction="ease"
                                                                                                                            onChange={CityDataHandel}
                                                                                                                            value={cityData ? cityData : (jobDetails.city ? jobDetails.city : null)}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            {/*<div
                                                                                                                className="mb-3 w-full items-center">
                                                                                                                <label htmlFor="address" className="form-input-sm-label-required">{t('Address')}</label>
                                                                                                                <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                                                    {errors.address?.type === 'required' &&
                                                                                                                    <div className="form-input-sm-error">
                                                                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.address?.message}
                                                                                                                    </div>
                                                                                                                    }
                                                                                                                    <textarea
                                                                                                                        {...register("address")}
                                                                                                                        className={`form-input-sm-control-textarea ${errors.address ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                                        name="address"
                                                                                                                        id="address"
                                                                                                                        placeholder={t("EnterAddress")}
                                                                                                                        onChange={(e) => updateJobDetails("address", e.target.value)}
                                                                                                                        defaultValue={jobDetails && jobDetails.address ? jobDetails.address : ''}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>*/}

                                                                                                            {/* <div className="mb-3 w-full items-center">
                                                                                                                <label htmlFor="locationMap" className="form-input-sm-label">{t('LocationMapLink')}</label>

                                                                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                                    {errors.locationMap?.type === 'required' &&
                                                                                                                        <div className="form-input-sm-error">
                                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.locationMap?.message}
                                                                                                                        </div>}
                                                                                                                    <textarea
                                                                                                                        {...register("locationMap")}
                                                                                                                        className={`form-input-sm-control-textarea ${errors.locationMap ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                                        name="locationMap"
                                                                                                                        id="locationMap"
                                                                                                                        placeholder={t("EnterLocationMapLink")}
                                                                                                                        rows={20}
                                                                                                                        onChange={(e) => updateJobDetails("locationMap", e.target.value)}
                                                                                                                        defaultValue={jobDetails && jobDetails.locationMap ? jobDetails.locationMap : ''}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div> */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/*</div>*/}
                                                                                        </div>

                                                                                    </div>
                                                                                </ScrollArea>
                                                                                <footer className="relative mt-1 border border-t px-3">
                                                                                    <div className='flex flex-row justify-between'>
                                                                                        <div>
                                                                                            <button
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-blue-600 bg-blue-700 items-center border rounded text-sm"
                                                                                                onClick={() => setShowJobViewModal(true)}
                                                                                            >
                                                                                                <HiOutlineEye size={16} className={'mr-2'} /><span>{t('Preview')}</span>
                                                                                            </button>
                                                                                        </div>
                                                                                        <div>
                                                                                            <button
                                                                                                className="inline-flex items-center ml-1 rounded bg-indigo-800 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                                                                                onClick={() => {
                                                                                                    setShowPublishButton(false)
                                                                                                    setSelectedTabIndex(prevState => prevState + 1)
                                                                                                }}
                                                                                            >
                                                                                                <HiOutlineChevronRight size={16} className={'mr-2'} />{t('Next')}

                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </footer>
                                                                            </form>
                                                                        </Tab.Panel>
                                                                        <Tab.Panel className="relative">
                                                                            <LoadingOverlay visible={reqDesignationShowLoader} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'sm' }} overlayColor="bg-indigo-100" />

                                                                            <form onSubmit={(e) => e.preventDefault()} id="horizontal-form">
                                                                                <ScrollArea scrollbarSize={4} style={{ height: fullFormHeight }}>
                                                                                    <div className="px-1 relative flex flex-col min-w-0 break-words w-full border-0">
                                                                                        
                                                                                        <div className="mx-auto justify-items-right w-full">
                                                                                            <section className="-mx-0.5 mb-6">
                                                                                                <div className="flex flex-col justify-center h-full" id="designationTable">

                                                                                                    {
                                                                                                        requiredDesignation && requiredDesignation.map((element, index) => {
                                                                                                            return (
                                                                                                                <Fragment key={element['id']}>
                                                                                                                    <div className="p-2 mb-1 rounded mx-3 bg-gray-50 border border-gray-100">
                                                                                                                        {
                                                                                                                            jobDetails['status'] !== 'published' && (
                                                                                                                                <Grid>
                                                                                                                                    <Grid.Col span="auto" offset={11} className='text-right pt-1 pb-0'>
                                                                                                                                        <button
                                                                                                                                            type="button"
                                                                                                                                            className="px-2 py-1 m-1 bg-red-700 hover:bg-red-600 text-white text-xs rounded"
                                                                                                                                            onClick={() => {
                                                                                                                                                setRequiredDesignationId(element.id)
                                                                                                                                                setShowRemoveModal(true)
                                                                                                                                            }}>
                                                                                                                                            <AiOutlineMinus size={18}/>
                                                                                                                                        </button>
                                                                                                                                    </Grid.Col>
                                                                                                                                </Grid>
                                                                                                                            )
                                                                                                                        }
                                                                                                                        
                                                                                                                        <Grid align="center">
                                                                                                                            <Grid.Col span="auto">
                                                                                                                                <Text fz="xs" fw={700} className="text-gray-500">{t('Designation')}:</Text>
                                                                                                                                <Select
                                                                                                                                    placeholder={t('Designation')}
                                                                                                                                    searchable
                                                                                                                                    nothingFound="No options"
                                                                                                                                    maxDropdownHeight={280}
                                                                                                                                    data={availableDesignation && availableDesignation.map((item, index) => ({ value: item.id, label: item.name }))}
                                                                                                                                    value={element.designationId}
                                                                                                                                    onChange={e => jobRequiredDesignationUpdate(element.id, "designationId", Number(e))}
                                                                                                                                />
                                                                                                                            </Grid.Col>
                                                                                                                            <Grid.Col span="auto">
                                                                                                                                <Text fz="xs" fw={700} className="text-gray-500">{t('Person')}:</Text>
                                                                                                                                <input
                                                                                                                                    onBlur={e => e.target.value && jobRequiredDesignationUpdate(element.id, "noOfPerson", e.target.value)}
                                                                                                                                    type="text"
                                                                                                                                    className={`form-input-sm-control`}
                                                                                                                                    name="noOfPerson"
                                                                                                                                    id={`noOfPerson_${index}`}
                                                                                                                                    defaultValue={element.noOfPerson ? element.noOfPerson : ''}
                                                                                                                                    placeholder={t('NoOfPerson')}
                                                                                                                                />
                                                                                                                            </Grid.Col>
                                                                                                                            <Grid.Col span="auto">
                                                                                                                                <Text fz="xs" fw={700} className="text-gray-500">{t('StartDate')}:</Text>

                                                                                                                                <input
                                                                                                                                    onChange={(e) => jobRequiredDesignationUpdate(element.id, "startTime", e.target.value)}
                                                                                                                                    type="date"
                                                                                                                                    className={`form-input-sm-control`}
                                                                                                                                    value={element.startTime ? format(new Date(element.startTime.date), "yyyy-MM-dd") : ''}
                                                                                                                                    min={format(new Date(), "yyyy-MM-dd")}
                                                                                                                                    // min={jobDetails.startTime && format(new Date(jobDetails.startTime.date), "yyyy-MM-dd")}
                                                                                                                                    // max={jobDetails.endTime && format(new Date(jobDetails.endTime.date), "yyyy-MM-dd")}
                                                                                                                                />
                                                                                                                            </Grid.Col>
                                                                                                                            
                                                                                                                            <Grid.Col span="auto">
                                                                                                                                <Text fz="xs" fw={700} className="text-gray-500">{t('Days')}:</Text>

                                                                                                                                <input
                                                                                                                                    onBlur={e => jobRequiredDesignationUpdate(element.id, "days", Number(e.target.value))}
                                                                                                                                    type="number"
                                                                                                                                    className={`form-input-sm-control`}
                                                                                                                                    name="days"
                                                                                                                                    id={`days_${index}`}
                                                                                                                                    defaultValue={element.days ? element.days : ''}
                                                                                                                                    placeholder={t('days')}
                                                                                                                                    min={1}
                                                                                                                                />
                                                                                                                            </Grid.Col>

                                                                                                                            <Grid.Col span="auto">
                                                                                                                                <Text fz="xs" fw={700} className="text-gray-500">{t('EndDate')}:</Text>
                                                                                                                                <input
                                                                                                                                    disabled={true}
                                                                                                                                    onChange={(e) => jobRequiredDesignationUpdate(element.id, "endTime", e.target.value)}
                                                                                                                                    type="date"
                                                                                                                                    className={`form-input-sm-control disabled:bg-gray-100 disabled:cursor-not-allowed`}
                                                                                                                                    value={element.endTime ? format(new Date(element.endTime.date), "yyyy-MM-dd") : ''}
                                                                                                                                    // min={element.startTime ? format(new Date(element.startTime.date), "yyyy-MM-dd") : format(new Date(jobDetails.startTime.date), "yyyy-MM-dd HH:mm")}
                                                                                                                                    // max={format(new Date(jobDetails.endTime.date), "yyyy-MM-dd")}
                                                                                                                                />
                                                                                                                            </Grid.Col>

                                                                                                                            <Grid.Col span="auto">
                                                                                                                                <Text fz="xs" fw={700} className="text-gray-500">{t('Rate')}:</Text>

                                                                                                                                <input
                                                                                                                                    // onChange={(e) => jobRequiredDesignationUpdate(element.id, "rate", e.target.value)}
                                                                                                                                    onBlur={(e) => jobRequiredDesignationUpdate(element.id, "rate", e.target.value)}
                                                                                                                                    type="text"
                                                                                                                                    className={`form-input-sm-control ${element.rate ? 'disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-500' : ''}`}
                                                                                                                                    name="hourRate"
                                                                                                                                    id={`hourRate_${index}`}
                                                                                                                                    defaultValue={element.rate ? element.rate : ''}
                                                                                                                                    // value={element.rate ? element.rate : ''}
                                                                                                                                    // disabled={!!element.rate}
                                                                                                                                    placeholder="Rate"
                                                                                                                                />
                                                                                                                            </Grid.Col>

                                                                                                                            <Grid.Col span="auto">
                                                                                                                                <Text fz="xs" fw={700} className="text-gray-500">{t('InTime')}:</Text>

                                                                                                                                <input
                                                                                                                                    onChange={(e) => jobRequiredDesignationUpdate(element.id, "inTime", e.target.value)}
                                                                                                                                    type="time"
                                                                                                                                    className={`form-input-sm-control`}
                                                                                                                                    value={element.inTime ? element.inTime : ''}
                                                                                                                                />
                                                                                                                            </Grid.Col>
                                                                                                                            
                                                                                                                            
                                                                                                                            <Grid.Col span="auto">
                                                                                                                                <Text fz="xs" fw={700} className="text-gray-500">{t('OutTime')}:</Text>
                                                                                                                                <input
                                                                                                                                    onChange={(e) => jobRequiredDesignationUpdate(element.id, "outTime", e.target.value)}
                                                                                                                                    type="time"
                                                                                                                                    className={`form-input-sm-control`}
                                                                                                                                    value={element.outTime ? element.outTime : ''}
                                                                                                                                />
                                                                                                                            </Grid.Col>
                                                                                                                        </Grid>
                                                                                                                        <Grid>
                                                                                                                            <Grid.Col span="auto">
                                                                                                                                <Text fz="xs" fw={700} className="text-gray-500">{t('Description')}:</Text>
                                                                                                                                <Textarea
                                                                                                                                    onBlur={e => jobRequiredDesignationUpdate(element.id, "description", e.target.value)}
                                                                                                                                    name="description"
                                                                                                                                    id={`description_${index}`}
                                                                                                                                    placeholder={t('EnterDescription')}
                                                                                                                                    defaultValue={element.description ? element.description : reqDesignationDescription}
                                                                                                                                    radius={0}
                                                                                                                                    autosize
                                                                                                                                    minRows={4}
                                                                                                                                    maxRows={4}
                                                                                                                                />
                                                                                                                            </Grid.Col>
                                                                                                                            { hasRoleModerator && (
                                                                                                                                <Grid.Col span="auto">
                                                                                                                                    <Text fz="xs" fw={700} className="text-gray-500">{t('Instruction')}:</Text>
                                                                                                                                    <Textarea
                                                                                                                                        onBlur={e => jobRequiredDesignationUpdate(element.id, "instruction", e.target.value)}
                                                                                                                                        name="instruction"
                                                                                                                                        id={`instruction_${index}`}
                                                                                                                                        placeholder={t('EnterInstruction')}
                                                                                                                                        defaultValue={element.instruction ? element.instruction : ''}
                                                                                                                                        radius={0}
                                                                                                                                        autosize
                                                                                                                                        minRows={4}
                                                                                                                                        maxRows={4}
                                                                                                                                    />
                                                                                                                                </Grid.Col>
                                                                                                                            )}
                                                                                                                            

                                                                                                                            
                                                                                                                        </Grid>
                                                                                                                        {/* <Grid className='bg-neutral-200 mt-1 border-b border-neutral-400 mb-2' gutter="xs"> */}
                                                                                                                        <Grid gutter={0}>
                                                                                                                            <Grid.Col span="auto">
                                                                                                                                <Text fz="sm" fw={700} className='bg-neutral-200 mt-1 border-b border-neutral-400 mb-2 p-1'>{ t('TimeMatrix') }</Text>

                                                                                                                            </Grid.Col>
                                                                                                                            <Grid.Col span="auto" className='text-right bg-neutral-200 mt-1 border-b border-neutral-400 mb-2 px-1'>
                                                                                                                                <button
                                                                                                                                    type="button"
                                                                                                                                    disabled={!element.inTime || !element.outTime}
                                                                                                                                    className="disabled:cursor-not-allowed disabled:text-gray-500 text-indigo-800 hover:text-indigo-700 text-sm underline"
                                                                                                                                    onClick={(e) => {
                                                                                                                                        setReqDesignationId(element['id'])
                                                                                                                                        setShowTimeMatrixModal(true)
                                                                                                                                    }}>
                                                                                                                                        { Object.keys(element['timeMatrix']).length > 0 ? t('EditMultipleCheckIn/CheckOutTime') : t('ThisPositionHasMMultipleCheckIn/CheckOutTime') }
                                                                                                                                </button>

                                                                                                                            </Grid.Col>
                                                                                                                        </Grid>

                                                                                                                        <Grid gutter="xs">
                                                                                                                            {
                                                                                                                                Object.keys(element['timeMatrix']).map((time, pIndex)=>(
                                                                                                                                    <Grid.Col key={pIndex} span={3}>
                                                                                                                                        <Group className='h-full mb-1 bg-gray-200 shadow p-1 px-3 py-2'>
                                                                                                                                            <Text fz="sm" fw={500}>{t('InTimeOutTime')}: {time}</Text>
                                                                                                                                            <ul role="list" className="flex flex-wrap w-full text-xs text-gray-600">
                                                                                                                                            {
                                                                                                                                                element['timeMatrix'][time].map((item, cIndex)=>(
                                                                                                                                                    <li key={cIndex} className="flex gap-x-1 w-1/3">
                                                                                                                                                        <HiCheckCircle size={18} className="h-4 w-4 flex-none text-indigo-800"/>{format(new Date(item['date'].date), 'MM/dd/Y')}
                                                                                                                                                    </li>
                                                                                                                                                    ))
                                                                                                                                            }
                                                                                                                                            </ul>
                                                                                                                                        </Group>
                                                                                                                                        

                                                                                                                                    </Grid.Col>
                                                                                                                                ))
                                                                                                                            }
                                                                                                                        </Grid>
                                                                                                                        
                                                                                                                    </div>
                                                                                                                </Fragment>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                    
                                                                                                </div>
                                                                                                {
                                                                                                    jobDetails['status'] !== 'published' && (
                                                                                                        <div className="p-2 mb-3 rounded ml-1">
                                                                                                            <a
                                                                                                                className="font-bold text-indigo-500 cursor-pointer hover:text-indigo-800 hover:underline"
                                                                                                                onClick={() => addNewRequiredDesignation()}
                                                                                                            >
                                                                                                                {t('AddMoreDesignation')}
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                                
                                                                                            </section>
                                                                                        </div>
                                                                                    </div>
                                                                                </ScrollArea>
                                                                                <footer className="relative mt-1 border-1 border-t">
                                                                                    <div className='flex flex-row justify-between px-3'>
                                                                                        <div>
                                                                                            <button
                                                                                                className="px-6 py-2 ml-1 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-blue-600 bg-blue-700 items-center border rounded text-sm"
                                                                                                type='button'
                                                                                                onClick={() => setShowJobViewModal(true)}
                                                                                            >
                                                                                                <HiOutlineEye size={16} className={'mr-2'} /><span>{t('Preview')}</span>
                                                                                            </button>
                                                                                        </div>
                                                                                        <div>
                                                                                            <button
                                                                                                className="mr-1 inline-flex items-center ml-6 rounded bg-red-700 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-0"
                                                                                                type='button'
                                                                                                onClick={() => {
                                                                                                    setShowPublishButton(false)
                                                                                                    setSelectedTabIndex(prevState => prevState - 1)
                                                                                                }}
                                                                                            >
                                                                                                <HiOutlineChevronLeft size={16} className={'mr-2'} />{t('Previous')}

                                                                                            </button>
                                                                                            {
                                                                                                "draft" === jobDetails["status"] ? (
                                                                                                    <button
                                                                                                        onClick={() => {
                                                                                                            setShowPublishButton(false)
                                                                                                            setShowJobSaveConfirmationModal(true)
                                                                                                        }}
                                                                                                        disabled={isDisableNextButtonOnRequiredDesignationTab}
                                                                                                        className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center ml-1 rounded bg-indigo-800 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"

                                                                                                    >
                                                                                                        {
                                                                                                            spinner ? (<AiOutlineLoading3Quarters className={'mr-2 animate-spin'} size={16} />) : (<HiOutlineChevronRight size={16} className={'mr-2'} />)
                                                                                                        }
                                                                                                        {t('Next')}
                                                                                                    </button>
                                                                                                ) : (


                                                                                                    <button
                                                                                                        className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center ml-1 rounded bg-indigo-800 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                                                                                        disabled={isDisableNextButtonOnRequiredDesignationTab}
                                                                                                        type='button'
                                                                                                        onClick={() => setSelectedTabIndex(prevState => prevState + 1)}
                                                                                                    >
                                                                                                        <HiOutlineChevronRight size={16} className={'mr-2'} />{t('Next')}

                                                                                                    </button>

                                                                                                )
                                                                                            }
                                                                                            {/* {
                                                                                                "draft" === jobDetails["status"] ? (
                                                                                                    <button
                                                                                                        onClick={() => setShowJobSaveConfirmationModal(true)}
                                                                                                        // className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm"
                                                                                                        className="inline-flex items-center ml-1 rounded bg-indigo-800 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"

                                                                                                    >
                                                                                                        {
                                                                                                            spinner ? (<AiOutlineLoading3Quarters className={'mr-2 animate-spin'} size={16} />) : (<HiOutlineChevronRight size={16} className={'mr-2'} />)
                                                                                                        }
                                                                                                        {t('Next')}
                                                                                                    </button>
                                                                                                ) : (


                                                                                                    <button
                                                                                                        className="inline-flex items-center ml-1 rounded bg-indigo-800 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                                                                                        type='button'
                                                                                                        onClick={() => setSelectedTabIndex(prevState => prevState + 1)}
                                                                                                    >
                                                                                                        <HiOutlineChevronRight size={16} className={'mr-2'} />{t('Next')}

                                                                                                    </button>

                                                                                                )
                                                                                            } */}
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                </footer>
                                                                            </form>

                                                                        </Tab.Panel>

                                                                        {
                                                                            jobDetails && jobDetails['designations'] && jobDetails['designations'].map((designation, index) => (
                                                                                designation['designationName'] && (
                                                                                    <Tab.Panel key={designation.id}>
                                                                                        <ScrollArea scrollbarSize={4} style={{ height: fullFormHeight }}>
                                                                                            <div className="bg-gray-50 p-4">
                                                                                                <Grid align="center">
                                                                                                    <Grid.Col span={3}>
                                                                                                        <div className='flex flex-start w-full'>
                                                                                                            <Text fw={700} className='w-1/3'>{t('Designation')}</Text>
                                                                                                            <Text className='w-2/3'>: {designation['designationName']}</Text>
                                                                                                        </div>
                                                                                                        <div className='flex flex-start w-full'>
                                                                                                            <Text fw={700} className='w-1/3'>{t('StartDate')}</Text>
                                                                                                            <Text className='w-2/3'>: {designation['startTime'] && format(new Date(designation['startTime'].date), "MM/dd/yyyy")}</Text>
                                                                                                        </div>
                                                                                                        <div className='flex flex-start w-full'>
                                                                                                            <Text fw={700} className='w-1/3'>{t('EndDate')}</Text>
                                                                                                            <Text className='w-2/3'>: {designation['endTime'] && format(new Date(designation['endTime'].date), "MM/dd/yyyy")}</Text>
                                                                                                        </div>
                                                                                                    </Grid.Col>

                                                                                                    <Grid.Col span={3}>
                                                                                                        <div className='flex flex-start w-full'>
                                                                                                            <Text fw={700} className='w-1/3'>{t('Rate')}</Text>
                                                                                                            <Text className='w-2/3'>: {designation['rate'] && "$" + designation['rate']}</Text>
                                                                                                        </div>
                                                                                                        <div className='flex flex-start w-full'>
                                                                                                            <Text fw={700} className='w-1/3'>{t('StartTime')}</Text>
                                                                                                            <Text className='w-2/3'>: {designation['inTime'] && designation['inTime']}</Text>
                                                                                                        </div>
                                                                                                        <div className='flex flex-start w-full'>
                                                                                                            <Text fw={700} className='w-1/3'>{t('EndTime')}</Text>
                                                                                                            <Text className='w-2/3'>: {designation['outTime'] && designation['outTime']}</Text>
                                                                                                        </div>
                                                                                                    </Grid.Col>

                                                                                                    <Grid.Col span={3}>
                                                                                                        <div className='flex flex-start w-full'>
                                                                                                            <Text fw={700} className='w-1/3'>{t('Requested')}</Text>
                                                                                                            <Text className='w-2/3'>: {designation['noOfPerson']}</Text>
                                                                                                        </div>
                                                                                                        {/* {console.log(designation)} */}
                                                                                                        <div className='flex flex-start w-full'>
                                                                                                            <Text fw={700} className='w-1/3'>{t('Selected')}</Text>
                                                                                                            <Text className='w-2/3'>: {designation?.finalCandidates ? designation?.finalCandidates?.length : 0}</Text>
                                                                                                        </div>
                                                                                                        <div className='flex flex-start w-full'>
                                                                                                            <Text fw={700} className='w-1/3'>{t('Open')}</Text>
                                                                                                            <Text className='w-2/3'>: { designation.candidates?.preferred ? designation.candidates?.preferred?.length : 0 }</Text>
                                                                                                        </div>
                                                                                                    </Grid.Col>
                              
                                                                                                    <Grid.Col span={3}>
                                                                                                        
                                                                                                        {
                                                                                                            hasRoleModerator && (
                                                                                                                <div className="text-center">
                                                                                                                    <button
                                                                                                                        disabled={'archived' === jobDetails['status'] || 'complete' === jobDetails['status']}
                                                                                                                        type="button"
                                                                                                                        className="w-full disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 px-2 py-2 rounded bg-indigo-600 text-white hover:bg-indigo-500 items-center text-sm"
                                                                                                                        onClick={() => {
                                                                                                                            setDesignation({
                                                                                                                                id: designation.id,
                                                                                                                                name: designation['designationName'],
                                                                                                                                slug: designation['designationSlug'],
                                                                                                                                startDate: designation['startTime'],
                                                                                                                                endDate: designation['endTime'],
                                                                                                                                inTime: designation['inTime'],
                                                                                                                                outTime: designation['outTime']
                                                                                                                            });
                                                                                                                            // setCandidates(candidateList[designation.designationSlug] ? candidateList[designation.designationSlug] : []);
                                                                                                                            setShowCandidateListModal(true)
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {t('SelectCandidate')}
                                                                                                                        {/* <HiOutlineUserGroup size={16} /> */}
                                                                                                                    </button>

                                                                                                                    <form method='post' onSubmit={handleSubmit(sendInvitationDesignationWise)}>
                                                                                                                        <Radio.Group
                                                                                                                            name="option"
                                                                                                                            {...register("invitationOption")}
                                                                                                                            onChange={(e) => setValue('invitationOption', e)}
                                                                                                                            defaultValue='invitation-all-candidate'
                                                                                                                        >
                                                                                                                            <Radio value="invitation-preferred-candidate" label="Send Invitation to select candidate." />
                                                                                                                            <Radio value="invitation-all-candidate" label="Send Invitation to all candidate." />

                                                                                                                        </Radio.Group>

                                                                                                                        <button
                                                                                                                            disabled={('published' !== jobDetails['status']) || spinner}
                                                                                                                            type='submit'
                                                                                                                            // disabled={spinner}
                                                                                                                            className="disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center ml-1 rounded bg-indigo-800 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                                                                                                            onClick={ () => {
                                                                                                                                setValue('preferredCandidates', designation['candidates']['preferred'] ? designation['candidates']['preferred'] : [])
                                                                                                                                setValue('additionalCandidates', designation['candidates']['additional'] ? designation['candidates']['additional'] : [])
                                                                                                                            } }
                                                                                                                        >
                                                                                                                            {spinner ? <AiOutlineLoading3Quarters className={'mr-2 animate-spin'} size={16} /> : <FaTelegramPlane size={16} className="mr-2" />}

                                                                                                                            {t('SendInvitation')}

                                                                                                                        </button>
                                                                                                                    </form>
                                                                                                                </div>
                                                                                                                
                                                                                                            )
                                                                                                        }
                                                                                                    </Grid.Col>
                                                                                                </Grid>
                                                                                                <div className='flex flex-start w-full'>
                                                                                                    <Text fw={700}>{t('Instruction')} </Text>
                                                                                                    <ScrollArea scrollbarSize={4} h={100} type='auto'>
                                                                                                        <Text>: {designation['instruction']}</Text>
                                                                                                    </ScrollArea>
                                                                                                </div>
                                                                                                
                                                                                                <div className="bg-gray-50">
                                                                                                    <Tab.Group>
                                                                                                        <Tab.List>
                                                                                                            {/* {
                                                                                                                designation['candidates'] && Object.keys(designation['candidates']).map((flag, index) => (
                                                                                                                    'additional' !== flag && (
                                                                                                                        <Tab as={Fragment}>
                                                                                                                            {({ selected }) => (
                                                                                                                                <button
                                                                                                                                    className={selected ? 'border-b-2 mb-4 mt-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-500 text-indigo-700' : 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'}>
                                                                                                                                    {flag && ("self" === flag ? "Applied" : ("preferred" === flag ? "Selected" : flag[0].toUpperCase() + flag.slice(1)))}
                                                                                                                                </button>
                                                                                                                            )}
                                                                                                                        </Tab>
                                                                                                                    )

                                                                                                                ))
                                                                                                            } */}
                                                                                                            {/* {
                                                                                                                ((designation['finalCandidates'] && designation['finalCandidates'].length > 0) || (designation['candidates']['additional'] && designation['candidates']['additional'].length > 0)) && (
                                                                                                                    <Tab as={Fragment}>
                                                                                                                        {({ selected }) => (
                                                                                                                            <button
                                                                                                                                className={selected ? 'border-b-2 mb-4 mt-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-500 text-indigo-700' : 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'}>
                                                                                                                                {t('FinalCandidates')}
                                                                                                                            </button>
                                                                                                                        )}
                                                                                                                    </Tab>
                                                                                                                )
                                                                                                            } */}
                                                                                                            
                                                                                                            <Tab as={Fragment}>{({ selected }) => <button className={selected ? 'border-b-2 mb-4 mt-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-500 text-indigo-700' : 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'}>{t('Invite')}</button>}</Tab>
                                                                                                            <Tab as={Fragment}>{({ selected }) => <button className={selected ? 'border-b-2 mb-4 mt-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-500 text-indigo-700' : 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'}>{t('Applied')}</button>}</Tab>
                                                                                                            <Tab as={Fragment}>{({ selected }) => <button className={selected ? 'border-b-2 mb-4 mt-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-500 text-indigo-700' : 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'}>{t('FinalCandidates')}</button>}</Tab>
                                                                                                            
                                                                                                        </Tab.List>
                                                                                                        <Tab.Panels>
                                                                                                            {
                                                                                                                !designation['candidates'] && (
                                                                                                                    <Fragment>
                                                                                                                        <Tab.Panel>
                                                                                                                            <table className="min-w-full divide-y divide-gray-300">
                                                                                                                                <thead className="bg-gray-50">
                                                                                                                                    <tr>
                                                                                                                                        <th scope="col"
                                                                                                                                            className="py-1 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t('Name')}
                                                                                                                                        </th>
                                                                                                                                        <th scope="col"
                                                                                                                                            className="py-1 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">{t('Phone')}
                                                                                                                                        </th>

                                                                                                                                        <th scope="col"
                                                                                                                                            className="py-1 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">{t('Status')}
                                                                                                                                        </th>
                                                                                                                                        {
                                                                                                                                            hasRoleModerator && (
                                                                                                                                                <th scope="col" className="py-1 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                                                                                    {t('FinalApprove')}
                                                                                                                                                </th>
                                                                                                                                            )
                                                                                                                                        }

                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                                                                                                    <tr>
                                                                                                                                        <td colSpan={4} className="text-red-700 bg-red-100 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm">
                                                                                                                                            {t('NoAvailableCandidates')}
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                            </tbody>
                                                                                                                            </table>
                                                                                                                        </Tab.Panel>
                                                                                                                        <Tab.Panel>
                                                                                                                            <table className="min-w-full divide-y divide-gray-300">
                                                                                                                                <thead className="bg-gray-50">
                                                                                                                                    <tr>
                                                                                                                                        <th scope="col"
                                                                                                                                            className="py-1 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t('Name')}
                                                                                                                                        </th>
                                                                                                                                        <th scope="col"
                                                                                                                                            className="py-1 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">{t('Phone')}
                                                                                                                                        </th>

                                                                                                                                        <th scope="col"
                                                                                                                                            className="py-1 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">{t('Status')}
                                                                                                                                        </th>
                                                                                                                                        {
                                                                                                                                            hasRoleModerator && (
                                                                                                                                                <th scope="col" className="py-1 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                                                                                    {t('FinalApprove')}
                                                                                                                                                </th>
                                                                                                                                            )
                                                                                                                                        }

                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                                                                                                    <tr>
                                                                                                                                        <td colSpan={4} className="text-red-700 bg-red-100 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm">
                                                                                                                                            {t('NoAvailableCandidates')}
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                            </tbody>
                                                                                                                            </table>
                                                                                                                        </Tab.Panel>
                                                                                                                    </Fragment>
                                                                                                                    
                                                                                                                )
                                                                                                            }

                                                                                                                
                                                                                                                <Tab.Panel>
                                                                                                                    <table className="min-w-full divide-y divide-gray-300">
                                                                                                                        <thead className="bg-gray-50">
                                                                                                                            <tr>
                                                                                                                                <th scope="col"
                                                                                                                                    className="py-1 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t('Name')}
                                                                                                                                </th>
                                                                                                                                <th scope="col"
                                                                                                                                    className="py-1 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">{t('Phone')}
                                                                                                                                </th>

                                                                                                                                <th scope="col"
                                                                                                                                    className="py-1 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">{t('Status')}
                                                                                                                                </th>
                                                                                                                                <th scope="col" className="py-1 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6"></th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                                                                                            {
                                                                                                                                designation['candidates'] && designation['candidates']['preferred'] ? designation['candidates']['preferred'].map((candidate, index) => (
                                                                                                                                    <tr key={candidate.id}>
                                                                                                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                                                                                                            <div className="flex items-center">
                                                                                                                                                <div className="h-10 w-10 flex-shrink-0">
                                                                                                                                                    <img className="h-10 w-10 rounded-full"
                                                                                                                                                        src={candidate['photo'] ? `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${candidate['photo']}` : "/assets/images/avatar.svg"}
                                                                                                                                                        // src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                                                                                                                        alt="" />
                                                                                                                                                </div>
                                                                                                                                                <div className="ml-4 hover:text-indigo-900">
                                                                                                                                                    <div className="font-medium text-gray-900">
                                                                                                                                                        {candidate.name}
                                                                                                                                                        { hasRoleModerator && (
                                                                                                                                                            <span 
                                                                                                                                                                onClick={(e) => {
                                                                                                                                                                    setCandidateId(candidate['userId'])
                                                                                                                                                                    setShowCandidateProfile(true)
                                                                                                                                                                }}

                                                                                                                                                                className="cursor-pointer ml-2 inline-flex items-center rounded bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800"
                                                                                                                                                            >
                                                                                                                                                                {t('Details')}
                                                                                                                                                            </span>
                                                                                                                                                        ) }
                                                                                                                                                    </div>
                                                                                                                                                    <div className="text-gray-500">{candidate.email}</div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">
                                                                                                                                            <div className="text-gray-900">{candidate.phoneNumber}</div>
                                                                                                                                        </td>

                                                                                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">
                                                                                                                                            <div className="text-gray-900">
                                                                                                                                                {/* {candidate['candidateConfirmationBy'] ? 'Yes' : 'No'} */}
                                                                                                                                                { candidate['applyStatus'] === 'preferred' && t('NotYetResponded') }
                                                                                                                                                { candidate['applyStatus'] === 'accepted' && t('InvitationAccepted') }
                                                                                                                                                { candidate['applyStatus'] === 'declined' && t('InvitationDeclined') }
                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                        <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
                                                                                                                                            {
                                                                                                                                                'in-progress' === jobDetails['status'] && (
                                                                                                                                                    <button
                                                                                                                                                        type='button'
                                                                                                                                                        className="inline-flex p-1 items-center rounded bg-red-700 border-none text-xs text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-0"
                                                                                                                                                        onClick={() => rejectAssignedCandidate(candidate, designation)}
                                                                                                                                                    >
                                                                                                                                                        <FiX size={12}/>{t('Remove')}
                                                                                                                                                    </button>
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                        </td>
                                                                                                                                        {/* {
                                                                                                                                            hasRoleModerator && (
                                                                                                                                                <td className="relative whitespace-nowrap p-2 text-right text-sm font-medium w-12">
                                                                                                                                                    <Switch
                                                                                                                                                        size="sm"
                                                                                                                                                        disabled={'published' !== jobDetails['status']}
                                                                                                                                                        checked={!!candidate['finalApprove']}
                                                                                                                                                        color={'bg-indigo-800'}
                                                                                                                                                        onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                                                                        offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                                                                        onChange={(e) => assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate, 'final-approve')}
                                                                                                                                                    />
                                                                                                                                                </td>
                                                                                                                                            )
                                                                                                                                        } */}
                                                                                                                                    </tr>
                                                                                                                                )) : (
                                                                                                                                    <tr>
                                                                                                                                        <td colSpan={4} className="text-red-700 bg-red-100 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm">
                                                                                                                                            {t('NoAvailableCandidates')}
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                                )
                                                                                                                            }


                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </Tab.Panel>
                                                                                                                <Tab.Panel>
                                                                                                                    <table className="min-w-full divide-y divide-gray-300">
                                                                                                                        <thead className="bg-gray-50">
                                                                                                                            <tr>
                                                                                                                                <th scope="col"
                                                                                                                                    className="py-1 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t('Name')}
                                                                                                                                </th>
                                                                                                                                <th scope="col"
                                                                                                                                    className="py-1 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">{t('Phone')}
                                                                                                                                </th>

                                                                                                                                <th scope="col"
                                                                                                                                    className="py-1 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">{t('Status')}
                                                                                                                                </th>
                                                                                                                                {
                                                                                                                                    hasRoleModerator && (
                                                                                                                                        <th scope="col" className="py-1 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                                                                            {t('FinalApproval')}
                                                                                                                                        </th>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                                                                                            {
                                                                                                                                designation['candidates'] && designation['candidates']['self'] ? designation['candidates']['self'].map((candidate, index) => (
                                                                                                                                    <tr key={candidate.id}>
                                                                                                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                                                                                                            <div className="flex items-center">
                                                                                                                                                <div className="h-10 w-10 flex-shrink-0">
                                                                                                                                                    <img className="h-10 w-10 rounded-full"
                                                                                                                                                        src={candidate['photo'] ? `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${candidate['photo']}` : "/assets/images/avatar.svg"}
                                                                                                                                                        // src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                                                                                                                        alt="" />
                                                                                                                                                </div>
                                                                                                                                                <div className="ml-4 hover:text-indigo-900">
                                                                                                                                                    <div className="font-medium text-gray-900">
                                                                                                                                                        {candidate.name}
                                                                                                                                                        { hasRoleModerator && (
                                                                                                                                                            <span 
                                                                                                                                                                onClick={(e) => {
                                                                                                                                                                    setCandidateId(candidate['userId'])
                                                                                                                                                                    setShowCandidateProfile(true)
                                                                                                                                                                }}

                                                                                                                                                                className="cursor-pointer ml-2 inline-flex items-center rounded bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800"
                                                                                                                                                            >
                                                                                                                                                                {t('Details')}
                                                                                                                                                            </span>
                                                                                                                                                        ) }
                                                                                                                                                        </div>
                                                                                                                                                    <div className="text-gray-500">{candidate.email}</div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">
                                                                                                                                            <div className="text-gray-900">{candidate.phoneNumber}</div>
                                                                                                                                        </td>

                                                                                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">
                                                                                                                                            <div className="text-gray-900">{t('Applied')}</div>
                                                                                                                                        </td>
                                                                                                                                        {
                                                                                                                                            hasRoleModerator && (
                                                                                                                                                <td className="relative whitespace-nowrap p-2 text-right text-sm font-medium w-12">
                                                                                                                                                    <Switch
                                                                                                                                                        size="sm"
                                                                                                                                                        disabled={'published' !== jobDetails['status']}
                                                                                                                                                        checked={!!candidate['finalApprove']}
                                                                                                                                                        color={'bg-indigo-800'}
                                                                                                                                                        onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                                                                        offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                                                                        onChange={(e) => assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate, 'final-approve')}
                                                                                                                                                    />
                                                                                                                                                </td>
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    </tr>
                                                                                                                                )) : (
                                                                                                                                    <tr>
                                                                                                                                        <td colSpan={4} className="text-red-700 bg-red-100 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm">
                                                                                                                                            {t('NoAvailableCandidates')}
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                                )
                                                                                                                            }


                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </Tab.Panel>
                                                                                                                <Tab.Panel>
                                                                                                                    <ScrollArea scrollbarSize={4} style={{ height: 400 }}>
                                                                                                                        <table className="min-w-full divide-y divide-gray-300">
                                                                                                                            <thead className="bg-gray-50">
                                                                                                                                <tr>
                                                                                                                                    <th scope="col"
                                                                                                                                        className="py-1 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t('Name')}
                                                                                                                                    </th>
                                                                                                                                    <th scope="col" className="py-1 text-center text-sm font-semibold text-gray-900">{t('Phone')}</th>
                                                                                                                                    <th scope="col" className="py-1 text-center text-sm font-semibold text-gray-900">{t('Status')}</th>
                                                                                                                                    {/* { hasRoleModerator && <th scope="col" className="py-1 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6">{t('FinalApproval')}</th> } */}
                                                                                                                                    
                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                                                                                                {
                                                                                                                                    designation['finalCandidates'].length > 0 ? designation['finalCandidates'].map((candidate, index) => (

                                                                                                                                        candidate['process'] !== 'additional' && (
                                                                                                                                            <tr key={candidate.id}>
                                                                                                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                                                                                                                    <div className="flex items-center">
                                                                                                                                                        <div className="h-10 w-10 flex-shrink-0">
                                                                                                                                                            <img className="h-10 w-10 rounded-full"
                                                                                                                                                                // src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                                                                                                                                src={candidate['photo'] ? `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${candidate['photo']}` : "/assets/images/avatar.svg"}
                                                                                                                                                                alt="" />
                                                                                                                                                        </div>
                                                                                                                                                        <div className="ml-4">
                                                                                                                                                            <div className="font-medium text-gray-900">
                                                                                                                                                                {candidate.name}
                                                                                                                                                                { hasRoleModerator && (
                                                                                                                                                                    <span 
                                                                                                                                                                        onClick={(e) => {
                                                                                                                                                                            setCandidateId(candidate['userId'])
                                                                                                                                                                            setShowCandidateProfile(true)
                                                                                                                                                                        }}

                                                                                                                                                                        className="cursor-pointer ml-2 inline-flex items-center rounded bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800"
                                                                                                                                                                    >
                                                                                                                                                                        {t('Details')}
                                                                                                                                                                    </span>
                                                                                                                                                                ) }
                                                                                                                                                            </div>
                                                                                                                                                            <div className="text-gray-500">{candidate.email}</div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
                                                                                                                                                    <div className="text-gray-900">{candidate.phoneNumber}</div>
                                                                                                                                                </td>
                                                                                                                                                <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
                                                                                                                                                    <div className="text-gray-900">
                                                                                                                                                        {candidate.process === 'preferred' ? t('InvitationAccepted') : '' }
                                                                                                                                                        {candidate.process === 'self' ? t('Selected') : '' }
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                {
                                                                                                                                                    hasRoleModerator && (
                                                                                                                                                        <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
                                                                                                                                                            <button
                                                                                                                                                                className="inline-flex items-center ml-6 rounded bg-red-700 border-none p-1 text-xs font-normal text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-0"
                                                                                                                                                                onClick={() => rejectAssignedCandidate(candidate, designation)}
                                                                                                                                                            >
                                                                                                                                                                <FiX size={16} className='mr-1'/>{t('Remove')}
                                                                                                                                                            </button>
                                                                                                                                                            
                                                                                                                                                        </td>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                                

                                                                                                                                            </tr>
                                                                                                                                        )
                                                                                                                                        
                                                                                                                                    )) : (
                                                                                                                                        <tr>
                                                                                                                                            <td colSpan={4} className="text-red-700 bg-red-100 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm">
                                                                                                                                                {t('NoAvailableCandidates')}
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    )

                                                                                                                                }
                                                                                                                                {
                                                                                                                                    designation['finalCandidates'].length > 0 && designation['finalCandidates'].some(item => item.process === 'additional') && (
                                                                                                                                        <tr>
                                                                                                                                            <th colSpan={4} scope="col" className="py-1 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                                                                                {t('AdditionalCandidates')}
                                                                                                                                            </th>
                                                                                                                                            
                                                                                                                                        </tr>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                

                                                                                                                                {

                                                                                                                                    designation['finalCandidates'].length > 0 && designation['finalCandidates'].map((candidate, index) => (
                                                                                                                                        candidate['process'] === 'additional' &&
                                                                                                                                            <Fragment>
                                                                                                                                                <tr key={candidate.id}>
                                                                                                                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                                                                                                                        <div className="flex items-center">
                                                                                                                                                            <div className="h-10 w-10 flex-shrink-0">
                                                                                                                                                                <img className="h-10 w-10 rounded-full"
                                                                                                                                                                    // src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                                                                                                                                    src={candidate['photo'] ? `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${candidate['photo']}` : "/assets/images/avatar.svg"}
                                                                                                                                                                    alt="" />
                                                                                                                                                            </div>
                                                                                                                                                            <div className="ml-4">
                                                                                                                                                                <div className="font-medium text-gray-900">
                                                                                                                                                                    {candidate.name}
                                                                                                                                                                    { hasRoleModerator && (
                                                                                                                                                                    <span 
                                                                                                                                                                        onClick={(e) => {
                                                                                                                                                                            setCandidateId(candidate['userId'])
                                                                                                                                                                            setShowCandidateProfile(true)
                                                                                                                                                                        }}

                                                                                                                                                                        className="cursor-pointer ml-2 inline-flex items-center rounded bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800"
                                                                                                                                                                    >
                                                                                                                                                                        {t('Details')}
                                                                                                                                                                    </span>
                                                                                                                                                                ) }
                                                                                                                                                                    </div>
                                                                                                                                                                <div className="text-gray-500">{candidate.email}</div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </td>
                                                                                                                                                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
                                                                                                                                                        <div className="text-gray-900">{candidate.phoneNumber}</div>
                                                                                                                                                    </td>
                                                                                                                                                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
                                                                                                                                                        <div className="text-gray-900">
                                                                                                                                                            {t('Selected')}
                                                                                                                                                        </div>
                                                                                                                                                    </td>
                                                                                                                                                    <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
                                                                                                                                                        <button
                                                                                                                                                            className="inline-flex items-center ml-6 rounded bg-red-700 border-none p-1 text-xs font-normal text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-0"
                                                                                                                                                            onClick={() => rejectAssignedCandidate(candidate, designation)}
                                                                                                                                                        >
                                                                                                                                                            <FiX size={16} className='mr-1'/>{t('Remove')}
                                                                                                                                                        </button>
                                                                                                                                                        
                                                                                                                                                    </td>

                                                                                                                                                </tr>
                                                                                                                                            </Fragment>
                                                                                                                                    ))
                                                                                                                                }
                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                    </ScrollArea>
                                                                                                                </Tab.Panel>



                                                                                                            {/* {
                                                                                                                designation['candidates'] && Object.keys(designation['candidates']).map((flag, index) => (
                                                                                                                    'additional' !== flag && (
                                                                                                                        <Tab.Panel>
                                                                                                                            <table className="min-w-full divide-y divide-gray-300">
                                                                                                                                <thead className="bg-gray-50">
                                                                                                                                    <tr>
                                                                                                                                        <th scope="col"
                                                                                                                                            className="py-1 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t('Name')}
                                                                                                                                        </th>
                                                                                                                                        <th scope="col"
                                                                                                                                            className="py-1 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">{t('Phone')}
                                                                                                                                        </th>

                                                                                                                                        <th scope="col"
                                                                                                                                            className="py-1 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">{t('Status')}
                                                                                                                                        </th>
                                                                                                                                        {
                                                                                                                                            hasRoleModerator && (
                                                                                                                                                <th scope="col" className="py-1 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                                                                                    {t('FinalApprove')}
                                                                                                                                                </th>
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                                                                                                    {designation['candidates'][flag] && designation['candidates'][flag].map((candidate, index) => (
                                                                                                                                        <>
                                                                                                                                            <tr key={candidate.id}>
                                                                                                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                                                                                                                    <div className="flex items-center cursor-pointer"
                                                                                                                                                        onClick={(e) => {
                                                                                                                                                            setCandidateId(candidate['userId'])
                                                                                                                                                            setShowCandidateProfile(true)
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <div className="h-10 w-10 flex-shrink-0">
                                                                                                                                                            <img className="h-10 w-10 rounded-full"
                                                                                                                                                                src={candidate['photo'] ? `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${candidate['photo']}` : "/assets/images/avatar.svg"}
                                                                                                                                                                // src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                                                                                                                                alt="" />
                                                                                                                                                        </div>
                                                                                                                                                        <div className="ml-4 hover:text-indigo-900">
                                                                                                                                                            <div className="font-medium text-gray-900 hover:text-indigo-900 hover:underline">{candidate.name}</div>
                                                                                                                                                            <div className="text-gray-500">{candidate.email}</div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">
                                                                                                                                                    <div className="text-gray-900">{candidate.phoneNumber}</div>
                                                                                                                                                </td>

                                                                                                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">
                                                                                                                                                    <div className="text-gray-900">{candidate['candidateConfirmationBy'] ? 'Yes' : 'No'}</div>
                                                                                                                                                </td>
                                                                                                                                                {
                                                                                                                                                    hasRoleModerator && (
                                                                                                                                                        <td className="relative whitespace-nowrap p-2 text-right text-sm font-medium w-12">
                                                                                                                                                            <Switch
                                                                                                                                                                size="sm"
                                                                                                                                                                disabled={'published' !== jobDetails['status']}
                                                                                                                                                                checked={!!candidate['finalApprove']}
                                                                                                                                                                color={'bg-indigo-800'}
                                                                                                                                                                onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                                                                                offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                                                                                onChange={(e) => assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate, 'final-approve')}
                                                                                                                                                            />
                                                                                                                                                        </td>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            </tr>
                                                                                                                                        </>

                                                                                                                                    ))}

                                                                                                                                    {
                                                                                                                                        designation['candidates'][flag].length === 0 && (
                                                                                                                                            <tr>
                                                                                                                                                <td colSpan={4} className="text-red-400 text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm">
                                                                                                                                                    {t('NoAvailableCandidates')}
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        )
                                                                                                                                    }

                                                                                                                                </tbody>
                                                                                                                            </table>
                                                                                                                        </Tab.Panel>

                                                                                                                    )
                                                                                                                ))
                                                                                                            } */}

                                                                                                            {/* {
                                                                                                                ((designation['finalCandidates'] && designation['finalCandidates'].length > 0) || (designation['candidates']['additional'] && designation['candidates']['additional'].length > 0)) && (
                                                                                                                    <Tab.Panel>
                                                                                                                        <ScrollArea scrollbarSize={4} style={{ height: 400 }}>
                                                                                                                            <table className="min-w-full divide-y divide-gray-300">
                                                                                                                                <thead className="bg-gray-50">
                                                                                                                                    <tr>
                                                                                                                                        <th scope="col"
                                                                                                                                            className="py-1 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t('Name')}
                                                                                                                                        </th>
                                                                                                                                        <th scope="col"
                                                                                                                                            className="py-1 text-center text-sm font-semibold text-gray-900">{t('Phone')}
                                                                                                                                        </th>
                                                                                                                                        <th scope="col" className="py-1 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6">{t('Action')}
                                                                                                                                        </th>
                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                                                                                                    {
                                                                                                                                        designation['finalCandidates'] && designation['finalCandidates'].map((candidate, index) => (
                                                                                                                                            <tr key={candidate.id}>
                                                                                                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                                                                                                                    <div
                                                                                                                                                        className="flex items-center cursor-pointer"
                                                                                                                                                        onClick={(e) => {
                                                                                                                                                            setCandidateId(candidate['userId'])
                                                                                                                                                            setShowCandidateProfile(true)
                                                                                                                                                        }}
                                                                                                                                                        >
                                                                                                                                                        <div className="h-10 w-10 flex-shrink-0">
                                                                                                                                                            <img className="h-10 w-10 rounded-full"
                                                                                                                                                                // src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                                                                                                                                src={candidate['photo'] ? `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${candidate['photo']}` : "/assets/images/avatar.svg"}
                                                                                                                                                                alt="" />
                                                                                                                                                        </div>
                                                                                                                                                        <div className="ml-4">
                                                                                                                                                            <div className="font-medium text-gray-900 hover:text-indigo-900 hover:underline">{candidate.name}</div>
                                                                                                                                                            <div className="text-gray-500">{candidate.email}</div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
                                                                                                                                                    <div className="text-gray-900">{candidate.phoneNumber}</div>
                                                                                                                                                </td>
                                                                                                                                                <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
                                                                                                                                                    <button
                                                                                                                                                        className="inline-flex items-center ml-6 rounded bg-red-700/[.6] border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-0"
                                                                                                                                                        onClick={() => rejectAssignedCandidate(candidate, designation)}
                                                                                                                                                    >
                                                                                                                                                        {t('Reject')}
                                                                                                                                                    </button>
                                                                                                                                                    
                                                                                                                                                </td>

                                                                                                                                            </tr>
                                                                                                                                        ))

                                                                                                                                    }
                                                                                                                            

                                                                                                                                    {
                                                                                                                                        designation['candidates']['additional'] && (
                                                                                                                                            <tr>
                                                                                                                                                <th colSpan={2} scope="col" className="py-1 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                                                                                    {t('AdditionalCandidates')}
                                                                                                                                                </th>
                                                                                                                                                <th scope="col" className="py-1 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6">
                                                                                                                                                    {t('FinalApprove')}
                                                                                                                                                </th>
                                                                                                                                            </tr>
                                                                                                                                        )
                                                                                                                                    }

                                                                                                                                    {
                                                                                                                                        designation['candidates']['additional'] && designation['candidates']['additional'].map((candidate, index) => (
                                                                                                                                            <tr key={candidate.id}>
                                                                                                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                                                                                                                    <div
                                                                                                                                                        className="flex items-center cursor-pointer"
                                                                                                                                                        onClick={(e) => {
                                                                                                                                                            setCandidateId(candidate['userId'])
                                                                                                                                                            setShowCandidateProfile(true)
                                                                                                                                                        }}
                                                                                                                                                        >
                                                                                                                                                        <div className="h-10 w-10 flex-shrink-0">
                                                                                                                                                            <img className="h-10 w-10 rounded-full"
                                                                                                                                                                // src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                                                                                                                                src={candidate['photo'] ? `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${candidate['photo']}` : "/assets/images/avatar.svg"}
                                                                                                                                                                alt="" />
                                                                                                                                                        </div>
                                                                                                                                                        <div className="ml-4">
                                                                                                                                                            <div className="font-medium text-gray-900 hover:text-indigo-900 hover:underline">{candidate.name}</div>
                                                                                                                                                            <div className="text-gray-500">{candidate.email}</div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
                                                                                                                                                    <div className="text-gray-900">{candidate.phoneNumber}</div>
                                                                                                                                                </td>
                                                                                                                                                <td className="whitespace-nowrap px-3 py-4 text-right text-sm text-gray-500">
                                                                                                                                                    <Switch
                                                                                                                                                        size="sm"
                                                                                                                                                        checked={!!candidate['finalApprove']}
                                                                                                                                                        color={'bg-indigo-800'}
                                                                                                                                                        onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                                                                        offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                                                                                        onChange={(e) => assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate, 'final-approve')}
                                                                                                                                                    />
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        ))
                                                                                                                                    }
                                                                                                                                </tbody>
                                                                                                                            </table>
                                                                                                                        </ScrollArea>
                                                                                                                    </Tab.Panel>
                                                                                                                )
                                                                                                            } */}
                                                                                                        </Tab.Panels>
                                                                                                    </Tab.Group>
                                                                                                </div>
                                                                                            </div>
                                                                                        </ScrollArea>
                                                                                        {

                                                                                            <footer className="relative mt-1 border border-t px-3 flex flex-row justify-between">
                                                                                                    <div className='flex flex-row'>
                                                                                                        <button
                                                                                                            className="px-6 py-2 ml-1 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-blue-600 bg-blue-700 items-center border rounded text-sm"
                                                                                                            onClick={() => setShowJobViewModal(true)}
                                                                                                            type='button'
                                                                                                        >
                                                                                                            <HiOutlineEye size={16} className={'mr-2'} /><span>{t('Preview')}</span>
                                                                                                        </button>
                                                                                                        
                                                                                                    </div>
                                                                                                    <div className="text-right pt-0.5 mb-0.5">

                                                                                                        <button
                                                                                                            className="inline-flex items-center ml-6 rounded bg-red-700 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-0"
                                                                                                            onClick={() => {
                                                                                                                setShowPublishButton(false)
                                                                                                                setSelectedTabIndex(prevState => prevState - 1)
                                                                                                            }}
                                                                                                        >
                                                                                                            <HiOutlineChevronLeft size={16} className={'mr-2'} />{t('Previous')}

                                                                                                        </button>
                                                                                                        {/* {
                                                                                                            hasRoleClient && jobDetails['designations'].length - 1 === index && (
                                                                                                                <button
                                                                                                                    className="inline-flex items-center ml-1 rounded bg-gray-200 border-none py-2 px-4 text-sm font-normal text-slate-700 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-0"
                                                                                                                    onClick={() => navigate('/job-post')}
                                                                                                                >
                                                                                                                    <FiX size={16} className={'mr-2'} />{t('Close')}

                                                                                                                </button>
                                                                                                            )
                                                                                                        } */}

                                                                                                        <button
                                                                                                            className="inline-flex items-center ml-1 rounded bg-indigo-800 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                                                                                            onClick={() => {
                                                                                                                setShowPublishButton(jobDetails['designations'].length - 1 === index)
                                                                                                                setSelectedTabIndex(prevState => prevState + 1)
                                                                                                            }}
                                                                                                        >
                                                                                                            <HiOutlineChevronRight size={16} className={'mr-2'} />{t('Next')}

                                                                                                        </button>
                                                                                                    </div>
                                                                                                
                                                                                            </footer>
                                                                                        }
                                                                                    </Tab.Panel>

                                                                                )

                                                                            ))
                                                                        }

                                                                        <Tab.Panel key={designation.id}>
                                                                            {/* <ScrollArea scrollbarSize={4} h={fullFormHeight}> */}
                                                                                <div className="w-full bg-indigo-100 p-2 text-gray-900 rounded">
                                                                                    <Grid>
                                                                                        <Grid.Col span='auto'>
                                                                                            <Text size="sm" weight={400} fw={700}>{t('JobBasicInformation')}</Text>
                                                                                        </Grid.Col>
                                                                                        <Grid.Col span={4}>
                                                                                            <Text size="md" weight={400} fw={700}>{jobDetails && jobDetails['title']}</Text>
                                                                                        </Grid.Col>
                                                                                        
                                                                                        <Grid.Col span='auto'>
                                                                                            <Text size="sm" weight={400} fw={700}>{t('JobDetails')} :</Text>   
                                                                                        </Grid.Col>
                                                                                    </Grid>
                                                                                    <Grid>
                                                                                        <Grid.Col span='auto'>
                                                                                            <div className='flex flex-row'>
                                                                                                <Text size="sm" weight={400} fw={700} className="text-gray-700">{t('Client')} : </Text>
                                                                                                <Text size="sm" weight={400} className="text-gray-700 ml-1"> {jobDetails && jobDetails['clientName']}</Text>
                                                                                            </div>
                                                                                        </Grid.Col>
                                                                                        <Grid.Col span='auto'>

                                                                                            <div className='flex flex-start w-full'>
                                                                                                <Text size="sm" weight={400} fw={700} className='w-1/2'>{t('Place')}</Text>
                                                                                                <Text size="sm" weight={400} className='w-1/2'>: {jobDetails && jobDetails['streetAddress']}</Text>
                                                                                            </div>
                                                                                            <div className='flex flex-start w-full'>
                                                                                                <Text size="sm" weight={400} fw={700} className='w-1/2'>{t('TotalNoOfPerson')}</Text>
                                                                                                <Text size="sm" weight={400} className='w-1/2'>: {jobDetails && jobDetails['vacancy']}</Text>
                                                                                            </div>
                                                                                            
                                                                                        </Grid.Col>
                                                                                        <Grid.Col span='auto'>
                                                                                            <div className='flex flex-start w-full'>
                                                                                                <Text size="sm" weight={400} fw={700} className='w-1/2'>{t('StartDate')}</Text>
                                                                                                <Text size="sm" weight={400} className='w-1/2'>: {jobDetails && jobDetails['startTime'] && format(new Date(jobDetails['startTime'].date), 'MM/dd/Y')}</Text>
                                                                                            </div>
                                                                                            <div className='flex flex-start w-full'>
                                                                                                <Text size="sm" weight={400} fw={700} className='w-1/2'>{t('EndDate')}</Text>
                                                                                                <Text size="sm" weight={400} className='w-1/2'>: {jobDetails && jobDetails['endTime'] && format(new Date(jobDetails['endTime'].date), 'MM/dd/Y')}</Text>
                                                                                            </div>
                                                                                        </Grid.Col>
                                                                                        <Grid.Col span={4}>
                                                                                            <ScrollArea scrollbarSize={4} style={{ height: 96}} type='auto' className="border border-1 border-gray-400 p-1 rounded">
                                                                                                <Text size="sm" weight={400}>{jobDetails && jobDetails['description']}</Text>
                                                                                            </ScrollArea>
                                                                                        </Grid.Col>
                                                                                    </Grid>
                                                                                </div>


                                                                                <ScrollArea scrollbarSize={4} h={window.innerHeight - (58 + 48 + 148 + 110)}>
                                                                                    {
                                                                                        Object.values(jobDetails).length > 0 && jobDetails['designations'].length > 0 && jobDetails['designations'].map((designation, index)=>(
                                                                                            designation['designationSlug'] && (
                                                                                                <PreviewPublishDesignationList
                                                                                                    key={designation['id']}
                                                                                                    designation={designation}
                                                                                                    rejectAssignedCandidate={rejectAssignedCandidate}
                                                                                                    setCandidateId={setCandidateId}
                                                                                                    setShowCandidateProfile={setShowCandidateProfile}
                                                                                                    assignedCandidateAcceptance={assignedCandidateAcceptance}
                                                                                                    jobDetails={jobDetails}
                                                                                                    setRecall={setRecall}
                                                                                                />
                                                                                            )
                                                                                        ))
                                                                                    }
                                                                                </ScrollArea>
                                                                            {/* </ScrollArea> */}
                                                                            <footer className="relative mt-1 border border-t px-3 flex flex-row justify-between">
                                                                                <div className="pt-0.5 mb-0.5">
                                                                                    <button
                                                                                        className="px-6 py-2 ml-1 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-sm"
                                                                                        onClick={() => setShowJobViewModal(true)}
                                                                                        type='button'
                                                                                    >
                                                                                        <HiOutlineEye size={16} className={'mr-2'} /><span>{t('Preview')}</span>
                                                                                    </button>
                                                                                </div>

                                                                                
                                                                                <div className="pt-0.5 mb-0.5">
                                                                                    <button
                                                                                        className="inline-flex items-center rounded bg-red-700 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-0"
                                                                                        onClick={() => {
                                                                                            setShowPublishButton(false)
                                                                                            setSelectedTabIndex(prevState => prevState - 1)
                                                                                        }}
                                                                                        type='button'
                                                                                    >
                                                                                        <HiOutlineChevronLeft size={16} className={'mr-2'} />{t('Previous')}

                                                                                    </button>

                                                                                    {
                                                                                        hasRoleClient && (
                                                                                            <button
                                                                                                className="ml-1 inline-flex items-center rounded bg-green-700 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-0"
                                                                                                onClick={() => navigate('/job-post')}
                                                                                            >
                                                                                                <HiArrowDownTray size={16} className={'mr-2'} />{t('Submit')}
                                                                                            </button>
                                                                                        )
                                                                                    }
                                                                                   
                                                                                    <button
                                                                                        className="ml-1 inline-flex items-center rounded bg-slate-700 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-slate-600 focus:outline-none focus:ring-0"
                                                                                        onClick={() => navigate('/job-post')}
                                                                                    >
                                                                                        <HiX size={16} className={'mr-2'} />{t('Close')}
                                                                                    </button>
                                                                                </div>
                                                                                {/* {
                                                                                    hasRoleModerator && Object.values(jobDetails).length > 0 && 'in-progress' === jobDetails['status'] && (
                                                                                        <form method='post' onSubmit={handleSubmit(jobPublish)} className='w-full flex flex-row justify-between basis-2/3'>
                                                                                                <div className="flex ml-2">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        className="disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:hover:bg-gray-50 mt-2 h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-transparent"
                                                                                                        onChange={e => setValue('sendAll', e.target.checked)}
                                                                                                        name="send-all"
                                                                                                        id="send-all"
                                                                                                        disabled={('published' === jobDetails['status']) || publishSpinner}

                                                                                                    />
                                                                                                    <label htmlFor="send-all" className="form-input-sm-label ml-1"> {t('SendInvitationToAll')}</label>
                                                                                                </div>
                                                                                                
                                                                                            <div className="pt-0.5 mb-0.5">
                                                                                                <button
                                                                                                    type='submit'
                                                                                                    disabled={publishSpinner}
                                                                                                    className="w-80 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center justify-center rounded bg-green-600 border-none py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-0"

                                                                                                >
                                                                                                    {
                                                                                                        publishSpinner ? <AiOutlineLoading3Quarters className={'mr-2 animate-spin'} size={16} /> : <HiOutlineBookOpen  size={16} className={'mr-2'} />
                                                                                                    }
                                                                                                    {t('Publish')}

                                                                                                </button>
                                                                                            </div>
                                                                                            
                                                                                        </form>
                                                                                    )
                                                                                } */}
                                                                                
                                                                                {/* {
                                                                                    'published' === jobDetails['status'] && (
                                                                                        <div className="pt-0.5 mb-0.5 text-center">
                                                                                            <button
                                                                                                type='button'
                                                                                                className="w-80 justify-center cursor-auto inline-flex items-center rounded bg-gray-50 hover:bg-gray-50 border-none py-2 px-4 text-sm font-normal text-gray-500 shadow-sm focus:outline-none focus:ring-0"

                                                                                            >
                                                                                            <HiOutlineBookOpen  size={16} className={'mr-2'} /> {t('Published')}

                                                                                            </button>
                                                                                        </div>
                                                                                    )
                                                                                } */}

                                                                                
                                                                                
                                                                            </footer>
                                                                        </Tab.Panel>

                                                                    </Tab.Panels>
                                                                </Tab.Group>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            { showRemoveModal && <RemoveConfirmation setShowRemoveModal={setShowRemoveModal} removeRequiredDesignation={removeRequiredDesignation} setRecall={setRecall} recall={recall} /> }
            { showCandidatePreferModal && <CandidatePreferableConfirmation setShowCandidatePreferModal={setShowCandidatePreferModal} updateJobDetails={updateJobDetails} setSelectedTabIndex={setSelectedTabIndex} /> }
            {/* { showCandidateListModal && <AddJobCandidate setShowModal={setShowCandidateListModal} candidates={candidates} designation={designation} recall={recall} setRecall={setRecall} /> } */}
            { showCandidateListModal && <AddJobCandidate setShowModal={setShowCandidateListModal} designation={designation} setRecall={setRecall} /> }
            { showJobSaveConfirmationModal && <JobSaveConfirmationModal setShowModal={setShowJobSaveConfirmationModal} updateJobDetails={updateJobDetails} setSelectedTabIndex={setSelectedTabIndex} /> }
            { showJobViewModal && <ViewJob setShowModal={setShowJobViewModal} jobDetails={jobDetails} roles={roles} jobView={getJobDetails} />  }
            { showTimeMatrixModal && <TimeMatrixModal setShowModal={setShowTimeMatrixModal} reqDesignationId={reqDesignationId} setRecallReqDesignation={setRecallReqDesignation}/> }
            { showCandidateProfile && <ViewCandidate setShowModal={setShowCandidateProfile} candidateid={candidateId}/> }


        </>
    )
}

export default Update