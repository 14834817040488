import React, { useState,useEffect } from "react";
import { HiOutlineArrowRight, HiOutlineX } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { ScrollArea, LoadingOverlay } from '@mantine/core';
import { format } from 'date-fns';



export default function BreakTimesModal({ setShowModal, breakTimes }) {
    const { token, loggedUserId } = JSON.parse(localStorage.getItem('user'))
    const { t, i18n } = useTranslation();

    const breakTimesRow = breakTimes ? breakTimes.split(',') : [];

    const breakTimesArray = breakTimesRow ? breakTimesRow.map((row) => row.split('#')) : [];

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.8]">
                <div className="relative w-3/4">
                    {/*content*/}
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="pt-4 modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('BreakTimes')}</h5>
                                <button
                                    onClick={() => {
                                        setShowModal(false)
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>

                            <div className="modal-body relative">
                                <div>
                                    <ScrollArea scrollbarSize={4} style={{ height: 500 }} className="relative">
                                        { <LoadingOverlay visible={!breakTimesArray} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'sm' }} overlayColor="bg-indigo-100"/> }
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col"
                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t('Date')}
                                                    </th>
                                                    <th scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{t('InTime')}
                                                    </th>
                                                    <th scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{t('OutTime')}
                                                    </th>

                                                    <th scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{t('Duration')}
                                                    </th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                
                                                {
                                                    breakTimesArray.map((record, index)=> (
                                                        <tr key={index}>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">{record[0] && format(new Date(record[0]), 'MM/dd/yyyy')}</td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record[1] && format(new Date(record[1]), 'HH:mm')}</td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record[2] && format(new Date(record[2]), 'HH:mm')}</td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record[3] + ' min'}</td>
                                                        </tr>
                                                    ))
                                                }
                                                

                                            </tbody>
                                        </table>
                                    </ScrollArea>
                                </div>
                            </div>

                            {/*<div
                                className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                <button onClick={() => {
                                    setRecall(!recall)
                                    setShowModal(false)
                                }}
                                        className="bg-grey-light hover:bg-grey rounded inline-flex items-cente focus:outline-none focus:ring-1 mt-1 focus:ring-offset-1  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-4 py-2 text-sm">
                                    <HiOutlineX
                                        className="mr-2 h-5 w-5"
                                        aria-hidden="true"
                                    /><span>{t('Close')}</span>
                                </button>

                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red" />
        </>
    );
}
