import React, {useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiEye,
    HiOutlineChartSquareBar, HiOutlineExclamation,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiPencil, HiTrash
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Group, Text,Box,ScrollArea} from "@mantine/core";
import {FiAirplay, FiSave, FiX} from "react-icons/fi";
import {TbCurrencyTaka} from "react-icons/tb";
import {MdOutlineMonitorWeight} from "react-icons/md";
import {Switch} from "@headlessui/react";
import {AiOutlineLoading3Quarters, AiOutlineMinus} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import container from "postcss/lib/container";


const PAGE_SIZE = 1;

function NewUser() {

    const {t, i18n} = useTranslation();
    const innerContainer = useRef(100);
    const height = innerContainer.current.clientHeight;
    var tableHeight = (height-63);
    var fromHeight = height-153+"px";


    const [items, setItemTypes] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isAddForm, setAddForm] = useState(true);

    //Add Model Init
    let [isOpen, setIsOpen] = useState(false);

    //Submit Spinner Init
    const [spinner, SetSpinner] = useState(false);

    //Add Model close function
    function closeModal() {
        setIsOpen(false)
    }

    //Add Model Open function
    function openModal() {
        setIsOpen(true)
    }

    const [page, setPage] = useState(1);
    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setItemTypes(items.slice(from, to));
    }, [page]);

    //Form validation
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t("NameIsRequired")),
        employeeId: Yup.string().required(t("EmployeeIDIsRequired")),
        mobile: Yup.string().required(t("MobileNumberIsRequired")),
        designation : Yup.string().required(t('SelectDesignation')),
        department : Yup.string().required(t('SelectDepartment')),
        username : Yup.string().required(t('EmailIsRequired')),
        password : Yup.string().required(t('PasswordIsRequired')),
        password_second : Yup.string().required(t('PleaseConfirmPassword'))
       
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const [noOfRows, setNoOfRows] = useState(1);
    const [status, setStatus] = useState(true);


    //Form Data Submit
    function formSubmit(data) {
        // SetSpinner(true);
         console.log(data)
        const formData = {
            name: data.name,
            mobile : data.name,
            employeeId : data.name,
            designation : data.name,
            department : data.name,
            status:1
        };

        
    }

    return(
        <>
        
        <main className="flex flex-1 " ref={innerContainer}>
            <div className="min-h-full w-full">
                <div className="flex w-full md:w-auto">
                    <div className="bg-red-100 flex-1">
                        <div className="min-w-0 flex-1 bg-white xl:flex">
                          <div className="bg-white lg:min-w-0 lg:flex-1">
                             <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                              <div className="relative">
                                <div className="w-full flex -ml-4 pl-4 h-12 bg-indigo-50 justify-start text-gray-600 mb-3">
                                 <div className={"flex-1"}>
                                   <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                                      <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                        <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                            {t('ManageNewUser')}
                                        <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('ManageUserAndStoreInformation')}</sub></span>
                                            </div>
                                                </div>
                                                   
                                                    </div>
                                                    <div className={'flex flex-1 overflow-hidden w-full'}>
                                                        

                                            {isAddForm ?
                                            <div className="w-full mr-0 mx-auto border">
                                                <header className="relative mb-1 bg-indigo-600">
                                                        <div
                                                            className="text-gray-400  text-right sm:px-2 sm:py-1">
                                                            <div
                                                                className="text-center flex justify-center">
                                                                <div className="text-white   text-md  text-center font-bold items-center leading-7">  {t('AddNewUser')}</div>
                                                                <br/>
                                                            </div>
                                                        </div>
                                                    </header>

                                                    <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form" >
                                                    <div  className= "px-8 py-3 relative flex flex-col min-w-0 break-words w-full bg-gray-200 border-0 inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full" style={{height:fromHeight}} > 
                                                    <div>
                                                        <div className="md:grid md:grid-cols-3 md:gap-6">
                                                        <div className="md:col-span-1">
                                                            <div className="px-4 sm:px-0">
                                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t("AccountInformation")}</h3>
                                                            <p className="mt-1 text-sm text-gray-600">
                                                                {t('EnterAccountRelatedDetails')}
                                                            </p>
                                                            </div>
                                                        </div>
                                                         {/* Account Form Start */}
                                                        <div className="mt-5 md:col-span-2 md:mt-0">
                                                            <div className="shadow sm:overflow-hidden sm:rounded-md">
                                                                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">

                                                                    {/* Full name section */} 

                                                                    <div
                                                                        className="mb-3 w-full items-center">
                                                                        <label htmlFor="name"
                                                                                className="form-input-sm-label-required">{t('FullName')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.name?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                            </div>}
                                                                            <div
                                                                                className="form-input-sm-right-icon">
                                                                                <FiAirplay
                                                                                    size={16}></FiAirplay>
                                                                            </div>
                                                                            <input
                                                                                {...register("name")}
                                                                                type="text"
                                                                                className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                name="name"
                                                                                id ="name"
                                                                                placeholder={t("EnterFullName")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {/* Employee ID and Mobile Section*/}
                                                                   
                                                                   <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                   <div
                                                                        className="mb-3 w-1/3 items-center">
                                                                        <label htmlFor="employeeId"
                                                                                className="form-input-sm-label-required">{t('EmployeeID')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.employeeId?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.employeeId?.message}
                                                                            </div>}
                                                                            
                                                                            <input
                                                                                {...register("employeeId")}
                                                                                type="text"
                                                                                className={`form-input-sm-control ${errors.employeeId ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                name="employeeId"
                                                                                id = "employeeId"
                                                                                placeholder={t("EnterEmployeeID")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className="mb-3 w-1/3 ml-5 items-center">
                                                                        <label htmlFor="mobile"
                                                                                className="form-input-sm-label-required">{t('Mobile')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.mobile?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.mobile?.message}
                                                                            </div>}
                                                                            
                                                                            <input
                                                                                {...register("mobile")}
                                                                                type="text"
                                                                                className={`form-input-sm-control ${errors.mobile ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                name="mobile"
                                                                                id = "mobile"
                                                                                placeholder={t("")}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    </div>

                                                                {/* Address Section */}
                                                                <div
                                                                        className="mb-3 w-full items-center">
                                                                        <label htmlFor="address"
                                                                                className="form-input-sm-label">{t('Address')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            <textarea
                                                                                {...register("address")}
                                                                                type="text"
                                                                                className={`form-input-sm-control`}
                                                                                name="address"
                                                                                placeholder={t("EnterEmployeeAddress")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {/* Profile Image Section */}
                                                                    <div
                                                                        className="mb-3 w-1/2 items-center">
                                                                        <label htmlFor="file"
                                                                                className="form-input-sm-label">{t('ProfileImage')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            <input
                                                                                {...register("file")}
                                                                                type="file"
                                                                                className={`form-input-sm-control`}
                                                                                name="file"
                                                                                placeholder={t("")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                     {/* Signature Section */}
                                                                     <div
                                                                        className="mb-3 w-1/2 items-center">
                                                                        <label htmlFor="signatureFile"
                                                                                className="form-input-sm-label">{t('Signature')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            <input
                                                                                {...register("signatureFile")}
                                                                                type="file"
                                                                                className={`form-input-sm-control`}
                                                                                name="signatureFile"
                                                                                placeholder={t("")}
                                                                            />
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        </div>
                                                    </div>

                                                    <div className="hidden sm:block" aria-hidden="true">
                                                        <div className="py-5">
                                                        <div className="border-t border-gray-200" />
                                                        </div>
                                                    </div>

                                                    {/* Operational Information Form Start */}
                                                    <div className="mt-10 sm:mt-0">
                                                        <div className="md:grid md:grid-cols-3 md:gap-6">
                                                        <div className="md:col-span-1">
                                                            <div className="px-4 sm:px-0">
                                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t('OperationalInformation')}</h3>
                                                            <p className="mt-1 text-sm text-gray-600">{t('EnterDetailsOfOperationalInformation')}</p>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="mt-5 md:col-span-2 md:mt-0">
                                                           
                                                            <div className="overflow-hidden shadow sm:rounded-md">
                                                                <div className="bg-white px-4 py-5 sm:p-6">
                                                                <div className="grid grid-cols-6 gap-6">
                                                                    {/* Designation Section */}
                                                                    <div className="col-span-6 sm:col-span-3">
                                                                    <label htmlFor="designation" className="form-input-sm-label-required">
                                                                        {t('Designation')}</label>

                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.designation?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.designation?.message}
                                                                            </div>}
                                                                                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                                    
                                                                                    <select
                                                                                        {...register("designation")}
                                                                                        name="designation" id ="designation"
                                                                                        className={`form-input-sm-control ${errors.designation ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} ` }>
                                                                                        <option value="">{t('SelectDesignation')}</option>
                                                                                        <option value="CEO">CEO</option>
                                                                                        <option value="Chief">Chief</option>
                                                                                        <option value="Deputy Manager">Deputy Manager</option>
                                                                                        <option value="DGM">DGM</option>
                                                                                        <option value="Manager">Manager</option>
                                                                                        <option value="Managing Director">Managing Director</option> 
                                                                                    </select>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                             </div>



                                                                    {/* Department Section */}
                                                                    <div className="col-span-6 sm:col-span-3">
                                                                    <label htmlFor="department" className="form-input-sm-label-required">
                                                                        {t('Department')}</label>

                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.department?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.department?.message}
                                                                            </div>}
                                                                                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                                    
                                                                                    <select
                                                                                        {...register("department")}
                                                                                        name="department" id ="department"
                                                                                        className={`form-input-sm-control ${errors.department ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} ` }>
                                                                                        <option value="">{t('SelectDepartment')}</option>
                                                                                        <option value="Finance">Finance</option>
                                                                                        <option value="Accounting">Accounting</option>
                                                                                        <option value="IT">IT</option> 
                                                                                    </select>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                             </div>

                                                                                    {/* Approve User Section */}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="status"
                                                                                               className="form-input-sm-label-required">{t('ApproveUser')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.status?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.status?.message}
                                                                                            </div>}
                                                                                         
                                                                                            <Switch
                                                                                                name="status"
                                                                                                {...register("status")}
                                                                                                defaultChecked={status}
                                                                                                onChange={() => {
                                                                                                    setStatus(!status)
                                                                                                }}
                                                                                                className={`${status ? 'bg-indigo-300' : 'bg-indigo-900'}
          relative inline-flex h-[24px] w-[60px] shrink-0 cursor-pointer rounded-full border-1 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                                                                                            >
                                                                                                {/*<span className="sr-only">Use setting</span>*/}
                                                                                                <span
                                                                                                    aria-hidden="true"
                                                                                                    className={`${status ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                                                                                />
                                                                                            </Switch>
                                                                                        </div>
                                                                                    </div>
                                    

                                                                   

                                                             
                                                                </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        </div>
                                                    </div>

                                                    <div className="hidden sm:block" aria-hidden="true">
                                                        <div className="py-5">
                                                        <div className="border-t border-gray-200" />
                                                        </div>
                                                    </div>

                                                    <div className="mt-10 sm:mt-0">
                                                        <div className="md:grid md:grid-cols-3 md:gap-6">
                                                        <div className="md:col-span-1">

                                                            {/* Login Information Section */}
                                                            <div className="px-4 sm:px-0">
                                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t('LoginInformation')}</h3>
                                                            <p className="mt-1 text-sm text-gray-600">{t('PasswordMustBeAtleast6Characters')}.</p>
                                                            </div>
                                                        </div>
                                                        <div className="mt-5 md:col-span-2 md:mt-0">
                                                            
                                                            <div className="overflow-hidden shadow sm:rounded-md">
                                                                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                                                                 
                                                                 {/* Username as email section */} 

                                                                 <div
                                                                        className="mb-3 w-full items-center">
                                                                        <label htmlFor="username"
                                                                                className="form-input-sm-label-required">{t('UsernameAsEmail')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.username?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.username?.message}
                                                                            </div>}
                                                                            
                                                                            <input
                                                                                {...register("username")}
                                                                                type="text"
                                                                                className={`form-input-sm-control ${errors.username ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                name="username"
                                                                                id ="username"
                                                                                placeholder={t("EnterEmailAddress")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                {/* Password & Password Confirmation Section */}
                                                                <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                   <div
                                                                        className="mb-3 w-1/3 items-center">
                                                                        <label htmlFor="password"
                                                                                className="form-input-sm-label-required">{t('Password')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.password?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.password?.message}
                                                                            </div>}
                                                                            
                                                                            <input
                                                                                {...register("password")}
                                                                                type="text"
                                                                                className={`form-input-sm-control ${errors.password ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                name="password"
                                                                                id = "password"
                                                                                placeholder={t("EnterPassword")}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className="mb-3 w-1/3 ml-5 items-center">
                                                                        <label htmlFor="password_second"
                                                                                className="form-input-sm-label-required">{t('ConfirmPassword')}</label>

                                                                        <div
                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.password_second?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.password_second?.message}
                                                                            </div>}
                                                                            
                                                                            <input
                                                                                {...register("password_second")}
                                                                                type="text"
                                                                                className={`form-input-sm-control ${errors.mobile ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                name="password_second"
                                                                                id = "password_second"
                                                                                placeholder={t("ConfirmPassword")}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    </div>

                                                                    <h1>User Role</h1>

                                                                
                                                                </div>
                                                              
                                                            </div>

                                                            
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
 
                                                    <footer className="relative mt-1">
                                                                                    <div className="container mx-auto justify-items-center">
                                                                                        <div
                                                                                            className="text-right border-t pt-0.5 mb-0.5">
                                                                                            <button onClick={event => setAddForm(prevCheck => !prevCheck)} type='reset'
                                                                                                    className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                                    ><FiX
                                                                                                size={16}
                                                                                                className={'mr-2'}></FiX>
                                                                                                <span>{t('Cancel')}</span>
                                                                                            </button>
                                                                                            <button type='submit'
                                                                                                    className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                                {spinner ? (
                                                                                                    <AiOutlineLoading3Quarters
                                                                                                        className={'mr-2 animate-spin'}
                                                                                                        size={16}></AiOutlineLoading3Quarters>
                                                                                                ) : (<FiSave size={16}
                                                                                                            className={'mr-2'}></FiSave>)}
                                                                                                <span>{t('Save')}</span>
                                                                                            </button>

                                                                                        </div>
                                                                                    </div>
                                                                                </footer>
                                                    </form>
                                                    </div>
                                                                                                                        
                                                        : ''}


                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </main>
        
        </>
    )
}

export default NewUser