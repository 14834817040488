import React from "react";
import { HiOutlineExclamation } from "react-icons/hi";
const InputSelect = ({
    labelText,
    labelFor,
    errors,
    name,
    registerForm,
    modal,
    children,
}) => {
    return (
        <div className="mb-3 w-full items-center">
            <label htmlFor={labelFor} className="form-input-sm-label-required">
                {labelText}
            </label>
            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                    {errors.country?.type === "required" && (
                        <div className="form-input-sm-error">
                            <HiOutlineExclamation
                                size={16}
                                className={"mr-2"}
                            ></HiOutlineExclamation>{" "}
                            {errors.country?.message}
                        </div>
                    )}
                    <select
                        {...registerForm}
                        name={name}
                        autoComplete="country-name"
                        className={`form-input-sm-control ${
                            errors.country
                                ? "border-red-300 focus:border-red-600"
                                : "border-gray-300 focus:border-blue-600"
                        } `}
                    >
                        {children}
                    </select>
                    {modal}
                </div>
            </div>
        </div>
    );
};

export default InputSelect;
