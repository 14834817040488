import {Document, Page, PDFViewer, Text, View, Image} from "@react-pdf/renderer";
import styles from "../../../pdf-styles";
import {Grid, ScrollArea} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {HiChevronLeft, HiEye, HiOutlineOfficeBuilding} from "react-icons/hi";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import axios from "axios";
import {useLocation} from "react-router";


function PayrollPdf() {

    const {domainId, loggedUserId ,roles, token} = JSON.parse(localStorage.getItem('user'));
    let location = useLocation();
    const paramsState = location.state;

    const viewMode = paramsState ? paramsState.viewMode : '';

    const {payrollId} = useParams();
    const {t, i18n} = useTranslation();
    const fullTableHeight = localStorage.getItem('fullTableHeight');
    const [payrollDetails, setPayrollDetails] = useState({});

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/payroll/${payrollId}/show`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                if (res.status === 200){
                    setPayrollDetails(res.data.data)
                }
            });
    }, [])

    var lineTotalAmount = payrollDetails.amount;
    var lineTotalAmountDecimalHour=payrollDetails.amountDecimalHour;


    const convertHourMinToDecimal = (time, separator) => {
        if(time === undefined || time === null || time === '') return 0;
        const totalMinutes = time.split(separator).reduce((acc,time) => (60 * acc) + +time)
        const hours = totalMinutes/60;

        return hours.toFixed(2)
    }

    const convertDecimalToHour = (time, separator) => {
        if(time === undefined || time === null || time === '') return 0;
        const timeSplit = time.split(separator);
        const hours = timeSplit[0];
        const minutes = timeSplit[1];

        const hourLength = hours.length;

        const minLength = minutes.length;

        const hour = hourLength<2?'0'+hours:hours;
        const minute = minLength<2?'0'+minutes:minutes;

        return `${hour}:${minute}`
    }

    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Payroll')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>
                                    {t('PayrollInformation')}
                                </sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">

                                <Link to={`/payroll/`+payrollDetails.id+`/view`}
                                      className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiEye size={16} className={'mr-1'}/>{t('ViewPayroll')}
                                </Link>

                                <Link to='/payroll'
                                      className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={12} className={'mr-1'}/>{t('Back')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <PDFViewer
                    showToolbar={true}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Document>
                        <Page orientation='p' size='A4' style={styles.page}>

                            <View style={[styles.width_100, {width:'100%', display: 'flex', clear: 'both', flexDirection: "row"}]}>
                                <View style={styles.section_left}>
                                    <View>
                                        <View>
                                            <Text>
                                                <Image style={styles.image} src={"/assets/images/simple-staffing.png"} alt="Simple Staffing" />
                                                {/*<img src={"/assets/images/simple-staffing.png"} alt="Simple Staffing" />*/}
                                            </Text>
                                        </View>
                                        <View>
                                            <Text style={[styles.text_12, {marginBottom:'5px'}]}>SIMPLE STAFFING USA LLC</Text>
                                        </View>
                                        <View>
                                            <Text style={styles.text_10}>
                                                3235 Satellite Blvd. Building 400
                                            </Text>
                                            <Text style={styles.text_10}>Suite 300 Duluth, GA</Text>
                                            <Text style={styles.text_10}>{t('Phone')}: 1.888.902.7759</Text>
                                            <Text style={styles.text_10}>{t('email')}: billing@simplestaffingllc.com</Text>
                                        </View>
                                    </View>

                                    <View>
                                        <View style={[{ marginTop: '15px' }]}>
                                            <Text style={[styles.text_12, {marginBottom:'5px'}]}>BILL TO:</Text>
                                            <Text style={[styles.text_12, {marginBottom:'5px'}]}>{payrollDetails.employeeName}</Text>
                                        </View>
                                        <View>
                                            <Text style={styles.text_10}>
                                                {payrollDetails.employeeAddress}
                                            </Text>
                                            <Text style={styles.text_10}>
                                                {t('Phone')}: {payrollDetails.employeePhone}
                                            </Text>
                                            <Text style={styles.text_10}>
                                                {t('email')}: {payrollDetails.employeeEmail}
                                            </Text>
                                        </View>
                                    </View>

                                </View>
                                <View style={styles.section_right}>
                                    <View>
                                        <View style={styles.text_16}>
                                            <Text style={[styles.textAlignCenter, styles.text_16, {marginBottom: '10px', color: 'red'}]}>
                                                {t('Payroll')}
                                            </Text>
                                        </View>
                                        <View style={[styles.tableNoBorder, styles.table_100]}>
                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableColNoBorder, styles.width_40]}>
                                                    <Text style={[styles.tableCell, styles.textAlignRight]}>{t('PayrollID')} : </Text>
                                                </View>
                                                <View style={[styles.tableColNoBorder, styles.width_60]}>
                                                    <Text style={[styles.tableCell, styles.textAlignLeft]}>{payrollDetails.payrollId}</Text>
                                                </View>
                                            </View>

                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableColNoBorder, styles.width_40]}>
                                                    <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                        {t('Date')} :
                                                    </Text>
                                                </View>
                                                <View style={[styles.tableColNoBorder, styles.width_60]}>
                                                    <Text style={[styles.tableCell, styles.textAlignLeft]}>
                                                        {payrollDetails.createdAt ? format(new Date(payrollDetails.createdAt.date), 'MMMM dd, Y') : ''}
                                                    </Text>
                                                </View>
                                            </View>

                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableColNoBorder, styles.width_40]}>
                                                    <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                        {t('PayrollPeriod')} :
                                                    </Text>
                                                </View>
                                                <View style={[styles.tableColNoBorder, styles.width_60]}>
                                                    <Text style={[styles.tableCell, styles.textAlignLeft]}>
                                                        {payrollDetails.startTime ? format(new Date(payrollDetails.startTime.date), 'MMM dd, Y') : ''} To  {payrollDetails.endTime ? format(new Date(payrollDetails.endTime.date), 'MMM dd, Y') : ''}
                                                    </Text>
                                                </View>
                                            </View>

                                            <View style={[styles.tableRow]}>
                                                <View style={[styles.tableColNoBorder, styles.width_40]}>
                                                    <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                        {t('PayrollDueDate')} :
                                                    </Text>
                                                </View>
                                                <View style={[styles.tableColNoBorder, styles.width_60]}>
                                                    <Text style={[styles.tableCell, styles.textAlignLeft]}>
                                                        {payrollDetails.dueDate ? format(new Date(payrollDetails.dueDate.date), 'MMM dd, Y') : ''}
                                                    </Text>
                                                </View>
                                            </View>

                                            {/*<View style={[styles.tableRow]}>
                                                <View style={[styles.tableColNoBorder, styles.width_40]}>
                                                    <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                        {t('PayrollDueDate')} :
                                                    </Text>
                                                </View>
                                                <View style={[styles.tableColNoBorder, styles.width_60]}>
                                                    <Text style={[styles.tableCell, styles.textAlignLeft]}>
                                                        {payrollDetails.dueDate ? format(new Date(payrollDetails.dueDate.date), 'dd, MMMM Y') : ''}
                                                    </Text>
                                                </View>
                                            </View>*/}

                                        </View>
                                    </View>
                                </View>
                            </View>
                            { viewMode==='decimal' &&
                                <View style={[styles.width_100, {display: 'block', clear: 'both', marginTop: '20px'}]}>
                                    <View style={[styles.table, styles.width_100, styles.textAlignLeft]}>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_16, styles.colPositionRelative]}>

                                                <Text style={[styles.tableCell, styles.textAlignCenter, styles.colPositionAbsolute, styles.colPositionTop45]}>
                                                    {t('JobTitle')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_90]}>

                                                <View style={[styles.tableRow, styles.width_100]}>

                                                    <View style={[styles.tableCol, styles.width_14, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('JobTitle')}</Text>*/}
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Designation')}</Text>*/}
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('RegularRate')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('OvertimeRate')}</Text>
                                                    </View>


                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Total Hours')}</Text>*/}
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Regular')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Overtime')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder, styles.tableColRightNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('TotalAmount')}</Text>*/}
                                                    </View>
                                                </View>

                                                <View style={[styles.tableRow, styles.width_100]}>


                                                    <View style={[styles.tableCol, styles.width_14, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Date')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Designation')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    {/*<View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>*/}
                                                    <View style={[styles.tableCol, styles.width_14, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    {/*<View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>*/}

                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Total Hours')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    {/*<View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>*/}
                                                    <View style={[styles.tableCol, styles.width_14, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    {/*<View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>*/}

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder, styles.tableColRightNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('TotalAmount')}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>

                                        {payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && Object.keys(payrollDetails.details['payrollDetails']).map((checkInTime) => {

                                            {var dateWiseCount = 0}
                                            {var jobWiseTotalHours = 0}
                                            {var jobWiseTotalAmount = 0}
                                            { var dataLength = (payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && payrollDetails.details['payrollDetails'][checkInTime]) ? Object.values(payrollDetails.details['payrollDetails'][[checkInTime]]).length:0}

                                            return (
                                                <>
                                                    <View style={[styles.tableRow]}>
                                                        <View style={[styles.tableCol, styles.width_16, styles.colPositionRelative]}>

                                                            <Text style={[styles.tableCell, styles.textAlignLeft, dataLength>1? styles.colPositionAbsolute:styles.colPositionRelative, dataLength>1?styles.colPositionTop35:'']}>
                                                                {(payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && payrollDetails.details['payrollDetails'][checkInTime] && Object.values(payrollDetails.details['payrollDetails'][[checkInTime]]).length > 0 ) ? Object.values(payrollDetails.details['payrollDetails'][[checkInTime]])[0].jobTitle:''}
                                                            </Text>
                                                        </View>
                                                        <View style={[styles.tableCol, styles.width_90]}>
                                                            {payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && payrollDetails.details['payrollDetails'][checkInTime] && Object.values(payrollDetails.details['payrollDetails'][[checkInTime]]).map((element, index) => {
                                                                { dateWiseCount = dateWiseCount+1}

                                                                { jobWiseTotalHours = jobWiseTotalHours + Number(element.hour)}
                                                                {var amountDecimalHour = element.amountDecimalHour>0?(element.amountDecimalHour):((element.regularHourMinToDecimal*element.regularHourRate)+(element.overtimeHourMinToDecimal*element.overtimeHourRate))}
                                                                { jobWiseTotalAmount = jobWiseTotalAmount + Number(amountDecimalHour)}

                                                                { var dateWiseDataLength = (payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && payrollDetails.details['payrollDetails'][checkInTime]) ? Object.values(payrollDetails.details['payrollDetails'][[checkInTime]]).length:0}

                                                                return(
                                                                    <>
                                                                        <View style={[styles.tableRow, styles.width_100]}>

                                                                            <View style={[styles.tableCol, styles.width_14, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {/*{element.jobTitle}*/}
                                                                                    {element.inTime ? format(new Date(element.inTime.date), 'MM/dd/Y') : ''}
                                                                                </Text>
                                                                            </View>
                                                                            <View style={[styles.tableCol, styles.width_12, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.designationName}
                                                                                </Text>
                                                                            </View>

                                                                            <View style={[styles.tableCol, styles.width_14, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.regularHourRate}
                                                                                </Text>
                                                                            </View>
                                                                            {/*<View style={[styles.tableCol, styles.width_7, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.regularMinRate}
                                                                                </Text>
                                                                            </View>*/}
                                                                            <View style={[styles.tableCol, styles.width_14, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.overtimeHourRate}
                                                                                </Text>
                                                                            </View>
                                                                            {/*<View style={[styles.tableCol, styles.width_7, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.overtimeMinRate}
                                                                                </Text>
                                                                            </View>*/}

                                                                            <View style={[styles.tableCol, styles.width_7, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.minuteFormatToHourFormat}
                                                                                </Text>
                                                                            </View>

                                                                            <View style={[styles.tableCol, styles.width_14, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.regularHourMinToDecimal}
                                                                                </Text>
                                                                            </View>
                                                                            {/*<View style={[styles.tableCol, styles.width_7, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.regularMin}
                                                                                </Text>
                                                                            </View>*/}
                                                                            <View style={[styles.tableCol, styles.width_14, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.overtimeHourMinToDecimal}
                                                                                </Text>
                                                                            </View>
                                                                            {/*<View style={[styles.tableCol, styles.width_7, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.overtimeMin}
                                                                                </Text>
                                                                            </View>*/}

                                                                            <View style={[styles.tableCol, styles.width_12, styles.tableColRightNoBorder, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                                                    {amountDecimalHour.toFixed(2)}
                                                                                </Text>
                                                                            </View>
                                                                        </View>
                                                                    </>
                                                                )

                                                            })}
                                                        </View>
                                                    </View>

                                                    <View style={[styles.tableRow]}>
                                                        <View style={[styles.tableCol, styles.width_64_5]}>
                                                            <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                                {t('Hours')}
                                                            </Text>
                                                        </View>
                                                        <View style={[styles.tableCol, styles.width_6_5]}>
                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                {convertHourMinToDecimal(jobWiseTotalHours.toFixed(2), '.')}
                                                            </Text>
                                                        </View>
                                                        <View style={[styles.tableCol, styles.width_25, {borderWidth: '1px'}]}>
                                                            <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                                {t('Amount')}
                                                            </Text>
                                                        </View>
                                                        <View style={[styles.tableCol, styles.width_11]}>
                                                            <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                                {Number(jobWiseTotalAmount).toFixed(2)}
                                                            </Text>
                                                        </View>
                                                    </View>


                                                </>


                                            )

                                        })}




                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_64_5]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('TotalHours')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_6_5]}>
                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                    {convertHourMinToDecimal(payrollDetails.totalHoursForDisplay, ':')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_25]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('GrossIncome')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_11]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {lineTotalAmountDecimalHour?Number(lineTotalAmountDecimalHour).toFixed(2):''}
                                                </Text>
                                            </View>
                                        </View>

                                    </View>
                                </View>
                            }

                            {viewMode==='hour' &&
                                <View style={[styles.width_100, {display: 'block', clear: 'both', marginTop: '20px'}]}>
                                    <View style={[styles.table, styles.width_100, styles.textAlignLeft]}>

                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_16, styles.colPositionRelative]}>

                                                <Text style={[styles.tableCell, styles.textAlignCenter, styles.colPositionAbsolute, styles.colPositionTop45]}>
                                                    {t('JobTitle')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_90]}>

                                                <View style={[styles.tableRow, styles.width_100]}>

                                                    <View style={[styles.tableCol, styles.width_14, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('JobTitle')}</Text>*/}
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Designation')}</Text>*/}
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('RegularRate')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('OvertimeRate')}</Text>
                                                    </View>


                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Total Hours')}</Text>*/}
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Regular')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_14]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Overtime')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder, styles.tableColRightNoBorder]}>
                                                        {/*<Text style={[styles.tableCell, styles.textAlignCenter]}>{t('TotalAmount')}</Text>*/}
                                                    </View>
                                                </View>

                                                <View style={[styles.tableRow, styles.width_100]}>


                                                    <View style={[styles.tableCol, styles.width_14, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Date')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Designation')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Total Hours')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Hours')}</Text>
                                                    </View>
                                                    <View style={[styles.tableCol, styles.width_7, styles.tableColBottomNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('Min')}</Text>
                                                    </View>

                                                    <View style={[styles.tableCol, styles.width_12, styles.tableColBottomNoBorder, styles.tableColRightNoBorder]}>
                                                        <Text style={[styles.tableCell, styles.textAlignCenter]}>{t('TotalAmount')}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>

                                        {payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && Object.keys(payrollDetails.details['payrollDetails']).map((checkInTime) => {

                                            {var dateWiseCount = 0}
                                            {var jobWiseTotalHours = 0}
                                            {var jobWiseTotalAmount = 0}
                                            { var dataLength = (payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && payrollDetails.details['payrollDetails'][checkInTime]) ? Object.values(payrollDetails.details['payrollDetails'][[checkInTime]]).length:0}

                                            return (
                                                <>
                                                    <View style={[styles.tableRow]}>
                                                        <View style={[styles.tableCol, styles.width_16, styles.colPositionRelative]}>

                                                            <Text style={[styles.tableCell, styles.textAlignLeft, dataLength>1? styles.colPositionAbsolute:styles.colPositionRelative, dataLength>1?styles.colPositionTop35:'']}>
                                                                {(payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && payrollDetails.details['payrollDetails'][checkInTime] && Object.values(payrollDetails.details['payrollDetails'][[checkInTime]]).length > 0 ) ? Object.values(payrollDetails.details['payrollDetails'][[checkInTime]])[0].jobTitle:''}
                                                            </Text>
                                                        </View>
                                                        <View style={[styles.tableCol, styles.width_90]}>
                                                            {payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && payrollDetails.details['payrollDetails'][checkInTime] && Object.values(payrollDetails.details['payrollDetails'][[checkInTime]]).map((element, index) => {
                                                                { dateWiseCount = dateWiseCount+1}

                                                                { jobWiseTotalHours = jobWiseTotalHours + Number(element.hour)}
                                                                { jobWiseTotalAmount = jobWiseTotalAmount + Number(element.amount)}

                                                                { var dateWiseDataLength = (payrollDetails && payrollDetails.details && payrollDetails.details['payrollDetails'] && payrollDetails.details['payrollDetails'][checkInTime]) ? Object.values(payrollDetails.details['payrollDetails'][[checkInTime]]).length:0}

                                                                return(
                                                                    <>
                                                                        <View style={[styles.tableRow, styles.width_100]}>

                                                                            <View style={[styles.tableCol, styles.width_14, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {/*{element.jobTitle}*/}
                                                                                    {element.inTime ? format(new Date(element.inTime.date), 'MM/dd/Y') : ''}
                                                                                </Text>
                                                                            </View>
                                                                            <View style={[styles.tableCol, styles.width_12, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.designationName}
                                                                                </Text>
                                                                            </View>

                                                                            <View style={[styles.tableCol, styles.width_7, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.regularHourRate}
                                                                                </Text>
                                                                            </View>
                                                                            <View style={[styles.tableCol, styles.width_7, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.regularMinRate}
                                                                                </Text>
                                                                            </View>
                                                                            <View style={[styles.tableCol, styles.width_7, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.overtimeHourRate}
                                                                                </Text>
                                                                            </View>
                                                                            <View style={[styles.tableCol, styles.width_7, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.overtimeMinRate}
                                                                                </Text>
                                                                            </View>

                                                                            <View style={[styles.tableCol, styles.width_7, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.hourForDisplay}
                                                                                </Text>
                                                                            </View>

                                                                            <View style={[styles.tableCol, styles.width_7, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.regularHour}
                                                                                </Text>
                                                                            </View>
                                                                            <View style={[styles.tableCol, styles.width_7, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.regularMin}
                                                                                </Text>
                                                                            </View>
                                                                            <View style={[styles.tableCol, styles.width_7, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.overtimeHour}
                                                                                </Text>
                                                                            </View>
                                                                            <View style={[styles.tableCol, styles.width_7, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                                    {element.overtimeMin}
                                                                                </Text>
                                                                            </View>

                                                                            <View style={[styles.tableCol, styles.width_12, styles.tableColRightNoBorder, dateWiseDataLength===dateWiseCount? styles.tableColBottomNoBorder:'']}>
                                                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                                                    {element.amount}
                                                                                </Text>
                                                                            </View>
                                                                        </View>
                                                                    </>
                                                                )

                                                            })}
                                                        </View>
                                                    </View>

                                                    <View style={[styles.tableRow]}>
                                                        <View style={[styles.tableCol, styles.width_64_5]}>
                                                            <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                                {t('Hours')}
                                                            </Text>
                                                        </View>
                                                        <View style={[styles.tableCol, styles.width_6_5]}>
                                                            <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                                { convertDecimalToHour(Number(jobWiseTotalHours).toFixed(2), '.') }
                                                            </Text>
                                                        </View>
                                                        <View style={[styles.tableCol, styles.width_25, {borderWidth: '1px'}]}>
                                                            <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                                {t('Amount')}
                                                            </Text>
                                                        </View>
                                                        <View style={[styles.tableCol, styles.width_11]}>
                                                            <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                                {Number(jobWiseTotalAmount).toFixed(2)}
                                                            </Text>
                                                        </View>
                                                    </View>


                                                </>


                                            )

                                        })}




                                        <View style={[styles.tableRow]}>
                                            <View style={[styles.tableCol, styles.width_64_5]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('TotalHours')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_6_5]}>
                                                <Text style={[styles.tableCell, styles.textAlignCenter]}>
                                                    {payrollDetails.totalHoursForDisplay}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_25]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {t('GrossIncome')}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol, styles.width_11]}>
                                                <Text style={[styles.tableCell, styles.textAlignRight]}>
                                                    {Number(lineTotalAmount).toFixed(2)}
                                                </Text>
                                            </View>
                                        </View>

                                    </View>
                                </View>
                            }

                            <View style={[styles.width_100, {display: 'block', clear: 'both', marginTop: '20px'}]}>

                                <Text style={[styles.text_13]}>
                                    {t('AmountInWords')}: {viewMode ==='decimal'? payrollDetails.amountInWordDecimalAmount:payrollDetails.amountInWord}
                                </Text>
                            </View>

                            <View style={[styles.width_100, {display: 'block', clear: 'both', marginTop: '10px'}]}>

                                <Text style={[styles.text_11]}>
                                    {t('Comments')}: {payrollDetails.remarks ? payrollDetails.remarks : ''}
                                </Text>
                            </View>

                            <View style={[styles.width_100, {display: 'block', clear: 'both', marginTop: '20px'}]}>

                                <Text style={[styles.text_12]}>
                                    {t('NoticeOfAssignment')} :
                                </Text>
                                <Text style={[styles.text_10]}>
                                    This account has been assigned and must be paid only to : SIMPLE STAFFING USA LLC
                                </Text>
                            </View>

                        </Page>
                    </Document>
                </PDFViewer>

            </main>

        </>
    )
}

export default PayrollPdf