import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiOutlinePlus,
    HiOutlineChevronDown,
    HiOutlineExclamation
} from "react-icons/hi";
import { FiX } from "react-icons/fi";
import {
    Text,
    Group,
    Stack,
    Select, TextInput, LoadingOverlay,
} from "@mantine/core";
import { DataTable } from "mantine-datatable";

import axios from "axios";
import {openConfirmModal} from '@mantine/modals';
import {Switch} from "@mantine/core";


function PreviousEventCandidates({reqDesignationId, recallCandidates, setRecallCandidates}) {
    const {token, loggedUserId, roles} = JSON.parse(localStorage.getItem('user'));
    const {t, i18n} = useTranslation();

    const [fetching, setFetching] = useState(true);
    // const [recallCandidates, setRecallCandidates] = useState(false);

    const [candidates, setCandidates] = useState([]);
    const [showDecimalFormat, setShowDecimalFormat] = useState(true);

    const getCandidates = (reqDesignationId) => {
        // setFetching(true);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/previous-job-candidates`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {id: reqDesignationId}
        })
            .then(res => {
                setCandidates(res.data.data);
                setFetching(false);
            });
    }

    const removeCandidate = (assignCandidateId) =>{
        // console.log(reqDesignationId)
        openConfirmModal({
            // title: (<HiOutlineExclamation size={30} className="text-amber-500"/>),
            title:<div className="flex"><HiOutlineExclamation size={16} className="text-amber-500 mr-2"/><Text size="xs" fw={700}> {t('AreYouSure')}?</Text></div>,
            centered: true,
            children: '',
            labels: {confirm: (t('Yes')), cancel: (t('No'))},
            // confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                axios({
                    method: 'delete',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/previous-job-candidates`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    data: {id: assignCandidateId}
                })
                    .then(res => {
                        if(res.data.status === 200){
                            setRecallCandidates(prevState => !prevState);
                        }
                        // console.log(res)
                    })
                    .catch(function (error) {
                        console.log(error)
                    });
            }
        }); 
        
    }

    const [rateChangeLoader, setRateChangeLoader] = useState(false)
    const updateCandidateRate = ({rowId, rate}) => {
        // job-assign-candidate-update
        setRateChangeLoader(true)

        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-assign-candidate-update`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {rowId, rate}
        })
            .then(res => {
                if(202 == res.data.status){
                    setRateChangeLoader(false)
                }

            })
            .catch(function (err) {
                console.log(err);

            });
    }


    useEffect(()=>{
        getCandidates(reqDesignationId);
    },[recallCandidates])

    return (
        <DataTable
            // minHeight={100}
            records={candidates}
            striped
            // noRecordsText={<Text fz="xs" fw={700}>{t('NoCandidates')}</Text>}
            noRecordsText=""
            noRecordsIcon={"..."}
            idAccessor="userId"
            columns={[
                {
                    accessor: '',
                    title: <Text fz="xs">{t('No.')}</Text>,
                    width: 45,
                    render: (item) => (candidates.indexOf(item) + 1)
                },
                {
                    accessor: 'name',
                    title: <Text fz="xs">{t('Name')}</Text>,
                },
                {
                    accessor: 'email',
                    title: <Text fz="xs">{t('Email')}</Text>,
                },
                {
                    accessor: 'phone',
                    title: <Text fz="xs">{t('Phone')}</Text>,
                },
                {
                    accessor: 'rate',
                    title: <Text fz="xs">{t('Rate')}</Text>,
                    render: ({rate, assignCandidateId}) => (
                        <Group pos="relative">
                            {/*<LoadingOverlay visible={rateChangeLoader} loaderProps={{ size: 'xs', color: 'indigo' }} />*/}

                            <TextInput w={60} defaultValue={rate} onBlur={(e) => e && updateCandidateRate({rowId: assignCandidateId, rate: e.target.value})} />
                        </Group>
                    )

                },
                {
                    accessor: 'checkIn',
                    title: <Text fz="xs">{t('CheckIn')}</Text>,
                },
                {
                    accessor: 'checkOut',
                    title: <Text fz="xs">{t('CheckOut')}</Text>,
                },
                {
                    accessor: 'breakDuration',
                    title: <Text fz="xs">{t('BreakDuration') + '(' + (t('min')) + '.)'}</Text>,
                },
                {
                    accessor: 'totalHour',
                    // title: <Text fz="xs">{t('Hour')}</Text>,
                    title: <Switch radius={`sm`} w={85} onChange={() => setShowDecimalFormat(!showDecimalFormat)} size="lg" color={`indigo.2`} checked={showDecimalFormat} onLabel={<Text fz={12} fw={600}>{t('Decimal')}</Text>} offLabel={<Text fz={12} fw={600}>{t('Hour')}</Text>} />,
                    render: ({totalHour, totalHourDecimalFormat}) => showDecimalFormat ? totalHourDecimalFormat : totalHour
                },
                {
                    accessor: 'actions',
                    title: <Text fz="xs">{t('Action')}</Text>,
                    textAlignment: 'right',
                    render: (item) => (
                        <Group spacing={2} position="right" noWrap>
                            <button
                                type="button"
                                className="px-1 py-1 text-white inline-flex focus:ring-red-700 transition duration-150 ease-in-out hover:bg-red-600 bg-red-700 items-center border rounded text-xs"
                                onClick={() => removeCandidate(item['assignCandidateId'])}
                            >
                                <FiX size={12}/>
                            </button>
                        </Group>
                    ),
                },

            ]}
            fetching={fetching}
            loaderSize="sm"
            loaderColor="indigo"
            loaderBackgroundBlur={2}
        />

    );
}

export default PreviousEventCandidates;
