import React, {useState} from "react";
import {ActionIcon, LoadingOverlay, Text} from "@mantine/core";
import {HiPencil, HiCheck} from "react-icons/hi";
import axios from "axios";
import {openConfirmModal} from "@mantine/modals";
import {showNotification} from "@mantine/notifications";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {format} from "date-fns";

export default function PayrollDetailTr({element, checkInTime, payrollDetails, dateWiseCount, dateWiseDataLength, setRecall}){
    const {t, i18n} = useTranslation();
    const {domainId, roles, token, loggedUserId, name} = JSON.parse(localStorage.getItem('user'));
    const [editAction, setEditAction] = useState(false);
    const [regularHourRate, setRegularHourRate] = useState(element.regularHourRate?element.regularHourRate:'');
    const [hourData, setHourData] = useState(element.hourForDisplay?element.hourForDisplay:'');
    const navigate = useNavigate();

    const regularHourRateHandle = (e) => {
        setRegularHourRate(e.target.value)
    }
    const hourDataHandle = (e) => {
        setHourData(e.target.value)
    }


    const [updateAfterShowLoading, setUpdateAfterShowLoading] = useState(false);

    const updatePayrollDetailsLineItem = (id) => {

        openConfirmModal({
            title: (t('')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSure')}?
                </Text>
            ),
            labels: {confirm: (t('Yes')), cancel: (t('No'))},
            confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
            // confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                setUpdateAfterShowLoading(true);
                axios({
                    method: 'put',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/payroll/update/payroll-details`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                        id,
                        regularHourRate,
                        hourData,
                        loggedUserId,
                        name
                    }
                })
                    .then(res => {
                        console.log(res.data)
                        if (res.data.status === 202) {
                            setUpdateAfterShowLoading(false);
                            setRecall(preState=>!preState)
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
        });


    }

    return (
        <tr id={element.id} className="border-t border-gray-200">
            {dateWiseCount===1 &&
            <td rowSpan={dateWiseDataLength} className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                {element.jobTitle}
            </td>
            }
            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                {element.inTime ? format(new Date(element.inTime.date), 'MM/dd/Y') : ''}
            </td>
            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                {element.designationName}
            </td>
            <td className="border border-slate-300 whitespace-nowrap py-0 px-1 text-sm font-medium text-gray-900">
                {
                    editAction ? (
                        <input
                            onChange={(e) => regularHourRateHandle(e)}
                            type="text"
                            className={`form-input-sm-control disabled:cursor-not-allowed`}
                            defaultValue={regularHourRate ? regularHourRate : element.regularHourRate}
                        />
                    ) : (
                        regularHourRate ? regularHourRate : element.regularHourRate
                    )
                }

            </td>
            {/*<td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                {element.regularHourRate}
            </td>*/}
            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                {element.regularMinRate}
            </td>

            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                {element.overtimeHourRate}
            </td>
            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                {element.overtimeMinRate}
            </td>

            <td className="border border-slate-300 whitespace-nowrap py-0 px-1 text-sm font-medium text-gray-900">
                {
                    editAction ? (
                        <input
                            onChange={(e) => hourDataHandle(e)}
                            type="text"
                            className={`form-input-sm-control priority:px-1`}
                            // id={`hour_${index}`}
                            defaultValue={hourData ? hourData : element.hourForDisplay}
                        />
                    ) : (
                        hourData ? hourData : element.hourForDisplay
                    )
                }
            </td>

            {/*<td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                {element.hourForDisplay}
            </td>*/}

            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                {element.regularHour}
            </td>
            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                {element.regularMin}
            </td>

            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                {element.overtimeHour}
            </td>
            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900">
                {element.overtimeMin}
            </td>

            <td className="border border-slate-300 whitespace-nowrap py-2 px-2 text-sm font-medium text-gray-900 text-right">
                {(element.amount).toFixed(2)}
            </td>
            {payrollDetails.dispute===2 &&
            <td className="border border-slate-300 px-2 py-2 text-left text-sm font-semibold text-gray-900">
                <ActionIcon color="green" onClick = {(e) => setEditAction(prevState => !prevState)}>
                    {
                        editAction ? <HiCheck onClick={(e) => updatePayrollDetailsLineItem(element.id)} size={16} /> : <HiPencil size={16}/>
                    }

                </ActionIcon>

            </td>
            }
        </tr>
    )
}