import React, { Fragment, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import button from "@material-tailwind/react";
import { HiHome, HiOutlineCalendar, HiOutlineExclamation, HiOutlineX, HiCheckCircle, HiOutlinePlus, HiOutlineSearch } from "react-icons/hi";
import {ScrollArea, Table, LoadingOverlay, Select, Grid, Group, Text, TextInput, Flex} from "@mantine/core";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiArrowRight } from "react-icons/fi";
import * as Yup from "yup";
import { format } from 'date-fns';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import ViewCandidateProfile from "../../candidate/modal/ViewCandidate";
import AddPositionModal from "./AddPositionModal";

export default function AddCandidateModal({ setShowModal, activeTab }) {
    const { t, i18n } = useTranslation();
    const { token, loggedUserId, roles } = JSON.parse(localStorage.getItem("user"));
    const modalHeight = localStorage.getItem("modalHeight");
    const [spinner, setSpinner] = useState(false);
    const [reqDesignationFieldLoader, setReqDesignationFieldLoader] = useState(false);
    const [showCandidateProfile, setShowCandidateProfile] = useState(false);
    const [candidateId, setCandidateId] = useState(null);
    const [reqDesignationId, setReqDesignationId] = useState(null);
    const [showAddPositionModal, setShowAddPositionModal] = useState(false);

    const validationSchema = Yup.object().shape({
        jobId: Yup.string().required(t('JobIsRequired')),
        reqDesignationId: Yup.string().required(t('DesignationIsRequired')),
        candidates: Yup.array().min(1).required(t("CandidateIsRequired")).nullable(),
        // working_dates: Yup.array().required(t("WorkingDateIsRequired")).nullable(),

        // working_dates: Yup.array().when("reqDesignationId", (value, validationSchema) => value && validationSchema.min(1).required(t("WorkingDateIsRequired")).nullable()),
    });

    // Yup.array().min(1).required(t("WorkingDateIsRequired")).nullable()
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState, setValue, getValues,setError } = useForm(formOptions);
    const { errors } = formState;



    const [jobList, setJobList] = useState([]);
    const getJobList = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-list-for-time-sheet`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                loggedUserId,
                roles,
                // flag: 'running',
                flag: activeTab,
                clientId: null
            }
        })
            .then(res => {
                setJobList(res.data.data);
            })
            .catch(function (err) {
                console.log(err);

            });
    }

    const [requiredDesignations, setRequiredDesignations] = useState([]);
    const [jobId, setJobId] = useState(null);
    const getRequiredDesignations = (jobId) => {
        setJobId(jobId);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/job-required-designation`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                setRequiredDesignations(res.data.data);
                setReqDesignationFieldLoader(false)
            })
            .catch(function (err) {
                console.log(err);

            });
    }


    const [requiredDesignationTimeMatrix, setRequiredDesignationTimeMatrix] = useState({});
                                                                    
    const [timeMatrixLoader, setTimeMatrixLoader] = useState(false);
    const getTimeMatrix = (reqDesignationId) => {
        const timeMatrix = requiredDesignations.find(designation => designation['id'] === reqDesignationId)['timeMatrix'];
        setRequiredDesignationTimeMatrix(timeMatrix)
        setTimeMatrixLoader(false)

    }
    const [workingDates, setWorkingDates] = useState([]);

    const assignWorkingDates = (event, date, timeRange) => {
        let dateWithTimeRange = date + '#' + timeRange;
        
        // console.log(event.target.checked, date, timeRange)
        if(event.target.checked){
            workingDates.push(dateWithTimeRange);
   
            // console.log(workingDates)

        }else{
            // workingDates.hasOwnProperty(date) && workingDates[date] === timeRange && delete workingDates[date];
            const index = workingDates.indexOf(dateWithTimeRange);
            if (index > -1) { // only splice array when item is found
                workingDates.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
        setValue('working_dates', workingDates)

        getAvailableCandidates(reqDesignationId, workingDates);

        // console.log(workingDates)


        // setWorkingDates([...workingDates,date]) ;
    }

    const [checked, setChecked] = useState([]);

    const handleCheck = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
          updatedList = [...checked, event.target.value];
            setValue('working_dates',updatedList)
        } else {
          updatedList.splice(checked.indexOf(event.target.value), 1);
          if(Object.values(updatedList).length === 0){
            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('Warning')),
                message: (t('WorkingDateMustBeSelected')),
                autoClose: 2000,
                disallowClose: true,
                color: 'red',
            });
                setValue('working_dates',updatedList)
          }
        }
        setChecked(updatedList);
        getAvailableCandidates(reqDesignationId, updatedList);


      };



    const [assignCandidates, setAssignCandidates] = useState([]);
    const assignCandidate = (event, candidate) => {
        if (event.target.checked) {
            assignCandidates.push(candidate);
          } else {
            assignCandidates.splice(assignCandidates.findIndex(assignCandidate => assignCandidate['userId'] === candidate['userId']),1)
          }

        setValue('candidates', assignCandidates)
    }

    const [candidateList, setCandidateList] = useState([]);
    const [candidateFilterdList, setCandidateFilterdList] = useState([]);
    const [showCandidateListLoader, setShowCandidateListLoader] = useState(false);
    const getAvailableCandidates = (reqDesignationId, workingDates) => {
        // console.log({reqDesignationId, workingDates, placeToUse: 'time-sheet'})

        setShowCandidateListLoader(true);

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/available-candidate-designation-wise`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {reqDesignationId, workingDates, placeToUse: 'time-sheet'}
        })
            .then(res => {
                setCandidateList(res.data.data);
                setCandidateFilterdList(res.data.data);
                setShowCandidateListLoader(false);

            })
            .catch(function (err) {
                console.log(err);
            });
    }

    function formSubmit(payload) {
    
        if(payload.working_dates.length === 0){
            showNotification({
                id: 'load-data',
                loading: true,
                title: (t('Warning')),
                message: (t('WorkingDateMustBeSelected')),
                autoClose: 2000,
                disallowClose: true,
                color: 'red',
            })
            return;
        }
        setSpinner(true);
        payload['loggedUserId'] = loggedUserId;
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/assign-candidate-from-time-sheet`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: payload
        })
            .then(res => {
                if (201 === res.data.status) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Success')),
                        message: (t(res.data.message)),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'green',
                    });
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: (t(res.data.message)),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });
                }
            })
            .catch(function (err) {
                console.log(err);
            });

            setShowModal(false);

    }


    const searchCandidate = (e) => {
        // console.log(candidateList)
   
        // setSearching(true);
        const query = e.target.value;
        // let updatedList = [...candidates];
        let filterUpdatedList = candidateList.length > 0 && candidateList.filter(candidate => {
            // return (item['name'].toLowerCase().indexOf(query.toLowerCase()) !== -1) || (item['name'].toLowerCase().indexOf(query.toLowerCase()) !== -1) || (item['phoneNumber'].indexOf(query) !== -1);
            return (candidate['name'].toLowerCase().includes(query.toLowerCase())) || (candidate['email'].toLowerCase().includes(query.toLowerCase())) || (candidate['phone'].includes(query));
        });

        setCandidateFilterdList(filterUpdatedList);

        // setSearching(false);
    }




    useEffect(() => {
        getJobList();
    }, [])


    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-2/3">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-gray-800 relative">
                            {spinner && <LoadingOverlay visible={true} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'sm' }} overlayColor="bg-indigo-100" />}
                            <form onSubmit={handleSubmit(formSubmit)} className="h-full">

                                <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                    {/* <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('AddCandidate')}</h5> */}
                                    <div className='flex flex-wrap col-span-2 items-end grow'>
                                        <div className="w-2/5 items-center">
                                            <label htmlFor="jobId" className="form-input-sm-label-required">{t('Job')}</label>
                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0 mr-2">
                                                {
                                                    errors.jobId?.type === 'required' &&
                                                    <div className="form-input-sm-error">
                                                        <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.jobId?.message}
                                                    </div>
                                                }
                                                <Select
                                                    {...register("jobId")}
                                                    placeholder={t('SelectJob')}
                                                    data={jobList.map((job, index) => ({ value: job['id'], label: job['title'] }))}
                                                    onChange={e => {
                                                        setReqDesignationFieldLoader(true)
                                                        setValue('jobId', e)
                                                        getRequiredDesignations(e)
                                                        setRequiredDesignationTimeMatrix([])
                                                        setCandidateList([])
                                                        
                                                    }}
                                                    nothingFound="No Jobs"
                                                    maxDropdownHeight={280}
                                                    searchable
                                                    id="jobId"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-2/5 items-center">
                                            <label htmlFor="reqDesignationId" className="form-input-sm-label-required">{t('Designation')}</label>
                                            <div className="relative mt-1 mr-2 sm:col-span-2 sm:mt-0">
                                                {
                                                    errors.reqDesignationId?.type === 'required' &&
                                                    <div className="form-input-sm-error">
                                                        <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.reqDesignationId?.message}
                                                    </div>
                                                }
                                                <Select
                                                    {...register("reqDesignationId")}
                                                    placeholder={t('SelectDesignation')}
                                                    data={requiredDesignations.map((designation, index) => ({ value: designation['id'], label: designation['designationName'] }))}
                                                    onChange={e => {
                                                        setTimeMatrixLoader(true)
                                                        setReqDesignationId(e)
                                                        setValue('reqDesignationId', e)
                                                        getTimeMatrix(e)
                                                        setCandidateList([])
                                                    }}
                                                    nothingFound="No Designations"
                                                    maxDropdownHeight={280}
                                                    searchable
                                                    id="reqDesignationId"
                                                />
                                                <LoadingOverlay visible={reqDesignationFieldLoader} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'xs' }} overlayColor="bg-indigo-100" />

                                            </div>
                                        </div>
                                        <div className="w-1/5">
                                            <button
                                                type="button"
                                                className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-100 px-2 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-xs"
                                                onClick={()=> setShowAddPositionModal(true)}
                                                disabled={!jobId}
                                            >
                                                <HiOutlinePlus size={12} className={'mr-1'} />
                                                <span>{t('AddPosition')}</span>
                                            </button>
                                        </div>
                                    </div>

                                    <button
                                        onClick={() => setShowModal(false)}
                                        className="inline-flex text-right font-normal text-gray-600">
                                        <HiOutlineX className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>

                                <div className="modal-body  p-4 pb-0 pt-0">
                                    <div className="container mx-auto">
                                        <div className="flex flex-row flex-wrap">
                                            <div role="main" className="w-full pt-1 px-2">
                                                
                                                    {
                                                        Object.values(requiredDesignationTimeMatrix).length > 0 && requiredDesignationTimeMatrix && (
                                                            <div className="w-full">
                                                                <label htmlFor="workingDates" className="form-input-sm-label-required">{t('WorkingDates')}</label>
                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                    <ScrollArea h={100} scrollbarSize={4} type="auto" className="w-full">
                                                                        <Grid gutter="xs" className="w-full">
                                                                            {
                                                                                Object.keys(requiredDesignationTimeMatrix).map((time, pIndex)=>(
                                                                                    <Grid.Col span={4} key={time + pIndex}>
                                                                                        <Group className='h-full mb-1 bg-gray-200 shadow px-3 py-2 w-full'>
                                                                                            <Text fz="sm" fw={500} className="-mb-4">{t('InTimeOutTime')}: {time}</Text>
                                                                                            <ScrollArea h={45} scrollbarSize={4} type="auto">
                                                                                                <ul role="list" className="flex flex-wrap w-full text-xs text-gray-600">
                                                                                                {
                                                                                                    requiredDesignationTimeMatrix[time].map((item, cIndex)=>(
                                                                                                        <li key={cIndex} className="flex gap-x-1 w-1/2 items-center py-1">

                                                                                                            <input 
                                                                                                                className="disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:hover:bg-gray-50 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0 focus:ring-offset-0"
                                                                                                                id={format(new Date(item['date'].date), 'Y-MM-dd') + '#' + time}
                                                                                                                value={format(new Date(item['date'].date), 'Y-MM-dd') + '#' + time}
                                                                                                                type="checkbox" 
                                                                                                                onChange={handleCheck} 
                                                                                                            />

                                                                                                            <label htmlFor={format(new Date(item['date'].date), 'Y-MM-dd') + '#' + time}>{format(new Date(item['date'].date), 'MM/dd/yyyy')}</label>

                                                                                                        </li>
                                                                                                        ))
                                                                                                }
                                                                                                </ul>
                                                                                            </ScrollArea>
                                                                                        </Group>
                                                                                    </Grid.Col>
                                                                                ))
                                                                            }
                                                                            <LoadingOverlay visible={timeMatrixLoader} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'xs', mih: 200 }} overlayColor="bg-indigo-100" />
                                                                        </Grid>
                                                                    </ScrollArea>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    <div className="w-full">
                                                        <label htmlFor="candidates" className="form-input-sm-label-required">{t('Candidates')}</label>
                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                            {
                                                                errors.candidates?.type === 'required' &&
                                                                <div className="form-input-sm-error">
                                                                    <HiOutlineExclamation size={16} className={'mr-2'} /> {errors.candidates?.message}
                                                                </div>
                                                            }
                                                            {
                                                                candidateList.length > 0 && (
                                                                    <TextInput
                                                                        onChange={(e) => e && searchCandidate(e)}
                                                                        // rightSection={searching && <Loader size="xs" />}
                                                                        placeholder=" name, email"
                                                                        className="w-full"
                                                                        icon={<HiOutlineSearch className="ml-1" />}
                                                                        iconWidth={15}
                                                                    />
                                                                )
                                                            }

                                                            <ScrollArea scrollbarSize={4} h={window.innerHeight - 400} className="relative">
                                                                <Grid gutter="xs" className="w-full mx-0 mt-1">
                                                                    {
                                                                        candidateFilterdList.map((candidate, index)=>(
                                                                            <Grid.Col span={4} key={candidate['userId']}>

                                                                                <Flex
                                                                                    className='flex h-full mb-1 shadow p-1 w-full bg-indigo-100'
                                                                                    justify="space-between"
                                                                                    align="center"
                                                                                >
                                                                                    <Group>
                                                                                        <div className="">
                                                                                            <img
                                                                                                className="h-8 w-8 rounded-full cursor-pointer"
                                                                                                // src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                                                                src={candidate['photo'] ? `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${candidate['photo']}` : "/assets/images/avatar.svg"}
                                                                                                alt=""
                                                                                                onClick={(e) => {
                                                                                                    setCandidateId(candidate['userId'])
                                                                                                    setShowCandidateProfile(true)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        <div
                                                                                            className="cursor-pointer"
                                                                                            onClick={(e) => {
                                                                                                setCandidateId(candidate['userId'])
                                                                                                setShowCandidateProfile(true)
                                                                                            }}
                                                                                        >
                                                                                            <Text fz={10} fw={600} td="underline">{candidate.name}</Text>
                                                                                            <Text fz={9} fw={200} fs="italic">{candidate.email}</Text>
                                                                                            <Text fz={9} fw={200}>{candidate.phone}</Text>
                                                                                        </div>
                                                                                    </Group>

                                                                                    <Group>
                                                                                        <Text>Rate</Text>
                                                                                        <TextInput
                                                                                            onChange={(e) =>  candidate.updateRate = e.target.value}
                                                                                            defaultValue={candidate.rate}
                                                                                            w={60}
                                                                                        />
                                                                                    </Group>


                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="text-right disabled:cursor-not-allowed disabled:bg-gray-500 disabled:text-gray-500 h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-0 focus:ring-offset-0"
                                                                                        // onChange={e => addCandidate(e.target.checked, candidate)}
                                                                                        onChange={e => assignCandidate(e, candidate)}
                                                                                        // onChange={e => console.log(e,candidate)}
                                                                                        // {...register('candidates')}
                                                                                    />

                                                                                </Flex>


                                                                                {/*<Group
                                                                                    className='flex h-full mb-1 shadow p-1 w-full bg-indigo-100'>
                                                                                    <div className="">
                                                                                        <img
                                                                                            className="h-10 w-10 rounded-full cursor-pointer"
                                                                                            // src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                                                            src={candidate['photo'] ? `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${candidate['photo']}` : "/assets/images/avatar.svg"}
                                                                                            alt=""
                                                                                            onClick={(e) => {
                                                                                                setCandidateId(candidate['userId'])
                                                                                                setShowCandidateProfile(true)
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div
                                                                                        className="cursor-pointer"
                                                                                        onClick={(e) => {
                                                                                            setCandidateId(candidate['userId'])
                                                                                            setShowCandidateProfile(true)
                                                                                        }}
                                                                                    >
                                                                                        <Text fz={10} fw={600} td="underline">{candidate.name}</Text>
                                                                                        <Text fz={9} fw={200} fs="italic">{candidate.email}</Text>
                                                                                        <Text fz={9} fw={200} >{candidate.phone}</Text>
                                                                                    </div>

                                                                                    <div className="text-right grow">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="text-right disabled:cursor-not-allowed disabled:bg-gray-500 disabled:text-gray-500 h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-0 focus:ring-offset-0"
                                                                                            // onChange={e => addCandidate(e.target.checked, candidate)}
                                                                                            onChange={e => assignCandidate(e, candidate)}
                                                                                            // onChange={e => console.log(e,candidate)}
                                                                                            // {...register('candidates')}
                                                                                        />
                                                                                    </div>
                                                                                    
                                                                
                                                                                </Group>*/}
                                                                            </Grid.Col>
                                                                        ))
                                                                    }
                                                                </Grid>

                                                                {/* <table className="min-w-full divide-y divide-gray-300">
                                                                    <thead className="bg-gray-50">
                                                                    <tr>
                                                                        <th scope="col" className="py-1 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t('Name')}</th>
                                                                        <th scope="col" className="px-3 py-1 text-left text-sm font-semibold text-gray-900">{t('Phone')}</th>
                                                                        <th scope="col" className="relative py-1 pl-3 pr-4 sm:pr-6"> </th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody className="divide-y divide-gray-200 bg-white relative">
                                                                    {
                                                                        candidateFilterdList.length > 0 && candidateFilterdList.map((candidate, index) => (
                                                                            <tr key={candidate['userId']}>
                                                                                <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm sm:pl-6">
                                                                                    <div
                                                                                    className="flex items-center cursor-pointer"
                                                                                    onClick={(e) => {
                                                                                        setCandidateId(candidate['userId'])
                                                                                        setShowCandidateProfile(true)
                                                                                    }}
                                                                                    >
                                                                                        <div className="h-10 w-10 flex-shrink-0">
                                                                                            <img className="h-10 w-10 rounded-full"
                                                                                                    src={candidate['photo'] ? `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${candidate['photo']}` : "/assets/images/avatar.svg"}
                                                                                                    alt="" />
                                                                                        </div>
                                                                                        <div className="ml-4">
                                                                                            <div className="font-medium text-gray-900 hover:underline hover:text-indigo-900">{candidate.name}</div>
                                                                                            <div className="text-gray-500">{candidate.email}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                                                                    <div className="text-gray-900">{candidate.phone}</div>
                                                                                </td>
                                                                                <td className="relative whitespace-nowrap p-2 text-right text-sm font-medium w-12">
                                                                                    <div className="relative h-6 w-6">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="disabled:cursor-not-allowed disabled:bg-gray-500 disabled:text-gray-500 h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-0 focus:ring-offset-0"
                                                                                            onChange={e => assignCandidate(e, candidate)}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                    {
                                                                        candidateList.length === 0 && (
                                                                            <tr>
                                                                                <td colSpan={3} className="text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm">
                                                                                    {t('NoAvailableCandidates')}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }

                                                                    </tbody>
                                                                </table> */}
                                                                <LoadingOverlay visible={showCandidateListLoader} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'sm'}} overlayColor="bg-indigo-100" />
                                                            </ScrollArea>
                                                        </div>
                                                    </div>
                                                    <footer className="relative mt-1">
                                                        <div className="text-right pt-0.5 mb-0.5">
                                                            <button
                                                                type="submit"
                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-sm"
                                                            >
                                                                <span>{t('Submit')}</span>
                                                                {
                                                                    spinner ? (<AiOutlineLoading3Quarters className={'ml-1 animate-spin'} size={16} />) : (<FiArrowRight size={16} className={'ml-1'} />)
                                                                }
                                                            </button>
                                                        </div>
                                                        {/*</div>*/}
                                                    </footer>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red" />
            { showCandidateProfile && <ViewCandidateProfile setShowModal={setShowCandidateProfile} candidateid={candidateId} />}
            { jobId && showAddPositionModal && <AddPositionModal jobId={jobId} setShowModal={setShowAddPositionModal} getRequiredDesignations={getRequiredDesignations}/> }
        </>
    );
}