import React from "react";
import {useTranslation} from "react-i18next";
import {
    HiLocationMarker,
    HiCalendar,
    HiOutlineX,
    HiOutlineCheck,
} from "react-icons/hi";
import {Fragment} from 'react';
import {format} from 'date-fns';
import {useNavigate} from "react-router";




export default function RemoveConfirmation({setShowRemoveModal, removeRequiredDesignation, setRecall, recall}) {
    // console.log(jobDetails, requiredDesignation, assignedCandidate)
    const {t, i18n} = useTranslation();
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const modalHeight = localStorage.getItem('modalHeight');

    const navigate = useNavigate();
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-1/3">
                    {/*content*/}
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-body  p-4 pt-0">
                                <div className="py-10 text-left">
                                    <div className="flex flex-wrap">
                                        <div className="w-full px-4">
                                            <h2 className="text-slate-800 text-center">{t('DoYouWantToRemoveThisRecord')}?</h2>
                                        </div>
                                        {/*<div className="w-full px-4">
                                            <p className="text-red-800 text-center italic">{t('IfYouRemoveThisDesignationAllPreferableCandidatesWillBeDeleted')}!</p>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                <button
                                    onClick={() => { setShowRemoveModal(false)  }}
                                    className="bg-gray-500 hover:bg-grey-600 rounded inline-flex items-cente focus:outline-none focus:ring-1 mt-1 focus:ring-offset-1  focus:ring-gray-400 ml-3  transition duration-150 text-white ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-4 py-2 text-sm">
                                    <HiOutlineX className="mr-2 h-5 w-5" aria-hidden="true" /><span>{t('No')}</span>
                                </button>
                                <button
                                    onClick={() => {
                                        removeRequiredDesignation()
                                        setRecall(!recall)
                                        setShowRemoveModal(false)
                                    }}
                                    className="bg-indigo-500 hover:bg-indigo-600 rounded inline-flex items-cente focus:outline-none focus:ring-1 mt-1 focus:ring-offset-1  focus:ring-gray-400 ml-3  transition duration-150 text-white ease-in-out  border rounded px-4 py-2 text-sm">
                                    <HiOutlineCheck className="mr-2 h-5 w-5" aria-hidden="true"/><span>{t('Yes')}</span>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"></div>
        </>
    );
}
