import React, {useEffect} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {
    HiEye,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiPencil, HiTrash
} from "react-icons/hi";
import {Link, useNavigate} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Box, Group, Text , Checkbox, Grid, TextInput} from "@mantine/core";
import dayjs from "dayjs";
import {showNotification} from "@mantine/notifications";

/*
const employees = {
    data: [
        {id: 1, name: 'Employee 1', roles: ["ROLE_CLIENT", "ROLE_EMPLOYEE", "ROLE_PAYROLL"]},
        {id: 2, name: 'Employee 2', roles: ["ROLE_CLIENT", "ROLE_EMPLOYEE", "ROLE_PAYROLL"]},
        {id: 3, name: 'Employee 3', roles: ["ROLE_CLIENT", "ROLE_EMPLOYEE", "ROLE_PAYROLL"]},
    ],
    totalEmployees: 3
};*/
const PER_PAGE = 20;

function Index() {

    const {domainId, loggedUserId ,roles, token} = JSON.parse(localStorage.getItem('user'));

    const {t, i18n} = useTranslation();
    const [page, setPage] = useState(1);

    const [employees, setEmployees] = useState([]);
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState("all");

    const fullTableHeight = localStorage.getItem('fullTableHeight');
    const navigate = useNavigate();


    const [employeeStatusList, setEmployeeStatusList] = useState([]);
    const getEmployeeStatusList = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/master-data`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "type_slug": "client-candidate-status",
            }
        })
            .then(res => {
                // console.log(res.data)
                setEmployeeStatusList(res.data.data)
            })
            .catch(function (error) {
                console.log(error)
            })
    }


    // get employees
    const getEmployees = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/employee`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "per_page": PER_PAGE,
                "page": page,
                "status": activeTab,
                "domainId": domainId,
            }
        })
            .then(res => {
                setEmployees(res.data)
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    useEffect(() => {
        getEmployees();
    }, [page, activeTab]);

    useEffect(()=>{
        setPage(1);
    }, [activeTab])

    const ItemDelete = (e, id) => {
        e.preventDefault();
    }

    const [query, setQuery] = useState('');

    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })

    useEffect(() => {
        getEmployeeStatusList();
    }, []);

    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div
                            className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Employees')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('AllEmployeeList')}</sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <Link to={`/employee/register`}
                                      className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiOutlinePlus size={12} className={'mr-1'}/>{t('NewEmployee')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full px-4">
                    <Grid align="center">
                        <Grid.Col xs={8} sm={8}>
                            <nav className="isolate flex divide-x divide-gray-200 rounded-t-lg shadow" aria-label="Tabs">
                                {
                                    employeeStatusList.length > 0 && employeeStatusList.map((status, index) => (
                                        <button
                                            key={index}
                                            className="text-gray-900 rounded group relative min-w-0 flex-1 overflow-hidden bg-gray-100 py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                            aria-current="page"
                                            onClick={() => setActiveTab(status.slug)}
                                        >
                                            <span>{t(status.name)}</span>
                                            {activeTab === status.slug  && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5"/> }

                                        </button>
                                    ))
                                }


                                {/*
                                <button
                                    className="text-gray-900 rounded group relative min-w-0 flex-1 overflow-hidden bg-gray-100 py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                    aria-current="page"
                                    onClick={() => setActiveTab(1)}
                                >
                                    <span>{t('Active')}</span>
                                    {activeTab === 1  && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5"/> }

                                </button>
                                <button
                                    className="text-gray-900 rounded group relative min-w-0 flex-1 overflow-hidden bg-gray-100 py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                    aria-current="page"
                                    onClick={() => setActiveTab(0)}
                                >
                                    <span>{t('Inactive')}</span>
                                    {activeTab === 0  && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5"/> }

                                </button>
                                */}
                            </nav>
                        </Grid.Col>
                        {/*<Grid.Col xs={5} sm={5}></Grid.Col>*/}
                        {/*<Grid.Col xs={2} sm={1}></Grid.Col>*/}
                    </Grid>
                    <Box sx={{height: fullTableHeight}}>
                        <DataTable
                            withBorder
                            records={employees.data}
                            striped
                            styles={(theme) => ({
                                root: {
                                    border: '1px solid #e0e7ff',
                                    borderRadius: 2,
                                },
                                header: {
                                    background: '#e0e7ff',
                                    backgroundColor: "#e0e7ff",
                                    color: theme.colors.indigo[6],
                                },
                                pagination: {
                                    color: theme.colors.gray[3],
                                    paddingTop : "5px",
                                    paddingBottom : "5px"
                                },
                                item: {
                                    '&[data-active]': {
                                        backgroundImage: theme.fn.gradient({ from: 'red', to: 'yellow' }),
                                    },
                                },
                            })}
                            columns={[
                                {
                                    accessor: '',
                                    title: (t('No.')),
                                    width: 45,
                                    render: (employee) => (employees.data.indexOf(employee) + 1) + PER_PAGE * (page - 1)
                                },
                                {
                                    accessor: 'name',
                                    title: (t('Name')),
                                    width: 200
                                },
                                {
                                    accessor: 'email',
                                    title: (t('Email')),
                                },
                                {
                                    accessor: 'phoneNumber',
                                    title: (t('PhoneNumber')),
                                },
                                {
                                    accessor: 'roles',
                                    title: (t('Access')),
                                    render: (employee) => (
                                        employee.roles.length > 0 && (
                                            employee.roles.map((role, index) => (
                                    // var filtered = array.filter(function(value, index, arr){
                                    //     return value > 5;
                                    // });
                                                (index ? ', ' : '') + role.split('_')[1].charAt(0).toUpperCase() + role.split('_')[1].toLowerCase().slice(1)
                                            ))
                                        )
                                    )
                                },
                                {
                                    accessor: 'actions',
                                    title: <Text mr="xs">{t('Action')}</Text>,
                                    textAlignment: 'right',
                                    render: (employee) => (
                                        <Group spacing={4} position="right" noWrap>
                                            <ActionIcon color="green">
                                                <HiPencil size={16} onClick = {(e) => {
                                                    /*showNotification({
                                                        id: 'load-data',
                                                        loading: true,
                                                        title: (t('Error') + ' !'),
                                                        message: (t('Published job can not be edited')),
                                                        autoClose: 1000,
                                                        disallowClose: true,
                                                        color: 'red',
                                                    });*/
                                                }}/>
                                            </ActionIcon>
                                            <ActionIcon color="blue">
                                                <HiEye size={16}/>
                                            </ActionIcon>


                                            <ActionIcon color="red">

                                                <HiTrash
                                                    size={16}
                                                    onClick={(e) => {
                                                        console.log(e)
                                                    }}/>
                                            </ActionIcon>
                                        </Group>
                                    ),
                                },

                            ]}
                            totalRecords={employees.records}
                            recordsPerPage={PER_PAGE}
                            page={page}
                            onPageChange={(p) => setPage(p)}
                        />
                    </Box>
                </div>
            </main>
        </>
    )
}

export default Index