import React, {Fragment, useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {
    HiEye,
    HiViewList,
    HiOutlineChartSquareBar,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiPencil, HiTrash, HiSearch, HiOutlineFilter
} from "react-icons/hi";
import {Link, useNavigate} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Box, Group, Text, Checkbox, Grid, TextInput, ScrollArea, Drawer} from "@mantine/core";
import toast, { Toaster } from 'react-hot-toast';
import dayjs from "dayjs";
import ViewInvoice from "./modal/ViewInvoice";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import DisputeModal from "./../crm/modals/DisputeModal";
import {format} from "date-fns";
// import AddJobCandidate from "./modal/AddJobCandidate";
// import ViewJob from "./modal/ViewJob";

import ViewClient from '../client/modal/ViewClient';
import {openConfirmModal} from "@mantine/modals";


const PER_PAGE = 10;

function Index() {

    const {domainId, loggedUserId ,roles, token} = JSON.parse(localStorage.getItem('user'));

    const hasRoleModerator = roles.find(role => {
        return role === "ROLE_MODERATOR";
    })
    const hasRoleClient = roles.find(role => {
        return role === "ROLE_CLIENT";
    })
    const hasRoleSuperAdmin = roles.find(role => {
        return role === "ROLE_SUPER_ADMIN";
    })

    const [showClientProfile, setShowClientProfile] = useState(false);
    const [clientId, setClientId] = useState(null);

    const {t, i18n} = useTranslation();
    const [page, setPage] = useState(1);
    const drawerHeight = localStorage.getItem('drawerHeight');

    const [items, setItem] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const fullTableHeight = localStorage.getItem('fullTableHeight');
    const navigate = useNavigate();

    const [showDisputeModal, setShowDisputeModal] = useState(false);

    const [activeTab, setActiveTab] = useState('all');


    const disputeModal = (invoiceDetails) => {
        setInvoiceDetails(invoiceDetails);
        setShowDisputeModal(prevState => !prevState);
    }

    const [fetching, setFetching] = useState(true);
    const [opened, setOpened] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [sortStatus, setSortStatus] = useState({ columnAccessor: 'invoiceGenerateId', direction: 'desc' });

    const [invoiceIdData, setInvoiceIdData] = useState(null);
    const InvoiceIdDataHandel = (e) => {
        setInvoiceIdData(e.target.value);
    }

    const [clientNameData, setClientNameData] = useState(null);
    const ClientNameDataHandel = (e) => {
        setClientNameData(e.target.value);
    }



    //Form validation
    const validationSchema = Yup.object().shape({
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    function formSubmit(data) {
        setSpinner(true);
        setFetching(true);
        if (page === 1){
            setRefresh(true);
        }else {
            setPage(1);
        }
        setOpened(false)
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/invoice/lists/`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "per_page": PER_PAGE,
                "page": page,
                "domainId": domainId,
                "loggedUserId": loggedUserId,
                "roles": roles,
                "invoiceGenerateId": invoiceIdData?invoiceIdData:'',
                "clientName": clientNameData?clientNameData:'',
                "order_type": sortStatus.columnAccessor?sortStatus.columnAccessor:'',
                "order_by": sortStatus.direction?sortStatus.direction:'',
                "flag": activeTab,
            }
        })
            .then(res => {

                setItem(res.data.data);
                setTotalItem(res.data.total);
                setSpinner(false);
                setRefresh(false);
                setFetching(false);
        });
    }, [page, refresh, sortStatus, activeTab, showDisputeModal]);

    const disputeStatusResolved = (invoiceNo) => {
        const resolvedStatus=3;
        openConfirmModal({
            title: (t('')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSure')}?
                </Text>
            ),
            labels: {confirm: (t('Yes')), cancel: (t('No'))},
            confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
            // confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () => {
                // setUpdateAfterShowLoading(true);
                axios({
                    method: 'put',
                    url: `${process.env.REACT_APP_API_GATEWAY_URL}/invoice/update/dispute-status-resolved`,
                    headers: {
                        "Accept": `application/json`,
                        "Content-Type": `application/json`,
                        "Access-Control-Allow-Origin": '*',
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                        invoiceNo,
                        loggedUserId,
                        resolvedStatus
                    }
                })
                    .then(res => {
                        console.log(res.data)
                        if (res.data.status === 202) {
                            setRefresh(preState=>!preState)
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
        });


    }

    return (
        <>
            <Toaster toastOptions={{ className: 'w-1/2' }} containerStyle={{ top : 63 }} />

            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div
                            className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('Invoice')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('InvoiceInformation')}</sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <button
                                    onClick={() => setOpened(true)}
                                    className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0">
                                    <HiOutlineFilter size={12} className={'mr-1'}/>{t('Filter')}
                                </button>
                                {hasRoleModerator &&
                                    <Link to={'/invoice/create'}>
                                        <button
                                            className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                        >
                                            <HiOutlinePlus size={12} className={'mr-1'}/>{t('Generate Invoice')}
                                        </button>
                                    </Link>
                                }


                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full px-4">
                    <Drawer
                        opened={opened}
                        onClose={() => setOpened(false)}
                        title="Filter Invoice"
                        padding="xl"
                        size="xl"
                        // overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
                        overlayColor={"black"}
                        overlayOpacity={0.55}
                        overlayBlur={3}
                        position={"right"}
                        // transition="rotate-left"
                        transitionDuration={250}
                        transitionTimingFunction="ease"
                    >
                        <form onSubmit={handleSubmit(formSubmit)}>
                            <ScrollArea style={{ height: drawerHeight }}>


                                <div className="mb-2">
                                    <label htmlFor="InvoiceId" className="form-input-sm-label">{t('InvoiceNo')}</label>
                                    <TextInput
                                        {...register("invoiceGenerateId")}
                                        onChange={InvoiceIdDataHandel}
                                        placeholder={t("EnterInvoiceId")}
                                        value={invoiceIdData?invoiceIdData:''}
                                    />

                                </div>

                                <div className="mb-2">
                                    <label htmlFor="clientName" className="form-input-sm-label">{t('ClientName')}</label>
                                    <TextInput
                                        {...register("clientName")}
                                        onChange={ClientNameDataHandel}
                                        placeholder={t("EnterClientName")}
                                        value={clientNameData?clientNameData:''}
                                    />
                                </div>

                                <div className="mb-2">

                                </div>

                            </ScrollArea>



                            <div className="mb-2 mt-5 text-right">

                                <Grid>
                                    <Grid.Col span={6}>


                                    </Grid.Col>
                                    <Grid.Col span={3}>

                                    </Grid.Col>
                                    <Grid.Col span={3}>
                                        <button type='submit' className="px-6 py-2 bg-indigo-800 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                            {spinner ? (
                                                <HiOutlineFilter
                                                    className={'mr-2 animate-spin'}
                                                    size={16}/>
                                            ) : (<HiOutlineFilter size={16} className={'mr-2'}/>)}
                                            <span>{t('Filter')}</span>
                                        </button>
                                    </Grid.Col>
                                </Grid>


                            </div>
                        </form>

                    </Drawer>

                    <Grid align="center">
                        <Grid.Col xs={4} sm={4} md={8} lg={8}>
                            <nav className="isolate flex divide-x divide-gray-200 rounded-t-lg shadow" aria-label="Tabs">
                                <button
                                    className="text-gray-900 rounded group relative min-w-0 flex-1 overflow-hidden bg-gray-100 py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                    aria-current="page"
                                    onClick={() => {
                                        setPage(1);
                                        setActiveTab('all');
                                        setFetching(true)
                                    }}
                                >
                                    <span>{t('All')}</span>
                                    {'all' === activeTab && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5" />}

                                </button>
                                <button
                                    className="text-gray-900 rounded group relative min-w-0 flex-1 overflow-hidden bg-gray-100 py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                    aria-current="page"
                                    onClick={() => {
                                        setPage(1);
                                        setActiveTab('due-payment');
                                        setFetching(true)
                                    }}
                                >
                                    <span>{t('Due Payment')}</span>
                                    {'due-payment' === activeTab && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5" />}

                                </button>
                                <button
                                    className="text-gray-900 rounded group relative min-w-0 flex-1 overflow-hidden bg-gray-100 py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                    aria-current="page"
                                    onClick={() => {
                                        setPage(1);
                                        setActiveTab('partial-paid');
                                        setFetching(true)
                                    }}
                                >
                                    <span>{t('Partial Paid')}</span>
                                    {'partial-paid' === activeTab && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5" />}

                                </button>
                                <button
                                    className="text-gray-900 rounded group relative min-w-0 flex-1 overflow-hidden bg-gray-100 py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                    aria-current="page"
                                    onClick={() => {
                                        setPage(1);
                                        setActiveTab('paid');
                                        setFetching(true)
                                    }}
                                >
                                    <span>{t('Paid')}</span>
                                    {'paid' === activeTab && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5" />}

                                </button>
                                <button
                                    className="text-gray-900 rounded group relative min-w-0 flex-1 overflow-hidden bg-gray-100 py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                    aria-current="page"
                                    onClick={() => {
                                        setPage(1);
                                        setActiveTab('disputed');
                                        setFetching(true)
                                    }}
                                >
                                    <span>{t('Disputed')}</span>
                                    {'disputed' === activeTab && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5" />}

                                </button>
                            </nav>
                        </Grid.Col>
                        <Grid.Col xs={4} sm={4}>
                        </Grid.Col>
                    </Grid>

                    <Box sx={{height: (parseInt(fullTableHeight))+ "px"}}>
                        <DataTable
                            withBorder
                            records={items}
                            striped
                            styles={(theme) => ({
                                root: {
                                    border: '1px solid #e0e7ff',
                                    borderRadius: 2,
                                },
                                header: {
                                    background: '#e0e7ff',
                                    backgroundColor: "#e0e7ff",
                                    color: theme.colors.indigo[6],
                                },
                                pagination: {
                                    color: theme.colors.gray[3],
                                    paddingTop : "5px",
                                    paddingBttom : "5px"
                                },
                                item: {
                                    '&[data-active]': {
                                        backgroundImage: theme.fn.gradient({ from: 'red', to: 'yellow' }),
                                    },
                                },
                            })}
                            columns={
                                [
                                    {
                                        accessor: '',
                                        title: (t('No.')),
                                        width: 45,
                                        render: (item) => (items.indexOf(item) + 1) + PER_PAGE * (page - 1)
                                    },
                                    {
                                        accessor: 'invoiceGenerateId',
                                        title: (t('InvoiceNo')),
                                        width: 150,
                                        sortable: true
                                    },
                                    {
                                        accessor: 'clientName',
                                        title: (t('ClientName')),
                                        width: 200,
                                        sortable: true,
                                        hidden: !!hasRoleClient,
                                        render: (item) => <Text className="cursor-pointer" onClick={() => {setClientId(item.clientId); setShowClientProfile(true)}}>{item.clientName}<span className="ml-2 inline-flex items-center rounded bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800">{t('Details')}</span></Text>,


                                    },
                                    {
                                        accessor: 'invoiceDate',
                                        title: (t('InvoiceDate')),
                                        width: 150,
                                        sortable: true,
                                        render: ({invoiceDate}) => invoiceDate ? format(new Date(invoiceDate.date), 'MM/dd/Y') : '',
                                    },
                                    {
                                        accessor: 'startDate',
                                        title: (t('StartDate')),
                                        render: ({startDate}) => startDate ? format(new Date(startDate.date), 'MM/dd/Y') : '',
                                    },
                                    {
                                        accessor: 'endDate',
                                        title: (t('EndDate')),
                                        render: ({endDate}) => endDate ? format(new Date(endDate.date), 'MM/dd/Y') : '',
                                    },
                                    {
                                        accessor: 'amountDecimalHour',
                                        title: (t('Amount')),
                                        render: ({amountDecimalHour, amount}) => amountDecimalHour ?amountDecimalHour.toFixed(2) : amount.toFixed(2),
                                        width: 150
                                    },
                                    {
                                        accessor: 'paymentStatus',
                                        title: (t('Status')),
                                    },
                                    {
                                        accessor: 'actions',
                                        title: <Text mr="xs">{t('Action')}</Text>,
                                        textAlignment: 'center',
                                        width: 100,
                                        render: (item) => (
                                            <Group spacing={4} position="right" noWrap>
    

                                                { hasRoleClient &&
                                                    <Fragment>
                                                        {
                                                            item.paymentStatus && item.paymentStatus==="CREATED" && item.dispute===0 ?
                                                            <button
                                                                onClick={() => disputeModal(item)}
                                                                className="inline-flex items-center ml-1 rounded border-none bg-amber-600 py-1 px-2 text-xs font-normal text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-0"
                                                            >
                                                                {t('Dispute')}
                                                            </button>
                                                            : ""
                                                        }
                                                        {
                                                            item.dispute && item.disputedBy ? (
                                                                <span
                                                                    className="inline-flex items-center ml-1 rounded border-none bg-amber-800 py-1 px-2 text-xs font-normal text-white shadow-sm focus:outline-none focus:ring-0"
                                                                >
                                                                    {item.dispute===3?"Resolved":"Disputed"}
                                                                </span>
                                                            ): ""
                                                        }

                                                    </Fragment>
                                                }

                                                { hasRoleModerator &&
                                                    <Fragment>
                                                    { item.dispute && item.disputedBy ?
                                                        <>
                                                            {item.dispute===2 ?
                                                                <button
                                                                    onClick={() => disputeStatusResolved(item.invoiceGenerateId)}
                                                                    className="inline-flex items-center ml-1 rounded border-none bg-yellow-400 py-1 px-2 text-xs font-normal text-black shadow-sm focus:outline-none focus:ring-0"
                                                                >
                                                                    {t('Resolve')}
                                                                </button>
                                                                :
                                                                <span
                                                                    className="inline-flex items-center ml-1 rounded border-none bg-amber-800 py-1 px-2 text-xs font-normal text-white shadow-sm focus:outline-none focus:ring-0"
                                                                >
                                                                        {item.dispute===3?"Resolved":(item.dispute===2?"Resolve":"Disputed")}
                                                                    </span>
                                                            }
                                                        </>
                                                        :""
                                                    }
                                                    { item.paymentStatus && item.paymentStatus!=="PAID" ?
                                                        <ActionIcon color="green">
                                                            <HiPencil size={16} onClick = {(e) => {
                                                                navigate(item.id + '/edit')
                                                            }}/>
                                                        </ActionIcon>: ""
                                                    }
                                                    </Fragment>
                                                }

                                                <ActionIcon color="blue">
                                                    <HiEye size={16} onClick={(e) => {

                                                        navigate(item.id + '/summery')

                                                    }}/>
                                                </ActionIcon>
    
                                            </Group>
                                        ),
                                    },
    
                                ]
                            }
                            totalRecords={totalItem}
                            recordsPerPage={PER_PAGE}
                            page={page}
                            onPageChange={(p) => {
                                setPage(p)
                                setFetching(true)
                            }}
                            fetching={fetching}
                            loaderSize="sm"
                            loaderColor="indigo"
                            loaderBackgroundBlur={2}
                            sortStatus={sortStatus}
                            onSortStatusChange={setSortStatus}
                        />
                    </Box>
                </div>
            </main>
            {/*{showModal ? <ViewInvoice setShowModal={setShowModal} invoiceDetails={invoiceDetails} /> : ''}*/}
            { showDisputeModal && <DisputeModal setShowModal={setShowDisputeModal} disputeDetailsData={invoiceDetails} flag={`invoice`} setRefreshList={setRefresh} /> }
            { showClientProfile && <ViewClient setShowModal={setShowClientProfile} clientID={clientId} /> }

        </>
    )
}

export default Index