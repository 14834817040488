import React, {useEffect, useRef} from 'react';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiEye,
    HiOutlineChartSquareBar, HiOutlineExclamation,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiPencil, HiTrash
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Group, Text,Box,ScrollArea} from "@mantine/core";
import {FiAirplay, FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import ModalForm from "./Modal/ModalForm";


const PAGE_SIZE = 1;

function TwoColumnForm() {

    const {t, i18n} = useTranslation();
    const innerContainer = useRef(100);
    const height = innerContainer.current.clientHeight;
    var tableHeight = (height-53);
    var fromHeight = height-92+"px";
    console.log(fromHeight);

    const [items, setItemTypes] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isAddForm, setAddForm] = useState(true);

    //Add Model Init
    let [isOpen, setIsOpen] = useState(false);

    //Submit Spinner Init
    const [spinner, SetSpinner] = useState(false);

    //Add Model close function
    function closeModal() {
        setIsOpen(false)
    }

    //Add Model Open function
    function openModal() {
        setIsOpen(true)
    }

    const [page, setPage] = useState(1);
    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setItemTypes(items.slice(from, to));
    }, [page]);


    //Form validation
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t("NameIsRequired"))
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;


    //Form Data Submit
    function formSubmit(data) {
        // SetSpinner(true);
        const formData = {
            item_types: data.name,
            status:1
        }
    }
    return (
        <>
            <main className="flex flex-1 flex-1 " ref={innerContainer}>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-indigo-50 xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 bg-indigo-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className="w-full flex justify-start text-gray-600 h-12">
                                                <div className={"flex-1"}>
                                                    <h1 className="flex text-gray-800  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                                            {t('pageTitle')}
                                                            <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('pageSubTitle')}</sub></span>
                                                    </h1>
                                                </div>
                                                <div className={"right flex  mr-8"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300" role="group">

                                                            <button
                                                                onClick={event => setAddForm(prevCheck => !prevCheck)}
                                                                className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>Add
                                                                New
                                                            </button>

                                                            <Link to='/master-data/item' className="inline-flex items-center px-4 py-2 bg-indigo-100 text-indigo-600 hover:bg-indigo-200 hover:text-indigo-800 text-white text-xs">
                                                                <HiOutlineChartSquareBar  size={16} className={'mr-1'} ></HiOutlineChartSquareBar>Lists
                                                            </Link>
                                                            <Link to='/master-data/item'
                                                                  className="inline-flex justify-center flex-1 items-center h-full  rounded-full px-2 py-2  text-red-600 text-xs group relative inline-block text-blue-500 underline hover:text-red-500 duration-300">
                                                                <HiOutlineExclamationCircle  size={16} ></HiOutlineExclamationCircle>

                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex flex-1 overflow-hidden -ml-4'}>
                                                <div className="min-h-full w-full bg-indigo-50">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex" >
                                                            <div className="w-full">
                                                                {isAddForm ?
                                                                    <div className="sm:block  h-full  transition-all transition-shadow transition-transform	">
                                                                        <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form" >
                                                                            <div className="relative flex flex-col min-w-0 break-words w-full bg-blueGray-50 border-0 inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full" style={{height:fromHeight}} >

                                                                                <main
                                                                                    className="flex flex-1  w-ful ml-3" >
                                                                                    <section
                                                                                        aria-labelledby="primary-heading"
                                                                                        className="flex h-full  flex-1 flex-col overflow-y-auto">
                                                                                        <div
                                                                                            className="relative flex h-full  flex-col overflow-y-auto border-r border-gray-200 bg-white">
                                                                                            <div className={'pt-3 px-3 sm:border-gray-200'}>
                                                                                                <div className="mb-3 w-full items-center">
                                                                                                    <label htmlFor="first-name" className="form-input-sm-label-required">{t('Name')}</label>

                                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                        {errors.name?.type === 'required' && <div className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                                        </div>}
                                                                                                        <div
                                                                                                            className="form-input-sm-right-icon">
                                                                                                            <FiAirplay size={16}></FiAirplay>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            {...register("name")}
                                                                                                            type="text"
                                                                                                            className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                            name="name"
                                                                                                            placeholder={t("EnterItemName")}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="mb-3 w-full items-center">
                                                                                                    <label htmlFor="first-name" className="form-input-sm-label-required">{t('Name')}
                                                                                                    </label>
                                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                        <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                                                            {errors.country?.type === 'required' && <div
                                                                                                                className="form-input-sm-error">
                                                                                                                <HiOutlineExclamation  size={16} className={'mr-2'}></HiOutlineExclamation> {errors.country?.message}
                                                                                                            </div>}
                                                                                                            <select
                                                                                                                {...register("country")}
                                                                                                                name="country"
                                                                                                                autoComplete="country-name"
                                                                                                                className={`form-input-sm-control ${errors.country ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}>
                                                                                                                <option value="">Select Country</option>
                                                                                                                <option value="United States">United States</option>
                                                                                                                <option value="Canada">Canada</option>
                                                                                                                <option value="Mexico">Mexico</option>
                                                                                                            </select>
                                                                                                            <ModalForm></ModalForm>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="mb-3 w-full items-center">
                                                                                                    <label htmlFor="first-name" className="form-input-sm-label">{t('Name')}</label>

                                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                                        <div
                                                                                                            className="form-input-sm-right-icon">
                                                                                                            <FiAirplay size={16}></FiAirplay>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            {...register("test")}
                                                                                                            type="text"
                                                                                                            className={`form-input-sm-control`}
                                                                                                            name="name"
                                                                                                            placeholder={t("EnterItemName")}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="mb-3 w-full items-center">
                                                                                                    <label htmlFor="first-name" className="form-input-sm-label-required">{t('Name')}</label>

                                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                        {errors.name?.type === 'required' && <div className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                                        </div>}
                                                                                                        <div
                                                                                                            className="form-input-sm-right-icon">
                                                                                                            <FiAirplay size={16}></FiAirplay>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            {...register("name")}
                                                                                                            type="text"
                                                                                                            className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                            name="name"
                                                                                                            placeholder={t("EnterItemName")}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="mb-3 w-full items-center">
                                                                                                    <label htmlFor="first-name" className="form-input-sm-label-required">{t('Name')}</label>

                                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                        {errors.name?.type === 'required' && <div className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                                        </div>}
                                                                                                        <div
                                                                                                            className="form-input-sm-right-icon">
                                                                                                            <FiAirplay size={16}></FiAirplay>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            {...register("name")}
                                                                                                            type="text"
                                                                                                            className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                            name="name"
                                                                                                            placeholder={t("EnterItemName")}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="mb-3 w-full items-center">
                                                                                                    <label htmlFor="first-name" className="form-input-sm-label-required">{t('Name')}</label>

                                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                        {errors.name?.type === 'required' && <div className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                                        </div>}
                                                                                                        <div
                                                                                                            className="form-input-sm-right-icon">
                                                                                                            <FiAirplay size={16}></FiAirplay>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            {...register("name")}
                                                                                                            type="text"
                                                                                                            className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                            name="name"
                                                                                                            placeholder={t("EnterItemName")}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="mb-3 w-full items-center">
                                                                                                    <label htmlFor="first-name" className="form-input-sm-label-required">{t('Name')}</label>

                                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                        {errors.name?.type === 'required' && <div className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                                        </div>}
                                                                                                        <div
                                                                                                            className="form-input-sm-right-icon">
                                                                                                            <FiAirplay size={16}></FiAirplay>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            {...register("name")}
                                                                                                            type="text"
                                                                                                            className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                            name="name"
                                                                                                            placeholder={t("EnterItemName")}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </section>
                                                                                    <section
                                                                                        className="flex h-full  flex-1 flex-col overflow-y-auto">
                                                                                        <div
                                                                                            className="relative flex h-full  flex-col overflow-y-auto  border-gray-200 bg-white">
                                                                                            <div className={'pt-3 px-3  sm:border-gray-200 '}>
                                                                                                <div className="mb-3 w-full items-center">
                                                                                                    <label htmlFor="first-name" className="form-input-sm-label-required">{t('Name')}</label>

                                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                        {errors.name?.type === 'required' && <div className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                                        </div>}
                                                                                                        <div
                                                                                                            className="form-input-sm-right-icon">
                                                                                                            <FiAirplay size={16}></FiAirplay>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            {...register("name")}
                                                                                                            type="text"
                                                                                                            className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                            name="name"
                                                                                                            placeholder={t("EnterItemName")}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="mb-3 w-full items-center">
                                                                                                    <label htmlFor="first-name" className="form-input-sm-label-required">{t('Name')}
                                                                                                    </label>
                                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                        <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                                                            {errors.country?.type === 'required' && <div
                                                                                                                className="form-input-sm-error">
                                                                                                                <HiOutlineExclamation  size={16} className={'mr-2'}></HiOutlineExclamation> {errors.country?.message}
                                                                                                            </div>}
                                                                                                            <select
                                                                                                                {...register("country")}
                                                                                                                name="country"
                                                                                                                autoComplete="country-name"
                                                                                                                className={`form-input-sm-control ${errors.country ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}>
                                                                                                                <option value="">Select Country</option>
                                                                                                                <option value="United States">United States</option>
                                                                                                                <option value="Canada">Canada</option>
                                                                                                                <option value="Mexico">Mexico</option>
                                                                                                            </select>
                                                                                                            <ModalForm></ModalForm>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="mb-3 w-full items-center">
                                                                                                    <label htmlFor="first-name" className="form-input-sm-label">{t('Name')}</label>

                                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">

                                                                                                        <div
                                                                                                            className="form-input-sm-right-icon">
                                                                                                            <FiAirplay size={16}></FiAirplay>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            {...register("test")}
                                                                                                            type="text"
                                                                                                            className={`form-input-sm-control`}
                                                                                                            name="name"
                                                                                                            placeholder={t("EnterItemName")}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="mb-3 w-full items-center">
                                                                                                    <label htmlFor="first-name" className="form-input-sm-label-required">{t('Name')}</label>

                                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                        {errors.name?.type === 'required' && <div className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                                        </div>}
                                                                                                        <div
                                                                                                            className="form-input-sm-right-icon">
                                                                                                            <FiAirplay size={16}></FiAirplay>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            {...register("name")}
                                                                                                            type="text"
                                                                                                            className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                            name="name"
                                                                                                            placeholder={t("EnterItemName")}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="mb-3 w-full items-center">
                                                                                                    <label htmlFor="first-name" className="form-input-sm-label-required">{t('Name')}</label>

                                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                        {errors.name?.type === 'required' && <div className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                                        </div>}
                                                                                                        <div
                                                                                                            className="form-input-sm-right-icon">
                                                                                                            <FiAirplay size={16}></FiAirplay>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            {...register("name")}
                                                                                                            type="text"
                                                                                                            className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                            name="name"
                                                                                                            placeholder={t("EnterItemName")}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="mb-3 w-full items-center">
                                                                                                    <label htmlFor="first-name" className="form-input-sm-label-required">{t('Name')}</label>

                                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                        {errors.name?.type === 'required' && <div className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                                        </div>}
                                                                                                        <div
                                                                                                            className="form-input-sm-right-icon">
                                                                                                            <FiAirplay size={16}></FiAirplay>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            {...register("name")}
                                                                                                            type="text"
                                                                                                            className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                            name="name"
                                                                                                            placeholder={t("EnterItemName")}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="mb-3 w-full items-center">
                                                                                                    <label htmlFor="first-name" className="form-input-sm-label-required">{t('Name')}</label>

                                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                                        {errors.name?.type === 'required' && <div className="form-input-sm-error">
                                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                                        </div>}
                                                                                                        <div
                                                                                                            className="form-input-sm-right-icon">
                                                                                                            <FiAirplay size={16}></FiAirplay>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            {...register("name")}
                                                                                                            type="text"
                                                                                                            className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                            name="name"
                                                                                                            placeholder={t("EnterItemName")}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </section>
                                                                                </main>
                                                                            </div>
                                                                            <footer className="mt-1 bg-indigo-50">
                                                                                <div className="container mx-auto">
                                                                                    <div
                                                                                        className="text-right pt-0.5 mb-0.5">
                                                                                        <button onClick={event => setAddForm(prevCheck => !prevCheck)} type='reset'
                                                                                                className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX
                                                                                            size={16}
                                                                                            className={'mr-2'}></FiX>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}></AiOutlineLoading3Quarters>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}></FiSave>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </footer>
                                                                        </form>
                                                                </div> : ''}

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>



                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </main>
        </>
    )
}
export default TwoColumnForm