import React, {useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {
    HiEye,
    HiOutlineChartSquareBar, HiOutlineExclamation,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiPencil, HiTrash
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Box, Group, Text} from "@mantine/core";
import {FiAirplay, FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {useForm} from "react-hook-form";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Switch} from "@headlessui/react";
import dayjs from "dayjs";
import toast, { Toaster } from 'react-hot-toast';
import { Textarea } from '@material-tailwind/react';

const PER_PAGE = 10;

function Particular() {
    const notify = () => toast.success('home created',{
        "position": 'top-center',
        "duration": 2000,
    });
    const user = JSON.parse(localStorage.getItem('user'));
    // const token = localStorage.getItem('user').token;

    const {roles, token} = user;

    const {t, i18n} = useTranslation();
    const [page, setPage] = useState(localStorage.getItem('item_page') ? localStorage.getItem('item_page') : 1);

    const [items, setItem] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const [isSubmit, setIsSubmit] = useState(false);
    const [recall, setRecall] = useState(false);
    const [isAddForm, setAddForm] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const [status, setStatus] = useState(true);

    const url = `${process.env.REACT_APP_API_GATEWAY_URL}/users/clients/?per_page=${PER_PAGE}&page=${page}`;

    useEffect(() => {
        axios.get(url, {headers: {"Authorization": `Bearer ${token}`}}).then(res => {
            setItem(res.data.data);
            setTotalItem(res.data.totalClients);
            localStorage.setItem('item_page', page);
            // console.log('Call')
        });
    }, [isSubmit, page, recall]);


    const innerContainer = useRef(100);
    const height = innerContainer.current.clientHeight;
    const tableHeight = (height - 63);
    const fromHeight = height - 153 + "px";


    //Form validation
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t("NameIsRequired")),
        code: Yup.string().required(t("CodeIsRequired")),
        particularType: Yup.string().required(t("SelectParticularType"))
    });

    const formOptions = {resolver: yupResolver(validationSchema)};
    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState} = useForm(formOptions);
    const {errors} = formState;


    //Form Data Submit
    function formSubmit(data) {
        setSpinner(true);
        console.log(data);
        // axios({
        //     method: 'post',
        //     url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/clients`,
        //     headers: {
        //         "Accept": `application/json`,
        //         "Content-Type": `application/json`,
        //         "Authorization": `Bearer ${token}`
        //     },
        //     data: data
        // })
        //     .then(function (response) {
        //         setSpinner(false)
        //         notify();
        //         reset();
        //         setRecall(!recall);
        //     })
        //     .catch(function (error) {
        //         console.log(error)
        //     });
    }


    const [editModel, setEditModel] = useState(false);
    const [editData, setEditData] = useState(null);


    const ItemEdit = (e, data) => {
        e.preventDefault();
        setEditModel(true);
        setEditData(data);
    }

    const ItemDelete = (e, id) => {
        e.preventDefault();
        // const thisClick = e.currentTarget;

        if (window.confirm('Are you sure?')) {

            axios({
                method: 'delete',
                url: `${process.env.REACT_APP_API_GATEWAY_URL}/domains/${id}`,
                headers: {
                    "Accept": `application/json`,
                    "Content-Type": `application/json`,
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(function (response) {
                    // thisClick.closest('tr').remove();
                    setRecall(!recall);
                })
                .catch(function (error) {
                    console.log(error)
                });
        }
    }

    const [viewModel, setViewModel] = useState(false);
    const [viewData, setViewData] = useState(null);
    const ItemView = (e, item) => {
        e.preventDefault();
        console.log(item);
        setViewModel(true);
        setViewData(item);
    }

    return (
        <>
        <Toaster toastOptions={{ className: 'w-1/2' }} containerStyle={{ top : 63 }} />

            {/*{editModel?<EditItem data={editData} setEditModel={setEditModel} isSubmit = {isSubmit} setIsSubmit = {setIsSubmit}/>:''}*/}
            {/*{viewModel?<ViewItem data={viewData} setViewModel={setViewModel} isSubmit = {isSubmit} setIsSubmit = {setIsSubmit}/>:''}*/}

            <main className="flex flex-1" ref={innerContainer}>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div
                                                className="w-full flex -ml-4 pl-4 h-12 bg-indigo-50 justify-start text-gray-600 mb-3">
                                                <div className={"flex-1"}>
                                                    <div
                                                        className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                                                        <HiOutlineOfficeBuilding size={24}
                                                                                 className={'mt-3 mr-2 text-indigo-300'}></HiOutlineOfficeBuilding>
                                                        <span
                                                            className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                                            {t('Particular')}
                                                            <sub
                                                                className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('ParticularComponents')}</sub></span>
                                                    </div>
                                                </div>
                                                <div className={"right flex mr-8"}>

                                                    <div className="flex items-center justify-center">
                                                        <div className="inline-flex border-1 border-red-300"
                                                             role="group">

                                                            <button onClick={event => setAddForm(prevCheck => !prevCheck)} className="inline-flex items-center px-2 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-xs">
                                                                <HiOutlinePlus size={12} className={'mr-1'}></HiOutlinePlus>{t('AddParticular')}</button>

            
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex flex-1 overflow-hidden '}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex">
                                                            <div className="w-full">
                                                                <Box sx={{height: tableHeight}}>
                                                                    {/*<TablePagination></TablePagination>*/}

                                                                    <DataTable
                                                                        withBorder
                                                                        records={items}
                                                                        columns={[
                                                                            {
                                                                                accessor: '',
                                                                                title: 'S/N',
                                                                                render: (item) => (items.indexOf(item) + 1) + PER_PAGE * (page - 1)
                                                                            },
                                                                            {
                                                                                accessor: 'ParticularType', 
                                                                                title: <Text mr="xs">{t('ParticularType')}</Text>,
                                                                                width: 150
                                                                            },
                                                                            {
                                                                                accessor: 'Particular',
                                                                                title: <Text mr="xs">{t('Particular')}</Text>, 
                                                                                width: 200
                                                                            },
                                                                            {
                                                                                accessor: 'Ordering', 
                                                                                title: <Text mr="xs">{t('Ordering')}</Text>,
                                                                                width: 100,
                                                                                render: ({roles}) => roles.join(', ')

                                                                            },
                                                                            {
                                                                                accessor: 'Code',
                                                                                title: <Text mr="xs">{t('Code')}</Text>
                                                                            },
                                                                            {
                                                                                title: <Text mr="xs">{t('Enable')}</Text>,
                                                                                accessor: 'status',
                                                                                width: 100,
                                                                                render: ({enable}) => enable ? 'Enable' : 'Disable'
                                                                            },
                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text mr="xs">{t('Action')}</Text>,
                                                                                textAlignment: 'right',
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="right"
                                                                                           noWrap>
                                                                                        <ActionIcon color="green"
                                                                                                    onClick={(e) => ItemEdit(e, item)}>
                                                                                            <HiPencil size={16}/>
                                                                                        </ActionIcon>
                                                                                        <ActionIcon color="blue">
                                                                                            <HiEye size={16}
                                                                                                   onClick={(e) => ItemView(e, item)}/>
                                                                                        </ActionIcon>
                                                                                        <ActionIcon color="red">
                                                                                            <HiTrash size={16}
                                                                                                     onClick={(e) => {
                                                                                                         {
                                                                                                             ItemDelete(e, item.id)
                                                                                                         }
                                                                                                     }}/>
                                                                                        </ActionIcon>
                                                                                    </Group>
                                                                                ),
                                                                            },

                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PER_PAGE}
                                                                        page={page}
                                                                        onPageChange={(p) => setPage(p)}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                {isAddForm ?
                                                    <aside
                                                        className="sm:block h-full transition-all">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">
                                                                        <header className="relative mb-1 bg-indigo-600">
                                                                            <div
                                                                                className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-center flex justify-between">
                                                                                    <div
                                                                                        className="text-white   text-sm font-bold items-center leading-7">  {t('AddNewParticular')}</div>
                                                                                    <button
                                                                                        onClick={event => setAddForm(prevCheck => !prevCheck)}
                                                                                        className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                        type="button">
                                                                                        <FiX></FiX>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>
                                                                        <form onSubmit={handleSubmit(formSubmit)}
                                                                              id="horizontal-form">
                                                                            <div
                                                                                className="relative flex flex-col min-w-0 break-words inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
                                                                                style={{height: fromHeight}}>
                                                                                <div
                                                                                    className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>

                                                                        {/* Particular Type Section */}
                                                                        <div className="mb-3 w-full items-center">
                                                                        <label htmlFor="particularType" className="form-input-sm-label-required">{t('ParticularType')}
                                                                            </label>
                                                                            
                                                                            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                            {errors.particularType?.type === 'required' &&
                                                                            <div
                                                                                className="form-input-sm-error">
                                                                                <HiOutlineExclamation
                                                                                    size={16}
                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.particularType?.message}
                                                                            </div>}
                                                                                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                                    
                                                                                    <select
                                                                                        {...register("particularType")}
                                                                                        name="particularType" id ="particularType"
                                                                                        className={`form-input-sm-control ${errors.particularType ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} ` }>
                                                                                        <option value="">{t('ChooseASettingType')}</option>
                                                                                        <option value="Addition">Addition</option>
                                                                                        <option value="Branch">Branch</option>
                                                                                        <option value="Deduction">Deduction</option>
                                                                                        <option value="DeploymentPost">Deployment Post</option>
                                                                                        <option value="Designation">Designation</option>
                                                                                    </select>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                                {/* Name Section */}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="name"
                                                                                               className="form-input-sm-label-required">{t('Name')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.name?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay
                                                                                                    size={16}></FiAirplay>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("name")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.name ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="name"
                                                                                                placeholder={t("EnterName")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Name BN Section */}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="nameBn"
                                                                                               className="form-input-sm-label">{t('Name(BN)')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            <div
                                                                                                className="form-input-sm-right-icon">
                                                                                                <FiAirplay
                                                                                                    size={16}></FiAirplay>
                                                                                            </div>
                                                                                            <input
                                                                                                {...register("nameBn")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control`}
                                                                                                name="nameBn"
                                                                                                placeholder={t("EnterName(BN)")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Code Section */}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="code"
                                                                                               className="form-input-sm-label-required">{t('Code')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.code?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.code?.message}
                                                                                            </div>}
                                                                                            
                                                                                            <input
                                                                                                {...register("code")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control ${errors.code ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                                name="code"
                                                                                                placeholder={t("EnterCode")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Description Section */}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="description"
                                                                                               className="form-input-sm-label">{t('Description')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            
                                                                                            <Textarea
                                                                                                {...register("description")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control`}
                                                                                                name="description"
                                                                                                placeholder={t("")}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Status Section */}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="status"
                                                                                               className="form-input-sm-label-required">{t('Status')}</label>

                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.status?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.status?.message}
                                                                                            </div>}
                                                                                         
                                                                                            <Switch
                                                                                                name="status"
                                                                                                {...register("status")}
                                                                                                defaultChecked={status}
                                                                                                onChange={() => {
                                                                                                    setStatus(!status)
                                                                                                }}
                                                                                                className={`${status ? 'bg-indigo-300' : 'bg-indigo-900'}
          relative inline-flex h-[24px] w-[60px] shrink-0 cursor-pointer rounded-full border-1 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                                                                                            >
                                                                                                {/*<span className="sr-only">Use setting</span>*/}
                                                                                                <span
                                                                                                    aria-hidden="true"
                                                                                                    className={`${status ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                                                                                />
                                                                                            </Switch>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div
                                                                                        className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button
                                                                                            onClick={event => setAddForm(prevCheck => !prevCheck)}
                                                                                            type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX size={16}
                                                                                              className={'mr-2'}></FiX>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}></AiOutlineLoading3Quarters>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}></FiSave>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </footer>

                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default Particular