import React, {useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiOutlineExclamation,
    HiOutlineOfficeBuilding,
    HiOutlinePlus,
    HiPencil,
    HiOutlineUserGroup,
    HiOutlineX
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {FiArrowDownLeft, FiArrowDownRight, FiSave, FiX} from "react-icons/fi";
import {Switch} from "@headlessui/react";
import {AiOutlineLoading3Quarters, AiOutlineMinus,AiOutlineFileSearch} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import {useParams, useNavigate} from "react-router";
// import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle';
import {format} from 'date-fns';
import {ActionIcon, Select} from "@mantine/core";

const Step1 = (props) => {
    const { data, handleChange, next} = props;
    const {roles, token, loggedUserId, domainId} = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();

    const {t, i18n} = useTranslation();
    const [spinner, setSpinner] = useState(false);

    const [clients, setClients] = useState([]);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [stateData, setStateData] = useState('');
    const [cityData, setCityData] = useState('');
    const [cities, setCities] = useState([]);

    const addJob = (e) => {
        data['domainId'] = domainId;
        data['process'] = "draft";
        data['createdBy'] = loggedUserId;
        e.preventDefault();
        // console.log(data)
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/new-job`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: data
        })
            .then(res => {
                navigate('/job-post/' + res.data.data.jobId + '/edit');
                // console.log(res.data)
            })
            .catch(function (error) {
                console.log(error)
            });
    }


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/dropdown`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setCountries(res.data.data);
            });
    }, []);

    let countryDropdown =
        countries.map((type, index) => {
            return ({
                // 'label': type.name, 'value': type.id
                'label': type.name, 'value': type.id + '-' + type.name
            })
        })
    const [countryData, setCountryData] = useState('');
    const CountryDataHandel = (e) => {
        if (e){
            handleChange({target:{name:'country',value:e}});
            const countryWithId = e.split('-');
            setCountryData(e);
            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/state/dropdown?country_id=${Number(countryWithId[0])}`,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                    if (res.data.status === 200) {
                        setStates(res.data.data)
                    }
                });

            axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/wise/city/dropdown?country_id=${Number(countryWithId[0])}`,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                    if (res.data.status === 200) {
                        setCities(res.data.data)
                    }
                });
        }

    }

    let stateDropdown =
        states.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id + '-' + type.name
            })
        })
    // on change state data get
    const StateDataHandel = (e) => {
        handleChange({target:{name:'state',value:e}});

        const stateWithId = e.split('-');
        setStateData(e);
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/countries/state/wise/city/dropdown?state_id=${Number(stateWithId[0])}`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setCities(res.data.data)
                }
            });
    }

    let cityDropdown =
        cities.map((type, index) => {
            return ({
                'label': type.name, 'value': type.id + '-' + type.name
            })
        })
    //city data handel
    const CityDataHandel = (e) => {

        handleChange({target:{name:'city',value:e}});
        setCityData(e);
    }

    //get active clients
    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/active-clients`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                if (res.status === 200){
                    setClients(res.data.data)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    },[]);


    let clientDropdown =
        clients.map((client, index) => {
            return ({
                'label': client.name, 'value': client.id
            })
        })

    const [clientData, setClientData] = useState('');
    const clientDataHandle = (e) => {
        setClientData(e);
        handleChange({target:{name:'clientId',value:e}});
    }

    const fullFormHeight = localStorage.getItem('fullFormHeight');

    //Form validation
    const validationSchema = Yup.object().shape({
        // client: Yup.string().required(t('SelectClient')),
        // jobTitle: Yup.string().required(t("JobTitleIsRequired")),
        // jobDetails: Yup.string().required(t("JobDetailsIsRequired")),
        // address: Yup.string().required(t('AddressIsRequired')),
        // // streetAddress: Yup.string().required(t('StreetAddressIsRequired')),
        // country: Yup.string().required(t('SelectCountry')),
        // startTime: Yup.string().required(t("StartTimeIsRequired")),
        // endTime: Yup.string().required(t("EndTimeIsRequired")),
        // vacancy: Yup.string().required(t("VacancyIsRequired")),

    });

    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState} = useForm(formOptions);
    const {errors} = formState;



    //Form Data Submit
    function formSubmit(data) {
        setSpinner(true);
    }

    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })


    return (
        <main className="w-full" >
            <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                <div className={"flex-1"}>
                    <div
                        className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                        <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                        <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('JobPost')}
                            <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('JobPostRelatedInformation')}</sub>
                            </span>
                    </div>
                </div>
                <div className={"right flex mr-8"}>
                    <div className="flex items-center justify-center">
                        <div className="inline-flex border-1 border-red-300" role="group">
                            <Link to="/job-post"
                                  onClick={(e) => createJobPost(e)}
                                  className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                            >
                                <AiOutlineFileSearch size={12} className={'mr-1'}/>{t('JobPost')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="min-h-full w-full">
                <div className="flex w-full md:w-auto">
                    <div className="bg-red-100 flex-1">
                        <div className="min-w-0 flex-1 bg-white xl:flex">
                            <div className="bg-white lg:min-w-0 lg:flex-1">
                                <div className="h-full px-4 sm:px-6 lg:px-4">
                                    <div className="relative">
                                        <div className={'flex flex-1 overflow-hidden w-full'}>
                                            <div className="w-full mr-0 mx-auto border">

                                                <form id="horizontal-form">
                                                    <div
                                                        className="px-8 py-3 relative flex flex-col min-w-0 break-words w-full border-0 inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
                                                        style={{height: fullFormHeight}}>
                                                        <div>
                                                            <div className="md:grid md:grid-cols-5 md:gap-6">
                                                                <div className="md:col-span-1">
                                                                    <div className="px-4 sm:px-0">
                                                                        <h3 className="text-lg font-medium leading-6 text-gray-900">{t("AppointmentInformation")}</h3>
                                                                        <p className="mt-1 text-sm text-gray-600">
                                                                            {t('EnterAppointmentRelatedDetails')}
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="mt-5 md:col-span-4 md:mt-0">
                                                                    <div className="shadow sm:overflow-hidden sm:rounded">
                                                                        <div className="space-y-6 bg-gray-50 px-4 py-5 sm:p-6">

                                                                            {
                                                                                hasRoleModerator && (
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="client" className="form-input-sm-label-required">{t('Client')}</label>
                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.client?.type === 'required' &&
                                                                                            <div className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.client?.message}
                                                                                            </div>}
                                                                                            <div
                                                                                                className="relative items-stretch focus-within:z-10">
                                                                                                <Select
                                                                                                    {...register("client")}
                                                                                                    placeholder={t('ChooseClient')}
                                                                                                    searchable clearable
                                                                                                    allowDeselect
                                                                                                    nothingFound="No options"
                                                                                                    data={clientDropdown}
                                                                                                    transition="pop-top-left"
                                                                                                    transitionDuration={80}
                                                                                                    transitionTimingFunction="ease"
                                                                                                    onChange={clientDataHandle}
                                                                                                    // onChange={handleChange}
                                                                                                    value={clientData}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            <div
                                                                                className="mb-3 w-full items-center">
                                                                                <label htmlFor="jobTitle"
                                                                                       className="form-input-sm-label-required">{t('JobTitle')}</label>
                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    {errors.title?.type === 'required' &&
                                                                                    <div
                                                                                        className="form-input-sm-error">
                                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.title?.message}
                                                                                    </div>}
                                                                                    <input
                                                                                        {...register("title")}
                                                                                        type="text"
                                                                                        className={`form-input-sm-control ${errors.title ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                        name="title"
                                                                                        id="title"
                                                                                        placeholder={t("EnterJobTitle")}
                                                                                        defaultValue={data.title}
                                                                                        onChange={handleChange}

                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="mb-3 w-full items-center">
                                                                                <label htmlFor="jobDetails"
                                                                                       className="form-input-sm-label-required">{t('JobDetails')}</label>

                                                                                <div
                                                                                    className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    {errors.description?.type === 'required' &&
                                                                                    <div
                                                                                        className="form-input-sm-error">
                                                                                        <HiOutlineExclamation
                                                                                            size={16}
                                                                                            className={'mr-2'}/> {errors.description?.message}
                                                                                    </div>}
                                                                                    <textarea
                                                                                        {...register("description")}
                                                                                        className={`form-input-sm-control-textarea ${errors.description ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                        name="description"
                                                                                        id="description"
                                                                                        placeholder={t("EnterJobDetails")}
                                                                                        rows={20}
                                                                                        defaultValue={data.description}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div>
                                                                                <label htmlFor="workingDuration" className="form-input-sm-label">{t('WorkingDuration')}</label>
                                                                            </div>
                                                                            <div>
                                                                                <div className='flex flex-wrap -mx-0.2 mb-2 col-span-2'>
                                                                                    <div className="mb-3 w-1/2 items-center">
                                                                                        <div>
                                                                                            <div className="inline-block mt-1 sm:col-span-2 sm:mt-0">
                                                                                                <label htmlFor="startTime" className="form-input-sm-label-required">{t('StartDate')}</label>
                                                                                                {errors.startTime?.type === 'required' &&
                                                                                                <div className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.startTime?.message}
                                                                                                </div>
                                                                                                }
                                                                                                <input
                                                                                                    {...register("startTime")}
                                                                                                    type="date"
                                                                                                    className={`form-input-sm-control`}
                                                                                                    name="startTime"
                                                                                                    id="startTime"
                                                                                                    placeholder={t("")}
                                                                                                    defaultValue={data.startTime}
                                                                                                    onChange={handleChange}
                                                                                                />
                                                                                            </div>
                                                                                            <div
                                                                                                className="inline-block mt-1 sm:col-span-2 ml-8 sm:mt-0">
                                                                                                <label
                                                                                                    htmlFor="endTime"
                                                                                                    className="form-input-sm-label-required">{t('EndDate')}</label>
                                                                                                <input
                                                                                                    {...register("endTime")}
                                                                                                    type="date"
                                                                                                    className={`form-input-sm-control`}
                                                                                                    name="endTime"
                                                                                                    id="endTime"
                                                                                                    placeholder={t("")}
                                                                                                    defaultValue={data.endTime}
                                                                                                    onChange={handleChange}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div
                                                                                className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                <div
                                                                                    className="mb-3 w-2/3 items-center">
                                                                                    <label htmlFor="vacancy"
                                                                                           className="form-input-sm-label-required">{t('Vacancy')}</label>
                                                                                    <div
                                                                                        className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                        {errors.vacancy?.type === 'required' &&
                                                                                        <div
                                                                                            className="form-input-sm-error">
                                                                                            <HiOutlineExclamation
                                                                                                size={16}
                                                                                                className={'mr-2'}/> {errors.vacancy?.message}
                                                                                        </div>
                                                                                        }
                                                                                        <input
                                                                                            {...register("vacancy")}
                                                                                            type="text"
                                                                                            className={`form-input-sm-control`}
                                                                                            name="vacancy"
                                                                                            id="vacancy"
                                                                                            placeholder={t("EnterNumberOfVacancy")}
                                                                                            defaultValue={data.vacancy}
                                                                                            onChange={handleChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="hidden sm:block" aria-hidden="true">
                                                            <div className="py-5">
                                                                <div className="border-t border-gray-200"/>
                                                            </div>
                                                        </div>

                                                        {/* Job Location Section */}
                                                        <div className="mt-10 sm:mt-0">
                                                            <div className="md:grid md:grid-cols-5 md:gap-6">
                                                                <div className="md:col-span-1">
                                                                    <div className="px-4 sm:px-0">
                                                                        <h3 className="text-lg font-medium leading-6 text-gray-900">{t('JobLocation')}</h3>
                                                                        <p className="mt-1 text-sm text-gray-600">{t('EnterJobsLocationRelatedDetails')}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-5 md:col-span-4 md:mt-0">
                                                                    <div className="shadow sm:overflow-hidden sm:rounded">
                                                                        <div className="space-y-6 bg-gray-50 px-4 py-5 sm:p-6">
                                                                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                <div className="mb-3 w-1/3 items-center">
                                                                                    <label htmlFor="country" className="form-input-sm-label-required">{t('Country')}</label>
                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                        {errors.country?.type === 'required' &&
                                                                                        <div className="form-input-sm-error">
                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.country?.message}
                                                                                        </div>
                                                                                        }
                                                                                        <div className="relative mr-3 items-stretch focus-within:z-10">

                                                                                            <Select
                                                                                                {...register("country")}
                                                                                                placeholder={t('ChooseCountry')}
                                                                                                searchable clearable
                                                                                                allowDeselect
                                                                                                nothingFound="No options"
                                                                                                data={countryDropdown}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onChange={CountryDataHandel}
                                                                                                value={countryData}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="mb-3 w-1/3 items-center">
                                                                                    <label htmlFor="state" className="form-input-sm-label">{t('State')}</label>
                                                                                    <div  className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                        <Select
                                                                                            placeholder={t('ChooseState')}
                                                                                            searchable clearable
                                                                                            allowDeselect
                                                                                            nothingFound="No options"
                                                                                            data={stateDropdown}
                                                                                            transition="pop-top-left"
                                                                                            transitionDuration={80}
                                                                                            transitionTimingFunction="ease"
                                                                                            onChange={StateDataHandel}
                                                                                            value={stateData}
                                                                                        />
                                                                                        {/*<input
                                                                                                {...register("state")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control`}
                                                                                                name="state"
                                                                                                id="state"
                                                                                                placeholder={t("EnterState")}
                                                                                                onChange={(e) => updateJobDetails("state", e.target.value)}
                                                                                                defaultValue={data && data.state ? data.state : ''}
                                                                                            />*/}
                                                                                    </div>
                                                                                </div>

                                                                                <div className="mb-3 w-1/3 items-center">
                                                                                    <label htmlFor="city" className="form-input-sm-label">{t('City')}</label>
                                                                                    <div
                                                                                        className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                        {errors.city?.type === 'required' &&
                                                                                        <div className="form-input-sm-error">
                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.city?.message}
                                                                                        </div>
                                                                                        }
                                                                                        <Select
                                                                                            placeholder={t('ChooseCity')}
                                                                                            searchable clearable
                                                                                            allowDeselect
                                                                                            nothingFound="No options"
                                                                                            data={cityDropdown}
                                                                                            transition="pop-top-left"
                                                                                            transitionDuration={80}
                                                                                            transitionTimingFunction="ease"
                                                                                            onChange={CityDataHandel}
                                                                                            value={cityData}
                                                                                        />
                                                                                        {/*<input
                                                                                                {...register("city")}
                                                                                                type="text"
                                                                                                className={`form-input-sm-control`}
                                                                                                name="city"
                                                                                                id="city"
                                                                                                placeholder={t("EnterCity")}
                                                                                                onChange={(e) => updateJobDetails("city", e.target.value)}
                                                                                                defaultValue={data && data.city ? data.city : ''}
                                                                                            />*/}

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className='flex flex-wrap -mx-0.5 mb-2 col-span-2'>
                                                                                <div className="mb-3 w-1/3 items-center">
                                                                                    <label htmlFor="address" className="form-input-sm-label-required">{t('Address')}</label>
                                                                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                        {errors.address?.type === 'required' &&
                                                                                        <div className="form-input-sm-error">
                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.address?.message}
                                                                                        </div>
                                                                                        }
                                                                                        <textarea
                                                                                            {...register("address")}
                                                                                            className={`form-input-sm-control ${errors.address ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                            name="address"
                                                                                            id="address"
                                                                                            placeholder={t("EnterAddress")}
                                                                                            defaultValue={data.address}
                                                                                            onChange={handleChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="mb-3 w-1/3 items-center">
                                                                                    <label htmlFor="streetAddress" className="form-input-sm-label">{t('StreetAddress')}</label>
                                                                                    <div className="relative mt-1 mr-3 sm:col-span-2 sm:mt-0">
                                                                                            <textarea
                                                                                                {...register("streetAddress")}
                                                                                                className={`form-input-sm-control `}
                                                                                                name="streetAddress"
                                                                                                id="streetAddress"
                                                                                                placeholder={t("EnterStreetAddress")}
                                                                                                defaultValue={data.streetAddress}
                                                                                                onChange={handleChange}
                                                                                            />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="mb-3 w-1/3 items-center">
                                                                                    <label htmlFor="zipCode" className="form-input-sm-label">{t('ZipCode')}</label>

                                                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                        {errors.zipCode?.type === 'required' &&
                                                                                        <div
                                                                                            className="form-input-sm-error">
                                                                                            <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.zipCode?.message}
                                                                                        </div>
                                                                                        }
                                                                                        <input
                                                                                            {...register("zipCode")}
                                                                                            type="text"
                                                                                            className={`form-input-sm-control`}
                                                                                            name="zipCode"
                                                                                            id="zipCode"
                                                                                            placeholder={t("EnterZipCode")}
                                                                                            defaultValue={data.zipCode}
                                                                                            onChange={handleChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mb-3 w-full items-center">
                                                                                <label htmlFor="jobDetails" className="form-input-sm-label">{t('LocationMapLink')}</label>

                                                                                <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                    {errors.locationMap?.type === 'required' &&
                                                                                    <div className="form-input-sm-error">
                                                                                        <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.locationMap?.message}
                                                                                    </div>}
                                                                                    <textarea
                                                                                        {...register("locationMap")}
                                                                                        className={`form-input-sm-control-textarea ${errors.locationMap ? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                                        name="locationMap"
                                                                                        id="locationMap"
                                                                                        placeholder={t("EnterLocationMapLink")}
                                                                                        rows={20}
                                                                                        defaultValue={data.locationMap}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <footer className="relative mt-1">
                                                        <div className="container mx-auto justify-items-center">
                                                            <div className="text-right pt-0.5 mb-0.5">
                                                                {/*<button onClick={next} className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">*/}
                                                                <button onClick={e => addJob(e)} className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                    {spinner ? (
                                                                        <AiOutlineLoading3Quarters className={'mr-2 animate-spin'} size={16}/>
                                                                    ) : (<FiArrowDownRight size={16} className={'mr-2'}/>)}
                                                                    <span>{t('Next')}</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </footer>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};
export default Step1;
