import React from "react";
import { HiOutlineExclamation } from "react-icons/hi";
const InputDateTime = ({
    labelText,
    labelFor,
    errors,
    name,
    placeholder,
    registerForm,
}) => {
    return (
        <div className="mb-3 w-full items-center">
            <label htmlFor={labelFor} className="form-input-sm-label-required">
                {labelText}
            </label>

            <div className="relative mt-1">
                {errors.dob?.type === "required" && (
                    <div className="flex bg-red-50 text-red-900 text-xs border border-1 border-red-300  py-1 px-2 right-0 absolute z-30 w-full -top-6">
                        <HiOutlineExclamation
                            size={16}
                            className={"mr-2"}
                        ></HiOutlineExclamation>{" "}
                        {errors.dob?.message}
                    </div>
                )}
                <input
                    {...registerForm}
                    type="date"
                    className={`form-control block w-full px-2 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none focus:ring-0 ${
                        errors.dob
                            ? "border-red-300 focus:border-red-600"
                            : "border-gray-300 focus:border-blue-600"
                    } `}
                    name={name}
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
};

export default InputDateTime;
