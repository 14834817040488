import React, {Fragment, useEffect, useRef, useState} from 'react';
import {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ClockIcon,
    EllipsisHorizontalIcon,
    BriefcaseIcon,
    ChatBubbleOvalLeftEllipsisIcon,
    ClipboardDocumentCheckIcon,
    UserGroupIcon
} from '@heroicons/react/20/solid'
import {Link, useNavigate} from "react-router-dom";

import {useTranslation} from "react-i18next";
import axios from "axios";

import ViewJob from "../modules/job-post/modal/ViewJob";
import {AiOutlineFileProtect, AiOutlineLoading3Quarters} from "react-icons/ai";
import {format} from "date-fns";
import {HiOutlinePlus, HiStar} from "react-icons/hi";
import {HoverCard, Anchor, Text, Group, Grid, ScrollArea, LoadingOverlay} from "@mantine/core";
import {Tab} from "@headlessui/react";
import CheckInCheckOutConfirmationModal from "./modals/CheckInCheckOutConfirmationModal";

function Home() {
    const {domainId, loggedUserId, roles, token} = JSON.parse(localStorage.getItem('user'));

    const {t, i18n} = useTranslation();

    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState((new Date()).getFullYear());
    const [days, setDays] = useState([]);
    // const selectedDay = days.find((day) => day.isSelected);
    const homeHeight = localStorage.getItem('homeHeight');

    const [showModal, setShowModal] = useState(false);
    const [selectJobListTabLoader, setSelectJobListTabLoader] = useState(true);
    const [recallCheckInOut, setRecallCheckInOut] = useState(false);
    const [calendarChangeButton, setCalendarChangeButton] = useState(true);
    const [selectDateLoader, setSelectDateLoader] = useState(true);
    const [showCheckInCheckOutConfirmationModal, setShowCheckInCheckOutConfirmationModal] = useState(false);
    const [checkInCheckOutData, setCheckInCheckOutData] = useState({});
    const navigate = useNavigate();

    const [showTotalJobsLoader, setShowTotalJobsLoader] = useState(true);
    const [showTotalAppointmentsLoader, setShowTotalAppointmentsLoader] = useState(true);
    const [showTotalClientsLoader, setShowTotalClientsLoader] = useState(true);
    const [showTotalCandidatesLoader, setShowTotalCandidatesLoader] = useState(true);


    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    // const selectedDay = true

    const [totalClients, setTotalClients] = useState(null);
    const getTotalClients = () => {
        axios({
            method: 'get',
            // url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/active-clients`,
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/client`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                setTotalClients(res.data.records);
                setShowTotalClientsLoader(false);

            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const [totalCandidates, setTotalCandidates] = useState(null);
    const getTotalCandidates = () => {
        axios({
            method: 'get',
            // url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/active-clients`,
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/candidate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                setTotalCandidates(res.data.records);
                setShowTotalCandidatesLoader(false);

            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const [totalJobs, setTotalJobs] = useState(null);
    const getTotalJobs = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/jobs/`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "status": ["all"],
                "domainId": domainId,
                "loggedUserId": loggedUserId,
                "roles": roles,
            }
        })
            .then(res => {
                setTotalJobs(res.data.total);
                setShowTotalJobsLoader(false);
            });
    }

    const [totalAppointments, setTotalAppointments] = useState(null);
    const getTotalAppointments = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/jobs/`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "status": ["published"],
                "domainId": domainId,
                "loggedUserId": loggedUserId,
                "roles": roles,
            }
        })
            .then(res => {
                setTotalAppointments(res.data.total);
                setShowTotalAppointmentsLoader(false);
            });
    }
    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/dashboard-job-calendar`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "month": month,
                "year": year,
                "domainId": domainId,
                "loggedUserId": loggedUserId,
                "roles": roles
            }
        })
            .then(res => {
                setDays(res.data.data)
                setMonth(res.data.month)
                setYear(res.data.year)
                // setCalendarChangeButton(false)
                setTimeout(()=> setCalendarChangeButton(false), 1)

            })
            .catch(function (error) {
                console.log(error)
            });

    }, [month]);

    const [jobDetails, setJobDetails] = useState({});
    //get job details
    const jobView = (jobId) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/${jobId}/show-job`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                loggedUserId
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setJobDetails(res.data.data)
                    setShowModal(!!setJobDetails)
                }
            });
    }

    const [selectDate, setSelectDate] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [jobList, setJobList] = useState([]);



    const getJobList = (selectDate) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/dashboard-job-list-by-date`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                domainId,
                loggedUserId,
                roles,
                date: selectDate
            }
        })
            .then(res => {
                // console.log(res)
                setJobList(res.data.data);
                setSelectDate(selectDate);
                setTimeout(() => setSelectDateLoader(false), 1)
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const [availableJobCheckInCheckOut, setAvailableJobCheckInCheckOut] = useState({});
    const getAvailableJobForCheckInCheckOut = (userId) => {
        // job-available-check-in
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-available-check-in-check-out`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: { loggedUserId: userId }
        })
            .then(res => {
                setAvailableJobCheckInCheckOut(res.data.data)
            })
            .catch(function (error) {
                console.log(error)
            });
    }
    const [selectJobListTab, setSelectJobListTab] = useState([]);
    const [candidateJobList, setCandidateJobList] = useState([]);
    const getCandidateJobs = () => {
        // candidate-job-list
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/candidate-job-list`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                userId: loggedUserId,
                // flags: [selectJobListTab]
                flags: selectJobListTab
            }
        })
            .then(res => {
                setCandidateJobList(res.data.data)
                setTimeout(() => setSelectJobListTabLoader(false), 1)
            })
            .catch(function (err) {
                console.log(err);

            });
    }

    useEffect(()=>{
        if (hasRoleCandidate){
            getCandidateJobs();
        }
    }, [selectJobListTab])

    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })

    const hasRoleClient = roles.find(role => {
        return role === 'ROLE_CLIENT';
    })

    const hasRoleCandidate = roles.find(role => {
        return role === 'ROLE_CANDIDATE';
    })

    useEffect(() => {
        if (hasRoleModerator){
            getTotalClients();
            getTotalCandidates();
            getTotalJobs();
            getTotalAppointments();
        }

        getJobList(selectDate);
    },[]);

    useEffect(()=> {
        if (hasRoleCandidate){
            getAvailableJobForCheckInCheckOut(loggedUserId);
            /*            if (Object.keys(availableJobCheckInCheckOut).length === 0){
                            getAvailableJobForCheckInCheckOut("check-out");
                        }*/
        }
    }, [recallCheckInOut]);

    const customLoader = (
        <img src={"/assets/images/simple-staffing.png"} className='w-6' />
    );

    return (
        <>
            <main className="w-full relative">
                <ScrollArea style={{ height: homeHeight }} scrollbarSize={4}>
                <div className="mx-auto px-6 bg-indigo-50 pb-4" >
                    {
                        hasRoleModerator && (
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mb-2 pt-6">
                                {/*Card*/}
                                <div className="overflow-hidden rounded-md bg-white relative">
                                    { <LoadingOverlay visible={showTotalJobsLoader} overlayBlur={2} loaderProps={{ size: 'xs', color: 'indigo' }} overlayColor="bg-indigo-100"/> }
                                    {/* { <LoadingOverlay visible={true} loader={customLoader} overlayBlur={2} loaderProps={{ size: 'xs', color: 'indigo' }} overlayColor="bg-indigo-100"/> } */}
                                    <Link to={"/job-post"} className="cursor-pointer">
                                        <div className="p-5">
                                            <div className="flex items-center">
                                                <div className="">
                                                    <ChatBubbleOvalLeftEllipsisIcon
                                                        className="h-5 w-5 text-indigo-500 hover:text-indigo-400"
                                                        aria-hidden="true"/>
                                                </div>
                                                <div className="ml-5 w-0 flex-1">
                                                    <dl>
                                                        <dt className="truncate text-sm font-medium text-gray-500">{t('TotalJobs')}</dt>
                                                        <dd>
                                                            <div className="text-lg font-medium text-gray-900">{ totalJobs ? totalJobs : 0 } </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="overflow-hidden rounded-md bg-white relative">
                                    { <LoadingOverlay visible={showTotalAppointmentsLoader} overlayBlur={2} loaderProps={{ size: 'xs', color: 'indigo' }} overlayColor="bg-indigo-100"/> }
                                    <Link to={"/appointments"} className="cursor-pointer">
                                        <div className="p-5">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0">
                                                    <BriefcaseIcon className="h-5 w-5 text-indigo-500 hover:text-indigo-400" aria-hidden="true"/>
                                                </div>
                                                <div className="ml-5 w-0 flex-1">
                                                    <dl>
                                                        <dt className="truncate text-sm font-medium text-gray-500">{t('Appointments')}</dt>
                                                        <dd>
                                                            <div
                                                                className="text-lg font-medium text-gray-900">{ totalAppointments ? totalAppointments : 0 }
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="overflow-hidden rounded-md bg-white relative">
                                    { <LoadingOverlay visible={showTotalClientsLoader} overlayBlur={2} loaderProps={{ size: 'xs', color: 'indigo' }} overlayColor="bg-indigo-100"/> }
                                    <Link to={`/client`} className="cursor-pointer">
                                        <div className="p-5">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0">
                                                    <ClipboardDocumentCheckIcon
                                                        className="h-5 w-5 text-indigo-500 hover:text-indigo-400"
                                                        aria-hidden="true"/>
                                                </div>
                                                <div className="ml-5 w-0 flex-1">
                                                    <dl>
                                                        <dt className="truncate text-sm font-medium text-gray-500">{t('Clients')}</dt>
                                                        <dd>
                                                            <div
                                                                className="text-lg font-medium text-gray-900">{ totalClients ? totalClients : 0}
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="overflow-hidden rounded-md bg-white relative">
                                    { <LoadingOverlay visible={showTotalCandidatesLoader} overlayBlur={2} loaderProps={{ size: 'xs', color: 'indigo' }} overlayColor="bg-indigo-100"/> }

                                    <Link to={`/candidate`} className="cursor-pointer">
                                        <div className="p-5">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0">
                                                    <UserGroupIcon className="h-5 w-5 text-indigo-500 hover:text-indigo-400" aria-hidden="true"/>
                                                </div>
                                                <div className="ml-5 w-0 flex-1">
                                                    <dl>
                                                        <dt className="truncate text-sm font-medium text-gray-500">{t('Candidates')}</dt>
                                                        <dd>
                                                            <div
                                                                className="text-lg font-medium text-gray-900">{ totalCandidates ? totalCandidates : 0 }
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )
                    }


                    <div className="md:grid md:grid-cols-2  mb-6">
                        <section>
                            <header
                                className="flex items-center justify-between border-b border-gray-200 py-4 px-6 pl-0 lg:flex-none h-16 mr-3">
                                <h1 className="text-lg font-semibold text-gray-900">
                                    {/*<time dateTime="2022-01">December 2022</time>*/}
                                    <time dateTime="">{(new Date(year + '-' + month + '-01')).toLocaleString('en-US', {month: 'long'}) + ' ' + (new Date(year + '-' + month + '-01')).getFullYear()}</time>
                                </h1>
                                <div className="flex items-center">
                                    <div className="flex items-center rounded-md shadow-sm md:items-stretch">
                                        <button
                                            disabled={calendarChangeButton}
                                            type="button"
                                            className="disabled:cursor-wait disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white p-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                                            onClick={() => {
                                                setCalendarChangeButton(true)
                                                setMonth(month === 1 ? 12 : (month - 1))
                                                setYear(month === 1 ? (year - 1) : year)
                                            }}
                                        >
                                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                                        </button>
                                        <span className="relative -mx-px h-5 w-px bg-gray-300 lg:h-9 md:h-7"/>
                                        <button
                                            disabled={calendarChangeButton}
                                            type="button"
                                            className="disabled:cursor-wait disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white p-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                                            onClick={() => {
                                                setCalendarChangeButton(true)
                                                setMonth(month === 12 ? 1 : (month + 1))
                                                setYear(month === 12 ? (year + 1) : year)

                                            }}

                                        >
                                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                                        </button>
                                    </div>

                                </div>
                            </header>
                            <div>
                                <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700">

                                    <div className="bg-white py-2">
                                        S<span className="sr-only not-sr-only">un</span>
                                    </div>
                                    <div className="bg-white py-2">
                                        M<span className="sr-only not-sr-only">on</span>
                                    </div>
                                    <div className="bg-white py-2">
                                        T<span className="sr-only not-sr-only">ue</span>
                                    </div>
                                    <div className="bg-white py-2">
                                        W<span className="sr-only not-sr-only">ed</span>
                                    </div>
                                    <div className="bg-white py-2">
                                        T<span className="sr-only not-sr-only">hu</span>
                                    </div>
                                    <div className="bg-white py-2">
                                        F<span className="sr-only not-sr-only">ri</span>
                                    </div>
                                    <div className="bg-white py-2">
                                        S<span className="sr-only not-sr-only">at</span>
                                    </div>
                                </div>
                                <div className="flex bg-indigo-50 text-xs leading-6 text-gray-700">
                                    <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px relative" style={{ height: "340px" }}>
                                        { calendarChangeButton && <LoadingOverlay visible={calendarChangeButton} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'sm' }} overlayColor="bg-indigo-100"/> }
                                        {
                                            days && days.map((day) => (
                                                <button
                                                    key={day.date}
                                                    type="button"
                                                    onClick={() => {
                                                        setSelectDateLoader(true)
                                                        getJobList(day.date)
                                                    }}
                                                    className={classNames(
                                                        day.isCurrentMonth ? 'bg-white focus:outline-none focus:ring focus:ring-indigo-800' : 'bg-gray-50 focus:outline-none focus:ring focus:ring-indigo-800',
                                                        (day.isSelected || day.isToday) && 'font-semibold',
                                                        day.isSelected && 'text-white',
                                                        !day.isSelected && day.isToday && 'text-indigo-600',
                                                        !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                                                        !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                                                        'flex h-14 flex-col py-2 px-3 hover:bg-gray-100 focus:z-10'
                                                    )}
                                                >
                                                    <time
                                                        dateTime={day.date}
                                                        className={classNames(
                                                            day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                                                            day.isSelected && day.isToday && 'bg-indigo-600',
                                                            day.isSelected && !day.isToday && 'bg-gray-900',
                                                            'ml-auto'
                                                        )}
                                                    >
                                                        {day.date.split('-').pop().replace(/^0/, '')}
                                                    </time>
                                                    {day.hasJobs && (
                                                        <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                                                <span className="h-2 w-2 rounded-full bg-red-500"/>
                                                        </span>
                                                    )}
                                                </button>
                                            ))

                                        }
                                    </div>
                                </div>

                            </div>
                        </section>
                        <section>
                            <header className="border-b border-gray-200 py-5 flex justify-between items-center h-16 ml-3">
                                <h1 className="text-lg font-semibold text-gray-900">{t('JobsFor')} <time dateTime="">{format(new Date(selectDate), 'MMMM dd, Y')}</time></h1>
                                { !hasRoleCandidate && jobList.length > 0 && (
                                    <Link to ='/job-post/create'
                                          className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    >
                                        <HiOutlinePlus size={12} className={'mr-1'}/>{t('NewJob')}
                                    </Link>
                                )}
                            </header>
                            <div className="relative">
                                {selectDateLoader && <LoadingOverlay visible={selectDateLoader} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'sm' }} overlayColor="bg-indigo-100"/>}
                                {
                                    jobList.length > 0 ? (
                                        <ScrollArea className="ml-3 text-sm leading-6 text-gray-500 border rounded"  style={{ height: "380px" }} scrollbarSize={4}>
                                            <ol className="space-y-1 text-sm leading-6 text-gray-500">
                                                {
                                                    jobList.map((job, index) => (
                                                        <li key={index} onClick={() => jobView(job.id)} className="cursor-pointer group flex items-center bg-indigo-100 space-x-4 rounded py-2 px-4 focus-within:bg-gray-100 hover:bg-indigo-200">
                                                            <AiOutlineFileProtect size={24} className="h-10 w-10 flex-none rounded-full text-indigo-400"/>

                                                            <div className="flex-auto">
                                                                <p className="text-gray-900"
                                                                   data-config-id="text14">{job.jobTitle.substring(0, 40)}{job.jobTitle.length > 40 && '...'}</p>
                                                                <p className="mt-0.5">{t('noOfVacancy')}: {job.vacancy}</p>
                                                                {/*<p className="mt-0.5">
                                                <time dateTime={job.startTime ? format(new Date(job.startTime.date), 'dd-MM-yyyy') : ''}>{job.startTime ? format(new Date(job.startTime.date), 'dd-MM-yyyy') : ''}</time>
                                                - <time dateTime="2022-01-21T14:30">2:30
                                                PM</time></p>*/}
                                                            </div>
                                                            <div
                                                                className="relative opacity-0 focus-within:opacity-100 group-hover:opacity-100">
                                                                <div>
                                                                    <button type="button"
                                                                            className="-m-2 flex items-center rounded-full p-1.5 text-gray-500 hover:text-gray-600"
                                                                            id="menu-0-button" aria-expanded="false"
                                                                            aria-haspopup="true">
                                                                        <span className="sr-only" data-config-id="text21">Open options</span>
                                                                        <svg className="h-6 w-6"
                                                                             xmlns="http://www.w3.org/2000/svg"
                                                                             fill="none" viewBox="0 0 24 24"
                                                                             strokeWidth="1.5"
                                                                             stroke="currentColor"
                                                                             aria-hidden="true"
                                                                             data-config-id="svg-inline8">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"/>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </ol>
                                        </ScrollArea>
                                    ) : (
                                        <div className="text-gray-500 text-center flex flex-col justify-center items-center min-h-[380px]">
                                            {/*<FiSearch/>*/}
                                            <h2 className="text-gray-500 text-md">No Jobs for <br/>{format(new Date(selectDate), 'MMMM dd, Y')}</h2>
                                            {
                                                !hasRoleCandidate && (
                                                    <Link to ='/job-post/create'
                                                          className="inline-flex items-center rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                                    >
                                                        <HiOutlinePlus size={12} className={'mr-1'}/>{t('NewJob')}
                                                    </Link>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>

                        </section>
                    </div>

                    {
                        hasRoleCandidate && (
                            <div className="md:grid md:grid-cols-2 my-2">
                                <section>
                                    <div className="p-1 mb-8 bg-white shadow rounded">
                                        <Grid align="center">
                                            <Grid.Col xs={6} sm={6}>
                                                <nav className="isolate flex divide-x divide-indigo-200" aria-label="Tabs">
                                                    <button
                                                        className="text-indigo-600 rounded-r relative min-w-0 bg-indigo-50 flex-1 overflow-hidden py-2 text-sm font-medium text-center focus:z-10 focus:bg-indigo-100"
                                                        aria-current="page"
                                                        onClick={() => {
                                                            setSelectJobListTabLoader(true)
                                                            setSelectJobListTab([])
                                                        }}
                                                    >
                                                        <span>{t('Matched')}</span>
                                                        {JSON.stringify([]) === JSON.stringify(selectJobListTab) && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5"/> }
                                                    </button>
                                                    <button
                                                        className="text-indigo-600 rounded-l relative min-w-0 bg-indigo-50 flex-1 overflow-hidden py-2 text-sm font-medium text-center focus:z-10 focus:bg-indigo-100"
                                                        aria-current="page"
                                                        onClick={() => {
                                                            setSelectJobListTabLoader(true)
                                                            // setSelectJobListTab([])
                                                            setSelectJobListTab(['applied', 'accepted'])
                                                        }}
                                                    >
                                                        <span>{t('Applied')}</span>
                                                        {JSON.stringify(['applied', 'accepted']) === JSON.stringify(selectJobListTab) && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5"/> }
                                                    </button>
                                                    <button
                                                        // className="text-gray-900 rounded group relative min-w-0 flex-1 overflow-hidden bg-gray-100 py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                                        className="text-indigo-600 relative min-w-0 bg-indigo-50 flex-1 overflow-hidden py-2 text-sm font-medium text-center focus:z-10 focus:bg-indigo-100"
                                                        aria-current="page"
                                                        onClick={() => {
                                                            setSelectJobListTabLoader(true)
                                                            // setSelectJobListTab([])
                                                            setSelectJobListTab(['continue', 'confirmed'])
                                                        }}
                                                    >
                                                        <span>{t('Confirmed')}</span>
                                                        {JSON.stringify(['continue', 'confirmed']) === JSON.stringify(selectJobListTab) && <span aria-hidden="true" className="bg-indigo-500 absolute inset-x-0 bottom-0 h-0.5"/> }
                                                    </button>
                                                </nav>
                                            </Grid.Col>
                                            <Grid.Col xs={6} sm={6}/>
                                        </Grid>
                                        <ScrollArea scrollbarSize={4} style={{height: 360}} className="relative">
                                            { selectJobListTabLoader && <LoadingOverlay visible={selectJobListTabLoader} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'sm' }} overlayColor="bg-indigo-100"/> }
                                            <ol className="mt-4 space-y-1 text-sm leading-6 text-gray-500 sidebar-div-scroll hover:scroll-auto" style={{height: '365px'}}>
                                                {
                                                    candidateJobList && candidateJobList.length > 0 ? candidateJobList.map((job, index) => (
                                                        <li key={index} onClick={() => jobView(job.id)} className="cursor-pointer group flex items-center bg-indigo-100 space-x-4 rounded py-2 px-4 focus-within:bg-gray-100 hover:bg-indigo-200">
                                                            <AiOutlineFileProtect size={24} className="h-10 w-10 flex-none rounded-full text-indigo-400"/>

                                                            <div className="flex-auto">
                                                                <p
                                                                    className="text-gray-900"
                                                                    data-config-id="text14"
                                                                >{job.title.substring(0, 40)}{job.title.length > 40 && '...'}</p>
                                                            </div>
                                                            {JSON.stringify([]) === JSON.stringify(selectJobListTab) && job['userId'] === loggedUserId && job['applyStatusSlug'] === 'preferred' ? <HiStar size={30} className='text-amber-500'/> : ''}
                                                            <div className="relative opacity-0 focus-within:opacity-100 group-hover:opacity-100">
                                                                
                                                                <div>
                                                                        <span
                                                                            className="-m-2 flex items-center rounded-full p-1.5 text-gray-500 hover:text-gray-600"
                                                                            id="menu-0-button" aria-expanded="false"
                                                                            aria-haspopup="true"
                                                                        >
                                                                            <span className="sr-only" data-config-id="text21">Open options</span>
                                                                            <svg className="h-6 w-6"
                                                                                 xmlns="http://www.w3.org/2000/svg"
                                                                                 fill="none" viewBox="0 0 24 24"
                                                                                 strokeWidth="1.5"
                                                                                 stroke="currentColor"
                                                                                 aria-hidden="true"
                                                                                 data-config-id="svg-inline8">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"/>
                                                                            </svg>
                                                                        </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )) : (
                                                        <div className="text-gray-500 text-center flex flex-col justify-center items-center min-h-[365px]">
                                                            <h2 className="text-gray-500 text-md">{t('NoJobs')}</h2>
                                                        </div>
                                                    )
                                                }
                                            </ol>
                                        </ScrollArea>
                                    </div>
                                </section>
                            </div>
                        )
                    }

                    {
                        !hasRoleCandidate && (
                            <div className="md:grid md:grid-cols-2 mt-2">
                                <section>
                                    <div className="p-6 mb-6 bg-white shadow rounded mr-3">
                                        <div className="flex mb-3 items-center justify-between">
                                            <h3 className="text-gray-500" data-config-id="header1">{t('TotalIncome')}</h3>
                                            <button className="focus:outline-none">
                                                <svg className="h-4 w-4 text-gray-200" viewBox="0 0 16 4" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-1-1">
                                                    <path d="M8 0.333344C7.67037 0.333344 7.34813 0.431092 7.07405 0.614228C6.79997 0.797363 6.58635 1.05766 6.4602 1.36221C6.33406 1.66675 6.30105 2.00186 6.36536 2.32516C6.42967 2.64846 6.5884 2.94543 6.82149 3.17852C7.05458 3.41161 7.35155 3.57034 7.67485 3.63465C7.99815 3.69896 8.33326 3.66596 8.63781 3.53981C8.94235 3.41366 9.20265 3.20004 9.38578 2.92596C9.56892 2.65188 9.66667 2.32965 9.66667 2.00001C9.66667 1.55798 9.49107 1.13406 9.17851 0.821499C8.86595 0.508939 8.44203 0.333344 8 0.333344ZM2.16667 0.333344C1.83703 0.333344 1.5148 0.431092 1.24072 0.614228C0.966635 0.797363 0.753014 1.05766 0.626868 1.36221C0.500722 1.66675 0.467717 2.00186 0.532025 2.32516C0.596334 2.64846 0.755068 2.94543 0.988156 3.17852C1.22124 3.41161 1.51822 3.57034 1.84152 3.63465C2.16482 3.69896 2.49993 3.66596 2.80447 3.53981C3.10902 3.41366 3.36931 3.20004 3.55245 2.92596C3.73559 2.65188 3.83333 2.32965 3.83333 2.00001C3.83333 1.55798 3.65774 1.13406 3.34518 0.821499C3.03262 0.508939 2.6087 0.333344 2.16667 0.333344ZM13.8333 0.333344C13.5037 0.333344 13.1815 0.431092 12.9074 0.614228C12.6333 0.797363 12.4197 1.05766 12.2935 1.36221C12.1674 1.66675 12.1344 2.00186 12.1987 2.32516C12.263 2.64846 12.4217 2.94543 12.6548 3.17852C12.8879 3.41161 13.1849 3.57034 13.5082 3.63465C13.8315 3.69896 14.1666 3.66596 14.4711 3.53981C14.7757 3.41366 15.036 3.20004 15.2191 2.92596C15.4023 2.65188 15.5 2.32965 15.5 2.00001C15.5 1.55798 15.3244 1.13406 15.0118 0.821499C14.6993 0.508939 14.2754 0.333344 13.8333 0.333344Z"  fill="currentColor"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="flex items-center mb-3">
                                            <span className="text-4xl font-bold" data-config-id="value1">$124,563.00</span>
                                            <span
                                                className="inline-block ml-2 py-1 px-2 bg-green-500 text-white text-xs rounded-full"
                                                data-config-id="change1">+6.9%</span>
                                        </div>
                                        <div className="relative w-full h-1 mb-2 bg-gray-50 rounded">
                                            <div className="absolute top-0 left-0 w-4/6 h-full bg-purple-500 rounded"/>
                                        </div>
                                        <p className="text-xs text-gray-200" data-config-id="desc1">{t('YearlyGoal')}</p>
                                    </div>
                                    <div className="p-6 mb-6 bg-white shadow rounded mr-3">
                                        <div className="flex mb-3 items-center justify-between">
                                            <h3 className="text-gray-500" data-config-id="header1">New users</h3>
                                            <button className="focus:outline-none">
                                                <svg className="h-4 w-4 text-gray-200" viewBox="0 0 16 4" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-1-1">
                                                    <path d="M8 0.333344C7.67037 0.333344 7.34813 0.431092 7.07405 0.614228C6.79997 0.797363 6.58635 1.05766 6.4602 1.36221C6.33406 1.66675 6.30105 2.00186 6.36536 2.32516C6.42967 2.64846 6.5884 2.94543 6.82149 3.17852C7.05458 3.41161 7.35155 3.57034 7.67485 3.63465C7.99815 3.69896 8.33326 3.66596 8.63781 3.53981C8.94235 3.41366 9.20265 3.20004 9.38578 2.92596C9.56892 2.65188 9.66667 2.32965 9.66667 2.00001C9.66667 1.55798 9.49107 1.13406 9.17851 0.821499C8.86595 0.508939 8.44203 0.333344 8 0.333344ZM2.16667 0.333344C1.83703 0.333344 1.5148 0.431092 1.24072 0.614228C0.966635 0.797363 0.753014 1.05766 0.626868 1.36221C0.500722 1.66675 0.467717 2.00186 0.532025 2.32516C0.596334 2.64846 0.755068 2.94543 0.988156 3.17852C1.22124 3.41161 1.51822 3.57034 1.84152 3.63465C2.16482 3.69896 2.49993 3.66596 2.80447 3.53981C3.10902 3.41366 3.36931 3.20004 3.55245 2.92596C3.73559 2.65188 3.83333 2.32965 3.83333 2.00001C3.83333 1.55798 3.65774 1.13406 3.34518 0.821499C3.03262 0.508939 2.6087 0.333344 2.16667 0.333344ZM13.8333 0.333344C13.5037 0.333344 13.1815 0.431092 12.9074 0.614228C12.6333 0.797363 12.4197 1.05766 12.2935 1.36221C12.1674 1.66675 12.1344 2.00186 12.1987 2.32516C12.263 2.64846 12.4217 2.94543 12.6548 3.17852C12.8879 3.41161 13.1849 3.57034 13.5082 3.63465C13.8315 3.69896 14.1666 3.66596 14.4711 3.53981C14.7757 3.41366 15.036 3.20004 15.2191 2.92596C15.4023 2.65188 15.5 2.32965 15.5 2.00001C15.5 1.55798 15.3244 1.13406 15.0118 0.821499C14.6993 0.508939 14.2754 0.333344 13.8333 0.333344Z"  fill="currentColor"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="flex items-center mb-3">
                                            <span className="text-4xl font-bold" data-config-id="value1">94.2%</span>
                                            <span
                                                className="inline-block ml-2 py-1 px-2 bg-green-500 text-white text-xs rounded-full"
                                                data-config-id="change1">+6.9%</span>
                                        </div>
                                        <div className="relative w-full h-1 mb-2 bg-gray-50 rounded">
                                            <div className="absolute top-0 left-0 w-4/6 h-full bg-purple-500 rounded"/>
                                        </div>
                                        <p className="text-xs text-gray-200" data-config-id="desc1">{t('YearlyGoal')}</p>
                                        <div>
                                            <img className="h-full" src={"/assets/images/bar-chart.png"} alt="Line graph" />
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className="w-ful lg:pl-3 md:pl-3">
                                        <div className="bg-white rounded">
                                            <div className="flex items-center py-5 px-6 border-b">
                                                <h3 className="text-2xl w-1/2 font-bold" data-config-id="header3">{t('Balance')}</h3>
                                                <div
                                                    className="ml-auto inline-block py-2 w-1/2 px-3 w-full text-xs text-gray-500">
                                                    <select className="pr-1 w-full" name="">
                                                        <option value="1" data-config-id="option1">{t('Monthly')}</option>
                                                        <option value="1" data-config-id="option2">{t('Yearly')}</option>
                                                        <option value="1" data-config-id="option3">{t('Weekly')}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="pt-4 px-6">
                                                <div className="flex flex-wrap -m-4 pb-16">
                                                    <div className="w-full md:w-1/2 p-4">
                                                        <div className="py-4 px-6 border rounded">
                                                            <h4 className="text-xs text-gray-500"
                                                                data-config-id="header3-1">{t('Earnings')}</h4>
                                                            <div className="flex items-center">
                                                        <span className="mr-2 text-3xl font-bold"
                                                              data-config-id="value3-1">43.41%</span>
                                                                <span
                                                                    className="py-1 px-2 bg-green-500 text-xs text-white rounded-full"
                                                                    data-config-id="change3-1">+2.5%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full md:w-1/2 p-4">
                                                        <div className="py-4 px-6 border rounded">
                                                            <h4 className="text-xs text-gray-500"
                                                                data-config-id="header3-2">{t('SalesValue')}</h4>
                                                            <div className="flex items-center">
                                                        <span className="mr-2 text-3xl font-bold"
                                                              data-config-id="value3-2">$95,422</span>
                                                                <span
                                                                    className="py-1 px-2 bg-green-500 text-xs text-white rounded-full"
                                                                    data-config-id="change3-2">+13.5%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex items-center py-5 pt-0 px-6 border-b">
                                                <img className="h-full" src={"/assets/images/line-graph.png"} alt="Line graph" />

                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        )
                    }
                </div>
                </ScrollArea>
                {hasRoleCandidate && availableJobCheckInCheckOut && Object.keys(availableJobCheckInCheckOut).length > 0 && (
                        <div className="absolute bottom-0 right-0 flex">
                            {
                                availableJobCheckInCheckOut.checkInTime && (
                                    <div className='mr-10'>
                                        <button
                                            onClick={() => {
                                                setCheckInCheckOutData({
                                                    rowId: availableJobCheckInCheckOut.id,
                                                    flag: availableJobCheckInCheckOut.breakInTime ? "break-out" : "break-in"
                                                })
                                                setShowCheckInCheckOutConfirmationModal(prevState => !prevState)
                                            }}
                                            className={`${availableJobCheckInCheckOut.breakInTime ? 'bg-violet-600 hover:bg-violet-800' : 'bg-violet-700 hover:bg-violet-800'} z-40 h-20 w-20 relative rounded-full border-none  p-2 text-sm font-normal text-white shadow-sm focus:outline-none focus:ring-0`}
                                        >
                                            {t(availableJobCheckInCheckOut.breakInTime ? 'EndBreak' : 'StartBreak')}
                                        </button>
                                    </div>
                                )
                            }
                            
                            <div>
                                <HoverCard width={500} shadow="md" withArrow closeDelay={false} position={`top`}>
                                    <HoverCard.Target>
                                        <div className='relative'>
                                            <button
                                                onClick={() => {
                                                    setCheckInCheckOutData({
                                                        rowId: availableJobCheckInCheckOut.id,
                                                        flag: availableJobCheckInCheckOut.checkInTime ? "check-out" : "check-in"
                                                    })
                                                    setShowCheckInCheckOutConfirmationModal(prevState => !prevState)
                                                }}
                                                className={`${availableJobCheckInCheckOut.checkInTime ? 'bg-indigo-400 hover:bg-indigo-800' : 'bg-indigo-800 hover:bg-indigo-400'} z-40 h-20 w-20 relative rounded-full border-none  p-2 text-sm font-normal text-white shadow-sm focus:outline-none focus:ring-0`}
                                            >
                                                {t(availableJobCheckInCheckOut.checkInTime ? 'CheckOut' : 'CheckIn')}
                                            </button>
                                            <span className="left-0 z-0 animate-ping absolute inline-flex h-full w-full rounded-full bg-indigo-800 opacity-40"/>
                                        </div>
                                    </HoverCard.Target>
                                    <HoverCard.Dropdown onClick={() => jobView(availableJobCheckInCheckOut.jobId)}>
                                        <h4 className="hover:text-indigo-500">
                                            <Anchor
                                                onClick={() => jobView(availableJobCheckInCheckOut.jobId)}
                                                href="#"
                                                color="text-indigo-500"
                                                size="lg"
                                                sx={{ lineHeight: 1 }}
                                            >
                                                {availableJobCheckInCheckOut.jobTitle}
                                            </Anchor>
                                        </h4>
                                        <Text size="sm" mt="md">
                                            {availableJobCheckInCheckOut.jobDescription}
                                        </Text>
                                        <Group mt="md" spacing="xl">
                                            <Text size="sm">
                                                <b>{availableJobCheckInCheckOut.reqDesignationName}</b> <br/>
                                                {t('EntryTime')}: <b>{availableJobCheckInCheckOut.entryTime ? format(new Date(availableJobCheckInCheckOut.entryTime.date), "MMMM dd, Y hh:mm a") : ''}</b>
                                            </Text>
                                        </Group>

                                    </HoverCard.Dropdown>
                                </HoverCard>
                            </div>
                        </div>
                )}


            </main>

            {showModal && <ViewJob setShowModal={setShowModal} jobDetails={jobDetails} roles={roles} jobView={jobView}/>}
            {showCheckInCheckOutConfirmationModal && <CheckInCheckOutConfirmationModal setShowModal={setShowCheckInCheckOutConfirmationModal} setRecallCheckInOut={setRecallCheckInOut} checkInCheckOutData={checkInCheckOutData}/>}
        </>
    )
}

export default Home