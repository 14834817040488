import React, {useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiEye, HiOutlineExclamation,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiPencil, HiTrash
} from "react-icons/hi";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Group, Text, Box, TextInput, ScrollArea, Select} from "@mantine/core";
import { FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import View from "./DesignationView";
import {createStyles} from "@mantine/styles";
import {openConfirmModal} from '@mantine/modals';
import {showNotification} from '@mantine/notifications';
import {getValue} from "@testing-library/user-event/dist/utils";



function Designation() {

    const {t, i18n} = useTranslation();
    const {roles, token} = JSON.parse(localStorage.getItem('user'));
    const [isAddForm, setAddForm] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const [listRefresh, setListRefresh] = useState(false);
    const formHeight = localStorage.getItem('formHeight');
    const formTableHeight = localStorage.getItem('formTableHeight');
    const addFormControl = (e) => {
        setEditForm(false);
        setAddForm(true);
    }

// FOR DATA TABLE DATA SHOW START
    const PAGE_SIZE = 10;
    const [page, setPage] = useState(localStorage.getItem('designation_page') ? localStorage.getItem('designation_page') : 1);

    const [designations, setDesignations] = useState([]);
    const [designationsGroup, setDesignationGroup] = useState([]);
    const [totalItem, setTotalItem] = useState(0);





    const getDesignationGroup = () => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/master-data`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                type_slug: 'designation-group',
            }
        })
            .then(res => {
                setDesignationGroup(res.data.data);
            })
            .catch(function (error) {
                console.log(error)
            })

    }

    useEffect(() => {
        getDesignationGroup();
    }, []);

    useEffect(() => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/master-data`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                type_slug: 'designation',
                per_page: PAGE_SIZE,
                page: page
            }
        })
            .then(res => {
                setDesignations(res.data.data);
                setTotalItem(res.data.totalData);
                localStorage.setItem('designation_page', page);
            })
            .catch(function (error) {
                console.log(error)

        })

    }, [page,spinner,listRefresh]);
// FOR DATA TABLE DATA SHOW END

//Form validation
    const validationSchema = Yup.object().shape({
        groupId: Yup.string().required(t("GroupIsRequired")),
        designation: Yup.string().required(t("designationIsRequired")),
        rate: Yup.string().required(t("RateIsRequired")),
    });

    const formOptions = {resolver: yupResolver(validationSchema)};

// get functions to build form with useForm() hook
    const {register, handleSubmit, reset, formState,setValue, getValues} = useForm(formOptions);
    const {errors} = formState;


//Form Data Submit
    function formSubmit(data) {

        setSpinner(true);
        const formData = {
            name: data.designation,
            rate: data.rate,
            masterDataTypeId: 1,
            parentGroupId: data.groupId
        };

        if (formData) {
            axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/agency/master-data`,
                formData,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                    if (res.data.status === 201) {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('SuccessfullyDataAdded')),
                            message: (t('DataLoad')),
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });

                        setSpinner(false);
                        reset();
                    } else if (res.data.status === 409){
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('Error')),
                            message: res.data.message,
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });
                    }else {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('Error')),
                            message: res.data.message,
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });
                    }
                });
        }
    }

    const [isEditForm, setEditForm] = useState(false);

    const DesignationEdit = (e, id) => {
        e.preventDefault();
        setAddForm(false);
        setEditForm(true);
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/agency/master-data/${id}`, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setValue('designation',res.data.data.designation)
                    setValue('groupId',res.data.data.groupId)
                    setValue('rate',res.data.data.rate)
                    setValue('id',id)
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: res.data.message,
                        autoClose: 3000,
                        disallowClose: true,
                        color: 'green',
                    });
                }
            });
    }

    function formSubmitUpdate(data) {
        setSpinner(true);

        axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/master-data/${data.id}`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {
                name: data.designation,
                rate: Number(data.rate),
                masterDataTypeId: 1
            }
        })
            .then(res => {
                if (res.data.status === 202) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('UpdatedSuccessfully')),
                        message: (t('DataLoad')),
                        autoClose: 500,
                        disallowClose: true,
                        color: 'green',
                    });
                    setSpinner(false);
                    reset();
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: res.data.message,
                        autoClose: 500,
                        disallowClose: true,
                        color: 'green',
                    });
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }


    const DesignationDelete = (e, id) => {
        e.preventDefault();
        openConfirmModal({
            title: (t('DeleteThisData')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureYouWantToDelete')}
                </Text>
            ),
            labels: {confirm: (t('Delete')), cancel: (t('NoDontDeleteIt'))},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () =>
                (
                    axios.delete(`${process.env.REACT_APP_API_GATEWAY_URL}/agency/master-data/${id}`, {headers: {"Authorization": `Bearer ${token}`}})
                        .then(res => {
                            if (res.status === 200) {
                                showNotification({
                                    id: 'load-data',
                                    loading: true,
                                    title: (t('DataIsDeleting')),
                                    message: (t('DataLoad')),
                                    autoClose: 500,
                                    disallowClose: true,
                                    color: 'red',
                                });
                                setListRefresh(true);
                            }
                        })
                )
            ,
        });
        setListRefresh(false);
    }
    const useStyles = createStyles((theme) => ({
        table: {
            border: `1px solid ${theme.colors.red[6]}`,
            borderRadius: 1,
        },
    }));

    const [viewModel, setViewModel] = useState(false);
    const [viewData, setViewData] = useState(null);
    const DesignationView = (e, id) => {
        e.preventDefault();
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/agency/master-data/${id}`, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    // console.log(res.data.data)
                    setViewData(res.data.data);
                    setViewModel(true);
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: res.data.message,
                        autoClose: 3000,
                        disallowClose: true,
                        color: 'green',
                    });
                }
            });
    }

    return (
        <>
            {viewModel ? <View data={viewData} setViewModel={setViewModel}/> : ''}

            <main className="w-full bg-blue-50" >
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-between text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-800 w-full'}>
                                                            {t('Designation')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('DesignationRelatedInformationCanBeFoundHere')}</sub></span>
                        </div>
                    </div>



                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <button
                                    onClick={event => addFormControl(event)}
                                    className="inline-flex items-center ml-6 rounded border-none bg-indigo-600 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiOutlinePlus size={12} className={'mr-1'}/>{t('AddDesignation')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className={'flex flex-1 overflow-hidden '}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex">
                                                            <div className="w-full">
                                                                <Box sx={{height: formTableHeight}}>

                                                                    <DataTable
                                                                        withBorder
                                                                        paginationSize="md"
                                                                        withColumnBorders
                                                                        striped
                                                                        styles={(theme) => ({
                                                                            root: {
                                                                                border: '1px solid #e0e7ff',
                                                                                borderRadius: 2,
                                                                            },
                                                                            header: {
                                                                                background: '#e0e7ff',
                                                                                backgroundColor: "#e0e7ff",
                                                                                color: theme.colors.indigo[6],
                                                                            },
                                                                            pagination: {
                                                                                color: theme.colors.gray[3],
                                                                                paddingTop : "5px",
                                                                                paddingBttom : "5px"
                                                                            },
                                                                            item: {
                                                                                '&[data-active]': {
                                                                                    backgroundImage: theme.fn.gradient({ from: 'red', to: 'yellow' }),
                                                                                },
                                                                            },
                                                                        })}
                                                                        records={designations}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: '#',
                                                                                textAlignment: 'right',
                                                                                render: (item) => (designations.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                            },
                                                                            {
                                                                                accessor: 'name',
                                                                                title: <Text mr="xs">{t('Name')}</Text>
                                                                            },
                                                                            {
                                                                                accessor: 'slug',
                                                                                title: <Text mr="xs">{t('Slug')}</Text>
                                                                            },
                                                                            {
                                                                                accessor: 'rate',
                                                                                title: <Text mr="xs">{t('Rate')}</Text>
                                                                            },
                                                                            {
                                                                                accessor: 'parentGroupName',
                                                                                title: <Text mr="xs">{t('Group')}</Text>
                                                                            },
                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text
                                                                                    mr="xs">{t('Action')}</Text>,
                                                                                textAlignment: 'right',
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="right"
                                                                                           noWrap>
                                                                                        <ActionIcon color="green"
                                                                                                    onClick={(e) => DesignationEdit(e, item.id)}>
                                                                                            <HiPencil size={16}/>
                                                                                        </ActionIcon>
                                                                                        <ActionIcon color="blue">
                                                                                            <HiEye size={16}
                                                                                                   onClick={(e) => DesignationView(e, item.id)}/>
                                                                                        </ActionIcon>
                                                                                        <ActionIcon color="red">
                                                                                            <HiTrash size={16}
                                                                                                     onClick={(e) => DesignationDelete(e, item.id)}/>
                                                                                        </ActionIcon>
                                                                                    </Group>
                                                                                ),
                                                                            },

                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => setPage(p)}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {isAddForm ?
                                                    <aside
                                                        className="sm:block  h-full  transition-all">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border rounded">
                                                                        <header className="relative bg-indigo-50">
                                                                            <div
                                                                                className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-center flex justify-between">
                                                                                    <div
                                                                                        className="text-indigo-800 text-sm font-bold items-center leading-7">  {t('CreateDesignation')}</div>
                                                                                    <button
                                                                                        onClick={() => setAddForm(false)}
                                                                                        className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                        type="button">
                                                                                        <FiX/>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>
                                                                        <form onSubmit={handleSubmit(formSubmit)} id="horizontal-form">
                                                                            <ScrollArea style={{ height: formHeight }}>
                                                                                <div className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>

                                                                                    {/*Designation Group Section*/}
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="label" className="form-input-sm-label-required">{t('Group')}</label>
                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.groupId?.type === 'required' &&
                                                                                                <div className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.groupId?.message}
                                                                                                </div>}
                                                                                            <Select
                                                                                                {...register("groupId")}
                                                                                                placeholder={t("EnterGroup")}
                                                                                                withAsterisk
                                                                                                onChange={(e) => setValue('groupId', e)}
                                                                                                data={designationsGroup.map((item) => {
                                                                                                    return {value: item.id, label: item.name}
                                                                                                })}
                                                                                                nothingFound={t('NoDataFound')}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    {/*Designation Section*/}
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="label" className="form-input-sm-label-required">{t('Name')}</label>
                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.designation?.type === 'required' &&
                                                                                                <div className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.designation?.message}
                                                                                                </div>}
                                                                                            <TextInput
                                                                                                {...register("designation")}
                                                                                                placeholder={t("EnterDesignation")}
                                                                                                withAsterisk
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    {/*Rate Section*/}
                                                                                    <div className="mb-3 w-full items-center">
                                                                                        <label htmlFor="label" className="form-input-sm-label-required">{t('Rate')}</label>
                                                                                        <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.rate?.type === 'required' &&
                                                                                                <div className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation size={16} className={'mr-2'}/> {errors.rate?.message}
                                                                                                </div>}
                                                                                            <TextInput
                                                                                                {...register("rate")}
                                                                                                placeholder={t("EnterRate")}
                                                                                                withAsterisk
                                                                                            />
                                                                                        </div>
                                                                                    </div>



                                                                                </div>
                                                                            </ScrollArea>
                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div
                                                                                        className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button
                                                                                            onClick={() => setAddForm(false)}
                                                                                            type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX size={16}
                                                                                              className={'mr-2'}/>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}/>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}/>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </footer>

                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}


                                                {isEditForm ?
                                                    <aside
                                                        className="sm:block  h-full  transition-all">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">
                                                                        <header className="relative mb-1 bg-indigo-600">
                                                                            <div
                                                                                className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-center flex justify-between">
                                                                                    <div
                                                                                        className="text-white   text-sm font-bold items-center leading-7">  {t('UpdateDesignation')}</div>
                                                                                    <button
                                                                                        onClick={() => setEditForm(false)}
                                                                                        className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                        type="button">
                                                                                        <FiX/>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>
                                                                        <form onSubmit={handleSubmit(formSubmitUpdate)} id="horizontal-form">
                                                                            <ScrollArea style={{ height: formHeight }}>
                                                                                <div
                                                                                    className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>

                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="label"
                                                                                               className="form-input-sm-label-required">{t('Group')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.groupId?.type === 'required' &&
                                                                                                <div
                                                                                                    className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation
                                                                                                        size={16}
                                                                                                        className={'mr-2'}/> {errors.groupId?.message}
                                                                                                </div>}
                                                                                            <Select
                                                                                                {...register("groupId")}
                                                                                                placeholder={t("EnterGroup")}
                                                                                                withAsterisk
                                                                                                onChange={(e) => setValue('groupId', e)}
                                                                                                data={designationsGroup.map((item) => {
                                                                                                    return {
                                                                                                        value: item.id,
                                                                                                        label: item.name
                                                                                                    }
                                                                                                })}
                                                                                                nothingFound={t('NoDataFound')}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    {/*Designation Section*/}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="label"
                                                                                               className="form-input-sm-label-required">{t('Name')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.designation?.type === 'required' &&
                                                                                                <div
                                                                                                    className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation
                                                                                                        size={16}
                                                                                                        className={'mr-2'}/> {errors.designation?.message}
                                                                                                </div>}
                                                                                            <TextInput
                                                                                                {...register("designation")}
                                                                                                placeholder={t("EnterDesignation")}
                                                                                                withAsterisk
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    {/*Rate Section*/}
                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="label"
                                                                                               className="form-input-sm-label-required">{t('Rate')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.rate?.type === 'required' &&
                                                                                                <div
                                                                                                    className="form-input-sm-error">
                                                                                                    <HiOutlineExclamation
                                                                                                        size={16}
                                                                                                        className={'mr-2'}/> {errors.rate?.message}
                                                                                                </div>}
                                                                                            <TextInput
                                                                                                {...register("rate")}
                                                                                                placeholder={t("EnterRate")}
                                                                                                withAsterisk
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </ScrollArea>
                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div
                                                                                        className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button
                                                                                            onClick={() => setEditForm(false)}
                                                                                            type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX size={16}
                                                                                              className={'mr-2'}/>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}/>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}/>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </footer>
                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Designation