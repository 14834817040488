import React, {useEffect} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    HiOutlineExclamation,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiPencil, HiTrash
} from "react-icons/hi";
import {DataTable} from "mantine-datatable";
import {ActionIcon, Group, Text, Box, Select, ScrollArea, TextInput, Switch} from "@mantine/core";
import {FiSave, FiX} from "react-icons/fi";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import View from "./CoreView";

import {openConfirmModal} from '@mantine/modals';
import {showNotification} from '@mantine/notifications';


function Core() {
    const {t, i18n} = useTranslation();
    const {roles, token} = JSON.parse(localStorage.getItem('user'));
    const [isAddForm, setAddForm] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const [listRefresh, setListRefresh] = useState(false);
    const [status, setStatus] = useState(true);


    const addFormControl = (e) => {
        setEditForm(false);
        setAddForm(true);
    }

// FOR DATA TABLE DATA SHOW START
    const PAGE_SIZE = 20;
    const [page, setPage] = useState(localStorage.getItem('core_master_data_page') ? localStorage.getItem('core_master_data_page') : 1);
    const formHeight = localStorage.getItem('formHeight');
    const formTableHeight = localStorage.getItem('formTableHeight');

    const [coreMasterData, setCoreMasterData] = useState([]);
    const [totalItem, setTotalItem] = useState(0);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/?per_page=${PAGE_SIZE}&page=${page}`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                setCoreMasterData(res.data.data);
                setTotalItem(res.data.total);
                localStorage.setItem('core_master_data_page', page);
            });
    }, [page, spinner, listRefresh]);
// FOR DATA TABLE DATA SHOW END


    // start get type dropdown
    const [typeData, setTypeData] = useState('');
    const [types, setType] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/types`,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setType(res.data.data);
                }
            });
    }, []);

    let typeDropdown =
        types.map((type, index) => {
            return ({
                'label': type.name, 'value': type.slug
            })
        })

    // on change type data get
    const TypeDataHandel = (e) => {
        setTypeData(e);
        setValue('master_data_slug', e)
    }
    // End get type dropdown

    const HandelStatus = (e) => {
        setStatus(e.currentTarget.checked)
        setValue('status', status)
    }

//Form validation
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t("NameIsRequired")),
        master_data_slug: Yup.string().required(t("Type is required"))
    });

// get functions to build form with useForm() hook
    const formOptions = {resolver: yupResolver(validationSchema)};
    const {register, handleSubmit, reset, formState, setValue} = useForm(formOptions);
    const {errors} = formState;

    function formSubmit(data) {
        setSpinner(true);
        const formData = {
            name: data.name,
            master_data_slug: data.master_data_slug,
            status: data.status
        };

        axios.post(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/`,
            formData,
            {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 201) {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('SuccessfullyDataAdded')),
                        message: (t('DataLoad')),
                        autoClose: 500,
                        disallowClose: true,
                        color: 'green',
                    });
                    setSpinner(false);
                    reset();
                    setTypeData(null);
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: res.data.message,
                        autoClose: 500,
                        disallowClose: true,
                        color: 'green',
                    });
                }
            });

    }


    const [isEditForm, setEditForm] = useState(false);

    const MasterDataEdit = (e, id) => {
        e.preventDefault();
        setAddForm(false);
        setEditForm(true);

        axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/${id}`, {headers: {"Authorization": `Bearer ${token}`}})
            .then(res => {
                if (res.data.status === 200) {
                    setTypeData(res.data.data[0].typeSlug)
                    setStatus(res.data.data[0].status)
                    setValue('name', res.data.data[0].name);
                    setValue('master_data_slug', res.data.data[0].typeSlug);
                    setValue('status', res.data.data[0].status);
                    setValue('id', id);
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: res.data.message,
                        autoClose: 3000,
                        disallowClose: true,
                        color: 'green',
                    });
                }
            });
    }

    function formSubmitUpdate(data) {
        setSpinner(true);
        const updateFormData = {
            name: data.name,
            master_data_slug: data.master_data_slug,
            status: data.status
        };
        if (updateFormData) {
            axios.put(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/${data.id}`,
                updateFormData,
                {headers: {"Authorization": `Bearer ${token}`}})
                .then(res => {
                    if (res.data.status === 202) {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('UpdatedSuccessfully')),
                            message: (t('DataLoad')),
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });
                        setSpinner(false);
                        reset();
                        setTypeData(null);
                    } else {
                        showNotification({
                            id: 'load-data',
                            loading: true,
                            title: (t('Error')),
                            message: res.data.message,
                            autoClose: 500,
                            disallowClose: true,
                            color: 'green',
                        });
                    }
                });
        }
    }


    const MasterDataDelete = (e, id) => {
        e.preventDefault();
        openConfirmModal({
            title: (t('DeleteThisData')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureYouWantToDelete')}
                </Text>
            ),
            labels: {confirm: (t('Delete')), cancel: (t('NoDontDeleteIt'))},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () =>
                (
                    axios.delete(`${process.env.REACT_APP_API_GATEWAY_URL}/master-data/${id}`, {headers: {"Authorization": `Bearer ${token}`}})
                        .then(res => {
                            if (res.status === 200) {
                                showNotification({
                                    id: 'load-data',
                                    loading: true,
                                    title: (t('DataIsDeleting')),
                                    message: (t('DataLoad')),
                                    autoClose: 500,
                                    disallowClose: true,
                                    color: 'red',
                                });
                                setListRefresh(true);
                            }
                        })
                )
            ,
        });
        setListRefresh(false);
    }

    const [viewModel, setViewModel] = useState(false);
    const [viewData, setViewData] = useState(null);


    return (
        <>
            {viewModel ? <View data={viewData} setViewModel={setViewModel}/> : ''}

            <main className="w-full bg-blue-50">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-between text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                                            {t('MasterData')}
                                <sub
                                    className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('MasterDataInformationCanBeFoundHere')}</sub></span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <button onClick={event => addFormControl(event)}
                                      className="inline-flex items-center ml-6 border rounded bg-indigo-600 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiOutlinePlus size={12} className={'mr-1'}/>{t('AddMasterData')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 bg-gray-50 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className={'flex flex-1 overflow-hidden '}>
                                                <div className="min-h-full w-full">
                                                    <div className="flex w-full md:w-auto">
                                                        <div className="flex min-w-0 flex-1 bg-white xl:flex">
                                                            <div className="w-full px-4">
                                                                <Box sx={{height: formTableHeight}}>
                                                                    <DataTable
                                                                        withBorder
                                                                        records={coreMasterData}
                                                                        columns={[
                                                                            {
                                                                                accessor: 'index',
                                                                                title: 'No.',
                                                                                textAlignment: 'center',
                                                                                width: 45,
                                                                                render: (item) => (coreMasterData.indexOf(item) + 1) + PAGE_SIZE * (page - 1)
                                                                            },
                                                                            {
                                                                                accessor: 'name',
                                                                                title: <Text
                                                                                    mr="xs">{t('Name')}</Text>
                                                                            },
                                                                            {
                                                                                accessor: 'typeName',
                                                                                title: <Text mr="xs">{t('Type')}</Text>
                                                                            },
                                                                            {
                                                                                accessor: 'status',
                                                                                title: <Text
                                                                                    mr="xs">{t('Status')}</Text>,
                                                                                render: status => status ? 'Enable' : 'Disable'

                                                                            },
                                                                            {
                                                                                accessor: 'actions',
                                                                                title: <Text
                                                                                    mr="xs">{t('Action')}</Text>,
                                                                                textAlignment: 'right',
                                                                                render: (item) => (
                                                                                    <Group spacing={4} position="right"
                                                                                           noWrap>
                                                                                        <ActionIcon color="green"
                                                                                                    onClick={(e) => MasterDataEdit(e, item.id)}>
                                                                                            <HiPencil size={16}/>
                                                                                        </ActionIcon>
                                                                                        {/*<ActionIcon color="blue">
                                                                                            <HiEye size={16}
                                                                                                   onClick={(e) => MasterDataView(e, item.id)}/>
                                                                                        </ActionIcon>*/}
                                                                                        <ActionIcon color="red">
                                                                                            <HiTrash size={16}
                                                                                                     onClick={(e) => MasterDataDelete(e, item.id)}/>
                                                                                        </ActionIcon>
                                                                                    </Group>
                                                                                ),
                                                                            },

                                                                        ]}
                                                                        totalRecords={totalItem}
                                                                        recordsPerPage={PAGE_SIZE}
                                                                        page={page}
                                                                        onPageChange={(p) => setPage(p)}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {isAddForm ?
                                                    <aside className="sm:block  h-full  transition-all">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">

                                                                        <header className="relative bg-indigo-50">
                                                                            <div
                                                                                className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-center flex justify-between">
                                                                                    <div
                                                                                        className="text-indigo-800 text-sm font-bold items-center leading-7">  {t('MasterData')}</div>
                                                                                    <button
                                                                                        onClick={() => setAddForm(false)}
                                                                                        className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                        type="button">
                                                                                        <FiX/>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>

                                                                        <form onSubmit={handleSubmit(formSubmit)}
                                                                              id="horizontal-form">
                                                                            <ScrollArea style={{height: formHeight}}>
                                                                                <div
                                                                                    className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>

                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label
                                                                                            htmlFor="master_data_slug"
                                                                                            className="form-input-sm-label-required">{t('MasterDataType')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.master_data_slug?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/>
                                                                                                {errors.master_data_slug?.message}
                                                                                            </div>}
                                                                                            <Select
                                                                                                {...register("master_data_slug")}
                                                                                                placeholder={t('ChooseMasterDataType')}
                                                                                                searchable clearable
                                                                                                allowDeselect
                                                                                                nothingFound="No options"
                                                                                                data={typeDropdown}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onChange={TypeDataHandel}
                                                                                                value={typeData}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="name"
                                                                                               className="form-input-sm-label-required">{t('Name')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.name?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation size={16} className={'mr-2'}/>
                                                                                                {errors.name?.message}
                                                                                            </div>}
                                                                                            <TextInput
                                                                                                {...register("name")}
                                                                                                placeholder={t("EnterName")}
                                                                                                withAsterisk
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            <Switch
                                                                                                {...register("status")}
                                                                                                onLabel="Active"
                                                                                                offLabel="Inactive"
                                                                                                size="md"
                                                                                                radius="xs"
                                                                                                label={t('Status')}
                                                                                                labelPosition="left"
                                                                                                checked={status}
                                                                                                onChange={HandelStatus}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </ScrollArea>
                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div
                                                                                        className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button
                                                                                            onClick={() => setAddForm(false)}
                                                                                            type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX size={16}
                                                                                              className={'mr-2'}/>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {
                                                                                                spinner ? ( <AiOutlineLoading3Quarters className={'mr-2 animate-spin'} size={16}/> ) : (<FiSave size={16} className={'mr-2'}/>)
                                                                                            }
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </footer>

                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}


                                                {isEditForm ?
                                                    <aside
                                                        className="sm:block  h-full  transition-all">
                                                        <div className="pr-4 sm:pr-6 lg:flex-shrink-0 lg:pr-8 xl:pr-0">
                                                            <div className="h-full ">
                                                                <section className="w-96 ml-3">
                                                                    <div className="w-full mr-0 mx-auto border">


                                                                        <header className="relative bg-indigo-50">
                                                                            <div
                                                                                className="text-indigo-300  text-right sm:px-2 sm:py-1">
                                                                                <div
                                                                                    className="text-center flex justify-between">
                                                                                    <div
                                                                                        className="text-indigo-800 text-sm font-bold items-center leading-7">  {t('UpdateMasterData')}</div>
                                                                                    <button
                                                                                        onClick={() => setEditForm(false)}
                                                                                        className="text-xs py-2 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                                                        type="button">
                                                                                        <FiX/>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </header>
                                                                        <form onSubmit={handleSubmit(formSubmitUpdate)}
                                                                              id="horizontal-form">
                                                                            <ScrollArea style={{height: formHeight}}>
                                                                                <div
                                                                                    className={'pt-3 px-3 sm:border-t sm:border-gray-200 '}>

                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label
                                                                                            htmlFor="master_data_slug"
                                                                                            className="form-input-sm-label-required">{t('MasterDataType')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.master_data_slug?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.master_data_slug?.message}
                                                                                            </div>}
                                                                                            <Select
                                                                                                {...register("master_data_slug")}
                                                                                                placeholder={t('ChooseMasterDataType')}
                                                                                                searchable clearable
                                                                                                allowDeselect
                                                                                                nothingFound="No options"
                                                                                                data={typeDropdown}
                                                                                                transition="pop-top-left"
                                                                                                transitionDuration={80}
                                                                                                transitionTimingFunction="ease"
                                                                                                onChange={TypeDataHandel}
                                                                                                value={typeData}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <label htmlFor="name"
                                                                                               className="form-input-sm-label-required">{t('Name')}</label>
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            {errors.name?.type === 'required' &&
                                                                                            <div
                                                                                                className="form-input-sm-error">
                                                                                                <HiOutlineExclamation
                                                                                                    size={16}
                                                                                                    className={'mr-2'}></HiOutlineExclamation> {errors.name?.message}
                                                                                            </div>}
                                                                                            <TextInput
                                                                                                {...register("name")}
                                                                                                placeholder={t("EnterName")}
                                                                                                withAsterisk
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className="mb-3 w-full items-center">
                                                                                        <div
                                                                                            className="relative mt-1 sm:col-span-2 sm:mt-0">
                                                                                            <Switch
                                                                                                {...register("status")}
                                                                                                onLabel="Active"
                                                                                                offLabel="Inactive"
                                                                                                size="md"
                                                                                                radius="xs"
                                                                                                label={t('Status')}
                                                                                                labelPosition="left"
                                                                                                checked={status}
                                                                                                onChange={HandelStatus}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </ScrollArea>
                                                                            <footer className="relative mt-1">
                                                                                <div className="container mx-auto">
                                                                                    <div
                                                                                        className="text-right border-t pt-0.5 mb-0.5">
                                                                                        <button
                                                                                            onClick={() => setEditForm(false)}
                                                                                            type='reset'
                                                                                            className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                        ><FiX size={16}
                                                                                              className={'mr-2'}/>
                                                                                            <span>{t('Cancel')}</span>
                                                                                        </button>
                                                                                        <button type='submit'
                                                                                                className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border  rounded text-sm">
                                                                                            {spinner ? (
                                                                                                <AiOutlineLoading3Quarters
                                                                                                    className={'mr-2 animate-spin'}
                                                                                                    size={16}/>
                                                                                            ) : (<FiSave size={16}
                                                                                                         className={'mr-2'}/>)}
                                                                                            <span>{t('Save')}</span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </footer>
                                                                        </form>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </aside> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Core