import React from "react";
import {useTranslation} from "react-i18next";
import {
    HiLocationMarker,
    HiCalendar,
    HiOutlineX,
    HiOutlineCheck,
} from "react-icons/hi";
import {Fragment} from 'react';
import {format} from 'date-fns';
import {useNavigate} from "react-router";
import toast from "react-hot-toast";
import {showNotification} from "@mantine/notifications";




export default function CandidatePreferableConfirmation({setShowCandidatePreferModal, updateJobDetails, setSelectedTabIndex}) {

    const notify = () => toast.custom((t) => (
        <div
            className={`${
                t.visible ? 'animate-enter' : 'animate-leave'
            } max-w-md w-full bg-white shadow-lg rounded pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
            <div className="flex-1 w-0 p-2">
                <div className="flex items-start">
                    <div className="flex-shrink-0 pt-0.5">
                        <HiOutlineCheck className="rounded-full text-white bg-indigo-500 mr-2 h-5 w-5" aria-hidden="true"/>
                    </div>
                    <div className="ml-3 flex-1">
                        <p className="text-sm font-medium text-gray-900">
                            Job is waiting for approval. When it will be approved you will receive an email notification
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex border-l border-gray-200">
                <button
                    onClick={() => toast.dismiss(t.id)}
                    className="w-full border border-transparent rounded-none rounded-r p-2 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none"
                >
                    <HiOutlineX className="mr-2 h-5 w-5" aria-hidden="true" />

                </button>
            </div>
        </div>
    ))





/*    const notify = () => toast.success('Job is waiting for approval. When it will be approved you will receive an email notification',{
        "position": 'top-center',
        "duration": 5000,
    });*/
    const {t, i18n} = useTranslation();
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const modalHeight = localStorage.getItem('modalHeight');

    const navigate = useNavigate();
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-1/3">
                    {/*content*/}
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div className="modal-body  p-4 pt-0">
                                <div className="py-10 text-left">
                                    <div className="flex flex-wrap">
                                        <div className="w-full px-4">
                                            <h2 className="text-slate-800  text-center">{t('DoYouHaveAnyPreferCandidate')}?</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                <button
                                    onClick={() => {
                                        setShowCandidatePreferModal(false)
                                        // notify()
                                        // updateJobDetails("process", "published")
                                        navigate('/job-post');
                                        showNotification({
                                            id: 'load-data',
                                            loading: true,
                                            title: (t('Thanks') + ' !'),
                                            message: (t('JobIsWaitingForApproval.WhenItWillBeApprovedYouWillReceiveAnEmailNotification')),
                                            autoClose: 2000,
                                            disallowClose: true,
                                            color: 'green',
                                        });

                                    }}
                                    className="bg-gray-500 hover:bg-grey-600 rounded inline-flex items-cente focus:outline-none focus:ring-1 mt-1 focus:ring-offset-1  focus:ring-gray-400 ml-3  transition duration-150 text-white ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-4 py-2 text-sm">
                                    <HiOutlineX className="mr-2 h-5 w-5" aria-hidden="true" /><span>{t('No')}</span>
                                </button>
                                <button
                                    onClick={() => {
                                        setShowCandidatePreferModal(false)
                                        // updateJobDetails("process", "published")
                                        setSelectedTabIndex(2)
                                    }}
                                    className="bg-indigo-500 hover:bg-indigo-600 rounded inline-flex items-cente focus:outline-none focus:ring-1 mt-1 focus:ring-offset-1  focus:ring-gray-400 ml-3  transition duration-150 text-white ease-in-out  border rounded px-4 py-2 text-sm">
                                    <HiOutlineCheck className="mr-2 h-5 w-5" aria-hidden="true"/><span>{t('Yes')}</span>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"></div>
        </>
    );
}
