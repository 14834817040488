import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format, lastDayOfMonth } from 'date-fns';
import { DataTable } from "mantine-datatable";
import { Box, Group, Text, Select, ScrollArea, TextInput, Grid, Drawer, useMantineTheme, NumberInput, Switch } from "@mantine/core";
import axios from "axios";
import {
    HiOutlineClipboardList,
    HiOutlineCalendar
} from "react-icons/hi";



const PER_PAGE = 20;
function CompleteListView({setView}) {
    const { t, i18n } = useTranslation();
    const { token, roles, loggedUserId, domainId } = JSON.parse(localStorage.getItem('user'));

    const theme = useMantineTheme();


    const fullTableHeight = localStorage.getItem('fullTableHeight');
    const [page, setPage] = useState(1);

    const [data, setData] = useState([]);
    const [jobId, setJobId] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [sortStatus, setSortStatus] = useState({ columnAccessor: 'jobTitle', direction: 'asc' });
    const [showDecimalFormat, setShowDecimalFormat] = useState(true);







    const [jobList, setJobList] = useState([]);
    const getJobList = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-list-for-time-sheet`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                loggedUserId,
                roles,
                flag: 'complete',
            }
        })
            .then(res => {
                setJobList(res.data.data);
            })
            .catch(function (err) {
                console.log(err);

            });
    }

    const timeSheetparams = {
        roles,
        loggedUserId,
        jobId,
        per_page: PER_PAGE,
        page: page,
        flag: 'complete'
    }

    function jobFilterSubmit(id) {
        setJobId(id)
        timeSheetparams['jobId'] = id;

        // console.log(timeSheetparams);
        getTimeSheet(timeSheetparams);
    }

    const getTimeSheet = (params) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-time-sheet`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: params
        })
            .then(res => {
                setData(res.data);
                setFetching(false);
            })
            .catch(function (err) {
                console.log(err);

            });
    }


















    useEffect(() => {
        getJobList();
        getTimeSheet(timeSheetparams);
    }, []);


    useEffect(() => {
        getTimeSheet(timeSheetparams);
    }, [page]);

    useEffect(() => {
        setFetching(true);
    }, [sortStatus]);

    return (
        <Fragment>
            <div className="my-2 p-1 bg-gray-400 rounded">
                <Grid align="center">
                    <Grid.Col span={10}>
                        <Select
                            placeholder={t('SelectJob')}
                            data={jobList.map((job, index) => ({ value: job['id'], label: job['title'] }))}
                            onChange={(e) => jobFilterSubmit(e)}
                            value={jobId}
                            nothingFound={t('NoJobs')}
                            maxDropdownHeight={280}
                            searchable
                        />
                    </Grid.Col>
                    <Grid.Col span="auto" className='text-right flex justify-between'>
                        <button
                            onClick={(e) => {
                                setView('week')
                            }}
                            className={`w-full disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center rounded border-none bg-gray-600 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-0`}
                        >
                            <HiOutlineCalendar size={12} className={'mr-1'} />{t('Week')}
                        </button>
                        <button
                            onClick={(e) => {
                                setView('list')
                            }}
                            className={`w-full ml-1 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center rounded border-none bg-green-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-0`}
                        >
                            <HiOutlineClipboardList size={12} className={'mr-1'} />{t('List')}
                        </button>
                        
                    </Grid.Col>
                    
                </Grid>
            </div>

            <Box sx={{ height: fullTableHeight }}>
                <DataTable
                    noRecordsText={t('NoRecords') + ' !'}
                    withBorder
                    records={data.data}
                    striped
                    styles={(theme) => ({
                        root: {
                            border: '1px solid #e0e7ff',
                            borderRadius: 2,
                        },
                        header: {
                            background: '#e0e7ff',
                            backgroundColor: "#e0e7ff",
                            color: theme.colors.indigo[6],
                        },
                        pagination: {
                            color: theme.colors.gray[3],
                            paddingTop: "5px",
                            paddingBottom: "5px"
                        },
                        item: {
                            '&[data-active]': {
                                backgroundImage: theme.fn.gradient({ from: 'red', to: 'yellow' }),
                            },
                        },
                    })}
                    columns={[
                        {
                            accessor: '',
                            title: (t('No.')),
                            width: 45,
                            render: (item) => (data.data.indexOf(item) + 1) + PER_PAGE * (page - 1)
                        },
                        {
                            accessor: 'jobTitle',
                            title: (t('JobTitle')),
                            // render: ({jobTitle}) => <Link to={`/job-post`} className={`text-red`}>{jobTitle}</Link>,

                        },
                        {
                            accessor: 'name',
                            title: (t('Name')),
                            // sortable: true
                            // render: ({jobTitle}) => <Link to={`/job-post`} className={`text-red`}>{jobTitle}</Link>,

                        },
                        {
                            accessor: 'designationName',
                            title: (t('Designation')),
                            // sortable: true
                        },
                        {
                            accessor: 'workingDate',
                            title: (t('Date')),
                            // render: ({ workingDate }) => format(new Date(workingDate.date), 'MM/dd/yyyy'),
                        },
                        {
                            accessor: 'checkInTime',
                            title: (t('InTime')),
                            width: 100,
                        },
                        {
                            accessor: 'checkOutTime',
                            title: (t('OutTime')),
                            width: 100,

                        },
                        {
                            accessor: 'breakDuration',
                            title: (t('BreakDuration')),
                            width: 120,
                            render: (timeSheet) => timeSheet['breakDuration'] >= 0 && timeSheet['breakDuration'] + ' min',
                        },
                        {
                            accessor: 'totalHour',
                            // title: (t('Hour') ),
                            title: <Switch radius={`sm`} w={85} onChange={() => setShowDecimalFormat(!showDecimalFormat)} size="lg" color={`indigo.2`} checked={showDecimalFormat} onLabel={<Text fz={12} fw={600}>{t('Decimal')}</Text>} offLabel={<Text fz={12} fw={600}>{t('Hour')}</Text>} />,
                            // width: 100,
                            
                            render: ({totalHour, totalHourDecimalFormat}) => showDecimalFormat ? totalHourDecimalFormat : totalHour
                        },

                    ]}
                    totalRecords={data.totalRecords}
                    recordsPerPage={PER_PAGE}
                    page={page}
                    onPageChange={(p) => {
                        setPage(p)
                        setFetching(true)
                    }}
                    fetching={fetching}
                    loaderSize="sm"
                    loaderColor="indigo"
                    loaderBackgroundBlur={2}
                // sortStatus={sortStatus}
                // onSortStatusChange={setSortStatus}
                />
            </Box>
        </Fragment>
    );

}
export default CompleteListView