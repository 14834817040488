import React from "react";
import { HiOutlineExclamation } from "react-icons/hi";
const Input = ({
    labelText,
    labelFor,
    errors,
    icon,
    name,
    placeholder,
    registerForm,
    type,
}) => {
    return (
        <div className="mb-3 w-full items-center">
            <label htmlFor={labelFor} className="form-input-sm-label-required">
                {labelText}
            </label>

            <div className="relative mt-1 sm:col-span-2 sm:mt-0">
                {errors.name?.type === "required" && (
                    <div className="form-input-sm-error">
                        <HiOutlineExclamation
                            size={16}
                            className={"mr-2"}
                        ></HiOutlineExclamation>{" "}
                        {errors.name?.message}
                    </div>
                )}
                <div className="form-input-sm-right-icon">{icon}</div>
                <input
                    {...registerForm}
                    type={type}
                    className={`form-input-sm-control ${
                        errors.name
                            ? "border-red-300 focus:border-red-600"
                            : "border-gray-300 focus:border-blue-600"
                    } `}
                    name={name}
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
};

export default Input;
