import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
    HiOutlineCheck,
    HiCheckCircle,
    HiOutlineX,
    HiOutlineDownload, HiOutlineCurrencyDollar
} from "react-icons/hi";
import {format} from 'date-fns';
import {LoadingOverlay, Grid, Text, Group, ScrollArea, Switch, TextInput} from "@mantine/core";
import { Tab } from '@headlessui/react';
import { FiX } from "react-icons/fi";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
export default function PreviewPublishDesignationList({jobDetails, designation, rejectAssignedCandidate, setCandidateId, setShowCandidateProfile, assignedCandidateAcceptance, setRecall}){
    const { t, i18n } = useTranslation();
    const {token, loggedUserId, roles} = JSON.parse(localStorage.getItem('user'));

    const [showTimeMatrixLoader, setShowTimeMatrixLoader] = useState(true);
    const [timeMatrix, setTimeMatrix] = useState({});
    // const [showCandidateProfile, setShowCandidateProfile] = useState(false);
    // const [candidateId, setCandidateId] = useState(null);


    const getDesignationTimeMatrix = (id) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/required-designation-time-matrix-for-job-view`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {id}
        })
            .then(res => {
                setTimeMatrix(res.data.data);
                setShowTimeMatrixLoader(false);

            })
            .catch(function (err) {
                console.log(err);

            });
    }

    const downloadAppliedCandidatesPdf = (data) => {
        let dataArray = [];
        data.map((candidate, index) => dataArray.push([index + 1, candidate.name, candidate.email, candidate.phoneNumber, 'Applied']))
        const doc = new jsPDF();
        doc.setFontSize(11)
        // doc.text('With content', 14, 22)
        doc.setTextColor(80);

        const headers = ['SN', 'Candidate Name', 'Email', 'Phone', 'Status'];

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        let pageSize = doc.internal.pageSize
        let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
        let text = doc.splitTextToSize(jobDetails['title'], pageWidth - 35, {})
        // doc.text(text, 14, 100)

        autoTable(doc, {
            head: [headers],
            body: dataArray,
            theme: 'grid',
            headStyles: {
                fillColor: '#BAC8FF',
                textColor: '#101113'
            },
        });

        doc.save(`${jobDetails['id'] + '-' + designation['designationSlug'] + '-' + 'applied-candidates.pdf'}`);
    }





    useEffect(()=>{
        getDesignationTimeMatrix(designation['id']);
        
    }, []);




    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })
    const hasRoleCandidate = roles.find(role => {
        return role === 'ROLE_CANDIDATE';
    })

    const [rateChangeLoader, setRateChangeLoader] = useState(false)
    const updateCandidateRate = ({rate, rowId}) => {
        // job-assign-candidate-update
        setRateChangeLoader(true)

        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-assign-candidate-update`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {rowId, rate}
        })
            .then(res => {
                if(202 == res.data.status){
                    setRateChangeLoader(false)
                    setRecall(prevState => !prevState)
                }

            })
            .catch(function (err) {
                console.log(err);

            });
    }

    return (
        <div className="w-full p-2 text-gray-900 bg-gray-100 rounded my-2 shadow-md border border-2 border-gray-300">
            <Grid className="w-full">
                <Grid.Col span='auto'>
                    <ScrollArea scrollbarSize={3} h={272}>
                        <Grid className="mb-1 w-full">
                            <Grid.Col span={4}>
                                <div className='flex flex-start w-full'>
                                    <Text size="sm" fw={700} className='w-1/2'>{t('Position')}</Text>
                                    <Text size="sm" weight={400} className='w-1/2'>: {designation.designationName}</Text>
                                </div>
                                <div className='flex flex-start w-full'>
                                    <Text size="sm" fw={700} className='w-1/2'>{t('StartDate')}</Text>
                                    <Text size="sm" weight={400} className='w-1/2'>: {designation['startTime'] ? format(new Date(designation['startTime'].date), 'MM/dd/Y') : ''}</Text>
                                </div>
                                <div className='flex flex-start w-full'>
                                    <Text size="sm" fw={700} className='w-1/2'>{t('EndDate')}</Text>
                                    <Text size="sm" weight={400} className='w-1/2'>: {designation['endTime'] ? format(new Date(designation['endTime'].date), 'MM/dd/Y') : ''}</Text>
                                </div>
                            </Grid.Col>
                            <Grid.Col span={4}>
                                <div className='flex flex-start w-full'>
                                    <Text size="sm" fw={700} className='w-1/2'>{t('Rate')}</Text>
                                    <Text size="sm" weight={400} className='w-1/2'>: {designation.rate ? '$'+designation.rate : ''}</Text>
                                </div>
                                <div className='flex flex-start w-full'>
                                    <Text size="sm" fw={700} className='w-1/2'>{t('StartTime')}</Text>
                                    <Text size="sm" weight={400} className='w-1/2'>: {designation['inTime'] ? designation['inTime'] : ''}</Text>
                                </div>
                                <div className='flex flex-start w-full'>
                                    <Text size="sm" fw={700} className='w-1/2'>{t('EndTime')}</Text>
                                    <Text size="sm" weight={400} className='w-1/2'>: {designation['outTime'] ? designation['outTime'] : ''}</Text>
                                </div>
                            </Grid.Col>
                            <Grid.Col span={4}>
                                <div className='flex flex-start w-full'>
                                    <Text size="sm" fw={700} className='w-1/2'>{t('Requested')}</Text>
                                    <Text size="sm" weight={400} className='w-1/2'>: {designation.noOfPerson ? designation.noOfPerson : 0}</Text>
                                </div>
                                <div className='flex flex-start w-full'>
                                    <Text size="sm" fw={700} className='w-1/2'>{t('Selected')}</Text>
                                    <Text size="sm" weight={400} className='w-1/2'>: { designation?.finalCandidates ? designation?.finalCandidates?.length : 0 }</Text>
                                </div>
                                <div className='flex flex-start w-full'>
                                    <Text size="sm" fw={700} className='w-1/2'>{t('Open')}</Text>
                                    <Text size="sm" weight={400} className='w-1/2'>: { designation.candidates?.preferred ? designation.candidates?.preferred?.length : 0 }</Text>
                                </div>
                            </Grid.Col>
                        </Grid>
                        <Grid className="mb-1 w-full">
                            <Grid.Col span="auto">
                                <Text size="sm" fw={700} >{t('Instruction')}:</Text>
                                <ScrollArea scrollbarSize={4} h={70} className="border border-1 border-gray-400 p-1 rounded">
                                    <Text size="sm" weight={400} >{designation['instruction']}</Text>
                                </ScrollArea>
                                
                            </Grid.Col>
                        </Grid>
                        <Grid gutter="xs" className="w-full relative">
                            <LoadingOverlay visible={showTimeMatrixLoader} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'xs' }} overlayColor="bg-indigo-100" h={100}/>

                            {
                                Object.keys(timeMatrix).map((time, pIndex)=>(
                                    <Grid.Col span={6} key={time + pIndex}>
                                        <Group className='h-full mb-1 bg-gray-200 shadow p-1 px-3 py-2'>
                                            <Text fz="sm" fw={500}>{t('InTimeOutTime')}: {time}</Text>
                                            <ul role="list" className="flex flex-wrap w-full text-xs text-gray-600">
                                            {
                                                timeMatrix[time].map((item, cIndex)=>(
                                                    <li key={cIndex} className="flex gap-x-1.2 w-1/3">
                                                        <HiCheckCircle size={18} className="h-4 w-4 flex-none text-indigo-800"/>{format(new Date(item['date'].date), 'MM/dd/Y')}
                                                    </li>
                                                    ))
                                            }
                                            </ul>
                                        </Group>
                                    </Grid.Col>
                                ))
                            }
                        </Grid>
                    </ScrollArea>
                </Grid.Col>
                <Grid.Col span='auto'>
                    <div className="bg-gray-50 w-full">
                        <Tab.Group defaultIndex={2}>
                            <Tab.List>
                                <Tab as={Fragment}>{({ selected }) => <button className={selected ? 'border-b-2 mb-4 mt-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-500 text-indigo-700' : 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'}>{t('Invite')}</button>}</Tab>
                                <Tab as={Fragment}>{({ selected }) => <button className={selected ? 'border-b-2 mb-4 mt-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-500 text-indigo-700' : 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'}>{t('Applied')}</button>}</Tab>
                                <Tab as={Fragment}>{({ selected }) => <button className={selected ? 'border-b-2 mb-4 mt-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-indigo-500 text-indigo-700' : 'border-b-2  py-1.5 p-2.5 pl-4 pr-4 text-sm font-medium leading-5 border-b-gray-200 text-indigo-400'}>{t('FinalCandidates')}</button>}</Tab>
                                
                            </Tab.List>
                            <Tab.Panels>
                                {
                                    !designation['candidates'] && (
                                        <Fragment>
                                            <Tab.Panel>
                                                <ScrollArea scrollbarSize={4} h={210}>
                                                    <table className="min-w-full divide-y divide-gray-300 text-xs">
                                                        <thead className="text-left bg-indigo-200">
                                                            <tr>
                                                                <th scope="col" className="pl-1 py-1">{t('Name')}</th>
                                                                <th scope="col" className="pl-1 py-1">{t('Phone')}</th>

                                                                <th scope="col" className="pl-1 py-1 text-center">{t('Status')}</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                            <tr>
                                                                <td colSpan={3} className="text-red-700 bg-red-100 text-center py-1">
                                                                    {t('NoAvailableCandidates')}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </ScrollArea>
                                                
                                            </Tab.Panel>
                                            <Tab.Panel>
                                                <ScrollArea scrollbarSize={4} h={210}>
                                                    <table className="min-w-full divide-y divide-gray-300 text-xs">
                                                        <thead className="text-left bg-indigo-200">
                                                            <tr>
                                                                <th scope="col" className="pl-1 py-1">{t('Name')}</th>
                                                                <th scope="col" className="pl-1 py-1">{t('Phone')}</th>
                                                                <th scope="col" className="pl-1 py-1 text-center">{t('Status')}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                            <tr>
                                                                <td colSpan={3} className="text-red-700 bg-red-100 text-center py-2">
                                                                    {t('NoAvailableCandidates')}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </ScrollArea>
                                                
                                            </Tab.Panel>
                                        </Fragment>
                                        
                                    )
                                }

                                {
                                    designation['candidates'] && (
                                        <Fragment>
                                            <Tab.Panel>
                                                <ScrollArea scrollbarSize={4} h={210}>
                                                    <table className="min-w-full divide-y divide-gray-300 text-xs">
                                                        <thead className="text-left bg-indigo-200">
                                                            <tr>
                                                                <th scope="col" className="pl-1 py-1">{t('Name')}</th>
                                                                <th scope="col" className="py-1">{t('Phone')}</th>
                                                                <th scope="col" className="text-center py-1">{t('Status')}</th>
                                                                <th scope="col" className="py-1"></th>
                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                            {
                                                                designation['candidates']['preferred'] ? designation['candidates']['preferred'].map((candidate, index) => (
                                                                    <tr key={candidate.id}>
                                                                        {/* <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm sm:pl-6 w-2/4"> */}
                                                                        <td>
                                                                            <div className="flex items-center" >
                                                                                <div className="h-7 w-7 flex-shrink-0">
                                                                                    <img className="h-7 w-7 rounded-full"
                                                                                        src={candidate['photo'] ? `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${candidate['photo']}` : "/assets/images/avatar.svg"}
                                                                                        // src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                                                        alt="" />
                                                                                </div>
                                                                                <div className="ml-4 hover:text-indigo-900">
                                                                                    <div className="font-medium text-gray-900">
                                                                                        {candidate.name}
                                                                                        { hasRoleModerator && (
                                                                                            <span 
                                                                                                onClick={(e) => {
                                                                                                    setCandidateId(candidate['userId'])
                                                                                                    setShowCandidateProfile(true)
                                                                                                }}
                                                                                                className="cursor-pointer ml-2 inline-flex items-center rounded bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800"
                                                                                            >
                                                                                                {t('Details')}
                                                                                            </span>
                                                                                        ) }
                                                                                    </div>
                                                                                    <div className="text-gray-500">{candidate.email}</div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        {/* <td className="whitespace-nowrap py-1 text-sm text-right text-gray-500 w-1/4"> */}
                                                                        <td>
                                                                            <div className="text-gray-900">{candidate.phoneNumber}</div>
                                                                        </td>

                                                                        {/* <td className="whitespace-nowrap px-3 py-1 text-sm text-center text-gray-500 w-1/4"> */}
                                                                        <td>
                                                                            <div className="text-gray-900">
                                                                                {/* {candidate['candidateConfirmationBy'] ? 'Yes' : 'No'} */}
                                                                                { candidate['applyStatus'] === 'preferred' && t('NotYetResponded') }
                                                                                { candidate['applyStatus'] === 'accepted' && t('InvitationAccepted') }
                                                                                { candidate['applyStatus'] === 'declined' && t('InvitationDeclined') }
                                                                            </div>
                                                                        </td>
                                                                        {/* <td className="whitespace-nowrap px-3 py-1 text-sm text-center text-gray-500 w-1/4"> */}
                                                                        <td className="text-right">
                                                                            {
                                                                                'in-progress' === jobDetails['status'] && (
                                                                                    <button
                                                                                        type='button'
                                                                                        className="inline-flex p-1 items-center rounded bg-red-700 border-none text-xs text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-0"
                                                                                        onClick={() => rejectAssignedCandidate(candidate, designation)}
                                                                                    >
                                                                                        <FiX size={12}/>{t('Remove')}
                                                                                    </button>
                                                                                )
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )) : (
                                                                    <tr>
                                                                        <td colSpan={4} className="text-red-700 bg-red-100 text-center py-2">
                                                                            {t('NoAvailableCandidates')}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }


                                                        </tbody>
                                                    </table>
                                                </ScrollArea>
                                                
                                            </Tab.Panel>
                                            <Tab.Panel>
                                                <ScrollArea scrollbarSize={4} h={210}>
                                                    {
                                                        designation['candidates']['self'] && (
                                                            <button
                                                                className="float-right disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center rounded bg-indigo-800 border-none py-2 px-3 text-xs font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                                                type='button'
                                                                onClick={() => downloadAppliedCandidatesPdf(designation['candidates']['self'])}
                                                            >
                                                                <HiOutlineDownload className={'mr-1'} />{t('PDF')}

                                                            </button>
                                                        )
                                                            
                                                    }
                                                    
                                                    <table className="min-w-full divide-y divide-gray-300 text-xs" id="applied-list">
                                                        <thead className="text-left bg-indigo-200">
                                                            <tr>
                                                                <th scope="col" className="pl-1 py-1">{t('Name')}</th>
                                                                <th scope="col" className="pl-1 py-1">{t('Phone')}</th>
                                                                <th scope="col" className="text-center py-1">{t('Status')}</th>
                                                                { hasRoleModerator && <th scope="col" className="pl-1 py-1 text-center">{t('FinalApproval')}</th> }
                                                            </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                            {
                                                                designation['candidates']['self'] ? designation['candidates']['self'].map((candidate, index) => (
                                                                    <tr key={candidate.id}>
                                                                        <td className="w-2/4 whitespace-nowrap py-1 pl-4 pr-3 text-sm sm:pl-6">
                                                                            <div className="flex items-center">
                                                                                <div className="h-7 w-7 flex-shrink-0">
                                                                                    <img className="h-7 w-7 rounded-full"
                                                                                        src={candidate['photo'] ? `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${candidate['photo']}` : "/assets/images/avatar.svg"}
                                                                                        // src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                                                        alt="" />
                                                                                </div>
                                                                                <div className="ml-4 hover:text-indigo-900">
                                                                                    <div className="font-medium text-gray-900">
                                                                                        {candidate.name}
                                                                                        { hasRoleModerator && (
                                                                                            <span 
                                                                                                onClick={(e) => {
                                                                                                    setCandidateId(candidate['userId'])
                                                                                                    setShowCandidateProfile(true)
                                                                                                }}
                                                                                                className="cursor-pointer ml-2 inline-flex items-center rounded bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800"
                                                                                            >
                                                                                                {t('Details')}
                                                                                            </span>
                                                                                        ) }
                                                                                    </div>
                                                                                    <div className="text-gray-500">{candidate.email}</div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="w-1/4 whitespace-nowrap py-1 text-sm text-right text-gray-500">
                                                                            <div className="text-gray-900">{candidate.phoneNumber}</div>
                                                                        </td>

                                                                        <td className="w-1/4 whitespace-nowrap px-3 py-1 text-sm text-center text-gray-500">
                                                                            <div className="text-gray-900">{t('Applied')}</div>
                                                                        </td>
                                                                        {
                                                                                hasRoleModerator && (
                                                                                    <td className="relative whitespace-nowrap p-2 text-right text-sm font-medium w-12">
                                                                                        <Switch
                                                                                            size="sm"
                                                                                            // disabled={'published' !== jobDetails['status']}
                                                                                            checked={!!candidate['finalApprove']}
                                                                                            color={'bg-indigo-800'}
                                                                                            onLabel={<HiOutlineCheck className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                            offLabel={<HiOutlineX className="mr-2 h-4 w-4" aria-hidden="true" />}
                                                                                            onChange={(e) => assignedCandidateAcceptance(e.target.checked, loggedUserId, candidate, 'final-approve')}
                                                                                        />
                                                                                    </td>
                                                                                )
                                                                            }
                                                                    </tr>
                                                                )) : (
                                                                    <tr><td colSpan={4} className="text-red-700 bg-red-100 text-center py-2">{t('NoAvailableCandidates')}</td></tr>
                                                                )
                                                            }


                                                        </tbody>
                                                    </table>
                                                </ScrollArea>
                                                
                                            </Tab.Panel>
                                        </Fragment>
                                    )
                                }

                                    <Tab.Panel>
                                        <ScrollArea scrollbarSize={4} h={210}>
                                            <table className="min-w-full divide-y divide-gray-300 text-xs">
                                                <thead className="text-left bg-indigo-200">
                                                    <tr>
                                                        <th scope="col" className="pl-1 py-1">{t('Name')}</th>
                                                        {
                                                            hasRoleModerator && <th scope="col" className="pl-1 py-1">{t('Rate')}</th>
                                                        }
                                                        <th scope="col" className="pl-1 py-1">{t('Phone')}</th>
                                                        <th scope="col" className="pl-1 py-1 text-center">{t('Status')}</th>
                                                        {
                                                            hasRoleModerator && <th scope="col" className="pl-1 py-1 text-center">{t('Action')}</th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">

                                                    {
                                                        designation['finalCandidates'].length > 0 ? designation['finalCandidates'].map((candidate, index) => (
                                                            candidate['process'] !== 'additional' && (
                                                                <tr key={candidate.id}>
                                                                    <td>
                                                                        <div className="flex items-center">
                                                                            <div className="h-7 w-7 flex-shrink-0">
                                                                                <img className="h-7 w-7 rounded-full"
                                                                                    // src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                                                    src={candidate['photo'] ? `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${candidate['photo']}` : "/assets/images/avatar.svg"}
                                                                                    alt="" />
                                                                            </div>
                                                                            <div className="ml-4">
                                                                                <div className="font-medium text-gray-900">
                                                                                    {candidate.name}
                                                                                    { hasRoleModerator && (
                                                                                        <span 
                                                                                            onClick={(e) => {
                                                                                                setCandidateId(candidate['userId'])
                                                                                                setShowCandidateProfile(true)
                                                                                            }}
                                                                                            className="cursor-pointer ml-2 inline-flex items-center rounded bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800"
                                                                                        >
                                                                                            {t('Details')}
                                                                                        </span>
                                                                                    ) }
                                                                                </div>
                                                                                <div className="text-gray-500">{candidate.email}</div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        hasRoleModerator &&
                                                                        <td>
                                                                            <div className="text-gray-900 relative">
                                                                                <LoadingOverlay visible={rateChangeLoader} loaderProps={{ size: 'xs', color: 'indigo' }} />
                                                                                <TextInput
                                                                                    disabled={'published' !== jobDetails['status']}
                                                                                    w={80}
                                                                                    defaultValue={candidate.rate}
                                                                                    onBlur={(e) => e && updateCandidateRate({
                                                                                        rate: e.target.value,
                                                                                        rowId: candidate.id
                                                                                    })}

                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    }

                                                                    <td>
                                                                        <div
                                                                            className="text-gray-900">{candidate.phoneNumber}</div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="text-gray-900">
                                                                            {candidate.process === 'preferred' ? t('InvitationAccepted') : ''}
                                                                            {candidate.process === 'self' ? t('Selected') : ''}
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        hasRoleModerator && (
                                                                            <td className="text-right">
                                                                                <button
                                                                                    className="disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:hover:bg-gray-50 inline-flex items-center rounded bg-red-700 border-none py-1 px-2 text-xs font-normal text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-0"
                                                                                    onClick={() => rejectAssignedCandidate(candidate, designation)}
                                                                                    disabled={'archived' === jobDetails['status'] || 'complete' === jobDetails['status'] }
                                                                                >
                                                                                    <FiX size={12}/>{t('Remove')} 
                                                                                </button>
                                                                            </td>
                                                                        )
                                                                    }

                                                                </tr>
                                                            )



                                                        )) : (
                                                            <tr>
                                                                <td colSpan={5} className="text-red-700 bg-red-100 text-center py-2">
                                                                    {t('NoAvailableCandidates')}
                                                                </td>
                                                            </tr>
                                                        )

                                                    }
                                                    {
                                                        designation['finalCandidates'].length > 0 && designation['finalCandidates'].some(item => item.process === 'additional') && (
                                                            <tr>
                                                                <th colSpan={5} scope="col" className="py-1 pl-1 bg-indigo-200 text-left">
                                                                    {t('AdditionalCandidates')}
                                                                </th>
                                                                
                                                            </tr>
                                                        )
                                                    }

                                                    {
                                                        designation['finalCandidates'].length > 0 && designation['finalCandidates'].map((candidate, index) => (
                                                            candidate['process'] === 'additional' && (
                                                                <tr key={candidate.id}>
                                                                    <td>
                                                                        <div
                                                                            className="flex items-center cursor-pointer"
                                                                            onClick={(e) => {
                                                                                setCandidateId(candidate['userId'])
                                                                                setShowCandidateProfile(true)
                                                                            }}
                                                                        >
                                                                            <div className="h-7 w-7 flex-shrink-0">
                                                                                <img className="h-7 w-7 rounded-full"
                                                                                    // src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                                                    src={candidate['photo'] ? `${process.env.REACT_APP_IMAGE_GATEWAY_URL}/uploads/candidate/profile/${candidate['photo']}` : "/assets/images/avatar.svg"}
                                                                                    alt="" />
                                                                            </div>
                                                                            <div className="ml-4">
                                                                                <div className="font-medium text-gray-900 hover:text-indigo-900 hover:underline">{candidate.name}<span className="ml-2 inline-flex items-center rounded bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800">{t('Details')}</span></div>
                                                                                <div className="text-gray-500">{candidate.email}</div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        hasRoleModerator &&
                                                                        <td>
                                                                            <div className="text-gray-900 relative">
                                                                                <LoadingOverlay visible={rateChangeLoader} loaderProps={{ size: 'xs', color: 'indigo' }} />
                                                                                <TextInput
                                                                                    disabled={'published' !== jobDetails['status']}
                                                                                    w={80}
                                                                                    defaultValue={candidate.rate}
                                                                                    onBlur={(e) => e && updateCandidateRate({
                                                                                        rate: e.target.value,
                                                                                        rowId: candidate.id
                                                                                    })}

                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    }
                                                                    <td>
                                                                        <div className="text-gray-900">{candidate.phoneNumber}</div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="text-gray-900">
                                                                            {t('Selected')}
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        hasRoleModerator && (
                                                                            <td className="text-right">
                                                                                <button
                                                                                    className="inline-flex items-center rounded bg-red-700 border-none py-1 px-2 text-xs font-normal text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-0"
                                                                                    onClick={() => rejectAssignedCandidate(candidate, designation)}
                                                                                >
                                                                                    <FiX size={12}/>{t('Remove')}
                                                                                </button>
                                                                                
                                                                            </td>
                                                                        )
                                                                    }
                                                                    
                                                                </tr>
                                                            )
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </ScrollArea>
                                    </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </Grid.Col>
            </Grid>

        </div>
    );
}