import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    HiOutlineExclamation,
    HiOutlineOfficeBuilding,
    HiOutlinePlus,
    HiPencil,
    HiOutlineUserGroup,
    HiOutlineX, HiArrowSmRight, HiEye, HiChevronLeft, HiOutlineFilter, HiOutlineSearch
} from "react-icons/hi";
import {Link} from "react-router-dom";
import {FiSave, FiX} from "react-icons/fi";
import {Switch, Tab} from "@headlessui/react";
import {AiOutlineLoading3Quarters, AiOutlineMinus} from "react-icons/ai";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import axios from "axios";
import {useParams, useNavigate} from "react-router";
// import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle';
import {format} from 'date-fns';
import {ActionIcon, Grid, LoadingOverlay, ScrollArea, Select, Text} from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import dayjs from "dayjs";
import {openConfirmModal} from "@mantine/modals";


function Create() {
    const {domainId, roles, token, loggedUserId, name} = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();

    const {t, i18n} = useTranslation();
    const [items, setItemTypes] = useState([]);

    const [candidateId, setCandidateId] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [activeTab, setActiveTab] = useState('candidate');
    const [fetching, setFetching] = useState(false);
    const [notFoundFetching, setNotFoundFetching] = useState(false);
    const [payrollDetailsRecords, setPayrollDetailsRecords] = useState({});
    const [candidateDetails, setCandidateDetails] = useState({});
    const [payrollDetailsId, setPayrollDetailsId] = useState([]);

    const [spinner, setSpinner] = useState(false);

    const [payrollCreateAfterShowLoading, setPayrollCreateAfterShowLoading] = useState(false);

    let fullFormHeight = localStorage.getItem('fullFormHeight');
    fullFormHeight = fullFormHeight.slice(0, -2);
    fullFormHeight = fullFormHeight - 90;
    // fullFormHeight = fullFormHeight + "px";

    const [candidates, setCandidates] = useState([]);

    const getCandidates = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/users/candidate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                status: "active"
            }
        })
            .then(res => {
                let newArray = res.data.data;
                if(0 < newArray.length){
                    newArray.unshift({id: -1, name: 'All'});
                }

                setCandidates(res.data.data)
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    useEffect(() => {
        getCandidates()
    }, [])

    const [isCheckAll, setIsCheckAll] = useState(false);

    const handleSelectAll = (e) => {
        setIsCheckAll(!isCheckAll);
        const rows = document.querySelectorAll('tbody input[type="checkbox"]');
        const selected = Array.from(rows).map((checkbox) => checkbox.value);

        setPayrollDetailsId(selected);

        if (isCheckAll) {
            setPayrollDetailsId([]);
        }
    };
    const {register, handleSubmit, reset, formState} = useForm();
    const [isError, setIsError] = useState(false);
    const handleClick = (e) => {
        const { checked, value } = e.target;
        setPayrollDetailsId([...payrollDetailsId, value.toString()]);

        if (!checked) {
            setPayrollDetailsId(payrollDetailsId.filter(item => item !== value));

        }

        if(payrollDetailsId.length===0){
            setIsError(true);
        }else {
            setIsError(false);
        }


    };

    const candidateDataHandle = (e) => {
        setCandidateId(e);
    }
    const fromDateHandle = (e) => {
        setFromDate(e.target.value)
    }
    const toDateHandle = (e) => {
        setToDate(e.target.value);
    }

    const payrolldetailsparams = {
        roles,
        loggedUserId,
        domainId,
        candidateId,
        fromDate,
        toDate,
    }
    const filterErrors = [];
    const [fromDateError, setFromDateError] = useState(false)
    const [toDateError, setToDateError] = useState(false)

    function payrollDetailsFilterSubmit() {
        if(fromDate===''){
            if(!(filterErrors.indexOf("fromDateIsRequired") > -1)){
                filterErrors.push('fromDateIsRequired');
            }
        }
        if(toDate===''){
            if(!(filterErrors.indexOf("toDateIsRequired") > -1)){
                filterErrors.push('toDateIsRequired');
            }
        }
        setFromDateError(filterErrors.includes('fromDateIsRequired'));
        setToDateError(filterErrors.includes('toDateIsRequired'));

        payrolldetailsparams['candidateId'] = candidateId;
        payrolldetailsparams['fromDate'] = fromDate;
        payrolldetailsparams['toDate'] = toDate;

        if(filterErrors.length===0){
            getPayrollDetails(payrolldetailsparams);
        }else {
            setNotFoundFetching(false);
            setPayrollDetailsRecords({});
        }
    }


    const getPayrollDetails = (params) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/payroll/details`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: params
        })
            .then(res => {
                console.log(res.data)
                if(res.data.status===200){
                    setPayrollDetailsRecords(res.data.data);
                    setCandidateDetails(res.data.candidateInfo);
                    setSpinner(false);
                    setFetching(false);
                }else{
                    setPayrollDetailsRecords({});
                    setCandidateDetails({});
                }
                setNotFoundFetching(true);

            })
            .catch(function (err) {
                console.log(err);

            });
    }

    let errors = [];

    function formSubmit() {
        // setSpinner(true);
        const formData = {
            candidateId: candidateId,
            fromDate: fromDate,
            toDate: toDate,
            // payrollDetailsId: payrollDetailsId,
            loggedUserId: loggedUserId,
            domainId: domainId,
            created_by_name: name,
        }

        if (formData.fromDate === ''){
            if(!(errors.indexOf("FromDateIsRequired") > -1)){
                errors.push('FromDateIsRequired');
            }
        }

        if (formData.toDate === ''){
            if(!(errors.indexOf("ToDateIsRequired") > -1)){
                errors.push('ToDateIsRequired');
            }
        }

        if (errors.length===0) {


            openConfirmModal({
                title: (t('')),
                centered: true,
                children: (
                    <Text size="sm">
                        {t('AreYouSure')}?
                    </Text>
                ),
                labels: {confirm: (t('Yes')), cancel: (t('No'))},
                confirmProps: {className: 'bg-green-600 hover:bg-green-500'},
                // confirmProps: {color: 'red'},
                onCancel: () => console.log(t('Cancel')),
                onConfirm: () => {
                    setPayrollCreateAfterShowLoading(true);

                    axios({
                        method: 'get',
                        url: `${process.env.REACT_APP_API_GATEWAY_URL}/payroll/selected-payroll-details-for-generate-payroll`,
                        headers: {
                            "Accept": `application/json`,
                            "Content-Type": `application/json`,
                            "Access-Control-Allow-Origin": '*',
                            "Authorization": `Bearer ${token}`
                        },
                        params: formData
                    }).then(res => {
                        console.log(res.data)

                        if (res.data.status === 201) {

                            setPayrollCreateAfterShowLoading(true);
                            setSpinner(false);
                            setPayrollDetailsId([]);
                            // navigate('/payroll/' + res.data.data.payrollId + '/edit');
                            navigate('/payroll');
                        }
                        if (res.data.status === 404) {
                            showNotification({
                                id: 'load-data',
                                loading: true,
                                title: (t(res.data.message)),
                                // message: (t(res.data.message)),
                                autoClose: 3000,
                                disallowClose: true,
                                color: 'red',
                            });
                        }

                    });
                }
            });


            setIsError(false);
        }else {
            setIsError(true);
        }
    }

    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })

    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-between text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('NewPayroll')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>{t('NewPayroll')}</sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <Link to='/payroll'
                                      className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                >
                                    <HiChevronLeft size={12} className={'mr-1'}/>{t('Back')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="min-h-full w-full">
                    <div className="flex w-full md:w-auto">
                        <div className="bg-red-100 flex-1">
                            <div className="min-w-0 flex-1 bg-white xl:flex">
                                <div className="bg-white lg:min-w-0 lg:flex-1">
                                    <div className="h-full px-4 sm:px-6 lg:px-4">
                                        <div className="relative">
                                            <div className={'flex flex-1 overflow-hidden w-full'}>
                                                <div className="w-full mr-0 mx-auto border">
                                                    <div className="my-2 p-1 rounded">
                                                        <Grid align="center">
                                                            <Grid.Col xs={12} sm={12} md={5} lg={5}>
                                                                <label htmlFor="fromDate"
                                                                       className="form-input-sm-label">{t('Candidate')}</label>
                                                                <Select
                                                                    placeholder={t('SelectCandidate')}
                                                                    data={candidates.map((candidate, index) => ({ value: candidate['id'], label: candidate['name'] }))}
                                                                    // onChange={(e) => getJobList(e)}
                                                                    // onChange={(e) => console.log(e)}
                                                                    nothingFound={t('NoCandidates')}
                                                                    maxDropdownHeight={280}
                                                                    onChange={e => { candidateDataHandle(e)}}
                                                                    value={candidateId}
                                                                    searchable
                                                                />
                                                            </Grid.Col>
                                                            <Grid.Col xs={6} sm={6} md={3} lg={3}>
                                                                <label htmlFor="fromDate"
                                                                       className="form-input-sm-label-required">{t('FromDate')}</label>
                                                                <input
                                                                    {...register("fromDate")}
                                                                    style={{border:`${fromDateError===true ?'red 1px solid':''}`}}
                                                                    type="date"
                                                                    className={`form-input-sm-control ${fromDateError? 'border-red-300 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                    name="fromDate"
                                                                    id="fromDate"
                                                                    onChange={e => {  fromDateHandle(e)}}
                                                                    placeholder={t("EnterFromDate")}
                                                                    value={fromDate}
                                                                />

                                                            </Grid.Col>
                                                            <Grid.Col xs={6} sm={6} md={3} lg={3}>
                                                                <label htmlFor="fromDate"
                                                                       className="form-input-sm-label-required">{t('ToDate')}</label>
                                                                <input
                                                                    {...register("toDate")}
                                                                    style={{border:`${toDateError===true ?'red 1px solid':''}`}}
                                                                    type="date"
                                                                    className={`form-input-sm-control ${toDateError===true ? 'border-red-600 focus:border-red-600' : 'border-gray-300 focus:border-blue-600'} `}
                                                                    name="toDate"
                                                                    id="toDate"
                                                                    onChange={e => { toDateHandle(e)}}
                                                                    placeholder={t("EnterToDate")}
                                                                    value={toDate}
                                                                />

                                                            </Grid.Col>
                                                            <Grid.Col xs={6} sm={6} md={1} lg={1}>
                                                                <button type='button' className="mt-8 inline-flex items-center rounded border-none bg-indigo-800 py-2 px-2 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0 border rounded text-sm" onClick={()=>payrollDetailsFilterSubmit()}>
                                                                    {spinner ? (
                                                                        <HiOutlineSearch
                                                                            className={'mr-2 animate-spin'}
                                                                            size={14}/>
                                                                    ) : (<HiOutlineSearch size={14} className={'mr-2'}/>)}
                                                                    <span>{t('Search')}</span>
                                                                </button>
                                                                {/*<button className="inline-flex items-center rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0" type="button" onClick={()=>payrollDetailsFilterSubmit()}>{t('Search')}</button>*/}
                                                            </Grid.Col>
                                                        </Grid>
                                                    </div>

                                                    <div className="w-full px-1">
                                                        {Object.values(payrollDetailsRecords).length>0 && payrollDetailsRecords ?
                                                            <>
                                                                <LoadingOverlay visible={payrollCreateAfterShowLoading} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'sm' }} overlayColor="bg-indigo-100" />

                                                                <form onSubmit={handleSubmit(formSubmit)}>

                                                                    <div style={{height: fullFormHeight}}
                                                                         className="relative flex flex-col min-w-0 break-words w-full border-0 inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                                                                        <table className="min-w-full">
                                                                            <thead className="bg-white">
                                                                            <tr className="bg-indigo-50">
                                                                                {/*<th className="relative whitespace-nowrap p-2 text-right text-sm font-medium w-12">
                                                                                    <input onChange={(e)=>handleSelectAll(e)}
                                                                                           checked={isCheckAll} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                                                                                </th>*/}
                                                                                <th>{t('CandidateName')}</th>
                                                                                <th>{t('JobTitle')}</th>
                                                                                <th>{t('Designation')}</th>
                                                                                <th>{t('Date')}</th>
                                                                                <th>{t('InTime')}</th>
                                                                                <th>{t('OutTime')}</th>
                                                                                <th>{t('Hour')}</th>
                                                                                <th>{t('Rate')}</th>
                                                                                {/*<th>{t('Amount')}</th>*/}
                                                                            </tr>
                                                                            </thead>

                                                                            <tbody>

                                                                            {payrollDetailsRecords && Object.keys(payrollDetailsRecords).map((employeeId)=>{
                                                                                {var candidateWiseCount = 0}
                                                                                return (
                                                                                    payrollDetailsRecords && payrollDetailsRecords[employeeId] && Object.values(payrollDetailsRecords[employeeId]).map((detail, index)=>{
                                                                                        { candidateWiseCount = candidateWiseCount+1}
                                                                                        return (
                                                                                            <tr key={index} id={index}>
                                                                                                {/*<th className="border border-slate-300 relative whitespace-nowrap p-2 text-right text-sm font-medium w-12">
                                                                                                                            <input
                                                                                                                                {...register('payrollDetailsId')}
                                                                                                                                value={detail.id}
                                                                                                                                type="checkbox"
                                                                                                                                className={`w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 ${isError && "border-pink-500 ring-pink-500"}`}
                                                                                                                                // onClick={(e)=>handlePayrollDetailsId(e, detail.id )}
                                                                                                                                onChange={(e)=>handleClick(e)}
                                                                                                                                checked={payrollDetailsId.includes(detail.id.toString())}
                                                                                                                            />

                                                                                                                        </th>*/}

                                                                                                        {candidateWiseCount===1 &&
                                                                                                        <td rowSpan={payrollDetailsRecords && Object.values(payrollDetailsRecords[employeeId]).length} className="border border-slate-300">
                                                                                                            {candidateDetails && candidateDetails[employeeId] && candidateDetails[employeeId]['employee_name']}
                                                                                                        </td>
                                                                                                        }

                                                                                                <td className="border border-slate-300">{detail.jobTitle}</td>
                                                                                                <td className="border border-slate-300">{detail.designationName}</td>
                                                                                                <td className="border border-slate-300">
                                                                                                    {detail.checkInTime ? format(new Date(detail.checkInTime.date), 'dd, MMMM Y') : ''}
                                                                                                </td>
                                                                                                <td className="border border-slate-300">
                                                                                                    {detail.checkInTime ? format(new Date(detail.checkInTime.date), 'hh:mm aa') : ''}
                                                                                                </td>
                                                                                                <td className="border border-slate-300">
                                                                                                    {detail.checkOutTime ? format(new Date(detail.checkOutTime.date), 'hh:mm aa') : ''}
                                                                                                </td>
                                                                                                <td className="border border-slate-300">
                                                                                                    {detail.hour ? detail.hour : ''}
                                                                                                </td>
                                                                                                <td className="border border-slate-300">
                                                                                                    {detail.rate ? detail.rate : ''}
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                )
                                                                            })}


                                                                            </tbody>

                                                                        </table>
                                                                    </div>

                                                                    <footer className="relative mt-1">
                                                                        <div
                                                                            className="container mx-auto justify-items-center">
                                                                            <div className="text-right  pt-0.5 mb-0.5">
                                                                                <button
                                                                                    type='reset'
                                                                                    className={'px-3 py-1 text-gray-400 inline-flex transition duration-150 ease-in-ou ml-3 bg-gray-25 hover:bg-gray-50 items-center  text-sm'}
                                                                                ><FiX size={16} className={'mr-2'}/>
                                                                                    <span>{t('Cancel')}</span>
                                                                                </button>
                                                                                <button type='submit'
                                                                                        className="px-6 py-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border rounded text-sm"
                                                                                >

                                                                                    <span>{t('Save')}</span>
                                                                                </button>

                                                                            </div>
                                                                        </div>
                                                                    </footer>

                                                                </form>
                                                            </>
                                                            :
                                                            <>
                                                                {fromDate && toDate && notFoundFetching && Object.values(payrollDetailsRecords).length===0 ?
                                                                    <div className="relative">
                                                                        <div className="text-center flex flex-col justify-center items-center min-h-[280px]">
                                                                            <h2 className="mb-2 text-2xl text-rose-600">No Approve candidate is available.</h2>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="relative">
                                                                        <div className="text-center flex flex-col justify-center items-center min-h-[280px]">
                                                                            <h2 className="mb-2 text-2xl underline">"Instruction"</h2>
                                                                            <p>To view approved time slots.</p>
                                                                            <p>Please select "Candidate" and "Date
                                                                                range" from above and click "Search"</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default Create