import React, { useState,useEffect } from "react";
import { HiOutlineArrowRight, HiOutlineX } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import { ScrollArea, LoadingOverlay } from '@mantine/core';


export default function TimeMatrixModal({ setShowModal, reqDesignationId, setRecallReqDesignation }) {
    const { token, loggedUserId } = JSON.parse(localStorage.getItem('user'))
    const { t, i18n } = useTranslation();

    const [data, setData] = useState([]);

    const getData = () => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-required-designation-time-matrix`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {reqDesigId: reqDesignationId}
        })
            .then(res => {
                setData(res.data.data)
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const updateInTimeOutTime = (id, time, flag) => {

        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-required-designation-time-matrix`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {id, time, flag}
        })
            .then(res => {
                showNotification({
                    id: 'load-data',
                    loading: true,
                    title: (200 === res.data.status ? t('Success') : t('Error')),
                    message: (t(res.data.message)),
                    autoClose: 2000,
                    disallowClose: true,
                    color: (200 === res.data.status ? 'green' : 'red'),
                });
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    useEffect(()=>{
        getData();
    }, [])


    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.8]">
                <div className="relative w-3/4">
                    {/*content*/}
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="pt-4 modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('TimeMatrix')}</h5>
                                <button
                                    onClick={() => {
                                        setShowModal(false)
                                        setRecallReqDesignation(prevState => !prevState)
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>

                            <div className="modal-body relative">
                                <div>
                                    <ScrollArea scrollbarSize={4} style={{ height: 500 }} className="relative">
                                        { !data && <LoadingOverlay visible={true} overlayBlur={2} loaderProps={{ color: 'indigo' }} overlayColor="bg-indigo-100"/> }
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col"
                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{t('Date')}
                                                    </th>
                                                    <th scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{t('InTime')}
                                                    </th>
                                                    <th scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{t('OutTime')}
                                                    </th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {
                                                    data.map((record, index)=> (
                                                        <tr key={record['id']}>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">{record['date']}</td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                <input
                                                                    type="time"
                                                                    className={`form-input-sm-control`}
                                                                    defaultValue={record['inTime'] ? record['inTime'] : ''}
                                                                    onChange={e => updateInTimeOutTime(record['id'], e.target.value, 'in-time')}
                                                                />
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                <input
                                                                    type="time"
                                                                    className={`form-input-sm-control`}
                                                                    defaultValue={record['outTime'] ? record['outTime'] : ''}
                                                                    onChange={e => updateInTimeOutTime(record['id'], e.target.value, 'out-time')}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                

                                            </tbody>
                                        </table>
                                    </ScrollArea>
                                </div>
                            </div>

                            {/*<div
                                className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                <button onClick={() => {
                                    setRecall(!recall)
                                    setShowModal(false)
                                }}
                                        className="bg-grey-light hover:bg-grey rounded inline-flex items-cente focus:outline-none focus:ring-1 mt-1 focus:ring-offset-1  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-4 py-2 text-sm">
                                    <HiOutlineX
                                        className="mr-2 h-5 w-5"
                                        aria-hidden="true"
                                    /><span>{t('Close')}</span>
                                </button>

                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red" />
        </>
    );
}
