import React, {useEffect, useState, Fragment} from "react";
import {useTranslation} from "react-i18next";
import {
    HiLocationMarker,
    HiCalendar,
    HiOutlineX, HiTrash, HiOutlineCheck, HiEye,
    HiOutlineCalendar,
    HiOutlineUserCircle,
    HiOutlineClock
} from "react-icons/hi";
import {format} from 'date-fns';
import {ActionIcon, Switch, Tooltip, Popover, Text, ScrollArea} from "@mantine/core";
import axios from "axios";
import {showNotification} from "@mantine/notifications";
import button from "@material-tailwind/react";
import { useDisclosure } from '@mantine/hooks';
import CandidateDeclineModal from "./CandidateDeclineModal";
import DesignationList from "./inc/DesignationList";



export default function ViewJob({setShowModal, jobDetails, roles, jobView}) {
    const [opened, { close, open }] = useDisclosure(false);
    // console.log(jobDetails, requiredDesignation, assignedCandidate)
    const {token, loggedUserId} = JSON.parse(localStorage.getItem('user'));
    const {t, i18n} = useTranslation();
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const [recall, setRecall] = useState(false);
    const [showDeclineModal, setShowDeclineModal] = useState(false);
    const [declineInfo, setDeclineInfo] = useState({});


    const modalHeight = localStorage.getItem('modalHeight');

    const [candidateDesignations, setCandidateDesignations] = useState([]);


    const getCandidateDesignations = (userId) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/designation-wise-rate`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {userId}
        })
            .then(res => {
                setCandidateDesignations(res.data.data);
            })
            .catch(function (err) {
                console.log(err);

            });
    }
    


    const [applyStatus, setApplyStatus] = useState({});
    const getAppliedJobStatus = () => {
        // job-applied-status
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/agency/job-applied-status`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                jobId: jobDetails.id,
                userId: loggedUserId
            }
        })
            .then(res => {
                // console.log(res)
                setApplyStatus(res.data.data)
            })
            .catch(function (err) {
                console.log(err);

            });
    }

    useEffect(()=>{
        getAppliedJobStatus()
    }, [recall])

    useEffect(() => {
        if(hasRoleCandidate){
            getCandidateDesignations(loggedUserId)

        }
    }, [])


    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })
    const hasRoleCandidate = roles.find(role => {
        return role === 'ROLE_CANDIDATE';
    })

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    {/*content*/}
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg  flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none  text-gray-800">
                            <div
                                className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{jobDetails.title}</h5>
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <aside className="w-full sm:w-1/3 md:w-1/4 px-2">
                                            <div className="sticky top-0 w-full">
                                                <div className="mt-2">
                                                    <p className="text-center">{jobDetails.clientName}</p>
                                                    <div className="mx-auto max-w-lg lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4 lg:mx-0 lg:mt-6 lg:max-w-none">
                                                        <div className="relative z-10 rounded-lg shadow-xl">
                                                            <div className="pointer-events-none absolute inset-0 rounded-lg border-2 border-indigo-600" aria-hidden="true"/>
                                                            <div className="absolute inset-x-0 top-0 translate-y-px transform">
                                                                <div className="flex -translate-y-1/2 transform justify-center">
                                                                    <span className="inline-flex rounded-full bg-indigo-600 px-4 py-1 text-base font-semibold text-white"><HiLocationMarker  size={20} className={"mr-1"}/>{jobDetails.city ? jobDetails.city.split('-')[1] : ''}, {jobDetails.country ? jobDetails.country.split('-')[1] : ''}</span>
                                                                </div>
                                                            </div>
                                                            <div className="rounded-t-lg bg-white px-6 pt-10 pb-4">
                                                                <div>
                                                                    <div className="flex items-center">
                                                                        <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">{t('Availability')}</h4>
                                                                        <div className="flex-1 border-t-2 border-gray-200"/>
                                                                    </div>
                                                                    <ul role="list" className="mt-2">
                                                                        <li className="flex items-start lg:col-span-1 mb-2">
                                                                            <div className="flex-shrink-0">
                                                                                <HiOutlineCalendar size={20} className={`bg-indigo-500 text-white`}/>
                                                                            </div>
                                                                            <p className="ml-3 text-sm text-gray-700">{t('Start')}: {jobDetails.startTime ? format(new Date(jobDetails.startTime.date), 'MM/dd/yyyy') : ''}</p>
                                                                        </li>
                                                                        <li className="flex items-start lg:col-span-1">
                                                                            <div className="flex-shrink-0">
                                                                                <HiOutlineCalendar size={20} className={`bg-indigo-500 text-white`}/>
                                                                            </div>
                                                                            <p className="ml-3 text-sm text-gray-700">{t('End')}: {jobDetails.endTime ? format(new Date(jobDetails.endTime.date), 'MM/dd/yyyy') : ''}</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="rounded-b-lg border-t-2 border-gray-100 bg-gray-50">
                                                                {/* <p className="block w-full rounded-lg border border-transparent bg-indigo-600 px-6 py-4 text-center text-xl font-medium leading-6 text-white hover:bg-indigo-700"> {jobDetails.vacancy ? jobDetails.vacancy : ''} {t('Person')}</p> */}
                                                                <p className="block w-full rounded-lg border border-transparent bg-indigo-600 px-6 py-4 text-center text-xl font-medium leading-6 text-white hover:bg-indigo-700"> {jobDetails['status'].charAt(0).toUpperCase() + jobDetails['status'].slice(1)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4 py-1 border-t border-blueGray-200 text-left">&nbsp;</div>
                                                    <ScrollArea style={{ height: 250 }} scrollbarSize={4}>
                                                        <div className="mt-1 py-1 text-left">

                                                            { jobDetails['latitude'] && jobDetails['longitude'] && (
                                                                <div className="flex flex-wrap">
                                                                    <div className="w-full">
                                                                        <iframe 
                                                                        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&q=${jobDetails['latitude']},${jobDetails['longitude']}`}
                                                                        height={300}
                                                                        allowFullScreen=""
                                                                        loading="lazy"
                                                                        referrerPolicy="no-referrer-when-downgrade">

                                                                        </iframe>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            
                                                            <div className="flex flex-wrap">
                                                                <div className="w-full">
                                                                    <p className="text-slate-800">{jobDetails.description ? jobDetails.description : ''}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ScrollArea>

                                                </div>
                                            </div>

                                        </aside>
                                        <main role="main" className="w-full sm:w-2/3 md:w-3/4 pt-1 px-2">
                                            <ScrollArea style={{ height: modalHeight }} scrollbarSize={4}>
                                                {
                                                    jobDetails.designations && jobDetails.designations.map((designation, index)=>(
                                                        designation['designationSlug'] && (
                                                            <Fragment>
                                                                {
                                                                    hasRoleCandidate && candidateDesignations.some(candidateDesignation => candidateDesignation['designationSlug'] === designation['designationSlug']) && 
                                                                        <DesignationList
                                                                            key={designation.id} 
                                                                            designation={designation} 
                                                                            setRecall={setRecall} 
                                                                            jobView={jobView} 
                                                                            jobDetails={jobDetails}
                                                                            setDeclineInfo={setDeclineInfo}
                                                                            setShowDeclineModal={setShowDeclineModal}
                                                                        />
                                                                }
                                                                {
                                                                    !hasRoleCandidate && (
                                                                        <DesignationList
                                                                            key={designation.id} 
                                                                            designation={designation} 
                                                                            setRecall={setRecall} 
                                                                            jobView={jobView} 
                                                                            jobDetails={jobDetails}
                                                                            setDeclineInfo={setDeclineInfo}
                                                                            setShowDeclineModal={setShowDeclineModal}
                                                                        />
                                                            )
                                                                }
                                                            </Fragment>
                                                        )

                                                    ))
                                                }


                                                {/* {
                                                    jobDetails.designations && jobDetails.designations.map((designation, index)=>(
                                                        designation['designationSlug'] && (
                                                            <DesignationList
                                                                key={designation.id} 
                                                                designation={designation} 
                                                                setRecall={setRecall} 
                                                                jobView={jobView} 
                                                                jobDetails={jobDetails}
                                                                setDeclineInfo={setDeclineInfo}
                                                                setShowDeclineModal={setShowDeclineModal}
                                                            />
                                                        )

                                                    ))
                                                } */}
                                            </ScrollArea>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red"/>
            {showDeclineModal && <CandidateDeclineModal setShowModal={setShowDeclineModal} declineInfo={declineInfo} jobView={jobView} jobDetails={jobDetails} setRecall={setRecall}/>}
        </>
    );
}
