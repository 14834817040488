import React, {useEffect, useState} from "react";
import {
    HiEye,
    HiOutlineFilter,
    HiOutlineOfficeBuilding,
    HiOutlinePlus,
    HiOutlineSearch,
    HiPencil,
    HiSearch, HiTrash
} from "react-icons/hi";
import {useTranslation} from "react-i18next";
import {format,lastDayOfMonth} from 'date-fns';
import {DataTable} from "mantine-datatable";
import {ActionIcon, Box, Group, Text, Select, ScrollArea, TextInput, Grid, Drawer} from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import {openConfirmModal} from "@mantine/modals";



const PER_PAGE = 20;
function Index() {
    const {t, i18n} = useTranslation();
    const {token, roles, loggedUserId, domainId} = JSON.parse(localStorage.getItem('user'));
    const fullTableHeight = localStorage.getItem('fullTableHeight');
    const [page, setPage] = useState(1);
    const [fetching, setFetching] = useState(true);
    const [sortStatus, setSortStatus] = useState({ columnAccessor: 'emailContent', direction: 'asc' });
    const [notifications,setNotificaton] = useState([]);
    const [totalNotification,setTotalNotification] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/notification`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {
                "per_page": PER_PAGE,
                "page": page
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setTimeout(() => {
                        setNotificaton(res.data.data)
                        setTotalNotification(res.data.total)
                        setFetching(false);
                    },500)
                }
            })
            .catch(function (error) {
                console.log(error)
            })

    }, [page,fetching]);

    const NotificationDelete = (e,id) => {
        e.preventDefault();
        openConfirmModal({
            title: (t('DeleteThisData')),
            centered: true,
            children: (
                <Text size="sm">
                    {t('AreYouSureYouWantToDelete')}
                </Text>
            ),
            labels: {confirm: (t('Delete')), cancel: (t('NoDontDeleteIt'))},
            confirmProps: {color: 'red'},
            onCancel: () => console.log(t('Cancel')),
            onConfirm: () =>
                (
                    axios.delete(`${process.env.REACT_APP_API_GATEWAY_URL}/notification/${id}`, {headers: {"Authorization": `Bearer ${token}`}})
                        .then(res => {
                            if (res.status === 200) {
                                showNotification({
                                    id: 'load-data',
                                    loading: true,
                                    title: (t('DataIsDeleting')),
                                    message: (t('DataLoad')),
                                    autoClose: 500,
                                    disallowClose: true,
                                    color: 'red',
                                });
                                setFetching(true)
                            }
                        })
                )
            ,
        });
    }



    const hasRoleClient = roles.find(role => {
        return role === 'ROLE_CLIENT';
    })
    const hasRoleCandidate = roles.find(role => {
        return role === 'ROLE_CANDIDATE';
    })
    const hasRoleModerator = roles.find(role => {
        return role === 'ROLE_MODERATOR';
    })

    return (
        <main className="w-full">
            <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-between text-gray-600 mb-3 items-center">
                <div className={"flex-none w-44"}>
                    <div className="flex text-gray-800 h-full tracking-normal leading-tight">
                        <HiOutlineOfficeBuilding size={24} className={'mr-2 text-indigo-300'}/>
                        <span className={'font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                            {t('Templates')}
                            <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('Notification')}</sub></span>
                    </div>
                </div>
                <div className={"right flex-none mr-8"}>
                    <div className="flex items-center justify-center">
                        <div className="inline-flex border-1 border-red-300" role="group">
                            <Link to ='/notification/create'
                                  className="inline-flex items-center ml-3 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                            >
                                <HiOutlinePlus size={12} className={'mr-1'}/>{t('NewTemplate')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full px-4">
                <Box sx={{height: fullTableHeight}}>
                    <DataTable
                        withBorder
                        records={notifications}
                        striped
                        styles={(theme) => ({
                            root: {
                                border: '1px solid #e0e7ff',
                                borderRadius: 2,
                            },
                            header: {
                                background: '#e0e7ff',
                                backgroundColor: "#e0e7ff",
                                color: theme.colors.indigo[6],
                            },
                            pagination: {
                                color: theme.colors.gray[3],
                                paddingTop : "5px",
                                paddingBottom : "5px"
                            },
                            item: {
                                '&[data-active]': {
                                    backgroundImage: theme.fn.gradient({ from: 'red', to: 'yellow' }),
                                },
                            },
                        })}
                        columns={[
                            {
                                accessor: '',
                                title: (t('SL.')),
                                width: 45,
                                render: (item) => (notifications.indexOf(item) + 1) + PER_PAGE * (page - 1)
                            },
                            {
                                accessor: 'serviceName',
                                title: (t('ServiceName')),
                                // sortable: true
                            },
                            {
                                accessor: 'moduleName',
                                title: (t('ModuleName')),
                                // sortable: true
                            },
                            {
                                accessor: 'name',
                                title: (t('Name')),
                                // sortable: true
                            },
                            {
                                accessor: 'emailContent',
                                title: (t('Email')),
                                // sortable: true
                            },
                            {
                                accessor: 'smsContent',
                                title: (t('Sms')),
                                // sortable: true
                            },
                            {
                                accessor: 'pushContent',
                                title: (t('Push')),
                            },
                            {
                                accessor: 'actions',
                                title: <Text mr="xs">{t('Action')}</Text>,
                                textAlignment: 'right',
                                width: 200,
                                render: (record) => (
                                    <Group spacing={4} position="right">
                                        <ActionIcon color="green" >
                                            <HiPencil size={16} onClick = {() => navigate(`/notification/${record['id']}/edit`) }/>
                                        </ActionIcon>
                                        <ActionIcon color="red">
                                            <HiTrash size={16} onClick = {(e) => NotificationDelete(e,record['id'])} />
                                        </ActionIcon>
                                    </Group>
                                ),
                            },

                        ]}
                        totalRecords={totalNotification}
                        recordsPerPage={PER_PAGE}
                        page={page}
                        onPageChange={(p) => {
                            setPage(p)
                            setFetching(true)
                        }}
                        fetching={fetching}
                        loaderSize="lg"
                        loaderColor="blue"
                        loaderBackgroundBlur={1}
                        // sortStatus={sortStatus}
                        // onSortStatusChange={setSortStatus}
                    />

                </Box>
            </div>
        </main>

    );

}
export default Index