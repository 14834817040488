import React, {useEffect, useRef} from 'react';

import {useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {
    HiEye,
    HiOutlineChartSquareBar,
    HiOutlineExclamationCircle,
    HiOutlineOfficeBuilding, HiOutlinePlus,
    HiPencil, HiTrash, HiSearch
} from "react-icons/hi";
import {Link, useNavigate} from "react-router-dom";
import JournalEntryModal from './modals/JournalEntryModal';

const PER_PAGE = 10;

function Bookkeeping() {
    const {domainId, loggedUserId ,roles, token} = JSON.parse(localStorage.getItem('user'));

    const {t, i18n} = useTranslation();

    const fullFormHeight = localStorage.getItem('fullFormHeight');
    const navigate = useNavigate();

    const [showJournalEntryModal, setShowJournalEntryModal] = useState(false);

    return (
        <>
            <main className="w-full">
                <div className="w-full flex pl-4 h-12 bg-indigo-100 justify-start text-gray-600 mb-3">
                    <div className={"flex-1"}>
                        <div className="flex text-gray-800 h-full  tracking-normal leading-tight ">
                            <HiOutlineOfficeBuilding size={24} className={'mt-3 mr-2 text-indigo-300'}/>
                            <span className={'mt-2 font-lg font-bold clear-both relative text-indigo-500 w-full'}>
                                {t('BookKeeping')}
                                <sub className={'font-normal absolute left-0 top-6 text-indigo-400'}>  {t('BookKeepingInformation')}</sub>
                            </span>
                        </div>
                    </div>
                    <div className={"right flex mr-8"}>
                        <div className="flex items-center justify-center">
                            <div className="inline-flex border-1 border-red-300" role="group">
                                <button
                                    onClick={()=> setShowJournalEntryModal(true)}
                                    className="inline-flex items-center ml-6 rounded border-none bg-indigo-800 py-2 px-4 text-sm font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0"
                                    type='button'
                                >
                                    <HiOutlinePlus size={12} className={'mr-1'}/>{t('Transactions')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full px-4">
                    <div
                        className="px-8 py-3 relative flex flex-col min-w-0 break-words w-full border-0 inner-div-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full" style={{height: fullFormHeight}}>
                       This page is under development
                    </div>
                </div>
            </main>

            { showJournalEntryModal && <JournalEntryModal setShowModal={setShowJournalEntryModal}/>}
        </>
    )
}

export default Bookkeeping