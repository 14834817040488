import React, { Fragment, useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import button from "@material-tailwind/react";
import { HiHome, HiOutlineCalendar, HiOutlineExclamation, HiOutlineX, HiOutlineRefresh } from "react-icons/hi";
import { ScrollArea, Table, LoadingOverlay, Checkbox, Group, Select, TextInput, Container, Grid, Text, List, Flex, Badge } from "@mantine/core";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiArrowRight } from "react-icons/fi";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import { FaTelegramPlane } from "react-icons/fa";

export default function DisputeThreadModal({ setShowModal, setRefreshList, threadId }) {
    const { t, i18n } = useTranslation();
    const { token, loggedUserId, name, roles } = JSON.parse(localStorage.getItem("user"));
    const modalHeight = localStorage.getItem("modalHeight");

    const viewport = useRef(null);
    const scrollToBottom = () => viewport.current.scrollTo({ top: viewport.current.scrollHeight, behavior: 'smooth' });

    const [spinner, setSpinner] = useState(false);
    const [loader, setLoader] = useState(false);
    const [commentSubmit, setCommentSubmit] = useState(false);
    const [reloadThread, setReloadThread] = useState(false);
    const [threadDetails, setThreadDetails] = useState({});
    const validationSchema = Yup.object().shape({
        comment: Yup.string().required(t('CommentIsRequired')),

    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState, setValue } = useForm(formOptions);
    const { errors } = formState;


    const getThreadDetails = (threadId) => {
        setLoader(true);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/thread/show`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            params: {threadId, roles}
        })
            .then(res => {
                setThreadDetails(res.data.data);
                setLoader(false)
            })
            .catch(function (err) {
                console.log(err);
            });
    }

    useEffect(()=> {
        scrollToBottom();
    });
    useEffect(()=> {
        getThreadDetails(threadId);
    }, [commentSubmit, reloadThread]);


    function formSubmit(payload) {
        setSpinner(true);
        payload['threadId'] = threadId;
        payload['commenterId'] = loggedUserId;
        payload['commenterName'] = name;
        payload['roles'] = roles;
        // data['comments'].push(
        //     {id: Math.random(), threadId: 1, text: payload['comment'], senderUserId: loggedUserId, senderName: name, parentId: null},
        // );
        // console.log(payload);
        // setSpinner(false);


        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/thread/comment`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: payload
        })
            .then(res => {
                // console.log(parameters)
                if (201 === res.data.status) {
                    setCommentSubmit(prevState => !prevState)
                    setValue('comment', null)
                } else {
                    showNotification({
                        id: 'load-data',
                        loading: true,
                        title: (t('Error')),
                        message: (t(res.data.message)),
                        autoClose: 2000,
                        disallowClose: true,
                        color: 'red',
                    });
                }
                setSpinner(false);
                // setShowModal(false);
                // setRefreshList(prevState => !prevState)
            })
            .catch(function (err) {
                console.log(err);
            });

    }

    const updateCommentReadStatus = () =>{
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_GATEWAY_URL}/thread/update-comment-read-status`,
            headers: {
                "Accept": `application/json`,
                "Content-Type": `application/json`,
                "Access-Control-Allow-Origin": '*',
                "Authorization": `Bearer ${token}`
            },
            data: {threadId, roles}
        })
            .then()
            .catch(function (err) {
                console.log(err);
            });
    }

    const hasRoleModerator = roles.find(role => {
        return role === "ROLE_MODERATOR";
    });
    const hasRoleClient = roles.find(role => {
        return role === "ROLE_CLIENT";
    });
    const hasRoleCandidate = roles.find(role => {
        return role === "ROLE_CANDIDATE";
    });


    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-indigo-100/[.6]">
                <div className="relative w-10/12">
                    <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
                        <div className="modal-content border-none shadow-lg flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-gray-800 relative">
                            <div className="modal-header flex justify-between p-4 border-b border-gray-200 rounded-b-md">
                                <h5 className="text-xl text-left font-semibold leading-normal mb-1 text-blueGray-700 mb-3">{t('IssueComments')}</h5>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setShowModal(false)
                                        updateCommentReadStatus()
                                    }}
                                    className="inline-flex items-center text-right font-normal text-gray-600 ">
                                    <HiOutlineX className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="modal-body  p-4 pb-0 pt-0">
                                <div className="container mx-auto">
                                    <div className="flex flex-row flex-wrap">
                                        <div role="main" className={`w-full pt-1 px-2`}>
                                            <ScrollArea style={{ height: modalHeight }} scrollbarSize={4}>
                                                <Container fluid className="bg-indigo-100 text-sm py-2">
                                                    <Grid>
                                                        <Grid.Col span="auto" className="flex flex-row">
                                                            <Text><span className="font-semibold">{t('Issue')}:</span> { threadDetails['threadIssue'] }</Text>
                                                        </Grid.Col>

                                                        <Grid.Col span={2} className="flex flex-row">
                                                            <Text><span className="font-semibold">{t('Status')}:</span> {threadDetails['status'] && threadDetails['status'][0].toUpperCase() + threadDetails['status'].slice(1)}</Text><Text></Text>
                                                        </Grid.Col>
                                                    </Grid>
                                                </Container>
                                                <Group className="mt-5">
                                                    <ScrollArea h={modalHeight} scrollbarSize={4} className="relative w-full" type="always" viewportRef={viewport}>
                                                        <LoadingOverlay visible={loader} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'sm', variant: 'dots'}} className="bg-indigo-50/[0.05]" overlayColor="bg-indigo-100" />

                                                        <ul className="w-full">
                                                            {
                                                                Object.values(threadDetails).length > 0 && threadDetails['comments'].length > 0 && threadDetails['comments'].map(comment => (
                                                                    <li className={`${loggedUserId === comment['commenterId'] ? 'bg-blue-100 text-right' : 'bg-gray-200'} px-1 py-2 mb-1 leading-none rounded w-full`} key={comment['id']}>
                                                                        {loggedUserId === comment['commenterId'] ? (
                                                                            <>
                                                                                <Flex justify="space-between" align="center" direction="row" className="w-full">
                                                                                    <Text fs="italic" fz="xs" fw={600} className="items-center rounded bg-white px-1 text-left">{comment['createdAt']}</Text>
                                                                                    <Text tt="uppercase" fw={700} fz="xs">{comment['commenterName']}</Text>
                                                                                </Flex>
                                                                                <Text fz="xs">{comment['comment']}</Text>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Flex justify="space-between" align="center" direction="row" className="w-full">
                                                                                    
                                                                                    <Text tt="uppercase" fw={700} fz="xs">{comment['commenterName']}</Text>
                                                                                    <Flex
                                                                                        justify="flex-end"
                                                                                        align="flex-end"
                                                                                        wrap="wrap"
                                                                                    >
                                                                                        {hasRoleModerator && !comment['moderatorRead'] && <Badge color="red" size="xs" variant="outline">{t('New')}</Badge>}
                                                                                        {hasRoleClient && !comment['clientRead'] && <Badge color="red" size="xs" variant="outline">{t('New')}</Badge>}
                                                                                        {hasRoleCandidate && !comment['candidateRead'] && <Badge color="red" size="xs" variant="outline">{t('New')}</Badge>}
                                                                                        <Text fs="italic" fz="xs" fw={600} className="items-center rounded bg-white px-1 text-left">{comment['createdAt']}</Text>
                                                                                            
                                                                                    </Flex>
                                                                                </Flex>
                                                                                <Text fz="xs">{comment['comment']}</Text>
                                                                            </>
                                                                        )}
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </ScrollArea>
                                                </Group>
                                               
                                            </ScrollArea>
                                         
                                            <form onSubmit={handleSubmit(formSubmit)}>
                                                <div className="w-full mb-1">
                                                    <div className="relative mt-1 sm:col-span-2 sm:mt-0 flex flex-row w-full">
                                                        <TextInput
                                                            {...register("comment")}
                                                            disabled={Object.values(threadDetails).length > 0 && threadDetails['status'] === 'complete'}
                                                            name="comment"
                                                            id="comment"
                                                            placeholder={t("Comment")}
                                                            className={`w-full`}
                                                            autoComplete="off"
                                                        />
                                                        <button
                                                            type="submit"
                                                            disabled={spinner || Object.values(threadDetails).length > 0 && threadDetails['status'] === 'complete'}
                                                            className="disabled:cursor-not-allowed disabled:bg-gray-700 disabled:border-gray-700 disabled:hover:bg-gray-700 p-2 text-white inline-flex focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 items-center border border-indigo-700 text-sm"
                                                        >
                                                            { spinner ? (<AiOutlineLoading3Quarters className={'animate-spin'} size={16} />) : (<FaTelegramPlane size={16} />) }
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="p-2 rounded-r text-white inline-flex focus:ring-blue-700 transition duration-150 ease-in-out hover:bg-blue-600 bg-blue-700 items-center border border-blue-700 text-sm"
                                                            onClick={e => setReloadThread(prevState => !prevState)}
                                                            title={t('Refresh')}
                                                        >
                                                            { <HiOutlineRefresh size={16} /> }
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-red" />
        </>
    );
}