import React from "react";
import GetTab from "../utilities/modal/GetTab";
import {HiOutlineArrowRight, HiOutlineHome} from "react-icons/hi";
import { FiBell,FiArrowRight, FiSearch } from "react-icons/fi";
import {useTranslation} from "react-i18next";
import {
  HiOutlineBell
} from "react-icons/hi";
import {Link} from "react-router-dom";
import ProfileDropdown from "./ProfileDropdown";
import {
    BriefcaseIcon,
    ChatBubbleOvalLeftEllipsisIcon,
    ClipboardDocumentCheckIcon,
    UserGroupIcon
} from "@heroicons/react/20/solid";

function Header(){
    const { t, i18n } = useTranslation();
    const defaultLanguage = localStorage.getItem('language');

    const changeLanguageHandler = (e,data) => {
        i18n.changeLanguage(data);
        localStorage.setItem('language',data);
    }

    return(
        <>
            <header className="sticky top-0 z-50 border-b-2 bg-white px-4">
                <div className="flex flex-row">
                    <div className="flex items-center">
                        <div className="h-12 w-6 lg:w-20">
                            <Link to='/home'>
                                <img className="h-full" src={"/assets/images/simple-staffing.png"} alt="Simple Staffing" />
                            </Link>
                        </div>
                    </div>
                    <div className="flex ml-8 w-full items-center">
                        <form className="flex w-full md:ml-0" action="#" method="GET">
                            <label htmlFor="search-field" className="sr-only">Search</label>
                            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2" aria-hidden="true">
                                    <FiSearch/>
                                </div>
                                <input id="search-field" name="search-field" className="border-gray-300 border-t-0 border-l-0 border-r-0 border-b block h-full w-1/2 py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm" placeholder={t('search')} type="search"/>
                            </div>
                        </form>
                    </div>
                    {/*<div className="flex w-full items-center"></div>*/}
                    <div className="flex items-center w-1/4">
                        <div className="group inline-block relative mr-3" id="button"></div>
                        <div className="pr-3">
                            <HiOutlineBell size={'24'}/>
                        </div>
                        <ProfileDropdown/>

                    </div>
                </div>
            </header>
        {/*<header className="flex bg-indigo-700 h-16 items-center z-10">*/}
        {/*  <div className="flex-1 flex flex-col">*/}
        {/*    <nav className="px-4 flex justify-between bg-white h-16 border-b-2">*/}
        {/*        <div className="flex items-center">*/}
        {/*          <div className="h-12 w-6 lg:w-20">*/}
        {/*            <img className="h-full" src={"/assets/images/simple-staffing.png"} alt="Simple Staffing" />*/}
        {/*          </div>*/}
        {/*        </div>*/}

        {/*        <div className="flex w-1/3 items-center">*/}
        {/*          <form className="flex w-full md:ml-0" action="#" method="GET">*/}
        {/*              <label htmlFor="search-field" className="sr-only">Search</label>*/}
        {/*              <div className="relative w-full text-gray-400 focus-within:text-gray-600">*/}
        {/*                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center" aria-hidden="true">*/}
        {/*                    <FiSearch/>*/}
        {/*                </div>*/}
        {/*                  <input id="search-field" name="search-field" className="border-t-0 border-l-0 border-r-0 border-gray-300 block h-full w-full py-2 pl-6 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm" placeholder={t('search')} type="search"/>*/}
        {/*              </div>*/}
        {/*          </form>*/}
        {/*        </div>*/}

        {/*      <div className="flex items-center">*/}
        {/*          <div className="group inline-block relative mr-3" id="button"></div>*/}
        {/*        <div className="pr-3">*/}
        {/*            <HiOutlineBell size={'24'}/>*/}
        {/*        </div>*/}
        {/*          <ProfileDropdown/>*/}
        {/*      </div>*/}
        {/*    </nav>*/}
        {/*  </div>*/}
        {/*</header>*/}


        {/*<header className="flex bg-indigo-700 h-16 items-center z-10">
          <div className="flex-1 flex flex-col">
            <nav className="px-4 flex justify-between bg-white h-16 border-b-2">

            <div className="flex items-center">
              <div className="h-12 w-6 lg:w-24">
                <img className="h-full" src={"/assets/images/simple-staffing.png"} alt="Simple Staffing" />
              </div>
              </div>

              <div className="flex items-center">

                  <form className="flex w-full md:ml-0" action="#" method="GET">
                      <label htmlFor="search-field" className="sr-only">Search</label>
                      <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center" aria-hidden="true">
                            <FiSearch/>
                        </div>
                          <FiSearch/>

                          <input id="search-field" name="search-field" className="border-t-0 border-l-0 border-r-0 border-gray-300 block h-full w-full py-2 pl-6 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm" placeholder={t('search')} type="search"/>
                      </div>
                  </form>
              </div>

              <div className="flex items-center">
                  <div className="group inline-block relative mr-3" id="button">
                  </div>
                <div className="pr-3">
                 <HiOutlineBell size={'24'}/>
                </div>
                <ProfileDropdown/>
              </div>
            </nav>
          </div>
        </header>*/}
        </>
    )
}

export default Header