import React, { useState } from 'react';
import {useTranslation} from "react-i18next";
import {LoadingOverlay, Tooltip} from '@mantine/core';
import {
    HiOutlineExclamation,
} from "react-icons/hi";
import { showNotification } from "@mantine/notifications";



export default function CandidateListTr({candidate, assignCandidates, setAssignCandidates}){
    const {t, i18n} = useTranslation();

    const [checkIn, setCheckIn] = useState(null);
    const [checkOut, setCheckOut] = useState(null);
    const [breakDuration, setBreakDuration] = useState(0);

    const selectCandidate = (event, candidate) => {

        if(event.target.checked){
            candidate['checkIn'] = checkIn;
            candidate['checkOut'] = checkOut;
            candidate['breakDuration'] = breakDuration;
            assignCandidates.push(candidate);
            
        }else{
            const index = assignCandidates.findIndex(prod => prod.userId === candidate['userId']); //use id instead of index
            if (index > -1) { //make sure you found it
                setAssignCandidates(prevState => prevState.splice(index, 1));
            }   
        }

    }

    return(
        <tr>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                <div className="flex items-center cursor-pointer">
                    <div className="h-10 w-10 flex-shrink-0">
                        <img
                            className="h-10 w-10 rounded-full"
                            src={"/assets/images/avatar.svg"}
                            alt="" 
                        />
                    </div>
                    <div className="ml-4">
                        <div className="font-medium text-gray-900 hover:underline hover:text-indigo-900">
                            {candidate.userName}
                            {/* <span className="ml-2 inline-flex items-center rounded bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800">{t('Details')}</span> */}
                            </div>
                        <div className="text-gray-500">{candidate.email}</div>
                    </div>
                </div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div className="text-gray-900">{candidate.phone}</div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <input
                    onChange={(e) => setCheckIn(e.target.value)}
                    type="time"
                    className={`form-input-sm-control disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500`}
                />
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <input
                    onChange={(e) => setCheckOut(e.target.value)}
                    type="time"
                    className={`form-input-sm-control disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500`}
                />
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <input
                    onChange={(e) => setBreakDuration(e.target.value)}
                    type="number"
                    className={`form-input-sm-control disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500`}
                    min={0}
                />
            </td>
            <td className="relative whitespace-nowrap p-2 text-right text-sm font-medium w-12">
                <div className="relative h-6 w-6">
                    <input
                        type="checkbox"
                        className="disabled:cursor-not-allowed disabled:bg-gray-500 disabled:text-gray-500 cursor-pointer h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-transparent"
                        disabled={!!!checkIn || !!!checkOut}
                        onChange={e => selectCandidate(e, candidate)}
                    />
                    
                    <LoadingOverlay visible={false} overlayBlur={2} loaderProps={{ color: 'indigo', size: 'xs' }} overlayColor="bg-indigo-100" />
                </div>
            </td>
        </tr>
        
    );
}